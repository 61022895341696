import React from 'react';
import { connect } from 'react-redux';
import Button from '../../components/CustomButtons/Button.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PaiementContainer from './PaiementContainer';
import GridItem from '../../components/Grid/GridItem';
import { Translate } from 'react-localize-redux';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogPaiement extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
    };
  }

  handleClose = () => {
    this.setState({ open: false });
    const { onClose } = this.props;

    onClose();
  };

  render() {
    const { open } = this.state;
    const { groups } = this.props;

    return (
      <Dialog
        maxWidth={false}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Translate id="traders.payment" />
        </DialogTitle>
        <DialogContent>
          <GridItem container>
            <PaiementContainer groups={groups} />
          </GridItem>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="danger">
            <Translate id="central.cancel" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    // change: bindActionCreators(change, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogPaiement);
