import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
// core components
import Button from '../../components/CustomButtons/Button';

import headerStyle from '../../assets/jss/material-dashboard-react/components/headerStyle.jsx';
import Loading from '../../layouts/Dashboard/Loading';
import HeaderDetails from './HeaderDetails';
import { isNull } from '../../helpers/Common';

function Header({ ...props }) {
  function makeBrand() {
    let name;
    props.routes.map((prop) => {
      if (!isNull(prop.collapse)) {
        prop.collapse.map((p) => {
          if (p.path === props.location.pathname) {
            name = p.navbarName;
          }
        });
      } else {
        if (prop.path === props.location.pathname) {
          name = prop.navbarName;
        }
      }
      return null;
    });
    return name;
  }
  const { classes, color } = props;
  const appBarClasses = classNames({
    [' ' + classes[color]]: color,
  });
  const nameBar = makeBrand();
  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          {nameBar && (
            <Button color="transparent" href="#" className={classes.title}>
              {nameBar}
            </Button>
          )}
        </div>
        <Hidden implementation="css">
          <HeaderDetails />
        </Hidden>
        {/*<Hidden smDown implementation="css">*/}
        {/*<HeaderLinks />*/}
        {/*</Hidden>*/}
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Loading />
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
};

export default withStyles(headerStyle)(Header);
