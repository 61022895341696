import React from 'react';
import { Translate } from 'react-localize-redux';
import { isEmpty, isNull } from '../../../helpers/Common';
import { isRequired } from '../../../helpers/form';

export default (values) => {
  const errors = {};

  const requiredFields = ['name'];
  for (const field of requiredFields) {
    if (isRequired(values[field])) {
      errors[field] = <Translate id="central.required" />;
    }
  }

  if (isEmpty(values) || isEmpty(values.holds) || values.holds.length === 0) {
    errors.holds = <Translate id="central.required" />;
  } else {
    values.holds.forEach((line) => {
      // Soit les deux sont vides, soit un deux sont vite
      if (
        !(isNull(line.amount) && isEmpty(line.token)) &&
        (isNull(line.amount) || isEmpty(line.token))
      ) {
        errors.holds = <Translate id="central.required" />;
      }
    });
  }

  return errors;
};
