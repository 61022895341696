export const strategyConfigurationConstants = {
  GET_STRATEGY_CONFIGURATION:
    '@strategyConfiguration/GET_STRATEGY_CONFIGURATION',
  POST_STRATEGY_CONFIGURATION:
    '@strategyConfiguration/POST_STRATEGY_CONFIGURATION',
  PUT_STRATEGY_CONFIGURATION:
    '@strategyConfiguration/PUT_STRATEGY_CONFIGURATION',
  DELETE_STRATEGY_CONFIGURATION:
    '@strategyConfiguration/DELETE_STRATEGY_CONFIGURATION',
};
