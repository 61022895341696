import React from 'react';
import { connect } from 'react-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import { bindActionCreators } from 'redux';
import {
  IsAdmin,
  setSaveDateDebug,
  getCurrentPrices,
} from '../../../redux/admin/actions';
import {
  getBaseUSDPairExchange,
  isNull,
  parseToFloat,
} from '../../../helpers/Common';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import Circular from '../../../components/Progress/CircularProgress';
import Danger from '../../../components/Typography/Danger';
import Warning from '../../../components/Typography/Warning';
import Success from '../../../components/Typography/Success';

const filter = createFilterOptions();

class WebSocketsPage extends React.Component {
  constructor(props) {
    super(props);
    const defaultPairValueAdmin = localStorage.getItem('defaultPairValueAdmin');
    this.state = {
      refreshSec: 5,
      value: isNull(defaultPairValueAdmin)
        ? []
        : JSON.parse(defaultPairValueAdmin),
    };
  }
  componentDidMount() {
    this.props.IsAdminActions();
    this.props.getCurrentPricesActions();
  }

  calcPourc(val, total) {
    return parseToFloat((val / total) * 100, 0.1);
  }

  render() {
    const { currentPrices, reqDate, repTime } = this.props;
    const exchanges = Object.keys(currentPrices).sort();
    const maxDiffOrange = 5000;
    const maxDiffRed = 60000;
    let nbTotal = 0;
    let nbTotalGreem = 0;
    let nbTotalOrange = 0;
    let nbTotalRed = 0;
    let allPairs = [];
    let symbols = [];
    for (const exchange of exchanges) {
      const pairs = Object.keys(currentPrices[exchange]);
      for (const pair of pairs) {
        if (allPairs.indexOf(pair) === -1) allPairs.push(pair);
        const split = pair.split('-');
        if (split.length > 0 && symbols.indexOf(split[0]) === -1)
          symbols.push(split[0]);
        if (split.length > 1 && symbols.indexOf(split[1]) === -1)
          symbols.push(split[1]);
      }
    }
    allPairs = allPairs.filter((x) => x.indexOf(':') === -1).sort();
    symbols = symbols.filter((x) => x.indexOf(':') === -1);

    const now = reqDate;
    return (
      <>
        <GridContainer>
          {exchanges.map((key, index) => {
            if (isNull(currentPrices[key])) return <div key={key}></div>;
            const exchangeName = key;
            const pairsObj = currentPrices[key];
            const pairs = Object.keys(pairsObj);
            let favoritePairs = [];
            for (const symbol of ['ETH', 'BTC'])
              favoritePairs.push(getBaseUSDPairExchange(exchangeName, symbol));
            for (const pair of this.state.value) favoritePairs.push(pair);

            let ret = [];
            let min = 0;
            let minStr = '';
            let max = 0;
            let maxStr = '';
            let nbPairRed = 0;
            let nbPairOrange = 0;
            let nbPairGreen = 0;

            for (const pair of pairs) {
              const data = pairsObj[pair];
              const time = data.Time;
              const utcDate = new Date(time);
              const date = utcDate;
              // const date = utcDate.setHours(utcDate.getHours() + 2);
              const diffMS = now - date;
              // if (diffMS< 0)
              //   console.log(now, date, now.getTime() - date.getTime(), now - date )

              const diff = diffMS / 1000;
              const str = (
                <div>
                  &nbsp;&nbsp;&nbsp;{pair}:
                  {diffMS > maxDiffOrange ? (
                    diffMS > maxDiffRed ? (
                      <Danger>
                        <b>{diff}</b>
                      </Danger>
                    ) : (
                      <Warning>
                        <b>{diff}</b>
                      </Warning>
                    )
                  ) : (
                    diff
                  )}
                  s p: {data?.Result?.Last ?? 0}
                </div>
              );

              if (diffMS > maxDiffRed) ++nbPairRed;
              else if (diffMS > maxDiffOrange) ++nbPairOrange;
              if (diffMS <= maxDiffOrange) ++nbPairGreen;
              if (diffMS > max) {
                max = diffMS;
                maxStr = str;
              }
              if (diffMS < min || min === 0) {
                min = diffMS;
                minStr = str;
              }

              if (favoritePairs.indexOf(pair) !== -1) ret.push(str);
            }
            const nbTotalExchange = nbPairRed + nbPairOrange + nbPairGreen;
            ret.push(minStr);
            ret.push(maxStr);
            const pourcGreen = this.calcPourc(nbPairGreen, nbTotalExchange);
            const pourcOrange = this.calcPourc(nbPairOrange, nbTotalExchange);
            const pourcRed = this.calcPourc(nbPairRed, nbTotalExchange);
            ret.push(
              <div>
                nbPairGreen:{' '}
                <Success>
                  <b>{nbPairGreen}</b>
                </Success>{' '}
                ({pourcGreen}%)
              </div>,
            );
            ret.push(
              <div>
                NbPairOrange:{' '}
                <Warning>
                  <b>{nbPairOrange}</b>
                </Warning>{' '}
                ({pourcOrange}%)
              </div>,
            );
            ret.push(
              <div>
                NbPairRed:{' '}
                <Danger>
                  <b>{nbPairRed}</b>
                </Danger>{' '}
                ({pourcRed}%)
              </div>,
            );
            nbTotalGreem += nbPairGreen;
            nbTotalOrange += nbPairOrange;
            nbTotalRed += nbPairRed;
            nbTotal += nbTotalExchange;

            return (
              <GridItem key={index} xs={6} sm={4} md={3}>
                {min > maxDiffRed ? (
                  <Danger>
                    <b>{exchangeName}</b>
                  </Danger>
                ) : min > maxDiffOrange ? (
                  <Warning>
                    <b>{exchangeName}</b>
                  </Warning>
                ) : (
                  <Success>
                    <b>{exchangeName}</b>
                  </Success>
                )}
                &nbsp;:&nbsp;
                {pourcGreen >= 70 ? (
                  <Success>
                    <b>{pairs.length} </b>
                  </Success>
                ) : pourcRed > 30 ? (
                  <Danger>
                    <b>{pairs.length} </b>
                  </Danger>
                ) : (
                  <Warning>
                    <b>{pairs.length} </b>
                  </Warning>
                )}
                &nbsp;pairs. <br />
                {ret.map((ke, v) => (
                  <div key={v}>{ke}</div>
                ))}
                <br />
              </GridItem>
            );
          })}
          <GridItem xs={6} sm={4} md={3}>
            <TextField
              value={this.state.refreshSec}
              onChange={(e, v) => {
                const val = parseInt(e.target.value);
                if (!isNaN(val)) this.setState({ refreshSec: val });
              }}
            />
            {this.state.refreshSec > 0 && (
              <Circular
                refreshSec={this.state.refreshSec}
                actionToDo={() => {
                  this.props.getCurrentPricesActions();
                }}
              />
            )}
          </GridItem>
          <GridItem xs={6} sm={4} md={3}>
            <div>RepTime: {repTime / 1000}s</div>
            <div>Nb Pairs: {allPairs.length} distinct</div>
            <div>Nb Symbols: {symbols.length} distinct</div>
            <div>NbPairs: {nbTotal}</div>
            <div>
              NbNormal:{' '}
              <Success>
                <b>{nbTotalGreem}</b>
              </Success>{' '}
              ({this.calcPourc(nbTotalGreem, nbTotal)}%) (plus petit que{' '}
              {maxDiffOrange / 1000}s)
            </div>
            <div>
              NbTotalOrange:{' '}
              <Warning>
                <b>{nbTotalOrange}</b>
              </Warning>{' '}
              ({this.calcPourc(nbTotalOrange, nbTotal)}%) (
              {maxDiffOrange / 1000}s)
            </div>
            <div>
              NbTotalRed:{' '}
              <Danger>
                <b>{nbTotalRed}</b>
              </Danger>{' '}
              ({this.calcPourc(nbTotalRed, nbTotal)}%) ({maxDiffRed / 1000}s)
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Autocomplete
              multiple
              options={allPairs}
              defaultValue={this.state.value}
              onChange={(e, v) => {
                this.setState({ value: v });
                localStorage.setItem(
                  'defaultPairValueAdmin',
                  JSON.stringify(v),
                );
              }}
              filterOptions={(options, params) => {
                let filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option,
                );
                if (inputValue !== '' && !isExisting) {
                  filtered.push(inputValue);
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Pairs"
                  placeholder="Pairs"
                />
              )}
            />
            <br />
            <br />
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    currentPrices: admin.currentPrices,
    reqDate: admin.reqDate,
    repTime: admin.repTime,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSaveDateDebugActions: bindActionCreators(setSaveDateDebug, dispatch),
    getCurrentPricesActions: bindActionCreators(getCurrentPrices, dispatch),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
  };
};

const connectedUsers = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebSocketsPage);
export default connectedUsers;
