import React from 'react';
import { Translate } from 'react-localize-redux';

export default (values) => {
  const errors = {};
  const requiredFields = ['email', 'password', 'confirmPassword', 'cgvu'];
  for (const field of requiredFields) {
    if (!values[field]) {
      errors[field] = <Translate id="central.required" />;
    }
  }

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)
  ) {
    errors.email = <Translate id="email.invalidEmail" />;
  }
  if (values.password) {
    // let error = '';
    // if (!/^(?=.*[a-z])$/i.test(values.password)) {
    //   error += 'password must contain 1 lowercase letters\n';
    // }
    // if (!/^(?=.*[A-Z])$/i.test(values.password)) {
    //   error += 'password must contain 1 uppercase letters\n';
    // }
    // if (!/^(?=.*\d)$/i.test(values.password)) {
    //   error += 'password must contain 1 number (0-9)\n';
    // }
    // if (error !== '') errors['password'] = error;
    if (
      !/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,100}$/.test(
        values.password,
      )
    ) {
      errors.password = <Translate id="email.checkPassword" />;
    }
  }
  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = <Translate id="email.matchPassword" />;
  }
  return errors;
};
