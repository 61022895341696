import React from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import GridItem from '../../../components/Grid/GridItem';
import Card from '../../../components/Card/Card';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditShareButton,
  RedditIcon,
} from 'react-share';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { getGroupLink, getGroupName } from '../../../redux/onboarding/selector';
import copy from 'copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';

const ShareCommunity = () => {
  const groupName = useSelector(getGroupName);
  const groupLink = useSelector(getGroupLink);
  const shareUrl = 'https://walloftraders.com?ref=14sad5sa4d54';
  const title = 'GitHub';
  const [copied, setCopied] = React.useState(false);

  return (
    <Grid container>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h6" align="center">
          <Translate
            id="onboarding.shareGroupLink"
            data={{ groupName: groupName }}
            options={{ renderInnerHtml: true }}
          />
        </Typography>
      </GridItem>

      <Card>
        <Grid container>
          <GridItem xs={12} sm={12} md={2} lg={2} />
          <GridItem xs={12} sm={12} md={8} lg={8} style={{ marginTop: '15px' }}>
            <Translate
              id="onboarding.linkMessage"
              data={{ groupName: groupName, link: groupLink }}
            />
            <br />
            {shareUrl}
            &nbsp;&nbsp;
            <Tooltip
              placement="top"
              title={
                copied ? (
                  <Translate id="onboarding.copied" />
                ) : (
                  <Translate id="onboarding.copyToClipboard" />
                )
              }
              enterDelay={0}
            >
              <FileCopyIcon
                color="primary"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  copy(shareUrl);
                  setCopied(true);
                }}
              />
            </Tooltip>
          </GridItem>
          <GridItem xs={12} sm={12} md={2} lg={2} />
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginTop: '30px' }}
          >
            <Grid container justifyContent="center">
              {/* ***************Twitter******************* */}
              <TwitterShareButton
                url={shareUrl}
                quote={title}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              {/* ***************FACEBOOK******************* */}
              <FacebookShareButton
                url={shareUrl}
                quote={title}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              {/* ***************TELEGRAM******************* */}
              <TelegramShareButton
                url={shareUrl}
                title={title}
                className="Demo__some-network__share-button"
              >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
              {/* ***************LINKEDIN******************* */}
              <LinkedinShareButton
                url={shareUrl}
                className="Demo__some-network__share-button"
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              {/* ***************REDDIT******************* */}
              <RedditShareButton
                url={shareUrl}
                title={title}
                windowWidth={660}
                windowHeight={460}
                className="Demo__some-network__share-button"
              >
                <RedditIcon size={32} round />
              </RedditShareButton>
            </Grid>
          </GridItem>
        </Grid>
      </Card>
    </Grid>
  );
};

export default ShareCommunity;
