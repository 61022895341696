import React from 'react';
import { Translate } from 'react-localize-redux';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';

import PairExchangeContainer from './Container/PairExchangeContainer';
import StartContainer from './Container/StartContainer';
import StopLossContainer from './Container/StopLossContainer';
import TakeProfitsContainer from './Container/TakeProfitsContainer';
import TraderContainer from './Container/TraderContainer';
import { isNull } from '../../helpers/Common';

function FormSmartTradeDesktop({ formName, currentOrder, onSubmit, disabled }) {
  return (
    <>
      <GridContainer>
        <PairExchangeContainer formName={formName} />
        {/*Envoi order pour lavoir dans le props pour etre utiliser dans capitalSelector , orderSelector*/}
        <StartContainer formName={formName} order={currentOrder} />
        <TakeProfitsContainer formName={formName} />
        <GridItem xs={12} sm={12} md={4}>
          <GridContainer>
            <StopLossContainer formName={formName} />
            <TraderContainer formName={formName} />
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer justifyContent="center">
        <Button onClick={onSubmit} color="primary" round disabled={disabled}>
          {!isNull(currentOrder) ? (
            <Translate id="smartTrade.updateTrade" />
          ) : (
            <Translate id="smartTrade.createTrade" />
          )}
        </Button>
      </GridContainer>
    </>
  );
}

export default FormSmartTradeDesktop;
