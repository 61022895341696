import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withLocalize } from 'react-localize-redux';

import {
  getAvailableAmount,
  getMyGroups,
  getOrderV1,
} from '../../redux/order/actions';
import transError from '../../locales/error.json';
import transSmartTrade from '../../locales/smartTrade.json';
import transOrders from '../../locales/orders';
import transConfirm from '../../locales/confirmation.json';
import transExchanges from '../../locales/exchanges';
import { getExchanges } from '../../redux/user/actions';
import { getTraders } from '../../redux/trader/actions';

import FormSmartTrade from './FormSmartTrade';
import TradingViewContainer from './Container/TradingViewContainer';
// import { isNull } from './Common';

class SmartTradePageDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transExchanges);
    this.props.addTranslation(transError);
    this.props.addTranslation(transSmartTrade);
    this.props.addTranslation(transOrders);
    this.props.addTranslation(transConfirm);
    this.state = {
      order: null,
    };
  }
  // componentDidMount() {
  //   const { orderId } = this.props;
  //
  //   if (!isNaN(orderId) && !isNull(orderId))
  //     this.props.getOrderActions(orderId);
  //
  //   console.log('DESTOP')
  //   this.props.getMyGroupsActions();
  //   this.props.getExchangesActions();
  //   this.props.getAvailableAmountActions();
  //   this.props.getTradersActions();
  // }

  // componentDidUpdate (prevProps, prevState, snapshot) {
  //   console.log('SmartTrade componentDidUpdate', this.props, this.state, prevProps, prevState);
  //   // if (prevState.order === null && this.state.order !== null) {
  //   //   this.setState({order})
  //   // }
  // }

  render() {
    const { orders, orderId } = this.props;

    let order = null;
    if (orders && orderId) {
      const filter = orders.filter((x) => x.OrderId === orderId);
      order = filter.length === 0 ? null : filter[0];
    }
    //Trix ne pas afficher quand order est null en modif
    if (orderId && orders.length === 0) {
      return <div />;
    }
    return (
      <div>
        <TradingViewContainer />
        <FormSmartTrade order={order} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { order } = state;
  const match = props.match;
  const orderId = match && match.params ? parseInt(match.params.id) : null;
  return {
    orders: order.orders,
    orderId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTradersActions: bindActionCreators(getTraders, dispatch),
    getMyGroupsActions: bindActionCreators(getMyGroups, dispatch),
    getOrderActions: bindActionCreators(getOrderV1, dispatch),
    getExchangesActions: bindActionCreators(getExchanges, dispatch),
    getAvailableAmountActions: bindActionCreators(getAvailableAmount, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(SmartTradePageDesktop),
);
