import React from 'react';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem';

import { withLocalize } from 'react-localize-redux';
import { Translate } from 'react-localize-redux';

import transSmartTrade from '../../locales/smartTrade.json';
import transOrders from '../../locales/orders.json';
import transError from '../../locales/error.json';
import transConfirm from '../../locales/confirmation.json';
import Orders from './Orders';

class OrdersPage extends React.Component {
  constructor(props) {
    super(props);

    this.props.addTranslation(transError);
    this.props.addTranslation(transOrders);
    this.props.addTranslation(transSmartTrade);
    this.props.addTranslation(transConfirm);
  }

  render() {
    return (
      <GridContainer>
        <GridItem container>
          <GridItem container xs={12} sm={6} md={6} alignItems="center">
            <h1 style={{ margin: 0 }}>
              <Translate id="orders.currentOrders" />
            </h1>
          </GridItem>
        </GridItem>
        <Orders />
      </GridContainer>
    );
  }
}

export default withLocalize(OrdersPage);
