import { isNull, isEmpty } from '../../helpers/Common';
import { getActiveCode } from '../../helpers/localize';

export const currentAddTraderSelector = (state) => {
  if (!state || !state.trader || !state.trader.currentAddTrader) return null;
  return state.trader.currentAddTrader;
};
export const tradersSelector = (state) => {
  if (!state || !state.trader || !state.trader.traders) return [];
  return state.trader.traders;
};

export const groupsSelector = (state) => {
  if (!state || !state.trader || !state.trader.groups) return [];
  return state.trader.groups;
};

export const followersSelector = (state) => {
  if (!state?.trader?.followers) return [];
  return state.trader.followers;
};

export const statGroupSelector = (state) => {
  if (!state || !state.trader || !state.trader.statGroup) return [];
  return state.trader.statGroup;
};

export const paiementSelector = (state) => {
  if (!state || !state.trader || !state.trader.paiement) return '';
  return state.trader.paiement;
};

export const descriptionSelector = (state, props) => {
  const { config } = state;
  const { trader } = props;
  const { theme } = config;
  const codeLang = getActiveCode(state);

  let description = '';
  try {
    const jsonDesc = JSON.parse(trader.ShortDescription);
    description = jsonDesc[codeLang];
  } catch (e) {
    description =
      codeLang === 'gb'
        ? isEmpty(trader.ShortDescriptionGb)
          ? trader.ShortDescription
          : trader.ShortDescriptionGb
        : trader.ShortDescription;
  }
  if (!isNull(description))
    description = description.replaceAll('${theme}', theme);
  return description;
};
