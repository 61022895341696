export const exchangeConstants = {
  ACTION_GET_EXCHANGE: '@user/ACTION_GET_EXCHANGE',
  ACTION_GET_EXCHANGES: '@user/ACTION_GET_EXCHANGES',
  ACTION_LOCAL_EXCHANGES: '@user/ACTION_LOCAL_EXCHANGES',
  ACTION_POST_EXCHANGE: '@user/ACTION_POST_EXCHANGE',
  ACTION_PUT_EXCHANGE: '@user/ACTION_PUT_EXCHANGE',
  ACTION_DELETE_EXCHANGE: '@user/ACTION_DELETE_EXCHANGE',

  ACTION_GET_AVAILABLE_AMOUNT: '@user/ACTION_GET_AVAILABLE_AMOUNT',
  ACTION_LOCAL_AVAILABLE_AMOUNT: '@user/ACTION_LOCAL_AVAILABLE_AMOUNT',
  ACTION_GET_ORDERS: '@user/ACTION_GET_ORDERS',
  ACTION_LOCAL_ORDERS: '@user/ACTION_LOCAL_ORDERS',

  ACTION_GET_HISTORIC_BALANCES: '@user/ACTION_GET_HISTORIC_BALANCES',
};
