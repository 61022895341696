import React from 'react';
import { change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import Button from '../../components/CustomButtons/Button.jsx';

import {
  clearConvertSmartTrade,
  convertSmartTradeV1,
  postSmartTradeV1,
  putOrderV1,
} from '../../redux/order/actions';

import {
  leverageBuySelector,
  leverageSellSelector,
} from '../../helpers/Common';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-localize-redux';
import OrderContainer from '../Orders/OrderContainer';
import TextField from '@material-ui/core/TextField';
import { convertOrderBeforeSendV1 } from '../../helpers/order';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogCreateTrade extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      sendIsLock: false,
      note: props.values.note,
    };
  }

  componentDidMount = () => {
    this.props.convertSmartTradeActions(
      this.convertOrderBeforeSend(this.props.values),
    );
  };

  accept = () => {
    this.createTrade();
    // this.setState({ open: true });
  };

  handleClose = () => {
    // let { open } = this.props;
    // open = false;
    this.props.clearConvert();
    this.setState({ open: false });
    const { onClose } = this.props;

    onClose();
  };

  convertOrderBeforeSend = () => {
    const { leverageBuy, leverageSell } = this.props;
    let order = { ...this.props.values };

    return convertOrderBeforeSendV1(order, leverageBuy, leverageSell);
  };

  createTrade = () => {
    // console.log('createTrade', this.props.values);
    this.setState({ sendIsLock: true });
    setInterval(() => this.setState({ sendIsLock: false }), 1500);

    let order = this.convertOrderBeforeSend();
    order.note = this.state.note;

    if (order.order != null) {
      this.props.putOrderActions(order.order.OrderId, order);
    } else {
      this.props.postSmartTradeActions(order);
    }
    this.handleClose();
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { open, sendIsLock, note } = this.state;
    const { convert } = this.props;

    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Translate id="smartTrade.confirmTrade" />
        </DialogTitle>
        <DialogContent>
          <div>
            <OrderContainer order={convert} />
            <TextField
              value={note}
              label={<Translate id="central.note" />}
              name="note"
              multiline={true}
              fullWidth={true}
              minRows={3}
              onChange={(e) => this.handleChange(e)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="danger">
            <Translate id="central.cancel" />
          </Button>
          <Button
            onClick={this.accept}
            color="success"
            disabled={sendIsLock || !convert}
          >
            <Translate id="smartTrade.confirm" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { order } = state;
  return {
    values: getFormValues(props.formName)(state),
    leverageBuy: leverageBuySelector(state, props),
    leverageSell: leverageSellSelector(state, props),
    convert: order.convert,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    convertSmartTradeActions: bindActionCreators(convertSmartTradeV1, dispatch),
    clearConvert: bindActionCreators(clearConvertSmartTrade, dispatch),
    postSmartTradeActions: bindActionCreators(postSmartTradeV1, dispatch),
    putOrderActions: bindActionCreators(putOrderV1, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogCreateTrade);
