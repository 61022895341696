import React from 'react';

export default class TermsAndConditionsRUPage extends React.Component {
  render() {
    return (
      <div style={{ textAlign: 'justify' }}>
        <h1>
          <b>Общие условия продажи и использования</b>
        </h1>
        <b>ПРЕДУПРЕЖДЕНИЕ</b>
        <br />
        Мы не даем вам никаких рекомендаций или инвестиционных советов. Мы
        предлагаем помощь в вашей торговле. Любая торговля связана с рисками,
        которые вы готовы принять. Используйте только те деньги, которые вы
        можете позволить себе потерять. Выигрыш не гарантируется. Все позиции на
        покупку и продажу на рынке крипто-активов, занимаемые Клиентом или
        Пользователем или инструментом автоматизации торговли на веб-сайте
        https://walloftraders.com, должны контролироваться Клиентом или
        Пользователем. Только Заказчик или Пользователь несут ответственность за
        эти позиции. Только люди, которые понимают торговлю и связанные с этим
        риски, могут зарегистрироваться. Мы не несем ответственности за ошибки,
        неточности, упущения, потерю данных, потерю фиатной валюты, потерю или
        обесценивание криптоактивов, неправильное понимание предоставленных
        Услуг, возможныее задержки в исполнении или изменения цен. Все Услуги,
        инструменты и информация, предоставляемые Wall Of Traders, являются
        частными и не должны рассматриваться как безошибочные. Вы должны быть
        старше 18 лет, чтобы использовать Wall Of Traders. Рынки крипто-активов,
        на которых Компания предлагает свои услуги, являются нерегулируемыми и
        децентрализованными рынками. Будучи нерегулируемыми, эти рынки не
        регулируются какими-либо рамками или правилами, французскими, русскими
        или европейскими. Следовательно, Клиент или Пользователь понимают, что
        он не пользуется защитой, предоставляемой регулируемыми рынками.
        <br />
        <h3>
          <b>1. О нас</b>
        </h3>
        <br />
        Компания WALL OF TRADERS, SAS, с капиталом в 1000 евро, головной офис
        которой находится в 15 rue des Halles 75001 Paris, зарегистрирована в
        торговом реестре и компаниях PARIS под номером SIREN 907601579 в лице
        г-на Адриена БОЗЗАНО (далее - «Компания») Компания предлагает следующие
        услуги: помощь в торговле криптовалютой и подписка на торговые сигналы.
        <h3>
          <b>2. Преамбула</b>
        </h3>
        <br />
        Компания предлагает своим Пользователям внимательно ознакомиться с
        настоящими Условиями продажи и использования (далее «Условия и
        положения»). Условия и положения распространяются на все Услуги,
        предоставляемые Компанией для своих Клиентов той же категории,
        независимо от положений, которые могут быть написаны на документах
        Заказчика и, в частности, на его общих условиях покупки. Условия и
        положения систематически сообщаются Клиенту, который их запрашивает.
        Клиент обязан прочитать Условия перед размещением любого Заказа. В
        случае последующего изменения Условий, Клиент использует последнюю
        опубликованную версию. Данные, записанные в ИТ-системе Компании,
        являются подтверждением совершенных сделок с Клиентом.
        <br />
        <h3>
          <b>3. Определения</b>
        </h3>
        <br />
        <b>&quot;Клиент&quot;</b> означает любое физическое или юридическое
        лицо, которое размещает Заказ на этом Веб-сайте;
        <br />
        <b>&quot;Заказ&quot;</b> означает любой заказ, размещенный
        Пользователем, зарегистрированным на этом Сайте, с целью использования
        Услуг Компании;
        <br />
        <b>&quot;Общие условия продажи и использования&quot;</b> или{' '}
        <b>&quot;Условия использования&quot;</b> означают настоящие общие
        условия продажи и использования в Интернете;
        <br />
        <b>&quot;Услуги&quot;</b> означает все услуги, предлагаемые
        Пользователям Компанией через данный Сайт;
        <br />
        <b>&quot;Криптоактивы&quot;</b> относятся ко всем дематериализованным
        активам, транзакции которых осуществляются через блокчейн: они включают
        в себя криптовалюты и токены;
        <br />
        <b>“Обмен”</b> означает биржевые площадки за пределами Сайта, на которых
        крипто-активы приобретаются и обмениваются и над которыми Сайт не имеет
        контроля; например, Binance, Bittrex, Bitfinex, Kraken, Coinbase
        являются биржами;
        <br />
        <b>&quot;Сайт&quot;</b> означает этот сайт, https://walloftraders.com;
        <br />
        <b>&quot;Компания&quot;</b> означает корпорацию Wall Of Traders, более
        подробно описанную в разделе I настоящего Соглашения; и<br />
        <b>&quot;Пользователь&quot;</b> означает любое лицо, которое использует
        Сайт.
        <br />
        <h3>
          <b>4. Регистрация</b>
        </h3>
        <br />
        Регистрация на Сайте открыта для всех юридических и физических лиц,
        которые могут пользоваться всеми сервисами и правовыми возможностями.
        <br />
        Использование Сервисов, предлагаемых на Сайте, подлежит регистрации
        Пользователем на Сайте.
        <br />
        Регистрация бесплатная.
        <br />
        Для продолжения регистрации Пользователь должен заполнить все
        обязательные поля, без которых услуга не может быть доставлена. В
        противном случае регистрация не может быть завершена.
        <br />
        Пользователи гарантируют и заявляют в свою честь, что вся информация,
        представленная на Сайте, в частности, при их регистрации, является
        точной и соответствует действительности. Они обязуются обновлять свою
        личную информацию со страницы, посвященной им и доступной в их учетной
        записи.
        <br />
        Любой зарегистрированный пользователь имеет имя пользователя и пароль.
        Они носят сугубо личный и конфиденциальный характер и ни в коем случае
        не должны являться предметом сообщения третьим лицам о наказании за
        удаление учетной записи зарегистрированного пользователя-нарушителя.
        Каждый зарегистрированный пользователь несет личную ответственность за
        сохранение конфиденциальности своего имени пользователя и пароля.
        Компания никоим образом не будет нести ответственность за кражу личных
        данных Пользователя. Если Пользователь подозревает мошенничество в любое
        время, он должен связаться с Компанией как можно скорее, чтобы принять
        необходимые меры и урегулировать ситуацию.
        <br />
        Каждый пользователь, будь то физическое или юридическое лицо, может
        иметь только одну учетную запись на Сайте.
        <br />
        В случае несоблюдения Условий, включая создание нескольких учетных
        записей для одного лица или предоставление ложной информации, Компания
        оставляет за собой право приступить к временной блокировке или
        постоянному удалению всех учетных записей, созданных зарегистрированным
        нарушившим Пользователем
        <br />
        Удаление учетной записи влечет за собой окончательную потерю всех
        преимуществ и услуг, приобретенных на Сайте. Однако любой Заказ,
        сделанный и выставленный на Сайте до удаления учетной записи, будет
        выполнен в обычных условиях.
        <br />
        В случае удаления Компанией учетной записи за нарушение обязанностей и
        обязательств, изложенных в Условиях, для Пользователя-нарушителя
        категорически запрещается повторная регистрация на Сайте напрямую, через
        другой адрес электронной почты или путем посредник без явного разрешения
        Компании.
        <br />
        <h3>
          <b>5. Предоставление услуг и цена</b>
        </h3>
        <br />
        Услуги, охватываемые настоящими Условиями, - это те, которые появляются
        на Сайте и предлагаются непосредственно Компанией или ее поставщиками
        партнерами.
        <br />
        Услуги описаны на соответствующей странице в Сайте, и упоминаются все их
        основные характеристики. Компания не может нести ответственность за
        невозможность оказания этой услуги, когда Пользователь не имеет на то
        права.
        <br />
        Когда Зарегистрированный пользователь желает получить услугу, проданную
        Компанией через Сайт, цена, указанная на странице услуги, соответствует
        цене в евро с учетом всех налогов (TTC) и учитывает скидки, применимые и
        действующие в день заказа. Указанная цена не включает непредвиденные
        расходы, которые будут указаны при необходимости в выписке до размещения
        заказа.
        <br />
        Компания оставляет за собой право изменять свои тарифы в любое время.
        Она обязуется выставлять счета за услуги по курсу, действующему на
        момент их Заказа. Для услуг, цена которых не может быть известна априори
        или указана с уверенностью, детальная оценка будет отправлена Заказчику.
        <br />
        Ни при каких обстоятельствах Пользователь не может потребовать
        применения скидок, которые более не действуют на дату Заказа.
        <br />
        <h3>
          <b>6. Заказы</b>
        </h3>
        <br />
        Любой Заказ может быть сделан только после регистрации Пользователя на
        Сайте. Пользователь, войдя в свою учетную запись, может добавить Услуги
        в свою виртуальную корзину. Он может получить доступ к сводке своей
        виртуальной корзины, чтобы подтвердить Услуги, которые он хочет
        заказать, и выполнить свой Заказ, нажав кнопку «Заказать».
        <br />
        Затем он должен ввести свой адрес электронной почты и действительный
        способ оплаты, чтобы завершить заказ и эффективно заключить договор
        купли-продажи между ним и Компанией.
        <br />
        Регистрация Заказа на Сайте производится, когда Клиент принимает
        Условия, поставив галочку в соответствующем поле и подтверждая свой
        Заказ. Эта проверка является доказательством договора купли-продажи.
        Завершение Заказа подразумевает принятие цен и условий предоставления
        услуг, указанных на Сайте.
        <br />
        После размещения Заказа Клиент получит подтверждение по электронной
        почте. Это подтверждение будет обобщать Заказ и информацию, относящуюся
        к выполнению услуги/(услуг).
        <br />
        В случае невыполнения платежа, неверного адреса Клиента или других
        проблем на счете Клиента, Компания оставляет за собой право блокировать
        заказ Клиента до тех пор, пока проблема не будет решена. В случае
        невозможности оказания услуги, Клиент будет проинформирован по
        электронной почте на адрес, который он предоставил Компании. В этом
        случае будет произведена отмена заказа этой услуги и ее возврат,
        остальная часть заказа остается твердой и окончательной.
        <br />
        <h3>
          <b>7. Сроки и условия оплаты</b>
        </h3>
        <br />
        Если не указано иное, все продажи полностью оплачиваются на момент
        размещения заказа.
        <br />
        В зависимости от характера или суммы Заказа, Компания оставляет за собой
        право потребовать задаток или оплату полной стоимости при размещении
        Заказа или при получении счета.
        <br />
        Оплата может быть произведена:
        <ul>
          <li>Банковская карта ;</li>
          <li>
            Крипто-актив Bitcoin, Ethereum или Litecoin при конвертации в евро
            или доллары от суммы подписки. Курс - один день и час к минуте
            прихода заказа;
          </li>
          <li>Paypal.</li>
        </ul>
        В случае полной или частичной неоплаты услуг в согласованную в счете
        дату, Клиент должен выплатить Компании штраф за несвоевременную оплату
        по ставке, равной ставке, установленной Европейским центральным банком
        за операцию рефинансирования, плюс 10 процентных пунктов. Выбранная
        операция финансирования является самой последней на дату Заказа Услуг.
        <br />
        В дополнение к несвоевременному платежу любая сумма, включая авансовый
        платеж, неуплаченная в установленный срок, автоматически приведет к
        уплате единовременной суммы в размере 40 евро.
        <br />
        В случае полной или частичной неоплаты услуг в дату, оговоренную в
        счете, Заказчик уплачивает Компании штраф за несвоевременную оплату по
        ставке, равной законной процентной ставке.
        <br />
        Заказчик не может получить компенсацию за несвоевременные штрафы за
        предоставление заказанных услуг и суммы задолженности Клиента перед
        Компанией за покупку Услуг, предлагаемых на Сайте.
        <br />
        TШтраф, подлежащий уплате Клиентом, рассчитывается на основе суммы,
        включающей налоги на оставшуюся причитающуюся сумму, и исчисляется с
        даты исполнения цены без предварительного уведомления.
        <br />
        В случае несоблюдения условий оплаты, описанных выше, Компания оставляет
        за собой право отменить или приостановить продажу.
        <br />
        <h3>
          <b>8. Реализация услуг</b>
        </h3>
        The provision of services ordered on the Site will be provided by the
        Company.Предоставленные на Сайте услуги будут предоставлены Компанией.
        <br />
        Компания обязуется использовать все человеческие и материальные ресурсы
        для оказания услуг в течение периода времени, заявленного при оформлении
        Заказа. Тем не менее, она никоим образом не может нести ответственность
        за задержки в предоставлении услуг, вызванные ошибкой, вызванной этим.
        <br />
        Если услуги не были выполнены в установленные сроки, Заказчик может
        запросить разрешение на продажу в соответствии с условиями,
        предусмотренными в статьях L138-2 и L138-3 Потребительского кодекса.
        Суммы, уплаченные Клиентом, будут возвращены самое позднее в течение
        четырнадцати дней с даты расторжения договора.
        <br />
        Это положение не применяется, если задержка Компании вызвана ошибкой
        Клиента или форс-мажорными обстоятельствами, т. е. Возникновением
        непредвиденного события, непреодолимого и независимого от воли Общества.
        <br />
        В случае, если выполнение физической услуги не могло быть выполнено или
        было отложено из-за ошибки в адресе, указанном Клиентом, транспортные
        расходы поставщика услуг, уполномоченного Компанией для выполнения
        неудачной услуги есть ответственность Заказчика..
        <br />
        <h3>
          <b>9. Претензия</b>
        </h3>
        <br />
        Для всех Заказов, сделанных на этом Сайте, Клиент имеет право на жалобу
        в течение 5 дней с момента предоставления Услуги.
        <br />
        Для реализации этого права на подачу жалобы Клиент должен отправить в
        Компанию по адресу contact@walloftraders.com заявление, в котором он
        выражает свои жалобы и требования, а также подтверждающие документы,
        связанные с этим.
        <br />
        Претензия, не соответствующая описанным выше условиям, не может быть
        принята.
        <br />
        После рассмотрения претензии Сайт может заменить или возместить услуги в
        кратчайшие сроки и за свой счет.
        <br />
        <h3>
          <b>10. Право клиента на отмену</b>
        </h3>
        <br />
        Клиент имеет право отказаться от заказа в течение 14 дней, за
        исключением продуктов, упомянутых в статье L121-21-8 Кодекса прав
        потребителей, как показано ниже:
        <br />
        «Право отказа не может быть реализовано для договоров:
        <br />1 ° Предоставление услуг, полностью выполненных до окончания
        периода снятия средств, выполнение которых началось после
        предварительного явного согласия Клиента и явного отказа от его права
        снятия;
        <br />2 ° поставка товаров или услуг, цена которых зависит от колебаний
        на финансовом рынке, которые не контролируются трейдером и могут
        произойти в течение периода вывода средств;
        <br />3 ° Поставка товаров, сделанных по спецификации Заказчика или
        четко персонализированных;
        <br /> 4 ° Поставка товаров, которые могут ухудшиться или быстро
        истекают;
        <br />5 ° Поставка товаров, которые были распечатаны Клиентом после
        доставки и которые не могут быть возвращены по соображениям гигиены или
        охраны здоровья;
        <br />6 ° поставка товаров, которые после доставки и по своей природе
        неразрывно смешиваются с другими предметами;
        <br />7 ° Поставка алкогольных напитков, доставка которых отложена до
        тридцати дней и стоимость которых согласована при заключении контракта,
        зависит от колебаний рынка, неподконтрольных профессионалу;
        <br />8 ° Работы по техническому обслуживанию или ремонту, которые
        должны быть срочно выполнены в доме Заказчика и по его прямой просьбе, в
        пределах запасных частей и работ, строго необходимых для реагирования на
        чрезвычайную ситуацию;
        <br />9 ° Предоставление аудио- или видеозаписей или программного
        обеспечения, если они были распечатаны Клиентом после доставки;
        <br />
        10 ° поставка газеты, периодического издания или журнала, за исключением
        договоров на подписку на такие публикации;
        <br />
        11 ° Заключен на открытом аукционе;
        <br />
        12 ° Предоставление услуг по размещению, кроме жилых помещений, услуг по
        перевозке товаров, проката автомобилей, общественного питания или
        досуга, которые должны быть предоставлены в указанную дату или период;
        <br />
        13 ° Предоставление цифрового контента, который не предоставляется на
        физическом носителе, исполнение которого началось после явного
        предварительного согласия Клиента и явно отказывается от его права на
        отзыв.&quot;
        <br />
        Для реализации этого права на отзыв Клиент отправляет декларацию по
        адресу:
        <br />
        contact@walloftraders.com.
        <br />
        Ему будут возмещены за все сборы, уплаченные за предоставление услуг в
        течение 14 дней после того, как станет известно о заявлении компании на
        снятие средств. Возврат будет осуществлен тем же способом оплаты,
        который использовался для покупки.
        <br />
        Тем не менее, если предоставление услуг уже началось на дату
        подтверждения отзыва Компанией, стоимость, соответствующая уже
        предоставленной услуге, будет вычтена из возмещения. Последний будет
        работать с тем же платежным средством, что и при покупке.
        <br />
        <h3>
          <b>11. Обработка персональных данных</b>
        </h3>
        <br />
        Регистрация на Сайте влечет за собой обработку персональных данных
        Клиента. Если Клиент отказывается от обработки своих данных, его просят
        воздержаться от использования Сайта.
        <br />
        Эта обработка персональных данных осуществляется в соответствии с Общим
        положением о защите данных 2016/679 от 27 апреля 2016 года.
        <br />
        Кроме того, в соответствии с Законом о защите данных от 6 января 1978
        года, Клиент всегда имеет право ставить под сомнение, получать доступ,
        исправлять, изменять и возражать против всех своих персональных данных,
        письменно, по почте и оправдывая свою личность на следующий адрес:
        contact@walloftraders.com.
        <br />
        Эти персональные данные необходимы для обработки Заказа и подготовки его
        счетов в случае необходимости, а также для улучшения функциональности
        Сайта.
        <br />
        <h3>
          <b>12. Обмен собранными данными</b>
        </h3>
        <br />
        Сайт может использовать сторонние компании для выполнения определенных
        операций. Просматривая Веб-сайт, Клиент соглашается с тем, что третьи
        стороны могут иметь доступ к его данным, чтобы обеспечить надлежащее
        функционирование Сайта.
        <br />
        Эти сторонние компании имеют доступ к собранным данным только в
        контексте выполнения определенной задачи.
        <br />
        Сайт несет ответственность за обработку этих данных.
        <br />
        Кроме того, Пользователь может быть обязан получать информацию или
        коммерческие предложения от Компании или ее партнеров.
        <br />
        Пользователь может в любое время выступить против получения этих
        коммерческих предложений, написав по адресу, указанному выше, или нажав
        на ссылку, указанную для этой цели, в полученных электронных письмах.
        <br />
        Кроме того, информация Клиентов может передаваться третьим лицам без их
        предварительного предварительного согласия для достижения следующих
        целей:
        <br />
        <ul>
          <li>для исполнения закона</li>
          <li>
            защитить кого-либо от серьезных телесных повреждений или смерти
          </li>
          <li>
            бороться с мошенничеством или ущербом компании или ее пользователям
          </li>
          <li>защита имущественных прав Компании.</li>
        </ul>
        <h3>
          <b>13. Data protection</b>
        </h3>
        <br />
        The Company ensures a level of security that is appropriate and
        proportional to the risks incurred and their probability, in accordance
        with the General Data Protection Regulation 2016/679 of 27 April 2016.
        <br />
        However, these measures are in no way a guarantee and do not commit the
        Company to an obligation of result concerning the security of the data.
        <br />
        <h3>
          <b>13. Защита данных</b>
        </h3>
        <br />
        Клиент или Пользователь принимает на себя риски, связанные с
        криптоактивами и их очень высокой волатильностью, и, в частности, риски,
        связанные с блокчейном, в котором совершаются транзакции. Не существует
        законодательства, регулирующего блокчейн, который является независимой и
        децентрализованной сетью. Клиент или Пользователь понимают, что ошибки,
        сбои или кражи могут возникнуть в блокчейне и привести к потере
        вложенных средств.
        <br />
        Инвестированные средства хранятся на биржах, и эти же биржи исполняют
        заявки на покупку и продажу этих фондов на рынке криптоактивов. Эти
        биржи не являются банками. Если они были украдены или обанкротились,
        юридическая защита не покрывает ваши убытки. Wall Of Traders не несет
        ответственности за любые убытки, вызванные этими Биржами. Кроме того,
        ликвидность этих платформ не гарантируется. Чистое и простое
        исчезновение крипто-актива возможно.
        <br />
        Взаимодействие и обмен данными между веб-сайтом компании Wall Of Traders
        и этими биржами осуществляется через ключ API. Вводя ключ API на
        веб-сайте Wall Of Traders, Клиент или Пользователь принимает на себя
        риск потери или кражи этих ключей API и вытекающих из этого последствий,
        включая тот факт, что вор может манипулировать кошельком Клиента или
        Пользователя. покупая его по высокой цене и перепродавая по более низкой
        цене, что приводит к потере стоимости клиентского или пользовательского
        портфеля крипто-активов..
        <br />
        Можно скопировать стратегии выбранных Компанией трейдеров. Тем не менее,
        мы не гарантируем результаты, качество, профессионализм или благие
        намерения трейдера. Прошлые результаты трейдера не могут предрешать его
        будущие результаты.
        <br />
        <h3>
          <b>15. Сбор данных</b>
        </h3>
        <br />
        Wall Of Traders не собирает личные данные непосредственно о своих
        пользователях. Никакая личная информация не хранится на наших серверах.
        Если вы хотите зарегистрироваться для получения обновлений по
        электронной почте, единственными собранными данными будут ваше имя,
        адрес электронной почты и страница, на которой вы заполнили форму. Эта
        информация хранится непосредственно на серверах SoYouStart, дочерней
        компании OVH. Адрес электронной почты не будет отправлен третьей стороне
        без разрешения подписчика.
        <br />
        <h3>
          <b>16. Язык</b>
        </h3>
        <br />
        В случае перевода данных Условий на другие языки, основным языком будет
        французский язык
        <br />
        <h3>
          <b>17. Cookies</b>
        </h3>
        <br />
        Чтобы дать своим Пользователям возможность пользоваться оптимальной
        навигацией на Сайте и лучше функционировать различными интерфейсами и
        приложениями, Компания может разместить cookie-файл на компьютере
        Пользователя. Этот файл cookie позволяет хранить информацию, касающуюся
        навигации по Сайту, а также любые данные, вводимые Пользователями
        (включая поиски, логин, электронную почту, пароль).
        <br />
        Пользователь прямо уполномочивает Компанию поместить на жесткий диск
        пользователя так называемый файл «cookie».
        <br />
        Пользователь имеет возможность заблокировать, изменить срок хранения или
        удалить этот файл cookie через интерфейс своего браузера. Если
        систематическая деактивация файлов cookie в браузере Пользователя не
        позволяет ему использовать определенные услуги или функции Сайта, эта
        неисправность никоим образом не может нанести ущерб участнику, который в
        результате не может требовать какой-либо компенсации.
        <br />
        <h3>
          <b>18. Изменения</b>
        </h3>
        <br />
        Компания оставляет за собой право изменять Сайт, предлагаемые на нем
        услуги, Условия и положения, а также любые процедуры доставки или другие
        компоненты услуг, предоставляемых Компанией через Сайт. При размещении
        Заказа Пользователь руководствуется положениями, изложенными в Условиях,
        действующих при размещении Заказа.
        <br />
        When placing an Order, the User is subject to the stipulations set out
        in the Terms and Conditions in force when placing the Order.
        <br />
        <h3>
          <b>19. Ответственность</b>
        </h3>
        <br />
        Компания не может ни при каких обстоятельствах нести ответственность за
        недоступность, временную или постоянную, Веб-сайта, и, хотя она
        использует все средства для обеспечения обслуживания в любое время,
        может случиться так, что он будет прерван в любое время. Кроме того,
        Компания оставляет за собой право добровольным действием сделать Сайт
        недоступным для выполнения каких-либо операций по обновлению, улучшению
        или обслуживанию.
        <br />
        Как упоминалось ранее, Компания не может быть привлечена к
        ответственности за задержки в оказании услуг по независящим от него
        причинам, непредвиденным и непреодолимым.
        <br />
        <h3>
          <b>20. Интеллектуальная собственность</b>
        </h3>
        <br />
        Бренд, логотип и графика на этом Сайте являются товарными знаками,
        зарегистрированными в INPI, и охраняются интеллектуальной
        собственностью, собственность которая является исключительной
        собственностью Компании. Любое распространение, эксплуатация,
        представление, воспроизведение, будь то частичное или полное без явного
        разрешения Компании, подвергнет нарушителя гражданскому и уголовному
        преследованию.
        <br />
        <h3>
          <b>21. Статья о юрисдикции</b>
        </h3>
        <br />
        Закон, регулирующий правила и условия, является французским
        законодательством. Любой спор, который может возникнуть между Компанией
        и Пользователем во время исполнения настоящего, будет предметом попытки
        решить по-дружески. В противном случае споры будут доведены до сведения
        компетентных судов общего права.
        <br />
        Клиент информирован о том, что он может прибегнуть к традиционному
        посредничеству с Комиссией по посредничеству потребителей,
        предусмотренной в статье L534-7 Кодекса потребителей, или с
        существующими отраслевыми органами посредничества. Он также может
        использовать любую альтернативную процедуру разрешения споров в случае
        возникновения спора.
        <br />
        <h3>
          <b>22. Преддоговорная информация</b>
        </h3>
        <br />
        До своего Заказа Клиент подтверждает, что имел общение, понятое и
        понятное из Условий и положений, а также информацию и информацию,
        предусмотренную в Статьях L111-1 - L111-7 Потребительского Кодекса, и, в
        частности:
        <br />
        <ul>
          <li>основные характеристики Услуг;</li>
          <li>цена Услуг;</li>
          <li>
            дата или время, когда Компания обязуется предоставлять Услугу;
          </li>
          <li>
            информация, касающаяся личности Компании (почтовые, телефонные и
            электронные контактные данные); информация о юридических и
            договорных гарантиях и о том, как они выполняются; возможность
            использования обычного посредничества в случае судебных
            разбирательств; информация, касающаяся права на отзыв (сроки, методы
            осуществления).
          </li>
        </ul>
        <br />
        Размещение Заказа на Сайте влечет за собой присоединение и принятие
        Условий. Заказчик не сможет рассчитывать на противоречивый документ.
        <br />
      </div>
    );
  }
}
