import React from 'react';
import {
  SortingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  IntegratedPaging,
  PagingState,
  SummaryState,
  IntegratedSummary,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  PagingPanel,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { createExchangesGridAction } from '../../redux/config/actions';
import Card from '../../components/Card/Card';
import { ColorPourcentageProvider, TokenProvider } from '../../helpers/table';
import { getActiveCode } from '../../helpers/localize';
import { KeyExchangeTable, parseQty } from '../../helpers/exchange';
import Image from '../../components/Image/Image';
import withWidth from '@material-ui/core/withWidth';
import { nbColumn } from '../../helpers/general';

class TableUIExchange extends React.PureComponent {
  render() {
    const {
      columnWidths,
      colorColumns,
      rows,
      columns,
      filters,
      currentPage,
      pageSize,
      pageSizes,
      sorting,
      onFiltersChange,
      onPageSizeChange,
      onCurrentPageChange,
      onSortingChange,
      onColumnWidthsChange,
    } = this.props;

    const columnsFiltered = columns.filter(
      (x) =>
        !Object.hasOwn(x, 'showSize') ||
        x.showSize <= nbColumn(this.props.width),
    );

    return (
      <Card>
        <Grid rows={rows} columns={columnsFiltered}>
          <FilteringState filters={filters} onFiltersChange={onFiltersChange} />
          <SortingState
            defaultSorting={sorting}
            sorting={sorting}
            onSortingChange={onSortingChange}
          />
          <PagingState
            defaultCurrentPage={currentPage}
            defaultPageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            onCurrentPageChange={onCurrentPageChange}
          />
          <SummaryState
            totalItems={[
              { columnName: KeyExchangeTable.POURCENTAGE.T, type: 'sum' },
              { columnName: KeyExchangeTable.TOTAL_USD.T, type: 'sum' },
            ]}
          />
          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          <IntegratedSummary />

          <ColorPourcentageProvider for={colorColumns} />
          <TokenProvider for={[KeyExchangeTable.TOKEN.T]} />

          <Table />
          <TableColumnResizing
            defaultColumnWidths={columnWidths}
            columnWidths={columnWidths}
            onColumnWidthsChange={onColumnWidthsChange}
          />
          <TableHeaderRow showSortingControls={true} />
          <TableFilterRow showFilterSelector={true} />
          <TableSummaryRow />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { config } = state;
  const { exchangesGrid } = config;
  const {
    filters,
    pageSizes,
    columnOrder,
    columnWidths,
    sorting,
    colorColumns,
  } = exchangesGrid;
  const codeLang = getActiveCode(state);
  const exchanges = props.exchange ? props.exchange : [];
  let ret = {
    colorColumns: colorColumns,
    codeLang: codeLang,
    rows: exchanges,
    columnOrder: columnOrder,
    columnWidths: columnWidths,
    filters: filters,
    pageSizes: pageSizes,
    sorting: sorting,
    columns: [
      {
        name: KeyExchangeTable.TOKEN.T,
        title: <Translate id={'exchanges.' + KeyExchangeTable.TOKEN.T} />,
        getCellValue: (row) => {
          return row[KeyExchangeTable.TOKEN.K];
        },
      },
      {
        name: KeyExchangeTable.AMOUNT.T,
        title: <Translate id={'exchanges.' + KeyExchangeTable.AMOUNT.T} />,
        getCellValue: (row) => {
          return row[KeyExchangeTable.AMOUNT.K];
        },
      },
      {
        showSize: 4,
        name: KeyExchangeTable.LOCKED.T,
        title: <Translate id={'exchanges.' + KeyExchangeTable.LOCKED.T} />,
        getCellValue: (row) => {
          return parseQty(parseFloat(row[KeyExchangeTable.LOCKED.K]));
        },
      },
      {
        showSize: 4,
        name: KeyExchangeTable.LOCKEDONWOT.T,
        title: <Translate id={'exchanges.' + KeyExchangeTable.LOCKEDONWOT.T} />,
        getCellValue: (row) => {
          return parseQty(parseFloat(row[KeyExchangeTable.LOCKEDONWOT.K]));
        },
      },
      {
        showSize: 4,
        name: KeyExchangeTable.AVAILABLE.T,
        title: <Translate id={'exchanges.' + KeyExchangeTable.AVAILABLE.T} />,
        getCellValue: (row) => {
          return parseQty(
            parseFloat(row[KeyExchangeTable.AMOUNT.K]) -
              parseFloat(row[KeyExchangeTable.LOCKED.K]) -
              parseFloat(row[KeyExchangeTable.LOCKEDONWOT.K]),
          );
        },
      },
      {
        name: KeyExchangeTable.PRICE_USD.T,
        title: <Translate id={'exchanges.' + KeyExchangeTable.PRICE_USD.T} />,
        getCellValue: (row) => {
          return row[KeyExchangeTable.PRICE_USD.K];
        },
      },
      {
        name: KeyExchangeTable.TOTAL_USD.T,
        title: <Translate id={'exchanges.' + KeyExchangeTable.TOTAL_USD.T} />,
        getCellValue: (row) => {
          return row[KeyExchangeTable.TOTAL_USD.K];
        },
      },
      {
        name: KeyExchangeTable.POURCENTAGE.T,
        title: KeyExchangeTable.POURCENTAGE.T,
        getCellValue: (row) => {
          return row[KeyExchangeTable.POURCENTAGE.K];
        },
      },
      {
        showSize: 2,
        name: KeyExchangeTable.POURC_24H.T,
        title: KeyExchangeTable.POURC_24H.T,
        getCellValue: (row) => {
          return row[KeyExchangeTable.POURC_24H.K];
        },
      },
      {
        showSize: 2,
        name: KeyExchangeTable.POURC_7D.T,
        title: KeyExchangeTable.POURC_7D.T,
        getCellValue: (row) => {
          return row[KeyExchangeTable.POURC_7D.K];
        },
      },
      {
        showSize: 3,
        name: KeyExchangeTable.URL_CHARTS.T,
        title: <Translate id={'exchanges.' + KeyExchangeTable.URL_CHARTS.T} />,
        getCellValue: (row) => {
          if (!row[KeyExchangeTable.URL_CHARTS.K]) return '';
          return <Image src={row[KeyExchangeTable.URL_CHARTS.K]} />;
        },
      },
      {
        showSize: 3,
        name: KeyExchangeTable.URL_CHARTS7.T,
        title: <Translate id={'exchanges.' + KeyExchangeTable.URL_CHARTS7.T} />,
        getCellValue: (row) => {
          if (!row[KeyExchangeTable.URL_CHARTS7.K]) return '';
          return <Image src={row[KeyExchangeTable.URL_CHARTS7.K]} />;
        },
      },
    ],
  };
  return ret;
};

const mapDispatchToProps = (dispatch) => ({
  onSortingChange: (sorting) =>
    dispatch(createExchangesGridAction('sorting', sorting)),
  onSelectionChange: (selection) =>
    dispatch(createExchangesGridAction('selection', selection)),
  onExpandedRowIdsChange: (expandedRowIds) =>
    dispatch(createExchangesGridAction('expandedRowIds', expandedRowIds)),
  onGroupingChange: (grouping) =>
    dispatch(createExchangesGridAction('grouping', grouping)),
  onExpandedGroupsChange: (expandedGroups) =>
    dispatch(createExchangesGridAction('expandedGroups', expandedGroups)),
  onFiltersChange: (filters) =>
    dispatch(createExchangesGridAction('filters', filters)),
  onCurrentPageChange: (currentPage) =>
    dispatch(createExchangesGridAction('currentPage', currentPage)),
  onPageSizeChange: (pageSize) =>
    dispatch(createExchangesGridAction('pageSize', pageSize)),
  onColumnOrderChange: (order) =>
    dispatch(createExchangesGridAction('columnOrder', order)),
  onColumnWidthsChange: (widths) =>
    dispatch(createExchangesGridAction('columnWidths', widths)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(TableUIExchange));
