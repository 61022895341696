import React from 'react';
import Card from '../../../components/Card/Card.jsx';
import {
  TreeDataState,
  SortingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';

import connect from 'react-redux/es/connect/connect';
import {
  getColumnTabStats,
  getDefaultColumnWidthsTabStats,
} from '../../../helpers/order';
import {
  ColorPourcentageProvider,
  NameGroupProvider,
} from '../../../helpers/table';
import { isNull } from '../../../helpers/Common';

class TableStatsGroup extends React.PureComponent {
  constructor(props) {
    super(props);

    let columns = [
      {
        name: 'Name',
        title: 'Name',
        getCellValue: (row) => {
          return isNull(row['Group']) ? '' : row['Group'].Name;
        },
      },
      {
        name: 'IsActive',
        title: 'Actif',
        getCellValue: (row) => {
          return isNull(row['Group'])
            ? ''
            : row['Group'].IsActive
            ? 'true'
            : 'false';
        },
      },
    ];
    columns = columns.concat(getColumnTabStats());

    let defaultColumnWidths = [
      { columnName: 'Name', width: 150 },
      { columnName: 'IsActive', width: 70 },
    ];
    defaultColumnWidths = defaultColumnWidths.concat(
      getDefaultColumnWidthsTabStats(),
    );

    this.state = {
      //table
      columns: columns,
      id: 1,
      rows: props.statsGroup,
      pageSizes: [5, 10, 20, 50, 0],
      colorColumns: [
        'ProfitTotal',
        'ProfitCapital',
        'PourcentageSuccess',
        'ProfitPerTrade',
        'ProfitPerTradeCapital',
      ],
      defaultColumnWidths: defaultColumnWidths,
      filters: [],
    };
    this.changeFilters = (filters) => this.setState({ filters });
  }

  componentDidUpdate() {
    this.setState({ rows: this.props.statsGroup });
  }

  render() {
    const { rows, columns, defaultColumnWidths, filters, colorColumns } =
      this.state;

    return (
      <Card>
        <Grid rows={rows} columns={columns}>
          <FilteringState
            filters={filters}
            onFiltersChange={this.changeFilters}
          />
          <SortingState
            defaultSorting={[
              { columnName: 'LastDateOrder', direction: 'desc' },
            ]}
          />
          <PagingState defaultCurrentPage={0} defaultPageSize={20} />
          <TreeDataState />

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />

          <NameGroupProvider for={['Name']} />
          <ColorPourcentageProvider for={colorColumns} />

          <Table />
          <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />

          <TableHeaderRow showSortingControls={true} />
          <TableFilterRow />
          <PagingPanel pageSizes={[5, 10, 20, 50, 0]} />
        </Grid>
        {/*{rows && <pre>{JSON.stringify(rows, null, 2)}</pre>}*/}
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TableStatsGroup);
