import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { bindActionCreators } from 'redux';
// import { getSLProfitFromPrice, getSLPriceFromProfit } from '.../../helpers/Common';
import { getTranslate, Translate } from 'react-localize-redux';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import SwitchUI from '@material-ui/core/Switch/Switch';
import Button from '../../../components/CustomButtons/Button.jsx';
import GroupsContainer from './GroupsContainer';
import People from '@material-ui/icons/People';
import { getMyGroupsSelect } from '../../../helpers/Common';

class TraderContainer extends React.PureComponent {
  componentDidUpdate = (prevProps, prevState) => {};

  toggleLock = () => {
    const { formName, change, followersLock, groups, dontDoOrder } = this.props;
    if (groups.length > 0 && followersLock)
      change(formName, 'groupId', groups[0]);
    else {
      if (dontDoOrder) change(formName, 'dontDoOrder', false);
      change(formName, 'groupId', null);
    }

    change(formName, 'followersLock', !followersLock);
  };

  render() {
    const { formName, followersLock, groups, isUpdate, isTemplate } =
      this.props;
    if (!groups || groups.length === 0) return <div />;
    const isUpdatable = isUpdate && !isTemplate;
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card className="smart-trade-bloc">
          <CardBody>
            <fieldset
              disabled={followersLock}
              className={followersLock ? 'disabled' : ''}
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Translate id="smartTrade.setFollowers" />
                  <SwitchUI
                    checked={!followersLock}
                    onChange={() => this.toggleLock()}
                    color="primary"
                    disabled={isUpdatable}
                  />
                </GridItem>
                <GroupsContainer formName={formName} />
              </GridContainer>
            </fieldset>
          </CardBody>
          {followersLock && (
            <div className="active-block">
              <Button
                color="primary"
                style={{ width: '200px' }}
                onClick={() => this.toggleLock()}
                disabled={!groups || groups.length === 0 || isUpdatable}
              >
                <People style={{ margin: '4px' }} />
                <Translate id="smartTrade.setFollowers" />
              </Button>
            </div>
          )}
        </Card>
      </GridItem>
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  const { localize } = state;
  return {
    translate: getTranslate(localize),
    // values: getFormValues(props.formName)(state),
    followersLock: formSelector(state, 'followersLock'),
    groups: getMyGroupsSelect(state),
    isUpdate: formSelector(state, 'isUpdate'),
    isTemplate: formSelector(state, 'isTemplate'),
    dontDoOrder: formSelector(state, 'dontDoOrder'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TraderContainer);
