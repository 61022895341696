import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { alertActions } from '../../redux/alert/alert.actions';
import withStyles from '@material-ui/core/styles/withStyles';
// import AddAlert from '@material-ui/core/SvgIcon/SvgIcon';
import Snackbar from '../../components/Snackbar/Snackbar.jsx';
import { alertConstants } from '../../redux/alert/alert.constants';

const styles = {};

class AlertNotification extends React.Component {
  constructor(props) {
    super(props);

    // const { dispatch } = this.props;

    this.state = {
      tl: false,
      tc: false,
      tr: false,
      bl: false,
      bc: false,
      br: false,
      type: 'info',
      message: '',
    };
    this.alertTimeout = null;
  }
  componentWillUnmount() {
    this.clearAlertTimeout();
  }
  clearAlertTimeout = () => {
    if (this.alertTimeout !== null) {
      clearTimeout(this.alertTimeout);
    }
  };

  componentDidUpdate() {
    const { alert } = this.props;

    if (alert.type && alert.type !== alertConstants.ALERT) {
      this.showNotification('tc');
    }
  }

  showNotification = (place) => {
    const { dispatch, alert } = this.props;

    let x = [];
    x[place] = true;
    this.setState(x);
    this.setState({
      message: alert.message,
      type: alert.type,
    });

    dispatch(alertActions.clear());

    this.clearAlertTimeout();
    this.alertTimeout = setTimeout(
      function () {
        x[place] = false;
        this.setState(x);
      }.bind(this),
      alert.duration,
    );
  };
  render() {
    return (
      <div>
        <Snackbar
          place="tc"
          color={this.state.type}
          // icon={AddAlert}
          message={this.state.message}
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
          // ContentProps={{
          //   // height: 'auto',
          //   // lineHeight: '28px',
          //   // padding: 24,
          //   whiteSpace: 'pre-wrap'
          // }}
        />
      </div>
    );
  }
}

AlertNotification.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { alert } = state;
  return {
    alert,
  };
};

export default withStyles(styles)(connect(mapStateToProps)(AlertNotification));
