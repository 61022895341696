import React from 'react';

export default class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  handleError = (e) => {
    this.setState({ show: false });
  };

  render() {
    const { show } = this.state;
    const { src } = this.props;
    return (
      <div>
        {show && (
          <img src={src} alt="chart" onError={(e) => this.handleError(e)} />
        )}
      </div>
    );
  }
}
