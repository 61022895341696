import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '../../components/CustomButtons/Button.jsx';
import CustomInput from '../../components/CustomInput/CustomInput';
import { bindActionCreators } from 'redux';
import {
  postSubscriptionGroup,
  postUnSubscribeGroup,
} from '../../redux/trader/actions';
import connect from 'react-redux/es/connect/connect';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import { Translate, getTranslate } from 'react-localize-redux';
import { getActiveCode } from '../../helpers/localize';
import DialogPaiement from '../Paiement/DialogPaiement';
import { getDateStr, isTradable } from '../../helpers/general';
import SwitchUI from '@material-ui/core/Switch/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import { getSubGroup } from '../../helpers/trader';
import { postPaiement } from '../../redux/trader/actions';
import { tradersSelector } from '../../redux/trader/selector';
import { userIdSelector } from '../../redux/authentication/selector';
import { Link } from 'react-router-dom';
import { Create } from '@material-ui/icons';
import { showPopupOptionsPaiementDispatch } from '../../redux/alert/alert.actions';
import { loggedInSelector } from '../../redux/authentication/selector';
import { redirectToLogin } from '../../redux/authentication/actions';
import {
  getSimpleExchangesSelect,
  isEmpty,
  isNull,
} from '../../helpers/Common.js';
import Helper from '../../components/Hover/Helper.jsx';
import { pushGroups } from '../../redux/user/actions.js';
import ReactSelectUI from '../../components/Form/ReactSelectUI.jsx';
import { getExchangeExEnum } from '../../helpers/order.js';
import WarningIcon from '@material-ui/icons/Warning';
import { warningColor } from '../../assets/jss/material-dashboard-react.jsx';

const styles = {
  inputPourc: {
    marginTop: 0,
  },
  main: {
    // width: '20%'
  },
};

class FollowContainer extends React.Component {
  constructor(props) {
    super(props);
    const subGroup = getSubGroup(this.props.trader);
    // take the first exchange of the user exchanges list that match the trader exchange name
    let exchange = this.props.userExchanges.find(
      (e) => getExchangeExEnum(e.exchange) === this.props.trader.ExchangeName,
    );
    const exchangeId = !isEmpty(this.props.trader.SubscriptionGroups)
      ? this.props.trader.SubscriptionGroups[0].ExchangeId
      : null;
    if (!isNull(exchangeId))
      exchange = this.props.userExchanges.find((e) => e.value === exchangeId);
    if (isNull(exchange))
      exchange = this.props.userExchanges.find(
        (e) => getExchangeExEnum(e.exchange) === this.props.trader.ExchangeName,
      );
    this.state = {
      pourcentage: subGroup === null ? 0 : subGroup.Pourcentage,
      autoOrder: subGroup !== null ? subGroup.AutoAcceptOrder : true,
      groupId: this.props.trader ? this.props.trader.GroupId : null,
      open: false,
      exchange: isNull(exchange) ? this.props.userExchanges[0] : exchange,
    };
  }
  copyTrader = (groupId) => {
    const { translate } = this.props;
    const { pourcentage } = this.state;
    if (
      !window.confirm(
        translate('traders.followTrader') +
          translate('traders.withPourc', { pourcentage }),
      )
    )
      return;
    const body = {
      GroupId: groupId,
      Pourcentage: pourcentage,
    };
    this.props.postSubscriptionActions(body);
  };

  toggleAuto = () => {
    const { autoOrder, groupId } = this.state;
    const { translate } = this.props;

    const newAuto = !autoOrder;
    const mode = newAuto
      ? translate('traders.fullAuto')
      : translate('traders.semiAuto');
    if (
      !window.confirm(
        translate('traders.followTrader') +
          translate('traders.inMode', { mode }),
      )
    )
      return;
    this.setState({ autoOrder: newAuto });
    const body = {
      GroupId: groupId,
      AutoAcceptOrder: newAuto,
    };
    this.props.postSubscriptionActions(body);
  };

  unsubscribe = (groupId) => {
    const { translate } = this.props;
    if (!window.confirm(translate('traders.unsubscribeTrader'))) return;
    this.props.postUnSubscribeActions(groupId);
  };

  onChange = (e) => {
    if (e.target.value < 0) e.target.value = 0;
    else {
      const { groups, trader } = this.props;
      let groupsCopy = { ...groups };
      groupsCopy[trader.GroupId] = e.target.value;
      this.props.pushGroupsActions(groupsCopy);
      this.setState({ pourcentage: e.target.value });
    }
  };

  onCloseDialog = () => {
    this.setState({ open: false });
  };

  updateExchangeSelect = (newExchange) => {
    if (isNull(newExchange)) return;
    const { groupId } = this.state;
    this.setState({ exchange: newExchange });
    const body = {
      GroupId: groupId,
      ExchangeId: newExchange.value,
    };
    // console.log(body)
    this.props.postSubscriptionActions(body);
  };

  render() {
    const { open, autoOrder, pourcentage, exchange } = this.state;
    const {
      translate,
      classes,
      trader,
      codeLang,
      traders,
      userId,
      isTradable,
      loggedIn,
      userExchanges,
    } = this.props;
    if (isNull(pourcentage)) this.setState({ pourcentage: 100 });
    const isExchangeDiff =
      !isEmpty(exchange) &&
      getExchangeExEnum(exchange.exchange) !== this.props.trader.ExchangeName;
    if (trader && userId !== '' && userId === trader.ApplicationUserId)
      return (
        <div>
          <Button
            color="info"
            fullWidth
            to={'/updateTrader/' + trader.GroupId}
            component={Link}
          >
            <Create style={{ margin: '4px' }} />
            <Translate id="traders.updateMyGroup" />
          </Button>
          <Translate id="traders.referenceForFollower" /> : <br />
          <b>{trader.Reference}</b>
          <br />
          <Button
            color="info"
            fullWidth
            to={'/manageFollowers/' + trader.GroupId}
            component={Link}
          >
            <Create style={{ margin: '4px' }} />
            <Translate id="traders.manageFollowers" />
          </Button>
        </div>
      );
    const subGroup = getSubGroup(trader);
    const wotPaiement = getSubGroup(traders.find((x) => x.GroupId === 1));
    const wotEndDate = wotPaiement ? wotPaiement.EndDate : null;
    let endDate = subGroup ? subGroup.EndDate : null;
    if (endDate === null || wotEndDate < endDate) {
      endDate = wotEndDate;
    }
    let groupsDialogPaiement = [trader.GroupId];
    if (!isTradable) groupsDialogPaiement.push(1);

    // if (!subGroup)
    //   return (
    //     <div>
    //       {open && (
    //         <DialogPaiement
    //           groups={groupsDialogPaiement}
    //           onClose={this.onCloseDialog}
    //         />
    //       )}
    //       <Button
    //         color="info"
    //         position="center"
    //         onClick={() => {
    //           if (!loggedIn) {
    //             this.props.loginActions();
    //             return;
    //           }

    //           if (!isTradable) {
    //             this.props.showPopupOptionsPaiementActions();
    //             return;
    //           }

    //           if (trader.Price === null) {
    //             const post = {
    //               Groups: [{ NbMonth: 0, GroupId: trader.GroupId }],
    //             };
    //             this.props.postPaiementActions(post);
    //           } else {
    //             this.setState({ open: true });
    //           }
    //         }}
    //         style={{ width: '100%' }}
    //       >
    //         <Translate id="traders.copyThisTrader" />
    //       </Button>
    //     </div>
    //   );
    const isActive = subGroup && subGroup.Pourcentage > 0;
    return (
      <div className={classes.main}>
        {isActive && (
          <div>
            <span>
              <Translate id="traders.registerUntil" />{' '}
              {getDateStr(endDate, codeLang)}
            </span>
            <br />
            <span>
              <Translate id="traders.with" /> {subGroup.Pourcentage}%
            </span>
            <br />
            <InputLabel style={{ fontSize: '0.75rem' }}>
              <Translate id="traders.semiAuto" />
              <SwitchUI
                checked={autoOrder}
                onChange={() => {
                  this.toggleAuto();
                  // this.setState({ autoRefresh: !this.state.autoRefresh });
                }}
                color="primary"
              />
              <Translate id="traders.fullAuto" />
            </InputLabel>
            {isEmpty(userExchanges) && (
              <>
                <Translate id="central.noExchange.mustAdd" />.{' '}
                <Link
                  to="/exchanges"
                  className={
                    'link-text ' +
                    (this.props.theme === 'dark' ? 'dark' : 'light')
                  }
                >
                  <Translate id="central.noExchange.here" />
                </Link>
              </>
            )}
            {!isEmpty(userExchanges) && (
              <>
                <ReactSelectUI
                  options={userExchanges}
                  isClearable={false}
                  input={{
                    value: exchange,
                    onChange: (e) => {
                      this.updateExchangeSelect(e);
                    },
                  }}
                />
                {isExchangeDiff && (
                  <div className="icons-text" style={{ color: warningColor }}>
                    <WarningIcon /> <Translate id="traders.accountDiff" />
                  </div>
                )}
              </>
            )}
            <Button
              color="info"
              position="center"
              onClick={() => {
                this.unsubscribe(subGroup.SubscriptionGroupId);
              }}
              style={{ width: '100%' }}
            >
              <Translate id="traders.unsubscription" />
            </Button>
          </div>
        )}
        {!isActive && (
          <div>
            {open && (
              <DialogPaiement
                groups={groupsDialogPaiement}
                onClose={this.onCloseDialog}
              />
            )}
            <Translate id="traders.choosePercentage" />
            &nbsp;
            <Helper text={<Translate id="traders.pourcentageDetails" />} />
            <CustomInput
              name={trader.GroupId + '.pourcentage'}
              label={<Translate id="central.percentage" />}
              fullWidth={true}
              inputProps={{
                type: 'number',
                onChange: this.onChange,
                className: classes.inputPourc,
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                placeholder: translate('traders.pourcentageInfos'),
              }}
            />
            <Button
              disabled={isEmpty(pourcentage) || pourcentage <= 0}
              color="info"
              position="center"
              onClick={() => {
                if (isEmpty(pourcentage) || pourcentage <= 0) return;
                if (!loggedIn) {
                  this.props.loginActions();
                  return;
                }
                if (!isTradable) {
                  this.props.showPopupOptionsPaiementActions();
                  return;
                }
                if (!subGroup) {
                  if (trader.Price === null) {
                    const post = {
                      Groups: [
                        {
                          NbMonth: 0,
                          GroupId: trader.GroupId,
                          Percentage: this.state.pourcentage,
                        },
                      ],
                    };
                    this.props.postPaiementActions(post);
                  } else {
                    this.setState({ open: true });
                  }
                } else {
                  this.copyTrader(trader.GroupId);
                }
                // if (!isTradable) {
                //   this.props.showPopupOptionsPaiementActions();
                //   return;
                // }
                // this.copyTrader(trader.GroupId);
              }}
              style={{ width: '100%' }}
            >
              <Translate id="traders.copyThisTrader" />
            </Button>
          </div>
        )}
      </div>
    );
  }
}

FollowContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { localize, config } = state;
  return {
    theme: config.theme,
    traders: tradersSelector(state),
    translate: getTranslate(localize),
    codeLang: getActiveCode(state),
    loggedIn: loggedInSelector(state),
    isTradable: isTradable(state),
    userId: userIdSelector(state),
    userExchanges: getSimpleExchangesSelect(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postSubscriptionActions: bindActionCreators(
      postSubscriptionGroup,
      dispatch,
    ),
    showPopupOptionsPaiementActions: bindActionCreators(
      showPopupOptionsPaiementDispatch,
      dispatch,
    ),
    postUnSubscribeActions: bindActionCreators(postUnSubscribeGroup, dispatch),
    postPaiementActions: bindActionCreators(postPaiement, dispatch),
    loginActions: bindActionCreators(redirectToLogin, dispatch),
    pushGroupsActions: bindActionCreators(pushGroups, dispatch),
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(FollowContainer);

export default withStyles(styles)(connected);
