import React from 'react';
import { Translate } from 'react-localize-redux';
import { isRequired } from '../../../helpers/form';

export default (values) => {
  const errors = {};
  const requiredFields = ['name'];
  const { name } = values;

  for (const field of requiredFields) {
    if (isRequired(values[field])) {
      errors[field] = <Translate id="central.required" />;
    }
  }

  const minAll = 5;

  const lengthName = name && name.length;
  const minName = minAll;
  const maxName = 25;
  if (lengthName < minName || lengthName > maxName)
    errors.name = (
      <Translate id="error.between" data={{ min: minName, max: maxName }} />
    );

  return errors;
};
