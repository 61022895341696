import { loadingConstants } from './loading.constants';

export const loadingActions = {
  loadingOn,
  loadingOff,
};

function loadingOn(message) {
  return { type: loadingConstants.LOADING_ON, message };
}

function loadingOff(message) {
  return { type: loadingConstants.LOADING_OFF, message };
}
