import React from 'react';
import '../../assets/css/coinbase-commerce-button.css';
import IFrame from './IFrame';
import { change, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const endFunction = (messageData) => {
  // console.log(messageData);
};

const successFunction = (messageData) => {};

class Coinbase extends React.PureComponent {
  handleModalClose = () => {
    this.props.change(this.props.formName, 'showModal', false);
    if (this.props.onModalClosed) {
      this.props.onModalClosed();
    }
  };

  /*
   * If we experience an unexpected error,
   * log it as an error to the console and close the modal.
   */
  handleError = (msg) => {
    console.error(msg);
    this.props.change(this.props.formName, 'showModal', false);
  };

  render() {
    const { chargeId, showModal, formName } = this.props;
    if (
      !chargeId ||
      !showModal ||
      chargeId === '' ||
      typeof chargeId !== 'string'
    )
      return <div />;
    return (
      <IFrame
        formName={formName}
        onChargeSuccess={successFunction}
        onChargeFailure={endFunction}
        onPaymentDetected={endFunction}
        onModalClose={this.handleModalClose}
        onError={this.handleError}
        chargeId={chargeId}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const { trader } = state;
  const formSelector = formValueSelector(props.formName);
  return {
    chargeId: trader.paiement,
    showModal: formSelector(state, 'showModal'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Coinbase);
