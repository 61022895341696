import React from 'react';
import { Tooltip } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

const style = {
  tooltip: {
    fontSize: '14px',
  },
};

class MyTooltip extends React.PureComponent {
  render() {
    const { text, disabled, classes, children } = this.props;
    if (disabled) return <div />;
    return (
      <Tooltip title={text} enterDelay={0} classes={classes}>
        {children}
      </Tooltip>
    );
  }
}

export default withStyles(style)(MyTooltip);
