import React from 'react';
import { connect } from 'react-redux';
import Button from '../../components/CustomButtons/Button.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import GridItem from '../../components/Grid/GridItem';
import { Translate } from 'react-localize-redux';
import { currentAddTraderSelector } from '../../redux/trader/selector';
import { removeCurrentAddTrader } from '../../redux/trader/actions';
import { bindActionCreators } from 'redux';
import Trader from './Trader';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogAddTrader extends React.PureComponent {
  handleClose = () => {
    this.props.removeCurrentAddTraderActions();
  };

  render() {
    const { currentAddTrader } = this.props;
    const open = currentAddTrader !== null;
    if (currentAddTrader === null) return <div></div>;

    return (
      <Dialog
        maxWidth={false}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Translate id="traders.addTrader" />
          <IconButton
            onClick={this.handleClose}
            style={{ position: 'Absolute', right: '8px', top: '8px' }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <GridItem container>
            <Trader trader={currentAddTrader} />
          </GridItem>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="danger">
            <Translate id="central.cancel" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentAddTrader: currentAddTraderSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeCurrentAddTraderActions: bindActionCreators(
      removeCurrentAddTrader,
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogAddTrader);
