import React from 'react';
import { connect } from 'react-redux';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import { bindActionCreators } from 'redux';
import {
  getOrderInfo,
  getOrderDetail,
  IsAdmin,
  getOpenOrder,
  getOrder,
  getLogs,
} from '../../../redux/admin/actions';

import { withLocalize } from 'react-localize-redux';
import OrderContainer from '../../Orders/OrderContainer';
import transOrders from '../../../locales/orders.json';
import transSmartTrade from '../../../locales/smartTrade.json';
import GridItem from '../../../components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';
import { getAllStart } from '../../../helpers/order';
import { getBaseFrontUrl } from '../../../helpers/fetcher';

const PrettyPrintJson = ({ data }) => (
  <div>
    <pre>{JSON.stringify(data, null, 2)}</pre>
  </div>
);

class OrderInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { orders: [], clicked: false };
    this.props.addTranslation(transOrders);
    this.props.addTranslation(transSmartTrade);
  }
  componentDidMount() {
    const { match } = this.props;
    this.props.IsAdminActions();
    const id = match.params.id;
    // this.props.GetLogsActions(id);
    this.props.GetOrderActions(id);
    this.props.GetOrderInfoActions(id);
    this.props.GetOrderDetailActions(id);
    this.props.GetOpenOrderActions(id);
  }

  urlLink(endpoint, name) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="link-text dark"
        href={getBaseFrontUrl() + endpoint}
      >
        {name}
      </a>
    );
  }

  render() {
    const { logs, order, orderInfo, orderDetail, openOrder, match } =
      this.props;
    const id = match.params.id;
    const starts = getAllStart(order.Triggers);

    return (
      <div>
        <GridContainer>
          {orderDetail && (
            <textarea rows="20" style={{ width: '100%' }}>
              {orderDetail.Result}
            </textarea>
          )}
        </GridContainer>
        <GridContainer>
          {logs && (
            <textarea rows="20" style={{ width: '100%' }}>
              {logs.join('\n')}
            </textarea>
          )}
          {!logs && (
            <Button
              disabled={this.state.clicked}
              onClick={() => {
                this.props.GetLogsActions(id);
                this.setState({ clicked: true });
              }}
            >
              Charge Logs
            </Button>
          )}
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <h1>Close Orders: </h1>
            {orderInfo && orderInfo.length > 0 && (
              <PrettyPrintJson data={orderInfo} />
            )}

            <h1>Open Orders: </h1>
            {openOrder && <PrettyPrintJson data={openOrder} />}
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            {order && <div>OrderId: {order.OrderId}</div>}
            {order && (
              <div>
                BaseOrderId:{' '}
                {this.urlLink(
                  'user/order/' + order.BaseOrderId,
                  order.BaseOrderId,
                )}
              </div>
            )}
            {order && (
              <div>
                GroupId:{' '}
                {this.urlLink(
                  'manageFollowers/' + order.GroupId,
                  order.GroupId,
                )}
              </div>
            )}
            {order && (
              <div>
                AAccountId:{' '}
                {this.urlLink('exchange/' + order.AAccountId, order.AAccountId)}
              </div>
            )}
            {order && order.ApplicationUser && (
              <div>
                ApplicationUserId:{' '}
                {this.urlLink(
                  'adminwotwot2019/user/' +
                    order.ApplicationUser.Id +
                    '/orders',
                  order.ApplicationUser.Id,
                )}
              </div>
            )}
            {order && order.ApplicationUser && (
              <div>Email: {order.ApplicationUser.Email}</div>
            )}
            {order && (
              <div>
                TradingViewGuid starts:{' '}
                {starts.map((trigger, key) => (
                  <div key={key}>{trigger.TradingViewGuid}</div>
                ))}
              </div>
            )}
            <OrderContainer order={order} />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    logs: admin.logs,
    order: admin.order,
    openOrder: admin.openOrder,
    orderInfo: admin.orderInfo,
    orderDetail: admin.orderDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetLogsActions: bindActionCreators(getLogs, dispatch),
    GetOrderActions: bindActionCreators(getOrder, dispatch),
    GetOpenOrderActions: bindActionCreators(getOpenOrder, dispatch),
    GetOrderInfoActions: bindActionCreators(getOrderInfo, dispatch),
    GetOrderDetailActions: bindActionCreators(getOrderDetail, dispatch),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(OrderInfoPage),
);
