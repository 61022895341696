import React from 'react';
import Card from '../../../components/Card/Card.jsx';
import {
  TreeDataState,
  SortingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  IntegratedPaging,
  PagingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';

import connect from 'react-redux/es/connect/connect';
import { getActiveCode } from '../../../helpers/localize';

const getEmailTypeStr = (state) => {
  switch (state) {
    case 0:
      return 'ConfirmEmail';
    case 1:
      return 'ForgotPassword';
    case 2:
      return 'ConfirmAccount';
    case 3:
      return 'ConfirmPayment';
    case 4:
      return 'AboWillExpired';
    case 5:
      return 'AboHasExpired';
    case 6:
      return 'NoExchange';
    case 7:
      return 'NoTelegram';
    case 8:
      return 'NoOrder';
    case 9:
      return 'NoPercToTrader';
    case 10:
      return 'NoWOTAbo';
    default:
      return 'Unknown';
  }
};

class TableHistoricEmail extends React.PureComponent {
  constructor(props) {
    super(props);

    let columns = [
      {
        name: 'HistoricEmailId',
        title: 'Id',
      },
      {
        name: 'Email',
        title: 'Email',
      },
      {
        name: 'DateSent',
        title: 'DateSent',
      },
      {
        name: 'Success',
        title: 'Success',
        getCellValue: (row) => {
          return row['Success'] === true ? 'true' : 'false';
        },
      },
      {
        name: 'Error',
        title: 'Error',
      },
      {
        name: 'EmailType',
        title: 'EmailType',
        getCellValue: (row) => {
          return getEmailTypeStr(parseInt(row['EmailType']));
        },
      },
      {
        name: 'Variables',
        title: 'Variables',
      },
    ];

    let defaultColumnWidths = [
      { columnName: 'HistoricEmailId', width: 80 },
      { columnName: 'Email', width: 250 },
      { columnName: 'DateSent', width: 105 },
      { columnName: 'Success', width: 80 },
      { columnName: 'Error', width: 100 },
      { columnName: 'EmailType', width: 150 },
      { columnName: 'Variables', width: 450 },
    ];

    this.state = {
      //table
      columns: columns,
      id: 1,
      rows: props.historicEmail,
      pageSizes: [5, 10, 20, 50, 0],
      defaultColumnWidths: defaultColumnWidths,
      filters: [],
    };
    this.changeFilters = (filters) => this.setState({ filters });
  }

  componentDidUpdate() {
    this.setState({ rows: this.props.historicEmail });
  }

  render() {
    const { rows, columns, defaultColumnWidths, filters, pageSizes } =
      this.state;

    return (
      <Card>
        <Grid rows={rows} columns={columns}>
          <FilteringState
            filters={filters}
            onFiltersChange={this.changeFilters}
          />
          <SortingState
            defaultSorting={[{ columnName: 'DateSent', direction: 'desc' }]}
          />
          <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[1]} />
          <TreeDataState />

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />

          <Table />
          <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />

          <TableHeaderRow showSortingControls={true} />
          <TableFilterRow />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
        {/*{rows && <pre>{JSON.stringify(rows, null, 2)}</pre>}*/}
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    codeLang: getActiveCode(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TableHistoricEmail);
