import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import useIsMobileDevice from '../../hooks/useIsMobileDevice';

const style = {
  grid: {
    padding: '0 10px !important',
  },
  mobileGrid: {
    padding: '0 5px 5px 5px !important',
  },
};

function GridItem({ ...props }) {
  const { classes, children, ...rest } = props;
  const isMobile = useIsMobileDevice();
  return (
    <Grid
      item
      {...rest}
      className={isMobile ? classes.mobileGrid : classes.grid}
    >
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridItem);
