import React from 'react';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import {
  darkColor,
  darkLightColor,
  lightColor,
  lightDarkColor,
} from '../../../assets/jss/material-dashboard-react';
import { getPairSelect } from '../../../helpers/Common';

import { bindActionCreators } from 'redux';

import withStyles from '@material-ui/core/styles/withStyles';
import Button from '../../../components/CustomButtons/Button.jsx';
import { ShowPair } from '../../../components/Fields/LogoContainer';

const style = (theme) => ({
  favoriteStyle: {
    float: 'left',
    padding: '5px',
    margin: '5px',
    border: '1px',
    backgroundColor:
      theme.palette.type === 'dark' ? darkLightColor : lightDarkColor,
    color: theme.palette.type === 'dark' ? lightColor : darkColor,
  },
  // myDiv: {
  //   backgroundColor: '#00',
  //   width: '200px'
  // },
  // fillDiv: {
  //   display: 'block',
  //   height: '100%',
  //   width: '100%',
  //   textDecoration: 'none',
  //   color: 'white'
  // }
});

class PairFavoriteContainer extends React.PureComponent {
  onClickPair = () => {
    const { pair, change, formName } = this.props;
    change(formName, 'pair', getPairSelect(pair));
  };

  render() {
    const { pair, classes } = this.props;
    return (
      <Button
        className={classes.favoriteStyle}
        onClick={() => this.onClickPair()}
      >
        {ShowPair(pair)}
      </Button>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    // pair: pairSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default withStyles(style)(
  connect(mapStateToProps, mapDispatchToProps)(PairFavoriteContainer),
);
