import React, { Component } from 'react';
// import logo from './logo.svg';
// import './App.css';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';
import { isEmpty, isNull } from '../../helpers/Common';
import { createAxisAndSeries } from './chartHelper';
import connect from 'react-redux/es/connect/connect';
import { getHistoricBalances } from '../../redux/user/actions';
import { bindActionCreators } from 'redux';
import { Translate, withLocalize } from 'react-localize-redux';

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_dark);

class LineChartWallet extends Component {
  buildChart = () => {
    const { theme, historicBalances } = this.props;

    if (!historicBalances) return;
    let chart = am4core.create('chartDiv', am4charts.XYChart);

    chart.paddingRight = 20;

    let data = [];

    for (const historicBalance of historicBalances) {
      data.push({
        date: new Date(historicBalance.Date),
        usd: historicBalance.TotalUSD,
        btc: historicBalance.TotalBTC,
        eth: historicBalance.TotalETH,
      });
    }

    chart.data = data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.tooltipDateFormat = 'yyyy-MM-dd HH:mm';
    dateAxis.renderer.labels.template.fill = theme === 'dark' ? '#fff' : '#000';

    createAxisAndSeries(chart, 'usd', 'USD', false, '#85bb65');
    createAxisAndSeries(chart, 'btc', 'BTC', true, '#f2a900');
    createAxisAndSeries(chart, 'eth', 'ETH', true, '#000000');

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    // valueAxis.renderer.minWidth = 10;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = theme === 'dark' ? '#fff' : '#000';

    this.chart = chart;
  };

  componentDidMount() {
    const { id } = this.props;
    this.props.getHistoricBalancesActions(isNull(id) ? null : id);
    this.buildChart();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { historicBalances, theme } = this.props;

    if (
      prevProps.theme !== theme ||
      prevProps.historicBalances !== historicBalances
    ) {
      this.buildChart();
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { historicBalances } = this.props;
    return (
      <>
        {!isEmpty(historicBalances) && (
          <div
            id="chartDiv"
            style={{ width: '100%', height: '300px', margin: '10px 0px 0 0px' }}
          />
        )}
        {isEmpty(historicBalances) && <Translate id="central.loading" />}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { config } = state;
  return {
    theme: config.theme,
    historicBalances: state.user.historicBalances,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getHistoricBalancesActions: bindActionCreators(
      getHistoricBalances,
      dispatch,
    ),
  };
}

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(LineChartWallet),
);
