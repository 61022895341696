import Checkbox from '../Form/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import React from 'react';

export const SmallCheckBox = (props) => {
  return (
    <Checkbox
      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
      checkedIcon={<CheckBoxIcon fontSize="small" />}
      style={{ padding: 0, paddingRight: '0.25em' }}
      {...props}
    />
  );
};
