import React from 'react';
// import { Checkbox } from 'redux-form-material-ui';
import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import createComponent from './createComponent';

const renderCheckbox = ({ input, ...rest }) => {
  return (
    <Checkbox
      checked={!!input.value}
      onChange={input.onChange}
      {...input}
      {...rest}
    />
  );
};

// const renderCheckbox = ({ input, label }) => (
//   <div>
//     <FormControlLabel
//       control={
//         <Checkbox
//           checked={input.value ? true : false}
//           onChange={input.onChange}
//         />
//       }
//       label={label}
//     />
//   </div>
// )
export default renderCheckbox;
// export default Checkbox;
// export default createComponent(
//   Checkbox,
//   ({
//     input: { onChange, value, ...inputProps },
//     meta,
//     onCheck,
//     defaultChecked,
//     ...props
//   }) => ({
//     ...inputProps,
//     ...props,
//     checked: !!value,
//     onCheck: (event, isInputChecked) => {
//       onChange(isInputChecked);
//       if (onCheck) {
//         onCheck(isInputChecked);
//       }
//     }
//   })
// );
