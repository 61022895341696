import React from 'react';
import {
  change,
  Field,
  formValueSelector,
  getFormSyncErrors,
} from 'redux-form';

import GridContainer from '../../../components/Grid/GridContainer';
import Grid from '@material-ui/core/Grid';
import Price from '../../../components/Fields/Price';
import withStyles from '@material-ui/core/styles/withStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactSelect from '../../../components/Form/ReactSelectUI';
import { satoshi } from '../../../helpers/general';

const style = {
  littleGridStart: {
    paddingLeft: '15px !important',
    paddingRight: '5px !important',
  },
  littleGrid: {
    padding: '0 5px !important',
  },
  littleGridEnd: {
    paddingLeft: '5px !important',
    paddingRight: '15px !important',
  },
};

class TokensLine extends React.PureComponent {
  render() {
    const { index, classes, translate, formName, tokensSelect, isLast } =
      this.props;
    return (
      <GridContainer key={index}>
        <Grid item xs={6} sm={6} md={6} className={classes.littleGridStart}>
          <Price
            formName={formName}
            // endadornment={getSecFromPair(pair)}
            name={`${index}.Amount`}
            label={translate('externals.amount')}
            step={satoshi}
            required
            // disabled={isDone}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} className={classes.littleGridEnd}>
          <Field
            name={`${index}.Token`}
            label={<Translate id="externals.currency" />}
            options={tokensSelect}
            fullWidth={true}
            component={ReactSelect}
            required
            disabled={!isLast}
          />
        </Grid>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { localize, tokens } = state;
  const { formName } = props;
  const formSelector = formValueSelector(formName);
  return {
    isUpdate: formSelector(state, 'isUpdate'),
    translate: getTranslate(localize),
    syncErrors: getFormSyncErrors(props.formName)(state),
    tokensSelect: tokens.tokensSelect,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default withStyles(style)(
  connect(mapStateToProps, mapDispatchToProps)(TokensLine),
);
