import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate, withLocalize } from 'react-localize-redux';
import { Add, Cached, Clear, DeleteForever } from '@material-ui/icons';
import ManageFollowers from './ManageFollowers';
import { tradersSelector } from '../../redux/trader/selector';
import {
  cleanOrders,
  getFollowers,
  setFollowersHedgeMode,
} from '../../redux/trader/actions';
import { IsAdmin } from '../../redux/admin/actions';
import {
  createFollowersGridAction,
  resetFollowersColumns,
} from '../../redux/config/actions';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button.jsx';
import { isBinanceFuture, isBybitFuture, isNull } from '../../helpers/Common';
import { nbColumn } from '../../helpers/general';
import transTrader from '../../locales/traders.json';
import transCentral from '../../locales/central.json';
import transOrders from '../../locales/orders.json';
import transConfirmation from '../../locales/confirmation.json';
import transExchanges from '../../locales/exchanges.json';
import transError from '../../locales/error.json';
import { withWidth } from '@material-ui/core';
import { alertPopup } from '../../redux/alert/alert.actions';

class ManageFollowersPage extends React.Component {
  constructor(props) {
    super(props);

    this.props.addTranslation(transTrader);
    this.props.addTranslation(transCentral);
    this.props.addTranslation(transOrders);
    this.props.addTranslation(transConfirmation);
    this.props.addTranslation(transExchanges);
    this.props.addTranslation(transError);
  }

  componentDidMount() {
    const { match } = this.props;
    const groupId = match.params.id;
    this.props.getFollowersActions(groupId);
  }

  render() {
    const { traders, match } = this.props;
    const groupId = match?.params?.id;
    const trader = !isNull(traders)
      ? traders.find((x) => x.GroupId === parseInt(groupId))
      : null;

    //if (!trader) return <div />;

    const isMobile = nbColumn(this.props.width) < 2;
    let isShowTable = !isMobile;

    return (
      <GridContainer>
        <GridItem container>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: '4px' }}>
            <h1 style={{ margin: 0 }}>
              <Translate id="traders.managementOfFollowersOf" /> :{' '}
              {trader?.Name}
            </h1>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={{ marginTop: '8px', marginBottom: '-10px' }}
          >
            <Button
              color="info"
              to={'/addFollower/' + groupId}
              component={Link}
            >
              <Add style={{ paddingRight: '4px', paddingBottom: '2px' }} />
              <Translate id="traders.addFollower" />
            </Button>
            {isShowTable && (
              <>
                <Button
                  onClick={() => {
                    localStorage.removeItem('followersGrid');
                    this.props.resetColumnsActions();
                  }}
                >
                  <Cached />
                  &nbsp;
                  <Translate id="orders.resetTable" />
                </Button>
                <Button
                  onClick={() => {
                    this.props.clearFilterActions();
                  }}
                >
                  <Clear />
                  &nbsp;
                  <Translate id="orders.resetFilter" />
                </Button>
              </>
            )}
            <Button
              onClick={() => {
                this.props.alertActions({
                  type: 'confirm',
                  then: (result) => {
                    if (result.value) {
                      this.props.cleanOrdersActions(groupId);
                    }
                  },
                });
              }}
            >
              {/* <DeleteForever style={{marginBottom: '2px'}}/> */}
              <DeleteForever
                style={{ paddingRight: '4px', paddingBottom: '2px' }}
              />
              &nbsp;
              <Translate id="orders.deleteEntryError" />
            </Button>
            {!isNull(trader) &&
              (isBinanceFuture(trader.ExchangeName) ||
                isBybitFuture(trader.ExchangeName)) && (
                <Button
                  onClick={() => {
                    this.props.SetFollowersHedgeModeActions(groupId, true);
                  }}
                >
                  <Translate id="confirmation.goToHedgeModeDone" />
                </Button>
              )}
          </GridItem>
        </GridItem>
        <ManageFollowers group={trader} />
      </GridContainer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    traders: tradersSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertActions: bindActionCreators(alertPopup, dispatch),
    cleanOrdersActions: bindActionCreators(cleanOrders, dispatch),
    getFollowersActions: bindActionCreators(getFollowers, dispatch),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
    SetFollowersHedgeModeActions: bindActionCreators(
      setFollowersHedgeMode,
      dispatch,
    ),
    resetColumnsActions: () => dispatch(resetFollowersColumns()),
    clearFilterActions: () =>
      dispatch(createFollowersGridAction('filters', [])),
  };
};

export default withWidth()(
  withLocalize(
    connect(mapStateToProps, mapDispatchToProps)(ManageFollowersPage),
  ),
);
