import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Translate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import { connectAs } from '../../helpers/general';
import { remove2FA } from '../../redux/admin/actions';
import { adminUrl } from '../../routes/dashboard';
import { AccountBox, Visibility } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import LittleButton from './LittleButton';

class AdminButtons extends React.PureComponent {
  render() {
    const { user } = this.props;
    return (
      <div>
        <LittleButton
          tooltip={<Translate id="central.connectAs" />}
          color="warning"
          icon={<AccountBox />}
          onClick={() => {
            connectAs(user.Id, user.Email);
          }}
        />
        <LittleButton
          tooltip={<Translate id="exchanges.showOrders" />}
          color="info"
          icon={<Visibility />}
          to={adminUrl + '/user/' + user.Id + '/orders'}
        />
        {user.HasTFA && (
          <LittleButton
            tooltip={<Translate id="central.remove2FA" />}
            color="danger"
            icon={<FontAwesomeIcon icon={faQrcode} size="lg" />}
            onClick={() => {
              if (!window.confirm('Supprimer 2fa ?')) return;
              this.props.remove2FAActions(user.Id);
            }}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    remove2FAActions: bindActionCreators(remove2FA, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(AdminButtons);
