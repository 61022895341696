/* eslint-disable react/prop-types */
import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Moment from 'react-moment';
import Visibility from '@material-ui/icons/Visibility';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import Input from '@material-ui/core/Input';
import { Link } from 'react-router-dom';
import { Help } from '@material-ui/icons';
import {
  dangerColor,
  infoColor,
  successColor,
} from '../assets/jss/material-dashboard-react';
import { ShowExchange, ShowPair } from '../components/Fields/LogoContainer';
import BuyType from '../components/Order/BuyType';
import 'moment/locale/fr';
import { isEmpty, isNull, parseToFloat } from './Common';
import { getIconStateActions } from '../views/Orders/ActionOrderLine';
import {
  getPanicSell,
  getStart,
  getStopLoss,
  getTargets,
  getTriggerPrice,
  startNoDone,
} from './order';
import { getDateHourStr, getNumeric, satoshi } from './general';
import Hover from '../components/Hover/Hover';
import LittleButton from '../components/CustomButtons/LittleButton';
import OrderSlider from '../components/Order/OrderSlider';
import { adminUrl } from '../routes/dashboard';
import { KeyExchangeTable } from './exchange';
import Card from '../components/Card/Card';
import ReferralContainer from '../views/Referrals/ReferralContainer';
import CustomizedMenus from '../views/Referrals/CustomizedMenus';
import { FollowerButtons } from '../views/Follower/FollowerButtons';
import OrderMenuButtons from '../views/Orders/OrderMenuButtons';
import MenuButtons from '../components/CustomButtons/MenuButtons';
import GetMenuItem from './menuItems';
import { Translate } from 'react-localize-redux';
import DialogOrderInfos from '../views/Orders/DialogOrderInfos';
import { getDateToFormat } from './localize';

export const numberFilterOperations = [
  'equal',
  'notEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'lessThan',
  'lessThanOrEqual',
];

const getColor = (amount) => {
  if (amount < 0) {
    return dangerColor;
  }
  return successColor;
};

function ColorFormatter({ value }) {
  if (isEmpty(value) || value === '-') return value;
  return (
    <span style={{ color: getColor(value) }}>{parseToFloat(value, 1)} $</span>
  );
}

export function ColorUSDProvider(props) {
  return <DataTypeProvider formatterComponent={ColorFormatter} {...props} />;
}

function CrossedEmailFormatter({ value, row }) {
  if (row.IsDeleted) return <s>{value}</s>;
  return value;
}

export function CrossedEmailProvider(props) {
  return (
    <DataTypeProvider formatterComponent={CrossedEmailFormatter} {...props} />
  );
}

function PourcentageFormatter({ value }) {
  return <span>{parseToFloat(value, 0.01)}%</span>;
}

export function PourcentageProvider(props) {
  return (
    <DataTypeProvider
      formatterComponent={PourcentageFormatter}
      availableFilterOperations={numberFilterOperations}
      {...props}
    />
  );
}

function ColorPourcentageFormatter({ value, row }) {
  const order = row;
  if (!isNull(order) && startNoDone(order.State)) return '-';
  const val = parseToFloat(value, 0.01);
  // if (isEmpty(value) || value === 0) return '-';
  return (
    <span style={{ color: getColor(val) }}>
      {val >= 0 ? '+' : ''}
      {val}%
    </span>
  );
}

export function ColorPourcentageProvider(props) {
  return (
    <DataTypeProvider
      formatterComponent={ColorPourcentageFormatter}
      availableFilterOperations={numberFilterOperations}
      {...props}
    />
  );
}

function ColorGainFormatter({ value, row }) {
  const order = row;
  if (isNull(order)) return '';
  const pairs = order.Pair.split('-');

  if (startNoDone(order.State)) return '-';
  return (
    <span style={{ color: getColor(value) }}>
      {value >= 0 ? '+' : ''}
      {value} {pairs[1]}
    </span>
  );
}

export function ColorGainProvider(props) {
  return (
    <DataTypeProvider
      formatterComponent={ColorGainFormatter}
      availableFilterOperations={numberFilterOperations}
      {...props}
    />
  );
}

function ActionButtonsFormatter({ row, column }) {
  return (
    <MenuButtons>
      <GetMenuItem
        icon={<Visibility htmlColor={infoColor} />}
        text={<Translate id="orders.showOrderInfos" />}
        dialog={<DialogOrderInfos order={row} />}
      />
      <OrderMenuButtons order={row} isAdmin={column.isAdmin} />
    </MenuButtons>
  );
}

export function ActionButtonsProvider(props) {
  return (
    <DataTypeProvider formatterComponent={ActionButtonsFormatter} {...props} />
  );
}

export function WillFollowProvider(props) {
  return (
    <DataTypeProvider formatterComponent={WillFollowFormatter} {...props} />
  );
}
function WillFollowFormatter({ value }) {
  const issue = value.split(';');
  const good = issue.length === 1 && issue[0] === '1';
  if (good) return <Done htmlColor={successColor} />;
  return (
    <Hover
      trigger={
        <div>
          <Close htmlColor={dangerColor} />
          <Help
            style={{ cursor: 'pointer' }}
            color="primary"
            fontSize="small"
          />
        </div>
      }
      hover={
        <Card>
          <ul>
            {issue.map((i, key) => (
              <li key={key}>{i}</li>
            ))}
          </ul>
        </Card>
      }
      minWidthHover="33%"
      maxWidthHover="40%"
    />
  );
}

function FollowersActionButtonsFormatter({ value, row }) {
  const groupId = value;
  const user = row;
  return <FollowerButtons user={user} groupId={groupId} />;
}

export function FollowersActionButtonsProvider(props) {
  return (
    <DataTypeProvider
      formatterComponent={FollowersActionButtonsFormatter}
      {...props}
    />
  );
}

function ReferralsActionButtonsFormatter({ row }) {
  const referral = row;
  const shareUrl = 'https://walloftraders.com?ref=';
  const finalUrl = shareUrl + referral.ReferralCode;

  return (
    <div>
      {referral.Gains && (
        <Hover
          trigger={
            <LittleButton
              size="xs"
              color="info"
              icon={<Visibility style={{ height: '23px', width: '23px' }} />}
            />
          }
          hover={<ReferralContainer referral={referral} />}
          minWidthHover="33%"
          maxWidthHover="40%"
        />
      )}
      {referral.ReferralCode && (
        // <CustomizedMenus referrallink={finalUrl}/>
        <Hover
          trigger={
            <LittleButton
              size="xs"
              color="primary"
              icon={<CustomizedMenus referrallink={finalUrl} />}
            />
          }
          minWidthHover="33%"
          maxWidthHover="40%"
        />
      )}

      {/* <ReferralButtons */}
      {/*  style={{ float: 'left' }} */}
      {/*  size={'xs'} */}
      {/*  campaignId={campaignId} */}
      {/*  referral={row} */}
      {/* /> */}
    </div>
  );
}

export function ReferralsActionButtonsProvider(props) {
  return (
    <DataTypeProvider
      formatterComponent={ReferralsActionButtonsFormatter}
      {...props}
    />
  );
}

function ColorEntryQtyFormatter({ value, row }) {
  const order = row;
  const pairs = isNull(order) ? [] : order.Pair.split('-');
  const isBuy = isNull(order) ? false : order.IsBuy;

  if (isEmpty(value) || value === '-') return value;
  return (
    <span>
      {isBuy ? '+' : '-'}
      {value} {pairs[0]}
    </span>
  );
}

export function ColorEntryQtyProvider(props) {
  return (
    <DataTypeProvider
      formatterComponent={ColorEntryQtyFormatter}
      availableFilterOperations={numberFilterOperations}
      {...props}
    />
  );
}

function ColorExitQtyFormatter({ value, row }) {
  const order = row;
  const pairs = order.Pair.split('-');
  const isBuy = order.IsBuy;

  if (isEmpty(value) || value === '-') return value;
  return (
    <span>
      {!isBuy ? '+' : '-'}
      {value} {pairs[1]}
    </span>
  );
}

export function ColorExitQtyProvider(props) {
  return (
    <DataTypeProvider
      formatterComponent={ColorExitQtyFormatter}
      availableFilterOperations={numberFilterOperations}
      {...props}
    />
  );
}

const ExchangeFormatter = ({ row }) => {
  const order = row;
  const account = order.AAccount;
  return ShowExchange(account);
};

export function ExchangeProvider(props) {
  return <DataTypeProvider formatterComponent={ExchangeFormatter} {...props} />;
}

const PairFormatter = ({ value }) => {
  return ShowPair(value);
};

export function PairProvider(props) {
  return <DataTypeProvider formatterComponent={PairFormatter} {...props} />;
}

function SideFormatter({ row }) {
  const isBuy = row.TVType === 1 || row.TVType === 2 ? null : row.IsBuy;
  return <BuyType isBuy={isBuy} />;
}

export function SideProvider(props) {
  return <DataTypeProvider formatterComponent={SideFormatter} {...props} />;
}

function DateAgoFormatter({ value, column }) {
  const lang = column.codeLang === 'gb' ? 'en' : column.codeLang;
  return (
    <Moment fromNow locale={lang}>
      {value}
    </Moment>
  );
}

export function DateAgoProvider(props) {
  return (
    <DataTypeProvider
      formatterComponent={DateAgoFormatter}
      availableFilterOperations={numberFilterOperations}
      {...props}
    />
  );
}

const DateFormatter = ({ value, column }) => {
  // const lang = column.codeLang === 'gb' ? 'en' : column.codeLang;
  if (isNull(value) || value === '') return value;
  if (getNumeric().indexOf(value[0]) !== -1)
    return getDateToFormat(value, column.codeLang);
  return value;
};

export function DateProvider(props) {
  return (
    <DataTypeProvider
      formatterComponent={DateFormatter}
      availableFilterOperations={numberFilterOperations}
      {...props}
    />
  );
}

export function ShowPriceIcon(icon, price, pourcentage = null, icon2 = null) {
  return (
    <div style={{ display: 'inline-flex' }}>
      {icon || ''}
      {icon2 || ''}
      &nbsp;
      {pourcentage ? `${pourcentage}%: ` : ''}
      {price}
      &nbsp;
    </div>
  );
}

const ColorPriceFormatter = ({ value, column, row }) => {
  if (isEmpty(value)) return '';
  const lang = column.codeLang === 'gb' ? 'en' : column.codeLang;
  let action = null;
  if (column.name === 'StartPrice') {
    action = getStart(row.Triggers);
  } else if (column.name === 'StopLoss') {
    action = getStopLoss(row.Triggers);
  } else if (column.name === 'PanicSell') {
    action = getPanicSell(row.Triggers);
  } else if (column.name === 'Targets') {
    const actions = getTargets(row.Triggers);
    return actions.map((action) => {
      const icon = getIconStateActions(action, lang, 'small');
      const price = parseToFloat(getTriggerPrice(action), satoshi);
      const pourcentage = parseToFloat(action.Pourcentage, 0.01);
      return ShowPriceIcon(icon, price, pourcentage);
    });
  }
  return ShowPriceIcon(getIconStateActions(action, lang, 'small'), value);
};

export function ColorPriceProvider(props) {
  return (
    <DataTypeProvider
      formatterComponent={ColorPriceFormatter}
      availableFilterOperations={numberFilterOperations}
      {...props}
    />
  );
}

function SliderProviderFormatter({ row }) {
  return <OrderSlider order={row} />;
}

export function SliderProvider(props) {
  return (
    <DataTypeProvider
      formatterComponent={SliderProviderFormatter}
      availableFilterOperations={numberFilterOperations}
      {...props}
    />
  );
}

const DollarProviderFormatter = ({ value }) => {
  return !isEmpty(value) ? `${parseToFloat(value, 1)} $` : '';
};

export function DollarProvider(props) {
  return (
    <DataTypeProvider
      formatterComponent={DollarProviderFormatter}
      availableFilterOperations={numberFilterOperations}
      {...props}
    />
  );
}

export function NumberEditorBase({ value, onValueChange }) {
  const handleChange = (event) => {
    const { value: targetValue } = event.target;
    if (targetValue.trim() === '') {
      onValueChange();
      return;
    }
    onValueChange(targetValue);
  };
  return (
    <Input
      type="number"
      fullWidth
      value={value === undefined ? '' : value}
      inputProps={{
        placeholder: 'Filter...',
      }}
      onChange={handleChange}
    />
  );
}

function NameGroupFormatter({ value, row }) {
  if (isNull(row.Group)) return <div />;
  return (
    <Link
      to={`${adminUrl}/statGroup/${row.Group.GroupId}`}
      style={{ color: 'gold' }}
    >
      {value}
    </Link>
  );
}

export function NameGroupProvider(props) {
  return (
    <DataTypeProvider formatterComponent={NameGroupFormatter} {...props} />
  );
}

const renderArray = (array) => {
  let i = 0;
  return (
    <Card>
      {array.map((x) => {
        return <div key={i++}>{x}</div>;
      })}
    </Card>
  );
};

function TokenFormatter({ row }) {
  return (
    <div>
      <Hover
        trigger={
          <div>
            <div className={row[KeyExchangeTable.TOKEN.K]} />
            &nbsp;{row[KeyExchangeTable.TOKEN.K]}
          </div>
        }
        hover={renderArray(row[KeyExchangeTable.DETAIL.K])}
      />
    </div>
  );
}

export function TokenProvider(props) {
  return <DataTypeProvider formatterComponent={TokenFormatter} {...props} />;
}
