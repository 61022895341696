import {
  AccountBox,
  Autorenew,
  Create,
  DeleteForever,
  Info,
  Visibility,
} from '@material-ui/icons';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  dangerColor,
  infoColor,
  successColor,
  warningColor,
} from '../../assets/jss/material-dashboard-react';
import LittleButton from '../../components/CustomButtons/LittleButton';
import MenuButtons from '../../components/CustomButtons/MenuButtons';
import { connectAs } from '../../helpers/general';
import GetMenuItem from '../../helpers/menuItems';
import { alertPopup } from '../../redux/alert/alert.actions';
import { isAdminSelector } from '../../redux/authentication/selector';
import {
  refreshAccountGroup,
  toggleActiveUser,
} from '../../redux/trader/actions';
import { deleteUser } from '../../redux/user/actions';
import { adminUrl } from '../../routes/dashboard';
import DialogExchangeFollower from '../Followers/DialogExchangeFollower';
import { isNull } from '../../helpers/Common';

const getFollowersButton = (groupId, user, size, dispatch) => {
  const result = [];
  const userId = user?.ApplicationUserId;
  const roleSG = user?.RoleSG;

  const refresh = (id) => {
    if (isNull(id)) return;
    refreshAccountGroup(id)(dispatch);
  };

  result.push(
    <LittleButton
      size={size ? size : 'sm'}
      tooltip={<Translate id="traders.editFollower" />}
      disabled={!userId}
      // disabled={!userId || roleSG !== 1}
      color="warning"
      icon={<Create htmlColor={warningColor} />}
      to={'/updateFollower/' + groupId + '/' + userId}
      component={Link}
    />,
  );
  result.push(
    <LittleButton
      size={size ? size : 'sm'}
      tooltip={<Translate id="exchanges.refresh" />}
      disabled={!userId || !user?.Exchange?.Holds || roleSG !== 1}
      icon={<Autorenew />}
      onClick={() => refresh(user?.Exchange?.Id)}
    />,
  );
  result.push(
    <LittleButton
      size={size ? size : 'sm'}
      tooltip={
        user.IsActive ? (
          <Translate id="traders.disableFollower" />
        ) : (
          <Translate id="traders.enableFollower" />
        )
      }
      // folower can be disabled by trader
      // disabled={roleSG !== 1}
      color={user.IsActive ? 'danger' : 'success'}
      icon={
        user.IsActive ? (
          <PowerOffIcon htmlColor={dangerColor} />
        ) : (
          <PowerIcon htmlColor={successColor} />
        )
      }
      onClick={() => {
        toggleActiveUser(groupId, userId)(dispatch);
      }}
    />,
  );
  return result;
};

export function FollowerButtons({ user, groupId }) {
  const userId = user?.ApplicationUserId;
  const userName = user?.UserName;
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const isAdmin = isAdminSelector(state);
  const getButtons = (user) => {
    const buttons = [];
    if (user.Exchange)
      buttons.push(
        <GetMenuItem
          icon={<Visibility htmlColor={infoColor} />}
          text={<Translate id="exchanges.showExchange" />}
          dialog={
            <DialogExchangeFollower
              exchange={user.Exchange}
              isAdmin={isAdmin}
            />
          }
        />,
      );
    else
      buttons.push(
        <GetMenuItem
          icon={<Visibility htmlColor={infoColor} />}
          disabled
          text={<Translate id="error.noExchange" />}
          closeOnClick={false}
        />,
      );
    if (isAdmin)
      buttons.push(
        <GetMenuItem
          icon={<Info htmlColor={infoColor} />}
          to={adminUrl + '/user/' + userId + '/orders'}
          text={<Translate id="exchanges.showOrders" />}
        />,
      );

    const followersButtons = getFollowersButton(groupId, user, 'xs', dispatch);
    for (const button of followersButtons)
      buttons.push(
        <GetMenuItem
          {...button.props}
          text={button.props.tooltip}
          closeOnClick={button.props.disabled === false}
        />,
      );

    if (user.Exchange)
      buttons.push(
        <GetMenuItem
          icon={<AccountBox htmlColor={successColor} />}
          onClick={() => {
            alertPopup({
              type: 'confirm',
              then: (result) => {
                if (result.value) connectAs(userId, userName);
              },
            })(dispatch);
          }}
          text={<Translate id="central.connectAs" />}
        />,
      );
    buttons.push(
      <GetMenuItem
        icon={<DeleteForever htmlColor={dangerColor} />}
        onClick={() => {
          alertPopup({
            type: 'confirm',
            then: (result) => {
              if (result.value) deleteUser(userId)(dispatch, state);
            },
          })(dispatch);
        }}
        text={<Translate id="central.deleteUser" />}
      />,
    );
    return buttons;
  };

  return <MenuButtons>{getButtons(user)}</MenuButtons>;
}
