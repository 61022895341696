import React from 'react';

import GridItem from '../../components/Grid/GridItem.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import Button from '../../components/CustomButtons/Button';
import { bindActionCreators } from 'redux';
import { confirmEmail } from '../../redux/authentication/actions';
import connect from 'react-redux/es/connect/connect';
import { withLocalize } from 'react-localize-redux';
import { Translate } from 'react-localize-redux';
import transEmail from '../../locales/email.json';
import transConfirmation from '../../locales/confirmation';

class ConfirmationEmailPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transEmail);
    this.props.addTranslation(transConfirmation);
  }
  // getURLParameter = name => {
  //   return (
  //     decodeURIComponent(
  //       (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
  //         this.props.location.search
  //       ) || [null, ''])[1].replace(/\+/g, '%20')
  //     ) || null
  //   );
  // };
  componentDidMount() {
    const urlParams = this.props.location.search.replace('+', '%2B');
    const url = new URL('http://www.example.com' + urlParams);
    this.props.confirmEmail(
      '?token=' +
        encodeURIComponent(url.searchParams.get('token')) +
        '&email=' +
        encodeURIComponent(url.searchParams.get('email')),
    );
  }
  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Button
            color="primary"
            onClick={() => (window.location.href = '/identification')}
          >
            <Translate id="email.goToLogin" />
          </Button>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    confirmEmail: bindActionCreators(confirmEmail, dispatch),
  };
};

export default withLocalize(
  connect(null, mapDispatchToProps)(ConfirmationEmailPage),
);
