import React from 'react';
import { Translate } from 'react-localize-redux';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormAddReferralName from './FormAddReferral/FormAddReferralName';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '../../components/Card/Card';
import { Add } from '@material-ui/icons';
import Button from '../../components/CustomButtons/Button';
import { makeStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: 0,
  },
}));

const AddReferralButton = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (reason) => {
    if ('backdropClick' === reason) return;
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} size="sm">
        <Add style={{ marginRight: '4px', marginBottom: '2px' }} />
        &nbsp;
        <Translate id="referrals.CreateReferralLink" />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <IconButton
            edge="end"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" align="center">
            <Translate id="referrals.CreateReferralLink" />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Card>
            <FormAddReferralName stopPopup={handleClose} />
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddReferralButton;
