import { useMediaQuery, useTheme } from '@material-ui/core';

/**
 * Compute weither a device width is < 'sm' (ie mobile)
 * @returns {Boolean} return true if the current screen size is <= sm
 */
function useIsMobileDevice() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
}

export default useIsMobileDevice;
