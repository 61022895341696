import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Slider from '@material-ui/core/Slider';
import {
  dangerColor,
  successColor,
  grayColor,
  lightColor,
  darkColor,
} from '../../assets/jss/material-dashboard-react';
import {
  getProfitFromOrder,
  getStart,
  getTriggerPrice,
  getTrigPrice,
  isActiveOrder,
  isTriggered,
  triggerIsDone,
} from '../../helpers/order';
import {
  currentPriceSelector,
  isNull,
  parseToFloat,
  stepPriceSelector,
} from '../../helpers/Common';
import { connect } from 'react-redux';
import { getActiveCode } from '../../helpers/localize';
import { getTooltip } from '../../views/Orders/ActionOrderLine';
import { Translate } from 'react-localize-redux';

const IOSSlider = withStyles((theme) => ({
  root: {
    color: successColor,
    height: 2,
    padding: '15px 0',
    '&$disabled': {
      color: successColor,
    },
  },
  thumb: {
    display: 'none',
    height: 0,
    width: 0,
    backgroundColor: theme.palette.type === 'dark' ? lightColor : darkColor,
    marginTop: 0,
    marginLeft: 0,
  },
  active: {},
  disabled: {},
  valueLabel: {
    display: 'none',
  },
  track: {
    height: 5,
  },
  rail: {
    height: 5,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: theme.palette.type === 'dark' ? lightColor : darkColor,
    height: '1em',
    width: 1,
    marginTop: 0,
  },
  markActive: {
    backgroundColor: theme.palette.type === 'dark' ? lightColor : darkColor,
  },
  markLabel: {
    marginTop: '0.5em',
  },
  markLabelActive: {
    marginTop: '0.5em',
  },
}))(Slider);

class OrderSlider extends React.Component {
  getColor = (trigger) => {
    if ([0].includes(trigger.Action)) return successColor;
    if ([1, 3].includes(trigger.Action)) return dangerColor;
    return '#ffffff';
  };
  getPrevStr = (trigger) => {
    const { order } = this.props;
    let ret = trigger.Trailing !== null ? 'T' : '';
    switch (trigger.Action) {
      case 0:
        ret += 'TP';
        break;
      case 1:
        ret += 'SL';
        break;
      case 2:
        ret += order.IsBuy ? 'Buy' : 'Sell';
        break;
      case 3:
        ret += 'PS';
        break;
      default:
        return '';
    }
    return ret;
  };
  getMark = (trigger, marksPrices) => {
    if (!trigger) return null;
    const { stepPrice, codeLang } = this.props;
    const price = parseToFloat(getTriggerPrice(trigger), stepPrice);
    if (!isNull(price) && price !== 0) {
      const index = marksPrices.findIndex(
        (x) => x.trigger.TriggerId === trigger.TriggerId,
      );
      const marginTop =
        index % 2 === 0 || marksPrices.length <= 2 ? '0em' : '1em';
      let color = this.getColor(trigger);
      color += triggerIsDone(trigger) ? '' : '88';
      return {
        // trigger: trigger,
        value: price,
        label: (
          <div style={{ color: color, marginTop: marginTop }}>
            {/*{ShowPriceIcon(*/}
            {/*// getIconAction(trigger),*/}
            {/*getIconStateActions(trigger, codeLang, 'small'),*/}
            {/*price,*/}
            {/*trigger.Action === 0 ? trigger.Pourcentage : null,*/}
            {/*// getIconStateActions(trigger, codeLang, 'small')*/}
            {/*)}*/}
            {getTooltip(
              trigger,
              <div>{this.getPrevStr(trigger) + ' ' + price}</div>,
              codeLang,
            )}
          </div>
        ),
      };
    }
    return null;
  };
  getMarks = (order, currentPrice) => {
    const { stepPrice } = this.props;
    let marks = [];
    if (!order || !order.Triggers) return marks;

    let marksPrices = [];
    for (const trigger of order.Triggers) {
      const price = parseToFloat(getTriggerPrice(trigger), stepPrice);
      if (!isNull(price) && price !== 0) {
        marksPrices.push({ trigger, price });
      }
    }
    marksPrices = marksPrices.sort((a, b) => b.price - a.price);
    if (
      currentPrice &&
      (isActiveOrder(order.State) || order.Triggers.length === 1)
    ) {
      marks.push({
        value: currentPrice,
        label: (
          <div style={{ marginTop: '-34px', color: grayColor }}>
            {currentPrice}
          </div>
        ),
      });
    }

    const start = getStart(order.Triggers);
    if (
      start &&
      start.TriggerPrice &&
      start.TriggerType &&
      !isTriggered(start)
    ) {
      const sign =
        (start.TriggerType === 1 ? '>' : '') +
        (start.TriggerType === 2 ? '<' : '');
      marks.push({
        value: start.TriggerPrice,
        label: (
          <div style={{ marginTop: '-34px', color: grayColor }}>
            <Translate id={'orders.ifPrice'} /> {sign} {start.TriggerPrice}
          </div>
        ),
      });
    }

    for (const trigger of order.Triggers) {
      const mark = this.getMark(trigger, marksPrices);
      if (!isNull(mark)) marks.push(mark);
    }
    return marks;
  };

  getMin = (order, currentPrice) => {
    if (!order || !order.Triggers) return 0;

    let min =
      isActiveOrder(order.State) || order.Triggers.length === 1
        ? currentPrice
        : 0;
    for (const trigger of order.Triggers) {
      let price = getTriggerPrice(trigger);
      if (price !== 0 && (min === 0 || price < min)) min = price;
      if (getTrigPrice(trigger) !== null && !isTriggered(trigger)) {
        let price = getTrigPrice(trigger);
        if (min === 0 || price < min) min = price;
      }
    }
    return min;
  };

  getMax = (order, currentPrice) => {
    if (!order || !order.Triggers) return 0;

    let max =
      isActiveOrder(order.State) || order.Triggers.length === 1
        ? currentPrice
        : 0;
    for (const trigger of order.Triggers) {
      const price = getTriggerPrice(trigger);
      if (price !== 0 && (max === 0 || price > max)) max = price;
      if (getTrigPrice(trigger) !== null && !isTriggered(trigger)) {
        let price = getTrigPrice(trigger);
        if (max === 0 || price > max) max = price;
      }
    }
    return max;
  };

  getDefaultValue = (order, currentPrice) => {
    if (!order || !order.Triggers) return 0;

    let defaultValue =
      isActiveOrder(order.State) || order.Triggers.length === 1
        ? [currentPrice]
        : [];
    if (defaultValue.length === 0) {
      let min = 0;
      let max = 0;
      for (const trigger of order.Triggers) {
        if (triggerIsDone(trigger)) {
          const price = getTriggerPrice(trigger);
          if (price !== 0 && (max === 0 || price > max)) max = price;
          if (price !== 0 && (min === 0 || price < min)) min = price;
        }
      }
      return [min, max];
    }
    const start = getStart(order.Triggers);
    if (start && triggerIsDone(start))
      defaultValue.push(getTriggerPrice(start));

    return defaultValue;
  };

  render() {
    const { order, currentPrice } = this.props;

    const marks = this.getMarks(order, currentPrice);
    const min = this.getMin(order, currentPrice);
    const max = this.getMax(order, currentPrice);
    const defaultValue = this.getDefaultValue(order, currentPrice);
    const startPrice = getTriggerPrice(getStart(order.Triggers));
    let isWin = currentPrice > startPrice;
    isWin = order.IsBuy ? isWin : !isWin;
    if (!isActiveOrder(order.State) && order.Triggers.length > 1) {
      const profit = getProfitFromOrder(order, currentPrice);
      isWin = profit > 0;
    }
    return (
      <div style={{ width: 'auto', marginLeft: '45px', marginRight: '45px' }}>
        <IOSSlider
          style={{ color: isWin ? successColor : dangerColor }}
          disabled
          aria-label="slider"
          min={min}
          max={max}
          defaultValue={defaultValue}
          marks={marks}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    codeLang: getActiveCode(state),
    stepPrice: stepPriceSelector(state, props),
    currentPrice: currentPriceSelector(state, props),
  };
};

export default connect(mapStateToProps, null)(OrderSlider);
