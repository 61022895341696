import React from 'react';
import { bindActionCreators } from 'redux';
import { change, formValueSelector } from 'redux-form';
import { smartTradeSelector, isBuySelector } from '../../../helpers/Common';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import Button from '../../../components/CustomButtons/Button.jsx';

const styles = {
  buttonLeft: {
    width: '49%',
    marginLeft: '0px',
    marginRight: '0px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  },
  buttonRight: {
    width: '49%',
    marginLeft: '0px',
    marginRight: '0px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
};

class ButtonsBuySell extends React.PureComponent {
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { isBuy, change, formName } = this.props;
  //
  //   if (prevProps.isBuy !== isBuy && !isBuy) {
  //     // change(formName, 'trailingStartCB', false);
  //     // change(formName, 'trailingTargetCB', false);
  //     // change(formName, 'trailingStopLossCB', false);
  //   }
  // }

  toggleBuySell = (value) => {
    const { formName } = this.props;

    // if (value !== isBuy) {
    //
    //   this.props.change(formName, 'trailingStopLoss', trailingStopLoss * -1);
    //   this.props.change(formName, 'trailingStart', trailingStart * -1);
    //
    //   for (const key in targets) {
    //     const target = targets[key];
    //     if (target && Object.hasOwn(target, 'trailing')) {
    //       const trailing = target.trailing;
    //       this.props.change(
    //         formName,
    //         `targets[${key}].trailing`,
    //         trailing * -1
    //       );
    //     }
    //   }
    // }

    this.props.change(formName, 'isBuy', value);

    // const { values } = this.props;
    // const { pair } = values;
    // this.onChangePair(pair);
  };

  render() {
    const { isBuy, isUpdate, tvType } = this.props;
    const isBuyAndSell = tvType === 1 || tvType === 2;
    return (
      <div>
        <Button
          style={styles.buttonLeft}
          color={isBuy ? 'success' : 'default'}
          onClick={() => this.toggleBuySell(true)}
          disabled={isUpdate || isBuyAndSell}
        >
          <b>
            <Translate id="smartTrade.buy" />
          </b>
        </Button>
        <Button
          style={styles.buttonRight}
          color={!isBuy ? 'danger' : 'default'}
          onClick={() => this.toggleBuySell(false)}
          disabled={isUpdate || isBuyAndSell}
        >
          <b>
            <Translate id="smartTrade.sell" />
          </b>
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  const values = smartTradeSelector(state);
  return {
    // values: getFormValues(props.formName)(state),
    values: values,
    tvType: values.TVType,
    isBuy: isBuySelector(state),
    isUpdate: formSelector(state, 'isUpdate'),
    trailingStopLoss: formSelector(state, 'trailingStopLoss'),
    targets: formSelector(state, 'targets'),
    trailingStart: formSelector(state, 'trailingStart'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsBuySell);
