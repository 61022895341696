import React from 'react';

export default class CGVUPage extends React.Component {
  render() {
    return (
      <div style={{ textAlign: 'justify' }}>
        <h1>
          <b>Conditions Générales de Vente et d&apos;Utilisation</b>
        </h1>
        <b>AVERTISSEMENT</b>
        <br />
        Nous ne vous donnons aucune recommandation ou aucun conseil
        d&apos;investissement. Nous vous proposons une assistance dans votre
        trading. Tout trading comporte des risques que vous êtes prêt à
        accepter. Misez uniquement de l&apos;argent que vous pouvez vous
        permettre de perdre. Aucun gain n&apos;est garanti. Toutes les positions
        d&apos;achat et de vente sur le marché des crypto-actifs prises par le
        Client ou l&apos;Utilisateur ou par l&apos;outil d&apos;automatisation
        de trading sur le site https://walloftraders.com, doivent être
        surveillées par le Client ou l&apos;Utilisateur. Seul le Client ou
        l&apos;Utilisateur est responsable de ces positions. Seules les
        personnes capables de comprendre le trading et les risques encourus
        doivent s&apos;inscrire. Nous déclinons toute responsabilité des
        erreurs, imprécisions, oublis, pertes de données, pertes de monnaie
        fiat, pertes ou dévalorisation de crypto-actifs, d&apos;une mauvaise
        compréhension des Services fournis, de retards possibles à
        l&apos;exécution ou des variations des prix. Tous les Services, outils
        et informations fournis par Wall Of Traders sont privés et ne doivent
        pas être considérés comme infaillibles. Vous devez avoir plus de 18 ans
        révolus pour utiliser Wall Of Traders. Les marchés de crypto-actifs sur
        lesquels la société propose ses Services sont des marchés qui ne sont
        pas réglementés et qui sont décentralisés. En étant non réglementés, ces
        marchés ne sont régis par aucun cadre ou réglementation, français ou
        européen. Par conséquent, le Client ou l&apos;Utilisateur prend
        conscience qu&apos;il ne bénéficie pas des protections offertes par les
        marchés réglementés.
        <br />
        <h3>
          <b>1. A propos de nous</b>
        </h3>
        <br />
        La Société WALL OF TRADERS, SAS, au capital de 1000 euros, dont le siège
        social est à PARIS (75001) immatriculée au registre du commerce et des
        sociétés de PARIS sous le numéro SIREN 907601579 et située au 15 rue des
        Halles 75001 Paris représentée par la <b>&quot;Société”</b>
        ). La Société propose les services suivants : Aide au trading de
        crypto-monnaies et abonnement à des signaux de trading.
        <h3>
          <b>2. Préambule</b>
        </h3>
        <br />
        La Société invite ses Utilisateurs à lire attentivement les présentes
        Conditions Générales de Vente et d&apos;Utilisation (ci-après les{' '}
        <b>&quot;CGVU&quot;</b>
        ). Les CGVU s&apos;appliquent à tous les Services fournis par la Société
        à ses Clients d&apos;une même catégorie, quelles que soient les clauses
        pouvant être inscrites sur les documents du Client et notamment ses
        conditions générales d&apos;achat.
        <br />
        Les CGVU sont systématiquement communiquées au Client qui en fait la
        demande.
        <br />
        Le Client est tenu de prendre connaissance des CGVU avant toute
        passation de Commande.
        <br />
        En cas de modification ultérieure des CGVU, le Client est soumis à la
        dernière version publiée.
        <br />
        Les données enregistrées dans le système informatique de la Société
        constituent la preuve des transactions conclues avec le Client.
        <br />
        <h3>
          <b>3. Définitions</b>
        </h3>
        <br />
        <b>&quot;Client&quot;</b> désigne toute personne physique ou morale qui
        passe une Commande sur le présent Site Internet ;<br />
        <b>&quot;Commande&quot;</b> désigne toute commande passée par
        l&apos;Utilisateur inscrit sur le présent Site, en vue de bénéficier des
        Services de la Société ;<br />
        <b>
          &quot;Conditions Générales de Vente et d&apos;Utilisation&quot;
        </b>{' '}
        ou <b>&quot;CGVU&quot;</b> désignent les présentes conditions générales
        de vente et d&apos;utilisation en ligne ;<br />
        <b>&quot;Services&quot;</b> désigne toutes les prestations de service
        proposées aux Utilisateurs par la Société à travers le présent Site ;
        <br />
        <b>&quot;Crypto-actifs&quot;</b> désigne l&apos;ensemble des actifs
        dématérialisés et dont les transactions sont réalisées à travers la
        blockchain : ils comprennent les crypto-monnaies et les tokens ;<br />
        <b>“Exchange”</b> désigne les plateformes d&apos;échange extérieures au
        Site sur lesquelles sont achetés et échangés les Crypto-actifs et sur
        lesquelles le Site n&apos;a aucune maîtrise ; par exemple Binance,
        Bittrex, Bitfinex, Kraken, Coinbase sont des Exchanges ;<br />
        <b>&quot;Site&quot;</b> désigne le présent Site, c&apos;est-à-dire
        https://walloftraders.com ;<br />
        <b>&quot;Société&quot;</b> désigne la Société Wall Of Traders, plus
        amplement désignée à l&apos;article I des présentes ; et
        <br />
        <b>&quot;Utilisateur&quot;</b> désigne toute personne qui fait
        utilisation du Site.
        <br />
        <h3>
          <b>4. Inscription</b>
        </h3>
        <br />
        L&apos;inscription au Site est ouverte à toutes les personnes morales ou
        physiques majeures et jouissant de leurs pleines personnalités et
        capacités juridiques.
        <br />
        L&apos;utilisation des Services proposés sur le Site est conditionnée à
        l&apos;inscription de l&apos;Utilisateur sur le Site.
        <br />
        L&apos;inscription est gratuite.
        <br />
        Pour procéder à l&apos;inscription, l&apos;Utilisateur doit remplir tous
        les champs obligatoires, sans lesquels le service ne peut être délivré.
        A défaut l&apos;inscription ne pourra être complétée.
        <br />
        Les Utilisateurs garantissent et déclarent sur l&apos;honneur que toutes
        les informations communiquées sur le Site, notamment lors de leur
        inscription, sont exactes et conformes à la réalité. Ils s&apos;engagent
        à mettre à jour leurs informations personnelles à partir de la page
        dédiée à ces dernières et disponible dans leur compte.
        <br />
        Tout Utilisateur enregistré dispose d&apos;un identifiant et d&apos;un
        mot de passe. Ces derniers sont strictement personnels et confidentiels
        et ne devront en aucun cas faire l&apos;objet d&apos;une communication à
        des tiers sous peine de suppression du compte de l&apos;Utilisateur
        enregistré contrevenant. Chaque Utilisateur inscrit est personnellement
        responsable du maintien de la confidentialité de son identifiant et de
        son mot de passe. La Société ne sera en aucun cas tenue pour responsable
        de l&apos;usurpation d&apos;identité d&apos;un Utilisateur. Si un
        Utilisateur suspecte une fraude à n&apos;importe quel moment, il devra
        contacter la Société dans les plus brefs délais afin que cette dernière
        puisse prendre les mesures nécessaires et régulariser la situation.
        <br />
        Chaque Utilisateur, qu&apos;il soit une personne morale ou physique, ne
        peut être titulaire que d&apos;un compte sur le Site.
        <br />
        En cas de non-respect des CGVU, notamment la création de plusieurs
        comptes pour une seule personne ou encore la fourniture de fausses
        informations, la Société se réserve le droit de procéder à la
        suppression temporaire ou définitive de tous les comptes créés par
        l&apos;Utilisateur enregistré contrevenant.
        <br />
        La suppression du compte entraîne la perte définitive de tous les
        avantages et services acquis sur le Site. Cependant, toute Commande
        réalisée et facturée par le Site avant la suppression du compte sera
        exécutée dans les conditions normales.
        <br />
        En cas de suppression d&apos;un compte par la Société pour manquement
        aux devoirs et obligations énoncées dans les CGVU, il est formellement
        interdit à l&apos;Utilisateur contrevenant de se réinscrire sur le Site
        directement, par le biais d&apos;une autre adresse électronique ou par
        personne interposée sans l&apos;autorisation expresse de la Société.
        <br />
        <h3>
          <b>5. Prestation de service et prix</b>
        </h3>
        <br />
        Les Services faisant l&apos;objet des CGVU sont ceux qui figurent sur le
        Site et qui sont proposés directement par la Société ou ses prestataires
        partenaires.
        <br />
        Les services sont décrits sur la page correspondante au sein du Site et
        mention est faite de toutes leurs caractéristiques essentielles. La
        Société ne peut être tenue responsable de l&apos;impossibilité de
        réaliser ladite prestation lorsque l&apos;Utilisateur n&apos;est pas
        éligible.
        <br />
        Lorsqu&apos;un Utilisateur enregistré souhaite obtenir une prestation de
        service vendue par la Société à travers le Site, le prix indiqué sur la
        page de la prestation correspond au prix en euros toutes taxes comprise
        (TTC) et tient compte des réductions applicables et en vigueur le jour
        de la Commande. Le prix indiqué n&apos;inclut pas les frais accessoires
        qui seront indiqués le cas échéant dans le récapitulatif avant de passer
        commande.
        <br />
        La Société se réserve la possibilité de modifier à tout moment ses
        tarifs. Elle s&apos;engage à facturer les prestations au tarif
        applicable au moment de leur Commande. Pour les prestations dont le prix
        ne peut être connu a priori ni indiqué avec certitude, un devis détaillé
        sera transmis au Client.
        <br />
        En aucun cas un Utilisateur ne pourra exiger l&apos;application de
        réductions n&apos;étant plus en vigueur le jour de la Commande.
        <br />
        <h3>
          <b>6. Commandes</b>
        </h3>
        <br />
        Toute Commande ne peut être réalisée que lorsque l&apos;Utilisateur
        s&apos;est inscrit sur le Site. L&apos;Utilisateur, lorsqu&apos;il est
        connecté sur son compte, peut ajouter des Services à son panier virtuel.
        Il peut accéder au récapitulatif de son panier virtuel afin de confirmer
        les Services qu&apos;il souhaite commander et effectuer sa Commande en
        appuyant sur le bouton “Commander&quot;.
        <br />
        Il devra ensuite renseigner son adresse email ainsi qu&apos;un mode de
        paiement valable afin de finaliser la commande et de former efficacement
        le contrat de vente entre lui et la Société.
        <br />
        L&apos;enregistrement d&apos;une Commande sur le Site est réalisé
        lorsque le Client accepte les CGVU en cochant la case prévue à cet effet
        et valide sa Commande. Cette validation constitue une preuve du contrat
        de vente. La finalisation de la Commande implique l&apos;acceptation des
        prix et des modalités de réalisations des prestations telles
        qu&apos;indiquées sur le Site.
        <br />
        Une fois sa Commande passée, le Client en recevra confirmation par
        courrier électronique. Cette confirmation fera le récapitulatif de la
        Commande et des informations relatives à la réalisation de la ou des
        prestations.
        <br />
        En cas de défaut de paiement, d&apos;adresse erronée du Client ou autre
        problème sur le compte du Client, la Société se réserve le droit de
        bloquer la commande du Client jusqu&apos;à ce que le problème soit
        résolu. En cas d&apos;impossibilité de réaliser la prestation, le Client
        en sera informé par courrier électronique à l&apos;adresse qu&apos;il
        aura fournie à la Société. L&apos;annulation de la commande de cette
        prestation et son remboursement seront dans ce cas effectués, le reste
        de la commande demeurant ferme et définitif.
        <br />
        <h3>
          <b>7. Délais et modalités de paiement</b>
        </h3>
        <br />
        Sauf dispositions contraires, toutes les ventes sont payées comptant au
        moment de la passation de la Commande.
        <br />
        En fonction de la nature ou du montant de la Commande, la Société reste
        libre d&apos;exiger un acompte ou le paiement de l&apos;intégralité du
        prix lors de la passation de la Commande ou lors de la réception de la
        facture.
        <br />
        Le paiement peut être réalisé par :
        <ul>
          <li>Carte bancaire ;</li>
          <li>
            Crypto-actif Bitcoin, Ethereum ou Litecoin au cours de conversion en
            euros ou en dollars du montant de l&apos;abonnement. Le cours est
            celui du jour et de l&apos;heure à la minute près du passage de la
            commande ;
          </li>
          <li>Paypal.</li>
        </ul>
        En cas de défaut de paiement total ou partiel des prestations à la date
        convenue sur la facture, le Client devra verser à la Société une
        pénalité de retard dont le taux est égal au taux pratiqué par la Banque
        Centrale Européenne pour son opération de refinancement majoré de 10
        points de pourcentage. L&apos;opération de financement retenue est la
        plus récente à la date de la Commande des prestations de Service.
        <br />
        En sus des indemnités de retard, toute somme, y compris l&apos;acompte,
        non payée à sa date d&apos;exigibilité produira de plein droit le
        paiement d&apos;une indemnité forfaitaire de 40 euros due au titre des
        frais de recouvrement.
        <br />
        En cas de défaut de paiement total ou partiel des prestations à la date
        convenue sur la facture, le Client devra verser à la Société une
        pénalité de retard dont le taux est égal au taux d&apos;intérêt légal.
        <br />
        Aucune compensation ne pourra être effectuée par le Client entre des
        pénalités de retard dans la fourniture des prestations commandées et des
        sommes dues par le Client à la Société au titre de l&apos;achat de
        Services proposés sur le Site.
        <br />
        La pénalité due par le Client est calculée sur le montant toutes taxes
        comprises de la somme restante due, et court à compter de la date
        d&apos;échéance du prix sans qu&apos;aucune mise en demeure préalable ne
        soit nécessaire.
        <br />
        En cas de non-respect des conditions de paiement décrites ci-dessus, la
        Société se réserve la possibilité d&apos;annuler ou de suspendre la
        vente.
        <br />
        <h3>
          <b>8. Réalisation des prestations</b>
        </h3>
        <br />
        La prestation de services commandée sur le Site sera assurée par la
        Société.
        <br />
        La Société s&apos;engage à mettre en oeuvre tous les moyens humains et
        matériels pour réaliser la prestation dans les délais annoncés lors de
        la passation de la Commande. Cependant, elle ne peut en aucun cas être
        tenue responsable pour les retards de réalisation de prestation
        occasionnés en raison de fautes qui ne lui sont imputables.
        <br />
        Si les prestations n&apos;ont pas été effectuées dans le délai prévu, la
        résolution de la vente pourra être demandée par le Client dans les
        conditions prévues aux articles L138-2 et L138-3 du Code de la
        consommation. Les sommes versées par le Client lui seront restituées au
        plus tard dans les quatorze jours qui suivent la date de dénonciation du
        contrat.
        <br />
        Cette disposition ne s&apos;applique pas quand le retard de la Société
        est dû à une faute du Client ou à un cas de force majeure, c&apos;est à
        dire à la survenance d&apos;un évènement imprévisible, irrésistible et
        indépendant de la volonté de la Société.
        <br />
        Dans le cas où l&apos;exécution d&apos;une prestation physique
        n&apos;aurait pas pu être effectuée ou aurait été reportée du fait
        d&apos;une erreur sur l&apos;adresse indiquée par le Client, les frais
        de déplacement du prestataire mandaté par la Société pour réaliser la
        prestation infructueuse seront à la charge du Client.
        <br />
        <h3>
          <b>9. Réclamation</b>
        </h3>
        <br />
        Pour toutes les Commandes réalisées sur le présent Site, le Client
        dispose d&apos;un droit de réclamation de 5 jours à compter de la
        fourniture du Service.
        <br />
        Pour exercer ce droit de réclamation, le Client doit faire parvenir à la
        Société, à l&apos;adresse contact@walloftraders.com, une déclaration
        dans laquelle il exprime ses réserves et réclamations, assorties des
        justificatifs y afférents.
        <br />
        Une réclamation ne respectant pas les conditions décrites ci-dessus ne
        pourra être acceptée.
        <br />
        Après examen de la réclamation, le Site pourra le cas échéant remplacer
        ou rembourser la prestation de Services dans les plus brefs délais et à
        ses frais.
        <br />
        <h3>
          <b>10. Droit de rétractation du Client</b>
        </h3>
        <br />
        Le Client dispose d&apos;un droit de rétractation de 14 jours à compter
        de la passation de la Commande, sauf pour les produits mentionnés à
        l&apos;article L121-21-8 du Code de la consommation tel que reproduit
        ci-dessous :
        <br />
        &quot;Le droit de rétractation ne peut être exercé pour les contrats :
        <br />
        1° De fourniture de services pleinement exécutés avant la fin du délai
        de rétractation et dont l&apos;exécution a commencé après accord
        préalable exprès du Client et renoncement exprès à son droit de
        rétractation ;
        <br />
        2° De fourniture de biens ou de services dont le prix dépend de
        fluctuations sur le marché financier échappant au contrôle du
        professionnel et susceptibles de se produire pendant le délai de
        rétractation ;<br />
        3° De fourniture de biens confectionnés selon les spécifications du
        Client ou nettement personnalisés ;<br />
        4° De fourniture de biens susceptibles de se détériorer ou de se périmer
        rapidement ;<br />
        5° De fourniture de biens qui ont été descellés par le Client après la
        livraison et qui ne peuvent être renvoyés pour des raisons
        d&apos;hygiène ou de protection de la santé ;<br />
        6° De fourniture de biens qui, après avoir été livrés et de par leur
        nature, sont mélangés de manière indissociable avec d&apos;autres
        articles ;
        <br />
        7° De fourniture de boissons alcoolisées dont la livraison est différée
        au-delà de trente jours et dont la valeur convenue à la conclusion du
        contrat dépend de fluctuations sur le marché échappant au contrôle du
        professionnel ;<br />
        8° De travaux d&apos;entretien ou de réparation à réaliser en urgence au
        domicile du Client et expressément sollicités par lui, dans la limite
        des pièces de rechange et travaux strictement nécessaires pour répondre
        à l&apos;urgence ;<br />
        9° De fourniture d&apos;enregistrements audio ou vidéo ou de logiciels
        informatiques lorsqu&apos;ils ont été descellés par le Client après la
        livraison ;<br />
        10° De fourniture d&apos;un journal, d&apos;un périodique ou d&apos;un
        magazine, sauf pour les contrats d&apos;abonnement à ces publications ;
        <br />
        11° Conclus lors d&apos;une enchère publique ;<br />
        12° De prestations de services d&apos;hébergement, autres que
        d&apos;hébergement résidentiel, de services de transport de biens, de
        locations de voitures, de restauration ou d&apos;activités de loisirs
        qui doivent être fournis à une date ou à une période déterminée ;<br />
        13° De fourniture d&apos;un contenu numérique non fourni sur un support
        matériel dont l&apos;exécution a commencé après accord préalable exprès
        du Client et renoncement exprès à son droit de rétractation.&quot;
        <br />
        Pour exercer ce droit de rétractation le Client envoie une déclaration à
        l&apos;adresse :<br />
        contact@walloftraders.com.
        <br />
        Il sera remboursé de la totalité des frais versés pour la prestation de
        services dans les 14 jours suivant la prise de connaissance par la
        Société de sa déclaration de rétractation. Le remboursement sera fait
        par le même moyen de paiement que celui utilisé à l&apos;achat.
        <br />
        Cependant, si la prestation de services est déjà entamée à la date de la
        prise de connaissance de la rétractation par la Société, la valeur
        correspondant à la prestation de service déjà effectuée sera déduite du
        remboursement. Ce dernier sera opéré par le même moyen de paiement que
        pour l&apos;achat.
        <br />
        <h3>
          <b>11. Traitement des données personnelles</b>
        </h3>
        <br />
        L&apos;enregistrement sur le Site entraîne le traitement des données à
        caractère personnel du Client. Si le Client refuse le traitement de ses
        données, il lui est demandé de s&apos;abstenir d&apos;utiliser le Site.
        <br />
        Ce traitement des données à caractère personnel se fait dans le respect
        du Règlement Général sur la Protection des Données 2016/679 du 27 avril
        2016.
        <br />
        Par ailleurs, conformément à la loi Informatique et Libertés du 6
        janvier 1978, le Client dispose, à tout moment, d&apos;un droit
        d&apos;interrogation, d&apos;accès, de rectification, de modification et
        d&apos;opposition à l&apos;ensemble de ses données personnelles en
        écrivant, par courrier et en justifiant de son identité, à
        l&apos;adresse suivante : contact@walloftraders.com.
        <br />
        Ces données personnelles sont nécessaires au traitement de sa Commande
        et à l&apos;établissement de ses factures le cas échéant, ainsi
        qu&apos;à l&apos;amélioration des fonctionnalités du Site.
        <br />
        <h3>
          <b>12. Partage des données collectées</b>
        </h3>
        <br />
        Le Site peut avoir recours à des sociétés tierces pour effectuer
        certaines opérations. En naviguant sur le Site, le Client accepte que
        des société tierces puissent avoir accès à ses données pour permettre le
        bon fonctionnement du Site.
        <br />
        Ces sociétés tierces n&apos;ont accès aux données collectées que dans la
        cadre de la réalisation d&apos;une tâche précise.
        <br />
        Le Site reste responsable du traitement de ces données.
        <br />
        Par ailleurs, l&apos;Utilisateur peut donc être amené à recevoir des
        informations ou des offres commerciales de la part de la Société ou de
        ses partenaires.
        <br />
        L&apos;Utilisateur peut à tout moment s&apos;opposer à la réception de
        ces offres commerciales, en écrivant à l&apos;adresse de la Société
        indiquée ci-dessus, ou en cliquant sur le lien prévu à cet effet au sein
        des courriers électroniques reçus.
        <br />
        Par ailleurs, les informations des Clients pourront être transmises à
        des tiers sans leur accord express préalable afin d&apos;atteindre les
        buts suivants :<br />
        <ul>
          <li>respecter la loi</li>
          <li>
            protéger toute personne contre des dommages corporels graves, voire
            la mort
          </li>
          <li>
            lutter contre la fraude ou les atteintes portées à la Société ou à
            ses utilisateurs
          </li>
          <li>protéger les droits de propriété de la Société.</li>
        </ul>
        <h3>
          <b>13. Protection des données</b>
        </h3>
        <br />
        La Société assure un niveau de sécurité approprié et proportionnel aux
        risques encourus ainsi qu&apos;à leur probabilité, conformément au
        Règlement Général sur la Protection des Données 2016/679 du 27 avril
        2016.
        <br />
        Toutefois, ces mesures ne constituent en aucun cas une garantie et
        n&apos;engagent pas la Société à une obligation de résultat concernant
        la sécurité des données.
        <br />
        <h3>
          <b>14. Acceptation des risques</b>
        </h3>
        <br />
        Le Client ou l&apos;Utilisateur accepte les risques liés aux
        crypto-actifs et à leur très forte volatilité, et notamment aux risques
        liés à la blockchain dans laquelle les transactions sont réalisées.
        Aucune législation ne régit la blockchain qui est un réseau indépendant
        et décentralisé. Le Client ou l&apos;Utilisateur comprend que des
        erreurs, des fautes ou des vols peuvent survenir dans la blockchain et
        entraîner la perte des fonds investis.
        <br />
        Les fonds investis sont détenus par les Exchanges et les ordres
        d&apos;achat et de vente de ces fonds sur le marché des crypto-actifs
        sont exécutés par ces mêmes Exchanges. Ces Exchanges ne sont pas des
        banques. Si elles se font volées ou font faillite, il n&apos;existe
        aucune protection juridique vous couvrant pour vos pertes. Wall Of
        Traders ne sera pas responsable en cas de pertes causées par ces
        Exchanges. De plus la liquidité de ces plateformes n&apos;est pas
        garantie. La disparition pure et simple du crypto-actif est possible.
        <br />
        L&apos;interaction et l&apos;échange de données entre le site de la
        Société Wall Of Traders et ces Exchanges se fait via clé API. En
        renseignant une clé API sur le site de la Société Wall Of Traders, le
        Client ou l&apos;Utilisateur accepte le risque de perte ou de vol de ces
        clés API et des conséquences qui peuvent en découler, notamment le fait
        pour un voleur de manipuler le portefeuille du Client ou de
        l&apos;Utilisateur en lui faisant acheter à un prix élevé et en lui
        faisant revendre à un prix plus bas, entraînant ainsi une perte de
        valeur du portefeuille de crypto-actifs du Client ou de
        l&apos;Utilisateur.
        <br />
        Il est possible de copier les stratégies de traders sélectionnés par la
        Société. Cependant, nous ne garantissons pas les résultats, la qualité,
        le professionnalisme ou les bonnes intentions d&apos;un trader. Les
        résultats passés d&apos;un trader ne peuvent pas préjuger de ses
        résultats futurs.
        <br />
        <h3>
          <b>15. Collecte des données</b>
        </h3>
        <br />
        Wall Of Traders ne collecte pas directement de données privées sur ses
        Utilisateurs. Aucune information personnelle n&apos;est stockée sur nos
        serveurs. Si vous souhaitez vous inscrire pour recevoir des mises à jour
        par email, les seules données collectées seront votre nom, email et la
        page sur laquelle vous avez rempli un formulaire. Ces informations sont
        stockées directement sur les serveurs de SoYouStart, filiale de OVH.
        Aucune adresse email ne sera transmise à un tiers sans la permission de
        l&apos;abonné.
        <br />
        <h3>
          <b>16. Langue</b>
        </h3>
        <br />
        En cas de traduction de ces présentes CGVU en d&apos;autres langues, la
        langue d&apos;interprétation sera la langue française.
        <br />
        <h3>
          <b>17. Cookies</b>
        </h3>
        <br />
        Pour permettre à ses Utilisateurs de bénéficier d&apos;une navigation
        optimale sur le Site et d&apos;un meilleur fonctionnement des
        différentes interfaces et applications, la Société est susceptible
        d&apos;implanter un cookie sur l&apos;ordinateur de l&apos;Utilisateur.
        Ce cookie permet de stocker des informations relatives à la navigation
        sur le Site, ainsi qu&apos;aux éventuelles données saisies par les
        Utilisateurs (notamment recherches, login, email, mot de passe).
        <br />
        L&apos;Utilisateur autorise expressément la Société à déposer sur le
        disque dur de l&apos;utilisateur un fichier dit« cookie ».
        <br />
        L&apos;Utilisateur dispose de la possibilité de bloquer, modifier la
        durée de conservation, ou supprimer ce cookie via l&apos;interface de
        son navigateur. Si la désactivation systématique des cookies sur le
        navigateur de l&apos;Utilisateur l&apos;empêche d&apos;utiliser certains
        services ou fonctionnalités du Site, ce dysfonctionnement ne saurait en
        aucun cas constituer un dommage pour le membre qui ne pourra prétendre à
        aucune indemnité de ce fait.
        <br />
        <h3>
          <b>18. Modifications</b>
        </h3>
        <br />
        La Société se réserve le droit de modifier le Site, les services qui y
        sont proposés, les CGVU ainsi que toute procédure de livraison ou autre
        élément constitutif des prestations réalisées par la Société par le
        biais du Site.
        <br />
        Lorsqu&apos;il passe une Commande, l&apos;Utilisateur est soumis aux
        stipulations énoncées par les CGVU en vigueur lors de la passation de la
        Commande.
        <br />
        <h3>
          <b>19. Responsabilité</b>
        </h3>
        <br />
        La Société ne peut en aucun cas être tenue responsable pour
        l&apos;indisponibilité, qu&apos;elle soit temporaire ou permanente du
        Site Internet et, bien qu&apos;elle mette en œuvre tous les moyens afin
        d&apos;assurer en permanence le service, il se peut que celui-ci soit
        interrompu à tout moment. En outre, la Société se réserve le droit, par
        un acte volontaire, de rendre le Site indisponible afin de réaliser
        toute opération de mise à jour, d&apos;amélioration ou de maintenance.
        <br />
        Tel que mentionné auparavant dans les présentes, la Société ne peut en
        aucun cas être tenue responsable des retards de réalisation d&apos;une
        prestation de service pour des motifs qui échappent à son contrôle,
        indépendants de sa volonté, imprévisibles et irrésistibles ou dont la
        faute ne peut lui être imputable.
        <br />
        <h3>
          <b>20. Propriété intellectuelle</b>
        </h3>
        <br />
        La marque, le logo, et la charte graphique du présent Site sont des
        marques déposées auprès de l&apos;INPI et des oeuvres protégées au titre
        de la propriété intellectuelle, dont la propriété revient exclusivement
        à la Société. Toute diffusion, exploitation, représentation,
        reproduction, qu&apos;elle soit partielle ou intégrale sans
        l&apos;autorisation expresse de ladite société exposera le contrevenant
        à des poursuites civiles et pénales.
        <br />
        <h3>
          <b>21. Clause attributive de compétence</b>
        </h3>
        <br />
        Le droit régissant les CGVU est le droit français. Tout litige pouvant
        survenir entre la Société et un Utilisateur lors de l&apos;exécution des
        présentes fera l&apos;objet d&apos;une tentative de résolution à
        l&apos;amiable. A défaut, les litiges seront portés à la connaissance
        des tribunaux compétents de droit commun.
        <br />
        Le Client est informé qu&apos;il peut recourir à une médiation
        conventionnelle, auprès de la Commission de la médiation de la
        Consommation prévue à l&apos;article L534-7 du Code de la consommation
        ou auprès des instances de médiation sectorielles existantes. Il peut
        également recourir à tout mode alternatif de règlement des différends en
        cas de litige.
        <br />
        <h3>
          <b>22. Information précontractuelle</b>
        </h3>
        <br />
        Préalablement à sa Commande, le Client reconnaît avoir eu communication,
        de façon lisible et compréhensible, des CGVU et des informations et
        renseignements prévus aux articles L111-1 à L111-7 du Code de la
        consommation, et notamment :<br />
        <ul>
          <li>les caractéristiques essentielles des Services ;</li>
          <li>le prix des Services ;</li>
          <li>
            la date ou le délai auquel la Société s&apos;engage à fournir le
            Service ;
          </li>
          <li>
            les informations relatives à l&apos;identité de la Société
            (coordonnées postales, téléphoniques, électroniques) ; les
            informations relatives aux garanties légales et contractuelles et à
            leurs modalités de mise en oeuvre ; la possibilité de recourir à une
            médiation conventionnelle en cas de litige ; les informations
            relatives au droit de rétractation (délai, modalités
            d&apos;exercice).
          </li>
        </ul>
        <br />
        La passation d&apos;une Commande sur le Site emporte adhésion et
        acceptation des CGVU. Le Client ne pourra se prévaloir d&apos;un
        document contradictoire.
        <br />
      </div>
    );
  }
}
