import { useEffect, useContext } from 'react';
import { LocalizeContext } from 'react-localize-redux';

/**
 *
 * @param {Object} locale object comming from locales
 * @returns {LocalizeContextProps} react-localize-context @see https://ryandrewjohnson.github.io/react-localize-redux-docs/#localizecontext
 */
function useTranslation(locale) {
  const context = useContext(LocalizeContext);
  useEffect(() => {
    context.addTranslation(locale);
  });
  return context;
}

export default useTranslation;
