import React from 'react';
import { connect } from 'react-redux';
import {
  formValueSelector,
  change,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { bindActionCreators } from 'redux';
import { getPourcentageNet } from '../../../helpers/Common';
import { getTranslate, Translate } from 'react-localize-redux';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import SwitchUI from '@material-ui/core/Switch/Switch';
import Button from '../../../components/CustomButtons/Button.jsx';
import EditableTableTakeProfits from './EditableTableTakeProfits';
import TrendingUp from '@material-ui/icons/TrendingUp';

class TakeProfitsContainer extends React.PureComponent {
  toggleLock = () => {
    const { formName, change, targetsLock } = this.props;
    change(formName, 'targetsLock', !targetsLock);
  };

  render() {
    const { values, formName, percentageNet, targetsLock } = this.props;
    const isBuy = values && values.isBuy;
    return (
      <GridItem xs={12} sm={12} md={4}>
        <Card className="smart-trade-bloc">
          <CardBody>
            <fieldset
              disabled={targetsLock}
              className={targetsLock ? 'disabled' : ''}
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {isBuy ? (
                    <Translate id="smartTrade.targetSell" />
                  ) : (
                    <Translate id="smartTrade.targetBuy" />
                  )}
                  <SwitchUI
                    checked={!targetsLock}
                    onChange={() => this.toggleLock()}
                    color="primary"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <EditableTableTakeProfits
                    formName={formName}
                    name="targets"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ textAlign: 'right' }}>
                    <Translate id="smartTrade.netPercentage" />: {percentageNet}
                    %
                  </div>
                </GridItem>
              </GridContainer>
            </fieldset>
          </CardBody>
          {targetsLock && (
            <div className="active-block">
              <Button
                color="primary"
                style={{ width: '200px' }}
                onClick={() => this.toggleLock()}
              >
                <TrendingUp style={{ margin: '4px' }} />
                <Translate id="smartTrade.setTargetProfit" />
              </Button>
            </div>
          )}
        </Card>
      </GridItem>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { localize } = state;
  const formSelector = formValueSelector(props.formName);
  return {
    translate: getTranslate(localize),
    values: getFormValues(props.formName)(state),
    syncErrors: getFormSyncErrors(props.formName)(state),
    trailingCB: formSelector(state, 'trailingCB'),
    targetsLock: formSelector(state, 'targetsLock'),
    percentageNet: getPourcentageNet(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TakeProfitsContainer);
