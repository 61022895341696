import React from 'react';
import {
  Field,
  reduxForm,
  formValueSelector,
  getFormSyncErrors,
} from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { getAllTokens } from '../../../redux/tokens/actions';
import { postExtra, putExtra } from '../../../redux/user/actions';
// Components
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import ReactTextField from '../../../components/Form/ReactTextFieldUI';
// Validator
import validate from './validate';
// Material UI
import Button from '../../../components/CustomButtons/Button';
import EditableTableTokens from './EditableTableTokens';
import { isEmpty, isNull } from '../../../helpers/Common';
import { Translate } from 'react-localize-redux';
import { createSelector } from 'reselect';

const formName = 'formExtraTokens';

class FormExtraTokens extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      exchange: null,
    };
  }

  componentDidMount() {
    this.props.getAllTokensActions();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.state.exchange == null && this.props.exchange != null) {
      // console.log('INITILIZED', this.props.initialValues,this.props.order);
      this.props.initialize(this.props.initialValues);
      // this.props.reset();
      this.setState({ exchange: this.props.exchange });
    }
  };

  postHolds = () => {
    const { myForm, isUpdate } = this.props;
    const { values } = myForm;

    for (let hold of values.holds) {
      hold.TokenId = isNull(hold.Token.value)
        ? hold.Token.TokenId
        : hold.Token.value;
    }
    if (isUpdate) this.props.putTokens(values);
    else this.props.postTokens(values);
  };

  render() {
    const { myForm, errors } = this.props;
    const hasErrors = Object.keys(errors).length !== 0;
    return (
      <form>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Field
              name="name"
              label={<Translate id="central.name" />}
              fullWidth={true}
              component={ReactTextField}
              required
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <EditableTableTokens formName={formName} name="holds" />
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center">
          <Button
            onClick={this.postHolds}
            color="primary"
            round
            disabled={isEmpty(myForm) || hasErrors}
          >
            <Translate id="externals.saveExternal" />
          </Button>
        </GridContainer>
      </form>
    );
  }
}

let formExtraTokens = reduxForm({
  form: formName, // a unique identifier for this form
  touchOnChange: true,
  validate,
})(FormExtraTokens);

const exchangeSelector = (state, props) => {
  return props.exchange;
};

const initialValuesSelector = createSelector(exchangeSelector, (exchange) => {
  if (exchange) {
    return {
      isUpdate: true,
      id: exchange.Id,
      name: exchange.Name,
      holds: exchange.Holds?.filter((x) => x.Token !== null),
    };
  }
  return {
    isUpdate: false,
    name: '',
    holds: [],
  };
});

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(formName);
  return {
    errors: getFormSyncErrors(formName)(state),
    myForm: state.form[formName],
    isUpdate: formSelector(state, 'isUpdate'),
    initialValues: initialValuesSelector(state, props),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllTokensActions: bindActionCreators(getAllTokens, dispatch),
    putTokens: bindActionCreators(putExtra, dispatch),
    postTokens: bindActionCreators(postExtra, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(formExtraTokens);
