import React from 'react';
import { Translate } from 'react-localize-redux';
import { isRequired } from '../../../helpers/form';
import { getApiLength } from '../../../helpers/exchange';
import { isBybit, isBybitFuture } from '../../../helpers/Common';

export default (values) => {
  const errors = {};
  const requiredFields = ['exchangeName'];
  const { isPaperTrading } = values;

  if (values.capitalRadio === 'Fixe' || isPaperTrading) {
    requiredFields.push('capital');
  }
  if (!isPaperTrading) {
    requiredFields.push('apiKey');
    requiredFields.push('apiSecret');
  }
  for (const field of requiredFields) {
    if (isRequired(values[field])) {
      errors[field] = <Translate id="central.required" />;
    }
  }

  if (
    !isPaperTrading &&
    values.exchangeName &&
    values.exchangeName.value &&
    values.exchangeName.value !== 'Huobi'
    // && !isBybit(values.exchangeName.value) && !isBybitFuture(values.exchangeName.value
  ) {
    const apiLength = getApiLength(values.exchangeName.exchange);
    if (
      apiLength.apiKey &&
      (!values.apiKey || values.apiKey.length !== apiLength.apiKey)
    ) {
      errors.apiKey = (
        <Translate id="exchanges.errorChar" data={{ val: apiLength.apiKey }} />
      );
    }
    if (
      apiLength.apiSecret &&
      (!values.apiSecret || values.apiSecret.length !== apiLength.apiSecret)
    ) {
      errors.apiSecret = (
        <Translate
          id="exchanges.errorChar"
          data={{ val: apiLength.apiSecret }}
        />
      );
    }
  }
  return errors;
};
