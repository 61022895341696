import React from 'react';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import Button from '../../components/CustomButtons/Button';

import { Translate } from 'react-localize-redux';
import GridItem from '../../components/Grid/GridItem.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { getShowDialog } from '../../redux/onboarding/selector';
import {
  changeShowDialog,
  changeStep,
} from '../../redux/onboarding/actions.js';
import useTranslation from '../../hooks/useTranslation';
import onboardingjson from '../../locales/onboarding.json';
import { profilConstant } from '../Onboarding/profil.constant';

const TutorialContainer = () => {
  const showDialog = useSelector(getShowDialog);
  const dispatch = useDispatch();
  useTranslation(onboardingjson);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Button
            disabled={showDialog}
            color="primary"
            onClick={() => {
              dispatch(changeStep(profilConstant.STEP_PROFIL));
              dispatch(changeShowDialog(true));
            }}
          >
            <Translate id="onboarding.relaunchOnboarding" />
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default TutorialContainer;
