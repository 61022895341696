import React from 'react';
import Button from '../../components/CustomButtons/Button';
import { Translate } from 'react-localize-redux';
import { Eject } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import WithdrawalPopup from './WithdrawalPopup';
import { makeStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: 0,
  },
}));

const WithdrawalButton = ({ totalGains }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (reason) => {
    if ('backdropClick' === reason) return;
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        size="sm"
        color="rose"
        disabled={totalGains < 50}
      >
        <Eject style={{ marginRight: '4px', marginBottom: '2px' }} />
        &nbsp;
        <Translate id="referrals.RequestWithdrawal" />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <IconButton
            edge="end"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <WithdrawalPopup stopPopup={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default WithdrawalButton;
