export const profilConstant = {
  USER_SOLO_TRADER: 'userSoloTrader',
  USER_GROUP_TRADER: 'userGroupTrader',
  USER_COPY_TRADER: 'userCopyTrader',
  USER_WALLET_TRADER: 'userWalletTrader',

  STEP_PROFIL: 'profilStep',
  STEP_ADD_EXCHANGE: 'addExchange',
  STEP_POPUP_EXCHANGE: 'popupExchange',
  STEP_ADD_TRADER: 'addTrader',
  STEP_GROUP_CHOICES: 'groupChoices',
  STEP_WHITE_LABEL: 'whiteLabel',
  STEP_SHARE_COMMUNITY: 'shareCommunity',
};
