import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import transError from '../../locales/error.json';
import transSmartTrade from '../../locales/smartTrade.json';
import transOrders from '../../locales/orders';
import transConfirm from '../../locales/confirmation.json';
import transExchanges from '../../locales/exchanges';

import useTranslation from '../../hooks/useTranslation';
import { orderByIdSelector } from '../../redux/order/selector';
import FormSmartTrade from './FormSmartTrade';

/**
 * SmartTradePage mobile screen size
 */

const SmartTradePageMobile = () => {
  useTranslation(transExchanges);
  useTranslation(transError);
  useTranslation(transSmartTrade);
  useTranslation(transOrders);
  useTranslation(transConfirm);
  const { id: orderId } = useParams();
  const currentOrder = useSelector(orderByIdSelector(orderId));
  return <FormSmartTrade order={currentOrder} />;
};

export default SmartTradePageMobile;
