import React from 'react';
import { connect } from 'react-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';

import { bindActionCreators } from 'redux';
import { getStatsGroup, IsAdmin } from '../../../redux/admin/actions';
import TableStatsGroup from './TableStatsGroup';

class StatsGroupPage extends React.Component {
  componentDidMount() {
    this.props.getStatsGroupActions();
    this.props.IsAdminActions();
  }

  render() {
    const { statsGroup } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          Nombre de groupe: {statsGroup.length}
          <TableStatsGroup statsGroup={statsGroup} />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    statsGroup: admin.statsGroup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStatsGroupActions: bindActionCreators(getStatsGroup, dispatch),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatsGroupPage);
