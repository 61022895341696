import ReactGA from 'react-ga';
import { push } from 'connected-react-router';
import { authenticationConstants } from './constants';
import { Translate as translateGeneral } from '../../helpers/general';
import { fetcher } from '../../helpers/fetcher';

export const register = (auth) => async (dispatch, getState) => {
  const eu = await fetcher(dispatch, 'api/v1/Account/Register', {
    method: 'POST',
    success: translateGeneral(getState, 'confirmation.successRegister'),
    duration: 60000,
    body: auth,
  });
  if (!eu) {
    ReactGA.event({
      category: 'User',
      action: 'Create an Account',
    });
    dispatch({
      type: authenticationConstants.REGISTER_SUCCESS,
      payload: eu,
    });
    // window.location.reload();
  }
};

export const login = (body) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Account/Login', {
    method: 'POST',
    body,
  });
  if (eu) {
    localStorage.setItem('authentication', JSON.stringify(eu));
    localStorage.setItem('tfaActive', JSON.stringify(eu.tfaActive === 'true'));
    ReactGA.event({
      category: 'User',
      action: 'Login',
      label: eu.id,
    });
    ReactGA.set({ userId: eu.id });
    dispatch({
      type: authenticationConstants.LOGIN_SUCCESS,
      payload: eu,
    });
  }
};

export const confirmEmail = (query) => async (dispatch, getState) => {
  await fetcher(dispatch, `api/v1/Account/ConfirmEmail${query}`, {
    method: 'POST',
    success: translateGeneral(getState, 'confirmation.confirmedEmail'),
  });
};

export const forgotPassword = (email) => async (dispatch, getState) => {
  await fetcher(dispatch, 'api/v1/Account/ForgotPassword', {
    method: 'POST',
    success: translateGeneral(getState, 'confirmation.sentEmail'),
    body: email,
  });
};

export const resetPassword = (data) => async (dispatch, getState) => {
  await fetcher(dispatch, 'api/v1/Account/ResetPassword', {
    method: 'POST',
    success: translateGeneral(getState, 'confirmation.passwordReset'),
    body: data,
  });
};

export const removeLocalStorageUser = () => {
  localStorage.removeItem('exchanges');
  localStorage.removeItem('myGroups');
  localStorage.removeItem('orderstrue');
  localStorage.removeItem('ordersfalse');
  localStorage.removeItem('orders0');
  localStorage.removeItem('orders1');
  localStorage.removeItem('orders2');
  localStorage.removeItem('orders3');
  localStorage.removeItem('orders4');
  localStorage.removeItem('orders5');
  localStorage.removeItem('traders');
  localStorage.removeItem('InitSteppers');
  localStorage.removeItem('dateOnboarding');
  localStorage.removeItem('nbExchange');
};

export const logoutFunc = async (dispatch) => {
  // const eu = await fetcher(dispatch, 'api/v1/Account/Logout', {
  //   method: 'POST',
  // });
  dispatch({
    type: authenticationConstants.LOGOUT,
  });
  // const auth = JSON.parse(localStorage.getItem('authentication'));
  // ReactGA.event({
  //   category: 'User',
  //   action: 'Logout',
  //   label: auth.id
  // });
  removeLocalStorageUser();
  localStorage.removeItem('authentication');
  // window.location.reload();
  dispatch(push('/identification?login'));
  // dispatch(push('/identification?login'));
  // dispatch(push('/identification?login'));
};

export const logout = () => async (dispatch) => {
  await logoutFunc(dispatch);
};

export const redirectToLogin = () => async (dispatch) => {
  dispatch(push('/identification?login'));
};

export const addFakeTelegram = () => async (dispatch) => {
  dispatch({
    type: authenticationConstants.ADD_FAKE_TELEGRAM,
    payload: 'init',
  });
};

export const changeLang = (lang) => {
  fetcher(null, `api/v1/Account/Lang?lang=${lang}`, {
    method: 'POST',
  }).then();
};

export const getTFA = (secret) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v1/Account/TFA?secret=${secret}`, {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: authenticationConstants.GET_TFA,
      payload: eu,
    });
  }
};

export const setTFA = (secret, code, active) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Account/TFA?secret=${secret}&code=${code}&active=${active}`,
    {
      method: 'POST',
    },
  );
  if (eu) {
    localStorage.setItem('tfaActive', JSON.stringify(active));
    dispatch({
      type: authenticationConstants.SET_TFA,
      payload: active,
    });
  }
};
