import Autorenew from '@material-ui/icons/Autorenew';
import Beenhere from '@material-ui/icons/Beenhere';
import Create from '@material-ui/icons/Create';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Visibility from '@material-ui/icons/Visibility';
import React from 'react';
import { connect } from 'react-redux';
import {
  dangerColor,
  infoColor,
  successColor,
  warningColor,
} from '../../assets/jss/material-dashboard-react';
import {
  getPourcentage,
  getTotalUSD,
  isBTC,
  isReferral,
  isStableCoin,
  isHedgeMode,
} from '../../helpers/exchange';

import { Info } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { getTranslate, Translate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import PieChartExchange from '../../components/Charts/PieChartExchange';
import Button from '../../components/CustomButtons/Button';
import MenuButtons from '../../components/CustomButtons/MenuButtons';
import { ShowAccount } from '../../components/Fields/LogoContainer';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import MyTooltip from '../../components/MyTooltip';
import { getActiveCode } from '../../helpers/localize';
import GetMenuItem from '../../helpers/menuItems';
import { getEmailByAccountId } from '../../redux/admin/actions';
import { alertPopup } from '../../redux/alert/alert.actions';
import {
  deleteExchanges,
  refreshExchange,
  sellAllTo,
  updateExchanges,
} from '../../redux/user/actions';
import {
  getBaseUSDPairExchange,
  isBinanceFuture,
  isEmpty,
  isNull,
  parseToFloat,
} from '../../helpers/Common';
import RadioOrderPosition from '../SmartTrade/Container/RadioOrderPosition';
import PositionContainer from './PositionContainer';
import AdminMenuButtons from '../../components/CustomButtons/AdminMenuButtons';

class ExchangeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPositions: props.showPositions //If exist
        ? props.showPositions
        : false,
      clicked: false,
    };
  }
  onDelete = (id) => {
    this.props.deleteExchangesActions(id);
  };
  remove = (id) => {
    const { translate } = this.props;
    if (!window.confirm(translate('confirmation.areYouSure'))) return;
    this.onDelete(id);
  };
  refresh = (id) => {
    this.props.refreshActions(id);
  };
  render() {
    const {
      exchange,
      showButton,
      translate,
      loading,
      isAdmin,
      hidePositions,
      emailByAccount,
    } = this.props;
    const showPosition =
      isAdmin || (!isNull(hidePositions) && hidePositions === false);
    let { showPositions } = this.state;
    if (isNull(showPositions)) showPositions = false;
    const hasPosition = !(
      isEmpty(exchange.Positions) || isEmpty(exchange.Positions[0])
    );
    const isArray = Array.isArray(exchange);
    const isExchange = exchange && exchange.AccountType === 'Exchange';
    const isOffExchange = exchange && exchange.AccountType === 'OffExchange';
    const isPaperWallet = exchange && exchange.IsPaperTrading;
    const title = ShowAccount(exchange);
    const totalUSD = getTotalUSD(exchange);
    const totalUSDStableCoin = getTotalUSD(exchange, isStableCoin);
    const totalUSDBTC = getTotalUSD(exchange, isBTC);
    const totalUSDAlt = parseToFloat(
      totalUSD - totalUSDStableCoin - totalUSDBTC,
      0.1,
      true,
    );
    const isBinanceFutures = exchange && isBinanceFuture(exchange.ExchangeName);
    const pair = isExchange
      ? getBaseUSDPairExchange(exchange.ExchangeName, 'BTC')
      : '';
    const isLoading = loading.type === 'ON';
    const splitPair = pair.split('-');

    const getButtons = () => {
      return (
        <>
          {isAdmin && (
            <>
              {isEmpty(emailByAccount) && (
                <GetMenuItem
                  icon={<Info htmlColor={infoColor} />}
                  text="Infos"
                  // text={<Translate id="" />}
                  onClick={() =>
                    this.props.getEmailByAccountIdActions(exchange.Id)
                  }
                />
              )}
              {!isEmpty(emailByAccount) && (
                <AdminMenuButtons user={emailByAccount} />
              )}
            </>
          )}
          <GetMenuItem
            icon={<Autorenew />}
            text={<Translate id="exchanges.refresh" />}
            onClick={() => this.refresh(exchange.Id)}
          />
          {!isPaperWallet && (
            <GetMenuItem
              icon={<Visibility htmlColor={infoColor} />}
              text={<Translate id="exchanges.seeDetails" />}
              to={'/exchange/' + exchange.Id}
            />
          )}
          {isOffExchange && (
            <GetMenuItem
              icon={<Create htmlColor={warningColor} />}
              text={<Translate id="exchanges.update" />}
              to={'/extraTokens/' + exchange.Id}
            />
          )}
          {isExchange && !isPaperWallet && (
            <GetMenuItem
              icon={<Create htmlColor={warningColor} />}
              text={<Translate id="exchanges.update" />}
              to={'/exchanges/' + exchange.Id}
            />
          )}
          <GetMenuItem
            icon={<DeleteForever htmlColor={dangerColor} />}
            text={<Translate id="central.delete" />}
            onClick={() => this.remove(exchange.Id)}
          />
        </>
      );
    };

    return (
      <Card>
        <CardHeader color="primary">
          <GridContainer alignItems="center">
            <GridItem xs>{title}</GridItem>
            {isHedgeMode(exchange?.Options) && (
              <GridItem alignItems="center" justifyContent="flex-end">
                <MyTooltip
                  text={'Hedge Mode Active'}
                  enterDelay={200}
                  leaveDelay={200}
                >
                  <div>HM</div>
                </MyTooltip>
              </GridItem>
            )}
            {isReferral(exchange) && (
              <GridItem alignItems="center" justifyContent="flex-end">
                <MyTooltip
                  text={<Translate id="exchanges.isNewBinance" />}
                  enterDelay={200}
                  leaveDelay={200}
                >
                  <Beenhere
                    htmlColor={successColor}
                    style={{ marginTop: '6px' }}
                  />
                </MyTooltip>
              </GridItem>
            )}
            {!isArray && showButton && exchange && (
              <GridItem alignItems="center" justifyContent="flex-end">
                <MenuButtons>{getButtons()}</MenuButtons>
              </GridItem>
            )}
          </GridContainer>
        </CardHeader>
        <CardBody>
          {showPosition && hasPosition && (
            <RadioOrderPosition
              showPositions={showPositions}
              onChange={(value) => this.setState({ showPositions: value })}
              positionCount={exchange.Positions.length}
            />
          )}
          {showPosition && showPositions && (
            <GridContainer>
              <GridItem
                container
                alignItems="center"
                xs={12}
                sm={12}
                md={12}
                style={{
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {!hasPosition && <div>Empty</div>}
                {hasPosition && (
                  <PositionContainer exchange={exchange} isAdmin={isAdmin} />
                )}
              </GridItem>
            </GridContainer>
          )}
          {!showPositions && (
            <GridContainer>
              <GridItem xs={5} sm={5} md={5}>
                <PieChartExchange exchange={exchange} />
              </GridItem>
              <GridItem container alignItems="center" xs={7} sm={7} md={7}>
                <GridContainer>
                  <GridItem container alignItems="center">
                    <Translate id="exchanges.total" /> BTC: &nbsp;
                    <b>{totalUSDBTC} $</b>
                    &nbsp; ({getPourcentage(totalUSDBTC, totalUSD)} %)
                  </GridItem>
                  <GridItem container alignItems="center">
                    <Translate id="exchanges.total" /> Alt: &nbsp;
                    <b>{totalUSDAlt} $</b>
                    &nbsp; ({getPourcentage(totalUSDAlt, totalUSD)} %)
                  </GridItem>
                  <GridItem container alignItems="center">
                    <Translate id="exchanges.total" /> Stable: &nbsp;
                    <b>{totalUSDStableCoin} $</b>
                    &nbsp; ({getPourcentage(totalUSDStableCoin, totalUSD)} %)
                  </GridItem>
                  <GridItem container alignItems="center">
                    <Translate id="exchanges.totalUSD" />: &nbsp;
                    <b>{totalUSD} $</b>
                  </GridItem>
                  {isExchange && (
                    <GridItem container alignItems="center">
                      <Translate id="exchanges.capital" />
                      {': '}
                      &nbsp;
                      <b>
                        {exchange.Capital === null
                          ? totalUSD
                          : exchange.Capital}{' '}
                        $
                      </b>
                    </GridItem>
                  )}
                </GridContainer>
              </GridItem>
            </GridContainer>
          )}
          {showButton &&
            isExchange &&
            !isPaperWallet &&
            splitPair.length > 1 && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem md={6} container justifyContent="center">
                      <MyTooltip
                        text={
                          translate('exchanges.sellAllTo') + ' ' + splitPair[0]
                        }
                        enterDelay={200}
                        leaveDelay={200}
                      >
                        <Button
                          color="primary"
                          size="sm"
                          disabled={isBinanceFutures || isLoading}
                          onClick={() =>
                            this.props.alertActions({
                              type: 'confirm',
                              then: (result) => {
                                if (result.value) {
                                  this.props.sellAllToActions({
                                    AAccountId: exchange.Id,
                                    ToBaseAsset: splitPair[0],
                                  });
                                }
                              },
                            })
                          }
                        >
                          <Translate id="exchanges.sellAllTo" />
                          {' ' + splitPair[0]}
                        </Button>
                      </MyTooltip>
                    </GridItem>
                    <GridItem md={6} container justifyContent="center">
                      <MyTooltip
                        text={
                          translate('exchanges.sellAllTo') + ' ' + splitPair[1]
                        }
                        enterDelay={200}
                        leaveDelay={200}
                      >
                        <Button
                          color="primary"
                          size="sm"
                          disabled={isBinanceFutures || isLoading}
                          onClick={() =>
                            this.props.alertActions({
                              type: 'confirm',
                              then: (result) => {
                                if (result.value) {
                                  this.props.sellAllToActions({
                                    AAccountId: exchange.Id,
                                    ToBaseAsset: splitPair[1],
                                  });
                                }
                              },
                            })
                          }
                        >
                          <Translate id="exchanges.sellAllTo" />
                          {' ' + splitPair[1]}
                        </Button>
                      </MyTooltip>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            )}
        </CardBody>
      </Card>
    );
  }
}

ExchangeContainer.propTypes = {
  exchange: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.object.isRequired,
  ]),
};

function mapStateToProps(state) {
  const { localize, loading, admin } = state;
  return {
    loading,
    translate: getTranslate(localize),
    getActiveCode: getActiveCode(state),
    emailByAccount: admin.emailByAccount,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    alertActions: bindActionCreators(alertPopup, dispatch),
    refreshActions: bindActionCreators(refreshExchange, dispatch),
    deleteExchangesActions: bindActionCreators(deleteExchanges, dispatch),
    updateExchangesActions: bindActionCreators(updateExchanges, dispatch),
    sellAllToActions: bindActionCreators(sellAllTo, dispatch),
    getEmailByAccountIdActions: bindActionCreators(
      getEmailByAccountId,
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeContainer);
