import React from 'react';
import { connect } from 'react-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';

import { bindActionCreators } from 'redux';
import { getPaiements, IsAdmin } from '../../../redux/admin/actions';
import TablePaiements from './TablePaiements';
import { getTraders } from '../../../redux/trader/actions';
import { tradersSelector } from '../../../redux/trader/selector';

class PaiementsPage extends React.Component {
  componentDidMount() {
    this.props.getPaiementsActions();
    this.props.getTradersActions();
    this.props.IsAdminActions();
  }

  render() {
    const { paiements, traders } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          Nombre de paiements: {paiements.length}
          {traders.length !== 0 && paiements.length !== 0 && (
            <TablePaiements paiements={paiements} traders={traders} />
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    paiements: admin.paiements,
    traders: tradersSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPaiementsActions: bindActionCreators(getPaiements, dispatch),
    getTradersActions: bindActionCreators(getTraders, dispatch),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaiementsPage);
