import React, { PureComponent } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';
import connect from 'react-redux/es/connect/connect';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_dark);

class FunnelHorizontal extends PureComponent {
  componentDidMount() {
    const { data, name, theme } = this.props;
    if (!data) return;
    let chart = am4core.create(name, am4charts.SlicedChart);
    chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

    chart.data = data;

    let series = chart.series.push(new am4charts.FunnelSeries());
    series.colors.step = 2;
    series.dataFields.value = 'value';
    series.dataFields.category = 'name';
    series.alignLabels = true;
    series.orientation = 'horizontal';
    series.bottomRatio = 1;
    series.stroke = theme === 'dark' ? '#fff' : '#000';

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';
    chart.legend.labels.template.fill = theme === 'dark' ? '#fff' : '#000';

    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { name } = this.props;

    return (
      <div
        id={name}
        style={{ width: '100%', height: '300px', margin: '10px 0px 0 0px' }}
      />
    );
  }
}

function mapStateToProps(state) {
  const { config } = state;
  return {
    theme: config.theme,
  };
}

export default connect(mapStateToProps, null)(FunnelHorizontal);
