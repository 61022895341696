import React from 'react';
import { Collapse } from '@material-ui/core';
import {
  getAverageTotalPrice,
  getAverageTotalProfit,
  getTotalPourcentage, getTotalQuantity, getTotalQuantityFilled, getTriggerPrice
} from '../../helpers/order';
import ActionOrderLine from './ActionOrderLine';
import { parseToFloat } from '../../helpers/Common';

export default class OrderTriggersContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getMaps = (triggers, order) => {
    return triggers.map((trigger, index) => (
      <ActionOrderLine key={index} order={order} action={trigger} />
    ));
  };

  getCollapsed = (state) => {
    const { order, triggers, stepPrice, stepQty } = this.props;
    const collapsed = this.state[state] ?? false;
    let triggersFiltred = triggers.filter(
      (trigger) => trigger.State === state,
    );
    triggersFiltred = triggersFiltred.sort((a, b) => {
      return getTriggerPrice(a) - getTriggerPrice(b);
    });
    if (triggersFiltred.length === 1)
      return <ActionOrderLine order={order} action={triggersFiltred[0]} />;
    return (
      <>
        <div
          onClick={() => this.setState({ [state]: !collapsed })}
          style={{ cursor: 'pointer' }}
        >
          {triggersFiltred.length > 0 && (
            <ActionOrderLine
              nbTriggers={triggersFiltred.length}
              collapsed={collapsed}
              order={order}
              action={{
                Pourcentage: parseToFloat(
                  getTotalPourcentage(triggersFiltred),
                  0.01,
                ),
                Profit: getAverageTotalProfit(triggersFiltred),
                Price: getAverageTotalPrice(triggersFiltred, stepPrice),
                Quantity: parseToFloat(getTotalQuantity(triggersFiltred), stepQty),
                QuantityFilled: parseToFloat(getTotalQuantityFilled(triggersFiltred), stepQty),
                TriggerId: 0,
                Action: triggersFiltred[0].Action,
                OrderId: order.OrderId,
                State: state,
                DateTrigged: new Date(),
                Order: order,
              }}
            />
          )}
        </div>
        {triggersFiltred.length > 0 && (
          <Collapse in={collapsed}>
            {this.getMaps(triggersFiltred, order)}
          </Collapse>
        )}
      </>
    );
  };

  render() {
    const { order, triggers } = this.props;
    if (!triggers || triggers.length === 0) return <div />;

    if (triggers.length === 1) return this.getMaps(triggers, order);
    const states = [3, 0, 1, 2, 4, 5, 6, 7];
    return (
      <div>
        {states.map((state) => {
          return this.getCollapsed(state);
        })}
      </div>
    );
  }
}
