import React from 'react';
import { bindActionCreators } from 'redux';
import { change, Field, formValueSelector } from 'redux-form';
import {
  getMyGroupsSelect,
  getPrimFromPair,
  getSecFromPair,
  secPriceSelector,
  parseToFloat,
  stepPriceSelector,
  stepQtySelector,
  getTotalCapital,
} from '../../../helpers/Common';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import ReactSelect from '../../../components/Form/ReactSelectUI';
import SwitchUI from '@material-ui/core/Switch/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Grid from '@material-ui/core/Grid';

class GroupsContainer extends React.PureComponent {
  toggleDontDo = () => {
    const { formName, change, dontDoOrder } = this.props;
    // console.log('toggleDontDo');
    change(formName, 'dontDoOrder', !dontDoOrder);
  };

  render() {
    const {
      groups,
      isUpdate,
      isTemplate,
      dontDoOrder,
      pair,
      exchange,
      stepPrice,
      lotStep,
      secPrice,
      totalCapital,
      startPrice,
      isBuy,
    } = this.props;
    const hasGroup = groups && groups.length > 0;
    const primStr = getPrimFromPair(pair);
    const secStr = getSecFromPair(pair);
    const isBitmex = exchange && exchange.exchange === 'Bitmex';
    const isUpdatable = isUpdate && !isTemplate;
    return (
      <GridItem xs={12} sm={12} md={12}>
        {hasGroup && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Field
                name="groupId"
                label={<Translate id="smartTrade.group" />}
                options={groups}
                fullWidth={true}
                component={ReactSelect}
                required
                disabled={isUpdatable}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Translate id="smartTrade.dontDoOrder" />
              <SwitchUI
                defaultChecked={dontDoOrder}
                checked={dontDoOrder}
                onChange={() => this.toggleDontDo()}
                color="primary"
                disabled={isUpdatable}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Grid container style={{ textAlign: 'left' }}>
                {primStr && (
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel
                      style={{ fontSize: '0.75rem' }}
                      component="span"
                    >
                      {!isBitmex ? primStr : 'XBT'}{' '}
                      <Translate id="smartTrade.followed" />:
                    </InputLabel>
                    <span
                      style={{ textDecoration: !isBuy ? 'underline' : 'none' }}
                    >
                      {parseToFloat(
                        totalCapital / startPrice / secPrice,
                        lotStep,
                      )}
                    </span>
                  </Grid>
                )}
                {!primStr && <Grid item xs={12} sm={12} md={6} />}
                {secStr && (
                  <GridItem xs={12} sm={12} md={6}>
                    <InputLabel
                      style={{ fontSize: '0.75rem' }}
                      component="span"
                    >
                      {secStr} <Translate id="smartTrade.followed" />:
                    </InputLabel>
                    <span
                      style={{ textDecoration: isBuy ? 'underline' : 'none' }}
                    >
                      {parseToFloat(totalCapital / secPrice, stepPrice)}
                    </span>
                  </GridItem>
                )}
              </Grid>
            </GridItem>
          </GridContainer>
        )}
        {!hasGroup && <div style={{ height: '96px' }} />}
      </GridItem>
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  return {
    groups: getMyGroupsSelect(state),
    isUpdate: formSelector(state, 'isUpdate'),
    isTemplate: formSelector(state, 'isTemplate'),
    pair: formSelector(state, 'pair'),
    exchange: formSelector(state, 'accountId'),
    startPrice: formSelector(state, 'startPrice'),
    isBuy: formSelector(state, 'isBuy'),
    dontDoOrder: formSelector(state, 'dontDoOrder'),
    stepPrice: stepPriceSelector(state, props),
    lotStep: stepQtySelector(state, props),
    secPrice: secPriceSelector(state, props),
    totalCapital: getTotalCapital(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsContainer);
