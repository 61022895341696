import { referralsConstants } from './constants';
import { alertActions } from '../alert/alert.actions';
import { Translate } from '../../helpers/general';
import { fetcher } from '../../helpers/fetcher';

export const createReferralsGridAction = (
  partialStateName,
  partialStateValue,
) => {
  if (partialStateName === 'pageSize' && partialStateValue === 0)
    partialStateValue = 50;
  return {
    type: referralsConstants.REFERRALS_GRID_STATE_CHANGE_ACTION,
    partialStateName,
    partialStateValue,
  };
};

export const manageReferralsGridAction = (
  partialStateName,
  partialStateValue,
) => {
  if (partialStateName === 'pageSize' && partialStateValue === 0)
    partialStateValue = 50;
  return {
    type: referralsConstants.REFERRALS_GRID_STATE_CHANGE_ACTION,
    partialStateName,
    partialStateValue,
  };
};

export const resetReferralsColumns = () => ({
  type: referralsConstants.REFERRALS_GRID_RESET,
});

// eslint-disable-next-line no-unused-vars
export const sendWithdrawal = (body) => async (dispatch, getState) => {
  // const eu = await fetcher(dispatch, 'api/ReferralWithdrawal', {
  //   method: 'POST',
  //   body: body,
  // });
  // if (eu) {
  const text = Translate(getState, 'referrals.referralWithdrawalSent');
  dispatch(alertActions.alertSuccess(text));
  // }
};

export const createCampaign = (body) => async (dispatch, getState) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Referral/campaigns?campaignName=${body.name}`,
    {
      method: 'POST',
    },
  );

  if (eu) {
    dispatch({
      type: referralsConstants.POST_CAMPAIGN,
      payload: eu,
    });
    const text = Translate(getState, 'referrals.referralNameAdded');
    dispatch(alertActions.alertSuccess(text));
  }
};

export const getCampaigns = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Referral/campaigns', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: referralsConstants.GET_CAMPAIGNS,
      payload: eu,
    });
  }

  // const referrals = [
  //   {
  //     campaignId: 0,
  //     name: 'totc',
  //     nbClick: 1,
  //     nbSubscribe: 2,
  //     nbProfitable: 3,
  //     referralCode: '52125TestRef1',
  //     amountWithdrawn: 25,
  //     gains: [
  //       {
  //         amount: 2,
  //         token: {
  //           tokenId: 0,
  //           name: 'Bitcoin',
  //           symbol: 'BTC',
  //           price: 25,
  //         },
  //       },
  //       {
  //         amount: 2,
  //         token: {
  //           tokenId: 1,
  //           name: 'Ethereum',
  //           symbol: 'Eth',
  //           price: 75,
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     campaignId: 1,
  //     name: 'tata',
  //     nbClick: 5,
  //     nbSubscribe: 6,
  //     nbProfitable: 7,
  //     referralCode: '52125TestRef2',
  //     amountWithdrawn: 25,
  //     gains: [
  //       {
  //         amount: 2,
  //         token: {
  //           tokenId: 1,
  //           name: 'Bitcoin',
  //           symbol: 'BTC',
  //           price: 10,
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     campaignId: 2,
  //     name: 'tototata',
  //     nbClick: 10,
  //     nbSubscribe: 11,
  //     nbProfitable: 12,
  //     referralCode: '52125TestRef3',
  //     amountWithdrawn: 25,
  //     gains: [
  //       {
  //         amount: 40,
  //         token: {
  //           TokenId: 2,
  //           name: 'Bitcoin',
  //           symbol: 'BTC',
  //           price: 10,
  //         },
  //       },
  //     ],
  //   },
  // ];
  // dispatch({
  //   type: referralsConstants.GET_REFERRALS,
  //   payload: referrals,
  // });
};
// };
