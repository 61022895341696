import DashboardPage from '../views/Dashboard/DashboardPage.jsx';
import TradersPage from '../views/Traders/TradersPage.jsx';
import TraderPage from '../views/Trader/TraderPage.jsx';
import ExchangePage from '../views/Accounts/Exchange/ExchangePage.jsx';
import ExternalPage from '../views/Accounts/Externals/ExternalPage';
import ExtraTokensPage from '../views/Accounts/ExtraTokens/ExtraTokensPage';
import IdentificationPage from '../views/Authentication/IdentificationPage.jsx';
import LogoutPage from '../views/Authentication/LogoutPage.jsx';
import ConfirmationEmailPage from '../views/Authentication/ConfirmationEmailPage.jsx';
import UnsubscribeEmailPage from '../views/Authentication/UnsubscribeEmailPage.jsx';
import ForgotPasswordPage from '../views/Authentication/ForgotPasswordPage.jsx';
import ResetPasswordPage from '../views/Authentication/ResetPasswordPage.jsx';
import SmartTradePage from '../views/SmartTrade/SmartTradePage.jsx';
import OrdersPage from '../views/Orders/OrdersPage.jsx';
import ExchangesPage from '../views/Accounts/Exchanges/ExchangesPage.jsx';
import FAQPage from '../views/Faq/FaqPage.jsx';
import AccountsPage from '../views/Accounts/AccountsPage.jsx';
import SettingsPage from '../views/Settings/SettingsPage.jsx';

import PolitiqueConfidentialitePage from '../views/Laws/PolitiqueConfidentialitePage.jsx';
import PrivacyPolicyPage from '../views/Laws/PrivacyPolicyPage.jsx';
import PrivacyPolicyRUPage from '../views/Laws/PrivacyPolicyRUPage.jsx';
import CGVUPage from '../views/Laws/CGVUPage.jsx';
import TermsAndConditionsPage from '../views/Laws/TermsAndConditionsPage.jsx';
import TermsAndConditionsRUPage from '../views/Laws/TermsAndConditionsRUPage.jsx';

import SubGroupsPage from '../views/Admin/SubGroups/SubGroupsPage.jsx';
import InscriptionGroupsPage from '../views/Admin/InscriptionGroups/InscriptionGroupsPage';
import StatsGroupPage from '../views/Admin/StatsGroup/StatsGroupPage.jsx';
import StatGroupPage from '../views/Admin/StatsGroup/StatGroupPage.jsx';
import HistoricEmailPage from '../views/Admin/HistoricEmail/HistoricEmailPage';
import UsersPage from '../views/Admin/Users/UsersPage.jsx';
import UserOrdersPage from '../views/Admin/Users/UserOrdersPage.jsx';
import DashboardAdminPage from '../views/Admin/DashboardAdminPage.jsx';
import OrdersAdminPage from '../views/Admin/Users/OrdersPage.jsx';
import OrdersLimitAdminPage from '../views/Admin/Users/OrdersLimitPage.jsx';
import OrdersPbAdminPage from '../views/Admin/Users/OrdersPbPage.jsx';
import PaiementsPage from '../views/Admin/Paiements/PaiementsPage.jsx';
import VolumesPage from '../views/Admin/Volumes/VolumesPage.jsx';
import WebSocketsPage from '../views/Admin/WebSockets/WebSocketsPage.jsx';
import SearchPage from '../views/Admin/Search/SearchPage.jsx';
import SearchV1Page from '../views/Admin/Search/SearchV1Page.jsx';

import { Translate } from 'react-localize-redux';
import React from 'react';
import PaiementPage from '../views/Paiement/PaiementPage';
import { isLogged, showInitSteppers } from '../helpers/general';
import StrategyConfigurationsPage from '../views/StrategyConfigurations/StrategyConfigurationsPage';
import EmailsPage from '../views/Admin/Emails/EmailsPage';
import CompanyPage from '../views/Admin/Company/CompanyPage';
import OrderInfoPage from '../views/Admin/Orders/OrderInfoPage';
import AddTraderPage from '../views/Trader/AddTraderPage';
import UpdateTraderPage from '../views/Trader/UpdateTraderPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faBlog } from '@fortawesome/free-solid-svg-icons';
import { blogLinks, tutorialLinks } from '../helpers/externalLink';
import AccountsGroupPage from '../views/Admin/StatsGroup/AccountsGroupPage';
import AddFollowerPage from '../views/Follower/AddFollowerPage';
import UpdateFollowerPage from '../views/Follower/UpdateFollowerPage';
import ManageFollowersPage from '../views/Followers/ManageFollowersPage';
import ReferralsPage from '../views/Referrals/ReferralsPage.jsx';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LeaderBoardPage from '../views/Admin/LeaderBoard/LeaderBoardPage.jsx';
import PanicSellAllPage from '../views/Admin/PanicSellAll/PanicSellAllPage.jsx';
import SmartTradePageV1 from '../views/SmartTradeV1/SmartTradePage.jsx';

export const adminUrl = '/adminwotwot2019';
export const traderUrl = '/admin';

export const getDashboardRoutes = (auth, lang = 'en') => {
  let dashboardRoutes = [];
  const logged = isLogged(auth);
  const initSteppers = showInitSteppers();

  if (initSteppers) {
    dashboardRoutes.push({
      link: tutorialLinks[lang],
      navbarName: <Translate id="menu.tutorial" />,
      icon: <FontAwesomeIcon icon={faGraduationCap} />,
      sidebarName: <Translate id="menu.tutorial" />,
    });
  }
  dashboardRoutes.push(
    {
      path: '/dashboard',
      sidebarName: <Translate id="menu.myDashboard" />,
      navbarName: <Translate id="menu.myDashboard" />,
      icon: 'dashboard',
      component: DashboardPage,
      disabled: !logged,
    },
    {
      path: '/extraTokens/:id',
      navbarName: 'My Extra Tokens',
      component: ExtraTokensPage,
    },
    {
      path: '/extraTokens',
      navbarName: 'My Extra Tokens',
      component: ExtraTokensPage,
    },
    {
      path: '/V1-smartTrade/:id',
      navbarName: <Translate id="menu.smartTradeV1" />,
      component: SmartTradePageV1,
    },
    {
      path: '/smartTrade/:id',
      navbarName: <Translate id="menu.smartTrade" />,
      component: SmartTradePage,
    },
    {
      path: '/V1-smartTrade',
      sidebarName: <Translate id="menu.smartTradeV1" />,
      navbarName: <Translate id="menu.smartTradeV1" />,
      icon: 'equalizer',
      component: SmartTradePageV1,
      disabled: !logged,
    },
    {
      path: '/smartTrade',
      sidebarName: <Translate id="menu.smartTrade" />,
      navbarName: <Translate id="menu.smartTrade" />,
      icon: 'equalizer',
      component: SmartTradePage,
      disabled: !logged,
    },
    {
      path: adminUrl + '/user/:id/orders',
      navbarName: 'Admin users',
      component: UserOrdersPage,
    },
    {
      path: adminUrl + '/user/order/:id',
      navbarName: 'Admin Info User',
      component: OrderInfoPage,
    },
    {
      path: adminUrl + '/users',
      navbarName: 'Admin users',
      component: UsersPage,
    },
    {
      path: adminUrl + '/volumes',
      navbarName: 'Admin users volumes',
      component: VolumesPage,
    },
    {
      path: adminUrl + '/subGroups',
      navbarName: 'SubGroups',
      component: SubGroupsPage,
    },
    {
      path: adminUrl + '/inscriptionGroups',
      navbarName: 'inscriptionGroups',
      component: InscriptionGroupsPage,
    },
    {
      path: adminUrl + '/orders',
      navbarName: 'Orders',
      component: OrdersAdminPage,
    },
    {
      path: adminUrl + '/ordersLimit',
      navbarName: 'Orders Limit',
      component: OrdersLimitAdminPage,
    },
    {
      path: adminUrl + '/ordersPb',
      navbarName: 'Orders PB',
      component: OrdersPbAdminPage,
    },
    {
      path: adminUrl + '/statGroup/:id',
      navbarName: 'StatGroup',
      component: StatGroupPage,
    },
    {
      path: traderUrl + '/accounts/:id',
      navbarName: 'Accounts',
      component: AccountsGroupPage,
    },
    {
      path: adminUrl + '/statsGroup',
      navbarName: 'StatsGroup',
      component: StatsGroupPage,
    },
    {
      path: adminUrl + '/historicEmail',
      navbarName: 'Historic Email',
      component: HistoricEmailPage,
    },
    {
      path: adminUrl + '/paiements',
      navbarName: 'Paiements',
      component: PaiementsPage,
    },
    {
      path: adminUrl + '/emails',
      navbarName: 'Emails',
      component: EmailsPage,
    },
    {
      path: adminUrl + '/company',
      navbarName: 'Company',
      component: CompanyPage,
    },
    {
      path: adminUrl + '/webSockets',
      navbarName: 'WebSockets',
      component: WebSocketsPage,
    },
    {
      path: adminUrl + '/searchV1',
      navbarName: 'SearchV1',
      component: SearchV1Page,
    },
    {
      path: adminUrl + '/search',
      navbarName: 'Search',
      component: SearchPage,
    },
    {
      path: adminUrl + '/leaderBoard',
      navbarName: 'LeaderBoard',
      component: LeaderBoardPage,
    },
    {
      path: adminUrl + '/panicSellAll',
      navbarName: 'PanicSellAll',
      component: PanicSellAllPage,
    },
    {
      path: adminUrl,
      navbarName: 'Admin',
      component: DashboardAdminPage,
    },
    {
      path: '/trader/:groupId',
      navbarName: 'Trader',
      component: TraderPage,
    },
    {
      path: '/traders',
      sidebarName: <Translate id="menu.traders" />,
      navbarName: <Translate id="menu.traders" />,
      icon: 'accessibility_new',
      component: TradersPage,
    },
    {
      path: '/orders',
      sidebarName: <Translate id="menu.myOrders" />,
      navbarName: <Translate id="menu.myOrders" />,
      icon: 'reorder',
      component: OrdersPage,
      disabled: !logged,
    },
    // {
    //   path: '/referrals',
    //   sidebarName: <Translate id="menu.inviteAFriend" />,
    //   navbarName: <Translate id="menu.inviteAFriend" />,
    //   icon: <PersonAddIcon />,
    //   component: ReferralsPage,
    //   disabled: !logged,
    // },
    {
      path: '/accounts',
      sidebarName: <Translate id="menu.myExchanges" />,
      navbarName: <Translate id="menu.myExchanges" />,
      icon: 'account_balance_wallet',
      component: AccountsPage,
      disabled: !logged,
    },
    {
      path: '/subscriptions',
      sidebarName: <Translate id="menu.subscriptions" />,
      navbarName: <Translate id="menu.subscriptions" />,
      icon: 'add_shopping_cart',
      component: PaiementPage,
      disabled: !logged,
    },
    {
      path: '/exchanges/:id',
      navbarName: <Translate id="menu.myExchanges" />,
      component: ExchangesPage,
    },
    {
      path: '/exchanges',
      navbarName: <Translate id="menu.myExchanges" />,
      component: ExchangesPage,
    },
    {
      path: '/externals',
      navbarName: <Translate id="menu.myExternals" />,
      component: ExternalPage,
    },
    {
      path: '/exchange/:id',
      navbarName: 'ExchangeID',
      component: ExchangePage,
    },
    {
      path: '/settings',
      sidebarName: <Translate id="menu.settings" />,
      navbarName: <Translate id="menu.settings" />,
      icon: 'settings',
      component: SettingsPage,
      disabled: !logged,
    },
    // {
    //   path: '/strategy',
    //   sidebarName: 'Strategy',
    //   navbarName: 'Strategy',
    //   icon: 'strategy',
    //   component: StrategyPage
    // },
    {
      link: blogLinks[lang],
      navbarName: <Translate id="menu.blog" />,
      icon: <FontAwesomeIcon icon={faBlog} />,
      sidebarName: <Translate id="menu.blog" />,
    },
    {
      path: '/faq/:id',
      navbarName: <Translate id="menu.FAQ" />,
      component: FAQPage,
    },
    {
      path: '/faq',
      navbarName: <Translate id="menu.FAQ" />,
      icon: 'help',
      sidebarName: <Translate id="menu.FAQ" />,
      component: FAQPage,
    },
    {
      path: '/privacy-policy',
      navbarName: 'Legal notice and privacy policy',
      component: PrivacyPolicyPage,
    },
    {
      path: '/condifent',
      navbarName: 'Legal notice and privacy policy',
      component: PrivacyPolicyRUPage,
    },
    {
      path: '/politique-de-confidentialite',
      navbarName: 'Politique de confidentialité',
      component: PolitiqueConfidentialitePage,
    },
    {
      path: '/cgvu',
      navbarName: 'Conditions Générales de Vente et d’Utilisation',
      component: CGVUPage,
    },
    {
      path: '/terms-and-conditions',
      navbarName: 'Terms and conditions',
      component: TermsAndConditionsPage,
    },
    {
      path: '/politika',
      navbarName: 'Terms and conditions',
      component: TermsAndConditionsRUPage,
    },
    {
      path: '/unsubscribe',
      navbarName: <Translate id="menu.unsubscribeEmail" />,
      component: UnsubscribeEmailPage,
    },
  );

  if (logged) {
    dashboardRoutes.push({
      path: '/disconnect',
      sidebarName: <Translate id="menu.disconnect" />,
      navbarName: <Translate id="menu.disconnect" />,
      icon: 'power_settings_new',
      component: LogoutPage,
    });
    dashboardRoutes.push({
      path: '/updateTrader/:id',
      // navbarName: <Translate id="traders.updateMyGroup" />, //Pas afficher car /:id
      component: UpdateTraderPage,
    });
    dashboardRoutes.push({
      path: '/addTrader',
      navbarName: <Translate id="traders.createTrader" />,
      component: AddTraderPage,
    });
    dashboardRoutes.push({
      path: '/addFollower/:id',
      //Pas affiché car /:id
      component: AddFollowerPage,
    });
    dashboardRoutes.push({
      path: '/updateFollower/:id/:userId',
      //Pas affiché car /:id
      component: UpdateFollowerPage,
    });
    dashboardRoutes.push({
      path: '/manageFollowers/:id',
      //Pas affiché car /:id
      component: ManageFollowersPage,
    });
    dashboardRoutes.push({
      path: '/strategyConfiguration',
      navbarName: <Translate id="menu.strategyConfiguration" />,
      component: StrategyConfigurationsPage,
    });
  } else {
    dashboardRoutes.push(
      {
        path: '/identification',
        sidebarName: <Translate id="menu.identification" />,
        navbarName: <Translate id="menu.identification" />,
        icon: 'account_circle',
        component: IdentificationPage,
      },
      {
        path: '/confirmationEmail',
        navbarName: <Translate id="menu.confirmationEmail" />,
        component: ConfirmationEmailPage,
      },
      {
        path: '/forgotPassword',
        navbarName: <Translate id="menu.forgotPassword" />,
        component: ForgotPasswordPage,
      },
      {
        path: '/resetPassword',
        navbarName: <Translate id="menu.resetPassword" />,
        component: ResetPasswordPage,
      },
    );
  }

  dashboardRoutes.push({
    redirect: true,
    path: '/',
    to: isLogged(auth) ? '/dashboard' : '/identification',
    navbarName: 'Redirect',
  });
  return dashboardRoutes;
};
