import { Dialog, DialogContent, Slide } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import GridItem from '../../components/Grid/GridItem';
import OrderContainer from './OrderContainer';

const Transition = (props) => <Slide direction="up" {...props} />;

class DialogOrderInfos extends React.PureComponent {
  render() {
    const { open, onClose, order } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <GridItem container justifyContent="flex-end">
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </GridItem>
        <DialogContent>
          <OrderContainer order={order} />
        </DialogContent>
      </Dialog>
    );
  }
}

export default DialogOrderInfos;
