import React from 'react';
import { bindActionCreators } from 'redux';
import { change, Field, formValueSelector } from 'redux-form';
import {
  currentPriceSelector,
  isEmpty,
  pairsSelectSelector,
  smartTradeSelector,
} from '../../../helpers/Common';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import ReactSelect from '../../../components/Form/ReactSelectUI';

// const PairOption = ({ pair }) => {
//   if (!pair) {
//     return '';
//   }
//   const split = pair.split('-');
//   if (split.length >= 1 && split[0] === 'XBT') split[0] = 'BTC';
//   if (split.length >= 2 && split[1] === 'XBT') split[1] = 'BTC';
//   return (
//     <div>
//       {split.length >= 1 && <div className={'s-s-' + split[0]} />} {pair}{' '}
//       {split.length >= 2 && <div className={'s-s-' + split[1]} />}
//     </div>
//   );
// };

class SelectPairsContainer extends React.PureComponent {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { pair, change, formName, currentPrice, isUpdate } = this.props;
    // const isUpdate = order !== null;
    if (isUpdate) return;
    // console.log('SelectPairsContainer componentDidUpdate', pair, currentPrice);
    if (!isEmpty(currentPrice) || !isEmpty(pair)) {
      if (pair !== prevProps.pair) {
        change(formName, 'startPrice', currentPrice);
        change(formName, 'startTriggerPrice', currentPrice);
      }
    }
  }

  render() {
    const { pairs, isUpdate } = this.props;
    const isDisabled = !pairs || !pairs.length || isUpdate;
    return (
      <Field
        name="pair"
        label={<Translate id="smartTrade.pair" />}
        options={pairs}
        fullWidth={true}
        component={ReactSelect}
        required
        disabled={isDisabled}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  return {
    // values: getFormValues(props.formName)(state),
    values: smartTradeSelector(state),
    // initialValues: getFormInitialValues(props.formName)(state),
    pair: formSelector(state, 'pair'),
    isUpdate: formSelector(state, 'isUpdate'),
    order: formSelector(state, 'order'),
    currentPrice: currentPriceSelector(state, props),
    pairs: pairsSelectSelector(state, props),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectPairsContainer);
