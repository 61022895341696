import React from 'react';
import { connect } from 'react-redux';
import {
  FormSection,
  formValueSelector,
  change,
  getFormSyncErrors,
} from 'redux-form';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { bindActionCreators } from 'redux';
import { dangerColor } from '../../../assets/jss/material-dashboard-react';
import { getTranslate, Translate } from 'react-localize-redux';
import TakeProfitLine from './TakeProfitLine';
import { isEmpty } from '../../../helpers/Common';

class EditableTableTakeProfits extends React.PureComponent {
  nbRows = () => {
    const tab = this.props[this.props.name] || [];
    let i = 0;
    for (const t of tab) {
      if (
        !isEmpty(t.pourcentage) ||
        (!isEmpty(t.price) && !isEmpty(t.profit))
      ) {
        ++i;
      }
    }
    return i + 1;
  };
  render() {
    const { syncErrors, name, formName, isTemplate } = this.props;
    const numRows = this.nbRows();
    return (
      <FormSection name={`${name}`}>
        <GridContainer>
          <GridItem
            xs={isTemplate ? 6 : 3}
            sm={isTemplate ? 6 : 3}
            md={isTemplate ? 6 : 3}
          >
            <b>{<Translate id="smartTrade.part" />}</b>
          </GridItem>
          {!isTemplate && (
            <GridItem xs={6} sm={6} md={6}>
              <b>{<Translate id="smartTrade.price" />}</b>
            </GridItem>
          )}
          <GridItem
            xs={isTemplate ? 6 : 3}
            sm={isTemplate ? 6 : 3}
            md={isTemplate ? 6 : 3}
          >
            <b>{<Translate id="smartTrade.profit" />}</b>
          </GridItem>
        </GridContainer>
        {[...Array(numRows).keys()].map((index) => {
          const isLast = numRows === index + 1;
          return (
            <TakeProfitLine
              index={index}
              key={index}
              formName={formName}
              name={name}
              isLast={isLast}
            />
          );
        })}
        {syncErrors && syncErrors.targets && (
          <span style={{ color: dangerColor }}>{syncErrors.targets}</span>
        )}
      </FormSection>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { localize } = state;
  const formSelector = formValueSelector(props.formName);
  return {
    isBuy: formSelector(state, 'isBuy'),
    translate: getTranslate(localize),
    syncErrors: getFormSyncErrors(props.formName)(state),
    isTemplate: formSelector(state, 'isTemplate'),
    pair: formSelector(state, 'pair'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

const Compo = connect((state, props) => ({
  [props.name]: formValueSelector(props.formName)(state, props.name),
}))(EditableTableTakeProfits);

export default connect(mapStateToProps, mapDispatchToProps)(Compo);
