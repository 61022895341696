import { Translate } from 'react-localize-redux';
import React from 'react';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

export const ShowTuto = (exchange, theme) => {
  return (
    <GridItem xs={12} sm={12} md={4}>
      <Card>
        <CardBody>
          <h6>
            <Translate id="exchanges.creationAccount" />
          </h6>
          <h4>
            <Translate id="exchanges.tutorial" />
          </h4>
          <ul>
            <li>
              <Translate
                options={{ renderInnerHtml: true }}
                data={{
                  classLink:
                    'link-text ' + (theme === 'dark' ? 'dark' : 'light'),
                }}
                id="exchanges.etape1"
              />
              <br />
              <br />
            </li>
            <li>
              <Translate
                options={{ renderInnerHtml: true }}
                id="exchanges.etape2"
              />
              <br />
              <br />
            </li>
            <li>
              <Translate
                options={{ renderInnerHtml: true }}
                id="exchanges.etape3"
              />
              <br />
              <br />
            </li>
            <li>
              <Translate
                options={{ renderInnerHtml: true }}
                id="exchanges.etape4"
              />
              <br />
              <br />
            </li>
          </ul>
        </CardBody>
      </Card>
    </GridItem>
  );
};
