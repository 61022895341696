import React from 'react';
import { parseToFloat } from '../../../helpers/Common';
import { getPricePair, parseQty } from '../../../helpers/exchange';

class ShowDetails extends React.Component {
  render() {
    const { tradingSummaries, availableAmounts } = this.props;

    let volumeQuotes = [];
    let valueQuotes = [];
    let valueUser = [];
    let emails = [];
    let assets = [];
    let sumValue = 0;
    let valueGroup = [];
    let groups = [];
    tradingSummaries.forEach((tradingSummary) => {
      const asset = tradingSummary.Asset;
      const email = tradingSummary.AAccount.ApplicationUser.Email;
      const group = tradingSummary.GroupName;
      if (asset in volumeQuotes) {
        volumeQuotes[asset] += tradingSummary.Income;
        valueQuotes[asset] += tradingSummary.Value;
      } else {
        volumeQuotes[asset] = tradingSummary.Income;
        valueQuotes[asset] = tradingSummary.Value;
        assets.push(asset);
      }
      if (email in valueUser) {
        valueUser[email] += tradingSummary.Value;
      } else {
        valueUser[email] = tradingSummary.Value;
        emails.push(email);
      }

      if (group in valueGroup) {
        valueGroup[group] += tradingSummary.Value;
      } else {
        valueGroup[group] = tradingSummary.Value;
        groups.push(group);
      }
      sumValue += tradingSummary.Value;
    });
    const mapped = emails.map((email) => ({
      email: email,
      value: valueUser[email],
    }));
    const mappedGroup = groups.map((group) => ({
      group: group,
      value: valueGroup[group],
    }));
    const sortedEmail = mapped.sort((a, b) => b.value - a.value).slice(0, 5);
    const sortedGroup = mappedGroup
      .sort((a, b) => b.value - a.value)
      .slice(0, 8);
    return (
      <div>
        <span>Total: {parseToFloat(sumValue, 0.01)}$ (Jour Request)</span>
        {assets.map((key) => {
          if (valueQuotes[key] > sumValue * 0.1) {
            const pairPrice =
              key === 'USDT'
                ? 1
                : getPricePair(key + '-USDT', availableAmounts);
            return (
              <span key={key}>
                &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                {parseQty(volumeQuotes[key])} {key} (
                {parseToFloat((valueQuotes[key] / sumValue) * 100, 0.1)}
                %) = {parseToFloat(pairPrice * volumeQuotes[key], 0.01)}$
                (Actual)
              </span>
            );
          }
        })}
        <br />
        {sortedEmail.map((value, key) => {
          return (
            <span key={key}>
              {value.email}: {parseToFloat(value.value, 0.01)}$ (
              {parseToFloat((value.value / sumValue) * 100, 0.01)}
              %) &nbsp;&nbsp;|&nbsp;&nbsp;
            </span>
          );
        })}
        <br />
        {sortedGroup.map((value, key) => {
          return (
            <span key={key}>
              {value.group}: {parseToFloat(value.value, 0.01)}$ (
              {parseToFloat((value.value / sumValue) * 100, 0.01)}
              %) &nbsp;&nbsp;|&nbsp;&nbsp;
            </span>
          );
        })}
      </div>
    );
  }
}

export default ShowDetails;
