import React from 'react';
import { changeStep } from '../../redux/onboarding/actions';
import { useDispatch } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { profilConstant } from './profil.constant';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import FormAddTrader from '../Trader/FormAddTrader';

const AddTrader = () => {
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader color="primary">
        <h4>
          <Translate id="traders.createTrader" />
        </h4>
      </CardHeader>
      <CardBody>
        <FormAddTrader
          noPopupTrading={false}
          redirect={false}
          afterHandleSubmit={() => {
            dispatch(changeStep(profilConstant.STEP_GROUP_CHOICES));
          }}
        />
      </CardBody>
    </Card>
  );
};

export default AddTrader;
