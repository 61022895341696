export const adminConstants = {
  ACTION_GET_USERS: '@admin/ACTION_GET_USERS',
  ACTION_GET_LOGS: '@admin/ACTION_GET_LOGS',
  ACTION_GET_ORDER: '@admin/ACTION_GET_ORDER',
  ACTION_GET_ORDER_DETAIL: '@admin/ACTION_GET_ORDER_DETAIL',
  ACTION_GET_ORDER_INFO: '@admin/ACTION_GET_ORDER_INFO',
  ACTION_GET_OPEN_ORDER: '@admin/ACTION_GET_OPEN_ORDER',
  ACTION_GET_TRADING_SUMMARIES: '@admin/ACTION_GET_TRADING_SUMMARIES',
  ACTION_GET_USERS_VOLUME: '@admin/ACTION_GET_USERS_VOLUME',
  ACTION_GET_USER_ORDERS: '@admin/ACTION_GET_USER_ORDERS',
  ACTION_GET_USER_EXCHANGES: '@admin/ACTION_GET_USER_EXCHANGES',
  ACTION_GET_STATS_GROUP: '@admin/ACTION_GET_STATS_GROUP',
  ACTION_GET_STATS_GROUP2: '@admin/ACTION_GET_STATS_GROUP2',
  ACTION_GET_STATS: '@admin/ACTION_GET_STATS',
  ACTION_GET_STATS2: '@admin/ACTION_GET_STATS2',
  ACTION_GET_ORDERS_GROUP: '@admin/ACTION_GET_ORDERS_GROUP',
  ACTION_GET_ACCOUNTS_GROUP: '@admin/ACTION_GET_ACCOUNTS_GROUP',
  ACTION_GET_HISTORIC_EMAIL: '@admin/ACTION_GET_HISTORIC_EMAIL',
  ACTION_GET_COMPANY_DATA: '@admin/ACTION_GET_COMPANY_DATA',
  ACTION_GET_PAIEMENTS: '@admin/ACTION_GET_PAIEMENTS',
  ACTION_GET_CURRENT_PRICES: '@admin/ACTION_GET_CURRENT_PRICES',
  ACTION_GET_SUBSCRIPTION_GROUP: '@admin/ACTION_GET_SUBSCRIPTION_GROUP',
  ACTION_GET_EMAIL_BY_ACCOUNT: '@admin/ACTION_GET_EMAIL_BY_ACCOUNT',
  ACTION_GET_EMAIL_BY_USER: '@admin/ACTION_GET_EMAIL_BY_USER',
  ACTION_GET_EMAIL_BY_TRIGGER: '@admin/ACTION_GET_EMAIL_BY_TRIGGER',
  ACTION_GET_EMAIL_BY_ORDER: '@admin/ACTION_GET_EMAIL_BY_ORDER',
  ACTION_GET_EMAIL_BY_GROUP: '@admin/ACTION_GET_EMAIL_BY_GROUP',
  ACTION_GET_EMAIL_BY_TRADINGVIEW: '@admin/ACTION_GET_EMAIL_BY_TRADINGVIEW',
  ACTION_CONFIRM_EMAIL: '@admin/ACTION_CONFIRM_EMAIL',
  ACTION_GET_PROXYS_INFOS: '@admin/ACTION_GET_PROXYS_INFOS',
};
