import {
  isEmpty, isFuture, isNull,
  parseToFloat
} from '../../Common';
import {
  between
} from '../../../views/SmartTrade/validate';
import { isRequired } from '../../form';
import { satoshi } from '../../general';
import {
  getAveragePrice,
  getLeverageLimit,
  haveLeverage
} from '../../order';
import { Translate } from 'react-localize-redux';
import React from 'react';

const validateStarts = (values, props) => {
  const {
    translate,
    stepPrice,
    leverageBuy,
    leverageSell,
    qtyPrim,
    lotStep,
    qtySec,
    capital
  } = props;
  const {
    accountId,
    order,
    isBuy,
    starts,
    dontDoStart,
    dontDoOrder,
    isPaperTrading,
    firstQty,
    secQty,
    isTemplate,
    leverageCB,
    leverage,
    pourcentage,
    partUse,

  } = values;
  const errors = [];
  const requiredFields = ['accountId', 'pair', 'pourcentage'];
  if (!isTemplate) requiredFields.push('firstQty', 'secQty', 'partUse');
  requiredFields.forEach((field) => {
    if (isRequired(values[field])) {
      errors[field] = <Translate id="central.required" />;
    }
  });

  const isFutures = isNull(accountId) ? false : isFuture(accountId.exchange);
  let isBitmex = false;
  let isKraken = false;
  let isLeverage = false;
  if (accountId) {
    isBitmex = accountId.exchange === 'Bitmex';
    isKraken = accountId.exchange === 'Kraken';
    isLeverage = haveLeverage(
      accountId.exchange,
      isBuy,
      leverageBuy,
      leverageSell
    );
  }

  if (pourcentage && accountId && !isFutures) {
    const min = 0;
    const max = 100;
    // if (isLeverage && leverageCB) max = max;
    if (between(pourcentage, min, max))
      errors.pourcentage = (
        <Translate id="error.between" data={{ min, max }} />
      );
  }

  const averageStartPrice = getAveragePrice(starts, stepPrice);
  if (accountId && isLeverage && leverageCB) {
    if (isRequired(values.leverage) && values.leverage !== 0)
      errors.leverage = translate('central.required');

    const min = getLeverageLimit(
      'min',
      accountId.exchange,
      isBuy,
      leverageBuy,
      leverageSell
    );
    const max = getLeverageLimit(
      'max',
      accountId.exchange,
      isBuy,
      leverageBuy,
      leverageSell
    );
    if (between(leverage, min, max))
      errors.leverage = translate('error.between', { min, max });
  }

  if (dontDoStart === true || isEmpty(starts)) return errors;
  if (isTemplate) return errors;

  if (!isTemplate && partUse && accountId && !isFutures) {
    const min = 0;
    let max = capital;
    if (isLeverage && leverageCB) max = parseToFloat(max * leverage, 0.01);
    if (between(partUse, min, max))
      errors.partUse = <Translate id="error.between" data={{ min, max }} />;
  }
  if (!isTemplate && !dontDoOrder && !isPaperTrading && accountId && !isFutures) {
    const qtyOrder = order && order.Quantity ? order.Quantity : 0;
    if (
      firstQty &&
      ((!isBuy && !dontDoStart) || (isBuy && dontDoStart))
    ) {
      const min = satoshi;
      let max = parseToFloat(qtyPrim, lotStep, true) + qtyOrder;
      if (isKraken && isLeverage && leverageCB)
        max = parseToFloat(max * (leverage ?? 1), lotStep, true);
      if (between(firstQty, min, max))
        errors.firstQty = translate('error.between', { min, max });
    }
    if (
      secQty &&
      ((isBuy && !dontDoStart) || (!isBuy && dontDoStart))
    ) {
      const qtyStart = qtyOrder * averageStartPrice;
      const min = satoshi;
      let max =
        parseToFloat(qtySec, stepPrice, true) +
        parseToFloat(qtyStart, stepPrice, true);
      if (isKraken && isLeverage && leverageCB)
        max = parseToFloat(max * (leverage ?? 1), stepPrice, true);
      // if (between(secQty, min, max))
      //   errors.secQty = translate('error.between', { min, max });
    }
  }

  // if (partUse && accountId && !isBitmex) {
  //   const min = 0;
  //   let max = capital;
  //   if (isLeverage && leverageCB) max = parseToFloat(max * leverage, 0.01);
  //   if (between(partUse, min, max))
  //     startErrors.push(
  //       translate('error.lineIndicator', {
  //         info: translate('error.between', { min, max }),
  //         line: count + 1,
  //       }),
  //     );
  // }


  // console.log('starts', errors);
  return errors;
};

export default validateStarts;
