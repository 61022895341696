import React from 'react';
import {
  Field,
  formValueSelector,
  getFormSyncErrors,
  reduxForm,
  initialize,
  change,
  getFormValues,
} from 'redux-form';

import GridItem from '../../components/Grid/GridItem.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import Grid from '@material-ui/core/Grid';
import ReactTextField from '../../components/Form/ReactTextFieldUI';
import validate from './validate';
import { getTranslate, Translate, withLocalize } from 'react-localize-redux';

import { connect } from 'react-redux';

import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { isNull } from '../../helpers/Common';
import Button from '../../components/CustomButtons/Button';
import { deleteGroup, postGroup, putGroup } from '../../redux/trader/actions';
import { makeRandomStr } from '../../helpers/general';
import { DeleteForever, Settings } from '@material-ui/icons';
import { getExchangeExEnum, getExchangeStr } from '../../helpers/order';
import { optionsExchanges } from '../../helpers/exchange';
import ReactSelect from '../../components/Form/ReactSelectUI';
import { required } from '../../components/Form/validator';
import useIsMobileDevice from '../../hooks/useIsMobileDevice';

const formName = 'formAddTrader';

class FormAddTrader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      trader: null,
      isMobile: { useIsMobileDevice },
    };
  }

  // componentDidUpdate = (prevProps, prevState, snapshot) => {
  //   if (this.state.trader === null && this.props.trader !== null) {
  //     // console.log('INITILIZED', this.props.initialValues,this.props.order);
  //     this.props.initialize(this.props.initialValues);
  //     // this.props.reset();
  //     this.setState({ trader: this.props.trader });
  //   }
  // };

  handleSubmit = () => {
    const { data, isUpdate, redirect } = this.props;
    if (isUpdate) {
      this.props.putGroupActions(data);
    } else {
      this.props.postGroupActions(data, redirect);
    }
  };

  render() {
    const {
      afterHandleSubmit,
      isUpdate,
      errors,
      loading,
      change,
      translate,
      groupId,
    } = this.props;
    const hasErrors = Object.keys(errors).length !== 0 || loading.type === 'ON';

    return (
      <Grid container>
        <GridItem xs={12} sm={12} md={6}>
          <Field
            name="exchangeName"
            label={<Translate id="exchanges.exchangeName" />}
            options={optionsExchanges}
            fullWidth={true}
            component={ReactSelect}
            validate={[required]}
            required
            // disabled={!isUpdatableExchange}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Field
            name="name"
            label={<Translate id="traders.traderName" />}
            fullWidth={true}
            component={ReactTextField}
            required
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <Field
                name="reference"
                label={<Translate id="traders.referenceForFollower" />}
                fullWidth={true}
                component={ReactTextField}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Button
                fullWidth
                onClick={() => {
                  change('reference', makeRandomStr(32));
                }}
                color="primary"
                // style={
                //   isMobile ? {fontSize: '9px'} : {fontSize: '15px'}
                // }
              >
                <Settings style={{ margin: '4px' }} />
                <Translate id="traders.generateReference" />
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Field
            name="shortDescription"
            label={<Translate id="traders.description" />}
            fullWidth={true}
            minRows={5}
            multiline
            component={ReactTextField}
            required
          />
        </GridItem>
        {/*{"1":24.99,"3":22.49,"6":21.24,"12":19.99}*/}
        {/*<GridItem xs={3} sm={3} md={3}>*/}
        {/*  <Price*/}
        {/*    formName={formName}*/}
        {/*    endAdornment={''}*/}
        {/*    name={`Price1M`}*/}
        {/*    min={0}*/}
        {/*    max={100}*/}
        {/*    placeholder={translate('traders.priceXM', { nbMonth: 1 })}*/}
        {/*    // disabled={isDone}*/}
        {/*  />*/}
        {/*</GridItem>*/}
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer justifyContent="center">
            <Button
              type="submit"
              onClick={() => {
                this.handleSubmit();
                if (afterHandleSubmit) afterHandleSubmit();
              }}
              color="primary"
              round
              disabled={hasErrors}
            >
              {isUpdate ? (
                <Translate id="traders.updateMyGroup" />
              ) : (
                <Translate id="traders.createTrader" />
              )}
            </Button>
          </GridContainer>
          {isUpdate && (
            <GridContainer justifyContent="flex-end">
              <Button
                color="danger"
                onClick={() => {
                  if (!window.confirm(translate('confirmation.areYouSure')))
                    return;
                  this.props.deleteGroupActions(groupId);
                }}
              >
                <DeleteForever style={{ margin: '4px' }} />
                <Translate id="traders.deleteThisGroup" />
              </Button>
            </GridContainer>
          )}
        </GridItem>
      </Grid>
    );
  }
}

const formAddTrader = reduxForm({
  form: formName, // a unique identifier for this form
  touchOnChange: true,
  validate,
})(FormAddTrader);

const traderSelector = (state, props) => {
  return isNull(props.trader) ? null : props.trader;
};

const defaultExchange = 'Binance_Spot';
const defaultExchangeLabel = 'Binance Spot';

const initialValuesSelector = createSelector(traderSelector, (trader) => {
  if (trader) {
    const exchangeStr = getExchangeStr(trader);
    const exchangeValue = getExchangeExEnum(exchangeStr);
    return {
      isUpdate: true,
      groupId: trader.GroupId,
      name: trader.Name,
      reference: trader.Reference,
      shortDescription: trader.ShortDescription,
      exchangeName: {
        value: exchangeValue,
        label: exchangeStr,
        exchange: exchangeStr,
      },
    };
  }
  return {
    isUpdate: false,
    name: '',
    shortDescription: '',
    exchangeName: {
      value: getExchangeExEnum(defaultExchange),
      label: defaultExchangeLabel,
      exchange: defaultExchange,
    },
  };
});

const mapStateToProps = (state, props) => {
  const { loading, localize } = state;
  const formSelector = formValueSelector(formName);
  return {
    translate: getTranslate(localize),
    loading,
    errors: getFormSyncErrors(formName)(state),
    data: getFormValues(formName)(state),
    isUpdate: formSelector(state, 'isUpdate'),
    groupId: formSelector(state, 'groupId'),
    // groupId: formSelector(state, 'GroupId'),
    name: formSelector(state, 'Name'),
    // shortDescription: formSelector(state, 'ShortDescription'),
    initialValues: initialValuesSelector(state, props),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initialize: bindActionCreators(initialize, dispatch),
    change: bindActionCreators(change, dispatch),
    postGroupActions: bindActionCreators(postGroup, dispatch),
    putGroupActions: bindActionCreators(putGroup, dispatch),
    deleteGroupActions: bindActionCreators(deleteGroup, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(formAddTrader),
);
