import React from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '../../../components/CustomButtons/Button.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import ReactSelect from '../../../components/Form/ReactSelectUI';
import ReactTextField from '../../../components/Form/ReactTextFieldUI';
import validate from './validate';
import { postExternal } from '../../../redux/user/actions';

import { required } from '../../../components/Form/validator';

import { Translate } from 'react-localize-redux';
import { sendWithdrawal } from '../../../redux/referrals/actions';

const tokenList = {
  currencyList: [
    {
      value: 'USDT',
      label: 'Tether',
    },
    {
      value: 'BTC',
      label: 'Bitcoin',
    },
    {
      value: 'ETH',
      label: 'Etherum',
    },
    {
      value: 'LTC',
      label: 'Litecoin',
    },
  ],
  networkBlockchainList: [
    {
      value: 'BSC',
      label: 'BSC',
    },
    {
      value: 'TRC20',
      label: 'TRC20',
    },
    {
      value: 'ERC20',
      label: 'ERC20',
    },
  ],
};

class FormWithdrawal extends React.Component {
  constructor(props) {
    super(props);

    this.state = tokenList;
  }

  // postExternal = () => {
  //   const externalWallet = this.props.myform.values;
  //   this.props.postNewWallet(externalWallet);
  // };
  sendWithdrawal = () => {
    const sendInfoReferralWithdrawal = this.props.myform.values;
    console.log('THE INFO: ', sendInfoReferralWithdrawal);
    this.props.postReferralsWithdrawal(sendInfoReferralWithdrawal);
  };

  render() {
    const { stopPopup, myform } = this.props;
    return (
      <div>
        <form>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Field
                name="currency"
                label={<Translate id="externals.currency" />}
                options={this.state.currencyList}
                fullWidth={true}
                component={ReactSelect}
                disabled="true"
                validate={[required]}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Field
                name="networkBlockchain"
                label="Network Blockchain"
                options={this.state.networkBlockchainList}
                fullWidth={true}
                component={ReactSelect}
                disabled="true"
                validate={[required]}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Field
                name="publicAddress"
                label={<Translate id="externals.address" />}
                fullWidth={true}
                component={ReactTextField}
                required
              />
            </GridItem>
          </GridContainer>
        </form>
        <GridContainer justifyContent="center">
          {/*disabled={submitting}*/}
          <Button
            type="submit"
            onClick={() => {
              this.sendWithdrawal();
              stopPopup();
            }}
            // onClick={this.sendWithdrawal}
            // onClick={this.postExternal}
            color="primary"
            round
            disabled={myform && myform.syncErrors}
          >
            <Translate id="confirmation.submitButton" />
          </Button>
        </GridContainer>
      </div>
    );
  }
}

let formWithdrawal = reduxForm({
  form: 'formWithdrawal', // a unique identifier for this form
  initialValues: {
    currency: {
      value: tokenList.currencyList[0].value,
      label: tokenList.currencyList[0].label,
    },
    networkBlockchain: {
      value: tokenList.networkBlockchainList[0].value,
      label: tokenList.networkBlockchainList[0].label,
    },
  },
  validate,
  // asyncValidate
})(FormWithdrawal);

const mapStateToProps = (state) => {
  const { form } = state;

  return {
    myform: form.formWithdrawal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    postNewWallet: bindActionCreators(postExternal, dispatch),
    postReferralsWithdrawal: bindActionCreators(sendWithdrawal, dispatch),
  };
};

formWithdrawal = connect(mapStateToProps, mapDispatchToProps)(formWithdrawal);

export default formWithdrawal;
