import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import FormAddTrader from './FormAddTrader';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import transTrader from '../../locales/traders.json';
import transExchanges from '../../locales/exchanges';
import transError from '../../locales/error';
import transConfirmation from '../../locales/confirmation';

class TradersPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transTrader);
    this.props.addTranslation(transError);
    this.props.addTranslation(transExchanges);
    this.props.addTranslation(transConfirmation);
  }

  render() {
    return (
      <Card>
        <CardHeader color="primary">
          <h4>
            <Translate id="traders.createTrader" />
          </h4>
        </CardHeader>
        <CardBody>
          <FormAddTrader noPopupTrading={true} redirect={true} trader={null} />
        </CardBody>
      </Card>
    );
  }
}

export default withLocalize(TradersPage);
