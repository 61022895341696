import React from 'react';
import connect from 'react-redux/es/connect/connect';

import { getTranslate, Translate, withLocalize } from 'react-localize-redux';
import GridItem from '../../components/Grid/GridItem';
import { isNull } from '../../helpers/Common';
import {
  Field,
  getFormSyncErrors,
  getFormValues,
  initialize,
  reduxForm,
} from 'redux-form';
import { optionsExchanges } from '../../helpers/exchange';
import { bindActionCreators } from 'redux';
import MultiSelectContainer from './MultiSelectContainer';
import Button from '../../components/CustomButtons/Button';
import {
  deleteStrategyConfigurations,
  putStrategyConfigurations,
} from '../../redux/strategyConfiguration/actions';
import ReactSelect from '../../components/Form/ReactSelectUI';
import ReactTextField from '../../components/Form/ReactTextFieldUI';
import {
  initialValuesSelector,
  optionsPairsSelector,
  optionsQuoteSelector,
  optionsSubscriptionGroupsSelector,
  strategyConfigurationSelector,
} from '../../redux/strategyConfiguration/selector';
import { tradersSelector } from '../../redux/trader/selector';
import { DeleteForever } from '@material-ui/icons';
import { changeTabStrategyValue } from '../../redux/config/actions';
import { required } from '../../components/Form/validator';

class StrategyConfigurations extends React.Component {
  updateStrategy = () => {
    const { formValues } = this.props;
    // console.log(formValues);
    // let sc = formValues;
    // for (const id of sc.SubscriptionGroups) {
    //   if (!isNull(traders) && traders.length > 0) {
    //     const sgs = getSubGroup(traders);
    //     const traders = traders.find(x => x.value === id);
    //     if (!isNull(traders))
    //       SubscriptionGroupsId.push({ label: traders.label, value: id });
    //   }
    // }
    this.props.putStrategyConfigurationsActions(formValues);
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const { tabStrategyValue } = this.props;

    if (tabStrategyValue !== prevProps.tabStrategyValue) {
      // console.log('INITILIZED');
      this.props.initialize(this.props.initialValues);
    }
  };

  removeStrategy = () => {
    const { translate, strategyConfiguration, tabStrategyValue } = this.props;
    if (!window.confirm(translate('confirmation.areYouSure'))) return;

    this.props.deleteStrategyConfigurationsActions(
      strategyConfiguration.StrategyConfigurationId,
    );
    this.props.changeTabStrategyValueActions(
      tabStrategyValue > 0 ? tabStrategyValue - 1 : 0,
    );
  };

  render() {
    const {
      strategyConfiguration,
      optionsQuote,
      optionsPairs,
      optionsSubscriptionGroups,
      errors,
      loading,
      traders,
    } = this.props;
    if (isNull(strategyConfiguration)) return <div />;
    const hasErrors = Object.keys(errors).length !== 0 || loading.type === 'ON';
    // console.log(strategyConfiguration, formValues, optionsSubscriptionGroups);
    return (
      <GridItem container>
        <GridItem xs={12} sm={12} md={6}>
          <Field
            name="Name"
            label={<Translate id="central.name" />}
            fullWidth={true}
            required
            validate={[required]}
            component={ReactTextField}
          />
        </GridItem>
        <GridItem
          container
          xs={12}
          sm={12}
          md={6}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            onClick={() => this.removeStrategy()}
            size="sm"
            color="danger"
          >
            <DeleteForever /> <Translate id="central.delete" />
          </Button>
        </GridItem>

        {traders.length > 0 && (
          <GridItem xs={12} sm={12} md={12}>
            <Field
              name="SubscriptionGroupsId"
              label="Traders"
              options={[...optionsSubscriptionGroups]}
              fullWidth={true}
              multi={true}
              component={ReactSelect}
            />
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={6}>
          <MultiSelectContainer
            name="BLExchanges"
            formName={formName}
            options={optionsExchanges}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <MultiSelectContainer
            name="WLExchanges"
            formName={formName}
            options={optionsExchanges}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <MultiSelectContainer
            name="BLQuotes"
            formName={formName}
            options={optionsQuote}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <MultiSelectContainer
            name="WLQuotes"
            formName={formName}
            options={optionsQuote}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <MultiSelectContainer
            name="BLPairs"
            formName={formName}
            options={optionsPairs}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <MultiSelectContainer
            name="WLPairs"
            formName={formName}
            options={optionsPairs}
          />
        </GridItem>
        {/*</form>*/}
        <GridItem
          xs={12}
          sm={12}
          md={12}
          align="center"
          style={{ marginTop: '10px' }}
        >
          <Button
            onClick={this.updateStrategy}
            color="primary"
            round
            disabled={hasErrors} // || submitting || pristine}
          >
            <Translate id="central.save" />
          </Button>
        </GridItem>
      </GridItem>
    );
  }
}

const formName = 'formStrategyConfiguration';
let formStrategyConfiguration = reduxForm({
  form: formName, // a unique identifier for this form
  // validate,
  // touchOnBlur: false,
  touchOnChange: true,
  // touchOnBlur: true,
  // enableReinitialize: true
  // warn
  // asyncValidate
})(StrategyConfigurations);

const mapStateToProps = (state, props) => {
  const { strategyConfiguration, loading, config, localize } = state;
  // const formSelector = formValueSelector(formName);
  return {
    translate: getTranslate(localize),
    loading,
    strategyConfigurations: strategyConfiguration.strategyConfigurations,
    tabStrategyValue: config.tabStrategyValue,
    strategyConfiguration: strategyConfigurationSelector(state, props),
    initialValues: initialValuesSelector(state, props),
    errors: getFormSyncErrors(formName)(state),
    formValues: getFormValues(formName)(state),
    optionsQuote: optionsQuoteSelector(state, props),
    optionsPairs: optionsPairsSelector(state, props),
    optionsSubscriptionGroups: optionsSubscriptionGroupsSelector(state, props),
    traders: tradersSelector(state, props),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initialize: bindActionCreators(initialize, dispatch),
    deleteStrategyConfigurationsActions: bindActionCreators(
      deleteStrategyConfigurations,
      dispatch,
    ),
    putStrategyConfigurationsActions: bindActionCreators(
      putStrategyConfigurations,
      dispatch,
    ),
    changeTabStrategyValueActions: bindActionCreators(
      changeTabStrategyValue,
      dispatch,
    ),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(formStrategyConfiguration),
);
