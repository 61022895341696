import React from 'react';
import ReactGA from 'react-ga';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router'; // react-router v4
import { ConnectedRouter } from 'connected-react-router';
import { LocalizeProvider } from 'react-localize-redux';
import configureStore, { history } from './store';
import './assets/css/material-dashboard-react.css?v=1.4.1';
import './assets/css/exchange-logo.css?v=1.4.1';
import './assets/css/logo.css?v=1.4.1';
import './assets/img/flags/flags.min.css?v=1.4.1';
import './assets/css/tokens-logo0toC.css?v=1.4.1';
import './assets/css/tokens-logoDtoF.css?v=1.4.1';
import './assets/css/tokens-logoGtoI.css?v=1.4.1';
import './assets/css/tokens-logoJtoL.css?v=1.4.1';
import './assets/css/tokens-logoMtoO.css?v=1.4.1';
import './assets/css/tokens-logoPtoR.css?v=1.4.1';
import './assets/css/tokens-logoStoU.css?v=1.4.1';
import './assets/css/tokens-logoVtoW.css?v=1.4.1';
import './assets/css/tokens-logoXtoZ.css?v=1.4.1';

import indexRoutes from './routes';

const store = configureStore();

const trackPageView = (location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
};

const initGa = (history) => {
  ReactGA.initialize('UA-128584411-1', {});
  trackPageView(history.location);
  history.listen(trackPageView);
};
initGa(history);

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <LocalizeProvider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          {indexRoutes.map((prop, key) => {
            return (
              <Route path={prop.path} component={prop.component} key={key} />
            );
          })}
        </Switch>
      </ConnectedRouter>
    </LocalizeProvider>
  </Provider>,
);
