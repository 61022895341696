import { strategyConfigurationConstants } from './constants';
import { fetcher } from '../../helpers/fetcher';

export const getStrategyConfigurations = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/StrategyConfiguration', {});
  if (eu) {
    dispatch({
      type: strategyConfigurationConstants.GET_STRATEGY_CONFIGURATION,
      payload: eu,
    });
  }
};

export const putStrategyConfigurations = (body) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/StrategyConfiguration', {
    method: 'PUT',
    body,
  });
  if (eu) {
    dispatch({
      type: strategyConfigurationConstants.PUT_STRATEGY_CONFIGURATION,
      payload: eu,
    });
  }
};

export const postStrategyConfigurations = (body) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/StrategyConfiguration', {
    method: 'POST',
    body,
  });
  if (eu) {
    dispatch({
      type: strategyConfigurationConstants.POST_STRATEGY_CONFIGURATION,
      payload: eu,
    });
  }
};

export const deleteStrategyConfigurations = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v1/StrategyConfiguration/${id}`, {
    method: 'DELETE',
  });
  if (eu) {
    dispatch({
      type: strategyConfigurationConstants.DELETE_STRATEGY_CONFIGURATION,
      payload: id,
    });
  }
};
