import TextField from './TextField';
// import TextField from '@material-ui/core/TextField';

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import purple from '@material-ui/core/colors/purple';
// import { warningColor } from '../../assets/jss/material-dashboard-react';
import { isEmpty } from '../../helpers/Common';

// const capitalize = s => {
//   if (typeof s !== 'string') return '';
//   s.replace(/([A-Z])/g, ' $1').replace(/^./, str => {
//     str.toUpperCase();
//   });
//   return s.charAt(0).toUpperCase() + s.slice(1);
// };
const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  // margin: {
  //   margin: theme.spacing(1)
  // },
  // cssLabel: {
  //   '&$cssFocused': {
  //     color: purple[500]
  //   }
  // },
  // cssWarning: {
  //   color: warningColor,
  //   '&$focused': {
  //     color: warningColor
  //   }
  // },
  // cssUnderlineWarning: {
  //   // color: warningColor,
  //   '&:before, &:after, &:hover': {
  //     borderBottomColor: warningColor
  //   }
  //   // borderBottomColor: warningColor
  // },
  // cssFocused: {},
  // cssUnderline: {
  //   '&:after': {
  //     borderBottomColor: purple[500]
  //   }
  // },
  // cssOutlinedInput: {
  //   '&$cssFocused $notchedOutline': {
  //     borderColor: purple[500]
  //   }
  // }
});

const warningManage = (props) => {
  let newProps = { ...props };
  const { classes } = props;

  newProps.helperText = newProps.warning;
  //Attention if n'existe pas
  newProps.FormHelperTextProps = {
    ...newProps.FormHelperTextProps,
    classes: {
      ...newProps.FormHelperTextProps.classes,
      root: classes.cssWarning,
    },
  };
  //Attention if n'existe pas
  newProps.InputProps = {
    ...newProps.InputProps,
    classes: {
      ...newProps.InputProps.classes,
      underline: classes.cssUnderlineWarning,
    },
  };
  //Attention if n'existe pas
  newProps.InputLabelProps = {
    ...newProps.InputLabelProps,
    classes: {
      ...newProps.InputLabelProps.classes,
      root: classes.cssWarning,
    },
  };
  return newProps;
};

const MyTextField = (props) => {
  // const { classes } = props;
  //Gestion Warning
  const isWarning = !isEmpty(props.warning);
  let newProps = isWarning ? warningManage(props) : props;
  //Hack to show error even if it is never touch
  // newProps = { ...newProps, meta: { ...newProps.meta, touched: true } };
  // const label = props.label || capitalize(props.input.name);
  // console.log('MyTextField', newProps);
  return (
    <div style={{ marginBottom: 20 }}>
      <TextField
        {...newProps}
        // margin="dense"
        // InputLabelProps={{
        //   shrink: true //https://material-ui.com/demos/text-fields/#limitations
        // }}
      />
    </div>
  );
};

export default withStyles(styles)(MyTextField);
