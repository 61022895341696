import { authenticationConstants } from './constants';
import { isEmpty } from '../../helpers/Common';

const auth = JSON.parse(localStorage.getItem('authentication'));
const tfaActive = JSON.parse(localStorage.getItem('tfaActive'));
const initialState = auth ? { loggedIn: true, auth, tfa: {}, tfaActive } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authenticationConstants.REGISTER_REQUEST:
      return {
        // loggingIn: true,
        // auth: action.payload
      };
    case authenticationConstants.REGISTER_SUCCESS:
      return {
        // loggedIn: true,
        // auth: action.payload
      };
    case authenticationConstants.REGISTER_FAILURE:
      return {};
    case authenticationConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        auth: action.payload,
      };
    case authenticationConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        tfaActive: action.payload.tfaActive === 'true',
        auth: action.payload,
      };
    case authenticationConstants.LOGIN_FAILURE:
      return {};
    case authenticationConstants.LOGOUT:
      return {};
    case authenticationConstants.GET_TFA:
      return {
        ...state,
        tfa: action.payload,
      };
    case authenticationConstants.SET_TFA:
      return {
        ...state,
        tfaActive: action.payload,
      };
    case authenticationConstants.ADD_FAKE_TELEGRAM: {
      if (isEmpty(state.auth.telegram)) {
        const newLog = {
          ...state,
          auth: { ...state.auth, telegram: action.payload },
        };
        localStorage.setItem('authentication', JSON.stringify(newLog.auth));
        return newLog;
      }
      return state;
    }
    default:
      return state;
  }
}
