import React from 'react';
import withWidth from '@material-ui/core/withWidth';
import OrderContainer from './OrderContainer';
import GridItem from '../../components/Grid/GridItem';
import { withLocalize } from 'react-localize-redux';
import { Translate } from 'react-localize-redux';
import { nbColumn } from '../../helpers/general';
import Grid from '@material-ui/core/Grid';
import TablePagination from '@material-ui/core/TablePagination';
import Card from '../../components/Card/Card';
import { bindActionCreators } from 'redux';
import { getOrders } from '../../redux/order/actions';
import connect from 'react-redux/es/connect/connect';

class OrdersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 6,
      pageSizes: [6, 12, 24],
    };
  }
  showColumn = (column, ordersFilter) => {
    let i = 0;
    const nbrColumn = nbColumn(this.props.width);
    const col = column === nbrColumn ? 0 : column;
    return ordersFilter.map((order) => {
      ++i;
      if (i % nbrColumn === col) {
        return (
          <Grid
            item
            key={order.OrderId}
            style={{ padding: '0 5px !important' }}
          >
            <OrderContainer order={order} />
          </Grid>
        );
      }
    });
  };

  setPage = (newPage) => {
    this.setState({ ...this.state, currentPage: newPage });
  };
  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      ...this.state,
      pageSize: event.target.value,
      currentPage: 0,
    });
  };

  paginate = (array, page_size, page_number) => {
    return array.slice(page_number * page_size, (page_number + 1) * page_size);
  };

  componentDidMount() {
    const { tabOrders, userId } = this.props;
    this.props.getOrdersActions(tabOrders, userId);
  }

  render() {
    const { orders } = this.props;
    if (!orders || orders.length <= 0) return <div />;
    const { currentPage, pageSize, pageSizes } = this.state;
    const ordersFiltered = this.paginate(orders, pageSize, currentPage);
    const nbrColumn = nbColumn(this.props.width);
    let tabCol = [];
    for (let i = 1; i <= nbrColumn; ++i) {
      tabCol.push(i);
    }
    return (
      <GridItem container style={{ boxSizing: 'border-box' }}>
        {ordersFiltered &&
          tabCol.map((col) => {
            return (
              <GridItem
                key={col}
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={3}
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexFlow: 'column',
                }}
              >
                {this.showColumn(col, ordersFiltered)}
              </GridItem>
            );
          })}
        {orders.length === 0 && (
          <h3>
            <Translate id="central.loading" />
          </h3>
        )}
        {/*<TableUIOrders orders={tableOrder} />*/}
        <Card>
          <TablePagination
            rowsPerPageOptions={pageSizes}
            component="div"
            count={orders.length}
            rowsPerPage={pageSize}
            page={currentPage}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Card>
      </GridItem>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { order } = state;
  return {
    orders: order.orders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrdersActions: bindActionCreators(getOrders, dispatch),
  };
};

export default withWidth()(
  withLocalize(connect(mapStateToProps, mapDispatchToProps)(OrdersContainer)),
);
