import React from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '../../../components/CustomButtons/Button.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import ReactSelect from '../../../components/Form/ReactSelectUI';
import ReactTextField from '../../../components/Form/ReactTextFieldUI';
import validate from './validate';
import { postExternal } from '../../../redux/user/actions';

import { required } from '../../../components/Form/validator';

import { Translate } from 'react-localize-redux';

const tokenList = {
  typeList: [
    // {
    //   value: 'BTC',
    //   label: 'Bitcoin'
    // },
    {
      value: 'ETH',
      label: 'Ethereum',
    },
    // {
    //   value: 'NEO',
    //   label: 'NEO'
    // }
  ],
};

class FormExternal extends React.Component {
  constructor(props) {
    super(props);

    this.state = tokenList;
  }

  postExternal = () => {
    const externalWallet = this.props.myform.values;
    this.props.postNewWallet(externalWallet);
  };

  render() {
    const { myform } = this.props;
    return (
      <div>
        <form>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Field
                name="name"
                label={<Translate id="central.name" />}
                fullWidth={true}
                component={ReactTextField}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Field
                name="currency"
                label={<Translate id="externals.currency" />}
                options={this.state.typeList}
                fullWidth={true}
                component={ReactSelect}
                validate={[required]}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Field
                name="publicAddress"
                label={<Translate id="externals.address" />}
                fullWidth={true}
                component={ReactTextField}
                required
              />
            </GridItem>
          </GridContainer>
        </form>
        <GridContainer justifyContent="center">
          {/*disabled={submitting}*/}
          <Button
            type="submit"
            onClick={this.postExternal}
            color="primary"
            round
            disabled={myform && myform.syncErrors}
          >
            <Translate id="externals.addWallet" />
          </Button>
        </GridContainer>
      </div>
    );
  }
}

let formExternal = reduxForm({
  form: 'formExternal', // a unique identifier for this form
  initialValues: {
    currency: {
      value: tokenList.typeList[0].value,
      label: tokenList.typeList[0].label,
    },
  },
  validate,
  // asyncValidate
})(FormExternal);

const mapStateToProps = (state) => {
  const { form } = state;

  return {
    myform: form.formExternal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    postNewWallet: bindActionCreators(postExternal, dispatch),
  };
};

formExternal = connect(mapStateToProps, mapDispatchToProps)(formExternal);

export default formExternal;
