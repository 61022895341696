import React from 'react';
import { connect } from 'react-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';

import { bindActionCreators } from 'redux';
import { getCompanyDatas, IsAdmin } from '../../../redux/admin/actions';
import { isNull } from '../../../helpers/Common';
import LineChartDataTime from '../../../components/Charts/LineChartDataTime';
import FunnelHorizontal from '../../../components/Charts/FunnelHorizontal';
import StackedArea from '../../../components/Charts/StackedArea';

class CompanyPage extends React.Component {
  componentDidMount() {
    this.props.IsAdminActions();
    this.props.getCompanyDatasActions();
  }

  getDataFromDataTime = (datasTime) => {
    let data = [];
    for (const dataTime of datasTime) {
      data.push({
        date: new Date(dataTime.Date),
        total: dataTime.Total,
      });
    }
    return data;
  };

  render() {
    const { companyDatas } = this.props;

    if (isNull(companyDatas)) return <div />;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          SumPaiement: {companyDatas.SumPaiement}
          <br />
          <h2>Conversion</h2>
          {/*<h3>Volume Referral USD</h3>*/}
          {/*<StackedArea*/}
          {/*  name={'DataVolumeUSDByMonthReferal'}*/}
          {/*  data={companyDatas.DataVolumeUSDByMonthReferal}*/}
          {/*/>*/}
          {/*<h3>Volume Referral BTC</h3>*/}
          {/*<StackedArea*/}
          {/*  name={'DataVolumeBTCByMonthReferal'}*/}
          {/*  data={companyDatas.DataVolumeBTCByMonthReferal}*/}
          {/*/>*/}
          {/*<h3>Volume Referral ETH</h3>*/}
          {/*<StackedArea*/}
          {/*  name={'DataVolumeETHByMonthReferal'}*/}
          {/*  data={companyDatas.DataVolumeETHByMonthReferal}*/}
          {/*/>*/}
          {/*<h3>Volume Referral BNB</h3>*/}
          {/*<StackedArea*/}
          {/*  name={'DataVolumeBNBByMonthReferal'}*/}
          {/*  data={companyDatas.DataVolumeBNBByMonthReferal}*/}
          {/*/>*/}
          {/*<h3>Volume USD</h3>*/}
          {/*<StackedArea*/}
          {/*  name={'DataVolumeUSDByMonth'}*/}
          {/*  data={companyDatas.DataVolumeUSDByMonth}*/}
          {/*/>*/}
          {/*<h3>Volume BTC</h3>*/}
          {/*<StackedArea*/}
          {/*  name={'DataVolumeBTCByMonth'}*/}
          {/*  data={companyDatas.DataVolumeBTCByMonth}*/}
          {/*/>*/}
          {/*<h3>Volume ETH</h3>*/}
          {/*<StackedArea*/}
          {/*  name={'DataVolumeETHByMonth'}*/}
          {/*  data={companyDatas.DataVolumeETHByMonth}*/}
          {/*/>*/}
          {/*<h3>Volume BNB</h3>*/}
          {/*<StackedArea*/}
          {/*  name={'DataVolumeBNBByMonth'}*/}
          {/*  data={companyDatas.DataVolumeBNBByMonth}*/}
          {/*/>*/}
          {/*<h3>Volume CE USD</h3>*/}
          {/*<StackedArea*/}
          {/*  name={'DataVolumeUSDByMonthCE'}*/}
          {/*  data={companyDatas.DataVolumeUSDByMonthCE}*/}
          {/*/>*/}
          {/*<h3>Volume CE BTC</h3>*/}
          {/*<StackedArea*/}
          {/*  name={'DataVolumeBTCByMonthCE'}*/}
          {/*  data={companyDatas.DataVolumeBTCByMonthCE}*/}
          {/*/>*/}
          {/*<h3>Volume CE ETH</h3>*/}
          {/*<StackedArea*/}
          {/*  name={'DataVolumeETHByMonthCE'}*/}
          {/*  data={companyDatas.DataVolumeETHByMonthCE}*/}
          {/*/>*/}
          {/*<h3>Volume CE BNB</h3>*/}
          {/*<StackedArea*/}
          {/*  name={'DataVolumeBNBByMonthCE'}*/}
          {/*  data={companyDatas.DataVolumeBNBByMonthCE}*/}
          {/*/>*/}
          <FunnelHorizontal
            data={[
              {
                name: 'Inscrits (F:' + companyDatas.NbUsersFollower + ')',
                value: companyDatas.NbUsers,
              },
              {
                name: 'Accounts (F:' + companyDatas.NbAccountsFollower + ')',
                value: companyDatas.NbAccounts,
              },
              { name: 'Orders', value: companyDatas.NbOrders },
              { name: 'User actif < 15j', value: companyDatas.NbOrdersActif },
              { name: 'User paid', value: companyDatas.NbUsersPaiement },
              {
                name: 'User paid actif',
                value: companyDatas.NbUsersPaiementActif,
              },
            ]}
            name={'chartDivFunnel'}
          />
          {/*<h2>Nombre ordres utilisateurs par jours</h2>*/}
          {/*<LineChartDataTime*/}
          {/*  data={this.getDataFromDataTime(companyDatas.NbOrdersUsersByDay)}*/}
          {/*  name={'chartDiv2'}*/}
          {/*/>*/}
          {/*<h2>Nombre ordres suiveurs par jours</h2>*/}
          {/*<LineChartDataTime*/}
          {/*  data={this.getDataFromDataTime(companyDatas.NbOrdersFollowersByDay)}*/}
          {/*  name={'chartDiv3'}*/}
          {/*/>*/}
          {/*<h2>Nombre ordres groups@testwot.com par jours</h2>*/}
          {/*<LineChartDataTime*/}
          {/*  data={this.getDataFromDataTime(companyDatas.NbOrdersGroupsByDay)}*/}
          {/*  name={'chartDiv4'}*/}
          {/*/>*/}
          <h2>Somme des paiements par mois</h2>
          <LineChartDataTime
            data={this.getDataFromDataTime(companyDatas.SumPaiementByMonth)}
            name={'chartDiv'}
          />
          <h2>Nouveaux inscrits par mois</h2>
          <LineChartDataTime
            data={this.getDataFromDataTime(companyDatas.NbUsersByMonth)}
            name={'chartDiv1'}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    companyDatas: admin.companyDatas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyDatasActions: bindActionCreators(getCompanyDatas, dispatch),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPage);
