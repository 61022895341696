import React from 'react';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import {
  getTargets,
  isActiveOrder,
  getProfitFromOrder,
  getResultFromOrder,
  getAllStart,
  getAllStopLoss,
} from '../../helpers/order';
import { getActiveCode } from '../../helpers/localize';
import {
  parseToFloat,
  currentPriceSelector,
  stepPriceSelector,
  isNull,
  isEmpty,
  stepQtySelector,
  isOrderV2
} from '../../helpers/Common';
import { ShowPair, ShowExchange } from '../../components/Fields/LogoContainer';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import BuyType from '../../components/Order/BuyType';

import { connect } from 'react-redux';

import Divider from '@material-ui/core/Divider/Divider';
import { getTranslate, Translate } from 'react-localize-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import typographyStyle from '../../assets/jss/material-dashboard-react/components/typographyStyle.jsx';
import { getDateHourStr } from '../../helpers/general';
import MenuButtons from '../../components/CustomButtons/MenuButtons';
import OrderMenuButtons from './OrderMenuButtons';
import OrderTriggersContainer from './OrderTriggersContainer';

class OrderContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showVersion: false,
    };
  }
  showProfit = (order, currentPrice, stepPrice) => {
    const { classes } = this.props;

    const profit = getProfitFromOrder(order, currentPrice);
    const result = getResultFromOrder(order, currentPrice, stepPrice);
    const pairs = order.Pair.split('-');

    if (profit === '-') return '-';
    if (parseToFloat(profit) >= 0) {
      return (
        <span className={classes.successText}>
          {'+'}
          {result} {pairs[1]}
          {' (+' + profit + '%)'}
        </span>
      );
    }
    return (
      <span className={classes.dangerText}>
        {result} {pairs[1]}
        {' (' + profit + '%)'}
      </span>
    );
  };

  render() {
    const { order, codeLang, stepPrice, currentPrice, translate, stepQty } = this.props;
    const { showVersion } = this.state;
    if (!order) return <div />;
    const isPreview = order.OrderId === 0;
    if (!order || !Object.hasOwn(order, 'Pair')) return '';
    const pairs = order.Pair.split('-');
    const starts = getAllStart(order.Triggers);
    const targets = getTargets(order.Triggers);
    const stopLoss = getAllStopLoss(order.Triggers);

    if (
      (isNull(starts) || starts.length === 0) &&
      order &&
      order.DontDoStart !== true
    )
      return <div />;

    const isBuy = order.TVType === 1 || order.TVType === 2 ? null : order.IsBuy;

    return (
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs
              onMouseEnter={() => this.setState({ showVersion: true })}
              onMouseLeave={() => this.setState({ showVersion: false })}
            >
              {ShowExchange(order.AAccount)}
              {showVersion && <span>&nbsp;{isOrderV2(order) ? "V2" : "V1"}</span>}
              {ShowPair(order.Pair)}
            </GridItem>
            <GridItem>
              <b>
                <BuyType isBuy={isBuy} />
              </b>
              <br />
              {order.Leverage !== null
                ? translate('smartTrade.leverage') +
                ' ' +
                (order.Leverage === 0 ? 'Cross' : order.Leverage + 'x')
                : ''}
            </GridItem>
            <GridItem xs container justifyContent="flex-end">
              <GridItem>
                <small>
                  <Translate id="orders.capital" />
                  {': '}
                  <b>{order.Pourcentage}%</b>
                </small>
                <br />
                <small>{getDateHourStr(order.DateCreated, codeLang)}</small>
              </GridItem>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Divider style={{ margin: '3px 0px' }} />
            </GridItem>
          </GridContainer>
          <OrderTriggersContainer
            order={order}
            triggers={starts}
            stepPrice={stepPrice}
            stepQty={stepQty}
          />
          <OrderTriggersContainer
            order={order}
            triggers={targets}
            stepPrice={stepPrice}
            stepQty={stepQty}
          />
          <OrderTriggersContainer
            order={order}
            triggers={stopLoss}
            stepPrice={stepPrice}
            stepQty={stepQty}
          />
        </CardBody>
        <CardFooter>
          <GridContainer style={{ width: '100%' }}>
            {!isPreview && !isEmpty(order.Note) && (
              <GridItem xs={12} sm={12} md={12}>
                <Translate id="central.note" />: {order.Note}
              </GridItem>
            )}
            {!isPreview && (
              <GridItem xs>
                {currentPrice && (
                  <small>
                    <p style={{ lineHeight: '180%' }}>
                      <Translate id="smartTrade.profit" />:{' '}
                      <b>{this.showProfit(order, currentPrice, stepPrice)}</b>
                      <br />
                      <Translate id="orders.currentPrice" />:
                      <b>
                        {' '}
                        {currentPrice}
                        {pairs[1]}
                      </b>
                      <br />
                      {order.Group && order.Group.Name ? 'Trader: ' : ''}
                      <b>
                        {order.Group && order.Group.Name
                          ? order.Group.Name
                          : ''}
                      </b>
                    </p>
                  </small>
                )}
              </GridItem>
            )}
            {!isPreview && (
              <GridItem>
                <MenuButtons>
                  <OrderMenuButtons order={order} />
                </MenuButtons>
              </GridItem>
            )}
            {!isActiveOrder(order.State) && !isPreview && (
              <GridItem>
                <br />
                <small>{order.OrderId}</small>
              </GridItem>
            )}
          </GridContainer>
        </CardFooter>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { localize } = state;
  return {
    translate: getTranslate(localize),
    codeLang: getActiveCode(state),
    currentPrice: currentPriceSelector(state, props),
    stepPrice: stepPriceSelector(state, props),
    stepQty: stepQtySelector(state, props),
  };
};

export default withStyles(typographyStyle)(
  connect(mapStateToProps, null)(OrderContainer),
);
