import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import useIsMobileDevice from '../../hooks/useIsMobileDevice';

const style = {
  grid: {
    margin: '0 -15px !important',
    width: 'unset',
  },
  mobileGrid: {
    width: '100%',
    margin: '0',
  },
};

function GridContainer(props) {
  const { classes, children, ...rest } = props;
  const isMobile = useIsMobileDevice();
  return (
    <Grid
      container
      {...rest}
      className={isMobile ? classes.mobileGrid : classes.grid}
    >
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridContainer);
