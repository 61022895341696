import React from 'react';
import { getDateHourStr } from '../../helpers/general';
import { getActiveCode } from '../../helpers/localize';
import {
  dangerColor,
  successColor,
} from '../../assets/jss/material-dashboard-react';
import { isNull, parseToFloat } from '../../helpers/Common';
import { connect } from 'react-redux';
import { availableAmountsSelector } from '../../redux/order/selector';
import { getPairInfo } from '../../helpers/exchange';

const PositionContainer = ({ exchange, isAdmin, aa }) => {
  if (!exchange) return <div></div>;
  const getColor = (amount) => {
    if (amount < 0) {
      return dangerColor;
    }
    return successColor;
  };
  let sumBase = 0;
  let sumUnPNL = 0;
  return (
    <table>
      <tr>
        <th>Pair</th>
        <th>Lev.</th>
        <th>Qty</th>
        <th>EntryPrice</th>
        {isAdmin && <th>UpdateTime</th>}
        {<th>Base</th>}
        {<th>UnPnl</th>}
      </tr>
      {exchange.Positions.map((position) => {
        const updateTime = getDateHourStr(position.UpdateTime, getActiveCode);
        const pairInfo = getPairInfo(position.Pair, aa, exchange.ExchangeName);
        const value =
          position.EntryPrice *
          (position.Quantity < 0 ? -1 * position.Quantity : position.Quantity);
        const stepPrice = isNull(pairInfo) ? 0.01 : pairInfo.PrecisionPrice;
        sumBase += value;
        sumUnPNL += position.UnrealizedPnl;
        return (
          <tr key={position.PositionId} style={{ textAlign: 'center' }}>
            <td>{position.Pair}</td>
            <td>{position.Leverage}</td>
            {/*<td>{ShowPair(position.Pair)}</td>*/}
            <td style={{ color: getColor(position.Quantity) }}>
              {position.Quantity}
            </td>
            <td>{parseToFloat(position.EntryPrice, stepPrice)}</td>
            {isAdmin && <td>{updateTime}</td>}
            <td>{parseToFloat(value, 1)}$</td>
            <td style={{ color: getColor(position.UnrealizedPnl) }}>
              {parseToFloat(position.UnrealizedPnl, 1)}$
            </td>
          </tr>
        );
      })}
      {isAdmin && (
        <tr>
          <td>Sum</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{parseToFloat(sumBase, 1)}$</td>
          <td style={{ color: getColor(sumUnPNL) }}>
            {parseToFloat(sumUnPNL, 1)}$
          </td>
        </tr>
      )}
    </table>
  );
};

function mapStateToProps(state) {
  return {
    aa: availableAmountsSelector(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionContainer);
