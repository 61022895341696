import React from 'react';

export default class PrivacyPolicyRUPage extends React.Component {
  render() {
    return (
      <div style={{ textAlign: 'justify' }}>
        <h1>
          <b>Политика конфиденциальности</b>
        </h1>
        Компания Wall Of Traders, заботящаяся о правах отдельных лиц, особенно в
        отношении автоматизированной обработки, и в стремлении обеспечить
        прозрачность работы со своими клиентами, разработала политику,
        охватывающую все эти виды обработки, преследуемые ими цели, а также
        средства действия, доступные для людей, чтобы они могли наилучшим
        образом реализовать свои права. Для получения дополнительной информации
        о защите персональных данных мы приглашаем вас посетить веб-сайт:
        https://www.cnil.fr/
        <br />
        Для продолжения навигации по этому сайту подразумевается безоговорочное
        принятие следующих условий использования.
        <h3>
          <b>Статья 1 - Официальное уведомление</b>
        </h3>
        <h4>
          <u>1.1 Сайт (далее «сайт»):</u>
        </h4>
        Wall Of Traders
        <h4>
          <u>1.2 Издатель (далее «издатель»):</u>
        </h4>
        Wall Of Traders SAS с капиталом 1000 € со штаб-квартирой по адресу: 15
        rue des Halles 75001 Paris президента зарегистрирован на адрес
        электронной почты RCS Paris number SIREN 907601579. Email:
        contact@walloftraders.com
        <h4>
          <u>1.3 Хост (далее «хост»):</u>
        </h4>
        Wall Of Traders размещена в So You Start OVH со штаб-квартирой по
        адресу: 2 rue Kellermann 59100 Roubaix.
        <h3>
          <b>Статья 2 - Доступ к сайту</b>
        </h3>
        Доступ к сайту и его использование зарезервированы исключительно для
        личного пользования. Вы соглашаетесь не использовать этот сайт и любую
        информацию или данные, содержащиеся на нем, для коммерческих,
        политических, рекламных или любых других форм коммерческих предложений
        и, в частности, для отправки нежелательных электронных писем.
        <h3>
          <b>Статья 3 - Содержание сайта</b>
        </h3>
        Все бренды, фотографии, тексты, комментарии, иллюстрации, анимированные
        или не анимированные изображения, видеопоследовательности, звуки, а
        также все компьютерные приложения, которые могут быть использованы для
        обеспечения работы этого сайта, и в целом все элементы, воспроизводимые
        или используемые на Сайте защищены действующим законодательством об
        интеллектуальной собственности. Они являются полной собственностью
        издателя или его партнеров. Любое воспроизведение, представление,
        использование или адаптация, в какой бы то ни было форме, всех или части
        этих элементов, включая компьютерные приложения, без предварительного
        письменного согласия издателя строго запрещены. Тот факт, что издатель
        не возбуждает судебное разбирательство после того, как ему стало
        известно об этих несанкционированных использованиях, не означает
        принятие таких видов использования и отказ от судебного преследования
        <h3>
          <b>Статья 4 - Управление сайтом</b>
        </h3>
        Для хорошего управления сайтом, издатель может в любое время:
        <ul>
          <li>
            приостанавливать, прерывать или ограничивать доступ ко всему или
            части сайта, резервировать доступ к сайту или частям сайта для
            определенной категории пользователей Интернета;
          </li>
          <li>
            удалять любую информацию, которая может помешать ее работе или
            которая противоречит национальным или международным законам;
          </li>
          <li>приостановить работу сайта для проведения обновлений.</li>
        </ul>
        <h3>
          <b>Статья 5 - Обязанности</b>
        </h3>
        Ответственность издателя не может быть взята на себя в случае сбоя,
        поломки, затруднения или прерывания работы, препятствующей доступу к
        сайту или одной из его функциональных возможностей. Материалы для связи
        с сайтом, который вы используете, находятся под вашей исключительной
        ответственностью. Вы должны принять все необходимые меры для защиты
        вашего оборудования и ваших собственных данных, включая вирусные атаки
        через Интернет. Вы также несете полную ответственность за сайты и
        данные, которые вы посещаете.
        <br />
        Издатель не может быть привлечен к ответственности в случае судебного
        разбирательства против вас:
        <ul>
          <li>
            из-за использования сайта или любого сервиса, доступного через
            Интернет;
          </li>
          <li>из-за несоблюдения вами настоящих условий.</li>
        </ul>
        Издатель не несет ответственности за ущерб, нанесенный вам, другим лицам
        и / или вашему оборудованию в результате вашего подключения или
        использования сайта, и вы не будете предпринимать никаких действий
        против него. Если издатель должен быть объектом дружественной или
        судебной процедуры из-за вашего использования сайта, он может отказать
        вам в получении компенсации за все убытки, суммы, осуждения и расходы,
        которые могут возникнуть в результате этой процедуры.
        <h3>
          <b>Статья 6 - Гипертекстовые ссылки</b>
        </h3>
        Настройка пользователями всех гипертекстовых ссылок на весь сайт или его
        часть разрешена издателем. Все ссылки должны быть удалены по требованию
        издателя. Любая информация, доступная по ссылке на другие сайты, не
        публикуется издателем. Издатель не имеет прав на содержание в ссылке.
        <h3>
          <b>Статья 7 - Сбор и защита данных</b>
        </h3>
        Ваши данные собраны Wall Of Traders. Персональные данные - любая
        информация, относящаяся к идентифицированному или идентифицируемому
        физическому лицу (субъекту данных); идентифицируемое лицо - это лицо,
        которое может быть идентифицировано прямо или косвенно, в частности,
        посредством ссылки на имя, идентификационный номер или один или
        несколько конкретных элементов, характерных для его физической,
        физиологической, генетической, психологической, экономической,
        культурной или социальной идентичности. Социальное. Личная информация,
        которую можно собирать на сайте, в основном используется издателем для
        управления отношениями с вами и, при необходимости, для обработки ваших
        заказов.
        <br />
        Собранные персональные данные:
        <ul>
          <li>Фамилия и имя</li>
          <li>Адрес электронной почты</li>
        </ul>
        <h3>
          <b>Статья 8 - Право на доступ, исправление и удаление ваших данных</b>
        </h3>
        При применении правил, применимых к персональным данным, пользователи
        имеют следующие права:
        <ul>
          <li>
            Право на доступ: они могут воспользоваться своим правом на доступ,
            знать личные данные о них, написав по адресу электронной почты,
            указанному ниже. В этом случае до реализации этого права Платформа
            может запросить подтверждение личности пользователя для проверки его
            точности;
          </li>
          <li>
            Право на исправление: если личные данные, хранящиеся на Платформе,
            являются неточными, они могут запросить обновление информации;
          </li>
          <li>
            Право на удаление данных: пользователи могут запросить удаление
            своих персональных данных в соответствии с действующим
            законодательством о защите данных;
          </li>
          <li>
            Право на ограничение обработки: пользователи могут запросить
            Платформу для ограничения обработки персональных данных в
            соответствии с предположениями, предоставленными GDPR;
          </li>
          <li>
            Право возражать против обработки данных: пользователи могут
            возражать против обработки их данных в соответствии с допущениями,
            изложенными в GDPR;
          </li>
          <li>
            Право на переносимость: они могут утверждать, что Платформа
            предоставляет им персональные данные, которые они предоставили, для
            передачи их на новую Платформу.
          </li>
        </ul>
        Вы можете воспользоваться этим правом, связавшись с нами по электронной
        почте: contact@walloftraders.com.
        <br />
        Все запросы должны сопровождаться фотокопией действительного
        удостоверения личности и указывать адрес, по которому издатель может
        связаться с заявителем. Ответ будет отправлен в течение одного месяца с
        момента получения запроса. Этот месячный период может быть продлен на
        два месяца, если этого требует сложность запроса и / или количество
        запросов.
        <br />
        Более того, и с момента принятия закона № 2016-1321 от 7 октября 2016
        года люди, которые этого хотят, имеют возможность организации своих
        данных после их смерти. Для получения дополнительной информации по этому
        вопросу вы можете обратиться на веб-сайт CNIL: https://www.cnil.fr/.
        <br />
        Пользователи также могут подать жалобу в CNIL на веб-сайте CNIL:
        https://www.cnil.fr.
        <br />
        Мы рекомендуем вам сначала связаться с нами перед подачей жалобы в CNIL,
        потому что мы в вашем распоряжении, чтобы решить вашу проблему.
        <br />
        <h3>
          <b>Статья 9 - Использование данных</b>
        </h3>
        Персональные данные, полученные от пользователей, направлены на
        предоставление услуг Платформы, их улучшение и поддержание безопасной
        среды. Правовой основой обработки является исполнение договора между
        пользователем и Платформой. В частности, используются:
        <ul>
          <li>доступ и использование Платформы пользователем;</li>
          <li>управление операциями и оптимизация Платформы;</li>
          <li>реализация помощи пользователям;</li>
          <li>
            verification, identification and authentication of the data
            transmitted by the user;
          </li>
          <li>
            проверка, идентификация и аутентификация данных, передаваемых
            пользователем; настройка услуг путем отображения рекламы на основе
            истории посещений пользователя, в соответствии с его предпочтениями;
          </li>
          <li>
            предотвращение и обнаружение мошенничества, вредоносных программ
            (вредоносных программ) и управление инцидентами безопасности;
          </li>
          <li>разрешение любых споров с пользователями;</li>
          <li>
            отправка коммерческой и рекламной информации, в соответствии с
            предпочтениями пользователя;
          </li>
          <li>организация условий использования Платежных услуг.</li>
        </ul>
        <h3>
          <b>Статья 10 - Политика хранения данных</b>
        </h3>
        Платформа хранит ваши данные столько времени, сколько необходимо для
        оказания вам услуг или помощи. В той степени, в которой это разумно
        необходимо или необходимо для выполнения правовых или нормативных
        обязательств, для разрешения споров, предотвращения мошенничества и
        злоупотреблений или для обеспечения соблюдения наших условий и
        положений, мы также можем сохранять часть вашей информации, если это
        необходимо, даже после того, как вы закрыли свою учетную запись. или что
        нам больше не нужно предоставлять вам наши услуги.
        <br />
        <h3>
          <b>Статья 11 - Обмен персональными данными с третьими лицами</b>
        </h3>
        Личные данные могут передаваться сторонним компаниям исключительно в
        Европейском Союзе, в следующих случаях:
        <ul>
          <li>
            когда пользователь использует платежные сервисы, для реализации этих
            сервисов Платформа связывается со сторонними банковскими и
            финансовыми компаниями, с которыми она заключила договор;
          </li>
          <li>
            когда пользователь публикует общедоступную информацию в открытых
            областях комментариев Платформы;
          </li>
          <li>
            когда пользователь авторизует веб-сайт третьей стороны для доступа к
            своим данным;
          </li>
          <li>
            когда Платформа использует услуги провайдера для предоставления
            поддержки пользователей, рекламных и платежных услуг. Эти поставщики
            услуг имеют ограниченный доступ к данным пользователя, в связи с
            выполнением этих услуг, и имеют договорное обязательство
            использовать их в соответствии с положениями применимых правил
            защиты данных. личный характер;
          </li>
          <li>
            если этого требует закон, Платформа может передавать данные для
            ответа на претензии к Платформе и соблюдения административных и
            судебных процедур.
          </li>
          <li>
            Политика конфиденциальности Trading View не связана с настоящей
            Политикой конфиденциальности.
          </li>
        </ul>
        <h3>
          <b>Статья 12 - Коммерческие предложения</b>
        </h3>
        Вы, вероятно, получите коммерческие предложения от издателя. Если вы не
        хотите, пожалуйста, отправьте электронное письмо по адресу:
        contact@walloftraders.com. Ваши данные могут использоваться партнерами
        издателя в маркетинговых целях, если вы не хотите, пожалуйста, отправьте
        электронное письмо по адресу: contact@walloftraders.com. Если во время
        консультации с сайтом вы получаете доступ к личным данным, вы должны
        воздерживаться от сбора, любого несанкционированного использования и
        любых действий, которые могут представлять собой посягательство на
        частную жизнь или репутацию отдельных лиц. Издатель не несет никакой
        ответственности в этом отношении. Данные хранятся и используются в
        течение периода в соответствии с действующим законодательством.
        <br />
        <h3>
          <b>Статья 13 - «cookie»</b>
        </h3>
        Что такое «cookie»? «Cookie» - это электронный файл, который хранится на
        терминале (компьютере, планшете, смартфоне и т. Д.) И читается,
        например, во время работы на веб-сайте, чтения электронной почты,
        установки или использования программного обеспечения или мобильного
        приложения и тому подобное, независимо от типа используемого терминала
        (источник: https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi). На
        этом устройстве на вашем устройстве могут храниться файлы cookie от
        компании, ответственной за сайт и / или сторонних компаний. Во время
        первой навигации по этому сайту появится пояснительный баннер об
        использовании файлов cookie. Поэтому продолжая навигацию, клиент и / или
        потенциальный клиент будут считаться информированными и согласными на
        использование указанных «файлов cookie». Полученное согласие будет
        действовать в течение тринадцати (13) месяцев. Пользователь может
        отключить файлы cookie. из его настроек браузера.
        <br />
        Вся собранная информация будет использоваться только для отслеживания
        объема, типа и конфигурации трафика с использованием этого сайта, для
        разработки дизайна и макета, а также для других административных и
        планировочных целей и, в более общем плане, для улучшения обслуживания.
        <br />
        На этом сайте присутствуют следующие файлы cookie:
        <br />
        <br />
        <b>Google cookies:</b>
        <ul>
          <li>Аналитика Google: для измерения аудитории сайта;</li>
          <li>
            Менеджер тегов Google: облегчает внедрение тегов на страницах и
            позволяет управлять тегами Google;
          </li>
          <li>
            Google Adsense: рекламное агентство Google, использующее сайты или
            видео YouTube в качестве поддержки своих объявлений;
          </li>
          <li>
            Динамический ремаркетинг Google: позволяет предлагать динамическую
            рекламу на основе предыдущих исследований;
          </li>
          <li>
            Google Adwords Conversion: инструмент для отслеживания рекламных
            кампаний AdWords;
          </li>
          <li>
            DoubleClick: рекламные cookie-файлы Google для распространения
            баннеров.
          </li>
        </ul>
        <b>Файлы cookie Facebook:</b>
        <ul>
          <li>
            Facebook connect: позволяет идентифицировать себя с помощью вашей
            учетной записи Facebook;
          </li>
          <li>
            Социальные плагины Facebook: позволяет лайкать, делиться,
            комментировать контент с учетной записью Facebook;
          </li>
          <li>
            Facebook Custom Audience: позволяет взаимодействовать с аудиторией в
            Facebook.
          </li>
        </ul>
        <b>Файлы cookie Twitter:</b>
        <ul>
          <li>
            Кнопка Twitter: позволяет легко делиться и просматривать контент
            Twitter;
          </li>
          <li>
            Реклама в Твиттере: позволяет показывать и нацеливать рекламные
            объявления рекламного агентства Твиттера.
          </li>
        </ul>
        Срок действия этих файлов cookie составляет тринадцать месяцев.
        <br />
        Для получения дополнительной информации об использовании, управлении и
        удалении файлов «cookie» для любого типа браузера мы предлагаем вам
        обратиться по следующей ссылке:
        https://www.cnil.fr/fr/cookies-les-outils-pour- ле-maitriser.
        <br />
        <h3>
          <b>Статья 14 - Фотографии и изображения продуктов</b>
        </h3>
        Фотографии продукции, сопровождающие их описание, не являются
        договорными и не привлекают к ответственности издателя.
        <br />
        <h3>
          <b>Статья 15 - Применимое право</b>
        </h3>
        Нынешние условия использования сайта регулируются французским
        законодательством и подпадают под юрисдикцию судов головного офиса
        издателя при условии присвоения определенной компетенции, вытекающей из
        текста конкретного закона или постановления.
        <br />
        <h3>
          <b>Статья 16 - Язык</b>
        </h3>
        В случае перевода данной политики конфиденциальности на другие языки,
        основным языком будет французский язык.
        <br />
        <h3>
          <b>Статья 17 - Свяжитесь с нами</b>
        </h3>
        По любым вопросам, информации о продуктах, представленных на сайте, или
        о самом сайте, вы можете отправить электронное письмо по адресу:
        contact@walloftraders.com.
      </div>
    );
  }
}
