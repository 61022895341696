import React from 'react';
// @material-ui/core components
// core components
import GridItem from '../../components/Grid/GridItem.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardBody from '../../components/Card/CardBody.jsx';

import { Translate } from 'react-localize-redux';
// import transExternals from '../../../locales/externals.json';
import useTranslation from '../../hooks/useTranslation';
import externalsjson from '../../locales/externals.json';
import { makeStyles } from '@material-ui/core/styles';
import FormWithdrawal from './FormWithdrawal/FormWithdrawal';

const useStyles = makeStyles(() => ({
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
}));

const WithdrawalPopup = ({ stopPopup }) => {
  useTranslation(externalsjson);
  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                <Translate id="referrals.RequestWithdrawal" />
              </h4>
              <p className={classes.cardCategoryWhite}>
                <Translate id="referrals.RequestWithdrawalMini" />
              </p>
            </CardHeader>
            <CardBody>
              <FormWithdrawal stopPopup={stopPopup} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default WithdrawalPopup;
