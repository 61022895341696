import React from 'react';
import { connect } from 'react-redux';
import {
  FormSection,
  formValueSelector,
  change,
  getFormSyncErrors,
} from 'redux-form';
import { bindActionCreators } from 'redux';
import { getTranslate } from 'react-localize-redux';
import TokensLine from './TokensLine';

class EditableTableTokens extends React.PureComponent {
  // nbRows = () => {
  //   const tab = this.props[this.props.name] || [];
  //   let i = 0;
  //   for (const t of tab) {
  //     if (!isEmpty(t.amount) || !isEmpty(t.token)) {
  //       ++i;
  //     }
  //   }
  //   return i + 1;
  // };
  render() {
    const { name, formName, holds } = this.props;
    let numRows = holds.length + 1; //this.nbRows();
    return (
      <FormSection name={`${name}`}>
        {[...Array(numRows).keys()].map((index) => (
          <TokensLine
            isLast={holds.length === index}
            index={index}
            key={index}
            formName={formName}
            name={name}
          />
        ))}
        {/*{syncErrors &&*/}
        {/*syncErrors.targets && (*/}
        {/*  <span style={{ color: dangerColor }}>*/}
        {/*      {syncErrors.targets}*/}
        {/*    </span>*/}
        {/*)}*/}
      </FormSection>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { localize } = state;
  const formSelector = formValueSelector(props.formName);
  return {
    isUpdate: formSelector(state, 'isUpdate'),
    translate: getTranslate(localize),
    holds: formSelector(state, 'holds'),
    syncErrors: getFormSyncErrors(props.formName)(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

const Compo = connect((state, props) => ({
  [props.name]: formValueSelector(props.formName)(state, props.name),
}))(EditableTableTokens);

export default connect(mapStateToProps, mapDispatchToProps)(Compo);
