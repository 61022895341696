import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import ReactTextField from '../Form/ReactTextFieldUI';
// import { Field } from 'redux-form-normalize-on-blur';
import { Field } from 'redux-form';
import {
  // capitalSelector,
  // initialValuesSelector,
  parseToFloat,
} from '../../helpers/Common';
// import { change, getFormSyncErrors, getFormValues } from 'redux-form';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';

export default class Percentage extends React.PureComponent {
  render() {
    const { name, step } = this.props;
    // console.log('Percentage render', name,values);
    const s = step !== undefined ? step : 0.01;
    // const miV = min !== undefined ? min : 0;
    // const maV = max !== undefined ? max : 100;
    // const between = (min, max, value) => {
    //   if (min !== '' && parseFloat(value) < parseFloat(min)) return min;
    //   if (max !== '' && parseFloat(value) > parseFloat(max)) return max;
    //   return value;
    // };
    // const between = (min, max, value) => {
    //   if (
    //     (min !== '' && parseFloat(value) < parseFloat(min)) ||
    //     (max !== '' && parseFloat(value) > parseFloat(max))
    //   )
    //     return `Should be between ${min} and ${max}`;
    //   return '';
    // };
    return (
      <Field
        className="form-control"
        name={name}
        fullWidth={true}
        component={ReactTextField}
        type="number"
        parse={(value) => parseToFloat(value, s)}
        // warning="s"
        // validate={value => between(miV, maV, value)}
        // normalizeOnBlur={value => between(miV, maV, value)}
        inputProps={{ step: s }}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        required
        {...this.props}
      />
    );
  }
}
//
//
// const mapStateToProps = (state, props) => {
//   return {
//     // important: initialValues prop will be read by redux-form
//     // the keys must match the `name` property of the each form field
//     // initialValues: initialValuesSelector(state, props),
//     // errors: getFormSyncErrors(props.formName)(state),
//     // capital: capitalSelector(state)
//   };
// };
//
// // const mapDispatchToProps = dispatch => {
// //   return {
// //     change: bindActionCreators(change, dispatch)
// //   };
// // };
//
// export default connect(
//   mapStateToProps,
//   null
// )(Percentage);
