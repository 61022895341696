import React from 'react';
import {
  change,
  Field,
  formValueSelector,
  getFormSyncErrors,
  getFormValues,
  initialize,
  reduxForm,
} from 'redux-form';

import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Grid from '@material-ui/core/Grid';
import ReactTextField from '../../../components/Form/ReactTextFieldUI';
import validate from './validate';
import { getTranslate, Translate, withLocalize } from 'react-localize-redux';

import { connect } from 'react-redux';

import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { isNull } from '../../../helpers/Common';
import Button from '../../../components/CustomButtons/Button';
import { createCampaign } from '../../../redux/referrals/actions';

const formName = 'formAddReferralName';

class FormAddReferralName extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: null,
    };
  }

  // createCampaign = () => {
  //   const sendCampaignName = this.props.data;
  //   console.log('THE INFO: ', sendCampaignName);
  //   this.props.createCampaignWithChosenName(sendCampaignName);
  // };

  createCampaign = () => {
    const { data, isUpdate } = this.props;
    this.setState({ campaign: data });

    if (isUpdate) {
      // it already exist and we modify it
      this.props.createCampaignWithChosenName(data);
    } else {
      // it doesnt exist and we create it
      this.props.createCampaignWithChosenName(data);
    }
  };

  render() {
    const { stopPopup, isUpdate, errors, loading } = this.props;
    const hasErrors = Object.keys(errors).length !== 0 || loading.type === 'ON';

    return (
      <Grid container>
        <GridItem xs={12} sm={12} md={6}>
          <Field
            name="name"
            label={<Translate id="referrals.referralLinkName" />}
            fullWidth={true}
            component={ReactTextField}
            required
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer justifyContent="center">
            <Button
              type="submit"
              onClick={() => {
                this.createCampaign();
                stopPopup();
              }}
              color="primary"
              round
              disabled={hasErrors}
            >
              {isUpdate ? (
                <Translate id="traders.updateMyGroup" />
              ) : (
                <Translate id="referrals.Create" />
              )}
            </Button>
            <Button
              color="danger"
              round
              onClick={() => {
                if (stopPopup) stopPopup();
              }}
            >
              <Translate id="central.cancel" />
            </Button>
          </GridContainer>
        </GridItem>
      </Grid>
    );
  }
}

const formAddReferralName = reduxForm({
  form: formName, // a unique identifier for this form
  touchOnChange: true,
  validate,
})(FormAddReferralName);

const newCampaignSelector = (state, props) => {
  return isNull(props.campaign) ? null : props.campaign;
};

const initialValuesSelector = createSelector(
  newCampaignSelector,
  (campaign) => {
    if (campaign) {
      return {
        isUpdate: true,
        name: campaign.Name,
      };
    }
    return {
      isUpdate: false,
      name: '',
    };
  },
);

const mapStateToProps = (state, props) => {
  const { loading, localize } = state;
  const formSelector = formValueSelector(formName);
  return {
    translate: getTranslate(localize),
    loading,
    errors: getFormSyncErrors(formName)(state),
    data: getFormValues(formName)(state),
    isUpdate: formSelector(state, 'isUpdate'),
    name: formSelector(state, 'Name'),
    initialValues: initialValuesSelector(state, props),
    // myform: form.formName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initialize: bindActionCreators(initialize, dispatch),
    change: bindActionCreators(change, dispatch),
    createCampaignWithChosenName: bindActionCreators(createCampaign, dispatch),
    // postGroupActions: bindActionCreators(postGroup, dispatch),
    // putGroupActions: bindActionCreators(putGroup, dispatch),
    // deleteGroupActions: bindActionCreators(deleteGroup, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(formAddReferralName),
);
