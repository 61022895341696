import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Danger from '../../components/Typography/Danger';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button.jsx';
import CustomInput from '../../components/CustomInput/CustomInput';
import { panicSellOrder } from '../../redux/order/actions';
import { getPercentageRemaining } from '../../helpers/order';
import { isNull, isOrderV2 } from '../../helpers/Common';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogPanicSell extends React.PureComponent {
  constructor(props) {
    super(props);

    const percentageRemaining = getPercentageRemaining(this.props.order);

    this.state = {
      percentageRemaining: percentageRemaining,
      percentage: percentageRemaining,
      invalidPercentage: false,
    };
  }
  componentDidUpdate(prevProps) {
    const { order } = this.props;
    if (prevProps.order !== order) {
      const percentageRemaining = getPercentageRemaining(order);
      if (percentageRemaining === this.state.percentageRemaining)
        return;

      this.setState({
        percentageRemaining: percentageRemaining,
        percentage: percentageRemaining,
        invalidPercentage: false,
      });
    }
  }
  onChange = (e) => {
    this.setState({ percentage: e.target.value });

    this.setState({
      invalidPercentage:
        e.target.value < 0 || e.target.value > this.state.percentageRemaining,
    });
  };
  render() {
    const { percentageRemaining } = this.state;
    const { open, onClose } = this.props;
    const panicSellOrder = () => {
      const { percentage } = this.state;
      const { order } = this.props;

      if (!isNull(order))
        this.props.panicSellOrderActions(
          order.OrderId,
          percentage,
          isOrderV2(order),
        );
      onClose();
    };
    return (
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        // keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <GridItem container justifyContent="flex-end">
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </GridItem>
        <DialogContent>
          <GridItem>
            <GridItem>
              <Translate id="orders.panicSellText" />
            </GridItem>
            <GridItem>
              <GridItem container justifyContent="center">
                <GridItem>
                  <CustomInput
                    name="partPourcentage"
                    label={<Translate id="central.percentage" />}
                    inputProps={{
                      defaultValue: percentageRemaining,
                      type: 'number',
                      onChange: this.onChange,
                      inputProps: { min: 0, max: 100 },
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      style: { margin: 0 },
                    }}
                  />
                </GridItem>
              </GridItem>
              {this.state.invalidPercentage && (
                <GridItem container justifyContent="center">
                  <Danger>
                    <Translate
                      id="error.between"
                      data={{ min: 0, max: percentageRemaining }}
                    />
                  </Danger>
                </GridItem>
              )}
              <GridItem container justifyContent="center">
                <Button
                  color="info"
                  position="center"
                  onClick={() => panicSellOrder()}
                  disabled={this.state.invalidPercentage}
                >
                  <Translate id="smartTrade.sellMarket" />
                </Button>
              </GridItem>
            </GridItem>
          </GridItem>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    panicSellOrderActions: bindActionCreators(panicSellOrder, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(DialogPanicSell);
