import React from 'react';
import PropTypes from 'prop-types';
import { getTableExchange } from '../../../helpers/exchange';
import TableUIExchange from '../../../components/Table/TableUIExchange';
import GridItem from '../../../components/Grid/GridItem.jsx';
import { ShowAccount } from '../../../components/Fields/LogoContainer';

class TableExchange extends React.Component {
  render() {
    const { exchange } = this.props;
    const table = getTableExchange(exchange);
    const title = ShowAccount(exchange);
    return (
      <GridItem xs={12} sm={12} md={12}>
        <h3 style={{ textAlign: 'center' }}>
          <b>{title}</b>
        </h3>
        <TableUIExchange exchange={table} />
      </GridItem>
    );
  }
}

TableExchange.propTypes = {
  exchange: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.object.isRequired,
  ]),
};

export default TableExchange;
