import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedSummary,
  PagingState,
  SortingState,
  SummaryState,
} from '@devexpress/dx-react-grid';
import {
  DragDropProvider,
  Grid,
  PagingPanel,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Card from '../../components/Card/Card';
import GridItem from '../../components/Grid/GridItem';
import { satoshi } from '../../helpers/general';
import { getActiveCode } from '../../helpers/localize';
import {
  getDistanceNextMove,
  getPanicSell,
  getProfitFromOrder,
  getQtyRemaining,
  getResultFromOrder,
  getStart,
  getStateStr,
  getStopLoss,
  getTargets,
  getTriggerPrice,
  isActiveOrder,
  isFinishedOrder,
} from '../../helpers/order';
import {
  ActionButtonsProvider,
  ColorEntryQtyProvider,
  ColorExitQtyProvider,
  ColorGainProvider,
  ColorPourcentageProvider,
  ColorPriceProvider,
  DateProvider,
  DollarProvider,
  ExchangeProvider,
  NumberEditorBase,
  PairProvider,
  PourcentageProvider,
  SideProvider,
  SliderProvider,
  numberFilterOperations,
} from '../../helpers/table';
import { createOrdersGridAction } from '../../redux/config/actions';
import { getOrders } from '../../redux/order/actions';
import {
  currentPriceSelector,
  isEmpty,
  isNull,
  parseToFloat,
  secPriceUSDSelector,
  stepPriceSelector,
} from '../../helpers/Common';

class TableUIOrders extends React.PureComponent {
  componentDidMount() {
    const { tabOrders, userId } = this.props;
    this.props.getOrdersActions(tabOrders, userId);
  }

  render() {
    const {
      rows,
      columns,
      columnWidths,
      filters,
      currentPage,
      pageSize,
      pageSizes,
      columnOrder,
      sorting,
      totalSummaryItems,
      tableColumnExtensions,
      onFiltersChange,
      onPageSizeChange,
      onCurrentPageChange,
      onColumnOrderChange,
      onSortingChange,
      onColumnWidthsChange,
    } = this.props;
    return (
      <GridItem container xs={12} sm={12} md={12}>
        {/*<Card style={{ marginBottom: '350px' }}>*/}
        <Card>
          <Grid rows={rows} columns={columns}>
            <FilteringState
              filters={filters}
              onFiltersChange={onFiltersChange}
            />
            <SortingState
              defaultSorting={sorting}
              sorting={sorting}
              onSortingChange={onSortingChange}
            />
            <PagingState
              defaultCurrentPage={currentPage}
              defaultPageSize={pageSize}
              onPageSizeChange={onPageSizeChange}
              onCurrentPageChange={onCurrentPageChange}
            />
            {/*<TreeDataState />*/}
            <SummaryState totalItems={totalSummaryItems} />

            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedPaging />
            <IntegratedSummary />

            <DragDropProvider />
            <PourcentageProvider
              for={['Capital', 'DistNextOrder']}
              editorComponent={NumberEditorBase}
            />
            <ActionButtonsProvider for={['Action']} />
            <ColorGainProvider
              for={['Gain']}
              editorComponent={NumberEditorBase}
            />
            <ColorEntryQtyProvider
              for={['EntryQty']}
              editorComponent={NumberEditorBase}
            />
            <ColorExitQtyProvider
              for={['ExitQty']}
              editorComponent={NumberEditorBase}
            />
            <ColorPourcentageProvider
              for={['Profit', 'MaxDrawdown']}
              editorComponent={NumberEditorBase}
            />
            <ExchangeProvider for={['Exchange']} />
            <PairProvider for={['Pair']} />
            <SideProvider for={['Side']} />
            {/*<DateAgoProvider for={['DateCreated', 'LastUpdate']} />*/}
            <DateProvider for={['DateCreated', 'LastUpdate']} />
            <ColorPriceProvider
              editorComponent={NumberEditorBase}
              for={['StartPrice', 'Targets', 'StopLoss', 'PanicSell']}
            />
            <SliderProvider
              for={['Slider']}
              editorComponent={NumberEditorBase}
            />
            <DollarProvider for={['USD']} />
            <DataTypeProvider
              for={['USD', 'CurrentPrice', 'Action']}
              editorComponent={NumberEditorBase}
              availableFilterOperations={numberFilterOperations}
            />

            <Table columnExtensions={tableColumnExtensions} />
            <TableColumnResizing
              defaultColumnWidths={columnWidths}
              columnWidths={columnWidths}
              onColumnWidthsChange={onColumnWidthsChange}
            />
            <TableHeaderRow showSortingControls={true} />
            <TableColumnReordering
              order={columnOrder}
              onOrderChange={onColumnOrderChange}
            />
            <TableSummaryRow />
            <TableFilterRow showFilterSelector={true} />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
          {/*{rows && <pre>{JSON.stringify(rows, null, 2)}</pre>}*/}
        </Card>
      </GridItem>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { config, order } = state;
  const { ordersGrid } = config;
  const {
    filters,
    pageSizes,
    currentPage,
    pageSize,
    columnOrder,
    columnWidths,
    sorting,
    totalSummaryItems,
    tableColumnExtensions,
  } = ordersGrid;
  const { tabOrders, isAdmin } = props;
  const isHistoric = [2, 3].includes(tabOrders);
  const isTemplate = [4].includes(tabOrders);
  const codeLang = getActiveCode(state);
  const orders = !isEmpty(props.orders) ? props.orders : order.orders;
  const haveLeverage = orders.filter((x) => x.Leverage > 1).length > 0;
  const columns = [
    {
      name: 'Action',
      title: <Translate id="central.actions" />,
      isAdmin: isAdmin,
      getCellValue: (row) => {
        return row.OrderId;
      },
    },
    {
      name: 'Disabled',
      title: <Translate id="orders.templateDisabled" />,
      getCellValue: (row) => (row['IsDisabled'] === true ? 'true' : 'false'),
    },
    {
      name: 'Exchange',
      title: <Translate id="orders.exchange" />,
      getCellValue: (row) => (row['AAccount'] ? row['AAccount'].Name : ''),
    },
    { name: 'Pair', title: <Translate id="smartTrade.pair" /> },
    {
      name: 'Side',
      title: <Translate id="orders.mode" />,
      getCellValue: (row) => {
        const result = row['IsBuy']
          ? codeLang === 'fr'
            ? 'achat'
            : 'buy'
          : codeLang === 'fr'
          ? 'vente'
          : 'sell';
        return result;
      },
    },
    {
      name: 'DistNextOrder',
      title: <Translate id="orders.distNextOrder" />,
      codeLang: codeLang,
      getCellValue: (row) => {
        return getDistanceNextMove(
          row,
          currentPriceSelector(state, { order: row }),
        );
      },
    },
    {
      name: 'Slider',
      title: <Translate id="orders.slider" />,
      codeLang: codeLang,
      getCellValue: (row) => {
        return getDistanceNextMove(
          row,
          currentPriceSelector(state, { order: row }),
        );
      },
    },
    {
      name: 'Leverage',
      title: <Translate id="orders.leverege" />,
    },
    {
      name: 'StartPrice',
      title: <Translate id="orders.entryPrice" />,
      codeLang: codeLang,
      getCellValue: (row) => {
        const stepPrice = stepPriceSelector(state, { order: row });
        return parseToFloat(getTriggerPrice(getStart(row.Triggers)), stepPrice);
      },
    },
    {
      name: 'Targets',
      title: <Translate id="orders.takeProfits" />,
      codeLang: codeLang,
      getCellValue: (row) => {
        const stepPrice = stepPriceSelector(state, { order: row });
        const targets = getTargets(row.Triggers);
        let ret = '';
        for (const target of targets) {
          if (ret !== '') ret += ', ';
          ret += parseToFloat(target.Pourcentage, 0.01);
          ret += '%: ';
          ret += parseToFloat(getTriggerPrice(target), stepPrice);
        }
        return ret;
      },
    },
    {
      name: 'StopLoss',
      title: <Translate id="orders.stopLoss" />,
      codeLang: codeLang,
      getCellValue: (row) => {
        const stepPrice = stepPriceSelector(state, { order: row });
        return parseToFloat(
          getTriggerPrice(getStopLoss(row.Triggers)),
          stepPrice,
        );
      },
    },
    {
      name: 'PanicSell',
      title: <Translate id="orders.panicSell" />,
      codeLang: codeLang,
      getCellValue: (row) => {
        const stepPrice = stepPriceSelector(state, { order: row });
        return parseToFloat(
          getTriggerPrice(getPanicSell(row.Triggers)),
          stepPrice,
        );
      },
    },
    {
      name: 'CurrentPrice',
      title: <Translate id="orders.currentPrice" />,
      getCellValue: (row) => {
        return parseToFloat(
          currentPriceSelector(state, { order: row }),
          satoshi,
        );
      },
    },
    {
      name: 'EntryQty',
      title: <Translate id="orders.entryQty" />,
      getCellValue: (row) => {
        return row.Quantity;
      },
    },
    {
      name: 'ExitQty',
      title: <Translate id="orders.exitQty" />,
      getCellValue: (row) => {
        const order = row;
        const start = getStart(order.Triggers);
        const startPrice = getTriggerPrice(start);
        const stepPrice = stepPriceSelector(state, { order: order });
        return parseToFloat(order.Quantity * startPrice, stepPrice);
      },
    },
    {
      name: 'Capital',
      title: <Translate id="orders.capital" />,
      getCellValue: (row) => {
        return parseFloat(row.Pourcentage);
      },
    },
    {
      name: 'USD',
      title: 'USD',
      getCellValue: (row) => {
        const order = row;
        const secPrice = secPriceUSDSelector(state, { order: row });
        const qtyRemaining = isFinishedOrder(order.State)
          ? order.Quantity
          : getQtyRemaining(order);
        return parseToFloat(secPrice * qtyRemaining, 0.01);
      },
    },
    {
      name: 'MaxDrawdown',
      title: 'Max Drawdown',
      getCellValue: (row) => {
        const profit = getProfitFromOrder(row, null);
        if (profit === '-') return 0;
        return parseFloat(profit);
      },
    },
    {
      name: 'Note',
      title: <Translate id="central.note" />,
      getCellValue: (row) => {
        const order = row;
        return !isNull(order) && !isNull(order.Note) ? order.Note : '';
      },
    },
    {
      name: 'Profit',
      title: <Translate id="smartTrade.profit" />,
      getCellValue: (row) => {
        const currentPrice = currentPriceSelector(state, { order: row });
        const profit = getProfitFromOrder(row, currentPrice);
        if (profit === '-') return 0;
        return parseFloat(profit);
      },
    },
    {
      name: 'Gain',
      title: <Translate id="orders.gain" />,
      getCellValue: (row) => {
        const stepPrice = stepPriceSelector(state, { order: row });
        const currentPrice = currentPriceSelector(state, { order: row });
        const profit = getResultFromOrder(row, currentPrice, stepPrice);
        if (profit === '-') return 0;
        return parseFloat(profit);
      },
    },
    {
      name: 'Trader',
      title: <Translate id="orders.trader" />,
      getCellValue: (row) => {
        return row.Group ? row.Group.Name : ' ';
      },
    },
    {
      name: 'LastUpdate',
      title: <Translate id="orders.lastOrder" />,
      codeLang: codeLang,
      getCellValue: (row) => {
        let lastDate = row.DateCreated;
        for (const t of row.Triggers) {
          if (t.DateTrigged && lastDate < t.DateTrigged)
            lastDate = t.DateTrigged;
        }
        return lastDate;
      },
    },
    {
      name: 'DateCreated',
      title: <Translate id="orders.creation" />,
      codeLang: codeLang,
      getCellValue: (row) => {
        return row.DateCreated;
      },
    },
    {
      name: 'Email',
      title: 'Email',
      getCellValue: (row) => {
        return row.ApplicationUser ? row.ApplicationUser.Email : '';
      },
    },
    {
      name: 'State',
      title: 'State',
      getCellValue: (row) => {
        return getStateStr(row.State);
      },
    },
    {
      name: 'States',
      title: 'States',
      getCellValue: (row) => {
        if (row.GroupId === null || row.BaseOrderId !== null) return '';
        return [0, 1, 2, 3, 4, 5, 6, 7, 8].map((prop, key) => {
          return (
            <span
              key={key}
              style={{ color: isActiveOrder(key) ? 'green' : 'red' }}
            >
              {getStateStr(key)[0] +
                ': ' +
                orders.filter(
                  (x) => x.State === key && x.BaseOrderId === row.OrderId,
                ).length +
                ' / '}
            </span>
          );
        });
      },
    },
  ];
  //Elem to remove
  let elemToRemove = [];
  if (!haveLeverage) elemToRemove.push('Leverage');
  if (!isHistoric) elemToRemove.push('PanicSell');
  if (!isAdmin) elemToRemove.push('Email', 'State', 'States');
  if (isTemplate)
    elemToRemove.push(
      'Slider',
      'USD',
      'MaxDrawdown',
      'Profit',
      'Gain',
      'ExitQty',
      'EntryQty',
      'DistNextOrder',
      'StartPrice',
    );
  if (!isTemplate) elemToRemove.push('Disabled');
  return {
    codeLang: codeLang,
    rows: orders,
    columns: columns.filter((x) => !elemToRemove.includes(x.name)),
    columnOrder: columnOrder.filter((x) => !elemToRemove.includes(x)),
    columnWidths: columnWidths.filter(
      (x) => !elemToRemove.includes(x.columnName),
    ),
    totalSummaryItems: totalSummaryItems,
    tableColumnExtensions: tableColumnExtensions,
    filters: filters,
    pageSizes: pageSizes,
    currentPage,
    pageSize,
    sorting: sorting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOrdersActions: bindActionCreators(getOrders, dispatch),
  onSortingChange: (sorting) =>
    dispatch(createOrdersGridAction('sorting', sorting)),
  onSelectionChange: (selection) =>
    dispatch(createOrdersGridAction('selection', selection)),
  onExpandedRowIdsChange: (expandedRowIds) =>
    dispatch(createOrdersGridAction('expandedRowIds', expandedRowIds)),
  onGroupingChange: (grouping) =>
    dispatch(createOrdersGridAction('grouping', grouping)),
  onExpandedGroupsChange: (expandedGroups) =>
    dispatch(createOrdersGridAction('expandedGroups', expandedGroups)),
  onFiltersChange: (filters) =>
    dispatch(createOrdersGridAction('filters', filters)),
  onCurrentPageChange: (currentPage) =>
    dispatch(createOrdersGridAction('currentPage', currentPage)),
  onPageSizeChange: (pageSize) =>
    dispatch(createOrdersGridAction('pageSize', pageSize)),
  onColumnOrderChange: (order) =>
    dispatch(createOrdersGridAction('columnOrder', order)),
  onColumnWidthsChange: (widths) =>
    dispatch(createOrdersGridAction('columnWidths', widths)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableUIOrders);
