import { IconButton, Menu } from '@material-ui/core';
import { MoreVertSharp } from '@material-ui/icons';
import React from 'react';
import { isNull } from '../../helpers/Common';

class MenuButtons extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }
  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };
    const handleClose = () => {
      this.setState({ anchorEl: null });
    };

    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertSharp />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={handleClose}
          MenuListProps={{ onMouseLeave: handleClose }}
        >
          {this.props.children}
        </Menu>
      </div>
    );
  }
}

export default MenuButtons;
