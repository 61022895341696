import React from 'react';

export default class PrivacyPolicyPage extends React.Component {
  render() {
    return (
      <div style={{ textAlign: 'justify' }}>
        <h1>
          <b>Legal notice and privacy policy</b>
        </h1>
        The company Wall Of Traders, concerned about the rights of individuals,
        especially with regard to automated processing and in a desire for
        transparency with its customers, has put in place a policy covering all
        these treatments, the aims pursued by them as well as means of action
        available to individuals so that they can best exercise their rights.
        For any additional information on the protection of personal data, we
        invite you to consult the website: https://www.cnil.fr/
        <br />
        To continue navigation on this site implies unreserved acceptance of the
        following terms and conditions of use.
        <h3>
          <b>Article 1 - Legal notice</b>
        </h3>
        <h4>
          <u>1.1 Site (hereinafter &quot;the site&quot;):</u>
        </h4>
        Wall Of Traders
        <h4>
          <u>1.2 Publisher (hereinafter &apos;the publisher&apos;):</u>
        </h4>
        Wall Of Traders SAS with a capital of 1000 € headquartered at: 15 rue
        des Halles 75001 Paris, registered at RCS Paris, email address:
        contact@walloftraders.com
        <h4>
          <u>1.3 Host (hereinafter &quot;the host&quot;):</u>
        </h4>
        Wall Of Traders is hosted by So you Start OVH, headquartered at 2 rue
        Kellermann 59100 Roubaix.
        <h3>
          <b>Article 2 - Access to the site</b>
        </h3>
        Access to the site and its use are reserved for strictly personal use.
        You agree not to use this site and any information or data contained
        therein for commercial, political, advertising or any other form of
        commercial solicitation and in particular the sending of unsolicited
        emails.
        <h3>
          <b>Article 3 - Content of the site</b>
        </h3>
        All brands, photographs, texts, comments, illustrations, animated or
        non-animated images, video sequences, sounds, as well as all the
        computer applications that could be used to make this site work and more
        generally all the elements reproduced or used on the site are protected
        by the laws in force for intellectual property. They are the full
        property of the publisher or its partners. Any reproduction,
        representation, use or adaptation, in any form whatsoever, of all or
        part of these elements, including computer applications, without the
        prior written consent of the publisher, are strictly prohibited. The
        fact that the publisher does not initiate proceedings upon becoming
        aware of these unauthorized uses does not constitute acceptance of such
        uses and waiver of prosecution.
        <h3>
          <b>Article 4 - Site Management</b>
        </h3>
        For the good management of the site, the publisher can at any time:
        <ul>
          <li>
            suspend, interrupt or limit access to all or part of the site,
            reserve access to the site, or parts of the site, to a specific
            category of Internet users;
          </li>
          <li>
            remove any information that may interfere with its operation or that
            contravenes national or international laws;
          </li>
          <li>suspend the site in order to carry out updates.</li>
        </ul>
        <h3>
          <b>Article 5 - Responsibilities</b>
        </h3>
        The responsibility of the publisher can not be engaged in case of
        failure, breakdown, difficulty or interruption of operation, preventing
        access to the site or one of its functionalities. The connection
        material to the site you use is under your sole responsibility. You must
        take all appropriate measures to protect your equipment and your own
        data, including viral attacks via the Internet. You are also solely
        responsible for the sites and data you visit.
        <br />
        The publisher can not be held responsible in case of legal proceedings
        against you:
        <ul>
          <li>
            because of the use of the site or any service accessible via the
            Internet;
          </li>
          <li>
            because of non-compliance by you with these terms and conditions.
          </li>
        </ul>
        The publisher is not responsible for damage to you, others and / or your
        equipment as a result of your connection or use of the site and you will
        not take any action against it. If the publisher should be the subject
        of an amicable or judicial procedure because of your use of the site, it
        may turn against you to obtain compensation for all damages, sums,
        convictions and costs that may arise from this procedure.
        <h3>
          <b>Article 6 - Hypertext links</b>
        </h3>
        The setting up by the users of all hypertext links towards all or part
        of the site is authorized by the publisher. All links must be removed on
        request of the publisher. Any information accessible via a link to other
        sites is not published by the publisher. The publisher has no rights to
        the content in the link.
        <h3>
          <b>Article 7 - Collection and protection of data</b>
        </h3>
        Your data is collected by Wall Of Traders. Personal data means any
        information relating to an identified or identifiable natural person
        (data subject); an identifiable person is one who can be identified,
        directly or indirectly, in particular by reference to a name, an
        identification number or one or more specific elements specific to his
        physical, physiological, genetic, psychological, economic, cultural or
        social identity. social. The personal information that can be collected
        on the site is mainly used by the publisher to manage relations with
        you, and if necessary for the processing of your orders.
        <br />
        The personal data collected are as follows:
        <ul>
          <li>Last name and first name</li>
          <li>Email address</li>
        </ul>
        <h3>
          <b>
            Article 8 - Right of access, rectification and dereferencing of your
            data
          </b>
        </h3>
        In application of the regulations applicable to personal data, users
        have the following rights:
        <ul>
          <li>
            The right of access: they can exercise their right of access, to
            know the personal data concerning them, by writing to the email
            address mentioned below. In this case, prior to the implementation
            of this right, the Platform may request proof of the identity of the
            user to verify its accuracy;
          </li>
          <li>
            The right of rectification: if the personal data held by the
            Platform are inaccurate, they may request the updating of the
            information;
          </li>
          <li>
            The right to delete data: users may request the deletion of their
            personal data, in accordance with applicable data protection laws;
          </li>
          <li>
            The right to limit processing: users may request the Platform to
            limit the processing of personal data in accordance with the
            assumptions provided by the GDPR;
          </li>
          <li>
            The right to oppose data processing: users may object to their data
            being processed in accordance with the assumptions set out in the
            GDPR;
          </li>
          <li>
            The right to portability: they can claim that the Platform gives
            them the personal data they have provided to transmit them to a new
            Platform.
          </li>
        </ul>
        You can exercise this right by contacting us by sending an email to:
        contact@walloftraders.com.
        <br />
        All requests must be accompanied by a photocopy of a valid ID card and
        include the address at which the publisher may contact the applicant.
        The reply will be sent within one month of receiving the request. This
        one-month period may be extended by two months if the complexity of the
        request and / or the number of requests so require.
        <br />
        Moreover, and since the law n ° 2016-1321 of October 7th, 2016, the
        people who wish it, have the possibility to organize the fate of their
        data after their death. For more information on the subject, you can
        consult the website of the CNIL: https://www.cnil.fr/.
        <br />
        Users can also file a complaint with the CNIL on the CNIL website:
        https://www.cnil.fr.
        <br />
        We recommend that you contact us first before filing a complaint with
        the CNIL, because we are at your disposal to solve your problem.
        <br />
        <h3>
          <b>Article 9 - Use of data</b>
        </h3>
        The personal data collected from the users aims at providing the
        services of the Platform, improving them and maintaining a secure
        environment. The legal basis of the processing is the execution of the
        contract between the user and the Platform. Specifically, the uses are:
        <ul>
          <li>access and use of the Platform by the user;</li>
          <li>operation management and optimization of the Platform;</li>
          <li>implementation of user assistance;</li>
          <li>
            verification, identification and authentication of the data
            transmitted by the user;
          </li>
          <li>
            customization of services by displaying advertisements based on the
            user&apos;s browsing history, according to his preferences;
          </li>
          <li>
            prevention and detection of fraud, malware (malicious softwares) and
            security incident management;
          </li>
          <li>management of any disputes with users;</li>
          <li>
            sending commercial and advertising information, according to the
            preferences of the user;
          </li>
          <li>
            organization of the conditions of use of the Payment Services.
          </li>
        </ul>
        <h3>
          <b>Article 10 - Data retention policy</b>
        </h3>
        The Platform keeps your data for as long as necessary to provide you
        with its services or assistance. To the extent reasonably necessary or
        required to fulfill legal or regulatory obligations, to resolve
        disputes, prevent fraud and abuse, or to enforce our terms and
        conditions, we may also retain some of your information if necessary,
        even after you have closed your account. or that we no longer need to
        provide you with our services.
        <br />
        <h3>
          <b>Article 11 - Sharing personal data with third parties</b>
        </h3>
        Personal data may be shared with third-party companies exclusively in
        the European Union, in the following cases:
        <ul>
          <li>
            when the user uses the payment services, for the implementation of
            these services, the Platform is in contact with third-party banking
            and financial companies with which it has contracted;
          </li>
          <li>
            when the user publishes publicly available information in the open
            comment areas of the Platform;
          </li>
          <li>
            when the user authorizes the website of a third party to access his
            data;
          </li>
          <li>
            when the Platform uses provider services to provide user support,
            advertising and payment services. These service providers have
            limited access to the user&apos;s data, in connection with the
            performance of these services, and have the contractual obligation
            to use them in accordance with the provisions of the applicable data
            protection regulations. personal character;
          </li>
          <li>
            if required by law, the Platform may transmit data to respond to
            claims against the Platform and comply with administrative and
            judicial procedures.
          </li>
          <li>
            Trading View&apos;s Privacy Policy is not related to this Privacy
            Policy.
          </li>
        </ul>
        <h3>
          <b>Article 12 - Commercial offers</b>
        </h3>
        You are likely to receive commercial offers from the publisher. If you
        do not wish, please send an email to: contact@walloftraders.com. Your
        data may be used by the publisher&apos;s partners for marketing
        purposes, if you do not wish, please send an email to:
        contact@walloftraders.com. If, during the consultation of the site, you
        access personal data, you must refrain from any collection, any
        unauthorized use and any act that may constitute an invasion of the
        privacy or reputation of individuals. The publisher declines all
        responsibility in this regard. The data are stored and used for a period
        in accordance with the legislation in force.
        <br />
        <h3>
          <b>Article 13 - Cookies</b>
        </h3>
        What is a “cookie&quot; ? A &quot;Cookie&quot; or plotter is an
        electronic file deposited on a terminal (computer, tablet, smartphone,
        ...) and read for example during the consultation of a website, the
        reading of an email, the installation or the use of a software or a
        mobile application and this, whatever the type of terminal used (source:
        https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi). By browsing
        this site, &quot;cookies&quot; from the company responsible for the site
        and / or third-party companies may be deposited on your device. During
        the first navigation on this site, an explanatory banner on the use of
        &quot;cookies&quot; will appear. Therefore, by continuing the
        navigation, the customer and / or prospect will be deemed informed and
        have accepted the use of said &quot;cookies&quot;. The consent given
        will be valid for a period of thirteen (13) months. The user has the
        option to disable cookies from his browser settings.
        <br />
        All information collected will be used only to track the volume, type
        and configuration of traffic using this site, to develop the design and
        layout and for other administrative and planning purposes and more
        generally to improve the service. that we offer you.
        <br />
        The following cookies are present on this site:
        <br />
        <br />
        <b>Google cookies:</b>
        <ul>
          <li>Google analytics: to measure the audience of the site;</li>
          <li>
            Google tag manager: facilitates the implementation of tags on pages
            and allows you to manage Google tags;
          </li>
          <li>
            Google Adsense: Google&apos;s advertising agency using YouTube
            websites or videos as support for its ads;
          </li>
          <li>
            Google Dynamic Remarketing: allows you to offer dynamic advertising
            based on previous research;
          </li>
          <li>
            Google Adwords Conversion: tool for tracking adwords advertising
            campaigns;
          </li>
          <li>
            DoubleClick: Google advertising cookies to distribute banners.
          </li>
        </ul>
        <b>Facebook cookies:</b>
        <ul>
          <li>
            Facebook connect: allows you to identify yourself using your
            Facebook account;
          </li>
          <li>
            Social Facebook plugins: allows to like, share, comment content with
            a Facebook account;
          </li>
          <li>
            Facebook Custom Audience: allows to interact with the audience on
            Facebook.
          </li>
        </ul>
        <b>Twitter Cookies:</b>
        <ul>
          <li>
            Twitter button: makes it easy to share and view Twitter content;
          </li>
          <li>
            Twitter advertising: allows to display and target advertisements by
            the advertising agency of Twitter.
          </li>
        </ul>
        The lifetime of these cookies is thirteen months.
        <br />
        For more information on the use, management and removal of
        &quot;cookies&quot;, for any type of browser, we invite you to consult
        the following link:
        https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser.
        <br />
        <h3>
          <b>Article 14 - Photographs and representation of products</b>
        </h3>
        The photographs of products, accompanying their description, are not
        contractual and do not engage the publisher.
        <br />
        <h3>
          <b>Article 15 - Applicable law</b>
        </h3>
        The present conditions of use of the site are governed by the French law
        and subject to the jurisdiction of the courts of the head office of the
        publisher, subject to a attribution of specific competence stemming from
        a text of particular law or regulation.
        <br />
        <h3>
          <b>Article 16 - Language</b>
        </h3>
        In case of translation of this privacy policy into other languages, the
        language of interpretation will be the French language.
        <br />
        <h3>
          <b>Article 17 - Contact us</b>
        </h3>
        For any questions, information about the products presented on the site,
        or about the site itself, you can send an email to:
        contact@walloftraders.com.
      </div>
    );
  }
}
