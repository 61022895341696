import React from 'react';
import { connect } from 'react-redux';
import {
  Field,
  formValueSelector,
  change,
  touch,
  getFormSyncErrors,
} from 'redux-form';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { bindActionCreators } from 'redux';
import {
  getPriceFromProfit,
  getProfitFromPrice,
  getPourcentageSLNetV2,
  isEmpty,
  parseToFloat,
  getPourcentageNet,
  capitalSelector,
  isNull,
  stepPriceSelector,
} from '../../../helpers/Common';
import { Translate } from 'react-localize-redux';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import SwitchUI from '@material-ui/core/Switch/Switch';
import TrendingDown from '@material-ui/icons/TrendingDown';
import Button from '../../../components/CustomButtons/Button.jsx';
import Helper from '../../../components/Hover/Helper';
import { SmallCheckBox } from '../../../components/Fields/SmallCheckBox';
import LabelCheckbox from '../../../components/Form/LabelCheckbox';
import { abs, getMoveSLOptions } from '../../../helpers/order';
import ReactSelect from '../../../components/Form/ReactSelectUI';
import TriggerLinesContainer from './TriggerLinesContainer';
import { dangerColor } from '../../../assets/jss/material-dashboard-react';
import { withWidth } from '@material-ui/core';
import { nbColumn } from '../../../helpers/general.js';

class StopLossContainer extends React.PureComponent {
  changeAndTouch = (formname, name, value) => {
    const { change, touch } = this.props;
    change(formname, name, value);
    touch(formname, name);
  };

  componentDidUpdate = (prevProps) => {
    const { formName, stops, startAveragePrice, isBuy, stepPrice } = this.props;
    const { stops: prevStops } = prevProps;
    if (isNull(stops) || isNull(prevStops)) return;

    for (let i = 0; i < stops.length; i++) {
      const actStop = stops[i];
      const prevStop = prevStops[i];

      if (isEmpty(actStop) || isEmpty(prevStop) || isEmpty(startAveragePrice))
        continue;

      const nPrice = parseFloat(
        getPriceFromProfit(actStop.profit, isBuy, startAveragePrice),
      );
      const newPrice = !isEmpty(nPrice)
        ? parseToFloat(nPrice, stepPrice)
        : null;
      const nProfit = parseFloat(
        getProfitFromPrice(actStop.price, isBuy, startAveragePrice),
      );
      const newProfit = !isEmpty(nProfit) ? parseToFloat(nProfit, 0.01) : null;
      if (
        isBuy !== prevProps.isBuy ||
        startAveragePrice !== prevProps.startAveragePrice
      ) {
        if (actStop.lastUpdate === 'profit') {
          this.changeAndTouch(
            formName,
            `stops.${i}.price`,
            newPrice === 0 ? undefined : newPrice,
          );
        } else if (actStop.lastUpdate === 'price') {
          this.changeAndTouch(
            formName,
            `stops.${i}.profit`,
            newProfit === 0 ? undefined : newProfit,
          );
        }
      } else if (
        actStop.profit !== prevStop.profit &&
        newProfit !== actStop.profit
      ) {
        this.changeAndTouch(
          formName,
          `stops.${i}.price`,
          newPrice === 0 ? undefined : newPrice,
        );
      } else if (
        actStop.price !== prevStop.price &&
        newPrice !== actStop.price
      ) {
        this.changeAndTouch(
          formName,
          `stops.${i}.profit`,
          newProfit === 0 ? undefined : newProfit,
        );
      }

      const nPriceTrailing = parseFloat(
        getPriceFromProfit(actStop.trailing, isBuy, actStop.price),
      );
      const newPriceTrailing = !isEmpty(nPriceTrailing)
        ? parseToFloat(nPriceTrailing, stepPrice)
        : null;
      const nProfitTrailing = parseFloat(
        getProfitFromPrice(actStop.trailingPrice, isBuy, actStop.price),
      );
      const newProfitTrailing = !isEmpty(nProfitTrailing)
        ? parseToFloat(nProfitTrailing, 0.01)
        : null;
      if (
        actStop.price !== prevStop.price ||
        (actStop.trailing !== prevStop.trailing &&
          newProfitTrailing !== actStop.trailing)
      ) {
        this.changeAndTouch(
          formName,
          `stops.${i}.trailingPrice`,
          newPriceTrailing === 0 ? undefined : newPriceTrailing,
        );
      } else if (
        actStop.trailingPrice !== prevStop.trailingPrice &&
        newPriceTrailing !== actStop.trailingPrice
      ) {
        this.changeAndTouch(
          formName,
          `stops.${i}.trailing`,
          newProfitTrailing === 0 ? undefined : newProfitTrailing,
        );
      }
    }
  };

  toggleLock = () => {
    const { formName, change, stopsLock, touch } = this.props;
    change(formName, 'stopsLock', !stopsLock);
    touch(formName, 'stopsLock');
  };

  // setProfitOnTrailingChange = (newValue) => {
  //   const {
  //     isBuyDone,
  //     formName,
  //     currentPrice,
  //     isBuy,
  //     startAveragePrice,
  //     change,
  //     touch,
  //   } = this.props;

  //   const val = !isBuyDone
  //     ? newValue
  //     : parseToFloat(
  //       getProfitFromPrice(currentPrice, isBuy, startAveragePrice) - -1 * newValue,
  //       0.01,
  //     );
  //   change(formName, 'stopProfit', val);
  //   touch(formName, 'stopProfit');
  // };

  render() {
    const {
      formName,
      stopsLock,
      percentageSLNet,
      percentageTPNet,
      partCapital,
      capital,
      leverage,
      moveSL,
      moveSLCB,
      syncErrors,
      isUpdate,
      isSC,
      localize,
    } = this.props;
    const optionsMoveSl = getMoveSLOptions(localize);
    if (moveSL === undefined)
      this.props.change(formName, 'moveSL', optionsMoveSl[0]);
    const errorsList =
      syncErrors && syncErrors.stops ? syncErrors.stops.split('\n') : [];

    if (isSC && stopsLock) {
      this.props.change(formName, 'stopsLock', false);
      this.props.touch(formName, 'stopsLock');
    }

    const isMobile = nbColumn(this.props.width) < 2;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card className="smart-trade-bloc">
          <CardBody>
            <fieldset
              disabled={stopsLock}
              className={stopsLock ? 'disabled' : ''}
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Translate id="smartTrade.setStopLoss" />
                  <SwitchUI
                    checked={!stopsLock}
                    onChange={() => this.toggleLock()}
                    color="primary"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TriggerLinesContainer name="stops" formName={formName} />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ textAlign: 'right' }}>
                    <Translate id="smartTrade.netPercentage" /> :{' '}
                    {percentageSLNet}%
                  </div>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <div style={{ float: 'left', margin: '0.5em 0px 20px 0px' }}>
                    <div style={{ float: 'right' }}>
                      &nbsp;
                      <Helper
                        text={<Translate id="smartTrade.moveSL_help" />}
                      />
                    </div>
                    <Field
                      name="moveSLCB"
                      id="moveSLCB"
                      component={SmallCheckBox}
                      onChange={() => {
                        if (moveSLCB === false) {
                          // this.setProfitOnTrailingChange(trailingStopLoss);
                        }
                      }}
                    />
                    <LabelCheckbox htmlFor="moveSLCB">
                      <span>
                        <Translate id="smartTrade.moveSL_checkbox" />
                      </span>
                    </LabelCheckbox>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  {moveSLCB && (
                    <Field
                      name="moveSL"
                      label={<Translate id="smartTrade.moveSL_selectTitle" />}
                      options={optionsMoveSl}
                      fullWidth={true}
                      component={ReactSelect}
                      required={true}
                      value={moveSL}
                      onChange={(event, newValue) => {
                        this.props.change(formName, 'moveSL', newValue);
                      }}
                    />
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  {/*//TODO:(percentageSLNet*(100-%TPDone) + percentageTPNet*%TPDone)*/}
                  <div style={{ textAlign: 'right' }}>
                    <Translate id="smartTrade.maxCapitalLoss" /> :{' '}
                    {parseToFloat(
                      (percentageSLNet * partCapital) / leverage / 100,
                      0.01,
                    )}
                    % ({' '}
                    {parseToFloat(
                      (capital * percentageSLNet * partCapital) /
                        leverage /
                        10000,
                      0.01,
                    )}{' '}
                    $ )<br />
                    <Translate id="smartTrade.rewardRisk" /> :{' '}
                    {parseToFloat(percentageTPNet / abs(percentageSLNet), 0.01)}
                    :1
                  </div>
                </GridItem>
              </GridContainer>
            </fieldset>
            {!isEmpty(errorsList) && (
              <>
                {errorsList.map((error, i) => (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    key={i}
                    style={{ color: dangerColor }}
                  >
                    {error}
                  </GridItem>
                ))}
              </>
            )}
          </CardBody>
          {stopsLock && (
            <div className="active-block">
              <Button
                color="primary"
                style={{ width: '200px' }}
                onClick={() => this.toggleLock()}
              >
                <TrendingDown style={{ margin: '4px' }} />
                <Translate id="smartTrade.setStopLoss" />
              </Button>
            </div>
          )}
        </Card>
        {/*<PanicSellContainer formName={formName} />*/}
        {isMobile && (
          <><br/><br/><br/><br/></>
        )}
      </GridItem>
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  const groupId = formSelector(state, 'groupId');
  return {
    isSC: [11586].includes(groupId?.value),
    stopsLock: formSelector(state, 'stopsLock'),
    percentageSLNet: getPourcentageSLNetV2(state),
    percentageTPNet: getPourcentageNet(state),
    partCapital: formSelector(state, 'pourcentage'),
    capital: capitalSelector(state, props),
    leverage: formSelector(state, 'leverage'),
    moveSL: formSelector(state, 'moveSL'),
    moveSLCB: formSelector(state, 'moveSLCB'),
    syncErrors: getFormSyncErrors(props.formName)(state),
    isUpdate: formSelector(state, 'isUpdate'),
    stops: formSelector(state, 'stops'),
    startAveragePrice: formSelector(state, 'startAveragePrice'),
    isBuy: formSelector(state, 'isBuy'),
    stepPrice: stepPriceSelector(state, props),
    localize: state.localize,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    touch: bindActionCreators(touch, dispatch),
  };
};

export default withWidth()(connect(mapStateToProps, mapDispatchToProps)(StopLossContainer));
