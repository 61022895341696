import React from 'react';
import { getTableExchange, KeyExchangeTable } from '../../helpers/exchange';
import PieChart, { getColor } from './PieChart';

const PieChartExchange = ({ exchange }) => {
  const exchangeToPie = () => {
    const table = getTableExchange(exchange);
    let r = [];
    for (const t of table) {
      if (t[KeyExchangeTable.POURCENTAGE.K] > 0) {
        r.push({
          name: t[KeyExchangeTable.TOKEN.K],
          y: parseFloat(t[KeyExchangeTable.POURCENTAGE.K]),
          color: getColor(t[KeyExchangeTable.TOKEN.K]),
          amount: t[KeyExchangeTable.AMOUNT.K],
          value: t[KeyExchangeTable.TOTAL_USD.K],
        });
      }
    }
    return r;
  };

  return <PieChart data={exchangeToPie()} />;
};

export default PieChartExchange;
