import React from 'react';
import { connect } from 'react-redux';

import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';

import { bindActionCreators } from 'redux';
import { postInscriptionGroups, IsAdmin } from '../../../redux/admin/actions';
import Input from '@material-ui/core/Input';
import Button from '../../../components/CustomButtons/Button';

class InscriptionGroupsPage extends React.Component {
  state = {
    value: '',
  };
  componentDidMount() {
    this.props.IsAdminActions();
    // this.props.getSubscriptionGroupActions();
  }
  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };
  postSub = () => {
    this.props.postInscriptionGroupsActions({ data: this.state.value });
  };
  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <span>
            Rappel: Trader ID(1133) \t Username \t Exchange \t API key \t API
            Secret \t Capital (USDT) \t % of Capital \t IsFullAuto \t End date
            of subcription(jj/mm/aaaa) \t Language
          </span>
          <Input
            value={this.state.value}
            multiline={true}
            fullWidth={true}
            minRows={25}
            onChange={(e) => this.handleChange(e)}
          />
          <Button onClick={() => this.postSub()}>Envoyer</Button>
        </GridItem>
        {/*<GridItem xs={12} sm={12} md={12}>*/}
        {/*  <TableSubGroups subsGroups={this.props.subsGroups} />*/}
        {/*</GridItem>*/}
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    subsGroups: admin.subscriptionsGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postInscriptionGroupsActions: bindActionCreators(
      postInscriptionGroups,
      dispatch,
    ),
    // getSubscriptionGroupActions: bindActionCreators(
    //   getSubscriptionGroup,
    //   dispatch
    // ),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InscriptionGroupsPage);
