import React from 'react';
import Button from '../../../components/CustomButtons/Button.jsx';

const styles = {
  buttonLeft: {
    width: '49%',
    marginLeft: '0px',
    marginRight: '0px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  },
  buttonRight: {
    width: '49%',
    marginLeft: '0px',
    marginRight: '0px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
};

class ButtonsOrderPosition extends React.PureComponent {
  render() {
    const { onChange, showPositions, positionCount } = this.props;
    return (
      <div>
        <Button
          style={styles.buttonLeft}
          color={!showPositions ? 'primary' : 'default'}
          size={'sm'}
          onClick={() => {
            if (showPositions !== false) onChange(false);
          }}
        >
          <b>Balances</b>
        </Button>
        <Button
          style={styles.buttonRight}
          color={showPositions ? 'primary' : 'default'}
          size={'sm'}
          onClick={() => {
            if (showPositions !== true) onChange(true);
          }}
        >
          <b>Positions ({positionCount})</b>
        </Button>
      </div>
    );
  }
}

export default ButtonsOrderPosition;
