import {
  goldColor,
  primaryColor,
  successColor,
} from '../../material-dashboard-react';

const linkStyle = (theme) => ({
  link: {
    color: theme.palette.type === 'dark' ? goldColor : successColor,
    textDecoration: 'none',
    '&:hover,&:focus,&:visited': {
      textDecoration: 'underline',
    },
    '&:hover': {
      color: theme.palette.type === 'dark' ? goldColor : successColor,
    },
  },
  networkLink: {
    color: primaryColor,
    '&:hover': {
      opacity: '80%',
    },
  },
});

export { linkStyle };
