export const getStepSelector = (state) => {
  const { onboarding } = state;
  return onboarding.step;
};
export const getProfilUserSelector = (state) => {
  const { onboarding } = state;
  return onboarding.profilUser;
};

export const getGroupId = (state) => {
  const { onboarding } = state;
  return onboarding.groupId;
};

export const getGroupName = (state) => {
  const { onboarding } = state;
  return onboarding.groupName;
};

export const getGroupLink = (state) => {
  const { onboarding } = state;
  return onboarding.groupLink;
};

export const getShowDialog = (state) => {
  const { onboarding } = state;
  return onboarding.showDialog;
};
