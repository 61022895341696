import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { clearAlert } from '../../redux/alert/alert.actions';

import AlertNotification from './AlertNotification';

import 'perfect-scrollbar/css/perfect-scrollbar.css';
import withStyles from '@material-ui/core/styles/withStyles';
import Header from '../../components/Header/Header.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import Sidebar from '../../components/Sidebar/Sidebar.jsx';

import { getDashboardRoutes } from '../../routes/dashboard.jsx';
import { withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';

import transMenu from '../../locales/menu.json';
import transCentral from '../../locales/central.json';
import {
  getActiveCode,
  getTabLanguages,
  setActiveCode,
} from '../../helpers/localize';

import dashboardStyle from '../../assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx';
import {
  darkColor,
  darkLightColor,
  lightColor,
  lightDarkColor,
} from '../../assets/jss/material-dashboard-react';

import logo from '../../assets/img/logo/TaureauArrondiRV_Rase.png';

import { history } from '../../store';

import { bindActionCreators } from 'redux';
import { getAvailableAmount, getOrders } from '../../redux/order/actions';
import { getExchanges } from '../../redux/user/actions';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import { getDefaultLanguage } from '../../helpers/localize';
import InitSteppers from '../../components/Steppers/InitSteppers';
import { isLogged, showInitSteppers } from '../../helpers/general';
import { getTraders } from '../../redux/trader/actions';
import { collapseMenu } from '../../redux/config/actions';
import Alert from './Alert';
import OnboardingDialog from '../../views/Onboarding/OnboardingDialog';
import DisconnectAs from '../../components/Sidebar/DisconnectAs';
import { isEmpty } from '../../helpers/Common';
import {
  changeShowDialog,
  getOnboarding,
} from '../../redux/onboarding/actions';
// library.add(fab);
// import { whyDidYouUpdate } from 'why-did-you-update';
//
// whyDidYouUpdate(React);

const getSwitchRoutes = (auth, getActiveCode) => {
  return (
    <Switch>
      {getDashboardRoutes(auth, getActiveCode).map((prop, key) => {
        if (prop.redirect)
          return <Redirect from={prop.path} to={prop.to} key={key} />;
        if (prop.collapse) {
          return prop.collapse.map((prop, key) => {
            return (
              <Route path={prop.path} component={prop.component} key={key} />
            );
          });
        }
        if (prop.component)
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
        return null;
      })}
    </Switch>
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);
    const generalTranslation = Object.assign(transMenu, transCentral);
    this.props.initialize({
      languages: getTabLanguages(),
      translation: generalTranslation,
      options: { renderToStaticMarkup, defaultLanguage: getDefaultLanguage() },
    });

    const { clearAlertActions } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      clearAlertActions();
    });
    this.state = {
      mobileOpen: false,
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  getTheme = () => {
    const { theme } = this.props;
    return createTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MuiStepper: {
          root: {
            backgroundColor: theme === 'dark' ? darkColor : lightColor,
            borderRadius: '6px',
          },
        },
      },
      palette: {
        // primary: {
        //   main: primaryColor
        // },
        type: theme,
        background: {
          paper: theme === 'dark' ? darkLightColor : lightDarkColor,
          default: theme === 'dark' ? darkLightColor : lightDarkColor,
        },
      },
    });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname; // !== "/maps";
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  componentDidMount() {
    const { auth, location, setActiveLanguage } = this.props;
    const isSmartTrade =
      location?.pathname?.indexOf('smartTrade') > -1 ?? false;
    const isAccounts = location?.pathname?.startsWith('/accounts') ?? false;
    const isDashboard = location?.pathname?.startsWith('/dashboard') ?? false;
    const isTrader = location?.pathname?.startsWith('/traders') ?? false;

    if (isLogged(auth) && !isSmartTrade) {
      if (!isAccounts && !isDashboard) this.props.getExchangesActions();
      this.props.getAvailableAmountActions();
      if (!isTrader) this.props.getTradersActions();
      if (showInitSteppers()) {
        this.props.getOrdersActions(0); //All
      }
    }
    //Change langue
    if (location && location.search) {
      const index = location.search.indexOf('lang=');
      if (index !== -1) {
        const lang = location.search.substr(index + 5, 2);
        setActiveLanguage(lang);
        setActiveCode(lang);
      }
    }
    // if (navigator.platform.indexOf('Win') > -1) {
    //   const ps = new PerfectScrollbar(this.refs.mainPanel);
    // }
    window.addEventListener('resize', this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      // this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFunction);
  }
  render() {
    const { showOnboardingDialog, auth, classes, getActiveCode, ...rest } =
      this.props;

    const isAuthAs = !isEmpty(localStorage.getItem('AuthAs'));

    const showOnboarding = getOnboarding();
    if (showOnboarding) this.props.changeShowDialogActions(showOnboarding);

    return (
      <MuiThemeProvider theme={this.getTheme()}>
        <CssBaseline />
        <div className={classes.wrapper}>
          {/*<TelegramButton />*/}
          <Sidebar
            routes={getDashboardRoutes(auth, getActiveCode)}
            logoText={'Wall Of Traders'}
            logo={logo}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            color="blue"
            {...rest}
          />
          <div className={classes.mainPanel}>
            {isAuthAs && <DisconnectAs />}
            <Header
              routes={getDashboardRoutes(auth, getActiveCode)}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />
            {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
            {/* {this.getRoute() ? ( */}
            <div className={classes.content}>
              {showInitSteppers() && isLogged(auth) && <InitSteppers />}
              <div className={classes.container}>
                {getSwitchRoutes(auth, getActiveCode)}
              </div>
            </div>
            <AlertNotification />
            <Alert />
            {/* ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}*/}
            {showOnboardingDialog && <OnboardingDialog />}
            {this.getRoute() ? <Footer /> : null}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

// App.propTypes = {
//   classes: PropTypes.object.isRequired
// };
function mapStateToProps(state) {
  const { authentication, config, onboarding, user } = state;
  return {
    auth: authentication,
    theme: config.theme,
    collapseMenu: config.collapseMenu,
    getActiveCode: getActiveCode(state),
    exchanges: user.exchanges, //Pour afficher l'onboarding
    showOnboardingDialog: onboarding.showDialog,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeShowDialogActions: bindActionCreators(changeShowDialog, dispatch),
    collapseMenuActions: bindActionCreators(collapseMenu, dispatch),
    getAvailableAmountActions: bindActionCreators(getAvailableAmount, dispatch),
    getExchangesActions: bindActionCreators(getExchanges, dispatch),
    getTradersActions: bindActionCreators(getTraders, dispatch),
    getOrdersActions: bindActionCreators(getOrders, dispatch),
    clearAlertActions: bindActionCreators(clearAlert, dispatch),
  };
};

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
// export { connectedApp as App };
export default withLocalize(withStyles(dashboardStyle)(connectedApp));

// export default withStyles(dashboardStyle)(App);
