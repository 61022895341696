import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedSummary,
  PagingState,
  SortingState,
  SummaryState,
  TreeDataState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccountBox, DeleteForever, Email, Info } from '@material-ui/icons';
import React from 'react';
import { Translate } from 'react-localize-redux';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import Card from '../../../components/Card/Card.jsx';
import MenuButtons from '../../../components/CustomButtons/MenuButtons.jsx';
import { connectAs } from '../../../helpers/general';
import { getActiveCode } from '../../../helpers/localize';
import GetMenuItem from '../../../helpers/menuItems';
import {
  getColumnTabStats,
  getDefaultColumnWidthsTabStats,
} from '../../../helpers/order';
import {
  ColorPourcentageProvider,
  CrossedEmailProvider,
} from '../../../helpers/table';
import { remove2FA, confirmEmailAdmin } from '../../../redux/admin/actions';
import { deleteUser } from '../../../redux/user/actions';
import { adminUrl } from '../../../routes/dashboard';
import { isNull, parseToFloat } from '../../../helpers/Common';

class TableUser extends React.PureComponent {
  constructor(props) {
    super(props);
    const getButtons = (row) => {
      return (
        <span>
          {!row['EmailConfirm'] && (
            <GetMenuItem
              icon={<Email />}
              onClick={() => {
                this.props.confirmEmailActions(row['Email']);
              }}
              text={<Translate id="central.confirmEmailAdmin" />}
            />
          )}
          <GetMenuItem
            icon={<AccountBox />}
            onClick={() => {
              connectAs(row['Id'], row['Email']);
            }}
            text={<Translate id="central.connectAs" />}
          />
          <GetMenuItem
            icon={<Info />}
            to={adminUrl + '/user/' + row['Id'] + '/orders'}
            text={<Translate id="central.showUserOrders" />}
          />
          {row['HasTFA'] && (
            <GetMenuItem
              icon={<FontAwesomeIcon icon={faQrcode} size="lg" />}
              onClick={() => {
                if (window.confirm(<Translate id="central.remove2FA" /> + '?'))
                  this.props.remove2FAActions(row['Id']);
              }}
              text={<Translate id="central.remove2FA" />}
            />
          )}
          <GetMenuItem
            icon={<DeleteForever />}
            onClick={() => {
              if (window.confirm(<Translate id="central.deleteUser" /> + '?'))
                this.props.deleteUserActions(row['Id']);
            }}
            text={<Translate id="central.deleteUser" />}
          />
        </span>
      );
    };

    let columns = [
      {
        name: 'B',
        title: 'B',
        getCellValue: (row) => <MenuButtons>{getButtons(row)}</MenuButtons>,
      },
      {
        name: 'Email',
        title: 'Email',
      },
      {
        name: 'TelegramConfirm',
        title: 'TelegramConfirm',
        getCellValue: (row) =>
          row['TelegramConfirm'] ? row['Telegram'] : 'false',
      },
      {
        name: 'EmailConfirm',
        title: 'EmailConfirm',
        getCellValue: (row) => (row['EmailConfirm'] ? 'true' : 'false'),
      },
      {
        name: 'DateWot',
        title: 'DateWot',
      },
      {
        name: 'Abos',
        title: 'Abos',
      },
      {
        name: 'DateCreated',
        title: 'DateCreated',
      },
      {
        name: 'BinanceRef',
        title: 'BinanceRef',
        getCellValue: (row) => row['Rebate'],
      },
      {
        name: 'CapitalBinance',
        title: 'Binance Capital',
        getCellValue: (row) => parseToFloat(row['CapitalBinance'], 0.1),
      },
      {
        name: 'CapitalBinanceFutures',
        title: 'Futures Binance Capital',
        getCellValue: (row) => parseToFloat(row['CapitalBinanceFutures'], 0.1),
      },
      {
        name: 'TotalFeesBinance',
        title: 'Fees Binance Total',
        getCellValue: (row) => parseToFloat(row['TotalFeesBinance'], 0.01),
      },
      {
        name: 'TotalFeesBinanceFutures',
        title: 'Fees Futures Binance Total',
        getCellValue: (row) =>
          parseToFloat(row['TotalFeesBinanceFutures'], 0.01),
      },
      {
        name: 'TotalPaiement',
        title: 'Paiement WoT Total',
        getCellValue: (row) => parseToFloat(row['TotalPaiement'], 0.1),
      },
      {
        name: 'Total',
        title: 'Total $',
      },
      {
        name: 'NbPositions',
        title: 'NbPositions',
      },
      {
        name: 'NbAccount',
        title: 'Accounts',
      },
      {
        name: 'NbOrder',
        title: 'Orders',
      },
      {
        name: 'LastDateOrder',
        title: 'LastDateOrder',
      },
      {
        name: 'Lang',
        title: 'Lang',
      },
      {
        name: 'Newsletter',
        title: 'Newsletter',
        getCellValue: (row) => (row['Newsletter'] ? 'true' : 'false'),
      },
      {
        name: 'HasTFA',
        title: 'HasTFA',
        getCellValue: (row) => (row['HasTFA'] ? 'true' : 'false'),
      },
    ];

    let defaultColumnWidths = [
      { columnName: 'B', width: 110 },
      { columnName: 'Email', width: 250 },
      { columnName: 'NbOrder', width: 75 },
      { columnName: 'NbAccount', width: 75 },
      { columnName: 'NbPositions', width: 75 },
      { columnName: 'BinanceRef', width: 75 },
      { columnName: 'CapitalBinance', width: 75 },
      { columnName: 'CapitalBinanceFutures', width: 75 },
      { columnName: 'TotalPaiement', width: 75 },
      { columnName: 'TotalFeesBinance', width: 75 },
      { columnName: 'TotalFeesBinanceFutures', width: 75 },
      { columnName: 'Total', width: 75 },
      { columnName: 'TelegramConfirm', width: 80 },
      { columnName: 'EmailConfirm', width: 80 },
      { columnName: 'DateWot', width: 100 },
      { columnName: 'Abos', width: 80 },
      { columnName: 'DateCreated', width: 100 },
      { columnName: 'LastDateOrder', width: 100 },
      { columnName: 'Lang', width: 50 },
      { columnName: 'Newsletter', width: 95 },
      { columnName: 'HasTFA', width: 95 },
    ];

    this.state = {
      //table
      columns: columns,
      id: 1,
      rows: props.users,
      pageSizes: [5, 10, 20, 50, 0],
      colorColumns: [
        'ProfitTotal',
        'ProfitCapital',
        'PourcentageSuccess',
        'ProfitPerTrade',
        'ProfitPerTradeCapital',
      ],
      defaultColumnWidths: defaultColumnWidths,
      columnWidths: defaultColumnWidths,
      filters: [],
    };

    this.changeFilters = (filters) => this.setState({ filters });
  }

  componentDidUpdate = () => {
    const users = this.props.users;
    this.setState({ rows: users });
  };

  onColumnWidthsChange = (widths) => {
    this.setState({ columnWidths: widths });
  };

  render() {
    const {
      rows,
      columns,
      defaultColumnWidths,
      columnWidths,
      filters,
      colorColumns,
    } = this.state;

    let newColumns = columns;
    let newDefaultColumnWidths = columnWidths;

    const withStats = rows.length > 0 && !isNull(rows[0].Stats);
    const containsStatsAlready =
      columns.filter((x) => x.name === 'NbSignalsWeek').length > 0;
    if (withStats && !containsStatsAlready) {
      newColumns = columns.concat(getColumnTabStats(true));
      newDefaultColumnWidths = defaultColumnWidths.concat(
        getDefaultColumnWidthsTabStats(),
      );
    }

    return (
      <Card>
        <Grid rows={rows} columns={newColumns}>
          <FilteringState
            filters={filters}
            onFiltersChange={this.changeFilters}
          />
          <SortingState
            defaultSorting={[{ columnName: 'DateCreated', direction: 'desc' }]}
          />
          <PagingState defaultCurrentPage={0} defaultPageSize={20} />
          <SummaryState
            totalItems={[
              { columnName: 'CapitalBinance', type: 'sum' },
              { columnName: 'CapitalBinanceFutures', type: 'sum' },
              { columnName: 'TotalPaiement', type: 'sum' },
              { columnName: 'TotalFeesBinance', type: 'sum' },
              { columnName: 'TotalFeesBinanceFutures', type: 'sum' },
              { columnName: 'Total', type: 'sum' },
            ]}
          />
          <TreeDataState />

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          <IntegratedSummary />

          <ColorPourcentageProvider for={colorColumns} />
          <CrossedEmailProvider for={['Email']} />

          <Table />
          <TableColumnResizing
            defaultColumnWidths={defaultColumnWidths}
            columnWidths={newDefaultColumnWidths}
            onColumnWidthsChange={this.onColumnWidthsChange}
          />

          <TableHeaderRow showSortingControls={true} />
          <TableFilterRow />
          <TableSummaryRow />
          <PagingPanel pageSizes={[5, 10, 20, 50, 0]} />
        </Grid>
        {/*{rows && <pre>{JSON.stringify(rows, null, 2)}</pre>}*/}
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    codeLang: getActiveCode(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    remove2FAActions: bindActionCreators(remove2FA, dispatch),
    // roleToUserActions: bindActionCreators(roleToUser, dispatch),
    // deleteRoleToUserActions: bindActionCreators(deleteRoleToUser, dispatch)
    deleteUserActions: bindActionCreators(deleteUser, dispatch),
    confirmEmailActions: bindActionCreators(confirmEmailAdmin, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableUser);
