import { combineReducers } from 'redux';

import { reducer as reduxFormReducer } from 'redux-form';
import { localizeReducer } from 'react-localize-redux';
import { connectRouter } from 'connected-react-router';
import { admin } from './admin/reducer';
import { alert } from './alert/alert.reducer';
import { loading } from './loading/loading.reducer';
import { user } from './user/reducer';
import { authentication } from './authentication/reducer';
import { trader } from './trader/reducer';
import { order } from './order/reducer';
import { config } from './config/reducer';
import { tokens } from './tokens/reducer';
import { onboarding } from './onboarding/reducer';
import { referrals } from './referrals/reducer';
import { strategyConfiguration } from './strategyConfiguration/reducer';

export const middlewareFormConstants = {
  REDUX_FORM_CHANGE: '@@redux-form/CHANGE',
};

const formPlugin = {
  formSmartTrade: (state, action) => {
    switch (action.type) {
      case middlewareFormConstants.REDUX_FORM_CHANGE:
        if (state?.values !== null) {
          localStorage.setItem('formSmartTrade', JSON.stringify(state.values));
        }
        return state;
      default:
        return state;
    }
  },
};

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    localize: localizeReducer,
    admin,
    alert,
    loading,
    user,
    authentication,
    trader,
    order,
    config,
    tokens,
    onboarding,
    referrals,
    strategyConfiguration,
    form: reduxFormReducer.plugin(formPlugin),
  });
