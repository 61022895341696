import { exchangeConstants } from './constants';
import { authenticationConstants } from '../authentication/constants';
import { isNull } from '../../helpers/Common';
import { getExchangeStr } from '../../helpers/order';

const exchanges = JSON.parse(localStorage.getItem('exchanges'));
const initialState = { exchanges, exchange: null };

export function user(state = initialState, action) {
  switch (action.type) {
    case authenticationConstants.LOGOUT:
      return {};
    case exchangeConstants.ACTION_GET_EXCHANGES: { // case exchangeConstants.ACTION_LOCAL_EXCHANGES:
      const nbExchange = action.payload.length;
      localStorage.setItem('nbExchange', nbExchange);

      for (const exchange of action.payload) {
        const exchangeStr = getExchangeStr(exchange);
        if (exchangeStr !== '') exchange.ExchangeName = exchangeStr;
        if (!exchange.HistoricBalances) {
          const payloadEx = state?.exchanges?.find((x) => x.Id === exchange.Id);
          if (payloadEx) exchange.HistoricBalances = payloadEx.HistoricBalances;
        }
      }
      return {
        ...state,
        exchanges: action.payload,
      };
    }
    case exchangeConstants.ACTION_GET_EXCHANGE:
    case exchangeConstants.ACTION_PUT_EXCHANGE: {
      const exchangeStr = getExchangeStr(action.payload);
      if (exchangeStr !== '') action.payload.ExchangeName = exchangeStr;
      if (!action.payload.HistoricBalances) {
        const payloadEx = state?.exchanges?.find(
          (x) => x.Id === action.payload.Id,
        );
        if (payloadEx)
          action.payload.HistoricBalances = payloadEx.HistoricBalances;
      }
      if (isNull(state.exchanges)) {
        return {
          ...state,
          exchange: action.payload,
          exchanges: [action.payload],
        };
      }
      const index = state.exchanges.findIndex(
        (x) => x.Id === action.payload.Id,
      );
      if (index !== -1) state.exchanges[index] = action.payload;
      return {
        ...state,
        exchange: action.payload,
        exchanges: [...state.exchanges],
      };
    }
    case exchangeConstants.ACTION_POST_EXCHANGE:
      return {
        ...state,
        exchanges: state.exchanges.concat(action.payload),
      };
    case exchangeConstants.ACTION_DELETE_EXCHANGE:
      return {
        ...state,
        exchanges: state.exchanges.filter(
          (x) => x.Id !== action.payload.AAccountId,
        ),
      };
    case exchangeConstants.ACTION_GET_HISTORIC_BALANCES:
      return {
        ...state,
        historicBalances: action.payload,
      };
    default:
      return state;
  }
}
