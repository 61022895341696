import React from 'react';

export default class TermsAndConditionsPage extends React.Component {
  render() {
    return (
      <div style={{ textAlign: 'justify' }}>
        <h1>
          <b>Terms and General Conditions of Sale and Use</b>
        </h1>
        <b>WARNING</b>
        <br />
        We do not give you any recommendation or investment advice. We offer
        assistance in your trading. Any trading involves risks that you are
        willing to accept. Bet only money that you can afford to lose. No
        winnings are guaranteed. All buy and sell positions on the crypto-asset
        market taken by the Customer or the User or by the trading automation
        tool on the https://walloftraders.com website, must be supervised by the
        Customer or User. Only the Customer or the User is responsible for these
        positions. Only people who understand the trading and the risks involved
        must register. We are not responsible for errors, inaccuracies,
        omissions, loss of data, loss of fiat currency, loss or devaluation of
        crypto-assets, misunderstanding of the Services provided, possible
        delays in execution or price variations. All Services, tools and
        information provided by Wall Of Traders are private and should not be
        considered infallible. You must be over 18 to use Wall Of Traders. The
        crypto-asset markets on which the Company offers its services are
        unregulated and decentralized markets. Being unregulated, these markets
        are not governed by any framework or regulation, French or European.
        Consequently, the Customer or the User realizes that he does not benefit
        from the protections offered by the regulated markets.
        <br />
        <h3>
          <b>1. About us</b>
        </h3>
        <br />
        The company WALL OF TRADERS, SAS, with a capital of 1000 euros, whose
        head office is in PARIS (75001), registered in the commercial register
        and companies of PARIS under the SIREN number 907601579 and located 15
        rue des Halles 75001 Paris the <b>&quot;Company&quot;</b>. The Company
        offers the following services: cryptocurrency trading assistance and
        subscription to trading signals.
        <h3>
          <b>2. Preamble</b>
        </h3>
        <br />
        The Company invites its Users to carefully read these Terms and
        Conditions of Sale and Use (hereinafter the{' '}
        <b>&quot;Terms and Conditions”</b>). The Terms and Conditions apply to
        all Services provided by the Company to its Customers of the same
        category, whatever the clauses that may be written on the
        Customer&apos;s documents and in particular its general conditions of
        purchase.
        <br />
        The Terms and Conditions are systematically communicated to the Customer
        who requests it.
        <br />
        The Customer is required to read the Terms and Conditions before placing
        any Order.
        <br />
        In the event of a subsequent modification of the Terms and Conditions,
        the Customer is subject to the latest published version.
        <br />
        The data recorded in the Company&apos;s IT system constitutes proof of
        the transactions concluded with the Customer.
        <br />
        <h3>
          <b>3. Definitions</b>
        </h3>
        <br />
        <b>&quot;Customer&quot;</b> means any natural or legal person who places
        an Order on this Website;
        <br />
        <b>&quot;Order&quot;</b> means any order placed by the User registered
        on this Site, in order to benefit from the Services of the Company;
        <br />
        <b>&quot;General Conditions of Sale and Use&quot;</b> or{' '}
        <b>&quot;Terms and Conditions&quot;</b> means these general conditions
        of sale and use online;
        <br />
        <b>&quot;Services&quot;</b> means all the services offered to Users by
        the Company through this Site;
        <br />
        <b>&quot;Crypto-assets&quot;</b> refers to all dematerialized assets
        whose transactions are made through the blockchain: they include
        cryptocurrencies and tokens;
        <br />
        <b>“Exchange”</b> means the exchange platforms outside the Site on which
        the Crypto-assets are purchased and exchanged and over which the Site
        has no control; for example Binance, Bittrex, Bitfinex, Kraken, Coinbase
        are Exchanges;
        <br />
        <b>&quot;Site&quot;</b> means this Site, https://walloftraders.com;
        <br />
        <b>&quot;Company&quot;</b> means the Wall Of Traders Corporation, more
        fully described in Section I hereof; and
        <br />
        <b>&quot;User&quot;</b> means any person who uses the Site.
        <br />
        <h3>
          <b>4. Registration</b>
        </h3>
        <br />
        The registration on the Site is open to all legal entities or physical
        majors and enjoying their full personalities and legal abilities.
        <br />
        The use of the Services offered on the Site is subject to the
        registration of the User on the Site.
        <br />
        Registration is free.
        <br />
        To proceed with registration, the User must complete all the required
        fields, without which the service can not be delivered. Failing
        registration can not be completed.
        <br />
        Users guarantee and declare on their honor that all the information
        communicated on the Site, in particular during their registration, is
        accurate and in conformity with reality. They undertake to update their
        personal information from the page dedicated to them and available in
        their account.
        <br />
        Any registered user has a username and password. These are strictly
        personal and confidential and should in no case be the subject of
        communication to third parties penalty of deletion of the account of the
        offending registered user. Each registered User is personally
        responsible for maintaining the confidentiality of his username and
        password. The Company will in no way be held responsible for the
        identity theft of a User. If a User suspects fraud at any time, he must
        contact the Company as soon as possible so that the latter can take the
        necessary measures and regularize the situation.
        <br />
        Each User, whether a natural or legal person, may only hold one account
        on the Site.
        <br />
        In case of non-compliance with the Terms and Conditions, including the
        creation of multiple accounts for one person or the provision of false
        information, the Company reserves the right to proceed to the temporary
        or permanent deletion of all accounts created by the User registered
        offender.
        <br />
        The deletion of the account entails the definitive loss of all the
        benefits and services acquired on the Site. However, any Order made and
        billed by the Site prior to the deletion of the account will be executed
        under normal conditions.
        <br />
        In the event of the deletion of an account by the Company for breach of
        the duties and obligations set forth in the Terms and Conditions, it is
        expressly forbidden for the offending User to re-register on the Website
        directly, through another email address or by an intermediary without
        the express authorization of the Company.
        <br />
        <h3>
          <b>5. Service Provision and Price</b>
        </h3>
        <br />
        The Services covered by the Terms and Conditions are those appearing on
        the Site and offered directly by the Company or its partner service
        providers.
        <br />
        The services are described on the corresponding page within the Site and
        mention is made of all their essential characteristics. The Company can
        not be held responsible for the impossibility of carrying out this
        service when the User is not eligible.
        <br />
        When a Registered User wishes to obtain a service sold by the Company
        through the Site, the price indicated on the page of the service
        corresponds to the price in euros all taxes included (TTC) and takes
        into account the reductions applicable and in force the day of the
        Order. The quoted price does not include the incidental expenses which
        will be indicated if necessary in the recapitulative before placing
        order.
        <br />
        The Company reserves the right to modify its rates at any time. It
        undertakes to invoice the services at the rate applicable at the time of
        their Order. For services whose price can not be known a priori or
        indicated with certainty, a detailed estimate will be sent to the
        Customer.
        <br />
        Under no circumstances may a User request the application of discounts
        no longer in effect on the date of the Order.
        <br />
        <h3>
          <b>6. Orders</b>
        </h3>
        <br />
        Any Order can be made only when the User has registered on the Site. The
        User, when logged into his account, can add Services to his virtual
        basket. He can access the summary of his virtual basket in order to
        confirm the Services he wishes to order and carry out his Order by
        pressing the &quot;Order&quot; button.
        <br />
        He must then enter his email address and a valid payment method to
        finalize the order and set effectively the sales contract between him
        and the Company.
        <br />
        The registration of an Order on the Site is made when the Customer
        accepts the Terms and Conditions by ticking the box provided for this
        purpose and validates his Order. This validation constitutes proof of
        the sales contract. The finalization of the Order implies the acceptance
        of the prices and terms of performance of the services as indicated on
        the Site.
        <br />
        Once the Order has been placed, the Customer will receive confirmation
        by email. This confirmation will summarize the Order and information
        related to the performance of the service(s).
        <br />
        In the event of a payment default, incorrect Customer address or other
        problem on the Customer&apos;s account, the Company reserves the right
        to block the Customer&apos;s order until the problem is resolved. If
        unable to perform the service, the Customer will be informed by email to
        the address he has provided to the Company. The cancellation of the
        order of this service and its refund will be made in this case, the rest
        of the order remaining firm and final.
        <br />
        <h3>
          <b>7. Deadlines and payment terms</b>
        </h3>
        <br />
        Unless otherwise stated, all sales are fully paid at the time of placing
        the Order.
        <br />
        Depending on the nature or amount of the Order, the Company remains free
        to demand a deposit or payment of the full price when placing the Order
        or upon receipt of the invoice.
        <br />
        Payment can be made by:
        <ul>
          <li>Bank card ;</li>
          <li>
            Crypto-asset Bitcoin, Ethereum or Litecoin upon conversion into
            euros or dollars of the subscription amount. The rate is the one of
            the day and the hour to the minute near the passage of the order;
          </li>
          <li>Paypal.</li>
        </ul>
        In the event of total or partial non-payment of the services on the date
        agreed upon on the invoice, the Customer shall pay the Company a late
        payment penalty at a rate equal to the rate charged by the European
        Central Bank for its refinancing operation plus 10 percentage points.
        The financing transaction selected is the most recent on the date of the
        Ordering of the Services.
        <br />
        In addition to the late payment, any sum, including the down payment,
        not paid on its due date will automatically result in the payment of a
        lump sum of 40 euros due for recovery costs.
        <br />
        In the event of total or partial non-payment of the services on the date
        agreed upon on the invoice, the Customer shall pay the Company a late
        payment penalty at a rate equal to the legal interest rate.
        <br />
        No compensation may be made by the Customer between late penalties in
        the provision of services ordered and amounts owed by the Customer to
        the Company for the purchase of Services offered on the Site.
        <br />
        The penalty payable by the Customer is calculated on the amount
        inclusive of taxes of the remaining amount due, and runs from the due
        date of the price without any prior notice being required.
        <br />
        In case of non-compliance with the terms of payment described above, the
        Company reserves the right to cancel or suspend the sale.
        <br />
        <h3>
          <b>8. Realization of services</b>
        </h3>
        The provision of services ordered on the Site will be provided by the
        Company.
        <br />
        The Company undertakes to implement all human and material resources to
        carry out the service within the time period announced when placing the
        Order. However, it can in no way be held responsible for delays in the
        performance of services caused by fault attributable to it.
        <br />
        If the services have not been performed within the deadline, the
        resolution of the sale may be requested by the Customer under the
        conditions provided for in Articles L138-2 and L138-3 of the Consumer
        Code. The sums paid by the Customer will be refunded at the latest
        within fourteen days of the date of termination of the contract.
        <br />
        This provision does not apply when the delay of the Company is due to a
        fault of the Customer or to a case of force majeure, ie to the
        occurrence of an unforeseeable event, irresistible and independent of
        the will of the Society.
        <br />
        In the event that the execution of a physical service could not have
        been carried out or would have been postponed due to an error on the
        address indicated by the Customer, the travel expenses of the service
        provider mandated by the Company to carry out the unsuccessful service
        will be the responsibility of the Customer.
        <br />
        <h3>
          <b>9. Claim</b>
        </h3>
        <br />
        For all Orders made on this Site, the Customer has a right of complaint
        of 5 days from the provision of the Service.
        <br />
        To exercise this right of complaint, the Customer must send to the
        Company, at the address contact@walloftraders.com, a statement in which
        he expresses his reserves and claims, together with supporting documents
        relating thereto.
        <br />
        A claim that does not comply with the conditions described above can not
        be accepted.
        <br />
        After examination of the claim, the Site may replace or refund the
        services as soon as possible and at its expense.
        <br />
        <h3>
          <b>10. Customer&apos;s right of withdrawal</b>
        </h3>
        <br />
        The Customer has a right of withdrawal of 14 days from the placing of
        the Order, except for the products mentioned in Article L121-21-8 of the
        Consumer Code as reproduced below:
        <br />
        &quot;The right of withdrawal can not be exercised for contracts:
        <br />
        1 ° Provision of services fully performed before the end of the
        withdrawal period and whose execution began after prior express
        agreement of the Customer and express waiver of his right of withdrawal;
        <br />
        2 ° supply of goods or services the price of which depends on
        fluctuations in the financial market beyond the control of the trader
        and likely to occur during the withdrawal period;
        <br />
        3 ° Supply of goods made to the Customer&apos;s specifications or
        clearly personalized;
        <br />
        4 ° Supply of goods likely to deteriorate or expire rapidly;
        <br />
        5 ° Supply of goods that have been unsealed by the Customer after
        delivery and that can not be returned for reasons of hygiene or health
        protection;
        <br />
        6 ° supply of goods which, after being delivered and by their nature,
        are mixed inseparably with other articles;
        <br />
        7 ° Supply of alcoholic beverages whose delivery is deferred beyond
        thirty days and whose value agreed at the conclusion of the contract
        depends on fluctuations in the market beyond the control of the
        professional;
        <br />
        8 ° Maintenance or repair work to be performed urgently in the
        Customer&apos;s home and expressly requested by him, within the limits
        of spare parts and work strictly necessary to respond to the emergency;
        <br />
        9 ° Provision of audio or video recordings or computer software when
        they have been unsealed by the Customer after delivery;
        <br />
        10 ° supplying a newspaper, periodical or magazine, except for
        subscription contracts to such publications;
        <br />
        11 ° Concluded at a public auction;
        <br />
        12 ° Providing accommodation services, other than residential
        accommodation, goods transport services, car rental, catering or leisure
        activities that must be provided at a specified date or period;
        <br />
        13 ° Providing digital content that is not provided on a physical medium
        whose execution has begun after express prior consent of the Customer
        and expressly waives its right of withdrawal. &quot;
        <br />
        To exercise this right of withdrawal the Customer sends a declaration to
        the address:
        <br />
        contact@walloftraders.com.
        <br />
        It will be reimbursed for all fees paid for the provision of services
        within 14 days of becoming aware of the company&apos;s declaration of
        withdrawal. The refund will be made by the same means of payment as the
        one used for the purchase.
        <br />
        However, if the provision of services is already started at the date of
        acknowledgment of the retraction by the Company, the value corresponding
        to the service already provided will be deducted from the refund. The
        latter will be operated by the same means of payment as for the
        purchase.
        <br />
        <h3>
          <b>11. Processing of personal data</b>
        </h3>
        <br />
        The registration on the Site entails the processing of the
        Customer&apos;s personal data. If the Customer refuses the processing of
        his data, he is asked to abstain from using the Site.
        <br />
        This processing of personal data is done in compliance with the General
        Regulation on Data Protection 2016/679 of 27 April 2016.
        <br />
        In addition, in accordance with the Data Protection Act of 6 January
        1978, the Customer has at all times the right to question, access,
        rectify, modify and oppose all of its personal data by writing, by mail
        and justifying his identity, to the following address:
        contact@walloftraders.com.
        <br />
        This personal data is necessary for the processing of the Order and the
        preparation of its invoices if necessary, as well as for improving the
        functionalities of the Site.
        <br />
        <h3>
          <b>12. Sharing collected data</b>
        </h3>
        <br />
        The Site may use third party companies to perform certain operations. By
        browsing the Website, the Customer accepts that third parties may have
        access to his data to enable the proper functioning of the Site.
        <br />
        These third-party companies have access to collected data only in the
        context of performing a specific task.
        <br />
        The Site remains responsible for the processing of this data.
        <br />
        In addition, the User may be required to receive information or
        commercial offers from the Company or its partners.
        <br />
        The User may at any time oppose the receipt of these commercial offers,
        by writing to the address of the Company indicated above, or by clicking
        on the link provided for this purpose in the emails received.
        <br />
        In addition, the information of the Customers may be transmitted to
        third parties without their express prior agreement in order to achieve
        the following purposes:
        <br />
        <ul>
          <li>respect the law</li>
          <li>protect anyone from serious bodily injury or death</li>
          <li>fight against fraud or damage to the Company or its users</li>
          <li>protect the Company&apos;s property rights.</li>
        </ul>
        <h3>
          <b>13. Data protection</b>
        </h3>
        <br />
        The Company ensures a level of security that is appropriate and
        proportional to the risks incurred and their probability, in accordance
        with the General Data Protection Regulation 2016/679 of 27 April 2016.
        <br />
        However, these measures are in no way a guarantee and do not commit the
        Company to an obligation of result concerning the security of the data.
        <br />
        <h3>
          <b>14. Acceptance of risks</b>
        </h3>
        <br />
        The Customer or the User accepts the risks related to crypto-assets and
        their very high volatility, and in particular the risks related to the
        blockchain in which transactions are made. There is no legislation
        governing blockchain which is an independent and decentralized network.
        The Customer or the User understands that errors, faults or theft may
        occur in the blockchain and result in the loss of the funds invested.
        <br />
        The funds invested are held by the Exchanges and the buy and sell orders
        of these funds in the market for crypto-assets are executed by these
        same Exchanges. These Exchanges are not banks. If they are stolen or go
        bankrupt, there is no legal protection covering you for your losses.
        Wall Of Traders will not be responsible for any losses caused by these
        Exchanges. In addition, the liquidity of these platforms is not
        guaranteed. The pure and simple disappearance of the crypto-asset is
        possible.
        <br />
        The interaction and exchange of data between the Wall Of Traders Company
        website and these Exchanges is done via API key. By entering an API key
        on the Wall Of Traders website, the Customer or the User accepts the
        risk of loss or theft of these API keys and the consequences that may
        result from it, including the fact that a thief may manipulate the
        Customer&apos;s or User&apos;s wallet by buying it at a high price and
        reselling it at a lower price, thus resulting in a loss of value of the
        Customer&apos;s or the User&apos;s crypto-asset portfolio.
        <br />
        It is possible to copy the strategies of traders selected by the
        Company. However, we do not guarantee the results, quality,
        professionalism or good intentions of a trader. Past results of a trader
        can not prejudge his future results.
        <br />
        <h3>
          <b>15. Data collection</b>
        </h3>
        <br />
        Wall Of Traders does not collect private data directly on its Users. No
        personal information is stored on our servers. If you wish to register
        to receive updates by email, the only data collected will be your name,
        email and the page on which you filled out a form. This information is
        stored directly on the servers of SoYouStart, a subsidiary of OVH. No
        email address will be sent to a third party without the permission of
        the subscriber.
        <br />
        <h3>
          <b>16. Language</b>
        </h3>
        <br />
        In case of translation of these Terms and Conditions into other
        languages, the language of interpretation will be the French language.
        <br />
        <h3>
          <b>17. Cookies</b>
        </h3>
        <br />
        To enable its Users to benefit from an optimal navigation on the Site
        and a better functioning of the various interfaces and applications, the
        Company may place a cookie on the User&apos;s computer. This cookie
        makes it possible to store information relating to the navigation on the
        Site, as well as any data entered by the Users (including searches,
        login, email, password).
        <br />
        The User expressly authorizes the Company to deposit on the hard disk of
        the user a so-called &quot;cookie&quot; file.
        <br />
        The User has the option to block, modify the retention period, or delete
        this cookie via the interface of his browser. If the systematic
        deactivation of cookies on the User&apos;s browser prevents him from
        using certain services or features of the Site, this malfunction can not
        in any way constitute damage to the member who can not claim any
        compensation as a result.
        <br />
        <h3>
          <b>18. Changes</b>
        </h3>
        <br />
        The Company reserves the right to modify the Site, the services offered
        therein, the Terms and Conditions as well as any delivery procedure or
        other component of the services provided by the Company through the
        Site.
        <br />
        When placing an Order, the User is subject to the stipulations set out
        in the Terms and Conditions in force when placing the Order.
        <br />
        <h3>
          <b>19. Liability</b>
        </h3>
        <br />
        The Company can not under any circumstances be held responsible for the
        unavailability, whether temporary or permanent, of the Website and,
        although it implements all means to ensure the service at all times, it
        may be that it is interrupted at any time. In addition, the Company
        reserves the right, by a voluntary act, to make the Site unavailable in
        order to carry out any update, improvement or maintenance operation.
        <br />
        As previously mentioned herein, the Company can not be held liable in
        any way for delays in performing a service for reasons beyond its
        control, beyond its control, unforeseeable and irresistible or whose
        fault can be attributed to him.
        <br />
        <h3>
          <b>20. Intellectual property</b>
        </h3>
        <br />
        The brand, the logo, and the graphic charter of this Site are trademarks
        registered with the INPI and works protected by intellectual property,
        the property of which is the exclusive property of the Company. Any
        dissemination, exploitation, representation, reproduction, whether
        partial or complete without the express authorization of the Company,
        will expose the violator to civil and criminal prosecution.
        <br />
        <h3>
          <b>21. Jurisdiction clause</b>
        </h3>
        <br />
        The law governing Terms and Conditions is French law. Any dispute that
        may arise between the Company and a User during the execution of the
        present will be the subject of an attempt to resolve amicably.
        Otherwise, the disputes will be brought to the attention of the
        competent courts of common law.
        <br />
        The Customer is informed that he can resort to a conventional mediation,
        with the Consumer Mediation Commission provided for in Article L534-7 of
        the Consumer Code or with existing sectoral mediation bodies. It may
        also use any alternative dispute resolution procedure in case of
        dispute.
        <br />
        <h3>
          <b>22. Pre-contractual information</b>
        </h3>
        <br />
        Prior to his Order, the Customer acknowledges having had communication,
        readable and understandable, of the Terms and Conditions and information
        and information provided for in Articles L111-1 to L111-7 of the
        Consumer Code, and in particular:
        <br />
        <ul>
          <li>the essential characteristics of the Services;</li>
          <li>the price of the Services;</li>
          <li>
            the date or time at which the Company undertakes to provide the
            Service;
          </li>
          <li>
            information relating to the identity of the Company (postal,
            telephone and electronic contact details); information on legal and
            contractual warranties and how they are implemented; the possibility
            of using conventional mediation in the event of litigation;
            information relating to the right of withdrawal (deadline, methods
            of exercise).
          </li>
        </ul>
        <br />
        The placing of an Order on the Site entails adhesion and acceptance of
        the Terms and Conditions. The Customer will not be able to rely on a
        contradictory document.
        <br />
      </div>
    );
  }
}
