import React from 'react';
import Trader from './Trader.jsx';

import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { getTraders } from '../../redux/trader/actions';

import { Add } from '@material-ui/icons';
import WarningIcon from '@material-ui/icons/Warning';
import { Translate, withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import Button from '../../components/CustomButtons/Button';
import GridItem from '../../components/Grid/GridItem';
import Warning from '../../components/Typography/Warning';
import { hasAnActiveSubscription } from '../../helpers/trader';
import transConfirm from '../../locales/confirmation';
import transTrader from '../../locales/traders.json';
import { loggedInSelector } from '../../redux/authentication/selector';
import { tradersSelector } from '../../redux/trader/selector';
import { getExchangesSelect, isNull } from '../../helpers/Common';
import AddTraderContainer from '../Trader/AddTraderContainer';

const styles = {
  buttonLeft: {
    width: '49%',
    marginLeft: '0px',
    marginRight: '0px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  },
  buttonRight: {
    width: '49%',
    marginLeft: '0px',
    marginRight: '0px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
};

class TradersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reference: '',
    };
    this.props.addTranslation(transTrader);
    this.props.addTranslation(transConfirm);
  }

  componentDidMount() {
    this.props.getTradersActions();
  }

  render() {
    const { traders, exchangesSelect, loggedIn, hasAnActiveSubscription } =
      this.props;
    return (
      <GridItem container>
        <GridItem xs={12} sm={6} md={6}>
          <AddTraderContainer />
        </GridItem>
        <GridItem xs={12} sm={3} md={3}>
          {loggedIn && hasAnActiveSubscription && (
            <Button
              color="info"
              fullWidth
              to={'/strategyConfiguration'}
              component={Link}
              size={'sm'}
            >
              <Add style={{ margin: '4px' }} />
              <Translate id="menu.strategyConfiguration" />
            </Button>
          )}
        </GridItem>
        <GridItem xs={12} sm={3} md={3}>
          <Button
            color="info"
            fullWidth
            to={'/addTrader'}
            component={Link}
            size={'sm'}
          >
            <Add style={{ margin: '4px' }} />
            <Translate id="traders.createTrader" />
          </Button>
        </GridItem>
        {traders &&
          traders.map((trader) => {
            if (trader.GroupId !== 1)
              return <Trader key={trader.GroupId} trader={{ ...trader }} />;
          })}
      </GridItem>
    );
  }
}

// will sort the traders in the following order:
// 1. groups the user created
// 2. groups the user is following
// 3. groups that are from the wot platform (and not followed nor created by the user)
// 4. groups that are from the leaderboard (and not followed by the user)
const sortTraders = (unsortedLst, userId) => {
  if (isNull(unsortedLst) || unsortedLst.length === 0) return [];

  // take user's groups first and sort it by group id
  const userGroups = unsortedLst.filter(
    (trader) => trader.ApplicationUserId === userId,
  );
  userGroups.sort((a, b) => a.GroupId - b.GroupId);
  // the rest is the groups the user has not created
  let rest = unsortedLst.filter((trader) => userGroups.indexOf(trader) === -1);

  // take the groups the user is following and sort it by subscription group id
  const userSubscriptions = rest.filter(
    (trader) =>
      !isNull(trader.SubscriptionGroups) &&
      trader.SubscriptionGroups.some((sg) => sg.ApplicationUserId === userId),
  );
  userSubscriptions.sort(
    (a, b) => a.SubscriptionGroups[0].GroupId - b.SubscriptionGroups[0].GroupId,
  );
  // the rest is the groups the user is not following (and not created by the user)
  rest = rest.filter((trader) => userSubscriptions.indexOf(trader) === -1);

  // take the wot groups and sort it by groupId
  const wotGroups = rest.filter((trader) => !(trader.IsLeaderBoard === true));
  wotGroups.sort((a, b) => a.GroupId - b.GroupId);
  // the rest is the leaderboard groups (and not followed nor created by the user)
  const leaderboardGroups = rest.filter(
    (trader) => wotGroups.indexOf(trader) === -1,
  );

  // sort the leaderBoard groups by NbSubscribers
  // leaderboardGroups.sort((a, b) => b.NbSubscribers - a.NbSubscribers);
  leaderboardGroups.sort((a, b) => b.NbSubscribers - a.NbSubscribers);

  return [
    ...userGroups,
    ...userSubscriptions,
    ...wotGroups,
    ...leaderboardGroups,
  ];
};

const mapStateToProps = (state, props) => {
  let isLogged = loggedInSelector(state);
  let userId = isLogged ? state.authentication.auth.id : null;
  return {
    traders: sortTraders(tradersSelector(state), userId),
    exchangesSelect: getExchangesSelect(state, props),
    loggedIn: isLogged,
    hasAnActiveSubscription: hasAnActiveSubscription(tradersSelector(state)),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTradersActions: bindActionCreators(getTraders, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(TradersPage),
);
