import React from 'react';
import Card from '../../../components/Card/Card.jsx';
import {
  TreeDataState,
  SortingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  IntegratedPaging,
  PagingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';

import connect from 'react-redux/es/connect/connect';
import { getActiveCode } from '../../../helpers/localize';

class TableSubGroups extends React.PureComponent {
  constructor(props) {
    super(props);

    let columns = [
      {
        name: 'SubscriptionGroupId',
        title: 'Id',
      },
      {
        name: 'Email',
        title: 'Email',
        getCellValue: (row) => {
          return row['ApplicationUser'] !== null
            ? row['ApplicationUser'].Email
            : row['Email'];
        },
      },
      {
        name: 'Group',
        title: 'Group',
        getCellValue: (row) => {
          return row['Group'] !== null ? row['Group'].Name : '';
        },
      },
      {
        name: 'GroupId',
        title: 'GroupId',
      },
      {
        name: 'EndDate',
        title: 'EndDate',
      },
      {
        name: 'Pourcentage',
        title: 'Pourcentage',
        getCellValue: (row) => {
          return row['Pourcentage'] > 0 ? row['Pourcentage'] : '';
        },
      },
      {
        name: 'AutoAcceptOrder',
        title: 'AutoAcceptOrder',
        getCellValue: (row) => {
          if (
            row['IsActive'] === true &&
            row['ApplicationUser'] !== null &&
            row['Pourcentage'] > 0
          )
            return row['AutoAcceptOrder'] === true ? 'true' : 'false';
          return '';
        },
      },
      {
        name: 'IsActive',
        title: 'IsActive',
        getCellValue: (row) => {
          return row['IsActive'] ? 'true' : 'false';
        },
      },
      {
        name: 'Newsletter',
        title: 'Newsletter',
        getCellValue: (row) => {
          return row['ApplicationUser'] !== null
            ? row['ApplicationUser'].Newsletter === true
              ? 'true'
              : 'false'
            : 'false';
        },
      },
      {
        name: 'HasAccount',
        title: 'HasAccount',
        getCellValue: (row) => {
          return row['ApplicationUser'] != null ? 'true' : 'false';
        },
      },
    ];

    let defaultColumnWidths = [
      { columnName: 'SubscriptionGroupId', width: 80 },
      { columnName: 'Email', width: 250 },
      { columnName: 'Group', width: 150 },
      { columnName: 'GroupId', width: 80 },
      { columnName: 'EndDate', width: 95 },
      { columnName: 'Pourcentage', width: 80 },
      { columnName: 'AutoAcceptOrder', width: 80 },
      { columnName: 'IsActive', width: 80 },
      { columnName: 'Newsletter', width: 80 },
      { columnName: 'HasAccount', width: 80 },
    ];

    this.state = {
      //table
      columns: columns,
      id: 1,
      rows: props.subsGroups,
      pageSizes: [5, 10, 20, 50, 0],
      defaultColumnWidths: defaultColumnWidths,
      filters: [],
    };
    this.changeFilters = (filters) => this.setState({ filters });
  }

  componentDidUpdate() {
    this.setState({ rows: this.props.subsGroups });
  }

  render() {
    const { rows, columns, defaultColumnWidths, filters, pageSizes } =
      this.state;

    return (
      <Card>
        <Grid rows={rows} columns={columns}>
          <FilteringState
            filters={filters}
            onFiltersChange={this.changeFilters}
          />
          <SortingState />
          <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[1]} />
          <TreeDataState />

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />

          <Table />
          <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />

          <TableHeaderRow showSortingControls={true} />
          <TableFilterRow />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
        {/*{rows && <pre>{JSON.stringify(rows, null, 2)}</pre>}*/}
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    codeLang: getActiveCode(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TableSubGroups);
