import React from 'react';
import typographyStyle from '../../assets/jss/material-dashboard-react/components/typographyStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import { Translate } from 'react-localize-redux';

function BuyType(props) {
  const { classes, isBuy } = props;
  if (isBuy === null)
    return (
      <>
        <span className={classes.successText}>
          <Translate id="orders.buy" />
        </span>
        &nbsp;/&nbsp;
        <span className={classes.dangerText}>
          <Translate id="orders.sell" />
        </span>
      </>
    );
  return (
    <span className={isBuy ? classes.successText : classes.dangerText}>
      {isBuy ? <Translate id="orders.buy" /> : <Translate id="orders.sell" />}
    </span>
  );
}

export default withStyles(typographyStyle)(BuyType);
