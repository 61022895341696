import { isNull } from './Common';

export const getUsdFromReferrals = (referrals) => {
  if (isNull(referrals)) return 0;

  let totalAmount = 0;
  for (const referral of referrals) {
    totalAmount += getUsdFromGains(referral.Gains);
  }
  return totalAmount;
};

export const getUsdFromGains = (gains) => {
  if (isNull(gains)) return 0;

  let total = 0;
  for (const gain of gains) {
    const amount = isNull(gain?.amount) ? 0 : gain?.amount;
    const price = isNull(gain?.token?.price) ? 0 : gain?.token.price;
    const usd = amount * price;
    total += usd;
  }
  return total;
};
