import React from 'react';
import { bindActionCreators } from 'redux';
import { change, getFormInitialValues, getFormValues } from 'redux-form';
import {
  getPrimFromPair,
  getSecFromPair,
  currentPriceSelector,
  stepPriceSelector,
} from '../../../helpers/Common';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { formValueSelector } from 'redux-form';
import Price from '../../../components/Fields/Price';

class StartPriceContainer extends React.PureComponent {
  // componentDidUpdate = (prevProps, prevState, snapshot) => {
  //   const {
  //     startPrice,
  //     isBuy,
  //     stopProfit,
  //     formName,
  //     change,
  //     targets
  //   } = this.props;
  //onStartPriceChange
  // console.log('targets', targets);
  // if (startPrice !== prevProps.startPrice || isBuy !== prevProps.isBuy) {
  //   const priceSL =
  //     isEmpty(startPrice) || isEmpty(stopProfit)
  //       ? ''
  //       : getPriceFromProfit(stopProfit, isBuy, startPrice);
  //
  //   change(formName, 'stopPrice', priceSL);
  //
  //   //Change targets
  //   // const nbTargets = targets.length - 1;
  //   // console.log('nbTargets', nbTargets);
  //   // for (let i = 0; i < nbTargets; ++i) {
  //   //   console.log('i', i);
  //   //   const target = targets[i];
  //   let i = 0;
  //   for (const target of targets) {
  //     change(
  //       formName,
  //       `targets[${i}].price`,
  //       getPriceFromProfit(target.profit, isBuy, startPrice)
  //     );
  //     i++;
  //   }
  // }
  // };

  render() {
    const { isBuy, pair, typeStart, isBuyDone, stepPrice } = this.props;
    const isMarket = typeStart === 'Market';
    // const isUpdate = false;
    const prim = getPrimFromPair(pair);
    const sec = getSecFromPair(pair);
    const endAdornment = sec + '/' + prim;
    // const endAdornment = (
    //   <div style={{ width: '40px' }}>
    //     <div className={`s-s-${sec}`} />/<div className={`s-s-${prim}`} />
    //   </div>
    // );
    return (
      <Price
        name="startPrice"
        label={
          isBuy ? (
            <Translate id="smartTrade.buyLimit" />
          ) : (
            <Translate id="smartTrade.sellLimit" />
          )
        }
        endAdornment={endAdornment}
        disabled={isMarket || isBuyDone}
        step={stepPrice}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  return {
    values: getFormValues(props.formName)(state),
    initialValues: getFormInitialValues(props.formName)(state),
    pair: formSelector(state, 'pair'),
    startPrice: formSelector(state, 'startPrice'),
    isBuy: formSelector(state, 'isBuy'),
    typeStart: formSelector(state, 'typeStart'),
    isBuyDone: formSelector(state, 'isBuyDone'),
    targets: formSelector(state, 'targets'),
    currentPrice: currentPriceSelector(state, props),
    stepPrice: stepPriceSelector(state, props),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StartPriceContainer);
