import React from 'react';
import RGroup from '@material-ui/core/RadioGroup';

const styles = {
  group: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
};

const RadioGroup = ({ input, ...rest }) => (
  <div className="input-row">
    <RGroup
      style={styles.group}
      {...input}
      {...rest}
      onChange={(event, value) => input.onChange(value)}
    />
  </div>
);
export default RadioGroup;
