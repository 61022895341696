import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import GridItem from '../../components/Grid/GridItem.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import Card from '../../components/Card/Card.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import transTraders from '../../locales/traders';
import { statGroupSelector } from '../../redux/trader/selector';
import { getStatTrader } from '../../redux/trader/actions';
import Responsive from '../../layouts/Responsive.jsx';
import useTranslation from '../../hooks/useTranslation.js';

import { isNull } from '../../helpers/Common';
import MonthlyStats from '../Traders/MonthlyStats.jsx';

const ResponsiveTraderPage = ({ traderName, monthlyStats }) => {
  useTranslation(transTraders);
  return (
    <GridItem xs={12} sm={12} md={12}>
      <Card plain profile>
        <CardHeader>
          <Responsive
            mobile={<h1 style={{ fontSize: '2rem' }}>{traderName}</h1>}
            desktop={<h1>{traderName}</h1>}
          />
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <MonthlyStats stats={monthlyStats} />
              </Card>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  );
};
class TraderPage extends React.Component {
  componentDidMount() {
    const { match } = this.props;
    const groupId = match.params.groupId;
    if (!isNull(groupId)) this.props.getStatTraderActions(groupId);
  }
  render() {
    const { statGroup } = this.props;
    if (isNull(statGroup) || isNull(statGroup.Group)) return <div />;
    return (
      <ResponsiveTraderPage
        traderName={statGroup.Group.Name}
        monthlyStats={statGroup.ProfitPerMonth}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    statGroup: statGroupSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStatTraderActions: bindActionCreators(getStatTrader, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TraderPage);
