import { Dialog, DialogContent, Slide } from '@material-ui/core';
import React from 'react';
import ExchangeContainer from '../Accounts/ExchangeContainer';
import GridItem from '../../components/Grid/GridItem';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const Transition = (props) => <Slide direction="up" {...props} />;

class DialogExchangeFollower extends React.PureComponent {
  render() {
    const { open, onClose, exchange, isAdmin = false } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <GridItem container justifyContent="flex-end">
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </GridItem>
        <DialogContent>
          <ExchangeContainer
            isAdmin={isAdmin}
            hidePositions={isAdmin}
            exchange={exchange}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

export default DialogExchangeFollower;
