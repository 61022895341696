import { isNull } from '../../helpers/Common';

//Satoshi Consultancy
export const isSCSelector = (state) => {
  const userId = userIdSelector(state);
  return [
    'cf17aebb-2f70-4206-aca8-1465f6e91fdf',
    '86461b5f-a13d-4c0f-bf41-19fc166fca21',
    '77d57111-ece9-4880-b2a3-84cdda899b40',
    '92ebc963-4d44-4bfd-9f38-3734a85fc9a6'
  ].includes(userId);
};
export const userIdSelector = (state) => {
  if (
    isNull(state) ||
    isNull(state.authentication) ||
    isNull(state.authentication.auth) ||
    isNull(state.authentication.auth.id)
  )
    return '';
  return state.authentication.auth.id;
};

export const loggedInSelector = (state) => {
  if (!state || !state.authentication || !state.authentication.loggedIn)
    return false;
  return state.authentication.loggedIn;
};

export const rolesSelector = (state) => {
  if (
    !state ||
    !state.authentication ||
    !state.authentication.auth ||
    !state.authentication.auth.roles
  )
    return [];
  return state.authentication.auth.roles;
};

export const isAdminSelector = (state) => {
  const roles = rolesSelector(state);
  return roles.includes('Admin');
};
