import React from 'react';
import {
  reduxForm,
  change,
  getFormValues,
  getFormSyncErrors,
  initialize,
  reset,
  formValueSelector,
} from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslate } from 'react-localize-redux';

import { postSmartTrade, putOrder } from '../../redux/order/actions';
import { convertOrderBeforeSend } from '../../helpers/order';
import Responsive from '../../layouts/Responsive';

import validate from './validate';
import DialogCreateTrade from './DialogCreateTrade';
import {
  capitalSelector,
  currentPriceSelector,
  qtyPrimSelector,
  qtySecSelector,
  initialValuesSelector,
  pairInfoSelector,
  stepPriceSelector,
  stepQtySelector,
  leverageBuySelector,
  leverageSellSelector,
  isNull,
} from '../../helpers/Common';
import FormSmartTradeMobile from './FormSmartTrade.mobile';
import FormSmartTradeDesktop from './FormSmartTrade.desktop';

const formName = 'formSmartTrade';

class FormSmartTrade extends React.Component {
  constructor(props) {
    super(props);

    this.first = false;
    this.state = {
      open: false,
      order: null,
      disabledClick: false,
    };
  }

  createTrade = () => {
    const { noPopupTrading, leverageBuy, leverageSell, data } = this.props;
    this.setState({ disabledClick: true });
    setTimeout(() => {
      this.setState({ disabledClick: false });
    }, 3000);
    if (noPopupTrading) {
      let order = convertOrderBeforeSend(data, leverageBuy, leverageSell);
      if (order.order != null) {
        this.props.putOrderActions(order.order.OrderId, order, noPopupTrading);
      } else {
        this.props.postSmartTradeActions(order, noPopupTrading);
      }
    } else {
      this.setState({ open: true });
    }
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const { capital, exchange } = this.props;
    // console.log(
    //   'FormSmartTrade componentDidUpdate',
    //   this.props,
    //   this.state,
    //   prevProps,
    //   prevState,
    //   this.props.order,
    //   currentPrice,
    //   prevProps.currentPrice,
    //   values
    // );
    // console.log('INITILIZED', this.props.initialValues,this.props.order, this.state.order, (this.props.order !== null && isNull(this.state.order)), (this.props.order !== null && capital !== prevProps.capital));

    const newAccountId = this.props.initialValues?.accountId?.value ?? 0;
    const currentAccountId = exchange?.value;
    if (
      (!isNull(this.props.order) &&
        isNull(this.state.order) &&
        this.first === false) ||
      newAccountId !== currentAccountId ||
      (this.props.order !== null && capital !== prevProps.capital)
    ) {
      this.first = true;
      if (this.props.order !== this.state.order) { // pour éviter de multi render quand on update un ordre après un partial PS
        this.setState({ order: this.props.order });
        this.props.initialize(this.props.initialValues);
      }
      // this.props.reset();
    }
    // if (prevState.order === null && this.state.order !== null) {
    //   this.setState({order})
    // }
  };

  onCloseDialog = () => {
    this.setState({ open: false });
  };

  render() {
    const { order, errors, loading } = this.props;
    const { open, disabledClick } = this.state;
    // const isUpdate = !!order;
    // const formName = 'formSmartTrade';
    const hasErrors = Object.keys(errors).length !== 0 || loading.type === 'ON';
    // console.log('FormSmartTrade render');
    // console.log(
    //   'FormSmartTrade render',
    //   this.props,
    //   errors,
    //   hasErrors,
    //   submitting,
    //   pristine,
    //   sendIsLock
    // );
    return (
      <form>
        <Responsive
          mobile={
            <FormSmartTradeMobile
              onSubmit={this.createTrade}
              currentOrder={order}
              disabled={hasErrors || disabledClick}
              formName={formName}
            />
          }
          desktop={
            <FormSmartTradeDesktop
              onSubmit={this.createTrade}
              currentOrder={order}
              disabled={hasErrors || disabledClick}
              formName={formName}
            />
          }
        />
        {open && (
          <DialogCreateTrade formName={formName} onClose={this.onCloseDialog} />
        )}
      </form>
    );
  }
}

let formSmartTrade = reduxForm({
  form: formName, // a unique identifier for this form
  validate,
  // touchOnBlur: false,
  touchOnChange: true,
  // touchOnBlur: true,
  // enableReinitialize: true
  // warn
  // asyncValidate
})(FormSmartTrade);

const mapStateToProps = (state, props) => {
  const { localize, loading, config } = state;
  const formSelector = formValueSelector(formName);
  return {
    noPopupTrading: config.noPopupTrading,
    loading,
    data: getFormValues(formName)(state),
    values: getFormValues(formName)(state),
    // important: initialValues prop will be read by redux-form
    // the keys must match the `name` property of the each form field
    initialValues: initialValuesSelector(state, props),
    errors: getFormSyncErrors(formName)(state),
    //For validate
    capital: capitalSelector(state, props),
    pairInfo: pairInfoSelector(state, props),
    currentPrice: currentPriceSelector(state, props),
    leverageBuy: leverageBuySelector(state, props),
    leverageSell: leverageSellSelector(state, props),

    exchange: formSelector(state, 'accountId'),
    // isUpdate: formSelector(state, 'isUpdate'),
    translate: getTranslate(localize),
    stepPrice: stepPriceSelector(state, props),
    lotStep: stepQtySelector(state, props),
    qtyPrim: qtyPrimSelector(state, props),
    qtySec: qtySecSelector(state, props),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    reset: bindActionCreators(reset, dispatch),
    initialize: bindActionCreators(initialize, dispatch),
    postSmartTradeActions: bindActionCreators(postSmartTrade, dispatch),
    putOrderActions: bindActionCreators(putOrder, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(formSmartTrade);
