import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { setActiveCode } from '../../helpers/localize';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const LanguageToggle = ({
  languages,
  activeLanguage,
  setActiveLanguage,
  withName,
}) => {
  // const getClass = languageCode => {
  //   if (!activeLanguage || !activeLanguage.code) return 'active';
  //   return languageCode === activeLanguage.code ? 'active' : 'disabled';
  // };

  const setLang = (code) => {
    setActiveLanguage(code);
    setActiveCode(code);
  };

  const handleChange = (event) => {
    setLang(event.target.value);
  };
  const showName = withName && withName === true;
  return (
    <Select
      onChange={handleChange}
      SelectDisplayProps={{ style: { padding: '0 24px 0 0' } }}
      value={activeLanguage && activeLanguage.code ? activeLanguage.code : 'gb'}
      style={{ marginBottom: '10px' }}
    >
      {languages.map((lang) => {
        return (
          <MenuItem component="div" key={lang.code} value={lang.code}>
            <div>
              <div className={'flag flag-' + lang.code} />
              <div
                style={{
                  display: 'inline-block',
                  position: 'relative',
                  top: '-9px',
                  left: '4px',
                }}
              >
                {showName && lang.name}
              </div>
            </div>
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default withLocalize(LanguageToggle);
