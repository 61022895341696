import { push } from 'connected-react-router';
import { onboardingConstants } from './constants';
import { Translate } from '../../helpers/general';
import { alertActions } from '../alert/alert.actions';
import { fetcher } from '../../helpers/fetcher';

export const changeProfil = (profil) => async (dispatch) => {
  dispatch({
    type: onboardingConstants.CHANGE_PROFIL,
    payload: profil,
  });
};

export const changeStep = (step) => async (dispatch) => {
  dispatch({
    type: onboardingConstants.CHANGE_STEP,
    payload: step,
  });
};

export const sendWhiteLabelText = (text) => async (dispatch, getState) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Referral/whitelabel?text=${text}`,
    {
      method: 'POST',
    },
  );
  if (eu) {
    const text = Translate(getState, 'onboarding.whiteLabelTextSent');
    dispatch(alertActions.alertSuccess(text));
  }
};

export const changeShowDialog = (dialog) => async (dispatch) => {
  dispatch({
    type: onboardingConstants.CHANGE_SHOW_DIALOG,
    payload: dialog,
  });
};

export const addFollowerOnboarding = (request) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/Group/AddUser', {
    method: 'POST',
    body: request,
  });
  if (eu) {
    dispatch(push(`/addFollower/${request.groupId}`));
  }
};

export const getOnboarding = () => {
  //Gestion Onboarding
  //S'il ny a pas de date OU
  //que la date est plus vieille que 1 jour affiche le onboarding
  const dateOnboardingLS = new Date(localStorage.getItem('dateOnboarding'));
  dateOnboardingLS.setDate(dateOnboardingLS.getDate() + 1);
  const now = new Date();
  const moreThanOneDay = dateOnboardingLS && now > dateOnboardingLS;
  const nbExchange = parseInt(localStorage.getItem('nbExchange'));
  // console.log(moreThanOneDay, nbExchange, now.toISOString(), dateOnboardingLS.toISOString());
  const authLS = localStorage.getItem('authentication');
  return !authLS ? false : moreThanOneDay && nbExchange === 0;
};
