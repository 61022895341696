export const getSubGroup = (trader) => {
  return trader &&
    trader.SubscriptionGroups &&
    trader.SubscriptionGroups.length > 0
    ? trader.SubscriptionGroups[0]
    : null;
};

export const hasAnActiveSubscription = (traders) => {
  for (const trader of traders) {
    const sub = getSubGroup(trader);
    if (
      sub !== null &&
      sub.GroupId !== 1 &&
      sub.IsActive &&
      sub.Pourcentage > 0
    ) {
      return true;
    }
  }
  return false;
};

export const getIssue = (row, translate) => {
  const issue = [];
  if (row.CapitalTrading < 10) issue.push(translate('traders.capitalTooLow'));
  if (!row.CanCopyTrade) {
    issue.push(translate('traders.needRebateOrWotSub'));
  }
  if (row.Pourcentage <= 0) issue.push(translate('traders.pourcentageZero'));
  if (!row.IsActive) issue.push(translate('traders.isDisable'));
  if (row.EndDate != null)
    if (new Date(row.EndDate) < new Date())
      issue.push(translate('traders.expiredDate'));
  return issue;
};
