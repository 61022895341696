import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { default as LinkUI } from '@material-ui/core/Link';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
// core components

import sidebarStyle from '../../assets/jss/material-dashboard-react/components/sidebarStyle.jsx';
import NetworkButtons from './NetworkButtons';
import GridItem from '../Grid/GridItem';
import SelectTheme from './SelectTheme';
import LanguageToggle from '../Translate/LanguageToggle';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Box from '@material-ui/core/Box';

const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return routeName?.startsWith(props.location.pathname) || false;
  }
  const handleClick = () => {
    props.collapseMenuActions(!props.collapseMenu);
  };
  const getItem = (prop, key) => {
    const isCollapse = Array.isArray(prop.collapse);
    if (prop.redirect) return null;
    if (!prop.sidebarName) return null;
    const listItemClasses = classNames({
      [' ' + classes[color]]: activeRoute(prop.path),
    });
    const whiteFontClasses = classNames({
      [' ' + classes.whiteFont]: activeRoute(prop.path),
    });
    const collapseIcon = (
      <Box className={classes.itemIconCollapse}>
        {isCollapse ? (
          props.collapseMenu ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )
        ) : (
          <span />
        )}
      </Box>
    );
    const collapse = isCollapse ? (
      <Collapse in={props.collapseMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {prop.collapse.map((prop, key) => {
            return getItem(prop, key);
          })}
        </List>
      </Collapse>
    ) : (
      <div />
    );
    const collapseLevel = prop.collapseLevel ? prop.collapseLevel * 20 : 0;
    const icon =
      typeof prop.icon === 'string' ? (
        <Icon>{prop.icon}</Icon>
      ) : (
        <Box>{prop.icon}</Box>
      );
    const listItem = (
      <ListItem
        button
        className={classes.itemLink + listItemClasses}
        disabled={prop.disabled}
        onClick={isCollapse ? handleClick : null}
        style={{ paddingLeft: collapseLevel + 'px' }}
      >
        <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={prop.sidebarName}
          className={classes.itemText + whiteFontClasses}
          disableTypography={true}
        />
        {collapseIcon}
      </ListItem>
    );
    if (prop.disabled) return <span key={key}>{listItem}</span>;
    if (prop.collapse)
      return (
        <Box key={key}>
          {listItem} {collapse}
        </Box>
      );
    if (prop.path && typeof prop.path === 'string')
      return (
        <NavLink
          to={prop.path}
          className={classes.item}
          activeClassName="active"
          key={key}
        >
          {listItem}
        </NavLink>
      );
    return (
      <LinkUI
        className={classes.item}
        href={prop.link}
        target="_blank"
        key={key}
        rel="noopener noreferrer"
      >
        {listItem}
      </LinkUI>
    );
  };

  const { classes, color, logo, image, logoText, routes } = props;
  const links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        return getItem(prop, key);
      })}
    </List>
  );
  const brand = (
    <div className={classes.logo}>
      <Link to="/" className={classes.logoLink}>
        <div className={classes.logoImage}>
          <img
            src={logo}
            alt="logo"
            className={classes.img}
            style={{ padding: '10px' }}
          />
        </div>
        {logoText}
      </Link>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {/*<HeaderLinks />*/}
            {links}
          </div>
          <Grid container>
            <GridItem xs={6} sm={6} md={6}>
              <LanguageToggle />
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <SelectTheme />
            </GridItem>
          </Grid>
          <NetworkButtons />
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : null}
          <Grid container>
            <GridItem xs={6} sm={6} md={6}>
              <LanguageToggle />
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <SelectTheme />
            </GridItem>
          </Grid>
          <NetworkButtons />
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(sidebarStyle)(Sidebar);
