import React from 'react';
import { push } from 'connected-react-router';
import { Translate } from 'react-localize-redux';
import {
  changeShowDialog,
  changeStep,
} from '../../../redux/onboarding/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupId, getGroupName } from '../../../redux/onboarding/selector';
import Button from '../../../components/CustomButtons/Button';
import GridItem from '../../../components/Grid/GridItem';
import { profilConstant } from '../profil.constant';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ShareIcon from '@material-ui/icons/Share';
import LabelIcon from '@material-ui/icons/Label';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const OnboardingForGroup = () => {
  const dispatch = useDispatch();
  const groupId = useSelector(getGroupId);
  const groupName = useSelector(getGroupName);

  return (
    <Grid container>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h6" align="center">
          <Translate id="onboarding.whatDoYouWantToDo" />
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={1} lg={1} />
      <GridItem xs={12} sm={12} md={10} lg={10}>
        <Button
          fullWidth
          color="primary"
          onClick={() => {
            dispatch(push('/addFollower/' + groupId));
            // dispatch(addFollowerOnboarding());
            dispatch(changeShowDialog(false));
            window.location.reload(true);
          }}
        >
          <AddCircleIcon />
          &nbsp;&nbsp;
          <Translate id="onboarding.addCopierAPIkeys" />
        </Button>
      </GridItem>
      <GridItem xs={12} sm={12} md={1} lg={1} />
      {/*<GridItem xs={12} sm={12} md={1} lg={1} />*/}
      {/*<GridItem xs={12} sm={12} md={10} lg={10}>*/}
      {/*  <Button*/}
      {/*    fullWidth*/}
      {/*    color="primary"*/}
      {/*    onClick={() => {*/}
      {/*      dispatch(changeStep(profilConstant.STEP_SHARE_COMMUNITY));*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <ShareIcon />*/}
      {/*    &nbsp;&nbsp;*/}
      {/*    <Translate*/}
      {/*      id="onboarding.shareGroupLink"*/}
      {/*      data={{ groupName: groupName }}*/}
      {/*      options={{ renderInnerHtml: true }}*/}
      {/*    />*/}
      {/*  </Button>*/}
      {/*</GridItem>*/}
      {/*<GridItem xs={12} sm={12} md={1} lg={1} />*/}
      <GridItem xs={12} sm={12} md={1} lg={1} />
      <GridItem xs={12} sm={12} md={10} lg={10}>
        <Button
          fullWidth
          color="primary"
          onClick={() => {
            dispatch(changeStep(profilConstant.STEP_WHITE_LABEL));
          }}
        >
          <LabelIcon />
          &nbsp;&nbsp;
          <Translate
            id="onboarding.installWhiteLabel"
            options={{ renderInnerHtml: true }}
          />
        </Button>
      </GridItem>
    </Grid>
  );
};

export default OnboardingForGroup;
