import React from 'react';
import Responsive from '../../layouts/Responsive';
import MonthlyStatsDesktop from './MonthlyStats.desktop';
import MonthlyStatsMobile from './MonthlyStats.mobile';

/**
 * Display monthly stats as a table by month in col and years in row on desktop devices
 * Display monthly stats as a table by month in rows and years in col on mobile devices
 * @example stats :{
    '2019': {
      '5': 3.219384777257953,
      '6': 16.437680338780854,
      '7': 3.668927668486575,
      '8': 1.6280215393338666,
      '9': 4.2170027767240015,
      '10': 2.7353354303107573,
      '11': 0.38517770849177224,
      '12': 1.7923472635145479,
    },
    '2020': {
      '1': 2.7285376692963923,
      '2': -1.6377020062404235,
      '3': 2.8790554474061394,
      '4': 5.556634335919063,
      '6': 11.913133255720618,
      '7': 4.098946203285479,
      '8': 10.907318668484656,
      '9': 38.02137448715809,
      '10': 5.252052044852703,
      '11': 2.5139843709125715,
      '12': 5.910506335509456,
    },
    '2021': {
      '1': 0.9001081104371812,
      '2': 6.271426495066559,
      '3': 5.040058182433437,
    },
  }
 */
function MonthlyStats({ stats, groupId }) {
  return (
    <Responsive
      mobile={<MonthlyStatsMobile stats={stats} />}
      desktop={<MonthlyStatsDesktop stats={stats} groupId={groupId} />}
    />
  );
}

export default MonthlyStats;
