import { darkColor, lightColor } from '../../material-dashboard-react.jsx';

const cardStyle = (theme) => ({
  card: {
    border: '0',
    marginBottom: '10px',
    marginTop: '30px',
    borderRadius: '6px',
    color: theme.palette.type === 'dark' ? lightColor : darkColor,
    background: theme.palette.type === 'dark' ? darkColor : lightColor,
    width: '100%',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '0',
    wordWrap: 'break-word',
    fontSize: '.875rem',
  },
  cardPlain: {
    background: 'transparent',
    boxShadow: 'none',
  },
  cardProfile: {
    marginTop: '0px',
    textAlign: 'center',
  },
  cardChart: {
    '& p': {
      marginTop: '0px',
      paddingTop: '0px',
    },
  },
});

export default cardStyle;
