export const myFacebookLink = 'https://www.facebook.com/WallOfTraders';
export const myTwitterLink = 'https://twitter.com/WallOfTraders';
export const myTelegramLinks = {
  fr: 'https://t.me/walloftraders_channel',
  gb: 'https://t.me/walloftraders_channel',
  ru: 'https://t.me/walloftraders_channel',
};
export const tutorialLinks = {
  fr: 'https://walloftraders.com/blog/comment-parametrer-son-compte-pour-faire-du-smart-trading-et-du-copy-trading/',
  gb: 'https://walloftraders.com/blog/en/how-to-set-up-your-account-for-smart-trading-and-copy-trading/',
  ru: 'https://walloftraders.com/blog/ru/%d0%ba%d0%b0%d0%ba-%d0%ba-%d0%b2%d0%b0%d1%88-%d1%81%d1%87%d0%b5%d1%82%d0%b0-%d0%b4%d0%bb%d1%8f-%d1%81%d0%bc%d0%b0%d1%80%d1%82-%d1%82%d0%be%d1%80%d0%b3%d0%be%d0%b2%d0%bb%d0%b8-%d0%bd%d0%b0%d0%b1%d0%be/',
};
export const blogLinks = {
  fr: 'https://walloftraders.com/blog/?lang=fr',
  gb: 'https://walloftraders.com/blog/en',
  ru: 'https://walloftraders.com/blog/ru',
};
