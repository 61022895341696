import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import Card from '../../../components/Card/Card';
import Button from '../../../components/CustomButtons/Button';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { getDateHourStr } from '../../../helpers/general';
import { getProxyListInfos } from '../../../redux/admin/actions';
import { isEmpty, isNull } from '../../../helpers/Common';

class LeaderBoardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { withDetails: false };
  }
  componentDidMount() {
    this.props.getProxyListActions();
  }
  sortLeaderBorardInfos = (rawInfos) => {
    if (isEmpty(rawInfos)) return [];
    rawInfos.sort((a, b) => a.proxy.localeCompare(b.proxy) * -1);
    let withProxy = rawInfos.filter((info) => !isEmpty(info.proxy));
    let withoutProxy = rawInfos.filter((info) => isEmpty(info.proxy));
    return [
      ...withProxy.filter((info) => !isEmpty(info.lastUpdate)),
      ...withProxy.filter((info) => isEmpty(info.lastUpdate)),
      ...withoutProxy,
    ];
  };
  render() {
    const { proxyInfos } = this.props;
    const { withDetails } = this.state;
    const leaderBorardInfos = this.sortLeaderBorardInfos(proxyInfos);
    return (
      <GridContainer>
        <GridItem xs={12} sm={3} md={3} />
        <GridItem xs={12} sm={3} md={3}>
          <Button
            color="primary"
            fullWidth
            onClick={() => this.setState({ withDetails: !withDetails })}
          >
            {withDetails ? 'Without' : 'With'} Details
          </Button>
        </GridItem>
        <GridItem xs={12} sm={3} md={3}>
          <Button
            color="primary"
            fullWidth
            onClick={() => this.props.getProxyListActions()}
          >
            Refresh
          </Button>
        </GridItem>
        <GridItem xs={12} sm={3} md={3} />
        <GridItem xs={12} sm={12} md={12}>
          {!isEmpty(leaderBorardInfos) && (
            <Card>
              <table style={{ borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th>EncryptedUId</th>
                    <th>GroupId</th>
                    <th>Name</th>
                    <th>Capital</th>
                    <th>Orders Counts</th>
                    <th>Proxy</th>
                    <th>LastRequest</th>
                    <th>LastUpdate</th>
                    <th>IsActive</th>
                    <th>Nb Follower</th>
                  </tr>
                </thead>
                <tbody>
                  {leaderBorardInfos.map((leaderBoard) => {
                    return (
                      <>
                        <tr
                          style={{
                            border: 'dotted',
                            borderWidth: '1px 0',
                            verticalAlign: 'top',
                          }}
                          key={leaderBoard.encryptedUId}
                        >
                          <td>
                            <a
                              style={{ color: 'white' }}
                              target="_blank"
                              href={`https://www.binance.com/en/futures-activity/leaderboard/user/um?encryptedUid=${leaderBoard.encryptedUId}`}
                              rel="noreferrer"
                            >
                              {leaderBoard.encryptedUId}
                            </a>
                          </td>
                          <td>{leaderBoard.groupId}</td>
                          <td>{leaderBoard.name}</td>
                          <td>{leaderBoard.capital}</td>
                          <td style={{ textAlign: 'center' }}>
                            {isEmpty(leaderBoard.ordersCount) && <>None</>}
                            {!isEmpty(leaderBoard.ordersCount) && (
                              <>
                                {withDetails === true &&
                                  Object.entries(leaderBoard.ordersCount).map(
                                    (oui, key) => {
                                      if (oui.length !== 2)
                                        return <div key={key}>None</div>;
                                      return (
                                        <div key={key}>
                                          {oui[0].split('-USDT')[0]}: Long:{' '}
                                          {oui[1].item1} | Short: {oui[1].item2}
                                        </div>
                                      );
                                    },
                                  )}
                                {withDetails === false && (
                                  <>
                                    {Object.entries(leaderBoard.ordersCount)
                                      .length === 1 &&
                                      Object.entries(
                                        leaderBoard.ordersCount,
                                      )[0][0] +
                                        ': Long: ' +
                                        Object.entries(
                                          leaderBoard.ordersCount,
                                        )[0][1].item1 +
                                        ' Short: ' +
                                        Object.entries(
                                          leaderBoard.ordersCount,
                                        )[0][1].item2}
                                    {Object.entries(leaderBoard.ordersCount)
                                      .length !== 1 && (
                                      <>
                                        Orders Count: &nbsp;
                                        {Object.entries(leaderBoard.ordersCount)
                                          .map((oui) => {
                                            let longCount = oui[1].item1;
                                            let shortCount = oui[1].item2;
                                            return longCount + shortCount;
                                          })
                                          .reduce(function (a, b) {
                                            return parseInt(a) + parseInt(b);
                                          }, 0)}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </td>
                          <td>
                            {isEmpty(leaderBoard.proxy)
                              ? 'none'
                              : leaderBoard.proxy}
                          </td>
                          <td>
                            {isNull(leaderBoard.lastRequest)
                              ? 'null'
                              : getDateHourStr(leaderBoard.lastRequest)}
                          </td>
                          <td>
                            {isNull(leaderBoard.lastUpdate)
                              ? 'null'
                              : getDateHourStr(leaderBoard.lastUpdate)}
                          </td>
                          <td>{leaderBoard.isActive ? 'True' : 'False'}</td>
                          {withDetails === false && (
                            <td>{leaderBoard.woTFollowers.length}</td>
                          )}
                          {withDetails === true && (
                            <td>{leaderBoard.woTFollowers.join(', ')}</td>
                          )}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    proxyInfos: admin.leaderBoardProxys,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProxyListActions: bindActionCreators(getProxyListInfos, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaderBoardPage);
