import React from 'react';
import { Help } from '@material-ui/icons';
import MyTooltip from '../MyTooltip';

class Helper extends React.PureComponent {
  render() {
    const { text, disabled } = this.props;
    if (disabled) return <div />;
    return (
      <MyTooltip text={text}>
        <Help style={{ cursor: 'pointer' }} fontSize={'small'} />
      </MyTooltip>
    );
  }
}

export default Helper;
