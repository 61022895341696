import { getActiveLanguage } from 'react-localize-redux';
import moment from 'moment';
import { changeLang } from '../redux/authentication/actions';
import { myTelegramLinks } from './externalLink';

export const getDefaultLanguage = () => {
  const codeLang = localStorage.getItem('codeLang');
  if (codeLang) return codeLang;
  return 'gb';
};

// export const getCodeFromLanguages = languages => {
//   let codeLang = defaultLanguage;
//   const lang = languages.filter(x => x.active === true);
//   if (lang && lang[0] && lang[0].hasOwn('code'))
//     codeLang = lang[0].code;
//   return codeLang;
// };

export const getDateToFormat = (date, lang) => {
  return moment(date).format(getDateFormat(lang));
};

export const formatDateEN = 'YYYY/MM/DD HH:mm';
export const getDateFormat = (lang) => {
  const l = lang === 'en' ? 'gb' : lang;
  return getTabLanguages()[getIndexLang(l)].formatDate;
};
export const getIndexLang = (lang) => {
  return lang === 'fr' ? 0 : lang === 'ru' ? 2 : 1;
};

export const getTabLanguages = () => {
  return [
    { name: 'Français', code: 'fr', formatDate: 'DD/MM/YYYY HH:mm' },
    { name: 'English', code: 'gb', formatDate: formatDateEN },
    { name: 'Pусский', code: 'ru', formatDate: 'DD/MM/YYYY HH:mm' },
  ];
};

export const setActiveCode = (code) => {
  localStorage.setItem('codeLang', code);
  changeLang(code);
};

export const getActiveCode = (state) => {
  const { localize } = state;
  const acL = getActiveLanguage(localize);
  return acL && Object.hasOwn(acL, 'code') ? acL.code : getDefaultLanguage();
};

export const getTelegramChannel = (getActiveCode) => {
  return Object.hasOwn(myTelegramLinks, getActiveCode)
    ? myTelegramLinks[getActiveCode]
    : myTelegramLinks[1]; // Default telegram
};
