import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import ReactTextField from '../Form/ReactTextFieldUI';
// import { Field } from 'redux-form-normalize-on-blur';
import { Field } from 'redux-form';
import {
  parseToFloat,
  // roundStepValue
} from '../../helpers/Common';

class Price extends React.PureComponent {
  render() {
    const { endAdornment, name, step } = this.props; //min, max,
    const s = step !== undefined ? step : 0.01;
    // const miV = min !== undefined ? min : 0;
    // const between = (min, max, value) => {
    //   if (min !== '' && parseFloat(value) < parseFloat(min)) return min;
    //   if (max !== undefined && parseFloat(value) > parseFloat(max)) return max;
    //   return value;
    // };
    // const between = (min, max, value) => {
    //   if (
    //     (min !== '' && parseFloat(value) < parseFloat(min)) ||
    //     (max !== '' && parseFloat(value) > parseFloat(max))
    //   )
    //     return `Should be between ${min} and ${max}`;
    //   return '';
    // };
    // const roundStep = (step, value) => {
    //   // console.log('step', step, value);
    //   // value = parseFloat(value);
    //   return roundStepValue(value, step);
    // };
    return (
      <Field
        className="form-control"
        name={name}
        component={ReactTextField}
        fullWidth={true}
        type="number"
        parse={(value) => parseToFloat(value, s)}
        // validate={value => between(miV, max, value)}
        // normalize={value => roundStep(step, value)}
        // normalizeOnBlur={value => value && value.trim()}
        // normalizeOnBlur={value => between(miV, max, value)}
        inputProps={{ step: s }}
        InputProps={{
          endAdornment: endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : (
            ''
          ),
        }}
        required
        {...this.props}
      />
    );
  }
}

export default Price;
