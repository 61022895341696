import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getExchange,
  postExchanges,
  updateExchanges,
} from '../../../redux/user/actions';
import FormExchange from './FormExchange';
import { ShowTuto } from './TutoExchange';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import { Translate, withLocalize } from 'react-localize-redux';
import transExchanges from '../../../locales/exchanges.json';
import { isNull } from '../../../helpers/Common';
import { formatDataForPost } from '../../../helpers/exchange';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

class ExchangesPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transExchanges);
  }
  componentDidMount() {
    const { match } = this.props;
    const id = match.params.id;
    if (!isNull(id)) this.props.getExchangeActions(id);
  }
  addExchange = async () => {
    const exchangeUser = formatDataForPost(this.props.formExchange.values);
    if (exchangeUser.isUpdate) {
      this.props.putExchangesActions(exchangeUser);
    } else {
      this.props.postExchangesActions(exchangeUser);
    }
  };

  render() {
    const { exchanges, match, classes, theme } = this.props;
    let { exchange } = this.props;
    const paramId = parseInt(match.params.id);
    if (!isNaN(paramId) && isNull(exchange)) return <div>Loading...</div>;
    if (isNull(exchange) || exchange.Id !== paramId)
      exchange = !isNull(exchanges)
        ? exchanges.find((x) => x.Id === paramId)
        : null;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                <Translate id="exchanges.addExchange" />
              </h4>
              <p className={classes.cardCategoryWhite}>
                <Translate id="exchanges.connect" />
              </p>
            </CardHeader>
            <CardBody>
              <FormExchange
                handleSubmit={this.addExchange}
                exchange={exchange}
              />
            </CardBody>
          </Card>
        </GridItem>
        {ShowTuto('Binance', theme)}
      </GridContainer>
    );
  }
}

ExchangesPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { form, user, config } = state;
  return {
    exchanges: user.exchanges,
    exchange: user.exchange,
    theme: config.theme,
    formExchange: form.formExchange,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postExchangesActions: bindActionCreators(postExchanges, dispatch),
    putExchangesActions: bindActionCreators(updateExchanges, dispatch),
    getExchangeActions: bindActionCreators(getExchange, dispatch),
  };
};

export default withLocalize(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(ExchangesPage),
  ),
);
