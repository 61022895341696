import React from 'react';
import { getAveragePrice, getAverageProfit, getTriggersDone } from '../../../helpers/order';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import connect from 'react-redux/es/connect/connect';
import { Collapse } from '@material-ui/core';
import { change, formValueSelector } from 'redux-form';
import Price from '../../../components/Fields/Price';
import Percentage from '../../../components/Fields/Percentage';
import { bindActionCreators } from 'redux';
import LittleButton from '../../../components/CustomButtons/LittleButton';
import { ArrowDropDown, ArrowLeft } from '@material-ui/icons';
import {
  getPrimFromPair,
  getSecFromPair,
  isEmpty, parseToFloat, stepPriceSelector
} from '../../../helpers/Common';
import TriggerLineContainer from './TriggerLineContainer';
import { getActiveCode } from '../../../helpers/localize';

class TriggerDoneLineContainer extends React.PureComponent {
  state = {
    collapsed: false,
  };
  handleChange = () => {
    this.setState((state) => ({ collapsed: !state.collapsed }));
  };
  getTriggersDone = () => {
    const { triggers } = this.props;
    const result = [];
    if (!triggers || triggers.length === 0) return result;
    for (const [index, trigger] of Object.entries(triggers)) {
      trigger.index = index;
      if (trigger.isDone) result.push(trigger);
    }
    return result;
  };
  setAverageInfos = () => {
    const {
      name,
      triggers,
      formName,
      totalDonePercent,
      averageDoneProfit,
      averageDonePrice,
    } = this.props;
    if (
      triggers.length === 0 ||
      (totalDonePercent === undefined ||
        averageDoneProfit === undefined ||
        averageDonePrice === undefined) === false
    )
      return;
    const totalPercent = triggers.reduce(
      (total, trigger) => total + trigger.pourcentage,
      0,
    );
    const triggersDone = getTriggersDone(triggers);

    const averageProfit = (100 * getAverageProfit(triggersDone)) / totalPercent;
    const averagePrice = (100 * getAveragePrice(triggersDone)) / totalPercent;

    if (totalDonePercent !== totalPercent)
      this.props.change(formName, `${name}totalDonePercent`, totalPercent);
    if (averageDoneProfit !== averageProfit)
      this.props.change(formName, `${name}averageDoneProfit`, averageProfit);
    if (averageDonePrice !== averagePrice)
      this.props.change(formName, `${name}averageDonePrice`, averagePrice);
  };
  componentDidUpdate(prevProps) {
    const { triggers } = this.props;
    if (triggers === prevProps.triggers || triggers.length === 0)
      return;
    this.setAverageInfos();
  }
  haveOptions = (trigger) => {
    return (
      (trigger.trailingCB && !isEmpty(trigger.trailing)) ||
      (trigger.tradingViewCB && !isEmpty(trigger.tradingViewGuid)) ||
      (trigger.triggerCB && !isEmpty(trigger.triggerPrice)) ||
      (trigger.timeOutCB && !isEmpty(trigger.timeOut))
    );
  };
  render() {
    const { collapsed } = this.state;
    const { name, triggers, pair } =
      this.props;
    const triggersDone = getTriggersDone(triggers);
    if (triggersDone.length === 0) return null;
    const isStart = name === 'starts';

    const prim = getPrimFromPair(pair);
    const sec = getSecFromPair(pair);
    const endAdornment = isStart ? sec + '/' + prim : sec;

    const priceLength = isStart ? 8 : 5;
    if (triggersDone.length === 1) this.setState({ collapsed: true });
    else this.setAverageInfos();
    return (
      <>
        {triggersDone.length > 1 && (
          <GridContainer>
            <GridItem xs={3} sm={3} md={3}>
              <Percentage
                className="form-control"
                name={`${name}totalDonePercent`}
                disabled
              />
            </GridItem>
            <GridItem
              xs={priceLength}
              sm={priceLength}
              md={priceLength}
              style={{ marginLeft: '-10px' }}
            >
              <Price
                className="form-control"
                name={`${name}averageDonePrice`}
                endAdornment={endAdornment}
                disabled
              />
            </GridItem>
            {!isStart && (
              <GridItem xs={3} sm={3} md={3} style={{ marginLeft: '-10px' }}>
                <Percentage
                  className="form-control"
                  name={`${name}averageDoneProfit`}
                  disabled
                />
              </GridItem>
            )}
            <GridItem xs={1} sm={1} md={1}>
              <LittleButton
                color="info"
                onClick={this.handleChange}
                icon={collapsed ? <ArrowDropDown /> : <ArrowLeft />}
              />
            </GridItem>
          </GridContainer>
        )}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Collapse in={collapsed}>
              {triggers.map((trigger, index) => {
                if (!trigger.isDone) return;
                return (<TriggerLineContainer key={index} index={index} trigger={trigger} {...this.props} />);
              })}
            </Collapse>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  return {
    pair: formSelector(state, 'pair'),
    totalDonePercent: formSelector(state, `${props.name}totalDonePercent`),
    averageDoneProfit: formSelector(state, `${props.name}averageDoneProfit`),
    averageDonePrice: formSelector(state, `${props.name}averageDonePrice`),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TriggerDoneLineContainer);
