import React from 'react';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem';

import { withLocalize } from 'react-localize-redux';
import { Translate } from 'react-localize-redux';

import transSmartTrade from '../../locales/smartTrade.json';
import transOrders from '../../locales/orders.json';
import transError from '../../locales/error.json';
import transConfirm from '../../locales/confirmation.json';
import transReferrals from '../../locales/referrals.json';
import transTraders from '../../locales/traders.json';
import Referrals from './Referrals';
import transTrader from '../../locales/traders.json';
import transCentral from '../../locales/central.json';
import transConfirmation from '../../locales/confirmation.json';
import transExchanges from '../../locales/exchanges.json';

class ReferralsPage extends React.Component {
  constructor(props) {
    super(props);

    this.props.addTranslation(transTrader);
    this.props.addTranslation(transCentral);
    this.props.addTranslation(transConfirmation);
    this.props.addTranslation(transExchanges);
    this.props.addTranslation(transError);
    this.props.addTranslation(transOrders);
    this.props.addTranslation(transSmartTrade);
    this.props.addTranslation(transConfirm);
    this.props.addTranslation(transReferrals);
    this.props.addTranslation(transTraders);
  }

  render() {
    return (
      <GridContainer>
        <GridItem container>
          <GridItem container xs={12} sm={6} md={6} alignItems="center">
            <h1 style={{ margin: 0 }}>
              <Translate id="menu.yourReferralLinks" />
            </h1>
          </GridItem>
        </GridItem>
        <Referrals />
      </GridContainer>
    );
  }
}

export default withLocalize(ReferralsPage);
