import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import GridItem from '../../components/Grid/GridItem.jsx';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { getSubGroup } from '../../helpers/trader';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from '../../helpers/Common';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import { successColor } from '../../assets/jss/material-dashboard-react';
import { bindActionCreators } from 'redux';
import { getTraders } from '../../redux/trader/actions';
import { getOrders } from '../../redux/order/actions';
import { push } from 'connected-react-router';
import { isLogged, nbColumn } from '../../helpers/general';
import { tradersSelector } from '../../redux/trader/selector';
import { withWidth } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

class InitSteppers extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      close: false,
    };
  }
  componentDidMount = () => {
    // console.log(this.props.authentication, isLogged(this.props.authentication), this.props.authentication.auth);
    // if (isLogged(this.props.authentication)) {
    //   this.props.getTradersActions();
    //   this.props.getOrdersActions(0); //All
    // }
  };

  render() {
    const { classes, auth, exchanges, orders, traders, redirect } = this.props;
    const { close } = this.state;

    const isMobile = nbColumn(this.props.width) < 2;
    const hasAccount = exchanges && exchanges.length > 0;
    const hasTelegram = auth && !isEmpty(auth.telegram);
    let hasTrader = false;
    for (const trader of traders) {
      const sub = getSubGroup(trader);
      if (sub !== null && sub.GroupId !== 1) {
        hasTrader = true;
        break;
      }
    }
    const hasSmartTrade = orders && orders.length > 0;
    const activeStep = !hasAccount ? 0 : !hasTelegram ? 1 : 2;
    if (close || (hasAccount && hasTelegram && (hasTrader || hasSmartTrade))) {
      localStorage.setItem('InitSteppers', 'false');
      return <div />;
    }

    return (
      <GridItem xs={12} sm={12} md={12} style={{ marginBottom: '10px' }}>
        <div className={classes.root} style={{ marginTop: '-24px' }}>
          <div
            style={{
              position: 'relative',
              float: 'right',
              top: '18px',
              right: '-10px',
            }}
          >
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                localStorage.setItem('InitSteppers', 'false');
                this.setState({ close: true });
              }}
            >
              <Cancel />
            </span>
          </div>
          <Stepper
            orientation={isMobile ? 'vertical' : 'horizontal'}
            nonLinear
            activeStep={activeStep}
            className={classes.root}
          >
            <Step>
              <StepButton
                onClick={() => redirect('/exchanges')}
                icon={hasAccount ? <CheckCircle htmlColor={successColor} /> : 1}
              >
                <Translate id="menu.addExchange" />
              </StepButton>
            </Step>
            <Step>
              <StepButton
                onClick={() => redirect('/settings')}
                icon={
                  hasTelegram ? <CheckCircle htmlColor={successColor} /> : 2
                }
              >
                <Translate id="menu.linkTelegram" />
              </StepButton>
            </Step>
            <Step>
              <StepButton
                onClick={() => redirect('/traders')}
                icon={hasTrader ? <CheckCircle htmlColor={successColor} /> : 3}
              >
                <Translate id="menu.addTrader" />
              </StepButton>
            </Step>
            <Step>
              <StepButton
                onClick={() => redirect('/smartTrade')}
                icon={
                  hasSmartTrade ? <CheckCircle htmlColor={successColor} /> : 3
                }
              >
                <Translate id="menu.createSmartTrade" />
              </StepButton>
            </Step>
          </Stepper>
        </div>
      </GridItem>
    );
  }
}

InitSteppers.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { user, authentication, order } = state;
  return {
    auth: authentication.auth,
    exchanges: user.exchanges,
    orders: order.orders,
    traders: tradersSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTradersActions: bindActionCreators(getTraders, dispatch),
    getOrdersActions: bindActionCreators(getOrders, dispatch),
    redirect: bindActionCreators(push, dispatch),
  };
};

export default withWidth()(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(InitSteppers),
  ),
);
