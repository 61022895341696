import React from 'react';
import SwitchUI from '@material-ui/core/Switch/Switch';
import { bindActionCreators } from 'redux';
import { changeTheme } from '../../redux/config/actions';
import connect from 'react-redux/es/connect/connect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faLightbulb } from '@fortawesome/free-solid-svg-icons';

class SelectTheme extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      theme: props.theme,
    };
  }

  handleChangeTheme = () => (event) => {
    const val = !event.target.checked ? 'light' : 'dark';
    this.setState({ theme: val });
    this.props.changeTheme(val);
  };

  render() {
    const { theme } = this.props;
    return (
      <div style={{ float: 'right' }}>
        <FontAwesomeIcon icon={faLightbulb} />
        <SwitchUI
          checked={theme === 'dark'}
          onChange={this.handleChangeTheme()}
          value="theme"
          color="primary"
          // disabled
        />
        <FontAwesomeIcon icon={faMoon} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { config } = state;
  return {
    theme: config.theme,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeTheme: bindActionCreators(changeTheme, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectTheme);
