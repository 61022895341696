import React from 'react';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';

import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { getExchange } from '../../../redux/user/actions';
import TableExchange from './TableExchange';
import ExchangeContainer from '../ExchangeContainer';
import transExchanges from '../../../locales/exchanges.json';
import { withLocalize } from 'react-localize-redux';
import LineChartWallet from '../../../components/Charts/LineChartWallet';
import { isNull } from '../../../helpers/Common';
import Responsive from '../../../layouts/Responsive.jsx';

class ExchangePage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transExchanges);
  }
  componentDidMount() {
    const { match } = this.props;
    const id = match.params.id;
    if (!isNull(id)) this.props.getExchangeActions(id, false);
  }
  render() {
    const { match } = this.props;
    const id = match.params.id;
    let { exchange } = this.props;
    if (isNull(exchange)) return <div />;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={4} xl={3}>
          <ExchangeContainer exchange={exchange} />
        </GridItem>
        {exchange && (
          <Responsive
            desktop={
              <GridItem xs={12} sm={12} md={12} lg={8} xl={9}>
                <LineChartWallet id={id} />
              </GridItem>
            }
          />
        )}
        <GridItem xs={12} sm={12} md={12}>
          <TableExchange exchange={exchange} />
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    exchange: user.exchange,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    getExchangeActions: bindActionCreators(getExchange, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(ExchangePage),
);
