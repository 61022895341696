import React from 'react';

import { connect } from 'react-redux';
import { getTranslate, Translate } from 'react-localize-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import typographyStyle from '../../assets/jss/material-dashboard-react/components/typographyStyle.jsx';

import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader.jsx';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { getDateStr } from '../../helpers/general';
import PieChartExchange from '../../components/Charts/PieChartExchange';
import { getIssue } from '../../helpers/trader';
import Done from '@material-ui/icons/Done';
import {
  dangerColor,
  successColor,
} from '../../assets/jss/material-dashboard-react';
import Close from '@material-ui/icons/Close';
import Danger from '../../components/Typography/Danger';
import { FollowerButtons } from './FollowerButtons.jsx';

class FollowerContainer extends React.PureComponent {
  render() {
    const { follower, groupId, translate } = this.props;
    const issue = getIssue(follower, translate);
    const good = issue.length === 0;
    if (!follower) return <div />;

    return (
      <Card>
        <CardHeader color="primary">
          <GridContainer alignItems="center">
            <GridItem xs>
              {good && <Done htmlColor={successColor} />}
              {!good && <Close htmlColor={dangerColor} />}
              <b>{follower.UserName}</b>
            </GridItem>
            <GridItem
              xs
              container
              alignItems="center"
              justifyContent="flex-end"
            >
              <FollowerButtons groupId={groupId} user={follower} />
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={5} sm={5} md={5}>
              {follower.Exchange && (
                <PieChartExchange exchange={follower.Exchange} />
              )}
            </GridItem>
            <GridItem container alignItems="center" xs={7} sm={7} md={7}>
              <GridContainer>
                <GridItem container alignItems="center">
                  <Translate id="traders.tradedCapital" />: &nbsp;
                  <b>{parseFloat(follower.CapitalTrading.toFixed(0))} $</b>
                </GridItem>
                <GridItem container alignItems="center">
                  <Translate id="traders.realCapital" />: &nbsp;
                  <b>
                    {follower.ReelCapital !== null
                      ? parseFloat(follower.ReelCapital.toFixed(0))
                      : ''}{' '}
                    $
                  </b>
                </GridItem>
                <GridItem container alignItems="center">
                  <Translate id="traders.numberOfTrades" />: &nbsp;
                  <b>{follower.NbOpenOrders}</b>
                </GridItem>
                <GridItem container alignItems="center">
                  <Translate id="traders.endDateWOT" />: &nbsp;
                  <b>
                    {follower.WotDate === null
                      ? ''
                      : getDateStr(follower.WotDate)}
                  </b>
                </GridItem>
                <GridItem container alignItems="center">
                  <Translate id="traders.endDate" />: &nbsp;
                  <b>
                    {follower.EndDate === null
                      ? translate('traders.noEndDate')
                      : getDateStr(follower.EndDate)}
                  </b>
                </GridItem>
                <GridItem container alignItems="center">
                  <Translate id="central.note" />: &nbsp;
                  <b>{follower.Note}</b>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem container alignItems="center" xs={12} sm={12} md={12}>
              <GridItem container alignItems="center">
                <ul>
                  {issue.map((e, key) => (
                    <li key={key}>
                      <Danger>{e}</Danger>
                    </li>
                  ))}
                </ul>
              </GridItem>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { localize } = state;
  const translate = getTranslate(localize);
  return {
    translate,
  };
};

export default withStyles(typographyStyle)(
  connect(mapStateToProps, null)(FollowerContainer),
);
