import React from 'react';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import NotificationsContainer from '../Settings/NotificationsContainer.jsx';
import useTranslation from '../../hooks/useTranslation.js';
import settingsjson from '../../locales/settings.json';

const UnsubscribeEmail = () => {
  useTranslation(settingsjson);
  return (
    <Card>
      <CardBody>
        <NotificationsContainer />
      </CardBody>
    </Card>
  );
};

export default UnsubscribeEmail;
