import React from 'react';
import { connect } from 'react-redux';

import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';

import { bindActionCreators } from 'redux';
import {
  postSubGroups,
  IsAdmin,
  getSubscriptionGroup,
} from '../../../redux/admin/actions';
import Input from '@material-ui/core/Input';
import Button from '../../../components/CustomButtons/Button';
import TableSubGroups from './TableSubGroups';

class SubGroupsPage extends React.Component {
  state = {
    value: 'adrien.bozzano@gmail.com\t12/07/2025\t1\t100\tTRUE',
  };
  componentDidMount() {
    this.props.IsAdminActions();
    this.props.getSubscriptionGroupActions();
  }
  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };
  postSub = () => {
    this.props.postSubGroupsActions({ data: this.state.value });
  };
  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <span>
            Rappel: email \t endDate(jj/mm/aaaa) \t groupId(1) \t pourcentage \t
            IsFullAuto
          </span>
          <Input
            value={this.state.value}
            multiline={true}
            fullWidth={true}
            minRows={25}
            onChange={(e) => this.handleChange(e)}
          />
          <Button onClick={() => this.postSub()}>Envoyer</Button>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <TableSubGroups subsGroups={this.props.subsGroups} />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    subsGroups: admin.subscriptionsGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postSubGroupsActions: bindActionCreators(postSubGroups, dispatch),
    getSubscriptionGroupActions: bindActionCreators(
      getSubscriptionGroup,
      dispatch,
    ),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
  };
};

const connectedUsers = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubGroupsPage);
export default connectedUsers;
