import React from 'react';
import { connect } from 'react-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';

import { bindActionCreators } from 'redux';
import {
  getTradingSummaries,
  getUsersVolume,
  IsAdmin,
} from '../../../redux/admin/actions';
import TableVolumes from './TableVolumes';
import TextField from '@material-ui/core/TextField';
import Button from '../../../components/CustomButtons/Button';
import StackedVolume from './StackedVolume';
import ShowDetails from './ShowDetails';

class VolumesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFrom: null,
      dateTo: null,
    };
  }
  componentDidMount() {
    const today = new Date();
    const lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 7,
    ).toLocaleDateString();

    this.props.IsAdminActions();
    this.props.getTradingSummariesActions(lastWeek, this.state.dateTo);
    this.props.getUsersVolumeActions(lastWeek, this.state.dateTo);
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { tradingSummaries, usersVolume, availableAmounts } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <TextField
            id="dateFrom"
            name="dateFrom"
            label="From"
            type="date"
            onChange={(e) => this.handleChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="dateTo"
            name="dateTo"
            label="to"
            type="date"
            onChange={(e) => this.handleChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            onClick={() => {
              this.props.getTradingSummariesActions(
                this.state.dateFrom,
                this.state.dateTo,
              );
              this.props.getUsersVolumeActions(
                this.state.dateFrom,
                this.state.dateTo,
              );
            }}
          >
            OK
          </Button>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <h3>Volume Referral USD</h3>
          {usersVolume.length > 0 && (
            <StackedVolume name={'usersVolume'} data={[...usersVolume]} />
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <ShowDetails
            tradingSummaries={tradingSummaries}
            availableAmounts={availableAmounts}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {tradingSummaries.length !== 0 && (
            <TableVolumes tradingSummaries={tradingSummaries} />
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin, order } = state;
  return {
    availableAmounts: order.availableAmounts,
    tradingSummaries: admin.tradingSummaries,
    usersVolume: admin.usersVolume,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
    getUsersVolumeActions: bindActionCreators(getUsersVolume, dispatch),
    getTradingSummariesActions: bindActionCreators(
      getTradingSummaries,
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VolumesPage);
