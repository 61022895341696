import React from 'react';
import { connect } from 'react-redux';
import {
  formValueSelector,
  change,
  getFormValues,
  getFormSyncErrors,
  touch,
} from 'redux-form';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { bindActionCreators } from 'redux';
import {
  getPourcentageNet,
  getPriceFromProfit,
  getProfitFromPrice,
  isEmpty,
  isNull,
  parseToFloat,
  stepPriceSelector,
} from '../../../helpers/Common';
import { Translate } from 'react-localize-redux';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import SwitchUI from '@material-ui/core/Switch/Switch';
import Button from '../../../components/CustomButtons/Button.jsx';
import TrendingUp from '@material-ui/icons/TrendingUp';
import TriggerLinesContainer from './TriggerLinesContainer';
import { dangerColor } from '../../../assets/jss/material-dashboard-react';

class TakeProfitsContainer extends React.PureComponent {
  changeAndTouch = (formname, name, value) => {
    const { change, touch } = this.props;
    change(formname, name, value);
    touch(formname, name);
  };

  componentDidUpdate = (prevProps) => {
    const { formName, targets, startAveragePrice, isBuy, stepPrice } =
      this.props;
    const { targets: prevTargets } = prevProps;

    if (isNull(targets) || isNull(prevTargets)) return;

    for (let i = 0; i < targets.length; i++) {
      const actTarget = targets[i];
      const prevTarget = prevTargets[i];

      if (
        isEmpty(actTarget) ||
        actTarget.isDone ||
        isEmpty(prevTarget) ||
        isEmpty(startAveragePrice)
      )
        continue;

      const nPrice = parseFloat(
        getPriceFromProfit(actTarget.profit, isBuy, startAveragePrice),
      );
      const newPrice = !isEmpty(nPrice)
        ? parseToFloat(nPrice, stepPrice)
        : null;
      const nProfit = parseFloat(
        getProfitFromPrice(actTarget.price, isBuy, startAveragePrice),
      );
      const newProfit = !isEmpty(nProfit) ? parseToFloat(nProfit, 0.01) : null;

      if (
        isBuy !== prevProps.isBuy ||
        startAveragePrice !== prevProps.startAveragePrice
      ) {
        if (actTarget.lastUpdate === 'profit') {
          this.changeAndTouch(
            formName,
            `targets.${i}.price`,
            newPrice === 0 ? undefined : newPrice,
          );
        } else if (actTarget.lastUpdate === 'price') {
          this.changeAndTouch(
            formName,
            `targets.${i}.profit`,
            newProfit === 0 ? undefined : newProfit,
          );
        }
      } else if (
        actTarget.price !== prevTarget.price &&
        newPrice !== actTarget.price
      ) {
        this.changeAndTouch(
          formName,
          `targets.${i}.profit`,
          newProfit === 0 ? undefined : newProfit,
        );
      } else if (
        actTarget.profit !== prevTarget.profit &&
        newProfit !== actTarget.profit
      ) {
        this.changeAndTouch(
          formName,
          `targets.${i}.price`,
          newPrice === 0 ? undefined : newPrice,
        );
      }
      const nPriceTrailing = parseFloat(
        getPriceFromProfit(-1 * actTarget.trailing, isBuy, actTarget.price),
      );
      const newPriceTrailing = !isEmpty(nPriceTrailing)
        ? parseToFloat(nPriceTrailing, stepPrice)
        : null;
      const nProfitTrailing =
        -1 *
        parseFloat(
          getProfitFromPrice(actTarget.trailingPrice, isBuy, actTarget.price),
        );
      const newProfitTrailing = !isEmpty(nProfitTrailing)
        ? parseToFloat(nProfitTrailing, 0.01)
        : null;

      if (
        actTarget.price !== prevTarget.price ||
        (actTarget.trailing !== prevTarget.trailing &&
          newProfitTrailing !== actTarget.trailing)
      ) {
        this.changeAndTouch(
          formName,
          `targets.${i}.trailingPrice`,
          newPriceTrailing === 0 ? undefined : newPriceTrailing,
        );
      } else if (
        actTarget.trailingPrice !== prevTarget.trailingPrice &&
        newPriceTrailing !== actTarget.trailingPrice
      ) {
        this.changeAndTouch(
          formName,
          `targets.${i}.trailing`,
          newProfitTrailing === 0 ? undefined : newProfitTrailing,
        );
      }
    }
  };

  toggleLock = () => {
    const { formName, change, targetsLock } = this.props;
    change(formName, 'targetsLock', !targetsLock);
  };

  render() {
    const { formName, values, targetsLock, percentageNet, syncErrors } =
      this.props;
    const isBuy = values && values.isBuy;

    const errorsList =
      syncErrors && syncErrors.targets ? syncErrors.targets.split('\n') : [];

    return (
      <GridItem xs={12} sm={12} md={4}>
        <Card className="smart-trade-bloc">
          <CardBody>
            <fieldset
              disabled={targetsLock}
              className={targetsLock ? 'disabled' : ''}
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {isBuy ? (
                    <Translate id="smartTrade.targetSell" />
                  ) : (
                    <Translate id="smartTrade.targetBuy" />
                  )}
                  <SwitchUI
                    checked={!targetsLock}
                    onChange={() => this.toggleLock()}
                    color="primary"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TriggerLinesContainer name="targets" formName={formName} />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ textAlign: 'right' }}>
                    <Translate id="smartTrade.netPercentage" />: {percentageNet}
                    %
                  </div>
                </GridItem>
              </GridContainer>
            </fieldset>
            {!isEmpty(errorsList) && (
              <>
                {errorsList.map((error, i) => (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    key={i}
                    style={{ color: dangerColor }}
                  >
                    {error}
                  </GridItem>
                ))}
              </>
            )}
          </CardBody>
          {targetsLock && (
            <div className="active-block">
              <Button
                color="primary"
                style={{ width: '200px' }}
                onClick={() => this.toggleLock()}
              >
                <TrendingUp style={{ margin: '4px' }} />
                <Translate id="smartTrade.setTargetProfit" />
              </Button>
            </div>
          )}
        </Card>
      </GridItem>
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  return {
    values: getFormValues(props.formName)(state),
    targetsLock: formSelector(state, 'targetsLock'),
    percentageNet: getPourcentageNet(state),
    trailingCB: formSelector(state, 'trailingCB'),
    syncErrors: getFormSyncErrors(props.formName)(state),
    targets: formSelector(state, 'targets'),
    startAveragePrice: formSelector(state, 'startAveragePrice'),
    stepPrice: stepPriceSelector(state, props),
    isBuy: formSelector(state, 'isBuy'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    touch: bindActionCreators(touch, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TakeProfitsContainer);
