import React from 'react';
import { connect } from 'react-redux';
import GridContainer from '../../../components/Grid/GridContainer';
import {
  currentPriceSelector,
  emptyTriggerModel,
  getPriceFromProfit,
  getPrimFromPair,
  getSecFromPair,
  isBinanceFuture,
  isEmpty, isFuture,
  isNull,
  parseToFloat,
  stepPriceSelector
} from '../../../helpers/Common';
import { Field, change, formValueSelector, touch } from 'redux-form';
import { Translate, getTranslate } from 'react-localize-redux';
import LittleButton from '../../../components/CustomButtons/LittleButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import GridItem from '../../../components/Grid/GridItem';
import Percentage from '../../../components/Fields/Percentage';
import Price from '../../../components/Fields/Price';
import { bindActionCreators } from 'redux';
import LimitMarketButton from './LimitMarketButton';
import { SmallCheckBox } from '../../../components/Fields/SmallCheckBox';
import LabelCheckbox from '../../../components/Form/LabelCheckbox';
import Helper from '../../../components/Hover/Helper';
import { getUrl } from '../../../helpers/fetcher';
import { v4 as uuidv4 } from 'uuid';
import { createTradingViewString } from '../../../redux/order/actions';
import MyDateTimePicker from '../../../components/Fields/MyDateTimePicker';
import CopyField from '../../../components/Fields/CopyField';
import { Divider, withWidth } from '@material-ui/core';
import { getActiveCode } from '../../../helpers/localize';
import { getPourcentage } from '../../../helpers/order';

class TriggerLineContainer extends React.PureComponent {
  changeAndTouch = (formName, name, value) => {
    this.props.change(formName, name, value);
    this.props.touch(formName, name);
  };
  addNewTrigger = () => {
    const {
      formName,
      name,
      currentPrice,
      triggers,
      isBuy,
      stepPrice,
      startAveragePrice,
    } = this.props;
    const isStart = name === 'starts';
    const isStop = name === 'stops';
    const triggersNotDone = triggers.filter((trigger) => !trigger.isDone);
    const length = triggers.length;
    const lengthNotDone = triggersNotDone.length;
    const newTrigger = emptyTriggerModel;
    let price = triggers[length - 1].price;
    if (isStart) price = currentPrice;
    else {
      newTrigger.profit =
        triggers[length - 1].profit + (isStop ? -2 : 2);
      const nPrice = parseFloat(
        getPriceFromProfit(newTrigger.profit, isBuy, startAveragePrice),
      );
      price = !isEmpty(nPrice) ? parseToFloat(nPrice, stepPrice) : null;
      // set last update to profit (only for tp and sl because start don't have profit)
      newTrigger.lastUpdate = 'profit';
    }

    // in case of update total isn't 100% so we need to recalculate
    const availablePercent =
      100 -
      triggers
        .filter((t) => t.isDone)
        .reduce((acc, trigger) => acc + trigger.pourcentage, 0);
    if (isNaN(availablePercent) || availablePercent === 0) return;

    // get old percent (if first trigger then 0)
    const tmpOldPercent =
      lengthNotDone === 0
        ? 0
        : Math.trunc(Number((availablePercent / lengthNotDone) * 100)); // will fix decimal to 2 after comma
    const oldPercent = tmpOldPercent / 100; // will fix decimal to 2 after comma
    // get new percent (if first trigger then availablePercent (maybe 100 or less))
    const tmpNewPercent =
      lengthNotDone === 0
        ? availablePercent * 100
        : Math.trunc(Number((availablePercent / (lengthNotDone + 1)) * 100)); // will fix decimal to 2 after comma
    const newPercent = tmpNewPercent / 100; // will fix decimal to 2 after comma

    // get the last percent (in case of total not equal 100% (33.33, etc))
    const oldLastPercent =
      Math.trunc((availablePercent - oldPercent * (lengthNotDone - 1)) * 100) /
      100;
    // force to 2 decimal after comma (to avoid errors like : .350000001)
    const newLastPercent =
      Math.trunc((availablePercent - newPercent * lengthNotDone) * 100) / 100;

    // set new trigger (have to be before the for loop otherwise index error and percent will be wrong)
    newTrigger.pourcentage = newLastPercent;
    this.changeAndTouch(formName, `${name}.${length}`, newTrigger);

    // update untouched and not from done trigger percents with new values
    for (const [index, trigger] of Object.entries(triggers)) {
      if (trigger.isDone) continue;
      const pourcentage = getPourcentage(trigger);
      if (
        pourcentage === oldPercent ||
        pourcentage === oldLastPercent ||
        pourcentage === newPercent ||
        pourcentage === newLastPercent
      )
        this.changeAndTouch(formName, `${name}.${index}.pourcentage`, newPercent);
    }
    // set new values of freashly added trigger
    this.changeAndTouch(formName, `${name}.${length}.price`, price);
    const nPriceTrailing = parseFloat(
      getPriceFromProfit(
        (name === 'stops' ? 1 : -1) * newTrigger.trailing,
        isBuy,
        price,
      ),
    );
    const newPriceTrailing = !isEmpty(nPriceTrailing)
      ? parseToFloat(nPriceTrailing, stepPrice)
      : null;
    this.changeAndTouch(
      formName,
      `${name}.${length}.trailingPrice`,
      newPriceTrailing === 0 ? undefined : newPriceTrailing,
    );
  };
  removeTrigger = (indexToRemove) => {
    const { formName, name, triggers } = this.props;
    const triggersNotDone = triggers.filter((trigger) => !trigger.isDone);
    const length = triggersNotDone.length;

    const availablePercent =
      100 -
      triggers
        .filter((t) => t.isDone)
        .reduce((acc, trigger) => acc + getPourcentage(trigger), 0);
    const tmpOldPercent = Math.trunc(Number((availablePercent / length) * 100));
    const oldPercent = tmpOldPercent / 100;
    const tmpNewPercent = Math.trunc(
      Number((availablePercent / (length - 1)) * 100),
    );
    const newPercent = tmpNewPercent / 100;
    const oldLastPercent =
      Math.trunc((availablePercent - oldPercent * (length - 1)) * 100) / 100;
    const newLastPercent =
      Math.trunc((availablePercent - newPercent * (length - 2)) * 100) / 100;
    const newTriggers = triggers.filter((_, i) => i !== indexToRemove);

    this.changeAndTouch(formName, name, newTriggers);

    for (const [index, trigger] of Object.entries(newTriggers)) {
      if (trigger.isDone) continue;
      const pourcentage = getPourcentage(trigger);
      if (
        pourcentage === oldPercent ||
        pourcentage === oldLastPercent ||
        pourcentage === newPercent ||
        pourcentage === newLastPercent
      )
        this.changeAndTouch(formName, `${name}.${index}.pourcentage`, newPercent);
    }
    const finalTrigger = newTriggers[newTriggers.length - 1];
    const pourcentage = getPourcentage(finalTrigger);
    if (
      pourcentage === oldPercent ||
      pourcentage === oldLastPercent ||
      pourcentage === newPercent ||
      pourcentage === newLastPercent
    )
      this.changeAndTouch(
        formName,
        `${name}.${newTriggers.length - 1}.pourcentage`,
        newLastPercent,
      );
  };
  render() {
    const {
      formName,
      name,
      triggers,
      isBuy,
      pair,
      stepPrice,
      translate,
      accountId,
      serverUrl,
      codeLang,
      isTemplate,
      trigger,
      notDoneIndex,
      dontDoStart,
      index
    } = this.props;

    const isStart = name === 'starts';
    const isStop = name === 'stops';
    const isTargets = name === 'targets';
    const hasManyOrTargets = (triggers && triggers.length > 1) || isTargets || isStop;
    const isBinanceFutures = accountId && isBinanceFuture(accountId.exchange);
    const isDone = trigger.isDone;
    const isSpot = !isFuture(accountId.exchange);

    //Length
    const partLength = isTemplate ? 5 : 3;
    const priceLength = hasManyOrTargets ? (isStart ? 8 : 5) : isStart ? 11 : 8;
    const profitLength = isTemplate ? (!hasManyOrTargets ? 11 : 6) : 3;

    //Text
    let stText = 'smartTrade.';
    if (isStart) stText += isBuy ? 'buy' : 'sell';
    else if (isStop) stText += isBuy ? 'stopLossSell' : 'stopLossBuy';
    else stText += 'price';
    const partText = translate('smartTrade.part');
    const priceText = stText;
    const profitText = translate('smartTrade.profit');
    if (isStart)
      stText = 'smartTrade.infoTrailingStart' + (isBuy ? 'Buy' : 'Sell');
    else if (isStop)
      stText = 'smartTrade.infoTrailingStopLoss' + (isBuy ? 'Sell' : 'Buy');
    else stText = 'smartTrade.infoTrailingTarget' + (isBuy ? 'Sell' : 'Buy');
    const trailingText = translate(stText);

    const prim = getPrimFromPair(pair);
    const sec = getSecFromPair(pair);
    const endAdornment = isStart ? sec + '/' + prim : sec;

    const isMobile = this.props.width === 'xs' || this.props.width === 'sm';
    if (triggers.some((trigger) => trigger.isDone !== true) === false)
      this.addNewTrigger();
    return (
        <GridContainer key={index}>
          {hasManyOrTargets && (
            <GridItem
              xs={partLength}
              sm={partLength}
              md={partLength}
              style={isMobile ? null : { marginRight: '-10px' }}
            >
              <Percentage
                className="form-control"
                name={`${name}.${index}.pourcentage`}
                label={!hasManyOrTargets ? partText : null}
                placeholder={hasManyOrTargets ? partText : null}
                step={0.01}
                disabled={isDone}
                // disabled={isMarket || isBuyDone}
              />
            </GridItem>
          )}
          {!isTemplate && (
            <GridItem
              xs={priceLength + (!isStart ? 0 : 1)}
              sm={priceLength + (!isStart ? 0 : 1)}
              md={priceLength + (!isStart ? 0 : 1)}
            >
              <Price
                name={`${name}.${index}.price`}
                label={!hasManyOrTargets ? (
                    <Translate id={priceText + (isStart ? trigger.type : '')} />
                  ) : null}
                placeholder={hasManyOrTargets ?
                  translate(priceText + (isStart ? trigger.type : ''))
                    : null}
                endAdornment={endAdornment}
                disabled={(isStart && trigger.type === 'Market') || isDone}
                step={stepPrice}
                onChange={() => {
                  this.changeAndTouch(
                    formName, `${name}.${index}.lastUpdate`, 'price');
                }}
              />
            </GridItem>
          )}
          {!isStart && (
            <GridItem
              xs={profitLength}
              sm={profitLength}
              md={profitLength}
              style={isMobile ? null
                  : isTemplate
                    ? { marginRight: '-10px' }
                    : { marginLeft: '-10px' }
              }
            >
              <Percentage
                name={`${name}.${index}.profit`}
                label={!hasManyOrTargets ? profitText : null}
                placeholder={hasManyOrTargets ? profitText : null}
                disabled={isDone}
                // disabled={trigger.trailingCB}
                onChange={() => {
                  this.changeAndTouch(
                    formName,
                    `${name}.${index}.lastUpdate`,
                    'profit',
                  );
                }}
              />
            </GridItem>
          )}
          {!isStart && !isDone && (
            <GridItem
              xs={1}
              sm={1}
              md={1}
              style={
                hasManyOrTargets && isMobile
                  ? null
                  : {
                      marginTop: '10px',
                      marginLeft: isMobile ? '0px' : '-10px',
                    }
              }
            >
              {notDoneIndex === 0 && (
                <LittleButton
                  color="info"
                  icon={<AddIcon />}
                  style={!hasManyOrTargets ? null : { marginTop: '0px' }}
                  onClick={() => {
                    this.addNewTrigger(triggers);
                  }}
                  timeout={0}
                />
              )}
              {notDoneIndex > 0 && (
                <LittleButton
                  color="danger"
                  icon={<RemoveIcon />}
                  style={!hasManyOrTargets ? null : { marginTop: '0px' }}
                  onClick={() => {
                    this.removeTrigger(index);
                  }}
                  timeout={0}
                />
              )}
            </GridItem>
          )}
          {!isDone && (
            <>
              <GridItem xs={6} sm={6} md={6}>
                {(!(isStop && isSpot)) && (
                  <LimitMarketButton
                    indexName={''}
                    formName={formName}
                    isDone={isDone}
                    name={`${name}.${index}.type`}
                  />
                )}
                {(isStop && isSpot) && (
                  <LimitMarketButton
                    indexName={''}
                    formName={formName}
                    isDone={true}
                    value={'Market'}
                    name={`${name}.${index}.type`}
                  />
                )}
              </GridItem>
              {(!isStart || !dontDoStart) && (
                <GridItem xs={5} sm={5} md={5} style={{ marginTop: '10px' }}>
                  <Field
                    name={`${name}.${index}.optionsCB`}
                    id={`${name}.${index}.optionsCB`}
                    component={SmallCheckBox}
                    // disabled={isBuyDone}
                  />
                  <LabelCheckbox htmlFor={`${name}.${index}.optionsCB`}>
                    <Translate id="smartTrade.options" />
                  </LabelCheckbox>
                </GridItem>
              )}
              {(trigger.optionsCB && (!isStart || !dontDoStart)) && (
                <>
                  {!isBinanceFutures && (
                    <>
                      <GridItem
                        xs={isTemplate ? 6 : 12}
                        sm={isTemplate ? 6 : 12}
                        md={isTemplate ? 6 : 5}
                      >
                        <div
                          style={{
                            float: 'left',
                            margin: '0.5em 0px 20px 0px',
                            textAlign: 'left',
                          }}
                        >
                          <div style={{ float: 'right' }}>
                            &nbsp;
                            <Helper text={trailingText} />
                          </div>
                          <Field
                            name={`${name}.${index}.trailingCB`}
                            id={`${name}.${index}.trailingCB`}
                            component={SmallCheckBox}
                          />
                          <LabelCheckbox
                            htmlFor={`${name}.${index}.trailingCB`}
                          >
                            {!isStop ? (
                              <Translate id="smartTrade.trailing" />
                            ) : (
                              <Translate id="smartTrade.trailingStopLoss" />
                            )}
                          </LabelCheckbox>
                        </div>
                      </GridItem>
                      {trigger.trailingCB && (
                        <>
                          <GridItem
                            xs={isTemplate ? 6 : 4}
                            sm={isTemplate ? 6 : 4}
                            md={isTemplate ? 6 : 2}
                          >
                            <Percentage name={`${name}.${index}.trailing`} />
                          </GridItem>
                          {!isTemplate && (
                            <GridItem xs={8} sm={8} md={5}>
                              <Price
                                name={`${name}.${index}.trailingPrice`}
                                endAdornment={endAdornment}
                                step={stepPrice}
                              />
                            </GridItem>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <GridItem xs={12} sm={12} md={12}>
                    <div
                      style={{ float: 'left', margin: '0.5em 0px 24px 0px' }}
                    >
                      <div style={{ float: 'right' }}>
                        &nbsp;
                        <Helper
                          // disabled={isBuyDone}
                          text={<Translate id="smartTrade.infoTradingView" />}
                        />
                      </div>
                      <Field
                        name={`${name}.${index}.tradingViewCB`}
                        id={`${name}.${index}.tradingViewCB`}
                        component={SmallCheckBox}
                        // disabled={
                        //   isBuyDone// ||
                        //   // dontDoStart ||
                        //   // startTriggerPriceCB ||
                        //   // isUpdateTemplate
                        // }
                        onChange={() => {
                          if (isNull(serverUrl)) {
                            this.changeAndTouch(
                              formName,
                              'serverUrl',
                              `${getUrl()}api/v2/Order/TradingView`,
                            );
                          }
                          if (isNull(trigger.tradingViewGuid)) {
                            this.changeAndTouch(
                              formName,
                              `${name}.${index}.tradingViewGuid`,
                              uuidv4(),
                            );
                          }
                        }}
                      />
                      <LabelCheckbox
                        // disabled={isBuyDone/* || isUpdateTemplate*/}
                        htmlFor={`${name}.${index}.tradingViewCB`}
                      >
                        <Translate id="smartTrade.tradingview" />
                      </LabelCheckbox>
                    </div>
                  </GridItem>
                  {trigger.tradingViewCB && (
                    <GridItem xs={12} sm={12} md={12}>
                      <div style={{ marginLeft: '10px' }}>
                        <CopyField
                          style={{ margin: '-24px -0px 0px 0px' }}
                          name="serverUrl"
                          copycontent={serverUrl}
                          value={serverUrl}
                          required={false}
                          label={<Translate id="smartTrade.url" />}
                        />
                        <CopyField
                          style={{ margin: '-10px -0px 0px 0px' }}
                          name={`${name}.${index}.tradingViewGuid`}
                          value={createTradingViewString(
                            trigger.tradingViewGuid,
                          )}
                          copycontent={createTradingViewString(
                            trigger.tradingViewGuid,
                          )}
                          required={false}
                          label={
                            <Translate id="smartTrade.tradingview.message" />
                          }
                        />
                      </div>
                    </GridItem>
                  )}
                  {isStart && (
                    <>
                      <GridItem xs={12} sm={12} md={6}>
                        <div
                          style={{
                            float: 'left',
                            margin: '0.5em 0px 24px 0px',
                          }}
                        >
                          <div style={{ float: 'right' }}>
                            &nbsp;
                            <Helper
                              // disabled={isBuyDone}
                              text={
                                <Translate id="smartTrade.infoTriggerPoint" />
                              }
                            />
                          </div>
                          <Field
                            name={`${name}.${index}.triggerCB`}
                            id={`${name}.${index}.triggerCB`}
                            component={SmallCheckBox}
                            // disabled={isBuyDone || dontDoStart || trigger.tradingViewCB}
                          />
                          <LabelCheckbox
                            // disabled={isBuyDone}
                            htmlFor={`${name}.${index}.triggerCB`}
                          >
                            <Translate id="smartTrade.triggerPoint" />
                          </LabelCheckbox>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        {!(!trigger.triggerCB /* || dontDoStart*/) && (
                          <Price
                            name={`${name}.${index}.triggerPrice`}
                            endAdornment={endAdornment}
                            // disabled={isBuyDone}
                            step={stepPrice}
                          />
                        )}
                      </GridItem>
                    </>
                  )}
                  {!isTemplate && (isStart || isStop) && (
                    <>
                      <GridItem xs={12} sm={12} md={6}>
                        <div
                          style={{
                            float: 'left',
                            margin: '0.5em 0px 24px 0px',
                          }}
                        >
                          <div style={{ float: 'right' }}>
                            &nbsp;
                            <Helper
                              // disabled={isBuyDone}
                              text={
                                <Translate
                                  id={
                                    isStart
                                      ? 'smartTrade.infoEntryTimeout'
                                      : 'smartTrade.infoStopLossTimeOut'
                                  }
                                />
                              }
                            />
                          </div>
                          <Field
                            name={`${name}.${index}.timeOutCB`}
                            id={`${name}.${index}.timeOutCB`}
                            component={SmallCheckBox}
                            // disabled={
                            //   isStart &&
                            //   (canITimeOut || (trigger.type == 'Market' && !(trigger.triggerCB || trigger.trailingCB)))
                            // }
                          />
                          <LabelCheckbox
                            // disabled={isBuyDone}
                            htmlFor={`${name}.${index}.timeOutCB`}
                          >
                            <Translate
                              id={
                                isStart
                                  ? 'smartTrade.entryTimeout'
                                  : 'smartTrade.stopLossTimeout'
                              }
                            />
                          </LabelCheckbox>
                        </div>
                      </GridItem>
                      {trigger.timeOutCB && (
                        <GridItem xs={12} sm={12} md={6}>
                          {/* {!canITimeOut && ( */}
                          <MyDateTimePicker
                            lang={codeLang}
                            name={`${name}.${index}.timeOut`}
                            formName={formName}
                          />
                          {/* )} */}
                        </GridItem>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
          <GridItem xs={12} sm={12} md={12}>
            <Divider style={{ margin: '10px 0px' }} />
          </GridItem>
        </GridContainer>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { localize } = state;
  const formSelector = formValueSelector(props.formName);
  return {
    triggers: formSelector(state, props.name),
    isBuy: formSelector(state, 'isBuy'),
    pair: formSelector(state, 'pair'),
    dontDoStart: formSelector(state, 'dontDoStart'),
    stepPrice: stepPriceSelector(state, props),
    translate: getTranslate(localize),
    accountId: formSelector(state, 'accountId'),
    serverUrl: formSelector(state, 'serverUrl'),
    codeLang: getActiveCode(state),
    isTemplate: formSelector(state, 'isTemplate'),
    currentPrice: currentPriceSelector(state, props),
    startAveragePrice: formSelector(state, 'startAveragePrice'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    touch: bindActionCreators(touch, dispatch),
  };
};

export default withWidth()(
  connect(mapStateToProps, mapDispatchToProps)(TriggerLineContainer),
);
