import React from 'react';
import { change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import SelectAccountContainer from './SelectAccountContainer';
import PairFavoriteContainer from './PairFavoriteContainer';
import SelectPairsContainer from './SelectPairsContainer';
import ButtonsBuySell from './ButtonsBuySell';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';

import { bindActionCreators } from 'redux';
import {
  getExchange,
  pairSelector,
  getBaseUSDPairExchange,
} from '../../../helpers/Common';

class PairExchangeContainer extends React.Component {
  render() {
    const { formName, isUpdate, exchange } = this.props;
    let favoritePairs = [];
    if (exchange) {
      favoritePairs = [
        getBaseUSDPairExchange(exchange.ExchangeName, 'ETH'),
        getBaseUSDPairExchange(exchange.ExchangeName, 'BTC'),
      ];
    }
    return (
      <>
        <GridItem xs={12} sm={12} md={12}>
          <Card profile>
            <CardBody profile>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <ButtonsBuySell formName={formName} />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <SelectAccountContainer formName={formName} />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <SelectPairsContainer formName={formName} />
                </GridItem>
                {!isUpdate && (
                  <GridItem>
                    {favoritePairs.map((pair) => {
                      return (
                        <PairFavoriteContainer
                          key={pair}
                          formName={formName}
                          pair={pair}
                        />
                      );
                    })}
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  return {
    exchange: getExchange(state),
    pair: pairSelector(state, props),
    isUpdate: formSelector(state, 'isUpdate'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PairExchangeContainer);
