export function getHeader() {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem('authentication'));
  if (user && user.access_token) {
    return new Headers({
      Authorization: `Bearer ${user.access_token}`,
    });
  }
  return new Headers();
}
