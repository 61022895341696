import { push } from 'connected-react-router';
import { orderConstants } from './constants';
import { fetcher } from '../../helpers/fetcher';
import { alertActions } from '../alert/alert.actions';
import {
  getAllNoStart,
  getAllStart,
  getAveragePrice,
  getStart,
  getStopLoss,
  getTargets,
} from '../../helpers/order';
import {
  getPriceFromProfit,
  getProfitFromPrice,
  isEmpty,
  isNull,
} from '../../helpers/Common';
import { Translate } from '../../helpers/general';
import { getErrorMessage } from '../../views/Orders/ActionOrderLine';
import { adminConstants } from '../admin/constants';

const convertTriggersV2 = (triggers) => {
  const result = [];
  if (!(triggers && triggers.length > 0)) return result;
  for (const trigger of triggers) {
    trigger.TriggerId = trigger.TriggerV2Id;
    result.push(trigger);
  }
  return result;
};

const convertOrderV2 = (order) => {
  order.OrderId = order.OrderV2Id;
  order.Triggers = convertTriggersV2(order.Triggers);
}

const convertOrdersV2 = (orders) => {
  const result = [];
  if (!orders || orders.length === 0) return result;
  for (const order of orders) {
    convertOrderV2(order);
    result.push(order);
  }
  return result;
};

const getV1AndV2Orders = async (dispatch, tabOrders, userId) => {
  const userStr = userId === null ? '' : `&userId=${userId}`;
  const ordersV1 = await fetcher(
    dispatch,
    `api/v1/Order/Orders?tabOrders=${tabOrders}${userStr}`,
    {},
  );
  const ordersV2 = await fetcher(
    dispatch,
    `api/v2/Order/Orders?tabOrders=${tabOrders}${userStr}`,
    {},
  );
  const ret = [];
  if (ordersV1 && ordersV1.length > 0) ret.push(...ordersV1);
  if (ordersV2 && ordersV2.length > 0) ret.push(...convertOrdersV2(ordersV2));
  return ret;
};

export const getOrders =
  (tabOrders = 0, userId = null) =>
    async (dispatch) => {
      const isAdmin = userId !== null;
      if (!isAdmin) {
        const euLocal = JSON.parse(localStorage.getItem(`orders${tabOrders}`));
        if (euLocal) {
          dispatch({
            type: orderConstants.ACTION_LOCAL_ORDERS,
            payload: euLocal,
          });
        }
      }

      const eu = await getV1AndV2Orders(dispatch, tabOrders, userId);
      if (eu) {
        dispatch({
          type: isAdmin
            ? adminConstants.ACTION_GET_USER_ORDERS
            : orderConstants.ACTION_GET_ORDERS,
          payload: eu,
        });
        if (!isAdmin)
          localStorage.setItem(`orders${tabOrders}`, JSON.stringify(eu));
      }
    };

export const getOrderV1 = (orderId) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v1/Order/${orderId}`, {});
  if (eu) {
    dispatch({
      type: orderConstants.ACTION_GET_ORDER,
      payload: eu,
    });
    // localStorage.setItem('orders', JSON.stringify(eu));
  }
};

export const getOrder = (orderId) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v2/Order/${orderId}`, {});
  if (eu) {
    convertOrderV2(eu);
    dispatch({
      type: orderConstants.ACTION_GET_ORDER,
      payload: eu,
    });
    // localStorage.setItem('orders', JSON.stringify(eu));
  }
};

export const getMyGroups = () => async (dispatch) => {
  const euLocal = JSON.parse(localStorage.getItem('myGroups'));
  if (euLocal) {
    dispatch({
      type: orderConstants.ACTION_LOCAL_MY_GROUPS,
      payload: euLocal,
    });
  }
  const eu = await fetcher(dispatch, 'api/v1/Group/MyGroup', {});
  if (eu) {
    dispatch({
      type: orderConstants.ACTION_GET_MY_GROUPS,
      payload: eu,
    });
    localStorage.setItem('myGroups', JSON.stringify(eu));
  }
};

export const getAvailableAmount = () => async (dispatch) => {
  const euLocal = JSON.parse(localStorage.getItem('availableAmounts'));
  if (euLocal) {
    dispatch({
      type: orderConstants.ACTION_LOCAL_AVAILABLE_AMOUNT,
      payload: euLocal,
    });
  }
  const eu = await fetcher(dispatch, 'api/v1/Order/AvailableAmounts', {});
  if (eu) {
    dispatch({
      type: orderConstants.ACTION_GET_AVAILABLE_AMOUNT,
      payload: eu,
    });
    localStorage.setItem('availableAmounts', JSON.stringify(eu));
  }
};

export const clearConvertSmartTrade = () => async (dispatch) => {
  dispatch({
    type: orderConstants.ACTION_CONVERT_ORDER,
    payload: null,
  });
};

const fillPriceProfit = (order) => {
  const avreageStartPrice = getAveragePrice(getAllStart(order.Triggers));
  const noStarts = getAllNoStart(order.Triggers);
  for (const trigger of noStarts) {
    if (trigger.Price === null)
      trigger.price = getPriceFromProfit(
        trigger.profit ?? trigger.Profit,
        order.IsBuy,
        avreageStartPrice,
      );
    else if (trigger.profit === null)
      trigger.profit = getProfitFromPrice(
        trigger.Price,
        order.IsBuy,
        avreageStartPrice,
      );
  }
};

export const convertSmartTradeV1 = (order) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Order/Convert', {
    method: 'POST',
    body: order,
  });
  if (eu) {
    fillPriceProfit(eu);
    dispatch({
      type: orderConstants.ACTION_CONVERT_ORDER,
      payload: eu,
    });
  }
};

export const convertSmartTrade = (order) => async (dispatch) => {
  //const eu = await fetcher(dispatch, 'api/v1/Order/Convert', {
  const eu = await fetcher(dispatch, 'api/v2/Order/Convert', {
    method: 'POST',
    body: order,
  });
  if (eu) {
    fillPriceProfit(eu);
    dispatch({
      type: orderConstants.ACTION_CONVERT_ORDER,
      payload: eu,
    });
  }
};

export const postSmartTradeV1 =
  (order, noPopupTrading = false) =>
    async (dispatch, getState) => {
      const eu = await fetcher(dispatch, 'api/v1/Order', {
        method: 'POST',
        body: order,
      });
      if (eu) {
        const text = Translate(getState, 'orders.orderCreated');
        // let html = null;
        // if (!isNull(order.startTradingViewGuidJson))
        //   html = `<div>${order.startTradingViewGuidJson}</div>`;
        if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
        dispatch({
          type: orderConstants.ACTION_POST_ORDER,
          payload: eu,
        });
        dispatch(push('/orders'));
      }
    };

export const postSmartTrade =
  (order, noPopupTrading = false) =>
    async (dispatch, getState) => {
      // const eu = await fetcher(dispatch, 'api/v1/Order', {
      const eu = await fetcher(dispatch, 'api/v2/Order', {
        method: 'POST',
        body: order,
      });
      if (eu) {
        const text = Translate(getState, 'orders.orderCreated');
        // let html = null;
        // if (!isNull(order.startTradingViewGuidJson))
        //   html = `<div>${order.startTradingViewGuidJson}</div>`;
        if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
        dispatch({
          type: orderConstants.ACTION_POST_ORDER,
          payload: eu,
        });
        dispatch(push('/orders'));
      }
    };

export const putOrderV1 =
  (id, order, noPopupTrading = false) =>
    async (dispatch, getState) => {
      const eu = await fetcher(dispatch, `api/v1/Order/${id}`, {
        method: 'PUT',
        body: order,
      });
      if (eu) {
        const text = Translate(getState, 'orders.orderUpdated');
        if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
        // dispatch({
        //   type: orderConstants.ACTION_DELETE_ORDER,
        //   payload: id
        // });
        dispatch(push('/orders'));
      }
    };

export const putOrder =
  (id, order, noPopupTrading = false) =>
    async (dispatch, getState) => {
      // const eu = await fetcher(dispatch, `api/v1/Order/${id}`, {
      const eu = await fetcher(dispatch, `api/v2/Order/${id}`, {
        method: 'PUT',
        body: order,
      });
      if (eu) {
        const text = Translate(getState, 'orders.orderUpdated');
        if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
        // dispatch({
        //   type: orderConstants.ACTION_DELETE_ORDER,
        //   payload: id
        // });
        dispatch(push('/orders'));
      }
    };

export const acceptOrder =
  (id, noPopupTrading = false, isV2 = false) =>
    async (dispatch, getState) => {
      const eu = await fetcher(dispatch, `api/${isV2 ? "v2" : "v1"}/Order/Accept?orderId=${id}`, {
        method: 'POST',
      });
      if (eu) {
        const start = getStart(eu.Triggers);
        if (!isEmpty(start.ErrorMsg)) {
          dispatch(alertActions.alertError(getErrorMessage(start)));
        } else {
          const text = Translate(getState, 'orders.orderUpdated');
          if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
        }
        dispatch({
          type: orderConstants.ACTION_GET_ORDER,
          payload: eu,
        });
      }
    };

export const refuseOrder =
  (id, noPopupTrading = false, isV2 = false) =>
    async (dispatch, getState) => {
      const eu = await fetcher(dispatch, `api/${isV2 ? "v2" : "v1"}/Order/Refuse?orderId=${id}`, {
        method: 'POST',
      });
      if (eu) {
        const text = Translate(getState, 'orders.orderRefused');
        if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
      }
    };

export const checkOrder =
  (id, noPopupTrading = false, isV2 = false) =>
    async (dispatch, getState) => {
      const eu = await fetcher(
        dispatch,
        `api/${isV2 ? "v2" : "v1"}/Order/CheckOrder?orderId=${id}`,
        {
          method: 'POST',
        },
      );
      if (eu) {
        dispatch({
          type: orderConstants.ACTION_GET_ORDER,
          payload: eu,
        });
        const text = Translate(getState, 'orders.orderUpdated');
        if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
      }
    };

export const panicSellOrder =
  (id, pourcentage, isOrderV2, noPopupTrading = false) =>
    async (dispatch, getState) => {
      const urlCall = `api/${isOrderV2 ? 'v2' : 'v1'
        }/Order/PanicSell?orderId=${id}&pourcentage=${pourcentage}`;
      const eu = await fetcher(dispatch, urlCall, { method: 'POST' });
      if (eu) {
        const text = Translate(getState, 'orders.orderPanicSell');
        if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
        dispatch({
          type: orderConstants.ACTION_GET_ORDER,
          payload: eu,
        });
      }
    };

export const deleteOrder =
  (id, isOrderV2, noPopupTrading = false) =>
    async (dispatch, getState) => {
      const eu = await fetcher(
        dispatch,
        `api/${isOrderV2 ? 'v2' : 'v1'}/Order/${id}`,
        {
          method: 'DELETE',
        },
      );
      if (eu) {
        const text = Translate(getState, 'orders.orderDeleted');
        if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
        dispatch({
          type: orderConstants.ACTION_DELETE_ORDER,
          payload: id,
        });
      }
    };

export const toggleDisabledUser =
  (orderId, disable, noPopupTrading = false) =>
    async (dispatch, getState) => {
      const eu = await fetcher(
        dispatch,
        `api/v1/Order/UpdateDisable?orderId=${orderId}&disable=${disable}`,
        {
          method: 'PUT',
        },
      );
      if (eu) {
        const text = Translate(getState, 'orders.orderUpdated');
        if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
        dispatch({
          type: orderConstants.ACTION_UPDATE_ORDER,
          payload: eu,
        });
      }
    };

export const refuseAllWaitValid =
  (userId = null) =>
    async (dispatch, getState) => {
      const userStr = userId === null ? '' : `?userId=${userId}`;
      const euV1 = await fetcher(
        dispatch,
        `api/v1/Order/RefuseAllWaitValid${userStr}`,
        {
          method: 'POST',
        },
      );
      const euV2 = await fetcher(
        dispatch,
        `api/v2/Order/RefuseAllWaitValid${userStr}`,
        {
          method: 'POST',
        },
      );
      if (euV1 || euV2) {
        const eu = [...(euV1 ?? []), ...(euV2 ?? [])];
        const text = Translate(getState, 'orders.orderDeleted');
        dispatch(alertActions.alertSuccess(text));
        dispatch({
          type: orderConstants.ACTION_GET_ORDERS,
          payload: eu,
        });
        // localStorage.setItem('orders', JSON.stringify(eu));
      }
    };

export const deleteEntryErrors =
  (userId = null) =>
    async (dispatch, getState) => {
      const userStr = userId === null ? '' : `?userId=${userId}`;
      const euV1 = await fetcher(
        dispatch,
        `api/v1/Order/DeleteEntryErrors${userStr}`,
        {
          method: 'POST',
        },
      );
      const euV2 = await fetcher(
        dispatch,
        `api/v2/Order/DeleteEntryErrors${userStr}`,
        {
          method: 'POST',
        },
      );
      if (euV1 || euV2) {
        const eu = [...(euV1 ?? []), ...(euV2 ?? [])];
        const text = Translate(getState, 'orders.orderDeleted');
        dispatch(alertActions.alertSuccess(text));
        dispatch({
          type: orderConstants.ACTION_GET_ORDERS,
          payload: eu,
        });
        // localStorage.setItem('orders', JSON.stringify(eu));
      }
    };

export const panicSellAll =
  (tabOrders, userId = null) =>
    async (dispatch, getState) => {
      const userStr = userId === null ? '' : `&userId=${userId}`;
      const euv1 = await fetcher(
        dispatch,
        `api/v1/Order/PanicSellAll?tabOrders=${tabOrders}${userStr}`,
        {
          method: 'POST',
        },
      );
      const euv2 = await fetcher(
        dispatch,
        `api/v2/Order/PanicSellAll?tabOrders=${tabOrders}${userStr}`,
        {
          method: 'POST',
        },
      );
      if (euv2 || euv1) {
        const eu = [...(euv1 ?? []), ...(euv2 ?? [])];
        const text = Translate(getState, 'orders.orderDeleted');
        dispatch(alertActions.alertSuccess(text));
        dispatch({
          type: orderConstants.ACTION_GET_ORDERS,
          payload: eu,
        });
        // localStorage.setItem('orders', JSON.stringify(eu));
      }
    };

export const cancelAll =
  (tabOrders, userId = null) =>
    async (dispatch, getState) => {
      const userStr = userId === null ? '' : `&userId=${userId}`;
      const euv1 = await fetcher(
        dispatch,
        `api/v1/Order/CancelAll?tabOrders=${tabOrders}${userStr}`,
        {
          method: 'POST',
        },
      );
      const euv2 = await fetcher(
        dispatch,
        `api/v2/Order/CancelAll?tabOrders=${tabOrders}${userStr}`,
        {
          method: 'POST',
        },
      );
      if (euv2 || euv1) {
        const eu = [...(euv1 ?? []), ...(euv2 ?? [])];
        const text = Translate(getState, 'orders.orderDeleted');
        dispatch(alertActions.alertSuccess(text));
        dispatch({
          type: orderConstants.ACTION_GET_ORDERS,
          payload: eu,
        });
        // localStorage.setItem('orders', JSON.stringify(eu));
      }
    };

export const createTradingViewJson = (guid, TVType = 0, triggerId = null) => {
  const result = {
    Guid: guid,
  };
  if (TVType === 1) result.PositionSize = '{{strategy.position_size}}';
  if (TVType === 2) result.SidePosition = 'buy/sell';
  if (!isNull(triggerId)) result.TriggerId = triggerId;
  return result;
};

export const createTradingViewString = (guid, TVType = 0, triggerId = null) =>
  JSON.stringify(createTradingViewJson(guid, TVType, triggerId));
