import { adminConstants } from './constants';
import { fetcher } from '../../helpers/fetcher';
import { alertActions } from '../alert/alert.actions';
import { traderConstants } from '../trader/constants';
import { alertConstants } from '../alert/alert.constants';

const fetchUser = async (dispatch, infoUser) => {
  const eu = await fetcher(dispatch, `api/v1/Admin/Users`, {
    method: 'POST',
    body: infoUser,
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_USERS,
      payload: eu,
    });
  }
  return eu;
};

export const IsAdmin = () => async (dispatch) => {
  await fetcher(dispatch, 'api/v1/Admin/IsAdmin', {});
};

export const getUsers = (state) => async (dispatch) => {
  await fetchUser(dispatch, state);
};

export const getCompanyDatas = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Admin/CompanyDatas', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_COMPANY_DATA,
      payload: eu,
    });
  }
};

export const getLogs = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v1/Admin/GetLogs?id=${id}`, {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_LOGS,
      payload: eu,
    });
  }
};

export const getOrder = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v1/Admin/GetOrder?id=${id}`, {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ORDER,
      payload: eu,
    });
  }
};

export const getOrderDetail = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v1/Admin/GetOrderDetail?id=${id}`, {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ORDER_DETAIL,
      payload: eu,
    });
  }
};

export const getOpenOrder = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v1/Admin/GetOpenOrder?id=${id}`, {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_OPEN_ORDER,
      payload: eu,
    });
  }
};

export const getOrderInfo = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v1/Admin/GetOrderInfo?id=${id}`, {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ORDER_INFO,
      payload: eu,
    });
  }
};

export const getExchangesUser = (id) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Admin/GetExchangesUser?userId=${id}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_USER_EXCHANGES,
      payload: eu,
    });
  }
};

export const getOrders = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Admin/Orders', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_USER_ORDERS,
      payload: eu,
    });
  }
};

export const getOrdersGroup =
  (
    groupId,
    dateFrom = null,
    dateTo = null,
    withOrderOnStats = true,
    orderFollowers = false,
  ) =>
  async (dispatch) => {
    const date =
      (dateFrom != null ? `&from=${dateFrom}` : '') +
      (dateTo != null ? `&to=${dateTo}` : '');
    const eu = await fetcher(
      dispatch,
      `api/v1/Admin/OrdersGroup?groupId=${groupId}${date}&withOrderOnStats=${withOrderOnStats}&orderFollowers=${orderFollowers}`,
      {
        method: 'GET',
      },
    );
    if (eu) {
      dispatch({
        type: adminConstants.ACTION_GET_USER_ORDERS,
        payload: eu,
      });
    }
  };

export const getOrdersLimit = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Admin/OrdersLimit', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_USER_ORDERS,
      payload: eu,
    });
  }
};

export const getTradingSummaries =
  (dateFrom = null, dateTo = null) =>
  async (dispatch) => {
    const date =
      (dateFrom != null ? `&from=${dateFrom}` : '') +
      (dateTo != null ? `&to=${dateTo}` : '');
    const eu = await fetcher(
      dispatch,
      `api/v1/Admin/GetTradingSummary?1${date}`,
      {
        method: 'GET',
      },
    );
    if (eu) {
      dispatch({
        type: adminConstants.ACTION_GET_TRADING_SUMMARIES,
        payload: eu,
      });
    }
  };

export const getUsersVolume =
  (dateFrom = null, dateTo = null) =>
  async (dispatch) => {
    const date =
      (dateFrom != null ? `&from=${dateFrom}` : '') +
      (dateTo != null ? `&to=${dateTo}` : '');
    const eu = await fetcher(dispatch, `api/v1/Admin/GetUsersVolume?1${date}`, {
      method: 'GET',
    });
    if (eu) {
      dispatch({
        type: adminConstants.ACTION_GET_USERS_VOLUME,
        payload: eu,
      });
    }
  };

export const getPositions = (userId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Admin/getPositions?userId=${userId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ORDERS_GROUP,
      payload: eu,
    });
  }
};

export const getOpenOrdersGroup = (groupId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Admin/GetPositionsByGroupId?groupId=${groupId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ORDERS_GROUP,
      payload: eu,
    });
  }
};

export const getAccountsGroup = (groupId, forceRefresh) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Trader/GetAccountsGroup?groupId=${groupId}&forceRefresh=${forceRefresh}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ACCOUNTS_GROUP,
      payload: eu,
    });
  }
};

export const getStatUser2 = (userId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Admin/GetStatUser2?userId=${userId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_STATS2,
      payload: eu,
    });
  }
};

export const getStatUser = (userId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Admin/GetStatUser?userId=${userId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_STATS,
      payload: eu,
    });
  }
};

export const getStatGroup2 =
  (groupId, dateFrom = null, dateTo = null) =>
  async (dispatch) => {
    const date =
      (dateFrom != null ? `&from=${dateFrom}` : '') +
      (dateTo != null ? `&to=${dateTo}` : '');
    const eu = await fetcher(
      dispatch,
      `api/v1/Admin/GetStatGroup2?groupId=${groupId}${date}`,
      {
        method: 'GET',
      },
    );
    if (eu) {
      dispatch({
        type: adminConstants.ACTION_GET_STATS_GROUP2,
        payload: eu,
      });
    }
  };

export const getStatGroup =
  (groupId, dateFrom = null, dateTo = null) =>
  async (dispatch) => {
    const date =
      (dateFrom != null ? `&from=${dateFrom}` : '') +
      (dateTo != null ? `&to=${dateTo}` : '');
    const eu = await fetcher(
      dispatch,
      `api/v1/Admin/GetStatGroup?groupId=${groupId}${date}`,
      {
        method: 'GET',
      },
    );
    if (eu) {
      dispatch({
        type: adminConstants.ACTION_GET_STATS_GROUP,
        payload: eu,
      });
    }
  };

export const getStatsGroup = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Admin/GetStatsGroup', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_STATS_GROUP,
      payload: eu,
    });
  }
};

export const getPaiements = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Admin/Paiements', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_PAIEMENTS,
      payload: eu,
    });
  }
};

export const setSaveDateDebug = (val, exchange) => async (dispatch) => {
  await fetcher(
    dispatch,
    `api/v1/Admin/SetSaveDateDebug?exchange=${exchange}&val=${val}`,
    {
      method: 'GET',
    },
  );
};

export const getCurrentPrices = () => async (dispatch) => {
  const start = new Date();
  const eu = await fetcher(dispatch, 'api/v1/Admin/GetCurrentPrices', {
    method: 'GET',
  });
  if (eu) {
    const end = new Date();
    const repTime = end - start;
    dispatch({
      type: adminConstants.ACTION_GET_CURRENT_PRICES,
      payload: eu,
      repTime: repTime,
      reqDate: end,
    });
  }
};

export const getHistoricEmail = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Admin/HistoricEmail', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_HISTORIC_EMAIL,
      payload: eu,
    });
  }
};
export const getSubscriptionGroup = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Admin/GetSubscriptionGroup', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_SUBSCRIPTION_GROUP,
      payload: eu,
    });
  }
};

export const postInscriptionGroups = (body) => async (dispatch) => {
  await fetcher(dispatch, 'api/v1/Admin/UpdateInscriptionGroup', {
    method: 'POST',
    body,
  });
};

export const postSubGroups = (body) => async (dispatch) => {
  await fetcher(dispatch, 'api/v1/Admin/UpdateSubGroup', {
    method: 'POST',
    body,
  });
};

export const postEmails = (dataEmail) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Admin/SendEmails', {
    method: 'POST',
    body: dataEmail,
  });
  if (eu) {
    dispatch(alertActions.alertSuccess('Email envoyé'));
  }
};

export const remove2FA = (userId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Admin/Remove2FA?userId=${userId}`,
    {
      method: 'DELETE',
    },
  );
  if (eu) {
    dispatch(alertActions.alertSuccess('2FA supprimé'));
  }
};

export const getEmailByAccountId = (accountId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Admin/GetEmailByAccountId?accountId=${accountId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_EMAIL_BY_ACCOUNT,
      payload: eu,
    });
  }
};

export const getEmailByUserId = (userId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Admin/GetEmailByUserId?userId=${userId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_EMAIL_BY_USER,
      payload: eu,
    });
  }
};

export const getEmailByTriggerId = (triggerId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Admin/GetEmailByTriggerId?triggerId=${triggerId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_EMAIL_BY_TRIGGER,
      payload: eu,
    });
  }
};

export const getEmailByOrderId = (orderId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Admin/GetEmailByOrderId?orderId=${orderId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_EMAIL_BY_ORDER,
      payload: eu,
    });
  }
};

export const getEmailByGroupId = (groupId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Admin/GetEmailByGroupId?groupId=${groupId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_EMAIL_BY_GROUP,
      payload: eu,
    });
  }
};

export const getEmailByTradingViewGuid =
  (tradingViewGuid) => async (dispatch) => {
    const eu = await fetcher(
      dispatch,
      `api/v1/Admin/GetEmailByTradingViewGuid?tradingViewGuid=${tradingViewGuid}`,
      {
        method: 'GET',
      },
    );
    if (eu) {
      dispatch({
        type: adminConstants.ACTION_GET_EMAIL_BY_TRADINGVIEW,
        payload: eu,
      });
    }
  };

export const confirmEmailAdmin = (email) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    'api/v1/Admin/ConfirmEmail?email=' + email,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_CONFIRM_EMAIL,
      payload: eu,
    });
  }
};

export const getProxyListInfos = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Admin/LeaderBoard/Proxys', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_PROXYS_INFOS,
      payload: eu,
    });
  }
};

export const panicSellAllIds = (ids) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Admin/PanicSellAll?ids=' + ids, {
    method: 'POST',
  });
  if (eu) {
    dispatch(alertActions.alertSuccess(eu));
  }
};

export const cancelAllIds = (ids) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Admin/CancelAll?ids=' + ids, {
    method: 'POST',
  });
  if (eu) {
    dispatch(alertActions.alertSuccess(eu));
  }
};

// export const roleToUser = id => async (dispatch, getState) => {
//   const eu = await fetcher(dispatch, 'api/v1/Admin/RoleToUser?id=' + id, {
//     method: 'POST',
//     body: id
//   });
//   await fetchUser(dispatch);
// };
//
// export const deleteRoleToUser = id => async (dispatch, getState) => {
//   const eu = await fetcher(dispatch, 'api/v1/Admin/RoleToUser?id=' + id, {
//     method: 'DELETE',
//     body: id
//   });
//   await fetchUser(dispatch);
// };
