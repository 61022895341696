import React from 'react';
import { change, Field, formValueSelector } from 'redux-form';
import GridContainer from '../../components/Grid/GridContainer';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Checkbox from '../../components/Form/Checkbox';
import ReactSelect from '../../components/Form/ReactSelectUI';
import { Translate } from 'react-localize-redux';

const style = {
  littleGridStart: {
    marginTop: '10px !important',
    paddingLeft: '15px !important',
    paddingRight: '5px !important',
  },
  littleGrid: {
    padding: '0 5px !important',
  },
  littleGridEnd: {
    marginTop: '10px !important',
    paddingLeft: '5px !important',
    paddingRight: '15px !important',
  },
};

class MultiSelectContainer extends React.PureComponent {
  render() {
    const { classes, formName, name, options } = this.props;
    const cbName = name + 'CB';
    const isWhiteList = name[0] === 'W';
    const cbOpName = (isWhiteList ? 'B' : 'W') + cbName.substring(1);
    const cbVal = this.props[cbName];
    const placeholder = isWhiteList ? 'All' : 'Nothing';
    return (
      <GridContainer>
        <Grid item xs={2} sm={2} md={2} className={classes.littleGridStart}>
          <Field
            name={cbName}
            id={cbName}
            component={Checkbox}
            type="checkbox"
            onChange={(v) => {
              if (v) this.props.change(formName, cbOpName, false);
            }}
          />
        </Grid>
        <Grid item xs={10} sm={10} md={10} className={classes.littleGridEnd}>
          <Field
            name={name}
            label={<Translate id={'strategyConfiguration.' + name} />}
            options={options}
            fullWidth={true}
            multi={true}
            placeholder={placeholder}
            component={ReactSelect}
            disabled={!cbVal}
          />
        </Grid>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { formName, name } = props;
  const formSelector = formValueSelector(formName);
  const cbName = name + 'CB';
  let ret = {};
  ret[cbName] = formSelector(state, cbName);
  return ret;
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default withStyles(style)(
  connect(mapStateToProps, mapDispatchToProps)(MultiSelectContainer),
);
