import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import { bindActionCreators } from 'redux';
import { getExchangesUser, IsAdmin } from '../../../redux/admin/actions';
import transSmartTrade from '../../../locales/smartTrade.json';
import transOrders from '../../../locales/orders.json';
import transConfirm from '../../../locales/confirmation.json';
import { withLocalize } from 'react-localize-redux';
import Orders from '../../Orders/Orders';
import ExchangeContainer from '../../Accounts/ExchangeContainer';
import transExchanges from '../../../locales/exchanges';
import transExternal from '../../../locales/externals';
import { isNull } from '../../../helpers/Common';
import ShowStats from '../StatsGroup/ShowStats';

class UsersPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transOrders);
    this.props.addTranslation(transSmartTrade);
    this.props.addTranslation(transConfirm);
    this.props.addTranslation(transExchanges);
    this.props.addTranslation(transExternal);
  }
  componentDidMount() {
    const { match } = this.props;
    const userId = match.params.id;
    this.props.getExchangesUserActions(userId);
    this.props.IsAdminActions();
  }

  render() {
    const { orders, exchanges, match } = this.props;
    const userId =
      !isNull(match) && !isNull(match.params) ? match.params.id : null;
    if (isNull(userId)) return <div />;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ShowStats userId={userId} whichStat={'old'} isAdmin={true} />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <ShowStats userId={userId} whichStat={'new'} isAdmin={true} />
        </GridItem>
        {exchanges &&
          exchanges.map((exchange) => {
            return (
              <GridItem xs={12} sm={6} md={6} lg={4} xl={3} key={exchange.Id}>
                <ExchangeContainer
                  exchange={exchange}
                  showButton={true}
                  isAdmin={true}
                />
              </GridItem>
            );
          })}
        <GridItem xs={12} sm={12} md={12}>
          Nombre ordre: {orders.length}
          <Orders
            isHistoric={true}
            forceTable={true}
            isAdmin={true}
            orders={orders}
            userId={userId}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

UsersPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    orders: admin.orders,
    exchanges: admin.exchanges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExchangesUserActions: bindActionCreators(getExchangesUser, dispatch),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
  };
};

const connectedUsers = withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(UsersPage),
);
// export { connectedApp as App };
export default connectedUsers;

// export default withStyles(styles)(UserProfile);
