import React from 'react';
import { bindActionCreators } from 'redux';
import { change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import Button from '../../../components/CustomButtons/Button.jsx';
import Helper from '../../../components/Hover/Helper';

const styles = {
  buttonLeft: {
    width: '32%',
    marginLeft: '0px',
    marginRight: '0px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  },
  buttonCenter: {
    width: '32%',
    marginLeft: '0px',
    marginRight: '0px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  },
  buttonRight: {
    width: '32%',
    marginLeft: '0px',
    marginRight: '0px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
};

class ButtonsTVType extends React.PureComponent {
  toggleTVType = (value) => {
    const { formName, name } = this.props;
    this.props.change(formName, name, value);
  };

  render() {
    const { TVType, disabled } = this.props;
    return (
      <div>
        <Button
          style={styles.buttonLeft}
          color={TVType === 0 ? 'primary' : 'default'}
          size={'sm'}
          disabled={disabled}
          onClick={() => this.toggleTVType(0)}
        >
          <b>
            <Translate id="smartTrade.TVType.0" />
            &nbsp;
            <Helper text={<Translate id="smartTrade.infoTemplate.0" />} />
          </b>
        </Button>
        <Button
          style={styles.buttonCenter}
          color={TVType === 1 ? 'primary' : 'default'}
          size={'sm'}
          disabled={disabled}
          onClick={() => this.toggleTVType(1)}
        >
          <b>
            <Translate id="smartTrade.TVType.1" />
            &nbsp;
            <Helper text={<Translate id="smartTrade.infoTemplate.1" />} />
          </b>
        </Button>
        <Button
          style={styles.buttonRight}
          color={TVType === 2 ? 'primary' : 'default'}
          size={'sm'}
          disabled={disabled}
          onClick={() => this.toggleTVType(2)}
        >
          <b>
            <Translate id="smartTrade.TVType.2" />
            &nbsp;
            <Helper text={<Translate id="smartTrade.infoTemplate.2" />} />
          </b>
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  return {
    TVType: formSelector(state, 'TVType'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsTVType);
