import moment from 'moment';
import {
  getAverageTotalPrice,
  getTargets,
  getTriggerPrice,
  triggerIsDone,
} from '../../order';
import {
  getFromValue,
  getPriceFromProfit,
  getProfitFromPrice,
  isEmpty,
  isNull,
  parseToFloat,
} from '../../Common';
import { formatDateEN } from '../../localize';

const getBaseTargetModel = (averagePrice, stepPrice) => {
  return {
    pourcentage: 100,
    price: parseToFloat(averagePrice * 1.02, stepPrice),
    profit: 2,
    tradingViewGuid: null,
    tradingViewCB: false,
    trailingPrice: null,
    trailing: 2,
    trailingCB: false,
    timeOut: moment().add(3, 'days').format(formatDateEN),
    timeOutCB: false,
    type: 'Limit',
    triggerPrice: 0,
    triggerCB: false,
    optionsCB: false,
    lastUpdate: 'profit',
    isDone: false,
  };
};

const targetsInitFromOrder = (order, currentPrice, stepPrice, starts) => {
  const ret = {
    targetsLock: true,
    targets: [],
  };
  const averagePrice = getAverageTotalPrice(starts, stepPrice);
  const triggers = getTargets(order.Triggers);

  if (isEmpty(triggers))
    ret.targets.push(getBaseTargetModel(averagePrice, stepPrice));
  else ret.targetsLock = false;

  const baseStartPrice = averagePrice === 0 ? currentPrice : averagePrice;
  const isTemplate = order.State === 9;
  const isBuy = order.IsBuy;
  const timeOut = moment().add(3, 'days').format(formatDateEN);

  for (const trigger of triggers) {
    const triggerPrice = getTriggerPrice(trigger);
    const price = isTemplate
      ? null
      : !isNull(triggerPrice)
      ? triggerPrice
      : getPriceFromProfit(trigger.Profit, isBuy, baseStartPrice);
    const profit = !isNull(trigger.Profit)
      ? trigger.Profit
      : getProfitFromPrice(price, isBuy, baseStartPrice);
    const trailingPrice =
      isNull(trigger.Trailing) || isNull(price)
        ? null
        : getPriceFromProfit(-1 * trigger.Trailing, isBuy, price);
    const newTrigger = {
      pourcentage: trigger.Pourcentage,
      price: parseToFloat(price, stepPrice),
      profit: parseToFloat(profit, 0.01),
      tradingViewGuid: trigger.TradingViewGuid,
      tradingViewCB: !isEmpty(trigger.TradingViewGuid),
      trailingPrice: trailingPrice,
      trailing: trigger.Trailing,
      trailingCB: !isEmpty(trigger.Trailing),
      timeOut: !isEmpty(trigger.TimeOut) ? trigger.TimeOut : timeOut,
      timeOutCB: !isEmpty(trigger.TimeOut),
      type: trigger.OrderType === 0 ? 'Limit' : 'Market',
      triggerPrice: trigger.TriggerPrice,
      triggerCB: !isEmpty(trigger.TriggerPrice),
      optionsCB: false,
      lastUpdate: !isNull(trigger.Profit) ? 'profit' : 'price',
      isDone: triggerIsDone(trigger),
      triggerId: trigger.TriggerId,
    };
    if (newTrigger.isDone === false) {
      newTrigger.optionsCB =
        newTrigger.tradingViewCB ||
        newTrigger.trailingCB ||
        newTrigger.timeOutCB ||
        newTrigger.triggerCB;
    }
    ret.targets.push(newTrigger);
  }

  return ret;
};

const targetsInit = ({
  order,
  correctCurrentPrice: currentPrice,
  form,
  stepPrice,
  starts,
}) => {
  if (order)
    return targetsInitFromOrder(order, currentPrice, stepPrice, starts);

  const ret = {
    targetsLock: getFromValue(form, 'targetsLock', true),
    targets: getFromValue(form, 'targets', [
      getBaseTargetModel(currentPrice, stepPrice),
    ]),
  };
  return ret;
};

export default targetsInit;
