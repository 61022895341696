import React from 'react';
import { bindActionCreators } from 'redux';
import { change, formValueSelector } from 'redux-form';
import { smartTradeSelector } from '../../../helpers/Common';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import Button from '../../../components/CustomButtons/Button.jsx';

const styles = {
  buttonLeft: {
    width: '49%',
    marginLeft: '0px',
    marginRight: '0px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  },
  buttonRight: {
    width: '49%',
    marginLeft: '0px',
    marginRight: '0px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
};

class LimitMarketButton extends React.PureComponent {
  toggle = (value) => {
    const { formName, indexName, name } = this.props;

    this.props.change(formName, indexName + name, value);
  };

  render() {
    const { value, trailingCB, isDone } = this.props;
    const isMarket = value === 'Market';
    return (
      <div>
        <Button
          style={styles.buttonLeft}
          size={'sm'}
          color={!isMarket ? 'primary' : 'default'}
          onClick={() => this.toggle('Limit')}
          disabled={trailingCB || isDone}
        >
          <b>
            <Translate id="smartTrade.limit" />
          </b>
        </Button>
        <Button
          style={styles.buttonRight}
          size={'sm'}
          color={isMarket ? 'primary' : 'default'}
          onClick={() => this.toggle('Market')}
          disabled={isDone}
        >
          <b>
            <Translate id="smartTrade.market" />
          </b>
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  const { name, indexName } = props;
  return {
    values: smartTradeSelector(state),
    trailingCB: formSelector(state, indexName + 'trailingCB'),
    value: formSelector(state, indexName + name),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LimitMarketButton);
