import React from 'react';
import StrategyConfigurations from './StrategyConfigurations.jsx';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withLocalize } from 'react-localize-redux';
import transStrategyConfiguration from '../../locales/strategyConfiguration.json';
import transConfirmation from '../../locales/confirmation.json';
import GridItem from '../../components/Grid/GridItem';
import {
  getStrategyConfigurations,
  postStrategyConfigurations,
} from '../../redux/strategyConfiguration/actions';
import { getTraders } from '../../redux/trader/actions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { changeTabStrategyValue } from '../../redux/config/actions';
import { tradersSelector } from '../../redux/trader/selector';

class StrategyConfigurationsPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transStrategyConfiguration);
    this.props.addTranslation(transConfirmation);
  }

  componentDidMount() {
    this.props.getStrategyConfigurationsActions();
    this.props.getTradersActions();
  }

  addStrategy = () => {
    const { strategyConfigurations } = this.props;

    this.props.postStrategyConfigurationsActions({
      Name: 'Strategy-' + (strategyConfigurations.length + 1),
    });
  };

  handleChange = (event, newValue) => {
    this.props.changeTabStrategyValueActions(newValue);
  };

  render() {
    const { strategyConfigurations, tabStrategyValue } = this.props;
    return (
      <GridItem container>
        <Tabs
          value={tabStrategyValue}
          onChange={this.handleChange}
          // indicatorColor="primary"
          // textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {strategyConfigurations &&
            strategyConfigurations.map((strategyConfiguration) => {
              return (
                <Tab
                  key={strategyConfiguration.strategyConfigurationId}
                  label={strategyConfiguration.Name}
                />
              );
            })}
          <Tab label={'+'} onClick={this.addStrategy} />
        </Tabs>
        <StrategyConfigurations />
      </GridItem>
    );
  }
}

const mapStateToProps = (state) => {
  const { strategyConfiguration, config } = state;
  return {
    strategyConfigurations: strategyConfiguration.strategyConfigurations,
    traders: tradersSelector(state),
    tabStrategyValue: config.tabStrategyValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTradersActions: bindActionCreators(getTraders, dispatch),
    getStrategyConfigurationsActions: bindActionCreators(
      getStrategyConfigurations,
      dispatch,
    ),
    postStrategyConfigurationsActions: bindActionCreators(
      postStrategyConfigurations,
      dispatch,
    ),
    changeTabStrategyValueActions: bindActionCreators(
      changeTabStrategyValue,
      dispatch,
    ),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(StrategyConfigurationsPage),
);
