import React from 'react';

export default class Hover extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
      isClicked: false,
    };
  }

  handleMouseOver = () => {
    this.setState({
      ...this.state,
      isHovering: true,
    });
  };
  handleMouseOut = () => {
    this.setState({
      ...this.state,
      isHovering: false,
    });
  };

  onClick = () => {
    const { onClick } = this.props;

    this.setState({
      ...this.state,
      isClicked: !this.state.isClicked,
    });
    if (onClick) onClick();
  };

  render() {
    const { trigger, hover, minWidthHover, maxWidthHover } = this.props;
    const { isClicked } = this.state;
    return (
      <div style={{ float: 'left' }}>
        <div
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
          onClick={this.onClick}
          style={{ cursor: 'pointer' }}
        >
          {trigger}
        </div>
        {(this.state.isHovering || isClicked) && (
          <div
            style={{
              position: 'absolute',
              zIndex: 1000,
              minWidth: minWidthHover,
              maxWidth: maxWidthHover,
            }}
          >
            {hover}
          </div>
        )}
      </div>
    );
  }
}
