import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccountBox, Visibility } from '@material-ui/icons';
import React from 'react';
import { Translate } from 'react-localize-redux';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import {
  dangerColor,
  infoColor,
  warningColor,
} from '../../assets/jss/material-dashboard-react';
import { connectAs } from '../../helpers/general';
import GetMenuItem from '../../helpers/menuItems';
import { remove2FA } from '../../redux/admin/actions';
import { adminUrl } from '../../routes/dashboard';

class AdminMenuButtons extends React.Component {
  render() {
    const { user } = this.props;
    return (
      <div>
        <GetMenuItem
          icon={<AccountBox htmlColor={warningColor} />}
          text={<Translate id="central.connectAs" />}
          onClick={() => connectAs(user.Id, user.Email)}
        />
        <GetMenuItem
          icon={<Visibility htmlColor={infoColor} />}
          text={<Translate id="exchanges.showOrders" />}
          to={adminUrl + '/user/' + user.Id + '/orders'}
        />
        {user.HasTFA && (
          <GetMenuItem
            icon={
              <FontAwesomeIcon
                icon={faQrcode}
                size="lg"
                htmlColor={dangerColor}
              />
            }
            text={<Translate id="central.remove2FA" />}
            onClick={() => {
              if (!window.confirm('Supprimer 2fa ?')) return;
              this.props.remove2FAActions(user.Id);
            }}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    remove2FAActions: bindActionCreators(remove2FA, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(AdminMenuButtons);
