import React, { PureComponent } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';
import { createAxisAndSeries } from './chartHelper';
import connect from 'react-redux/es/connect/connect';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_dark);

class LineChartDataTime extends PureComponent {
  buildChart = () => {
    const { data, name, theme } = this.props;
    if (!data) return;
    let chart = am4core.create(name, am4charts.XYChart);

    chart.paddingRight = 20;
    // console.log(data);

    chart.data = data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.tooltipDateFormat = 'yyyy-MM-dd';
    dateAxis.renderer.labels.template.fill = theme === 'dark' ? '#fff' : '#000';

    createAxisAndSeries(chart, 'total', 'Total', false, '#85bb65');

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = theme === 'dark' ? '#fff' : '#000';

    this.chart = chart;
  };

  componentDidMount() {
    this.buildChart();
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { name } = this.props;

    return (
      <div
        id={name}
        style={{ width: '100%', height: '300px', margin: '10px 0px 0 0px' }}
      />
    );
  }
}

function mapStateToProps(state) {
  const { config } = state;
  return {
    theme: config.theme,
  };
}

export default connect(mapStateToProps, null)(LineChartDataTime);
