export const orderConstants = {
  ACTION_GET_MY_GROUPS: '@order/ACTION_GET_MY_GROUPS',
  ACTION_LOCAL_MY_GROUPS: '@order/ACTION_LOCAL_MY_GROUPS',
  ACTION_GET_AVAILABLE_AMOUNT: '@order/ACTION_GET_AVAILABLE_AMOUNT',
  ACTION_LOCAL_AVAILABLE_AMOUNT: '@order/ACTION_LOCAL_AVAILABLE_AMOUNT',
  ACTION_GET_ORDER: '@order/ACTION_GET_ORDER',
  ACTION_GET_ORDERS: '@order/ACTION_GET_ORDERS',
  ACTION_LOCAL_ORDERS: '@order/ACTION_LOCAL_ORDERS',
  ACTION_POST_ORDER: '@order/ACTION_POST_ORDER',
  ACTION_CONVERT_ORDER: '@order/ACTION_CONVERT_ORDER',
  ACTION_DELETE_ORDER: '@order/ACTION_DELETE_ORDER',
  ACTION_UPDATE_ORDER: '@order/ACTION_UPDATE_ORDER',
};
