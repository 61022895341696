import React from 'react';

// @material-ui/core components
import GridContainer from '../../components/Grid/GridContainer.jsx';

import { connect } from 'react-redux';

import { Translate, withLocalize } from 'react-localize-redux';
import GridItem from '../../components/Grid/GridItem.jsx';
import SwitchUI from '@material-ui/core/Switch/Switch';
import { bindActionCreators } from 'redux';
import { changeNoPopupTrading } from '../../redux/config/actions';

class TradingContainer extends React.Component {
  componentDidMount() {}
  render() {
    const { noPopupTrading, changeNoPopupTradingActions } = this.props;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Translate id="settings.noPopupTrading" />
            <SwitchUI
              checked={noPopupTrading}
              onChange={() => {
                changeNoPopupTradingActions(!noPopupTrading);
                // this.change()
                // this.toggleAuto();
                // this.setState({ autoRefresh: !this.state.autoRefresh });
              }}
              color="primary"
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { config } = state;

  return {
    noPopupTrading: config.noPopupTrading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeNoPopupTradingActions: bindActionCreators(
      changeNoPopupTrading,
      dispatch,
    ),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(TradingContainer),
);
