import React from 'react';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import { connect } from 'react-redux';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import { withLocalize } from 'react-localize-redux';
import { Translate } from 'react-localize-redux';
import { getActiveCode } from '../../../helpers/localize';
import {
  isBinanceFuture,
  isBybitFuture,
  isNull,
} from '../../../helpers/Common.js';

const styles = {
  tradingViewWidget: {
    minHeight: '300px',
    height: '300px',
    marginBottom: '5px',
  },
};
const indicator = ['BB@tv-basicstudies', 'MASimple@tv-basicstudies'];

class TradingViewContainer extends React.Component {
  render() {
    const { formSmartTrade, theme, codeLang } = this.props;
    let exchange = '';
    let pairStr = '';
    if (formSmartTrade && formSmartTrade.values) {
      const { accountId } = formSmartTrade.values;
      if (accountId) exchange = accountId.exchange;

      const { pair } = formSmartTrade.values;
      if (pair && pair.value) pairStr = pair.value.replace('-', '');
    }
    if (exchange === 'LBank') return <div></div>;
    if (!isNull(exchange) && exchange.startsWith('Binance')) {
      if (isBinanceFuture(exchange)) {
        pairStr += 'PERP';
      }
      exchange = 'Binance';
    }
    if (!isNull(exchange) && exchange.startsWith('Bybit')) {
      if (isBybitFuture(exchange)) {
        pairStr += '.P';
      }
      exchange = 'Bybit';
    }
    const symbol = exchange + ':' + pairStr;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={styles.tradingViewWidget}>
          {exchange && pairStr ? (
            <TradingViewWidget
              symbol={symbol}
              theme={theme === 'dark' ? Themes.DARK : Themes.LIGHT}
              locale={codeLang}
              // autosize={true}
              width="100%"
              height="300px"
              timezone="Europe/Paris"
              interval="120"
              hide_side_toolbar={false}
              studies={indicator}
            />
          ) : (
            <GridItem container alignItems="center" justifyContent="center">
              <Translate id="smartTrade.selectPair" />
            </GridItem>
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { form, order, config } = state;

  return {
    formSmartTrade: form.formSmartTrade,
    orders: order.orders,
    theme: config.theme,
    codeLang: getActiveCode(state),
  };
};

export default withLocalize(
  connect(mapStateToProps, null)(TradingViewContainer),
);
