import React from 'react';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';

class Loading extends React.Component {
  render() {
    const { loading } = this.props;
    return (
      <div>
        {loading.type === 'ON' && (
          <LinearProgress variant="query" color="primary" />
          // <CircularProgress className={classes.progress} size="40" />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading } = state;
  return {
    loading,
  };
}

export default connect(mapStateToProps)(Loading);
