import React from 'react';
import { connect } from 'react-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import { bindActionCreators } from 'redux';
import { getOrders, IsAdmin } from '../../../redux/admin/actions';
import transSmartTrade from '../../../locales/smartTrade.json';
import transOrders from '../../../locales/orders.json';
import transConfirm from '../../../locales/confirmation.json';
import { withLocalize } from 'react-localize-redux';
import Orders from '../../Orders/Orders';
import { getAllNoStart, getStart, triggerIsDone } from '../../../helpers/order';
import { isNull } from '../../../helpers/Common';

const messageErrorAucunState = 'Aucun State';
// const messageErrorBinance =
//   '-2010: Account has insufficient balance for requested action.';
// const messageErrorHuobi =
//   '3: Server error: account-frozen-balance-insufficient-error';
class OrdersPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transOrders);
    this.props.addTranslation(transSmartTrade);
    this.props.addTranslation(transConfirm);
  }
  componentDidMount() {
    this.props.getOrdersActions();
    this.props.IsAdminActions();
  }

  triggerDontStart = (orders) => {
    let filterOrders = [];

    for (const order of orders) {
      let toAdd = false;
      const start = getStart(order.Triggers);
      const allNoStart = getAllNoStart(order.Triggers);

      //Aucun state
      for (const trigger of order.Triggers) {
        if (
          !isNull(trigger.ErrorMsg) &&
          trigger.ErrorMsg.startsWith(messageErrorAucunState)
        ) {
          toAdd = true;
          break;
        }
      }

      //TP or SL no start
      //Order is Wait Or Started
      if (!isNull(start) && triggerIsDone(start)) {
        if (order.State < 2) toAdd = true;
        for (const trigger of allNoStart) {
          if (trigger.State === 0) {
            toAdd = true;
            break;
          }
        }
      }

      // //Huobi Market Buy PB arrondi target
      // if (!isNull(start) && triggerIsDone(start)) {
      //   for (const trigger of allNoStart) {
      //     if (
      //       !isNull(trigger.ErrorMsg) &&
      //       (trigger.ErrorMsg.startsWith(messageErrorHuobi) ||
      //         trigger.ErrorMsg.startsWith(messageErrorBinance))
      //     ) {
      //       toAdd = true;
      //       break;
      //     }
      //   }
      // }
      if (toAdd) {
        filterOrders.push(order);
      }
    }
    return filterOrders;
  };

  render() {
    const { orders } = this.props;
    const filterOrders = this.triggerDontStart(orders);
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          Nombre ordre: {orders.length}
          <Orders
            isHistoric={true}
            forceTable={true}
            isAdmin={true}
            orders={filterOrders}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    orders: admin.orders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrdersActions: bindActionCreators(getOrders, dispatch),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(OrdersPage),
);
