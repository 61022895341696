import { tokensConstants } from './constants';

const initialState = { tokens: [], tokensSelect: [] };

export function tokens(state = initialState, action) {
  switch (action.type) {
    case tokensConstants.ACTION_GET_ALL_TOKENS:
    case tokensConstants.ACTION_LOCAL_ALL_TOKENS: {
      const tokens = action.payload;
      const newState = state;
      if (tokens && tokens.length > 0) {
        state.tokens = tokens;
        const newTab = [];
        tokens.map((elem) => {
          newTab.push({
            value: elem.TokenId,
            label: `${elem.Name} (${elem.Symbol})`,
            Symbol: elem.Symbol,
          });
        });
        state.tokensSelect = newTab;
      }
      return { ...newState };
    }
    default:
      return state;
  }
}
