import React from 'react';

import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import {
  isAdminSelector,
  userIdSelector,
} from '../../redux/authentication/selector';
import { tradersSelector } from '../../redux/trader/selector';

import { isNull } from '../../helpers/Common';
import FormAddFollower from '../Follower/FormAddFollower';
import { bindActionCreators } from 'redux';
import { getFollower } from '../../redux/trader/actions';
import transTrader from '../../locales/traders';
import transCentral from '../../locales/central';
import transOrders from '../../locales/orders';
import transConfirmation from '../../locales/confirmation';
import transExchanges from '../../locales/exchanges';
import transError from '../../locales/error.json';

class UpdateFollowerPage extends React.Component {
  constructor(props) {
    super(props);
    const { match } = this.props;

    this.props.addTranslation(transTrader);
    this.props.addTranslation(transCentral);
    this.props.addTranslation(transOrders);
    this.props.addTranslation(transConfirmation);
    this.props.addTranslation(transExchanges);
    this.props.addTranslation(transError);

    const userId = match?.params?.userId;
    const groupId = match?.params?.id;
    this.state = {
      userId: userId,
      groupId: groupId,
    };
  }

  componentDidMount() {
    const { userId, groupId, isAdmin } = this.state;
    this.props.getFollowerActions(groupId, userId);
  }

  render() {
    const { traders, followers, userIdAdmin, isAdmin } = this.props;
    const { userId, groupId } = this.state;

    if (isNull(userId)) return <div />;

    const trader = !isNull(traders)
      ? traders.find((x) => x.GroupId === parseInt(groupId))
      : null;

    if (!trader || (userIdAdmin !== trader.ApplicationUserId && !isAdmin))
      return <div />;

    const follower = followers.find((x) => x.ApplicationUserId === userId);
    if (!follower) return <div />;

    return (
      <FormAddFollower trader={trader} follower={follower} userId={userId} />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    followers: state?.trader?.followers,
    traders: tradersSelector(state),
    userIdAdmin: userIdSelector(state),
    isAdmin: isAdminSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFollowerActions: bindActionCreators(getFollower, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(UpdateFollowerPage),
);
