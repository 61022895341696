import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, change, touch } from 'redux-form';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { bindActionCreators } from 'redux';
import {
  getPriceFromProfit,
  getSLProfitFromPrice,
  getSLPriceFromProfit,
  getSecFromPair,
  getProfitFromPrice,
  getPourcentageSLNetV1,
  isEmpty,
  stepPriceSelector,
  parseToFloat,
  currentPriceSelector,
  getPourcentageNet,
  capitalSelector,
  isBinanceFuture,
} from '../../../helpers/Common';
import { getTranslate, Translate } from 'react-localize-redux';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import SwitchUI from '@material-ui/core/Switch/Switch';
import TrendingDown from '@material-ui/icons/TrendingDown';
import Button from '../../../components/CustomButtons/Button.jsx';
import Price from '../../../components/Fields/Price';
import Percentage from '../../../components/Fields/Percentage';
import Helper from '../../../components/Hover/Helper';
import { SmallCheckBox } from '../../../components/Fields/SmallCheckBox';
import LabelCheckbox from '../../../components/Form/LabelCheckbox';
import { abs, getMoveSLOptions } from '../../../helpers/order';
import MyDateTimePicker from '../../../components/Fields/MyDateTimePicker';
import { getActiveCode } from '../../../helpers/localize';
import LimitMarketButton from './LimitMarketButton';
import ReactSelect from '../../../components/Form/ReactSelectUI';

class StopLossContainer extends React.PureComponent {
  componentDidUpdate = (prevProps) => {
    const {
      stopProfit,
      stopPrice,
      isBuy,
      startPrice,
      formName,
      change,
      touch,
      stepPrice,
    } = this.props;

    if (!isEmpty(stopPrice) || !isEmpty(stopProfit) || !isEmpty(startPrice)) {
      const nPrice = parseFloat(
        getPriceFromProfit(stopProfit, isBuy, startPrice),
      );
      const newPrice = !isEmpty(nPrice)
        ? parseToFloat(nPrice, stepPrice)
        : nPrice;
      const nProfit = parseFloat(
        getProfitFromPrice(stopPrice, isBuy, startPrice),
      );
      const newProfit = !isEmpty(nProfit)
        ? parseToFloat(nProfit, 0.01)
        : nProfit;

      if (
        isBuy !== prevProps.isBuy ||
        startPrice !== prevProps.startPrice ||
        (stopProfit !== prevProps.stopProfit && newProfit !== stopProfit)
      ) {
        change(formName, 'stopPrice', newPrice);
        touch(formName, 'stopPrice');
      } else if (stopPrice !== prevProps.stopPrice && newPrice !== stopPrice) {
        change(formName, 'stopProfit', newProfit);
        touch(formName, 'stopProfit');
      }
    }
  };

  toggleLock = () => {
    const { formName, change, stopsLock, touch } = this.props;
    change(formName, 'stopsLock', !stopsLock);
    touch(formName, 'stopsLock');
  };

  setProfitOnTrailingChange = (newValue) => {
    const {
      isBuyDone,
      formName,
      currentPrice,
      isBuy,
      startPrice,
      change,
      touch,
    } = this.props;

    const val = !isBuyDone
      ? newValue
      : parseToFloat(
          getProfitFromPrice(currentPrice, isBuy, startPrice) - -1 * newValue,
          0.01,
        );
    change(formName, 'stopProfit', val);
    touch(formName, 'stopProfit');
  };

  render() {
    const {
      pair,
      startPrice,
      isBuy,
      percentageSLNet,
      percentageTPNet,
      formName,
      timeOutStopLossCB,
      stopsLock,
      trailingStopLoss,
      trailingStopLossCB,
      stepPrice,
      partCapital,
      capital,
      codeLang,
      leverage,
      accountId,
      isTemplate,
      moveSL,
      moveSLCB,
      isSC,
    } = this.props;
    const isBinanceFutures = accountId && isBinanceFuture(accountId.exchange);
    const optionsMoveSl = getMoveSLOptions();
    if (moveSL === undefined)
      this.props.change(formName, 'moveSL', optionsMoveSl[0]);
    if (isSC && stopsLock) {
      this.props.change(formName, 'stopsLock', false);
      this.props.touch(formName, 'stopsLock');
    }
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card className="smart-trade-bloc">
          <CardBody>
            <fieldset
              disabled={stopsLock}
              className={stopsLock ? 'disabled' : ''}
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Translate id="smartTrade.setStopLoss" />
                  <SwitchUI
                    checked={!stopsLock}
                    onChange={() => this.toggleLock()}
                    color="primary"
                  />
                </GridItem>
                {!isTemplate && (
                  <GridItem xs={8} sm={8} md={8}>
                    <Price
                      endAdornment={getSecFromPair(pair)}
                      name="stopPrice"
                      min={isBuy ? 0 : startPrice}
                      max={isBuy ? startPrice : ''}
                      step={stepPrice}
                      disabled={trailingStopLossCB}
                      label={
                        isBuy ? (
                          <Translate id="smartTrade.stopLossSell" />
                        ) : (
                          <Translate id="smartTrade.stopLossBuy" />
                        )
                      }
                      onChange={() => {
                        this.props.change(formName, 'lastUpdatedStop', 'price');
                      }}
                    />
                  </GridItem>
                )}
                <GridItem
                  xs={!isTemplate ? 4 : 12}
                  sm={!isTemplate ? 4 : 12}
                  md={!isTemplate ? 4 : 12}
                >
                  <Percentage
                    name="stopProfit"
                    label={<Translate id="smartTrade.profit" />}
                    disabled={trailingStopLossCB}
                    min={isBuy ? -100 : 0}
                    max={isBuy ? 0 : ''}
                    onChange={() => {
                      this.props.change(formName, 'lastUpdatedStop', 'profit');
                    }}
                  />
                </GridItem>
                {isBinanceFutures && (
                  <GridItem xs={12} sm={12} md={6}>
                    <LimitMarketButton
                      indexName={''}
                      formName={formName}
                      name="orderTypeStop"
                      isTargets={true}
                    />
                  </GridItem>
                )}
                <GridItem xs={12} sm={12} md={isBinanceFutures ? 6 : 12}>
                  <div style={{ textAlign: 'right' }}>
                    <Translate id="smartTrade.netPercentage" /> :{' '}
                    {percentageSLNet}%
                  </div>
                </GridItem>
                {!isBinanceFutures && (
                  <GridItem xs={8} sm={8} md={8} style={{ marginTop: '30px' }}>
                    <div
                      style={{ float: 'left', margin: '0.5em 0px 20px 0px' }}
                    >
                      <div style={{ float: 'right' }}>
                        &nbsp;
                        <Helper
                          text={
                            isBuy ? (
                              <Translate id="smartTrade.infoTrailingStopLossSell" />
                            ) : (
                              <Translate id="smartTrade.infoTrailingStopLossBuy" />
                            )
                          }
                        />
                      </div>
                      <Field
                        name="trailingStopLossCB"
                        id="trailingStopLossCB"
                        component={SmallCheckBox}
                        onChange={() => {
                          if (trailingStopLossCB === false) {
                            this.setProfitOnTrailingChange(trailingStopLoss);
                          }
                        }}
                      />
                      <LabelCheckbox htmlFor="trailingStopLossCB">
                        {isBuy ? (
                          <Translate id="smartTrade.trailingStopLossSell" />
                        ) : (
                          <Translate id="smartTrade.trailingStopLossBuy" />
                        )}
                      </LabelCheckbox>
                    </div>
                  </GridItem>
                )}
                {!isBinanceFutures && (
                  <GridItem xs={4} sm={4} md={4} style={{ marginTop: '30px' }}>
                    {trailingStopLossCB && (
                      <Percentage
                        name="trailingStopLoss"
                        onChange={(event, newValue) => {
                          this.setProfitOnTrailingChange(newValue);
                        }}
                      />
                    )}
                  </GridItem>
                )}
                {!isTemplate && (
                  <GridItem xs={12} sm={12} md={12}>
                    <div
                      style={{ float: 'left', margin: '0.5em 0px 20px 0px' }}
                    >
                      <div style={{ float: 'right' }}>
                        &nbsp;
                        <Helper
                          text={
                            <Translate id="smartTrade.infoStopLossTimeOut" />
                          }
                        />
                      </div>
                      <Field
                        name="timeOutStopLossCB"
                        id="timeOutStopLossCB"
                        component={SmallCheckBox}
                        // disabled={ || }
                        // onChange={() => {}}
                      />
                      <LabelCheckbox
                        htmlFor="timeOutStopLossCB"
                        style={{ cursor: 'pointer' }}
                      >
                        <Translate id="smartTrade.stopLossTimeout" />
                      </LabelCheckbox>
                    </div>
                  </GridItem>
                )}
                {!isTemplate && (
                  <GridItem xs={12} sm={12} md={12}>
                    {timeOutStopLossCB && (
                      <MyDateTimePicker
                        style={{ marginBottom: '20px' }}
                        lang={codeLang}
                        name="timeOutStopLoss"
                        formName={formName}
                      />
                    )}
                  </GridItem>
                )}
                <GridItem xs={8} sm={8} md={8}>
                  <div style={{ float: 'left', margin: '0.5em 0px 20px 0px' }}>
                    <div style={{ float: 'right' }}>
                      &nbsp;
                      <Helper
                        text={<Translate id="smartTrade.moveSL_help" />}
                      />
                    </div>
                    <Field
                      name="moveSLCB"
                      id="moveSLCB"
                      component={SmallCheckBox}
                      onChange={() => {
                        if (moveSLCB === false) {
                          // this.setProfitOnTrailingChange(trailingStopLoss);
                        }
                      }}
                    />
                    <LabelCheckbox htmlFor="moveSLCB">
                      <span>
                        <Translate id="smartTrade.moveSL_checkbox" />
                      </span>
                    </LabelCheckbox>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  {moveSLCB && (
                    <Field
                      name="moveSL"
                      label={<Translate id="smartTrade.moveSL_selectTitle" />}
                      options={optionsMoveSl}
                      fullWidth={true}
                      component={ReactSelect}
                      required={true}
                      value={moveSL}
                      onChange={(event, newValue) => {
                        this.props.change(formName, 'moveSL', newValue);
                      }}
                      // disabled={isUpdate}
                    />
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  {/*//TODO:(percentageSLNet*(100-%TPDone) + percentageTPNet*%TPDone)*/}
                  <div style={{ textAlign: 'right' }}>
                    <Translate id="smartTrade.maxCapitalLoss" /> :{' '}
                    {parseToFloat(
                      (percentageSLNet * partCapital) / leverage / 100,
                      0.01,
                    )}
                    % ({' '}
                    {parseToFloat(
                      (capital * percentageSLNet * partCapital) /
                        leverage /
                        10000,
                      0.01,
                    )}{' '}
                    $ )<br />
                    <Translate id="smartTrade.rewardRisk" /> :{' '}
                    {parseToFloat(percentageTPNet / abs(percentageSLNet), 0.01)}
                    :1
                  </div>
                </GridItem>
              </GridContainer>
            </fieldset>
          </CardBody>
          {stopsLock && (
            <div className="active-block">
              <Button
                color="primary"
                style={{ width: '200px' }}
                onClick={() => this.toggleLock()}
              >
                <TrendingDown style={{ margin: '4px' }} />
                <Translate id="smartTrade.setStopLoss" />
              </Button>
            </div>
          )}
        </Card>
        {/*<PanicSellContainer formName={formName} />*/}
      </GridItem>
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  const { localize } = state;
  const groupId = formSelector(state, 'groupId');
  return {
    translate: getTranslate(localize),
    // values: getFormValues(props.formName)(state),
    isSC: [11586].includes(groupId?.value),
    pair: formSelector(state, 'pair'),
    isBuy: formSelector(state, 'isBuy'),
    isBuyDone: formSelector(state, 'isBuyDone'),
    currentPrice: currentPriceSelector(state, props),
    startPrice: formSelector(state, 'startPrice'),
    timeOutStopLossCB: formSelector(state, 'timeOutStopLossCB'),
    trailingStopLossCB: formSelector(state, 'trailingStopLossCB'),
    stopProfit: formSelector(state, 'stopProfit'),
    trailingStopLoss: formSelector(state, 'trailingStopLoss'),
    stopsLock: formSelector(state, 'stopsLock'),
    stopPrice: formSelector(state, 'stopPrice'),
    isUpdate: formSelector(state, 'isUpdate'),
    partCapital: formSelector(state, 'pourcentage'),
    accountId: formSelector(state, 'accountId'),
    leverage: formSelector(state, 'leverage'),
    capital: capitalSelector(state, props),
    percentageSLNet: getPourcentageSLNetV1(state),
    percentageTPNet: getPourcentageNet(state),
    codeLang: getActiveCode(state),
    stepPrice: stepPriceSelector(state, props),
    isTemplate: formSelector(state, 'isTemplate'),
    moveSL: formSelector(state, 'moveSL'),
    moveSLCB: formSelector(state, 'moveSLCB'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    touch: bindActionCreators(touch, dispatch),
    getSLProfitFromPrice: bindActionCreators(getSLProfitFromPrice, dispatch),
    getSLPriceFromProfit: bindActionCreators(getSLPriceFromProfit, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StopLossContainer);
