import React from 'react';
import { connect } from 'react-redux';
import { getTranslate, Translate } from 'react-localize-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import typographyStyle from '../../assets/jss/material-dashboard-react/components/typographyStyle.jsx';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader.jsx';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import PieChartGains from '../../components/Charts/PieChartGains';

class ReferralContainer extends React.PureComponent {
  render() {
    const { referral } = this.props;
    if (!referral) return <div />;

    return (
      <Card>
        <CardHeader color="primary">
          <GridContainer alignItems="center">
            <GridItem xs>
              <b>{referral.Name}</b>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={5} sm={5} md={5}>
              {referral.Gains && <PieChartGains gains={referral.Gains} />}
            </GridItem>
            <GridItem container alignItems="center" xs={7} sm={7} md={7}>
              <GridContainer>
                <GridItem container alignItems="center">
                  {<Translate id="referrals.numberOfUniqueClicks" />}
                  {' : '}
                  <b>{referral.NbClick}</b>
                </GridItem>
                <GridItem container alignItems="center">
                  {<Translate id="referrals.numberOfRegistrations" />}
                  {' : '}
                  <b>{referral.NbSubscribe}</b>
                </GridItem>
                <GridItem container alignItems="center">
                  {<Translate id="referrals.numberWithAtLeast1Trade" />}
                  {' : '}
                  <b>{referral.NbProfitable}</b>
                </GridItem>
              </GridContainer>
              <GridItem />
              <GridContainer>
                {/*<GridItem container alignItems="center">*/}
                {/*  <Translate id="traders.tradedCapital" />: &nbsp;*/}
                {/*  <b>{parseFloat(referrals.CapitalTrading.toFixed(0))} $</b>*/}
                {/*</GridItem>*/}
                {/*<GridItem container alignItems="center">*/}
                {/*  <Translate id="traders.realCapital" />: &nbsp;*/}
                {/*  <b>*/}
                {/*    {referrals.ReelCapital !== null*/}
                {/*      ? parseFloat(referrals.ReelCapital.toFixed(0))*/}
                {/*      : ''}{' '}*/}
                {/*    $*/}
                {/*  </b>*/}
                {/*</GridItem>*/}
                {/*<GridItem container alignItems="center">*/}
                {/*  <Translate id="traders.numberOfTrades" />: &nbsp;*/}
                {/*  <b>{referrals.NbOpenOrders}</b>*/}
                {/*</GridItem>*/}
                {/*<GridItem container alignItems="center">*/}
                {/*  <Translate id="traders.endDateWOT" />: &nbsp;*/}
                {/*  <b>*/}
                {/*    {referrals.WotDate === null*/}
                {/*      ? ''*/}
                {/*      : getDateStr(referrals.WotDate)}*/}
                {/*  </b>*/}
                {/*</GridItem>*/}
                {/*<GridItem container alignItems="center">*/}
                {/*  <Translate id="traders.endDate" />: &nbsp;*/}
                {/*  <b>*/}
                {/*    {referrals.EndDate === null*/}
                {/*      ? translate('traders.noEndDate')*/}
                {/*      : getDateStr(referrals.EndDate)}*/}
                {/*  </b>*/}
                {/*</GridItem>*/}
                {/*<GridItem container alignItems="center">*/}
                {/*  <Translate id="central.note" />: &nbsp;*/}
                {/*  <b>{referrals.Note}</b>*/}
                {/*</GridItem>*/}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  const { localize } = state;
  const translate = getTranslate(localize);
  return {
    translate,
  };
};

export default withStyles(typographyStyle)(
  connect(mapStateToProps, null)(ReferralContainer),
);
