import { adminConstants } from './constants';
import { fetcher } from '../../helpers/fetcher';
import { alertActions } from '../alert/alert.actions';

const fetchUser = async (dispatch, infoUser) => {
  const eu = await fetcher(dispatch, `api/v2/AdminV2/Users`, {
    method: 'POST',
    body: infoUser,
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_USERS,
      payload: eu,
    });
  }
  return eu;
};

export const IsAdmin = () => async (dispatch) => {
  await fetcher(dispatch, 'api/v2/AdminV2/IsAdmin', {});
};

export const getUsers = (state) => async (dispatch) => {
  await fetchUser(dispatch, state);
};

export const getCompanyDatas = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v2/AdminV2/CompanyDatas', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_COMPANY_DATA,
      payload: eu,
    });
  }
};

export const getLogs = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v2/AdminV2/GetLogs?id=${id}`, {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_LOGS,
      payload: eu,
    });
  }
};

export const getOrder = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v2/AdminV2/GetOrder?id=${id}`, {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ORDER,
      payload: eu,
    });
  }
};

export const getOrderDetail = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v2/AdminV2/GetOrderDetail?id=${id}`, {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ORDER_DETAIL,
      payload: eu,
    });
  }
};

export const getOpenOrder = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v2/AdminV2/GetOpenOrder?id=${id}`, {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_OPEN_ORDER,
      payload: eu,
    });
  }
};

export const getOrderInfo = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v2/AdminV2/GetOrderInfo?id=${id}`, {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ORDER_INFO,
      payload: eu,
    });
  }
};

export const getExchangesUser = (id) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v2/AdminV2/GetExchangesUser?userId=${id}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_USER_EXCHANGES,
      payload: eu,
    });
  }
};

export const getOrders = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v2/AdminV2/Orders', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_USER_ORDERS,
      payload: eu,
    });
  }
};

export const getOrdersGroup =
  (
    groupId,
    dateFrom = null,
    dateTo = null,
    withOrderOnStats = true,
    orderFollowers = false,
  ) =>
    async (dispatch) => {
      const date =
        (dateFrom != null ? `&from=${dateFrom}` : '') +
        (dateTo != null ? `&to=${dateTo}` : '');
      const eu = await fetcher(
        dispatch,
        `api/v2/AdminV2/OrdersGroup?groupId=${groupId}${date}&withOrderOnStats=${withOrderOnStats}&orderFollowers=${orderFollowers}`,
        {
          method: 'GET',
        },
      );
      if (eu) {
        dispatch({
          type: adminConstants.ACTION_GET_USER_ORDERS,
          payload: eu,
        });
      }
    };

export const getOrdersLimit = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v2/AdminV2/OrdersLimit', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_USER_ORDERS,
      payload: eu,
    });
  }
};

export const getTradingSummaries =
  (dateFrom = null, dateTo = null) =>
    async (dispatch) => {
      const date =
        (dateFrom != null ? `&from=${dateFrom}` : '') +
        (dateTo != null ? `&to=${dateTo}` : '');
      const eu = await fetcher(
        dispatch,
        `api/v2/AdminV2/GetTradingSummary?1${date}`,
        {
          method: 'GET',
        },
      );
      if (eu) {
        dispatch({
          type: adminConstants.ACTION_GET_TRADING_SUMMARIES,
          payload: eu,
        });
      }
    };

export const getUsersVolume =
  (dateFrom = null, dateTo = null) =>
    async (dispatch) => {
      const date =
        (dateFrom != null ? `&from=${dateFrom}` : '') +
        (dateTo != null ? `&to=${dateTo}` : '');
      const eu = await fetcher(dispatch, `api/v2/AdminV2/GetUsersVolume?1${date}`, {
        method: 'GET',
      });
      if (eu) {
        dispatch({
          type: adminConstants.ACTION_GET_USERS_VOLUME,
          payload: eu,
        });
      }
    };

export const getPositions = (userId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v2/AdminV2/getPositions?userId=${userId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ORDERS_GROUP,
      payload: eu,
    });
  }
};

export const getOpenOrdersGroup = (groupId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v2/AdminV2/GetPositionsByGroupId?groupId=${groupId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ORDERS_GROUP,
      payload: eu,
    });
  }
};

export const getAccountsGroup = (groupId, forceRefresh) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v2/Trader/GetAccountsGroup?groupId=${groupId}&forceRefresh=${forceRefresh}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ACCOUNTS_GROUP,
      payload: eu,
    });
  }
};

export const getStatUser2 = (userId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v2/AdminV2/GetStatUser2?userId=${userId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_STATS2,
      payload: eu,
    });
  }
};

export const getStatUser = (userId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v2/AdminV2/GetStatUser?userId=${userId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_STATS,
      payload: eu,
    });
  }
};

export const getStatGroup2 =
  (groupId, dateFrom = null, dateTo = null) =>
    async (dispatch) => {
      const date =
        (dateFrom != null ? `&from=${dateFrom}` : '') +
        (dateTo != null ? `&to=${dateTo}` : '');
      const eu = await fetcher(
        dispatch,
        `api/v2/AdminV2/GetStatGroup2?groupId=${groupId}${date}`,
        {
          method: 'GET',
        },
      );
      if (eu) {
        dispatch({
          type: adminConstants.ACTION_GET_STATS_GROUP2,
          payload: eu,
        });
      }
    };

export const getStatGroup =
  (groupId, dateFrom = null, dateTo = null) =>
    async (dispatch) => {
      const date =
        (dateFrom != null ? `&from=${dateFrom}` : '') +
        (dateTo != null ? `&to=${dateTo}` : '');
      const eu = await fetcher(
        dispatch,
        `api/v2/AdminV2/GetStatGroup?groupId=${groupId}${date}`,
        {
          method: 'GET',
        },
      );
      if (eu) {
        dispatch({
          type: adminConstants.ACTION_GET_STATS_GROUP,
          payload: eu,
        });
      }
    };

export const getStatsGroup = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v2/AdminV2/GetStatsGroup', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_STATS_GROUP,
      payload: eu,
    });
  }
};

export const getPaiements = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v2/AdminV2/Paiements', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_PAIEMENTS,
      payload: eu,
    });
  }
};

export const setSaveDateDebug = (val, exchange) => async (dispatch) => {
  await fetcher(
    dispatch,
    `api/v2/AdminV2/SetSaveDateDebug?exchange=${exchange}&val=${val}`,
    {
      method: 'GET',
    },
  );
};

export const getCurrentPrices = () => async (dispatch) => {
  const start = new Date();
  const eu = await fetcher(dispatch, 'api/v2/AdminV2/GetCurrentPrices', {
    method: 'GET',
  });
  if (eu) {
    const end = new Date();
    const repTime = end - start;
    dispatch({
      type: adminConstants.ACTION_GET_CURRENT_PRICES,
      payload: eu,
      repTime: repTime,
      reqDate: end,
    });
  }
};

export const getHistoricEmail = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v2/AdminV2/HistoricEmail', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_HISTORIC_EMAIL,
      payload: eu,
    });
  }
};
export const getSubscriptionGroup = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v2/AdminV2/GetSubscriptionGroup', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_SUBSCRIPTION_GROUP,
      payload: eu,
    });
  }
};

export const postInscriptionGroups = (body) => async (dispatch) => {
  await fetcher(dispatch, 'api/v2/AdminV2/UpdateInscriptionGroup', {
    method: 'POST',
    body,
  });
};

export const postSubGroups = (body) => async (dispatch) => {
  await fetcher(dispatch, 'api/v2/AdminV2/UpdateSubGroup', {
    method: 'POST',
    body,
  });
};

export const postEmails = (dataEmail) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v2/AdminV2/SendEmails', {
    method: 'POST',
    body: dataEmail,
  });
  if (eu) {
    dispatch(alertActions.alertSuccess('Email envoyé'));
  }
};

export const remove2FA = (userId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v2/AdminV2/Remove2FA?userId=${userId}`,
    {
      method: 'DELETE',
    },
  );
  if (eu) {
    dispatch(alertActions.alertSuccess('2FA supprimé'));
  }
};

export const getEmailByAccountId = (accountId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v2/AdminV2/GetEmailByAccountId?accountId=${accountId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_EMAIL_BY_ACCOUNT,
      payload: eu,
    });
  }
};

export const getEmailByUserId = (userId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v2/AdminV2/GetEmailByUserId?userId=${userId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_EMAIL_BY_USER,
      payload: eu,
    });
  }
};

export const getEmailByTriggerId = (triggerId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v2/AdminV2/GetEmailByTriggerId?triggerId=${triggerId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_EMAIL_BY_TRIGGER,
      payload: eu,
    });
  }
};

export const getEmailByOrderId = (orderId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v2/AdminV2/GetEmailByOrderId?orderId=${orderId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_EMAIL_BY_ORDER,
      payload: eu,
    });
  }
};

export const getEmailByGroupId = (groupId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v2/AdminV2/GetEmailByGroupId?groupId=${groupId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_EMAIL_BY_GROUP,
      payload: eu,
    });
  }
};

export const getEmailByTradingViewGuid =
  (tradingViewGuid) => async (dispatch) => {
    const eu = await fetcher(
      dispatch,
      `api/v2/AdminV2/GetEmailByTradingViewGuid?tradingViewGuid=${tradingViewGuid}`,
      {
        method: 'GET',
      },
    );
    if (eu) {
      dispatch({
        type: adminConstants.ACTION_GET_EMAIL_BY_TRADINGVIEW,
        payload: eu,
      });
    }
  };

export const confirmEmailAdmin = (email) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    'api/v2/AdminV2/ConfirmEmail?email=' + email,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_CONFIRM_EMAIL,
      payload: eu,
    });
  }
};

export const getProxyListInfos = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v2/AdminV2/LeaderBoard/Proxys', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_PROXYS_INFOS,
      payload: eu,
    });
  }
};

export const panicSellAllIds = (ids) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v2/AdminV2/PanicSellAll?ids=' + ids, {
    method: 'POST',
  });
  if (eu) {
    dispatch(alertActions.alertSuccess(eu));
  }
};

export const cancelAllIds = (ids) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v2/AdminV2/CancelAll?ids=' + ids, {
    method: 'POST',
  });
  if (eu) {
    dispatch(alertActions.alertSuccess(eu));
  }
};

// export const roleToUser = id => async (dispatch, getState) => {
//   const eu = await fetcher(dispatch, 'api/v2/AdminV2/RoleToUser?id=' + id, {
//     method: 'POST',
//     body: id
//   });
//   await fetchUser(dispatch);
// };
//
// export const deleteRoleToUser = id => async (dispatch, getState) => {
//   const eu = await fetcher(dispatch, 'api/v2/AdminV2/RoleToUser?id=' + id, {
//     method: 'DELETE',
//     body: id
//   });
//   await fetchUser(dispatch);
// };
