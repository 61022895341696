import React from 'react';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import CardBody from '../../components/Card/CardBody';
import Card from '../../components/Card/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { getActiveCode } from '../../helpers/localize';
import connect from 'react-redux/es/connect/connect';
import { getDateStr, getPriceStr } from '../../helpers/general';
import CardFooter from '../../components/Card/CardFooter';
import { change, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import {
  primaryColor,
  goldColor,
} from '../../assets/jss/material-dashboard-react.jsx';
import { lightColor } from '../../assets/jss/material-dashboard-react';
import { isNull } from '../../helpers/Common';
import Helper from '../../components/Hover/Helper';
import CustomInput from '../../components/CustomInput/CustomInput';
import { InputAdornment } from '@material-ui/core';
import { groupsSelector } from '../../redux/trader/selector';
import { pushGroups } from '../../redux/user/actions';

const styles = () => ({
  img: {
    maxWidth: '50px',
    maxHeight: '50px',
  },
  onClick: {
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: primaryColor,
    color: lightColor,
  },
  goldText: {
    color: goldColor,
  },
});

class ProductContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    const { groups, trader } = props;

    const pourcentage = groups[trader.GroupId];

    this.state = {
      pourcentage: isNull(pourcentage) ? 100 : pourcentage,
    };
  }
  toggleTrader = (trader) => {
    const { change, formName, groupIds } = this.props;
    let newSub = [...groupIds];
    if (newSub.includes(trader.GroupId))
      newSub = newSub.filter((x) => x !== trader.GroupId);
    else newSub.push(trader.GroupId);
    change(formName, 'groupIds', newSub);
  };

  getNewDate = () => {
    const { trader, codeLang, groupIds, nbMonth } = this.props;
    const subscription =
      trader.SubscriptionGroups &&
      trader.SubscriptionGroups.length > 0 &&
      trader.SubscriptionGroups[0].IsActive
        ? trader.SubscriptionGroups[0]
        : null;
    const isSubscription = groupIds ? groupIds.includes(trader.GroupId) : false;
    if (!isSubscription) return;
    let date = new Date();
    if (subscription) date = new Date(subscription.EndDate);
    if (nbMonth < 12) date.setMonth(date.getMonth() + nbMonth);
    if (nbMonth >= 12) date.setFullYear(date.getFullYear() + 1);
    return getDateStr(date, codeLang);
  };
  onChange = (e) => {
    if (e.target.value < 0) e.target.value = 0;
    else {
      const { groups, trader } = this.props;
      let groupsCopy = { ...groups };
      groupsCopy[trader.GroupId] = e.target.value;
      this.props.pushGroupsActions(groupsCopy);
      this.setState({ pourcentage: e.target.value });
    }
  };
  render() {
    const { classes, trader, codeLang, groupIds, translate, nbMonth } =
      this.props;
    const { pourcentage } = this.state;
    const subscription =
      trader.SubscriptionGroups &&
      trader.SubscriptionGroups.length > 0 &&
      trader.SubscriptionGroups[0].IsActive
        ? trader.SubscriptionGroups[0]
        : null;
    const isSubscription = groupIds ? groupIds.includes(trader.GroupId) : false;
    const isWoT = trader.GroupId === 1;
    const priceStr = getPriceStr(trader.Prices, nbMonth);
    let inInput = false;
    return (
      <div
        className={classes.onClick}
        onClick={() => {
          if (!inInput) this.toggleTrader(trader);
          else inInput = false;
        }}
      >
        <Card className={isSubscription ? classes.selected : ''}>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <CardMedia>
                  <Grid container justifyContent="center">
                    <img
                      className={classes.img}
                      src={trader.Image}
                      alt={trader.Name}
                    />
                  </Grid>
                </CardMedia>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <b>{trader.Name}</b>
                <br />
                <span className={classes.goldText}>
                  {priceStr === '' && translate('traders.free')}
                  {priceStr !== '' &&
                    priceStr + ' ' + translate('traders.perMonth')}
                </span>
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <GridContainer style={{ width: '100%' }}>
              <GridItem xs={12} sm={12} md={12}>
                {subscription && (
                  <span>
                    <Translate id="traders.registerUntil" />{' '}
                    {getDateStr(subscription.EndDate, codeLang)}.
                  </span>
                )}
                {!subscription && (
                  <span>
                    <Translate id="traders.noRegister" />
                  </span>
                )}
                <br />
                {!isSubscription && <span>&nbsp;</span>}
                {isSubscription && (
                  <span>
                    <Translate id="traders.extendDate" />{' '}
                    <b>{this.getNewDate()}</b>.
                  </span>
                )}
                {isSubscription && !isWoT && (
                  <GridContainer
                    item
                    alignItems="center"
                    onClick={() => (inInput = true)}
                  >
                    <GridItem xs>
                      <div style={{ marginTop: '1px', float: 'left' }}>
                        <Translate id="traders.choosePercentageShort" /> :
                        &nbsp;
                      </div>
                      <Helper
                        text={<Translate id="traders.pourcentageDetails" />}
                      />
                    </GridItem>
                    <GridItem alignItems="center" justifyContent="flex-end">
                      <div style={{ width: '80px' }}>
                        <CustomInput
                          name={trader.GroupId + '.pourcentage'}
                          // label={<Translate id="central.percentage" />}

                          formControlProps={{
                            style: { marginTop: 0 },
                          }}
                          inputProps={{
                            style: { marginTop: 0 },
                            type: 'number',
                            onChange: this.onChange,
                            className: classes.inputPourc,
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                            placeholder: translate('traders.pourcentageInfos'),
                            value: pourcentage,
                          }}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                )}
              </GridItem>
            </GridContainer>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { localize } = state;
  const formSelector = formValueSelector(props.formName);
  return {
    translate: getTranslate(localize),
    codeLang: getActiveCode(state),
    groupIds: formSelector(state, 'groupIds'),
    nbMonth: formSelector(state, 'nbMonth'),
    groups: groupsSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    pushGroupsActions: bindActionCreators(pushGroups, dispatch),
  };
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductContainer);

export default withStyles(styles)(connected);
