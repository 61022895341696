import { tokensConstants } from './constants';
import { fetcher } from '../../helpers/fetcher';

export const getAllTokens = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/token', {});

  if (eu) {
    dispatch({
      type: tokensConstants.ACTION_GET_ALL_TOKENS,
      payload: eu,
    });
  }
};
