import { createSelector } from 'reselect';
import { getSubGroup } from '../../helpers/trader';
import {
  getPairSelect,
  getSecFromPair,
  isEmpty,
  isNull,
} from '../../helpers/Common';
import { getExchangeSelect, optionsExchanges } from '../../helpers/exchange';
import { availableAmountsSelector } from '../order/selector';
import { tradersSelector } from '../trader/selector';

export const optionsSubscriptionGroupsSelector = createSelector(
  tradersSelector,
  (traders) => {
    const options = [];
    for (const trader of traders) {
      if (trader.GroupId !== 1) {
        const sg = getSubGroup(trader);
        if (sg !== null)
          options.push({ label: trader.Name, value: sg.SubscriptionGroupId });
      }
    }
    return options;
  },
);
export const strategyConfigurationSelector = (state) => {
  const { strategyConfiguration, config } = state;
  const value = config.tabStrategyValue;
  const { strategyConfigurations } = strategyConfiguration;
  return strategyConfigurations[value];
};

export const getQuoteSelect = (quote) => {
  return { label: quote, value: quote, quote };
};

export const initialValuesSelector = createSelector(
  optionsSubscriptionGroupsSelector,
  strategyConfigurationSelector,
  (optionsTraders, strategyConfiguration) => {
    let sc = null;
    if (!isNull(strategyConfiguration)) {
      sc = { ...strategyConfiguration };

      sc.BLExchanges = !isNull(sc.BLExchanges)
        ? sc.BLExchanges.map((exchange) => getExchangeSelect(exchange))
        : [];
      sc.WLExchanges = !isNull(sc.WLExchanges)
        ? sc.WLExchanges.map((exchange) => getExchangeSelect(exchange))
        : [];
      sc.BLQuotes = !isNull(sc.BLQuotes)
        ? sc.BLQuotes.map((quote) => getQuoteSelect(quote))
        : [];
      sc.WLQuotes = !isNull(sc.WLQuotes)
        ? sc.WLQuotes.map((quote) => getQuoteSelect(quote))
        : [];
      sc.BLPairs = !isNull(sc.BLPairs)
        ? sc.BLPairs.map((pair) => getPairSelect(pair))
        : [];
      sc.WLPairs = !isNull(sc.WLPairs)
        ? sc.WLPairs.map((pair) => getPairSelect(pair))
        : [];
      const SubscriptionGroupsId = [];
      if (sc.SubscriptionGroups) {
        for (const sg of sc.SubscriptionGroups) {
          SubscriptionGroupsId.push({
            label: sg.Group.Name,
            value: sg.SubscriptionGroupId,
          });
        }
      }
      sc.SubscriptionGroupsId = SubscriptionGroupsId;
      // console.log(SubscriptionGroupsId);
      if (!isNull(sc.Override)) {
        for (const overrideStr of sc.Override) {
          sc[`${overrideStr}CB`] = true;
        }
      }
    }
    // console.log(sc);
    return sc;
  },
);

export const getFormStrategyConfiguration = (state) => {
  if (
    !state ||
    !state.form ||
    !state.form.formStrategyConfiguration ||
    !state.form.formStrategyConfiguration.values
  )
    return {};
  return state.form.formStrategyConfiguration.values;
};

const tabSelectorStr = [
  'BLExchanges',
  'WLExchanges',
  'BLExchangesCB',
  'WLExchangesCB',
  'BLQuotes',
  'WLQuotes',
  'BLQuotesCB',
  'WLQuotesCB',
  'BLPairs',
  'WLPairs',
  'BLPairsCB',
  'WLPairsCB',
];
const tabSelector = [];
for (const selector of tabSelectorStr) {
  tabSelector[selector] = createSelector(
    getFormStrategyConfiguration,
    (form) => {
      return form && form[selector] ? form[selector] : '';
    },
  );
}
export const exchangesSelector = createSelector(
  tabSelector.BLExchanges,
  tabSelector.WLExchanges,
  tabSelector.BLExchangesCB,
  tabSelector.WLExchangesCB,
  (BLExchanges, WLExchanges, BLExchangesCB, WLExchangesCB) => {
    let exchanges = optionsExchanges;
    if (WLExchangesCB && WLExchanges && WLExchanges.length > 0) {
      exchanges = WLExchanges;
    }
    if (BLExchangesCB) {
      for (const ex of BLExchanges) {
        exchanges = exchanges.filter((x) => ex.label !== x.label);
      }
    }
    return exchanges;
  },
);

export const optionsQuoteSelector = createSelector(
  exchangesSelector,
  availableAmountsSelector,
  (exchanges, availableAmounts) => {
    const optionsQuote = [];
    for (const exchange of exchanges) {
      const pairs = availableAmounts.find(
        (x) => x.ExchangeName === exchange.label,
      );
      if (pairs && Object.hasOwn(pairs, 'PairSelect')) {
        for (const pair of pairs.PairSelect) {
          const quote = getSecFromPair(pair);
          if (
            !isEmpty(quote) &&
            optionsQuote.findIndex((x) => x.label === quote) === -1
          ) {
            optionsQuote.push(getQuoteSelect(quote));
          }
        }
      }
    }
    optionsQuote.sort((a, b) => a.label.localeCompare(b.label));
    return optionsQuote;
  },
);
export const quotesSelector = createSelector(
  tabSelector.BLQuotes,
  tabSelector.WLQuotes,
  tabSelector.BLQuotesCB,
  tabSelector.WLQuotesCB,
  optionsQuoteSelector,
  (BLQuotes, WLQuotes, BLQuotesCB, WLQuotesCB, optionsQuote) => {
    let quotes = optionsQuote;
    if (WLQuotesCB && WLQuotes && WLQuotes.length > 0) {
      quotes = WLQuotes;
    }
    if (BLQuotesCB) {
      for (const ex of BLQuotes) {
        quotes = quotes.filter((x) => ex.label !== x.label);
      }
    }
    // console.log(quotes);
    return quotes;
  },
);

export const optionsPairsSelector = createSelector(
  exchangesSelector,
  quotesSelector,
  availableAmountsSelector,
  (exchanges, quotes, availableAmounts) => {
    const optionsPairs = [];
    for (const exchange of exchanges) {
      const pairs = availableAmounts.find(
        (x) => x.ExchangeName === exchange.label,
      );
      if (pairs && Object.hasOwn(pairs, 'PairSelect')) {
        for (const pair of pairs.PairSelect) {
          const pairStr = pair.pair;
          const quote = getSecFromPair(pair);
          if (
            quotes.findIndex((x) => x.label === quote) !== -1 && // Quote trouvée
            !isEmpty(pairStr) &&
            optionsPairs.findIndex((x) => x.label === pairStr) === -1 // Pair n'exhiste pas deja
          ) {
            optionsPairs.push(getPairSelect(pairStr));
          }
        }
      }
    }
    optionsPairs.sort((a, b) => a.label.localeCompare(b.label));
    return optionsPairs;
  },
);
