import React from 'react';

import { withLocalize } from 'react-localize-redux';
import transSettings from '../../locales/settings.json';
import GridItem from '../../components/Grid/GridItem.jsx';
import TelegramsContainer from './TelegramsContainer';
import NotificationsContainer from './NotificationsContainer';
import TFAContainer from './TFAContainer';
import CustomTabs from '../../components/CustomTabs/CustomTabs';
import TradingContainer from './TradingContainer';
import TutorialContainer from './TutorialContainer';
import { Translate } from 'react-localize-redux';

class SettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transSettings);
    const { location } = this.props;
    const search = location.search ? location.search.toLowerCase() : '';
    let value = 0;
    // if (search.indexOf('notification') !== -1) value = 0;
    // else if (search.indexOf('2fa') !== -1) value = 1;
    // else if (search.indexOf('trading') !== -1) value = 2;
    // else if (search.indexOf('tutorial') !== -1) value = 3;
    if (search.indexOf('telegram') !== -1) value = 0;
    else if (search.indexOf('2fa') !== -1) value = 1;
    else if (search.indexOf('trading') !== -1) value = 2;

    this.state = {
      value: value,
    };
  }
  handleChange = (event, newValue) => {
    this.setState({ ...this.state, value: newValue });
  };
  render() {
    return (
      <GridItem>
        <CustomTabs
          title=""
          headerColor="primary"
          value={this.state.value}
          onChange={this.handleChange}
          tabs={[
            {
              tabName: <Translate id="settings.notifications" />,
              tabContent: <NotificationsContainer />,
            },
            {
              tabName: 'Telegram',
              tabContent: <TelegramsContainer />,
            },
            {
              tabName: '2FA',
              tabContent: <TFAContainer />,
            },
            {
              tabName: <Translate id="settings.trading" />,
              tabContent: <TradingContainer />,
            },
            {
              tabName: <Translate id="settings.tutorial" />,
              tabContent: <TutorialContainer />,
            },
          ]}
        />
      </GridItem>
    );
  }
}

export default withLocalize(SettingsPage);
