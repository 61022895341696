import { referralsConstants } from './constants';

const referralsGrid = JSON.parse(localStorage.getItem('referralsGrid'));

const defaultReferralsGrid = {
  id: 1,
  rows: [],
  sorting: [{ columnName: 'CreationDate', direction: 'desc' }],
  grouping: [],
  expandedGroups: [],
  selection: [],
  expandedRowIds: [1],
  filters: [],
  tableColumnExtensions: [],
  currentPage: 0,
  pageSize: 10,
  pageSizes: [5, 10, 20, 50, 0],
  columns: [],
  columnOrder: [
    'Action',
    'ReferralCode',
    'CampaignId',
    'Name',
    'NumberOfUniqueClicks',
    'NumberOfRegistrations',
    'NbProfitable',
    'GainInUSDT',
    'AmountWithdrawn',
    'numberWithAtLeast1Trade',
  ],
  columnWidths: [
    { columnName: 'Action', width: 125 },
    { columnName: 'ReferralCode', width: 140 },
    { columnName: 'Name', width: 100 },
    { columnName: 'NumberOfUniqueClicks', width: 140 },
    { columnName: 'NumberOfRegistrations', width: 140 },
    { columnName: 'NbProfitable', width: 230 },
    { columnName: 'GainInUSDT', width: 100 },
    { columnName: 'AmountWithdrawn', width: 150 },
    { columnName: 'numberWithAtLeast1Trade', width: 100 },
  ],
};

if (referralsGrid) {
  for (const e in defaultReferralsGrid) {
    if (!Object.hasOwn(referralsGrid, e)) {
      referralsGrid[e] = defaultReferralsGrid[e];
    }
    for (const c of defaultReferralsGrid.columnOrder) {
      if (referralsGrid.columnOrder.indexOf(c) === -1) {
        referralsGrid.columnOrder.push(c);
      }
    }
    for (const c of defaultReferralsGrid.columnWidths) {
      if (
        referralsGrid.columnWidths.filter((x) => x.columnName === c.columnName)
          .length === 0
      ) {
        referralsGrid.columnWidths.push(c);
      }
    }
  }
}

const referralsInitialState = {
  referralsGrid: referralsGrid || defaultReferralsGrid,
  referrals: [],
};

export function referrals(state = referralsInitialState, action) {
  switch (action.type) {
    case referralsConstants.REFERRALS_GRID_STATE_CHANGE_ACTION: {
      const referralsGrid = {
        ...state.referralsGrid,
        [action.partialStateName]: action.partialStateValue,
      };
      localStorage.setItem('referralsGrid', JSON.stringify(referralsGrid));
      return {
        ...state,
        referralsGrid,
      };
    }
    case referralsConstants.REFERRALS_GRID_RESET:
      return {
        ...state,
        referralsGrid: defaultReferralsGrid,
      };
    case referralsConstants.GET_CAMPAIGNS:
      return {
        ...state,
        referrals: action.payload,
      };
    case referralsConstants.POST_CAMPAIGN:
      return {
        ...state,
        referrals: state.referrals.concat(action.payload),
      };
    default:
      return state;
  }
}
