import Button from '../../components/CustomButtons/Button';
import { profilConstant } from './profil.constant';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { Translate } from 'react-localize-redux';
import React from 'react';
import { useDispatch } from 'react-redux';
import GridItem from '../../components/Grid/GridItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { changeProfil, changeStep } from '../../redux/onboarding/actions';

const ProfilContainer = () => {
  const dispatch = useDispatch();

  return (
    <Grid container>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h6" align="center">
          <Translate id="onboarding.whatDoYouWantToDo" />
        </Typography>
      </GridItem>

      {/*<GridItem xs={12} sm={12} md={3} lg={3} />*/}
      {/*<GridItem xs={12} sm={12} md={6} lg={6}>*/}
      {/*  <Button*/}
      {/*    fullWidth*/}
      {/*    color="primary"*/}
      {/*    onClick={() => {*/}
      {/*      dispatch(changeProfil(profilConstant.USER_COPY_TRADER));*/}
      {/*      dispatch(changeStep(profilConstant.STEP_ADD_EXCHANGE));*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <FileCopyIcon />*/}
      {/*    &nbsp;&nbsp;*/}
      {/*    <Translate id="onboarding.userCopier" />*/}
      {/*  </Button>*/}
      {/*</GridItem>*/}
      {/*<GridItem xs={12} sm={12} md={3} lg={3} />*/}

      <GridItem xs={12} sm={12} md={3} lg={3} />
      <GridItem xs={12} sm={12} md={6} lg={6}>
        <Button
          fullWidth
          color="primary"
          onClick={() => {
            dispatch(changeProfil(profilConstant.USER_GROUP_TRADER));
            dispatch(changeStep(profilConstant.STEP_ADD_EXCHANGE));
          }}
        >
          <GroupIcon />
          &nbsp;&nbsp;
          <Translate id="onboarding.userGroupTrader" />
        </Button>
      </GridItem>
      <GridItem xs={12} sm={12} md={3} lg={3} />

      <GridItem xs={12} sm={12} md={3} lg={3} />
      <GridItem xs={12} sm={12} md={6} lg={6}>
        <Button
          fullWidth
          color="primary"
          onClick={() => {
            dispatch(changeProfil(profilConstant.USER_SOLO_TRADER));
            dispatch(changeStep(profilConstant.STEP_ADD_EXCHANGE));
          }}
        >
          <PersonIcon />
          &nbsp;&nbsp;
          <Translate id="onboarding.userSoloTrader" />
        </Button>
      </GridItem>
      <GridItem xs={12} sm={12} md={3} lg={3} />

      <GridItem xs={12} sm={12} md={3} lg={3} />
      <GridItem xs={12} sm={12} md={6} lg={6}>
        <Button
          fullWidth
          color="primary"
          onClick={() => {
            dispatch(changeProfil(profilConstant.USER_WALLET_TRADER));
            dispatch(changeStep(profilConstant.STEP_ADD_EXCHANGE));
          }}
        >
          <AccountBalanceWalletIcon />
          &nbsp;&nbsp;
          <Translate id="onboarding.userWallet" />
        </Button>
      </GridItem>
    </Grid>
  );
};

export default ProfilContainer;
