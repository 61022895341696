import React from 'react';
import { isRequired } from '../../helpers/form';
import {
  getLeverageLimit,
  getStart,
  haveLeverage,
  triggerIsDone,
} from '../../helpers/order';
import { Translate } from 'react-localize-redux';
import {
  isBinanceFuture,
  isBybitFuture,
  isEmpty,
  parseToFloat,
} from '../../helpers/Common';
import { satoshi } from '../../helpers/general';

const between = (val, min, max) => {
  return (min !== '' && val < min) || (max !== '' && val > max);
};

const greaterThan = (val, min) => {
  return min !== '' && val < min;
};
const floatSafeRemainder = (val, step) => {
  val = parseFloat(val);
  step = parseFloat(step);
  if (isEmpty(val) || isEmpty(step)) return 0;
  const valDecCount = (val.toString().split('.')[1] || '').length;
  const stepDecCount = (step.toString().split('.')[1] || '').length;
  const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
  const valInt = parseInt(val.toFixed(decCount).replace('.', ''));
  const stepInt = parseInt(step.toFixed(decCount).replace('.', ''));
  return (valInt % stepInt) / 10 ** decCount;
};

// export const validateSmartForm = values => (dispatch, getState) => {
// export default values => (dispatch, getState) => {
export default (values, props) => {
  // console.log('validateSmartForm', values, props);
  const {
    pairInfo,
    currentPrice,
    translate,
    capital,
    stepPrice,
    lotStep,
    qtyPrim,
    qtySec,
    leverageBuy,
    leverageSell,
  } = props;
  const {
    isBuy,
    isUpdate,
    isBuyDone,
    isTemplate,
    startPrice,
    partUse,
    pourcentage,
    targetsLock,
    stopsLock,
    stopPrice,
    stopProfit,
    startTriggerPrice,
    startTriggerPriceCB,
    targets,
    trailingStopLoss,
    trailingStopLossCB,
    trailingStartCB,
    leverageCB,
    trailingStart,
    firstQty,
    secQty,
    accountId,
    leverage,
    order,
    dontDoOrder,
    dontDoStart,
    isPaperTrading,
  } = values;

  const errors = {};
  const requiredFields = ['accountId', 'pair', 'pourcentage'];
  if (!isTemplate) requiredFields.push('startPrice', 'firstQty', 'secQty');
  requiredFields.forEach((field) => {
    if (isRequired(values[field])) {
      errors[field] = <Translate id="central.required" />;
    }
  });

  let isBitmex = false;
  let isKraken = false;
  let isLeverage = false;
  let isBinanceMargin = false;
  let isBinanceFutures = false;
  let isBybitFutures = false;
  if (accountId) {
    isBitmex = accountId.exchange === 'Bitmex';
    isKraken = accountId.exchange === 'Kraken';
    isBinanceMargin = accountId.exchange === 'Binance_Margin';
    isBinanceFutures = isBinanceFuture(accountId.exchange);
    isBybitFutures = isBybitFuture(accountId.exchange);
    isLeverage = haveLeverage(
      accountId.exchange,
      isBuy,
      leverageBuy,
      leverageSell,
    );
  }

  // Buy group
  if (!isBuyDone && !isBinanceMargin) {
    if (
      !isTemplate &&
      startPrice &&
      !dontDoStart &&
      !isBinanceFutures &&
      !isBybitFutures
    ) {
      const multDown =
        pairInfo && pairInfo.MultiplierDown ? pairInfo.MultiplierDown : 0.1;
      const multUp =
        pairInfo && pairInfo.MultiplierUp ? pairInfo.MultiplierUp : 10;
      const min = currentPrice * multDown;
      const max = currentPrice * multUp;
      if (between(startPrice, min, max))
        errors.startPrice = (
          <Translate id="error.between" data={{ min, max }} />
        );
      if (floatSafeRemainder(startPrice, stepPrice) > 0)
        errors.startPrice = (
          <Translate id="error.stepPrice" data={{ val: stepPrice }} />
        );
    }
    if (pourcentage && accountId && !isBitmex) {
      const min = 0;
      const max = 100;
      // if (isLeverage && leverageCB) max = max;
      if (between(pourcentage, min, max))
        errors.pourcentage = (
          <Translate id="error.between" data={{ min, max }} />
        );
    }
    if (!isTemplate && partUse && accountId && !isBitmex) {
      const min = 0;
      let max = capital;
      if (isLeverage && leverageCB) max = parseToFloat(max * leverage, 0.01);
      if (between(partUse, min, max))
        errors.partUse = <Translate id="error.between" data={{ min, max }} />;
    }
    if (!isTemplate && !dontDoOrder && !isPaperTrading && !leverage) {
      if (
        firstQty &&
        ((!isBuy && !dontDoStart) || (isBuy && dontDoStart)) &&
        accountId &&
        !isBitmex
      ) {
        const qtyOrder = order && order.Quantity ? order.Quantity : 0;
        const min = satoshi;
        let max = parseToFloat(qtyPrim, lotStep, true) + qtyOrder;
        if (isKraken && isLeverage && leverageCB)
          max = parseToFloat(max * leverage, lotStep, true);
        if (between(firstQty, min, max))
          errors.firstQty = (
            <Translate id="error.between" data={{ min, max }} />
          );
      }
      if (
        !isTemplate &&
        secQty &&
        ((isBuy && !dontDoStart) || (!isBuy && dontDoStart)) &&
        accountId &&
        !isBitmex
      ) {
        const start = order && order.Triggers ? getStart(order.Triggers) : null;
        const qtyOrder =
          order && order.Quantity && start ? order.Quantity * start.Price : 0;
        const min = satoshi;
        let max =
          parseToFloat(qtySec, stepPrice, true) +
          parseToFloat(qtyOrder, stepPrice, true);
        if (isKraken && isLeverage && leverageCB)
          max = parseToFloat(max * leverage, stepPrice, true);
        if (between(secQty, min, max))
          errors.secQty = <Translate id="error.between" data={{ min, max }} />;
      }
    }
    if (accountId && isLeverage && leverageCB) {
      if (isRequired(values.leverage) && values.leverage !== 0) {
        errors.leverage = <Translate id="central.required" />;
      }
      const min = getLeverageLimit(
        'min',
        accountId.exchange,
        isBuy,
        leverageBuy,
        leverageSell,
      );
      const max = getLeverageLimit(
        'max',
        accountId.exchange,
        isBuy,
        leverageBuy,
        leverageSell,
      );
      if (between(leverage, min, max))
        errors.leverage = <Translate id="error.between" data={{ min, max }} />;
    }
    if (startTriggerPriceCB && !dontDoStart) {
      // const min = currentPrice;
      // const max = currentPrice * 2;
      // if (between(startTriggerPrice, min, max)) {
      //   errors['startTriggerPrice'] = (
      //     <Translate id="error.between" data={{ min, max }} />
      //   );
      // }
      if (isRequired(startTriggerPrice)) {
        errors.startTriggerPrice = <Translate id="central.required" />;
      }
    }
    if (trailingStartCB && !dontDoStart) {
      const min = 0.01;
      const max = 20;
      // const min = isBuy ? 0.01 : -20;
      // const max = isBuy ? 20 : -0.01;
      if (between(trailingStart, min, max)) {
        errors.trailingStart = (
          <Translate id="error.between" data={{ min, max }} />
        );
      }
      if (isRequired(trailingStart)) {
        errors.trailingStart = <Translate id="central.required" />;
      }
    }
  }

  // Targets
  if (targetsLock === false && targets) {
    let pourcentage = 0;
    let i = 0;
    const targetsArrayErrors = [];
    for (const target of targets) {
      const valPourc = parseFloat(target.pourcentage);
      if (!isNaN(valPourc)) pourcentage += valPourc;
      if (triggerIsDone(target.target)) continue;

      if (
        (isEmpty(target.pourcentage) && !isEmpty(target.price)) ||
        greaterThan(parseFloat(target.pourcentage), 0.01)
      )
        targetsArrayErrors.push(
          translate('error.lineIndicator', {
            info: translate('smartTrade.part'),
            line: i + 1,
          }) + translate('error.greater', { val: 0.01 }).toLowerCase(),
        );
      // else if (!isEmpty(target.pourcentage)) {
      //   var quantityTarget = (firstQty * target.pourcentage) / 100;
      //   if (quantityTarget < lotStep) {
      //     targetsArrayErrors.push(
      //       translate('error.lineIndicator', {
      //         info: translate('smartTrade.quantity'),
      //         line: i + 1
      //       }) + translate('error.greater', { val: lotStep }).toLowerCase()
      //     );
      //   }
      // }
      if (target.price) {
        const limit = isBuyDone ? currentPrice : startPrice;
        const min = isBuy ? limit : 0;
        const max = isBuy ? '' : limit;
        if (between(target.price, min, max)) {
          targetsArrayErrors.push(
            translate('error.lineIndicator', {
              info: translate('smartTrade.price'),
              line: i + 1,
            }) +
              (isBuy
                ? translate('error.greater', { val: min }).toLowerCase()
                : translate('error.between', { min, max }).toLowerCase()),
          );
          if (target.profit) {
            const min = 0;
            if (greaterThan(target.profit, min)) {
              targetsArrayErrors.push(
                translate('error.lineIndicator', {
                  info: translate('smartTrade.profit'),
                  line: i + 1,
                }) + translate('error.greater', { val: min }).toLowerCase(),
              );
            }
          }
        }

        if (target.trailingCB) {
          const min = 0.01;
          const max = 20;
          // const min = isBuy ? -20 : 0.01;
          // const max = isBuy ? -0.01 : 20;
          const idName = `targets[${i}].trailing`;
          if (between(target.trailing, min, max)) {
            targetsArrayErrors.push(
              translate('error.lineIndicator', {
                info: 'Trailing',
                line: i + 1,
              }) + translate('error.between', { min, max }).toLowerCase(),
            );
          }
          if (isRequired(target.trailing)) {
            errors[idName] = <Translate id="central.required" />;
          }
        }
      }

      if (floatSafeRemainder(target.price, stepPrice) > 0)
        targetsArrayErrors.push(
          translate('error.lineIndicator', {
            info: translate('smartTrade.price'),
            line: i + 1,
          }) +
            translate('error.stepPrice', {
              val: stepPrice,
            }).toLowerCase(),
        );
      i++;
    }
    if (pourcentage > 100) {
      targetsArrayErrors.push(translate('error.sumAlert'));
    }
    if (targetsArrayErrors.length > 0)
      errors.targets = targetsArrayErrors.join('\n');
    // }
  }

  // StopLoss
  if (stopsLock === false) {
    if (!isTemplate && isRequired(stopPrice)) {
      errors.stopPrice = <Translate id="central.required" />;
    }
    const rangeLeverage = 1;
    if (stopPrice) {
      // if (!trailingStopLossCB)
      if (floatSafeRemainder(stopPrice, stepPrice) > 0)
        errors.stopPrice = (
          <Translate id="error.stepPrice" data={{ val: stepPrice }} />
        );
      const limit = isUpdate || dontDoStart ? currentPrice : startPrice;
      const minBuy = limit - limit * rangeLeverage;
      const min = isBuy ? minBuy : limit;
      const max = isBuy ? limit : '';
      if (!isUpdate && !dontDoStart && between(stopPrice, min, max))
        errors.stopPrice = isBuy ? (
          <Translate id="error.between" data={{ min, max }} />
        ) : (
          <Translate id="error.lessThanBuy" data={{ val: min }} />
        );
    }
    if (stopProfit && !isUpdate) {
      const min = -100 * rangeLeverage;
      const max = 0;
      if (!dontDoStart && between(stopProfit, min, max))
        errors.stopProfit = (
          <Translate id="error.between" data={{ min, max }} />
        );
    }
    // todo check profitSL
    if (trailingStopLossCB) {
      const min = isBinanceFutures ? -5 : -20;
      const max = -0.01;
      // const min = isBuy ? -20 : 0.01;
      // const max = isBuy ? -0.01 : 20;
      if (!dontDoStart && between(trailingStopLoss, min, max))
        errors.trailingStopLoss = (
          <Translate id="error.between" data={{ min, max }} />
        );
    }
  }
  return errors;
};
