import React from 'react';
import { successColor } from '../../assets/jss/material-dashboard-react';
import { Translate } from 'react-localize-redux';
import GridItem from '../../components/Grid/GridItem.jsx';
import Card from '../../components/Card/Card.jsx';
import MyTooltip from '../../components/MyTooltip';
import { ShowAccount } from '../../components/Fields/LogoContainer';
import Grid from '@material-ui/core/Grid';
import Beenhere from '@material-ui/icons/Beenhere';
import { getTotalUSD, isReferral } from '../../helpers/exchange';

const ExchangeLiteContainer = ({ exchange }) => {
  if (!exchange) return <div></div>;
  const title = ShowAccount(exchange);
  let totalUSD = getTotalUSD(exchange);

  if (totalUSD === 0) {
    totalUSD = exchange?.Capital;
  }
  return (
    <Card style={{ marginTop: '5px' }}>
      <Grid container alignItems="center">
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          {title}
        </GridItem>
        <GridItem xs container>
          {exchange.Capital === null ? totalUSD : exchange.Capital} $
        </GridItem>
        {isReferral && (
          <GridItem xs container justifyContent="flex-end">
            <MyTooltip
              text={<Translate id="exchanges.isNewBinance" />}
              enterDelay={200}
              leaveDelay={200}
            >
              <Beenhere
                htmlColor={successColor}
                style={{ marginTop: '-20px' }}
              />
            </MyTooltip>
          </GridItem>
        )}
      </Grid>
    </Card>
  );
};

export default ExchangeLiteContainer;
