import React, { useEffect } from 'react';

import Responsive from '../../layouts/Responsive';

import SmartTradePageDesktop from './SmartTradePage.desktop';
import SmartTradePageMobile from './SmartTradePage.mobile';
import { batch, useDispatch } from 'react-redux';
import { isNull } from '../../helpers/Common';
import {
  getAvailableAmount,
  getMyGroups,
  getOrder,
} from '../../redux/order/actions';
import { getExchanges } from '../../redux/user/actions';
import { getTraders } from '../../redux/trader/actions';
import { useParams } from 'react-router';

function SmartTradePage(props) {
  const { id: orderId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    batch(() => {
      if (!isNaN(orderId) && !isNull(orderId)) {
        dispatch(getOrder(orderId));
      }
      dispatch(getMyGroups());
      dispatch(getExchanges());
      dispatch(getAvailableAmount());
      dispatch(getTraders());
    });
  }, []);
  return (
    <Responsive
      mobile={<SmartTradePageMobile {...props} />}
      desktop={<SmartTradePageDesktop {...props} />}
    />
  );
}

export default SmartTradePage;
