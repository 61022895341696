import Grid from '@material-ui/core/Grid';
import { myFacebookLink, myTwitterLink } from '../../helpers/externalLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTelegramPlane,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import packageJson from '../../../package.json';
import React from 'react';
import GridItem from '../Grid/GridItem';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Link from '@material-ui/core/Link';
import withStyles from '@material-ui/core/styles/withStyles';
import { linkStyle } from '../../assets/jss/material-dashboard-react/components/linkStyle';
import { getActiveCode, getTelegramChannel } from '../../helpers/localize';

class NetworkButtons extends React.PureComponent {
  render() {
    const { getActiveCode, classes } = this.props;
    const telegramLink = getTelegramChannel(getActiveCode);
    return (
      <Grid container>
        <GridItem xs={3} sm={3} md={3}>
          <Link
            className={classes.networkLink}
            href={telegramLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTelegramPlane} size="lg" />
          </Link>
        </GridItem>
        <GridItem xs={3} sm={3} md={3}>
          <Link
            className={classes.networkLink}
            href={myFacebookLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} size="lg" />
          </Link>
        </GridItem>
        <GridItem xs={3} sm={3} md={3}>
          <Link
            className={classes.networkLink}
            href={myTwitterLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} size="lg" />
          </Link>
        </GridItem>
        <GridItem xs={3} sm={3} md={3}>
          <small>v{packageJson.version}</small>
        </GridItem>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getActiveCode: getActiveCode(state),
  };
};

export default withStyles(linkStyle)(
  withLocalize(connect(mapStateToProps, null)(NetworkButtons)),
);
