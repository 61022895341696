import { loadingConstants } from './loading.constants';

const initialState = {
  nbReq: 0,
  type: 'OFF',
  message: '',
};

export function loading(state = initialState, action) {
  switch (action.type) {
    case loadingConstants.LOADING_ON:
      return {
        nbReq: state.nbReq + 1,
        type: state.nbReq + 1 > 0 ? 'ON' : 'OFF',
        message: action.message,
      };
    case loadingConstants.LOADING_OFF:
      return {
        nbReq: state.nbReq - 1,
        type: state.nbReq - 1 > 0 ? 'ON' : 'OFF',
        message: action.message,
      };
    default:
      return state;
  }
}
