import { adminConstants } from './constants';

// const exchanges = JSON.parse(localStorage.getItem('exchanges'));
const initialState = {
  users: [],
  order: {},
  orderDetail: '',
  orderInfo: [],
  openOrder: '',
  orders: [],
  logs: null,
  ordersGroup: [],
  exchanges: [],
  accountsGroup: [],
  statsGroup: [],
  stats: [],
  tradingSummaries: [],
  usersVolume: [],
  monthlyStatsGroup: {},
  historicEmail: [],
  paiements: [],
  subscriptionsGroups: [],
  currentPrices: {},
  reqDate: new Date(),
  repTime: 0,
  companyDatas: null,
};

export function admin(state = initialState, action) {
  switch (action.type) {
    case adminConstants.ACTION_GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case adminConstants.ACTION_GET_LOGS:
      return {
        ...state,
        logs: action.payload,
      };
    case adminConstants.ACTION_GET_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case adminConstants.ACTION_GET_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: action.payload,
      };
    case adminConstants.ACTION_GET_OPEN_ORDER:
      return {
        ...state,
        openOrder: action.payload,
      };
    case adminConstants.ACTION_GET_ORDER_INFO:
      return {
        ...state,
        orderInfo: action.payload,
      };
    case adminConstants.ACTION_GET_TRADING_SUMMARIES:
      return {
        ...state,
        tradingSummaries: action.payload,
      };
    case adminConstants.ACTION_GET_USERS_VOLUME:
      return {
        ...state,
        usersVolume: action.payload,
      };
    case adminConstants.ACTION_GET_ORDERS_GROUP:
      return {
        ...state,
        ordersGroup: action.payload,
      };
    case adminConstants.ACTION_GET_HISTORIC_EMAIL:
      return {
        ...state,
        historicEmail: action.payload,
      };
    case adminConstants.ACTION_GET_ACCOUNTS_GROUP:
      return {
        ...state,
        accountsGroup: action.payload,
      };
    case adminConstants.ACTION_GET_STATS_GROUP:
      return {
        ...state,
        statsGroup: action.payload,
      };
    case adminConstants.ACTION_GET_STATS_GROUP2:
      return {
        ...state,
        statsGroup2: action.payload,
      };
    case adminConstants.ACTION_GET_STATS:
      return {
        ...state,
        stats: action.payload,
      };
    case adminConstants.ACTION_GET_STATS2:
      return {
        ...state,
        stats2: action.payload,
      };
    case adminConstants.ACTION_GET_USER_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case adminConstants.ACTION_GET_USER_EXCHANGES:
      return {
        ...state,
        exchanges: action.payload,
      };
    case adminConstants.ACTION_GET_CURRENT_PRICES:
      return {
        ...state,
        currentPrices: action.payload,
        reqDate: action.reqDate,
        repTime: action.repTime,
      };
    case adminConstants.ACTION_GET_COMPANY_DATA:
      return {
        ...state,
        companyDatas: action.payload,
      };
    case adminConstants.ACTION_GET_PAIEMENTS:
      return {
        ...state,
        paiements: action.payload,
      };
    case adminConstants.ACTION_GET_SUBSCRIPTION_GROUP:
      return {
        ...state,
        subscriptionsGroups: action.payload,
      };
    case adminConstants.ACTION_GET_EMAIL_BY_ACCOUNT:
      return {
        ...state,
        emailByAccount: action.payload,
      };
    case adminConstants.ACTION_GET_EMAIL_BY_USER:
      return {
        ...state,
        emailByUser: action.payload,
      };
    case adminConstants.ACTION_GET_EMAIL_BY_TRIGGER:
      return {
        ...state,
        emailByTrigger: action.payload,
      };
    case adminConstants.ACTION_GET_EMAIL_BY_ORDER:
      return {
        ...state,
        emailByOrder: action.payload,
      };
    case adminConstants.ACTION_GET_EMAIL_BY_GROUP:
      return {
        ...state,
        emailByGroup: action.payload,
      };
    case adminConstants.ACTION_GET_EMAIL_BY_TRADINGVIEW:
      return {
        ...state,
        emailByTradingView: action.payload,
      };
    case adminConstants.ACTION_GET_PROXYS_INFOS:
      return {
        ...state,
        leaderBoardProxys: action.payload,
      };
    default:
      return state;
  }
}
