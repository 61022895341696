import React from 'react';
import { connect } from 'react-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';

import { bindActionCreators } from 'redux';
import { getAccountsGroup } from '../../../redux/admin/actions';

import { withLocalize } from 'react-localize-redux';
import Button from '../../../components/CustomButtons/Button';
import ExchangeContainer from '../../Accounts/ExchangeContainer';
import { isNull } from '../../../helpers/Common';
import SwitchUI from '@material-ui/core/Switch/Switch';

class ShowAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAccounts: false,
      forceRefresh: false,
    };
  }

  render() {
    const { match, accounts, showButton, showPositions, isAdmin } = this.props;
    const { showAccounts } = this.state;
    const groupId = match.params.id;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <Button
          color={'primary'}
          onClick={() => {
            if (!showAccounts) {
              this.props.getAccountsGroupActions(
                groupId,
                this.state.forceRefresh,
              );
            }
            this.setState({ showAccounts: !showAccounts });
          }}
        >
          {isNull(showPositions) || showPositions === false
            ? 'Show Account'
            : 'Show Position'}
        </Button>
        {showButton && <span>Force Refresh: </span>}
        {showButton && (
          <SwitchUI
            checked={this.state.forceRefresh}
            onChange={() => {
              this.setState({ forceRefresh: !this.state.forceRefresh });
            }}
            color="primary"
          />
        )}
        {showAccounts && (
          <GridContainer>
            {accounts &&
              accounts.map((account) => {
                return (
                  <GridItem
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={3}
                    key={account.Id}
                  >
                    <ExchangeContainer
                      exchange={account}
                      showButton={showButton}
                      showPositions={showPositions}
                      isAdmin={isAdmin}
                    />
                  </GridItem>
                );
              })}
          </GridContainer>
        )}
      </GridItem>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    accounts: admin.accountsGroup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountsGroupActions: bindActionCreators(getAccountsGroup, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(ShowAccounts),
);
