import React from 'react';

// @material-ui/core components
import GridContainer from '../../components/Grid/GridContainer.jsx';

import { Translate, withLocalize } from 'react-localize-redux';
import GridItem from '../../components/Grid/GridItem.jsx';
import TextField from '@material-ui/core/TextField';
import Button from '../../components/CustomButtons/Button';
import { Check } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addTrader } from '../../redux/trader/actions';
import DialogAddTrader from '../Traders/DialogAddTrader';

class AddTraderContainer extends React.Component {
  componentDidMount() {}

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validReference = () => {
    const { reference } = this.state;
    this.props.addTraderActions(reference);
  };

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={8} md={8}>
          <TextField
            name="reference"
            id="reference"
            onChange={this.handleChange}
            label={<Translate id="traders.referenceTrader" />}
            fullWidth={true}
            required
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Button
            color="info"
            // position="center"
            fullWidth
            size={'sm'}
            onClick={this.validReference}
          >
            <Check style={{ margin: '4px' }} />
            <Translate id="traders.addTrader" />
          </Button>
        </GridItem>
        <DialogAddTrader />
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTraderActions: bindActionCreators(addTrader, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(AddTraderContainer),
);
