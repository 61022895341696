import React from 'react';
import { push } from 'connected-react-router';
import { changeShowDialog, changeStep } from '../../redux/onboarding/actions';
import { Translate } from 'react-localize-redux';
import { exchangesSelector } from '../../redux/user/selector';
import { getProfilUserSelector } from '../../redux/onboarding/selector';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/CustomButtons/Button';
import GridItem from '../../components/Grid/GridItem';
import ExchangeLiteContainer from '../../views/Accounts/ExchangeLiteContainer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { profilConstant } from './profil.constant';

const useStyles = makeStyles((theme) => ({
  yesIcon: {
    alignContent: 'center',
  },
  noIcon: {
    alignContent: 'center',
  },
}));

const NewPopupContentExchange = () => {
  const dispatch = useDispatch();
  const exchanges = useSelector(exchangesSelector);
  const classes = useStyles();
  const profilUser = useSelector(getProfilUserSelector);

  return (
    <>
      <Typography variant="h6" align="center">
        <Translate id="onboarding.addAnotherExchange" />
      </Typography>
      <Grid container>
        {exchanges &&
          exchanges.map((exchange) => {
            return (
              <GridItem xs={12} sm={6} md={6} lg={4} xl={4} key={exchange.Id}>
                <ExchangeLiteContainer exchange={exchange} />
              </GridItem>
            );
          })}
      </Grid>
      <Grid container justifyContent="center" alignContent="center">
        <Button
          edge="start"
          title="yes"
          color="primary"
          onClick={() => {
            dispatch(changeStep(profilConstant.STEP_ADD_EXCHANGE));
          }}
          aria-label="comeback to add exchange"
          className={classes.yesIcon}
        >
          <Translate id="central.yes" />
        </Button>
        &nbsp;&nbsp;
        <Button
          edge="end"
          title="no"
          color="primary"
          onClick={() => {
            if (profilUser === profilConstant.USER_SOLO_TRADER) {
              dispatch(push('/smartTrade'));
              dispatch(changeShowDialog(false));
            } else if (profilUser === profilConstant.USER_COPY_TRADER) {
              dispatch(push('/traders'));
              dispatch(changeShowDialog(false));
            } else if (profilUser === profilConstant.USER_WALLET_TRADER) {
              dispatch(push('/accounts'));
              dispatch(changeShowDialog(false));
            } else if (profilUser === profilConstant.USER_GROUP_TRADER) {
              dispatch(changeStep(profilConstant.STEP_ADD_TRADER));
            }
          }}
          aria-label="comeback to first user choice"
          className={classes.noIcon}
        >
          <Translate id="central.no" />
        </Button>
      </Grid>
    </>
  );
};

export default NewPopupContentExchange;
