import React from 'react';
import { connect } from 'react-redux';
import { getTranslate, Translate } from 'react-localize-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { bindActionCreators } from 'redux';
import Divider from '@material-ui/core/Divider/Divider';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

import { getActiveCode, getIndexLang } from '../../helpers/localize';
import {
  successColor,
  dangerColor,
} from '../../assets/jss/material-dashboard-react.jsx';
import { getOrdersGroup, getStatGroup } from '../../redux/admin/actions';

import { isNull } from '../../helpers/Common';

const styles = {
  divTr: {
    display: 'flex',
    flexDirection: 'row',
  },
  divTd: {
    width: 'auto',
    flex: 1,
    lineHeight: '60px',
    textAlign: 'center',
    height: '60px',
    fontWeight: '700',
  },
};

class MonthlyStats extends React.PureComponent {
  render() {
    const { classes, stats, groupId, showOnly, codeLang } = this.props;
    const enableClick = isNull(showOnly) ? false : showOnly;
    const indexLang = getIndexLang(codeLang);
    const monthsStr = [
      [
        'ANNÉE',
        'JAN',
        'FÉV',
        'MARS',
        'AVR',
        'MAI',
        'JUIN',
        'JUIL',
        'AOÛT',
        'SEP',
        'OCT',
        'NOV',
        'DÉC',
        'TOTAL',
      ],
      [
        'YEAR',
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
        'TOTAL',
      ],
      [
        'ГОД',
        'ЯНВ',
        'ФЕВ',
        'МАР',
        'АПР',
        'МАЙ',
        'ИЮН',
        'ИЮЛ',
        'АВГ',
        'СЕН',
        'ОКТ',
        'НОЯ',
        'ДЕК',
        'ИТОГО',
      ],
    ];
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const table = [];
    const tableDiv = [];
    const tableHeader = [];

    for (const monthStr of monthsStr[indexLang]) {
      tableHeader.push(<div className={classes.divTd}>{monthStr}</div>);
    }
    tableDiv.push(<div className={classes.divTr}>{tableHeader}</div>);
    for (const year in stats) {
      const tableYear = [];
      const divTableYear = [];
      let total = 0;
      for (const month in months) {
        const iMonth = months[month];
        if (isNull(stats[year][iMonth])) {
          tableYear.push(<td>&nbsp;</td>);
          divTableYear.push(<div className={classes.divTd}>&nbsp;</div>);
        } else {
          const val = stats[year][iMonth];
          tableYear.push(<td>{val.toFixed(2)}</td>);
          divTableYear.push(
            <div className={classes.divTd}>
              <div
                style={{
                  color: val >= 0 ? successColor : dangerColor,
                  cursor: enableClick ? 'pointer' : '',
                }}
                onClick={() => {
                  if (enableClick) {
                    const dateFrom = new Date(parseInt(year), iMonth - 1, 1);
                    const dateTo = new Date(dateFrom);
                    dateTo.setMonth(iMonth);
                    const from = dateFrom.toLocaleString();
                    const to = dateTo.toLocaleString();
                    this.props.getOrdersGroupActions(groupId, from, to);
                    this.props.getStatGroupActions(groupId, from, to);
                  }
                }}
              >
                {val.toFixed(2)}
              </div>
            </div>,
          );
          total += val;
        }
      }
      table.push(
        <tr>
          <td>{year}</td>
          {tableYear}
          <td>{total.toFixed(2)}</td>
        </tr>,
      );
      tableDiv.push(<Divider />);
      tableDiv.push(
        <div className={classes.divTr}>
          <div className={classes.divTd}>{year}</div>
          {divTableYear}
          <div className={classes.divTd}>{total.toFixed(2)}%</div>
        </div>,
      );
    }

    return (
      <div>
        {tableDiv}
        <Divider />
        <div style={{ textAlign: 'center', marginBottom: '5px' }}>
          <div className="icons-text">
            <ErrorOutline />
          </div>
          <Translate
            id="traders.pastDontTellFuture"
            options={{ renderInnerHtml: true }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { localize } = state;
  return {
    translate: getTranslate(localize),
    codeLang: getActiveCode(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrdersGroupActions: bindActionCreators(getOrdersGroup, dispatch),
    getStatGroupActions: bindActionCreators(getStatGroup, dispatch),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MonthlyStats),
);
