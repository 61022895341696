import React from 'react';
import { connect } from 'react-redux';
import GridContainer from '../../../components/Grid/GridContainer';
import {
  currentPriceSelector
} from '../../../helpers/Common';
import { change, formValueSelector, touch } from 'redux-form';
import { Translate, getTranslate } from 'react-localize-redux';
import GridItem from '../../../components/Grid/GridItem';
import { bindActionCreators } from 'redux';
import { withWidth } from '@material-ui/core';
import TriggerDoneLineContainer from './TriggerDoneLineContainer';
import TriggerLineContainer from './TriggerLineContainer';

class TriggerLinesContainer extends React.PureComponent {
  render() {
    const {
      formName,
      name,
      triggers,
      isBuy,
      translate,
      isTemplate,
    } = this.props;

    const isStart = name === 'starts';
    const isStop = name === 'stops';
    const isTargets = name === 'targets';
    const hasManyOrTargets = (triggers && triggers.length > 1) || isTargets;

    //Length
    const partLength = isTemplate ? 5 : 3;
    const priceLength = hasManyOrTargets ? (isStart ? 8 : 5) : isStart ? 11 : 8;
    const profitLength = isTemplate ? (!hasManyOrTargets ? 11 : 6) : 3;

    //Text
    let stText = 'smartTrade.';
    if (isStart) stText += isBuy ? 'buy' : 'sell';
    else if (isStop) stText += isBuy ? 'stopLossSell' : 'stopLossBuy';
    else stText += 'price';
    const partText = translate('smartTrade.part');
    const priceText = stText;
    const profitText = translate('smartTrade.profit');
    let notDoneIndex = -1;
    return (
      <div style={{ marginBottom: '10px' }}>
        {hasManyOrTargets && (
          <GridContainer>
            <GridItem xs={partLength} sm={partLength} md={partLength}>
              {partText}
            </GridItem>
            {!isTemplate && (
              <GridItem xs={priceLength} sm={priceLength} md={priceLength}>
                <Translate id={priceText} />
              </GridItem>
            )}
            {!isStart && (
              <GridItem xs={profitLength} sm={profitLength} md={profitLength}>
                {profitText}
              </GridItem>
            )}
          </GridContainer>
        )}
        <TriggerDoneLineContainer
          formName={formName}
          name={name}
          triggers={triggers}
        />
        {triggers.map((trigger, index) => {
          if (trigger.isDone) return;
          notDoneIndex++;
          return <TriggerLineContainer key={index} index={index} trigger={trigger} notDoneIndex={notDoneIndex} {...this.props} />;
        })}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { localize } = state;
  const formSelector = formValueSelector(props.formName);
  return {
    triggers: formSelector(state, props.name),
    isBuy: formSelector(state, 'isBuy'),
    translate: getTranslate(localize),
    isTemplate: formSelector(state, 'isTemplate'),
    currentPrice: currentPriceSelector(state, props),
    startAveragePrice: formSelector(state, 'startAveragePrice'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    touch: bindActionCreators(touch, dispatch),
  };
};

export default withWidth()(
  connect(mapStateToProps, mapDispatchToProps)(TriggerLinesContainer),
);
