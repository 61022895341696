import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
} from 'react-share';
import copy from 'copy-to-clipboard';
import ShareIcon from '@material-ui/icons/Share';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const CustomizedMenus = ({ referrallink, translate }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const title = translate('referrals.textSocialNetwork');

  // const shareUrl = 'https://walloftraders.com?ref=14sad5sa4d54';

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        style={{ height: '16px', width: '16px' }}
      >
        <ShareIcon fontSize="small" style={{ height: '16px', width: '16px' }} />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          onClick={() => {
            copy(referrallink);
          }}
        >
          <ListItemIcon>
            <FileCopyIcon size={32} round="true" />
          </ListItemIcon>
          <ListItemText>
            <ListItemText primary="Copy" />
          </ListItemText>
        </StyledMenuItem>

        <StyledMenuItem>
          {/* ***************Twitter******************* */}
          <TwitterShareButton url={referrallink} quote={title}>
            <ListItemIcon>
              <TwitterIcon size={32} round="true" />
              <ListItemText primary="Twitter" style={{ marginLeft: '20px' }} />
            </ListItemIcon>
          </TwitterShareButton>
        </StyledMenuItem>
        <StyledMenuItem>
          {/* ***************FACEBOOK******************* */}
          <FacebookShareButton
            url={referrallink}
            // quote={<Translate id="referrals.textSocialNetwork" options={{ renderInnerHtml: true }} />}
            quote={title}
          >
            <ListItemIcon>
              <FacebookIcon size={32} round="true" />
              <ListItemText primary="Facebook" style={{ marginLeft: '20px' }} />
            </ListItemIcon>
          </FacebookShareButton>
        </StyledMenuItem>
        <StyledMenuItem>
          {/* ***************TELEGRAM******************* */}
          <TelegramShareButton url={referrallink} title={title}>
            <ListItemIcon>
              <TelegramIcon size={32} round="true" />
              <ListItemText primary="Telegram" style={{ marginLeft: '20px' }} />
            </ListItemIcon>
          </TelegramShareButton>
        </StyledMenuItem>
        <StyledMenuItem>
          {/* ***************LINKEDIN******************* */}
          <LinkedinShareButton url={referrallink} title={title}>
            <ListItemIcon>
              <LinkedinIcon size={32} round="true" />
              <ListItemText primary="Linkedin" style={{ marginLeft: '20px' }} />
            </ListItemIcon>
          </LinkedinShareButton>
        </StyledMenuItem>
        <StyledMenuItem>
          {/* ***************REDDIT******************* */}
          <RedditShareButton
            url={referrallink}
            title={title}
            // windowWidth={660}
            // windowHeight={460}
          >
            <ListItemIcon>
              <RedditIcon size={32} round="true" />
              <ListItemText primary="Reddit" style={{ marginLeft: '20px' }} />
            </ListItemIcon>
          </RedditShareButton>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.localize),
  };
}

export default connect(mapStateToProps)(CustomizedMenus);
