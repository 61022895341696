import { fetcher } from '../../helpers/fetcher';
import { configConstants } from './constants';

export const resetOrderColumns = () => ({
  type: configConstants.ORDERS_GRID_RESET,
});

export const resetFollowersColumns = () => ({
  type: configConstants.FOLLOWERS_GRID_RESET,
});

export const createExchangesGridAction = (
  partialStateName,
  partialStateValue,
) => {
  return {
    type: configConstants.EXCHANGES_GRID_STATE_CHANGE_ACTION,
    partialStateName,
    partialStateValue,
  };
};

export const createOrdersGridAction = (partialStateName, partialStateValue) => {
  if (partialStateName === 'pageSize' && partialStateValue === 0)
    partialStateValue = 50;
  return {
    type: configConstants.ORDERS_GRID_STATE_CHANGE_ACTION,
    partialStateName,
    partialStateValue,
  };
};

export const createFollowersGridAction = (
  partialStateName,
  partialStateValue,
) => {
  if (partialStateName === 'pageSize' && partialStateValue === 0)
    partialStateValue = 50;
  return {
    type: configConstants.FOLLOWERS_GRID_STATE_CHANGE_ACTION,
    partialStateName,
    partialStateValue,
  };
};

export const manageFollowersGridAction = (
  partialStateName,
  partialStateValue,
) => {
  if (partialStateName === 'pageSize' && partialStateValue === 0)
    partialStateValue = 50;
  return {
    type: configConstants.FOLLOWERS_GRID_STATE_CHANGE_ACTION,
    partialStateName,
    partialStateValue,
  };
};

export const changeTabOrders = (value) => async (dispatch) => {
  dispatch({
    type: configConstants.TAB_ORDERS,
    payload: value,
  });
};

export const changeTheme = (value) => async (dispatch) => {
  dispatch({
    type: configConstants.ACTION_CHANGE_THEME,
    payload: value,
  });
  localStorage.setItem('theme', JSON.stringify(value));
};

export const changeNoPopupTrading = (value) => async (dispatch) => {
  dispatch({
    type: configConstants.ACTION_CHANGE_NO_POPUP_TRADING,
    payload: value,
  });
  localStorage.setItem('noPopupTrading', JSON.stringify(value));
};

export const collapseMenu = (value) => async (dispatch) => {
  dispatch({
    type: configConstants.COLLAPSE_MENU,
    payload: value,
  });
  localStorage.setItem('collapseMenu', JSON.stringify(value));
};

export const changeTabStrategyValue = (value) => async (dispatch) => {
  dispatch({
    type: configConstants.TAB_STRATEGY_VALUE,
    payload: value,
  });
};

export const getUserNotifications =
  (email = null, code = null) =>
  async (dispatch) => {
    let request = 'api/v1/Settings/Notification';
    if (email !== null) request += `?email=${email}&code=${code}`;

    const res = await fetcher(dispatch, request, {});
    if (res) {
      dispatch({
        type: configConstants.GET_NOTIFICATION,
        payload: res,
      });
    } else {
      dispatch({
        type: configConstants.NOTIFICATION_ERROR,
        payload: true,
      });
    }
  };

export const putUserNotifications =
  (userNotification, email = null, code = null) =>
  async (dispatch) => {
    await fetcher(dispatch, 'api/v1/Settings/Notification', {
      method: 'PUT',
      body: {
        newUserInfos: userNotification,
        email,
        code,
      },
    });
  };
