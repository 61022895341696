import React from 'react';
import { bindActionCreators } from 'redux';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import {
  SortingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  IntegratedPaging,
  PagingState,
  SummaryState,
  IntegratedSummary,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  PagingPanel,
  TableColumnReordering,
  DragDropProvider,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  FollowersActionButtonsProvider,
  DollarProvider,
  WillFollowProvider,
  DateProvider,
  ColorUSDProvider,
  PourcentageProvider,
} from '../../helpers/table';
import { getFollowers } from '../../redux/trader/actions';
import { manageFollowersGridAction } from '../../redux/config/actions';
import { getActiveCode } from '../../helpers/localize';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import { getIssue } from '../../helpers/trader';
import {
  isBinanceFuture,
  isBybitFuture,
  isEmpty,
  isNull,
} from '../../helpers/Common';
import { isAdminSelector } from '../../redux/authentication/selector';
import { isHedgeMode } from '../../helpers/exchange';

class TableUIFollowers extends React.PureComponent {
  render() {
    const {
      rows,
      columns,
      columnWidths,
      filters,
      currentPage,
      pageSize,
      pageSizes,
      columnOrder,
      sorting,
      tableColumnExtensions,
      onFiltersChange,
      onPageSizeChange,
      onCurrentPageChange,
      onColumnOrderChange,
      onSortingChange,
      onColumnWidthsChange,
    } = this.props;

    return (
      <GridItem container xs={12} sm={12} md={12}>
        <Card>
          <Grid rows={rows} columns={columns}>
            <FilteringState
              filters={filters}
              onFiltersChange={onFiltersChange}
            />
            <SortingState
              defaultSorting={sorting}
              sorting={sorting}
              onSortingChange={onSortingChange}
            />
            <PagingState
              defaultCurrentPage={currentPage}
              defaultPageSize={pageSize}
              onPageSizeChange={onPageSizeChange}
              onCurrentPageChange={onCurrentPageChange}
            />
            <SummaryState
              totalItems={[
                { columnName: 'SelectedCapital', type: 'avg' },
                { columnName: 'SelectedCapital', type: 'sum' },
                { columnName: 'Capital', type: 'avg' },
                { columnName: 'Capital', type: 'sum' },
                { columnName: 'Wallet', type: 'avg' },
                { columnName: 'Wallet', type: 'sum' },
                { columnName: 'UnPNL', type: 'avg' },
                { columnName: 'UnPNL', type: 'sum' },
                { columnName: 'NbrTrade', type: 'avg' },
                { columnName: 'NbrTrade', type: 'sum' },
                { columnName: 'NbErrEntry', type: 'avg' },
                { columnName: 'NbErrEntry', type: 'sum' },
                { columnName: 'NbPositionExchange', type: 'avg' },
                { columnName: 'NbPositionExchange', type: 'sum' },
              ]}
            />

            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedPaging />
            <IntegratedSummary />

            <DragDropProvider />
            <WillFollowProvider for={['WillFollow']} />
            <FollowersActionButtonsProvider for={['Action']} />
            <DateProvider for={['CreationDate', 'EndDate', 'WotDate']} />
            <DollarProvider for={['Capital', 'SelectedCapital', 'Wallet']} />
            <ColorUSDProvider for={['UnPNL']} />
            <PourcentageProvider for={['Risk']} />
            <Table columnExtensions={tableColumnExtensions} />
            <TableColumnResizing
              defaultColumnWidths={columnWidths}
              columnWidths={columnWidths}
              onColumnWidthsChange={onColumnWidthsChange}
            />
            <TableHeaderRow showSortingControls={true} />
            <TableColumnReordering
              order={columnOrder}
              onOrderChange={onColumnOrderChange}
            />
            <TableSummaryRow />
            <TableFilterRow showFilterSelector={true} />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
        </Card>
      </GridItem>
    );
  }
}

const getUnPnl = (exchange) => {
  if (isEmpty(exchange) || isEmpty(exchange.Positions)) return 0;
  let UnPNL = 0;
  exchange.Positions.forEach((position) => {
    UnPNL += position.UnrealizedPnl;
  });
  return UnPNL;
};

const mapStateToProps = (state, props) => {
  const { group } = props;
  const { config, localize } = state;
  const { followersGrid } = config;
  const isAdmin = isAdminSelector(state);
  const translate = getTranslate(localize);
  const showColumnFutures =
    isAdmin ||
    (!isNull(group) &&
      (isBinanceFuture(group.ExchangeName) ||
        isBybitFuture(group.ExchangeName)));
  const {
    filters,
    pageSizes,
    currentPage,
    pageSize,
    columnOrder,
    columnWidths,
    sorting,
    totalSummaryItems,
    tableColumnExtensions,
  } = followersGrid;
  const codeLang = getActiveCode(state);
  const followers = props.followers;
  let ret = {
    codeLang: codeLang,
    rows: followers,
    columnOrder: columnOrder,
    columnWidths: columnWidths,
    totalSummaryItems: totalSummaryItems,
    tableColumnExtensions: tableColumnExtensions,
    filters: filters,
    pageSizes: pageSizes,
    currentPage,
    pageSize,
    sorting: sorting,
    columns: [
      {
        name: 'Action',
        title: <Translate id="central.actions" />,
        getCellValue: (row) => (isNull(group) ? 0 : group.GroupId),
      },
      {
        name: 'Username',
        title: <Translate id="central.username" />,
        getCellValue: (row) => row.UserName,
      },
      {
        name: 'WillFollow',
        title: <Translate id="traders.willFollow" />,
        codeLang: codeLang,
        getCellValue: (row) => {
          const issue = getIssue(row, translate);
          return issue.length > 0 ? issue.join(';') : '1';
        },
      },
      // {
      //   name: 'CanCopyTrade',
      //   title: 'CanCopyTrade',
      //   codeLang: codeLang,
      //   getCellValue: (row) => (row.CanCopyTrade ? 'true' : 'false'),
      // },
      {
        name: 'SelectedCapital',
        title: <Translate id="traders.tradedCapital" />,
        getCellValue: (row) => parseFloat(row.CapitalTrading),
      },
      {
        name: 'Capital',
        title: <Translate id="traders.margin" />,
        getCellValue: (row) =>
          row.ReelCapital !== null ? parseFloat(row.ReelCapital) : 0,
      },
      {
        name: 'Wallet',
        title: <Translate id="traders.wallet" />,
        getCellValue: (row) => {
          const margin =
            row.ReelCapital !== null ? parseFloat(row.ReelCapital) : 0;
          return margin - getUnPnl(row.Exchange);
        },
      },
      {
        name: 'UnPNL',
        title: <Translate id="traders.UnrealizedPnL" />,
        getCellValue: (row) => {
          const unPnl = getUnPnl(row.Exchange);
          return unPnl == 0 ? '' : unPnl;
        },
      },
      {
        name: 'NbrTrade',
        title: <Translate id="traders.nbTradeWot" />,
        getCellValue: (row) => row.NbOpenOrders,
      },
      {
        name: 'NbErrEntry',
        title: <Translate id="traders.nbErrorEntries" />,
        getCellValue: (row) => row.NbErrorEntries,
      },
      {
        name: 'NbPositionExchange',
        title: <Translate id="traders.nbPositionExchange" />,
        getCellValue: (row) =>
          !isEmpty(row.Exchange) && !isNull(row.Exchange.Positions)
            ? row.Exchange.Positions.length
            : 0,
      },
      // {
      //   name: 'HaveRebate',
      //   title: 'HaveRebate',
      //   codeLang: codeLang,
      //   getCellValue: (row) => (row.HaveRebate ? 'true' : 'false'),
      // },
      // {
      //   name: 'Pourcentage',
      //   title: 'Pourcentage',
      //   codeLang: codeLang,
      //   getCellValue: (row) => row.Pourcentage,
      // },
      {
        name: 'WotDate',
        title: <Translate id="traders.endDateWOT" />,
        codeLang: codeLang,
        getCellValue: (row) => (row.WotDate === null ? '' : row.WotDate),
      },
      {
        name: 'EndDate',
        title: <Translate id="traders.endDate" />,
        codeLang: codeLang,
        getCellValue: (row) =>
          row.EndDate === null ? translate('traders.noEndDate') : row.EndDate,
      },
      {
        name: 'CreationDate',
        title: <Translate id="traders.creationDate" />,
        codeLang: codeLang,
        getCellValue: (row) => row.CreateDate,
      },
      {
        name: 'Note',
        title: <Translate id="central.note" />,
        getCellValue: (row) => row.Note,
      },
      {
        name: 'Risk',
        title: <Translate id="central.risk" />,
        getCellValue: (row) =>
          !isNull(row.Exchange) &&
          !isNull(row.Exchange.Datas) &&
          !isNull(row.Exchange.Datas.MarginRatio)
            ? parseFloat(row.Exchange.Datas.MarginRatio.replace(',', '.'))
            : 0,
      },
      {
        name: 'HedgeMode',
        title: <Translate id="exchanges.hedgeMode" />,
        getCellValue: (row) =>
          !isNull(row.Exchange) && isHedgeMode(row.Exchange.Options)
            ? 'true'
            : 'false',
      },
    ],
  };
  let elemToRemove = [];
  if (!showColumnFutures)
    elemToRemove.push(
      'NbPositionExchange',
      'UnPNL',
      'Capital',
      'Risk',
      'HedgeMode',
    );
  ret.columns = ret.columns.filter((x) => !elemToRemove.includes(x.name));
  ret.columnOrder = ret.columnOrder.filter((x) => !elemToRemove.includes(x));
  ret.columnWidths = ret.columnWidths.filter(
    (x) => !elemToRemove.includes(x.columnName),
  );
  return ret;
};

const mapDispatchToProps = (dispatch) => ({
  getFollowersActions: bindActionCreators(getFollowers, dispatch),
  onSortingChange: (sorting) =>
    dispatch(manageFollowersGridAction('sorting', sorting)),
  onFiltersChange: (filters) =>
    dispatch(manageFollowersGridAction('filters', filters)),
  onCurrentPageChange: (currentPage) =>
    dispatch(manageFollowersGridAction('currentPage', currentPage)),
  onPageSizeChange: (pageSize) =>
    dispatch(manageFollowersGridAction('pageSize', pageSize)),
  onColumnOrderChange: (order) =>
    dispatch(manageFollowersGridAction('columnOrder', order)),
  onColumnWidthsChange: (widths) =>
    dispatch(manageFollowersGridAction('columnWidths', widths)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableUIFollowers);
