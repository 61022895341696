import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
// core components
import GridItem from '../../components/Grid/GridItem.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';

import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Button from '../../components/CustomButtons/Button';
import { IsAdmin } from '../../redux/admin/actions';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { adminUrl } from '../../routes/dashboard';

class DashboardAdminPage extends React.Component {
  componentDidMount() {
    this.props.IsAdminActions();
  }

  render() {
    return (
      <GridContainer>
        <GridItem md={3} container justifyContent="center">
          <Tooltip title="Users" enterDelay={200} leaveDelay={200}>
            <Button
              color="primary"
              fullWidth
              to={adminUrl + '/users'}
              component={Link}
            >
              Users
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem md={3} container justifyContent="center">
          <Tooltip title="Groupe" enterDelay={200} leaveDelay={200}>
            <Button
              color="primary"
              fullWidth
              to={adminUrl + '/subGroups'}
              component={Link}
            >
              SubGroups
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem md={3} container justifyContent="center">
          <Tooltip title="Stats Groupes" enterDelay={200} leaveDelay={200}>
            <Button
              color="primary"
              fullWidth
              to={adminUrl + '/statsGroup'}
              component={Link}
            >
              Stats Group
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem md={3} container justifyContent="center">
          <Tooltip title="Orders" enterDelay={200} leaveDelay={200}>
            <Button
              color="primary"
              fullWidth
              to={adminUrl + '/orders'}
              component={Link}
            >
              Ordres
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem md={3} container justifyContent="center">
          <Tooltip title="OrdersPb" enterDelay={200} leaveDelay={200}>
            <Button
              color="primary"
              fullWidth
              to={adminUrl + '/ordersPb'}
              component={Link}
            >
              Ordres Probleme
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem md={3} container justifyContent="center">
          <Tooltip title="Paiements" enterDelay={200} leaveDelay={200}>
            <Button
              color="primary"
              fullWidth
              to={adminUrl + '/paiements'}
              component={Link}
            >
              Paiements
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem md={3} container justifyContent="center">
          <Tooltip title="Company" enterDelay={200} leaveDelay={200}>
            <Button
              color="primary"
              fullWidth
              to={adminUrl + '/company'}
              component={Link}
            >
              Company
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem md={3} container justifyContent="center">
          <Tooltip title="HistoricEmail" enterDelay={200} leaveDelay={200}>
            <Button
              color="primary"
              fullWidth
              to={adminUrl + '/historicEmail'}
              component={Link}
            >
              HistoricEmail
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem md={3} container justifyContent="center">
          <Tooltip title="WebSockets" enterDelay={200} leaveDelay={200}>
            <Button
              color="primary"
              fullWidth
              to={adminUrl + '/webSockets'}
              component={Link}
            >
              WebSockets
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem md={3} container justifyContent="center">
          <Tooltip title="Orders Limit" enterDelay={200} leaveDelay={200}>
            <Button
              color="primary"
              fullWidth
              to={adminUrl + '/ordersLimit'}
              component={Link}
            >
              Orders Limit
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem md={3} container justifyContent="center">
          <Tooltip title="Inscription Group" enterDelay={200} leaveDelay={200}>
            <Button
              color="primary"
              fullWidth
              to={adminUrl + '/inscriptionGroups'}
              component={Link}
            >
              Inscription Group
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem md={3} container justifyContent="center">
          <Tooltip title="Volumes" enterDelay={200} leaveDelay={200}>
            <Button
              color="primary"
              fullWidth
              to={adminUrl + '/volumes'}
              component={Link}
            >
              Volumes
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem md={3} container justifyContent="center">
          <Tooltip title="Search" enterDelay={200} leaveDelay={200}>
            <Button
              color="primary"
              fullWidth
              to={adminUrl + '/searchV1'}
              component={Link}
            >
              Search V1
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem md={3} container justifyContent="center">
          <Tooltip title="Search" enterDelay={200} leaveDelay={200}>
            <Button
              color="primary"
              fullWidth
              to={adminUrl + '/search'}
              component={Link}
            >
              Search V2
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem md={3} container justifyContent="center">
          <Tooltip title="LeaderBoard" enterDelay={200} leaveDelay={200}>
            <Button
              color="primary"
              fullWidth
              to={adminUrl + '/leaderBoard'}
              component={Link}
            >
              LeaderBoard
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem md={3} container justifyContent="center">
          <Button
            color="primary"
            fullWidth
            to={adminUrl + '/emails'}
            component={Link}
          >
            Emails
          </Button>
        </GridItem>
        <GridItem md={3} container justifyContent="center">
          <Button
            color="primary"
            fullWidth
            to={adminUrl + '/panicSellAll'}
            component={Link}
          >
            Panic Sell All
          </Button>
        </GridItem>
      </GridContainer>
    );
  }
}

DashboardAdminPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(DashboardAdminPage);
