import React from 'react';
import { Translate } from 'react-localize-redux';
import { change, touch, Field, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Percentage from '../../../components/Fields/Percentage';
import Price from '../../../components/Fields/Price';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import RadioLimitMarketContainer from './RadioLimitMarketContainer';
import ReactTextField from '../../../components/Form/ReactTextFieldUI';
import StartPriceContainer from './StartPriceContainer';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';

import {
  capitalSelector,
  getSecFromPair,
  qtyPrimSelector,
  qtySecSelector,
  getPrimFromPair,
  getPartFromPourcentage,
  currentPriceSelector,
  parseToFloat,
  stepPriceSelector,
  stepQtySelector,
  secPriceSelector,
  getPourcentageFromPart,
  getPartFromSecQty,
  getSecFromFirst,
  getFirstFromSec,
  getSecQtyFromPart,
  leverageBuySelector,
  leverageSellSelector,
  tokenPrimSelector,
  tokenSecSelector,
  isNull,
  isBinanceFuture,
  isBybitFuture,
  getFeeFromName,
} from '../../../helpers/Common';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Helper from '../../../components/Hover/Helper';
import { SmallCheckBox } from '../../../components/Fields/SmallCheckBox';
import LabelCheckbox from '../../../components/Form/LabelCheckbox';
import {
  getFeeStr,
  getLeverageLimit,
  haveLeverage,
} from '../../../helpers/order';
import MyDateTimePicker from '../../../components/Fields/MyDateTimePicker';
import { getActiveCode } from '../../../helpers/localize';
import { KeyExchangeTable } from '../../../helpers/exchange';
import MyTooltip from '../../../components/MyTooltip';

import { v4 as uuidv4 } from 'uuid';
import { createTradingViewString } from '../../../redux/order/actions.js';
import CopyField from '../../../components/Fields/CopyField.jsx';
import { getUrl } from '../../../helpers/fetcher.js';
import RadioOneEatchContainer from './RadioOneEachContainer.jsx';
import RadioTVTypeContainer from './RadioTVTypeContainer';

class StartContainer extends React.PureComponent {
  componentDidUpdate = (prevProps, prevState) => {
    const {
      formName,
      change,
      typeStart,
      touch,
      currentPrice,
      isBuyDone,
      startPrice,
      lastUpdated,
      startTriggerPriceCB,
    } = this.props;

    if (isBuyDone) return;

    if (typeStart !== prevProps.typeStart) {
      if (typeStart === 'Market' && !startTriggerPriceCB) {
        change(formName, 'startPrice', currentPrice);
        touch(formName, 'startPrice');
      }
    }

    if (startPrice !== prevProps.startPrice) {
      this.changeValue(lastUpdated, this.props[lastUpdated]);
    }
  };

  changeValue = (elemId, newValue) => {
    const {
      lotStep,
      capital,
      formName,
      change,
      touch,
      stepPrice,
      startPrice,
      pourcentage,
      secPrice,
      leverage,
      leverageCB,
      isBuyDone,
      exchange,
      isBuy,
      typeStart,
      leverageBuy,
      leverageSell,
    } = this.props;

    if (isBuyDone) return;
    change(formName, 'lastUpdated', elemId);
    let isLeverage =
      exchange &&
      haveLeverage(exchange.exchange, isBuy, leverageBuy, leverageSell);
    const leverageUse = leverageCB && isLeverage ? leverage : 1;
    const exchangeName = exchange?.exchange ?? '';
    const isTaker = typeStart === 'Market';
    // console.log(elemId, startPrice);
    if (elemId === 'firstQty') {
      const newSecQty = getSecFromFirst(newValue, startPrice, stepPrice);
      const partUse = getPartFromSecQty(newSecQty, secPrice);

      change(formName, 'secQty', newSecQty);
      touch(formName, 'secQty');

      change(formName, 'partUse', partUse);
      touch(formName, 'partUse');

      change(
        formName,
        'pourcentage',
        getPourcentageFromPart(partUse, capital, leverageUse),
      );
      touch(formName, 'pourcentage');
    } else if (elemId === 'secQty') {
      const newFirstQty = getFirstFromSec(
        newValue,
        startPrice,
        lotStep,
        exchangeName,
        leverageUse,
        isBuy,
        isTaker,
      );
      change(formName, 'firstQty', newFirstQty);
      touch(formName, 'firstQty');

      const partUse = getPartFromSecQty(newValue, secPrice);
      change(formName, 'partUse', partUse);
      touch(formName, 'partUse');

      change(
        formName,
        'pourcentage',
        getPourcentageFromPart(partUse, capital, leverageUse),
      );
      touch(formName, 'pourcentage');
    } else if (elemId === 'pourcentage') {
      let newPartUse = getPartFromPourcentage(capital, newValue, leverageUse);
      let qtySec = getSecQtyFromPart(newPartUse, secPrice, stepPrice);
      const firstQty = getFirstFromSec(
        qtySec,
        startPrice,
        lotStep,
        exchangeName,
        leverageUse,
        isBuy,
        isTaker,
      );
      // qtySec = getSecFromFirst(firstQty, startPrice, stepPrice);
      // newPartUse = getPartFromSecQty(qtySec, secPrice);

      change(formName, 'partUse', newPartUse);
      touch(formName, 'partUse');

      change(formName, 'secQty', qtySec);
      touch(formName, 'secQty');

      // console.log(firstQty, qtySec, newPartUse);
      change(formName, 'firstQty', firstQty);
      touch(formName, 'firstQty');
    } else if (elemId === 'partUse') {
      const pourcentage = getPourcentageFromPart(
        newValue,
        capital,
        leverageUse,
      );
      change(formName, 'pourcentage', pourcentage);
      touch(formName, 'pourcentage');

      const qtySec = getSecQtyFromPart(newValue, secPrice, stepPrice);
      change(formName, 'secQty', qtySec);
      touch(formName, 'secQty');

      const qtyFirst = getFirstFromSec(
        qtySec,
        startPrice,
        lotStep,
        exchangeName,
        leverageUse,
        isBuy,
        isTaker,
      );
      change(formName, 'firstQty', qtyFirst);
      touch(formName, 'firstQty');
    } else if (elemId === 'leverage') {
      change(formName, 'leverage', newValue);
      touch(formName, 'leverage');

      const newPartUse = getPartFromPourcentage(capital, pourcentage, newValue);
      change(formName, 'partUse', newPartUse);
      touch(formName, 'partUse');

      const qtySec = getSecQtyFromPart(newPartUse, secPrice, stepPrice);
      change(formName, 'secQty', qtySec);
      touch(formName, 'secQty');

      change(
        formName,
        'firstQty',
        getFirstFromSec(
          qtySec,
          startPrice,
          lotStep,
          exchangeName,
          newValue,
          isBuy,
          isTaker,
        ),
      );
    }
  };

  getToolTipQty = (token) => {
    return isNull(token) ? (
      ' '
    ) : (
      <div>
        <Translate id={'exchanges.' + KeyExchangeTable.AMOUNT.T} />:{' '}
        {token.Amount}
        <br />
        <Translate id={'exchanges.' + KeyExchangeTable.LOCKED.T} />:{' '}
        {token.Locked}
        <br />
        <Translate id={'exchanges.' + KeyExchangeTable.LOCKEDONWOT.T} />:{' '}
        {token.LockedOnWot}
      </div>
    );
  };

  render() {
    const {
      formName,
      capital,
      pair,
      qtyPrim,
      qtySec,
      tokenPrim,
      tokenSec,
      isBuy,
      trailingStartCB,
      TVType,
      typeStart,
      startTriggerPrice,
      startTriggerPriceCB,
      timeOutStartCB,
      leverageCB,
      leverageBuy,
      leverageSell,
      isBuyDone,
      isUpdate,
      stepPrice,
      change,
      lotStep,
      exchange,
      leverage,
      dontDoStart,
      tradingViewCB,
      startTradingViewGuid,
      isPaperTrading,
      codeLang,
      isTemplate,
      serverUrl,
      timeOutStopLossCB,
    } = this.props;
    const TVJson = createTradingViewString(startTradingViewGuid, TVType);
    const primStr = getPrimFromPair(pair);
    const secStr = getSecFromPair(pair);
    const isBitmex = exchange && exchange.exchange === 'Bitmex';
    const isKraken = exchange && exchange.exchange === 'Kraken';
    const isBinanceFutures = exchange && isBinanceFuture(exchange.exchange);
    const isBybitFutures = exchange && isBybitFuture(exchange.exchange);
    let isLeverage =
      exchange &&
      haveLeverage(exchange.exchange, isBuy, leverageBuy, leverageSell);
    const minLeverage = exchange
      ? getLeverageLimit(
          'min',
          exchange.exchange,
          isBuy,
          leverageBuy,
          leverageSell,
        )
      : 0;
    const maxLeverage = exchange
      ? getLeverageLimit(
          'max',
          exchange.exchange,
          isBuy,
          leverageBuy,
          leverageSell,
        )
      : 0;
    const endAdornment = secStr + '/' + primStr;
    //const hideBitmexPrim = !isBitmex || primStr === 'XBT';
    //const hideBitmexSec = !isBitmex || secStr === 'XBT';
    const isMarket = typeStart === 'Market';
    const canITimeOut =
      isBuyDone ||
      dontDoStart ||
      (isMarket && !(startTriggerPriceCB || trailingStartCB));

    const tooltipPrimQty = this.getToolTipQty(tokenPrim);
    const tooltipSecQty = this.getToolTipQty(tokenSec);
    const isUpdateTemplate = isUpdate && isTemplate;

    return (
      <GridItem xs={12} sm={12} md={4}>
        <Card className="smart-trade-bloc">
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <RadioLimitMarketContainer
                  formName={formName}
                  name="typeStart"
                  isTargets={false}
                  disabledLimit={trailingStartCB}
                  disabled={isBuyDone || isTemplate}
                  onChange={(event, newValue) => {
                    if (newValue === 'Market' && !startTriggerPriceCB) {
                      this.props.change(formName, 'timeOutStartCB', false);
                    }
                    if (newValue === 'Market' && startTriggerPriceCB) {
                      this.props.change(
                        formName,
                        'startPrice',
                        startTriggerPrice,
                      );
                    }
                  }}
                />
              </GridItem>
              {!isTemplate && (
                <GridItem xs={12} sm={12} md={12}>
                  {!trailingStartCB && (
                    <StartPriceContainer formName={formName} />
                  )}
                </GridItem>
              )}
              <GridItem xs={12} sm={12} md={12}>
                <div style={{ float: 'left', margin: '0.5em 0px 24px 0px' }}>
                  <div style={{ float: 'right' }}>
                    &nbsp;
                    <Helper
                      disabled={isBuyDone}
                      text={<Translate id="smartTrade.infoTradingView" />}
                    />
                  </div>
                  <Field
                    name="tradingViewCB"
                    id="tradingViewCB"
                    component={SmallCheckBox}
                    disabled={
                      isBuyDone ||
                      dontDoStart ||
                      startTriggerPriceCB ||
                      isUpdateTemplate
                    }
                    onChange={() => {
                      this.props.change(formName, 'startTriggerPriceCB', false);
                      if (isNull(serverUrl)) {
                        this.props.change(
                          formName,
                          'serverUrl',
                          `${getUrl()}api/v1/Order/TradingView`,
                        );
                      }
                      if (isNull(startTradingViewGuid)) {
                        this.props.change(
                          formName,
                          'startTradingViewGuid',
                          uuidv4(),
                        );
                      }
                      if (isTemplate === true)
                        this.props.change(formName, 'isTemplate', false);
                    }}
                  />
                  <LabelCheckbox
                    disabled={isBuyDone || isUpdateTemplate}
                    htmlFor="tradingViewCB"
                  >
                    <Translate id="smartTrade.tradingview" />
                  </LabelCheckbox>
                </div>
              </GridItem>
              {!(!tradingViewCB || dontDoStart) && (
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ marginLeft: '10px' }}>
                    <CopyField
                      style={{ margin: '-24px -0px 0px 0px' }}
                      name="serverUrl"
                      copycontent={serverUrl}
                      value={serverUrl}
                      required={false}
                      label={<Translate id="smartTrade.url" />}
                    />
                    <CopyField
                      style={{ margin: '-10px -0px 0px 0px' }}
                      name="startTradingViewGuidJson"
                      value={TVJson}
                      copycontent={TVJson}
                      required={false}
                      label={<Translate id="smartTrade.tradingview.message" />}
                    />
                  </div>
                  <RadioOneEatchContainer
                    formName={formName}
                    name="isTemplate"
                    disabled={isBuyDone || !tradingViewCB || isUpdateTemplate}
                  />
                  {isTemplate && (
                    <RadioTVTypeContainer
                      formName={formName}
                      name="TVType"
                      disabled={isBuyDone || !tradingViewCB || isUpdateTemplate}
                    />
                  )}
                </GridItem>
              )}
              {!isTemplate && (
                <GridItem xs={12} sm={12} md={6}>
                  <div style={{ float: 'left', margin: '0.5em 0px 24px 0px' }}>
                    <div style={{ float: 'right' }}>
                      &nbsp;
                      <Helper
                        disabled={isBuyDone}
                        text={<Translate id="smartTrade.infoTriggerPoint" />}
                      />
                    </div>
                    <Field
                      name="startTriggerPriceCB"
                      id="startTriggerPriceCB"
                      component={SmallCheckBox}
                      onChange={() => {
                        this.props.change(formName, 'tradingViewCB', false);
                      }}
                      // onChange={(event, newValue) =>
                      // {
                      //   if (!newValue && isMarket)
                      //     this.props.change(formName, 'timeOutStartCB', false);
                      // }}
                      disabled={isBuyDone || dontDoStart || tradingViewCB}
                    />
                    <LabelCheckbox
                      disabled={isBuyDone}
                      htmlFor="startTriggerPriceCB"
                    >
                      <Translate id="smartTrade.triggerPoint" />
                    </LabelCheckbox>
                  </div>
                </GridItem>
              )}
              {!isTemplate && (
                <GridItem xs={12} sm={12} md={6}>
                  {!(!startTriggerPriceCB || dontDoStart) && (
                    <Price
                      name="startTriggerPrice"
                      endAdornment={endAdornment}
                      disabled={isBuyDone}
                      step={stepPrice}
                      onChange={(event, newValue) => {
                        if (isMarket) {
                          this.props.change(formName, 'startPrice', newValue);
                        }
                      }}
                    />
                  )}
                </GridItem>
              )}
              {!isTemplate && (
                <GridItem xs={12} sm={12} md={6}>
                  <div style={{ float: 'left', marginBottom: '0.5em' }}>
                    <div style={{ float: 'right' }}>
                      &nbsp;
                      <Helper
                        disabled={isBuyDone}
                        text={
                          <Translate
                            id="smartTrade.infoDontDoStart"
                            data={{
                              side: (
                                <Translate
                                  id={'smartTrade.' + (isBuy ? 'buy' : 'sell')}
                                />
                              ),
                            }}
                          />
                        }
                      />
                    </div>
                    <Field
                      name="dontDoStart"
                      id="dontDoStart"
                      component={SmallCheckBox}
                      type="checkbox"
                      disabled={isBuyDone}
                      onChange={() => {
                        this.props.change(formName, 'typeStart', 'Limit');
                        this.props.change(formName, 'trailingStartCB', false);
                        this.props.change(formName, 'timeOutStartCB', false);
                        this.props.change(
                          formName,
                          'startTriggerPriceCB',
                          false,
                        );
                        this.props.change(formName, 'tradingViewCB', false);
                      }}
                    />
                    <LabelCheckbox disabled={isBuyDone} htmlFor="dontDoStart">
                      {isBuy ? (
                        <Translate id="smartTrade.dontDoBuy" />
                      ) : (
                        <Translate id="smartTrade.dontDoSell" />
                      )}
                    </LabelCheckbox>
                  </div>
                </GridItem>
              )}
              <GridItem xs={12} sm={12} md={!isTemplate ? 6 : 12}>
                <div style={{ float: 'right' }}>
                  <Translate id="orders.capital" />: {capital}$
                </div>
              </GridItem>
              <GridItem
                xs={12}
                sm={!isTemplate ? 6 : 12}
                md={!isTemplate ? 6 : 12}
              >
                <Percentage
                  formName={formName}
                  name="pourcentage"
                  step={0.01}
                  label={<Translate id="smartTrade.partCapital" />}
                  disabled={isBuyDone}
                  onChange={(event, newValue) => {
                    const elemId = event.target.name;
                    this.changeValue(elemId, newValue);
                  }}
                />
              </GridItem>
              {!isTemplate && (
                <GridItem xs={12} sm={6} md={6}>
                  <Price
                    name="partUse"
                    label={<Translate id="smartTrade.usedCapital" />}
                    endAdornment="$"
                    required={false}
                    max={capital}
                    disabled={isBuyDone}
                    onChange={(event, newValue) => {
                      const elemId = event.target.name;
                      this.changeValue(elemId, newValue);
                    }}
                  />
                </GridItem>
              )}
              {!isTemplate && (
                <GridItem xs={12} sm={6} md={6}>
                  <Price
                    name="firstQty"
                    label={
                      !dontDoStart ? (
                        isBuy ? (
                          <Translate id="smartTrade.iBuy" />
                        ) : (
                          <Translate id="smartTrade.iSell" />
                        )
                      ) : isBuy ? (
                        <Translate id="smartTrade.iAlreadyBuy" />
                      ) : (
                        <Translate id="smartTrade.iAlreadySell" />
                      )
                    }
                    endAdornment={primStr}
                    disabled={isBuyDone}
                    step={lotStep}
                    required={false}
                    onChange={(event, newValue) => {
                      const elemId = event.target.name;
                      this.changeValue(elemId, newValue);
                    }}
                  />
                </GridItem>
              )}
              {!isTemplate && (
                <GridItem xs={12} sm={6} md={6}>
                  <Price
                    name="secQty"
                    label={
                      isBuy ? (
                        <Translate id="smartTrade.with" />
                      ) : (
                        <Translate id="smartTrade.for" />
                      )
                    }
                    endAdornment={secStr}
                    max={qtySec}
                    step={stepPrice}
                    disabled={isBuyDone}
                    required={false}
                    onChange={(event, newValue) => {
                      const elemId = event.target.name;
                      this.changeValue(elemId, newValue);
                    }}
                  />
                </GridItem>
              )}
              {!isPaperTrading && (
                <GridItem xs={12} sm={12} md={12}>
                  <Grid container style={{ textAlign: 'left' }}>
                    {primStr && (
                      <Grid item xs={12} sm={12} md={6}>
                        <InputLabel
                          style={{ fontSize: '0.75rem', marginBottom: '5px' }}
                          component="span"
                        >
                          {!isBitmex ? primStr : 'XBT'}{' '}
                          <Translate id="smartTrade.available" />:
                        </InputLabel>
                        {!isBuyDone &&
                          ((!isBuy && !dontDoStart) ||
                            (isBuy && dontDoStart)) &&
                          !isBitmex && (
                            <MyTooltip text={tooltipPrimQty}>
                              <span
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  const newValue = parseToFloat(
                                    qtyPrim,
                                    lotStep,
                                    true,
                                  );
                                  change(formName, 'firstQty', newValue);
                                  this.changeValue('firstQty', newValue);
                                }}
                              >
                                {parseToFloat(qtyPrim, lotStep, true)}
                              </span>
                            </MyTooltip>
                          )}
                        {(isBuyDone ||
                          (isBuy && !dontDoStart) ||
                          (!isBuy && dontDoStart)) &&
                          !isBitmex && (
                            <MyTooltip text={tooltipPrimQty}>
                              <span>
                                {parseToFloat(qtyPrim, lotStep, true)}
                              </span>
                            </MyTooltip>
                          )}
                        {isBitmex && <span>{qtyPrim}</span>}
                      </Grid>
                    )}
                    {!primStr && <Grid item xs={12} sm={12} md={6} />}
                    {secStr && !isBitmex && (
                      <GridItem xs={12} sm={12} md={6}>
                        <InputLabel
                          style={{ fontSize: '0.75rem', marginBottom: '5px' }}
                          component="span"
                        >
                          {secStr} <Translate id="smartTrade.available" />:
                        </InputLabel>
                        {!isBuyDone &&
                          ((isBuy && !dontDoStart) ||
                            (!isBuy && dontDoStart)) && (
                            <MyTooltip text={tooltipSecQty}>
                              <span
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  const newValue = parseToFloat(
                                    qtySec,
                                    stepPrice,
                                    true,
                                  );
                                  change(formName, 'secQty', newValue);
                                  this.changeValue('secQty', newValue);
                                }}
                              >
                                {parseToFloat(qtySec, stepPrice, true)}
                              </span>
                            </MyTooltip>
                          )}
                        {(isBuyDone ||
                          (!isBuy && !dontDoStart) ||
                          (isBuy && dontDoStart)) && (
                          <MyTooltip text={tooltipSecQty}>
                            <span>{parseToFloat(qtySec, stepPrice, true)}</span>
                          </MyTooltip>
                        )}
                      </GridItem>
                    )}
                    {!secStr && <GridItem xs={12} sm={12} md={6} />}
                    {isBitmex && <GridItem xs={12} sm={12} md={6} />}
                  </Grid>
                </GridItem>
              )}
              {isLeverage && (
                <GridItem xs={12} sm={12} md={6} style={{ marginTop: '30px' }}>
                  <div style={{ float: 'left', margin: '0.5em 0px 24px 0px' }}>
                    <div style={{ float: 'right' }}>
                      &nbsp;
                      <Helper
                        disabled={isBuyDone}
                        text={
                          isBitmex ? (
                            <Translate id="smartTrade.infoLeverageBitmex" />
                          ) : (
                            <Translate id="smartTrade.infoLeverage" />
                          )
                        }
                      />
                    </div>
                    <Field
                      name="leverageCB"
                      id="leverageCB"
                      component={SmallCheckBox}
                      type="checkbox"
                      disabled={isBuyDone}
                      onChange={() => {
                        this.changeValue('leverage', 1);
                        // this.props.change(formName, 'leverage', 1);
                      }}
                    />
                    <LabelCheckbox htmlFor="leverageCB">
                      <Translate id="smartTrade.leverage" />
                    </LabelCheckbox>
                  </div>
                </GridItem>
              )}
              {isLeverage && (
                <GridItem xs={12} sm={12} md={6} style={{ marginTop: '12px' }}>
                  {leverageCB && (
                    <Field
                      className="form-control"
                      name="leverage"
                      min={minLeverage}
                      max={maxLeverage}
                      label={<Translate id="smartTrade.leverage" />}
                      component={ReactTextField}
                      fullWidth={true}
                      type="number"
                      parse={(value) =>
                        parseToFloat(value, isKraken ? 1 : 0.01)
                      }
                      inputProps={{ step: 1 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">X</InputAdornment>
                        ),
                      }}
                      onChange={(event, newValue) => {
                        const elemId = event.target.name;
                        this.changeValue(elemId, newValue);
                      }}
                      disabled={isBuyDone}
                      required
                    />
                  )}
                  {leverage === 0 && isBitmex && <span>Cross</span>}
                </GridItem>
              )}
              {!isBinanceFutures && !isBybitFutures && (
                <GridItem xs={8} sm={8} md={8} style={{ marginTop: '30px' }}>
                  <div style={{ float: 'left', margin: '0.5em 0px 20px 0px' }}>
                    <div style={{ float: 'right' }}>
                      &nbsp;
                      <Helper
                        disabled={isBuyDone}
                        text={
                          isBuy ? (
                            <Translate id="smartTrade.infoTrailingStartBuy" />
                          ) : (
                            <Translate id="smartTrade.infoTrailingStartSell" />
                          )
                        }
                      />
                    </div>
                    <Field
                      name="trailingStartCB"
                      id="trailingStartCB"
                      component={SmallCheckBox}
                      disabled={isBuyDone || dontDoStart}
                      onChange={(event, newValue) => {
                        this.props.change(formName, 'typeStart', 'Market');
                        if (!newValue && isMarket)
                          this.props.change(formName, 'timeOutStartCB', false);
                      }}
                    />
                    <LabelCheckbox
                      disabled={isBuyDone}
                      htmlFor="trailingStartCB"
                      // style={{ cursor: 'pointer' }}
                    >
                      {isBuy ? (
                        <Translate id="smartTrade.buyTrailing" />
                      ) : (
                        <Translate id="smartTrade.sellTrailing" />
                      )}
                    </LabelCheckbox>
                  </div>
                </GridItem>
              )}
              {!isBinanceFutures && !isBybitFutures && (
                <GridItem xs={4} sm={4} md={4} style={{ marginTop: '25px' }}>
                  {!(!trailingStartCB || dontDoStart) && (
                    <Percentage name="trailingStart" disabled={isBuyDone} />
                  )}
                </GridItem>
              )}
              {!isTemplate && (
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ float: 'left', margin: '0.5em 0px 24px 0px' }}>
                    <div style={{ float: 'right' }}>
                      &nbsp;
                      <Helper
                        disabled={isBuyDone}
                        text={<Translate id="smartTrade.infoEntryTimeout" />}
                      />
                    </div>
                    <Field
                      name="timeOutStartCB"
                      id="timeOutStartCB"
                      component={SmallCheckBox}
                      disabled={canITimeOut}
                    />
                    <LabelCheckbox
                      disabled={isBuyDone}
                      htmlFor="timeOutStartCB"
                      // style={{ cursor: 'pointer' }}
                    >
                      <Translate id="smartTrade.entryTimeout" />
                    </LabelCheckbox>
                  </div>
                </GridItem>
              )}
              {!isTemplate && (
                <GridItem xs={12} sm={12} md={12}>
                  {timeOutStartCB && !canITimeOut && (
                    <MyDateTimePicker
                      lang={codeLang}
                      name="timeOutStart"
                      formName={formName}
                    />
                  )}
                </GridItem>
              )}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  return {
    TVType: formSelector(state, 'TVType'),
    partUse: formSelector(state, 'partUse'),
    pourcentage: formSelector(state, 'pourcentage'),
    firstQty: formSelector(state, 'firstQty'),
    secQty: formSelector(state, 'secQty'),
    lastUpdated: formSelector(state, 'lastUpdated'),
    capital: capitalSelector(state, props),
    pair: formSelector(state, 'pair'),
    exchange: formSelector(state, 'accountId'),
    leverage: formSelector(state, 'leverage'),
    startPrice: formSelector(state, 'startPrice'),
    isBuy: formSelector(state, 'isBuy'),
    isBuyDone: formSelector(state, 'isBuyDone'),
    isUpdate: formSelector(state, 'isUpdate'),
    dontDoStart: formSelector(state, 'dontDoStart'),
    isPaperTrading: formSelector(state, 'isPaperTrading'),
    typeStart: formSelector(state, 'typeStart'),
    startTriggerPrice: formSelector(state, 'startTriggerPrice'),
    startTriggerPriceCB: formSelector(state, 'startTriggerPriceCB'),
    tradingViewCB: formSelector(state, 'tradingViewCB'),
    startTradingViewGuid: formSelector(state, 'startTradingViewGuid'),
    trailingStartCB: formSelector(state, 'trailingStartCB'),
    timeOutStartCB: formSelector(state, 'timeOutStartCB'),
    timeOutStart: formSelector(state, 'timeOutStart'),
    leverageCB: formSelector(state, 'leverageCB'),
    isTemplate: formSelector(state, 'isTemplate'),
    serverUrl: formSelector(state, 'serverUrl'),
    timeOutStopLossCB: formSelector(state, 'timeOutStopLossCB'),
    tokenPrim: tokenPrimSelector(state, props),
    tokenSec: tokenSecSelector(state, props),
    qtyPrim: qtyPrimSelector(state, props),
    qtySec: qtySecSelector(state, props),
    currentPrice: currentPriceSelector(state, props),
    stepPrice: stepPriceSelector(state, props),
    lotStep: stepQtySelector(state, props),
    leverageBuy: leverageBuySelector(state, props),
    leverageSell: leverageSellSelector(state, props),
    secPrice: secPriceSelector(state, props),
    codeLang: getActiveCode(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    touch: bindActionCreators(touch, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartContainer);
