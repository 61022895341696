import React from 'react';
import { withWidth } from '@material-ui/core';
import TableUIReferrals from './TableUIReferrals';
import ReferralsContainer from './ReferralsContainer';
import { nbColumn } from '../../helpers/general';
import GridItem from '../../components/Grid/GridItem';

class ManageReferrals extends React.Component {
  render() {
    const { referrals } = this.props;

    const isMobile = nbColumn(this.props.width) < 2;
    let isShowTable = !isMobile;

    return (
      <GridItem container>
        {isShowTable && <TableUIReferrals referrals={referrals} />}
        {!isShowTable && <ReferralsContainer referrals={referrals} />}
      </GridItem>
    );
  }
}

export default withWidth()(ManageReferrals);
