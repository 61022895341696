import React from 'react';
import { bindActionCreators } from 'redux';
import { change, Field } from 'redux-form';
import { smartTradeSelector, isBuySelector } from '../../../helpers/Common';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';

import RadioGroup from '../../../components/Form/ReactRadioGroupUI';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class RadioLimitMarketContainer extends React.PureComponent {
  render() {
    const {
      isBuy,
      name,
      isTargets,
      disabledLimit,
      disabledMarket,
      disabled,
      onChange,
    } = this.props;
    const isBuyV = isTargets ? isBuy : !isBuy;
    return (
      <Field name={name} component={RadioGroup} onChange={onChange}>
        <FormControlLabel
          value="Limit"
          control={<Radio color="primary" />}
          label={
            isBuyV ? (
              <Translate id="smartTrade.sellLimit" />
            ) : (
              <Translate id="smartTrade.buyLimit" />
            )
          }
          disabled={disabledLimit || disabled}
        />
        <FormControlLabel
          value="Market"
          control={<Radio color="primary" />}
          label={
            isBuyV ? (
              <Translate id="smartTrade.sellMarket" />
            ) : (
              <Translate id="smartTrade.buyMarket" />
            )
          }
          disabled={disabledMarket || disabled}
        />
      </Field>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    values: smartTradeSelector(state),
    isBuy: isBuySelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RadioLimitMarketContainer);
