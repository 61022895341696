import React from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedSummary,
  PagingState,
  SortingState,
  SummaryState,
} from '@devexpress/dx-react-grid';
import {
  DragDropProvider,
  Grid,
  PagingPanel,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  DollarProvider,
  ReferralsActionButtonsProvider,
} from '../../helpers/table';
import { manageReferralsGridAction } from '../../redux/referrals/actions';
import { getActiveCode } from '../../helpers/localize';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import { getUsdFromGains } from '../../helpers/referrals';

class TableUIReferrals extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      rows,
      columns,
      columnWidths,
      filters,
      currentPage,
      pageSize,
      pageSizes,
      columnOrder,
      sorting,
      tableColumnExtensions,
      onFiltersChange,
      onPageSizeChange,
      onCurrentPageChange,
      onColumnOrderChange,
      onSortingChange,
      onColumnWidthsChange,
    } = this.props;
    const state = this.state;

    return (
      <GridItem container xs={12} sm={12} md={12}>
        <Card>
          <Grid rows={rows} columns={columns}>
            <FilteringState
              filters={filters}
              onFiltersChange={onFiltersChange}
            />
            <SortingState
              defaultSorting={sorting}
              sorting={sorting}
              onSortingChange={onSortingChange}
            />
            <PagingState
              defaultCurrentPage={currentPage}
              defaultPageSize={pageSize}
              onPageSizeChange={onPageSizeChange}
              onCurrentPageChange={onCurrentPageChange}
            />
            <SummaryState
              totalItems={[
                { columnName: 'NbProfitable', type: 'sum' },
                { columnName: 'NumberOfUniqueClicks', type: 'sum' },
                { columnName: 'NumberOfRegistrations', type: 'sum' },
                { columnName: 'AmountWithdrawn', type: 'sum' },
                { columnName: 'GainInUSDT', type: 'sum' },
                { columnName: 'GainInUSDT', type: 'avg' },
              ]}
            />

            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedPaging />
            <IntegratedSummary />

            <DragDropProvider />
            <ReferralsActionButtonsProvider for={['Action']} state={state} />
            <DollarProvider for={[]} />
            <Table columnExtensions={tableColumnExtensions} />
            <TableColumnResizing
              defaultColumnWidths={columnWidths}
              columnWidths={columnWidths}
              onColumnWidthsChange={onColumnWidthsChange}
            />
            <TableHeaderRow showSortingControls={true} />
            <TableColumnReordering
              order={columnOrder}
              onOrderChange={onColumnOrderChange}
            />
            <TableSummaryRow />
            <TableFilterRow showFilterSelector={true} />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
        </Card>
      </GridItem>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { referrals } = state;
  const { referralsGrid } = referrals;
  const {
    filters,
    pageSizes,
    currentPage,
    pageSize,
    columnOrder,
    columnWidths,
    sorting,
    totalSummaryItems,
    tableColumnExtensions,
  } = referralsGrid;
  const codeLang = getActiveCode(state);
  const myReferrals = props.referrals;

  return {
    codeLang: codeLang,
    rows: myReferrals,
    columnOrder: columnOrder,
    columnWidths: columnWidths,
    totalSummaryItems: totalSummaryItems,
    tableColumnExtensions: tableColumnExtensions,
    filters: filters,
    pageSizes: pageSizes,
    currentPage,
    pageSize,
    sorting: sorting,
    columns: [
      {
        name: 'Action',
        title: <Translate id="central.actions" />,
        getCellValue: (row) => row.CampaignId,
      },
      {
        name: 'Name', //NOM DU LIEN DE PARRAINAGE
        title: <Translate id="central.name" />,
        getCellValue: (row) => row.Name,
      },
      {
        name: 'NumberOfUniqueClicks', //nombre de clics uniques
        title: <Translate id="referrals.numberOfUniqueClicks" />,
        getCellValue: (row) => row.NbClick,
      },
      {
        name: 'NumberOfRegistrations', //nombre d'inscriptions
        title: <Translate id="referrals.numberOfRegistrations" />,
        getCellValue: (row) => row.NbSubscribe,
      },
      {
        name: 'NbProfitable', //nombre d'au moins 1 trade passé
        title: <Translate id="referrals.numberWithAtLeast1Trade" />,
        getCellValue: (row) => row.NbProfitable,
      },
      {
        name: 'GainInUSDT', //gains pour le parrain en USDT au cours actuel
        title: <Translate id="referrals.gainInUSDT" />,
        getCellValue: (row) => {
          return getUsdFromGains(row.Gains);
        },
      },
      {
        name: 'AmountWithdrawn',
        title: 'Amount Withdrawn',
        getCellValue: (row) => row.AmountWithdrawn,
      },
    ],
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSortingChange: (sorting) =>
    dispatch(manageReferralsGridAction('sorting', sorting)),
  onFiltersChange: (filters) =>
    dispatch(manageReferralsGridAction('filters', filters)),
  onCurrentPageChange: (currentPage) =>
    dispatch(manageReferralsGridAction('currentPage', currentPage)),
  onPageSizeChange: (pageSize) =>
    dispatch(manageReferralsGridAction('pageSize', pageSize)),
  onColumnOrderChange: (order) =>
    dispatch(manageReferralsGridAction('columnOrder', order)),
  onColumnWidthsChange: (widths) =>
    dispatch(manageReferralsGridAction('columnWidths', widths)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableUIReferrals);
