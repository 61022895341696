import { getTranslate } from 'react-localize-redux';
import { tradersSelector } from '../redux/trader/selector';
import { isReferral } from './exchange';
import { isNull } from './Common';
import { getSubGroup } from './trader';
import { removeLocalStorageUser } from '../redux/authentication/actions';

export const satoshi = 0.00000001;

String.prototype.replaceAll = function (search, replacement) {
  const target = this;
  // return target.replace(new RegExp(search, 'g'), replacement);
  return target.split(search).join(replacement);
};

export const isLogged = (auth) => {
  return auth && auth.loggedIn === true;
};

export const nbColumn = (width) => {
  switch (width) {
    case 'xs':
      return 1;
    case 'sm':
      return 2;
    case 'md':
      return 2;
    case 'lg':
      return 3;
    default:
      return 4;
  }
};

export const showInitSteppers = () => {
  const showInitSteppers = localStorage.getItem('InitSteppers');
  return showInitSteppers === null || showInitSteppers === 'true';
};

export const getDateStr = (date, codeLang) => {
  if (date === null) return 'Infini';
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return new Date(date).toLocaleString(codeLang, options);
};
export const getDateHourStr = (date, codeLang) => {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return new Date(date).toLocaleString(codeLang, options);
};

export const getPriceAbo = (price, nbMonth = 0) => {
  if (price === null) return 0;
  if (nbMonth === 0) {
    let lowest = 0;
    for (const i in price) {
      const cP = price[i];
      if (lowest === 0 || cP < lowest) lowest = cP;
    }
    return lowest;
  }
  if (price[nbMonth]) return price[nbMonth];
  return 0;
};

export const getPriceStr = (price, nbMonth) => {
  const finalPrice = getPriceAbo(price, nbMonth);
  if (finalPrice === 0) return '';
  return finalPrice !== null ? `${finalPrice.toFixed(2)}€` : '';
};

export const getNumeric = () => {
  return '0123456789';
};
export const makeRandomStr = (length = 32, base32 = false) => {
  let result = '';
  const characters = base32
    ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567'
    : `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz${getNumeric()}`;
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const abs = (val) => {
  return val < 0 ? val * -1 : val;
};

export const Translate = (getState, id, data = null) => {
  const state = getState();
  const { localize } = state;
  const translate = getTranslate(localize);
  return translate(id, data);
};

export const isTradable = (state) => {
  const { user } = state;
  const { exchanges } = user;
  let haveReferral = false;
  if (!isNull(exchanges)) {
    for (const exchange of exchanges) {
      if (isReferral(exchange)) {
        haveReferral = true;
        break;
      }
    }
  }

  if (haveReferral) return true;

  const traders = tradersSelector(state);
  const wotGroup = traders.find((x) => x.GroupId === 1);
  const wotSG = getSubGroup(wotGroup);
  const wotEndDate = wotSG ? wotSG.EndDate : null;
  const now = new Date().toISOString();

  if (wotSG && wotSG.IsActive && (wotEndDate === null || wotEndDate >= now)) {
    return true;
  }

  return false;
};

export const sum = (numbers) =>
  numbers.reduce((a, b) => {
    return a + b;
  }, 0);

// export const nbDaysMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const connectAs = (id, email) => {
  removeLocalStorageUser();
  localStorage.setItem(
    'AuthAs',
    JSON.stringify({
      userId: id,
      email,
    }),
  );
  window.location.replace('/orders');
};
