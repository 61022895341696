import React from 'react';
import { connect } from 'react-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Card from '../../../components/Card/Card';
import { bindActionCreators } from 'redux';
import {
  getStatGroup,
  getStatGroup2,
  getStatUser,
  getStatUser2,
} from '../../../redux/admin/actions';

import { withLocalize } from 'react-localize-redux';
import Button from '../../../components/CustomButtons/Button';
import { isEmpty, isNull } from '../../../helpers/Common';
import MonthlyStats from '../../Traders/MonthlyStats';
import TableUIOrders from '../../Orders/TableUIOrders.jsx';
import TableStatsGroup from './TableStatsGroup.jsx';

class ShowStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showStats: false,
    };
  }

  //whichStat: old by default (new if stats2)
  //userId or groupId
  render() {
    const {
      userId,
      groupId,
      statsUser,
      statsUser2,
      statsGroup,
      statsGroup2,
      whichStat,
    } = this.props;
    const isAdmin = isNull(this.props.isAdmin) ? false : this.props.isAdmin;
    const { showStats } = this.state;

    // if (isNull(userId)) return <div />;
    const statsToShow = !isNull(userId)
      ? isEmpty(whichStat) || whichStat === 'old'
        ? statsUser
        : statsUser2
      : isEmpty(whichStat) || whichStat === 'old'
      ? statsGroup
      : statsGroup2;
    const monthlyStats =
      !isNull(statsToShow) && statsToShow.length > 0
        ? statsToShow[0].ProfitPerMonth
        : null;
    return (
      <>
        <Button
          color={'primary'}
          onClick={() => {
            if (!showStats) {
              if (isEmpty(whichStat) || whichStat === 'old') {
                if (!isNull(userId)) this.props.getStatUserActions(userId);
                if (!isNull(groupId)) this.props.getStatGroupActions(groupId);
              } else if (whichStat === 'new') {
                if (!isNull(userId)) this.props.getStatUser2Actions(userId);
                if (!isNull(groupId)) this.props.getStatGroup2Actions(groupId);
              }
            }
            this.setState({ showStats: !showStats });
          }}
        >
          ShowStats {isEmpty(whichStat) ? 'old' : whichStat}
        </Button>
        {showStats && (
          <div>
            <Card>
              <MonthlyStats stats={monthlyStats} />
            </Card>
            {!isNull(statsToShow) && statsToShow.length > 0 && (
              <>
                <span>
                  Stats group {isNull(whichStat) ? 'old' : whichStat}:
                </span>
                <TableStatsGroup statsGroup={statsToShow} />
              </>
            )}
            {!isNull(statsToShow) && statsToShow.length > 0 && (
              <>
                <span>Orders Considerated : </span>
                <TableUIOrders
                  tabOrders={2}
                  isAdmin={isAdmin}
                  userId={userId}
                  orders={statsToShow[0].OrdersConsiderated}
                />
                <span>Orders Not Considerated : </span>
                <TableUIOrders
                  tabOrders={2}
                  isAdmin={isAdmin}
                  userId={userId}
                  orders={statsToShow[0].OrdersNotConsiderated}
                />
              </>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    statsUser: admin.stats,
    statsUser2: admin.stats2,
    statsGroup: admin.statsGroup,
    statsGroup2: admin.statsGroup2,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStatUserActions: bindActionCreators(getStatUser, dispatch),
    getStatUser2Actions: bindActionCreators(getStatUser2, dispatch),
    getStatGroupActions: bindActionCreators(getStatGroup, dispatch),
    getStatGroup2Actions: bindActionCreators(getStatGroup2, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(ShowStats),
);
