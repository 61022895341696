import { isEmpty } from '../../helpers/Common';
import validateStarts from '../../helpers/smartTrading/StartActions/validate';
import validateTriggers from '../../helpers/smartTrading/validate';

export const between = (val, min, max) => {
  return (min !== '' && val < min) || (max !== '' && val > max);
};
export const greaterThan = (val, min) => {
  return min !== '' && val < min;
};
export const floatSafeRemainder = (val, step) => {
  val = parseFloat(val);
  step = parseFloat(step);
  if (isEmpty(val) || isEmpty(step)) return 0;
  const valDecCount = (val.toString().split('.')[1] || '').length;
  const stepDecCount = (step.toString().split('.')[1] || '').length;
  const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
  const valInt = parseInt(val.toFixed(decCount).replace('.', ''));
  const stepInt = parseInt(step.toFixed(decCount).replace('.', ''));
  return (valInt % stepInt) / 10 ** decCount;
};

export default (values, props) => {
  return {
    ...validateStarts(values, props), //base

    ...validateTriggers(values, props, 'starts'),
    ...validateTriggers(values, props, 'targets'),
    ...validateTriggers(values, props, 'stops'),
  };
};
