export const configConstants = {
  TAB_ORDERS: '@config/TAB_ORDERS',
  ORDERS_GRID_STATE_CHANGE_ACTION: '@config/ORDERS_GRID_STATE_CHANGE_ACTION',
  ORDERS_GRID_RESET: '@config/ORDERS_GRID_RESET',

  EXCHANGES_GRID_STATE_CHANGE_ACTION:
    '@config/EXCHANGES_GRID_STATE_CHANGE_ACTION',
  COLLAPSE_MENU: '@config/COLLAPSE_MENU',
  ACTION_CHANGE_THEME: '@config/ACTION_CHANGE_THEME',
  ACTION_CHANGE_NO_POPUP_TRADING: '@config/ACTION_CHANGE_NO_POPUP_TRADING',
  TAB_STRATEGY_VALUE: '@config/TAB_STRATEGY_VALUE',

  FOLLOWERS_GRID_STATE_CHANGE_ACTION:
    '@config/FOLLOWERS_GRID_STATE_CHANGE_ACTION',
  FOLLOWERS_GRID_RESET: '@config/FOLLOWERS_GRID_RESET',
  GET_NOTIFICATION: '@config/GET_NOTIFICATION',
  NOTIFICATION_ERROR: '@config/NOTIFICATION_ERROR',
};
