import {
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import React, { cloneElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from './Common';
import { lightColor } from '../assets/jss/material-dashboard-react';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    '&:hover': {
      color:
        theme.palette.type === 'dark' ? lightColor : theme.palette.primary.main,
    },
  },
}));

const GetMenuItem = ({
  icon,
  to,
  text,
  closeOnClick = true,
  dialog,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  let dialogWithOpen = null;
  if (!isEmpty(dialog)) {
    dialogWithOpen = cloneElement(
      dialog,
      { open, onClose: () => setOpen(false) },
      null,
    );
  }
  return (
    <>
      <MenuItem
        className={classes.menuItem}
        style={{ width: '100%' }}
        component={!isEmpty(to) ? Link : 'button'}
        to={to}
        {...rest}
        {...(!isEmpty(dialogWithOpen) && { onClick: () => setOpen(true) })}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{text}</ListItemText>
      </MenuItem>
      {!isEmpty(dialogWithOpen) && { ...dialogWithOpen }}
    </>
  );
};

export default GetMenuItem;
