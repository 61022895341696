import React from 'react';
import { Translate } from 'react-localize-redux';
import Grid from '@material-ui/core/Grid';
import TablePagination from '@material-ui/core/TablePagination';
import withWidth from '@material-ui/core/withWidth';
import { nbColumn } from '../../helpers/general';
import Card from '../../components/Card/Card';
import GridItem from '../../components/Grid/GridItem';
import ReferralContainer from './ReferralContainer';

class ReferralsContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 6,
      pageSizes: [6, 12, 24],
    };
  }

  showColumn = (column, referralsFiltered) => {
    let i = 0;
    const nbrColumn = nbColumn(this.props.width);
    const col = column === nbrColumn ? 0 : column;
    return referralsFiltered.map((referral) => {
      ++i;
      if (i % nbrColumn === col) {
        return (
          <Grid item key={i} style={{ padding: '0 5px !important' }}>
            <ReferralContainer referral={referral} />
          </Grid>
        );
      }
    });
  };

  setPage = (newPage) => {
    this.setState({ ...this.state, currentPage: newPage });
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      ...this.state,
      pageSize: event.target.value,
      currentPage: 0,
    });
  };

  paginate = (array, page_size, page_number) => {
    return array.slice(page_number * page_size, (page_number + 1) * page_size);
  };

  render() {
    const { referrals } = this.props;
    if (!referrals || referrals.length <= 0) return <div />;
    const { currentPage, pageSize, pageSizes } = this.state;
    const referralsFiltered = this.paginate(referrals, pageSize, currentPage);
    const nbrColumn = nbColumn(this.props.width);
    let tabCol = [];
    for (let i = 1; i <= nbrColumn; ++i) {
      tabCol.push(i);
    }
    return (
      <GridItem container style={{ boxSizing: 'border-box' }}>
        {referralsFiltered &&
          tabCol.map((col) => {
            return (
              <GridItem
                key={col}
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={3}
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexFlow: 'column',
                }}
              >
                {this.showColumn(col, referralsFiltered)}
              </GridItem>
            );
          })}
        {referrals.length === 0 && (
          <h3>
            <Translate id="central.loading" />
          </h3>
        )}
        <Card>
          <TablePagination
            rowsPerPageOptions={pageSizes}
            component="div"
            count={referrals.length}
            rowsPerPage={pageSize}
            page={currentPage}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Card>
      </GridItem>
    );
  }
}

export default withWidth()(ReferralsContainer);
