import { configConstants } from './constants';
import { KeyExchangeTable } from '../../helpers/exchange';

const localTheme = JSON.parse(localStorage.getItem('theme'));
const noPopupTrading = JSON.parse(localStorage.getItem('noPopupTrading'));
const collapseMenu = JSON.parse(localStorage.getItem('collapseMenu'));
const ordersGrid = JSON.parse(localStorage.getItem('ordersGrid'));
const exchangesGrid = JSON.parse(localStorage.getItem('exchangesGrid'));
const followersGrid = JSON.parse(localStorage.getItem('followersGrid'));
const defaultExchangesGrid = {
  // table
  columns: [],
  id: 1,
  rows: [],
  sorting: [{ columnName: KeyExchangeTable.POURCENTAGE.T, direction: 'desc' }],
  pageSizes: [5, 10, 20, 50, 0],
  colorColumns: [KeyExchangeTable.POURC_24H.T, KeyExchangeTable.POURC_7D.T],
  columnWidths: [
    { columnName: KeyExchangeTable.TOKEN.T, width: 100 },
    { columnName: KeyExchangeTable.AMOUNT.T, width: 100 },
    { columnName: KeyExchangeTable.LOCKED.T, width: 100 },
    { columnName: KeyExchangeTable.LOCKEDONWOT.T, width: 150 },
    { columnName: KeyExchangeTable.AVAILABLE.T, width: 110 },
    { columnName: KeyExchangeTable.PRICE_USD.T, width: 90 },
    { columnName: KeyExchangeTable.TOTAL_USD.T, width: 90 },
    { columnName: KeyExchangeTable.POURCENTAGE.T, width: 60 },
    { columnName: KeyExchangeTable.POURC_24H.T, width: 90 },
    { columnName: KeyExchangeTable.POURC_7D.T, width: 80 },
    { columnName: KeyExchangeTable.URL_CHARTS.T, width: 130 },
    { columnName: KeyExchangeTable.URL_CHARTS7.T, width: 150 },
  ],
  filters: [],
};

const defaultOrdersGrid = {
  id: 1,
  rows: [],
  sorting: [{ columnName: 'LastUpdate', direction: 'desc' }],
  grouping: [],
  expandedGroups: [],
  selection: [],
  expandedRowIds: [1],
  filters: [],
  tableColumnExtensions: [], // not use
  totalSummaryItems: [
    { columnName: 'Capital', type: 'sum' },
    { columnName: 'Profit', type: 'avg' },
    { columnName: 'Profit', type: 'sum' },
    { columnName: 'USD', type: 'sum' },
    { columnName: 'Gain', type: 'sum' },
    { columnName: 'DistNextOrder', type: 'avg' },
  ],
  currentPage: 0,
  pageSize: 10,
  pageSizes: [5, 10, 20, 50, 0],
  columns: [],
  columnOrder: [
    'Action',
    'Disabled',
    'Exchange',
    'Pair',
    'Side',
    'Slider',
    'Capital',
    'USD',
    'MaxDrawdown',
    'Note',
    'Profit',
    'Gain',
    'Trader',
    'EntryQty',
    'ExitQty',
    'DistNextOrder',
    'CurrentPrice',
    'StartPrice',
    'Leverage',
    'Targets',
    'StopLoss',
    'PanicSell',
    'LastUpdate',
    'DateCreated',
    'Email',
    'State',
    'States',
  ],
  columnWidths: [
    { columnName: 'Action', width: 110 },
    { columnName: 'Disabled', width: 130 },
    { columnName: 'Exchange', width: 110 },
    { columnName: 'Pair', width: 90 },
    { columnName: 'Side', width: 65 },
    { columnName: 'Slider', width: 400 },
    { columnName: 'DistNextOrder', width: 160 },
    { columnName: 'StartPrice', width: 110 },
    { columnName: 'Leverage', width: 70 },
    { columnName: 'Targets', width: 200 },
    { columnName: 'StopLoss', width: 110 },
    { columnName: 'PanicSell', width: 110 },
    { columnName: 'CurrentPrice', width: 120 },
    { columnName: 'EntryQty', width: 120 },
    { columnName: 'ExitQty', width: 120 },
    { columnName: 'USD', width: 70 },
    { columnName: 'MaxDrawdown', width: 120 },
    { columnName: 'Capital', width: 90 },
    { columnName: 'Note', width: 75 },
    { columnName: 'Profit', width: 110 },
    { columnName: 'Gain', width: 110 },
    { columnName: 'Trader', width: 110 },
    { columnName: 'LastUpdate', width: 110 },
    { columnName: 'DateCreated', width: 110 },
    { columnName: 'Email', width: 110 },
    { columnName: 'State', width: 100 },
    { columnName: 'States', width: 150 },
  ],
};
if (ordersGrid) {
  for (const e in defaultOrdersGrid) {
    if (!Object.hasOwn(ordersGrid, e)) {
      ordersGrid[e] = defaultOrdersGrid[e];
    }
    for (const c of defaultOrdersGrid.columnOrder) {
      if (ordersGrid.columnOrder.indexOf(c) === -1) {
        ordersGrid.columnOrder.push(c);
      }
    }
    for (const c of defaultOrdersGrid.columnWidths) {
      if (
        ordersGrid.columnWidths.filter((x) => x.columnName === c.columnName)
          .length === 0
      ) {
        ordersGrid.columnWidths.push(c);
      }
    }
  }
}

const defaultFollowersGrid = {
  id: 1,
  rows: [],
  sorting: [{ columnName: 'CreationDate', direction: 'desc' }],
  grouping: [],
  expandedGroups: [],
  selection: [],
  expandedRowIds: [1],
  filters: [],
  tableColumnExtensions: [],
  currentPage: 0,
  pageSize: 10,
  pageSizes: [5, 10, 20, 50, 0],
  columns: [],
  columnOrder: [
    'Action',
    'Username',
    'WillFollow',
    // 'CanCopyTrade',
    'SelectedCapital',
    'Capital',
    'Wallet',
    'UnPNL',
    // 'Pourcentage',
    'NbrTrade',
    'NbErrEntry',
    'NbPositionExchange',
    // 'HaveRebate',
    'EndDate',
    'WotDate',
    'Note',
    'CreationDate',
    'Risk',
    'HedgeMode',
  ],
  columnWidths: [
    { columnName: 'Action', width: 110 },
    { columnName: 'Username', width: 200 },
    { columnName: 'WillFollow', width: 80 },
    // { columnName: 'CanCopyTrade', width: 50 },
    { columnName: 'SelectedCapital', width: 100 },
    { columnName: 'Capital', width: 100 },
    { columnName: 'Wallet', width: 100 },
    { columnName: 'UnPNL', width: 100 },
    // { columnName: 'Pourcentage', width: 80 },
    { columnName: 'NbrTrade', width: 120 },
    { columnName: 'NbErrEntry', width: 120 },
    { columnName: 'NbPositionExchange', width: 120 },
    // { columnName: 'HaveRebate', width: 50 },
    { columnName: 'EndDate', width: 120 },
    { columnName: 'WotDate', width: 110 },
    { columnName: 'Note', width: 150 },
    { columnName: 'CreationDate', width: 110 },
    { columnName: 'Risk', width: 100 },
    { columnName: 'HedgeMode', width: 100 },
  ],
};
if (followersGrid) {
  for (const e in defaultFollowersGrid) {
    if (!Object.hasOwn(followersGrid, e)) {
      followersGrid[e] = defaultFollowersGrid[e];
    }
    for (const c of defaultFollowersGrid.columnOrder) {
      if (followersGrid.columnOrder.indexOf(c) === -1) {
        followersGrid.columnOrder.push(c);
      }
    }
    for (const c of defaultFollowersGrid.columnWidths) {
      if (
        followersGrid.columnWidths.filter((x) => x.columnName === c.columnName)
          .length === 0
      ) {
        followersGrid.columnWidths.push(c);
      }
    }
  }
}

const configInitialState = {
  tabStrategyValue: 0,
  theme: localTheme || 'dark',
  noPopupTrading: noPopupTrading || false,
  collapseMenu: collapseMenu || false,
  exchangesGrid: exchangesGrid || defaultExchangesGrid,
  followersGrid: followersGrid || defaultFollowersGrid,
  tabOrders: 0,
  ordersGrid: ordersGrid || defaultOrdersGrid,
  notificationSettings: null,
};

export function config(state = configInitialState, action) {
  switch (action.type) {
    case configConstants.TAB_ORDERS:
      return {
        ...state,
        tabOrders: action.payload,
      };
    case configConstants.TAB_STRATEGY_VALUE:
      return {
        ...state,
        tabStrategyValue: action.payload,
      };
    case configConstants.ORDERS_GRID_RESET:
      return {
        ...state,
        ordersGrid: defaultOrdersGrid,
      };
    case configConstants.EXCHANGES_GRID_STATE_CHANGE_ACTION: {
      const exchangesGrid = {
        ...state.exchangesGrid,
        [action.partialStateName]: action.partialStateValue,
      };
      localStorage.setItem('exchangesGrid', JSON.stringify(exchangesGrid));
      return {
        ...state,
        exchangesGrid,
      };
    }
    case configConstants.ORDERS_GRID_STATE_CHANGE_ACTION: {
      const ordersGrid = {
        ...state.ordersGrid,
        [action.partialStateName]: action.partialStateValue,
      };
      localStorage.setItem('ordersGrid', JSON.stringify(ordersGrid));
      return {
        ...state,
        ordersGrid,
      };
    }
    case configConstants.FOLLOWERS_GRID_STATE_CHANGE_ACTION: {
      const followersGrid = {
        ...state.followersGrid,
        [action.partialStateName]: action.partialStateValue,
      };
      localStorage.setItem('followersGrid', JSON.stringify(followersGrid));
      return {
        ...state,
        followersGrid,
      };
    }
    case configConstants.FOLLOWERS_GRID_RESET:
      return {
        ...state,
        followersGrid: defaultFollowersGrid,
      };
    case configConstants.ACTION_CHANGE_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case configConstants.ACTION_CHANGE_NO_POPUP_TRADING:
      return {
        ...state,
        noPopupTrading: action.payload,
      };
    case configConstants.COLLAPSE_MENU:
      return {
        ...state,
        collapseMenu: action.payload,
      };
    case configConstants.GET_NOTIFICATION:
      return {
        ...state,
        notificationSettings: action.payload,
      };
    case configConstants.NOTIFICATION_ERROR:
      return {
        ...state,
        notificationError: action.payload,
      };
    default:
      return state;
  }
}
