import {
  alertActions,
  showPopupOptionsPaiement,
} from '../redux/alert/alert.actions';
import { loadingActions } from '../redux/loading/loading.actions';
import { logoutFunc } from '../redux/authentication/actions';
import { getHeader } from './auth-header';
import { getDefaultLanguage } from './localize';

export const isDev = () =>
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_VERCEL_ENV === 'development' ||
  process.env.REACT_APP_VERCEL_ENV === 'preview';

export const getBaseFrontUrl = () => {
  // return 'http://localhost:3000/';
  // make eslint ignore the unreachable code error
  // eslint-disable-next-line no-unreachable
  if (isDev()) return 'https://socialtradingcryptos.vercel.app/';
  return 'https://walloftraders.com/';
};
export const getUrl = () => {
  // return 'http://localhost:5054/';
  // make eslint ignore the unreachable code error
  // eslint-disable-next-line no-unreachable
  if (isDev()) return 'https://test.walloftraders.com/';
  return 'https://api.walloftraders.com/';
};

export const myFetchAsync = async ({ endPoint, options }) => {
  // Create header
  const headers = getHeader();
  const lang = getDefaultLanguage();
  headers.append('Accept-Language', lang);
  const authAs = JSON.parse(localStorage.getItem('AuthAs'));
  if (authAs != null) headers.append('AuthAs', authAs.userId);

  if (options.method === 'POST' || options.method === 'PUT') {
    headers.append('Content-Type', 'application/json');
    // headers.append('Accept', 'application/json');
  }

  // Options request
  const fetchOptions = {
    method: options.method || 'GET',
    headers,
  };

  if (endPoint === 'token') {
    fetchOptions.body = options.body;
  } else {
    // Create body
    if (options.body) {
      const body = { ...options.body };
      const override = [];
      for (const val in body) {
        if (val && body[val] && typeof body[val] === 'object') {
          if (Array.isArray(body[val])) {
            const newArr = [];
            let isSelect = false;
            for (const v of body[val]) {
              if (Object.hasOwn(v, 'value') && Object.hasOwn(v, 'label')) {
                isSelect = true;
                newArr.push(v.value);
              } else {
                newArr.push(v);
              }
            }
            body[val] = newArr;
            if (isSelect) {
              body[`${val}Str`] = JSON.stringify(newArr);
              if (
                Object.hasOwn(body, `${val}CB`) &&
                body[`${val}CB`] === true
              ) {
                override.push(val);
              }
            }
          } else {
            // si select put value as value
            if (
              Object.hasOwn(body[val], 'value') &&
              Object.hasOwn(body[val], 'label')
            ) {
              body[val] = body[val].value;
            }
          }
        }
      }
      if (Object.hasOwn(body, 'StrategyConfigurationId'))
        body.OverrideStr = JSON.stringify(override);
      fetchOptions.body = JSON.stringify(body);
    }
  }

  let url = getUrl();

  // Do request
  return await fetch(url + endPoint, fetchOptions);
};

export const fetcher = async (dispatch, endPoint, options) => {
  const loadingName = options.loadingName || 'Loading';
  try {
    if (dispatch) dispatch(loadingActions.loadingOn(loadingName));

    const response = await myFetchAsync({ endPoint, options });
    // console.log(response)
    let json = null;
    try {
      json = await response.json();
      // eslint-disable-next-line no-empty
    } catch (e) { }
    if (response.ok) {
      if (options.success && dispatch)
        dispatch(alertActions.notifSuccess(options.success, options.duration));
      if (dispatch) dispatch(loadingActions.loadingOff(loadingName));
      return json;
    }

    let errorMessage = '';

    // Redirect if no connecter
    if (
      (response.status === 401 || response.status === 403) &&
      endPoint.indexOf('Logout') === -1
    ) {
      errorMessage = 'Authorization has been denied for this request.';
      await logoutFunc(dispatch);
    }

    // Gestion derreur
    // if (json && json.Success === true && json.Result != null) {
    // Success pass plus haut
    //   if (dispatch) dispatch(alertActions.notifSuccess(json.Result));
    // } else
    if (json && json.Success === false) {
      errorMessage = json.Error;
    }
    if (json && json.errors) {
      errorMessage = json.title;
      for (const v in json.errors) {
        const table = json.errors[v];
        if (table.length > 0) {
          errorMessage += `\n${table[0]}`;
        }
      }
    }
    if (json && json.Message) {
      errorMessage = json.Message;
      for (const v in json.ModelState) {
        const table = json.ModelState[v];
        if (table.length > 0) {
          errorMessage += `\n${table[0]}`;
        }
      }
    }
    if (errorMessage === 'popup.optionsPaiement') {
      if (dispatch) dispatch(showPopupOptionsPaiement());
    } else if (errorMessage !== '') {
      if (dispatch) dispatch(alertActions.notifError(errorMessage));
    }
    if (dispatch) dispatch(loadingActions.loadingOff(loadingName));
  } catch (e) {
    if (dispatch) dispatch(alertActions.notifError(e.message));
    if (dispatch) dispatch(loadingActions.loadingOff(loadingName));
  }
};
