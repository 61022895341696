import React from 'react';
import {
  change,
  Field,
  formValueSelector,
  getFormSyncErrors,
} from 'redux-form';
import {
  getPriceFromProfit,
  getProfitFromPrice,
  getSecFromPair,
  isEmpty,
  parseToFloat,
  stepPriceSelector,
} from '../../../helpers/Common';
import GridContainer from '../../../components/Grid/GridContainer';
import Grid from '@material-ui/core/Grid';
import Percentage from '../../../components/Fields/Percentage';
import Price from '../../../components/Fields/Price';
import withStyles from '@material-ui/core/styles/withStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { triggerIsDone } from '../../../helpers/order';
import GridItem from '../../../components/Grid/GridItem';
import Helper from '../../../components/Hover/Helper';
import { SmallCheckBox } from '../../../components/Fields/SmallCheckBox';
import LabelCheckbox from '../../../components/Form/LabelCheckbox';
import LimitMarketButton from './LimitMarketButton';
import Divider from '@material-ui/core/Divider/Divider';

const style = {
  littleGridStart: {
    paddingLeft: '15px !important',
    paddingRight: '5px !important',
  },
  littleGrid: {
    padding: '0 5px !important',
  },
  littleGridEnd: {
    paddingLeft: '5px !important',
    paddingRight: '15px !important',
  },
};

class TakeProfitLine extends React.PureComponent {
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const {
      index,
      price,
      profit,
      startPrice,
      trailing,
      trailingPrice,
      isBuy,
      name,
      formName,
      change,
      stepPrice,
    } = this.props;

    // if (!isEmpty(price) || !isEmpty(profit) || !isEmpty(startPrice)) {
    // const step = nbStep(stepPrice);
    const nPrice = parseFloat(getPriceFromProfit(profit, isBuy, startPrice));
    const newPrice = !isEmpty(nPrice) ? parseToFloat(nPrice, stepPrice) : null;
    const nProfit = parseFloat(getProfitFromPrice(price, isBuy, startPrice));
    const newProfit = !isEmpty(nProfit) ? parseToFloat(nProfit, 0.01) : null;
    // console.log('getPriceFromProfit', getPriceFromProfit(profit, isBuy, startPrice), profit, isBuy, startPrice, nPrice, nProfit, step);

    // console.log(price, profit, isBuy, startPrice);
    // console.log(profit, prevProps.profit, newProfit);
    // console.log(price, prevProps.price, newPrice);
    if (
      isBuy !== prevProps.isBuy ||
      startPrice !== prevProps.startPrice ||
      (profit !== prevProps.profit && newProfit !== profit)
    ) {
      // console.log('change price', index, newPrice);
      change(
        formName,
        `${name}[${index}].price`,
        newPrice === 0 ? undefined : newPrice,
      );
    } else if (price !== prevProps.price && newPrice !== price) {
      // console.log('change profit', index, newProfit);
      change(
        formName,
        `${name}[${index}].profit`,
        newProfit === 0 ? undefined : newProfit,
      );
    }
    // }

    const nPriceTrailing = parseFloat(
      getPriceFromProfit(-1 * trailing, isBuy, price),
    );
    const newPriceTrailing = !isEmpty(nPriceTrailing)
      ? parseToFloat(nPriceTrailing, stepPrice)
      : null;
    const nProfitTrailing =
      -1 * parseFloat(getProfitFromPrice(trailingPrice, isBuy, price));
    const newProfitTrailing = !isEmpty(nProfitTrailing)
      ? parseToFloat(nProfitTrailing, 0.01)
      : null;

    if (
      price !== prevProps.price ||
      (trailing !== prevProps.trailing && newProfitTrailing !== trailing)
    ) {
      change(
        formName,
        `${name}[${index}].trailingPrice`,
        newPriceTrailing === 0 ? undefined : newPriceTrailing,
      );
    } else if (
      trailingPrice !== prevProps.trailingPrice &&
      newPriceTrailing !== trailingPrice
    ) {
      // console.log('change profit', index, newProfit);
      change(
        formName,
        `${name}[${index}].trailing`,
        newProfitTrailing === 0 ? undefined : newProfitTrailing,
      );
    }
  };

  render() {
    const {
      index,
      classes,
      translate,
      pair,
      isBuy,
      startPrice,
      trailing,
      trailingCB,
      formName,
      name,
      price,
      target,
      stepPrice,
      isLast,
      isTemplate,
    } = this.props;
    const isDone = triggerIsDone(target);
    return (
      <GridContainer key={index}>
        <Grid
          item
          xs={!isTemplate ? 3 : 6}
          sm={!isTemplate ? 3 : 6}
          md={!isTemplate ? 3 : 6}
          className={classes.littleGridStart}
        >
          <Percentage
            formName={formName}
            className="form-control"
            name={`${index}.pourcentage`}
            placeholder="Part"
            step={0.01}
            disabled={isDone}
          />
        </Grid>
        {!isTemplate && (
          <Grid item xs={6} sm={6} md={6} className={classes.littleGrid}>
            <Price
              formName={formName}
              endAdornment={getSecFromPair(pair)}
              name={`${index}.price`}
              min={isBuy ? startPrice : 0}
              max={isBuy ? '' : startPrice}
              step={stepPrice}
              placeholder={translate('smartTrade.price')}
              disabled={isDone}
              onChange={() => {
                this.props.change(
                  formName,
                  `${name}.${index}.lastUpdated`,
                  'price',
                );
              }}
            />
          </Grid>
        )}
        <Grid
          item
          xs={!isTemplate ? 3 : 6}
          sm={!isTemplate ? 3 : 6}
          md={!isTemplate ? 3 : 6}
          className={classes.littleGridEnd}
        >
          <Percentage
            formName={formName}
            name={`${index}.profit`}
            placeholder={translate('smartTrade.profit')}
            max=""
            disabled={isDone}
            onChange={() => {
              this.props.change(
                formName,
                `${name}.${index}.lastUpdated`,
                'profit',
              );
            }}
          />
        </Grid>
        {!isLast && (
          <GridItem xs={12} sm={12} md={5}>
            <LimitMarketButton
              formName={formName}
              indexName={`${name}[${index}].`}
              isDone={isDone}
              name="orderType"
              isTargets={true}
            />
          </GridItem>
        )}
        {!isLast && (
          <GridItem xs={6} sm={6} md={4}>
            <div style={{ float: 'left', margin: '0.5em 0px 13px 0px' }}>
              <div style={{ float: 'right', marginTop: '5px' }}>
                &nbsp;
                <Helper
                  text={
                    isBuy ? (
                      <Translate id="smartTrade.infoTrailingTargetSell" />
                    ) : (
                      <Translate id="smartTrade.infoTrailingTargetBuy" />
                    )
                  }
                />
              </div>
              <Field
                name={`${index}.trailingCB`}
                id={`${index}.trailingCB`}
                component={SmallCheckBox}
                style={{ padding: '6px' }}
                disabled={isDone}
                onChange={() => {
                  this.props.change(
                    formName,
                    `${name}[${index}].orderType`,
                    'Market',
                  );
                  const nPrice = parseFloat(
                    getPriceFromProfit(-1 * trailing, isBuy, price),
                  );
                  const newPrice = !isEmpty(nPrice)
                    ? parseToFloat(nPrice, stepPrice)
                    : null;
                  this.props.change(
                    formName,
                    `${name}.${index}.trailingPrice`,
                    newPrice,
                  );
                }}
              />
              <LabelCheckbox htmlFor={`${index}.trailingCB`}>
                <Translate id="smartTrade.trailing" />
              </LabelCheckbox>
            </div>
          </GridItem>
        )}
        {!isLast && trailingCB && (
          <>
            <GridItem xs={6} sm={6} md={6}>
              <Percentage
                disabled={isDone}
                placeholder={'Trailing Pourcentage'}
                name={`${index}.trailing`}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <Price
                formName={formName}
                endAdornment={getSecFromPair(pair)}
                name={`${index}.trailingPrice`}
                min={isBuy ? startPrice : 0}
                max={isBuy ? '' : startPrice}
                step={stepPrice}
                placeholder={'Trailing Price'}
                disabled={isDone}
              />
            </GridItem>
          </>
        )}
        <GridItem xs={12} sm={12} md={12}>
          <Divider style={{ margin: '10px 0px' }} />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { localize } = state;
  const { index, formName, name } = props;
  const formSelector = formValueSelector(formName);
  return {
    isBuy: formSelector(state, 'isBuy'),
    startPrice: formSelector(state, 'startPrice'),
    translate: getTranslate(localize),
    syncErrors: getFormSyncErrors(props.formName)(state),
    pair: formSelector(state, 'pair'),
    trailingCB: formSelector(state, `${name}[${index}].trailingCB`),
    trailing: formSelector(state, `${name}[${index}].trailing`),
    trailingPrice: formSelector(state, `${name}[${index}].trailingPrice`),
    orderType: formSelector(state, `${name}[${index}].orderType`),
    price: formSelector(state, `${name}[${index}].price`),
    profit: formSelector(state, `${name}[${index}].profit`),
    target: formSelector(state, `${name}[${index}].target`),
    isTemplate: formSelector(state, 'isTemplate'),
    stepPrice: stepPriceSelector(state, props),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default withStyles(style)(
  connect(mapStateToProps, mapDispatchToProps)(TakeProfitLine),
);
