export const authenticationConstants = {
  REGISTER_REQUEST: '@auth/USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: '@auth/USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: '@auth/USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: '@auth/USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: '@auth/USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: '@auth/USERS_LOGIN_FAILURE',

  LOGOUT: '@auth/USERS_LOGOUT',

  ADD_FAKE_TELEGRAM: '@auth/ADD_FAKE_TELEGRAM',

  GET_TFA: '@auth/GET_TFA',
  SET_TFA: '@auth/SET_TFA',

  // GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  // GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  // GETALL_FAILURE: 'USERS_GETALL_FAILURE'
};
