import React from 'react';
import PieChart, { getColor } from './PieChart';

const PieChartGains = ({ gains }) => {
  const gainsToPie = () => {
    let rets = [];
    let total = 0;
    for (const gain of gains) {
      rets.push({
        name: gain.name,
        color: getColor(gain.token.symbol),
        amount: gain.amount,
        value: gain.token.price,
      });
      total += gain.token.price;
    }
    for (const ret of rets) {
      ret.y = (ret.value / total) * 100;
    }
    return rets;
  };
  return <PieChart data={gainsToPie()} />;
};

export default PieChartGains;
