import React from 'react';
import MyTooltip from '../MyTooltip';
import WarningIcon from '@material-ui/icons/Warning';
import { warningColor } from '../../assets/jss/material-dashboard-react';

class WarningHover extends React.PureComponent {
  render() {
    const { text, disabled } = this.props;
    if (disabled) return <div />;
    return (
      <MyTooltip text={text}>
        <WarningIcon
          htmlColor={warningColor}
          style={{ cursor: 'pointer' }}
          fontSize={'small'}
        />
      </MyTooltip>
    );
  }
}

export default WarningHover;
