import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

import ReactSelect from '../../../components/Form/ReactSelectUI';

import {
  getExchangesSelect,
  isEmpty,
  getBaseUSDPairExchange,
  getPairSelect,
  getExchange,
  isBinanceFuture,
  isBybitFuture,
} from '../../../helpers/Common';

class SelectAccountContainer extends React.PureComponent {
  componentDidUpdate = (prevProps, prevState) => {
    const { accountId, formName, change, isUpdate, exchange } = this.props;

    if (isUpdate) return;

    if (!isEmpty(accountId) && exchange) {
      if (accountId !== prevProps.accountId) {
        const pairStr = getBaseUSDPairExchange(accountId.exchange, 'BTC');
        change(formName, 'pair', getPairSelect(pairStr));
        change(formName, 'isPaperTrading', exchange && exchange.IsPaperTrading);
        //Active leverage par default quand binance Futures
        if (
          isBinanceFuture(accountId.exchange) ||
          isBybitFuture(accountId.exchange)
        )
          change(formName, 'leverageCB', true);
        change(formName, 'leverage', 1);
      }
    }
  };

  render() {
    const { exchangesSelect, isUpdate, theme } = this.props;
    let exSelect = exchangesSelect;
    exSelect = exSelect.filter((x) => x.exchange !== 'Bitmex');
    const hasExchange = exSelect && exSelect.length > 0;
    return (
      <div>
        {hasExchange && (
          <Field
            name="accountId"
            label={<Translate id="smartTrade.accountName" />}
            options={exSelect}
            fullWidth={true}
            component={ReactSelect}
            required
            disabled={isUpdate}
          />
        )}
        {!hasExchange && (
          <>
            <Translate id="central.noExchange.mustAdd" />.{' '}
            <Link
              to="/exchanges"
              className={'link-text ' + (theme === 'dark' ? 'dark' : 'light')}
            >
              <Translate id="central.noExchange.here" />
            </Link>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { config } = state;

  const formSelector = formValueSelector(props.formName);
  return {
    theme: config.theme,
    exchangesSelect: getExchangesSelect(state, props),
    exchange: getExchange(state, props),
    accountId: formSelector(state, 'accountId'),
    isUpdate: formSelector(state, 'isUpdate'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectAccountContainer);
