import React from 'react';
import Highcharts from 'highcharts/highcharts';
import HighchartsReact from 'highcharts-react-official';

const dataCrypto = [
  { name: 'XBT', color: '#f2a900' },
  { name: 'BTC', color: '#f2a900' },
  { name: 'BNB', color: '#F0B90B' },
  { name: 'BETH', color: '#3c3c3d' },
  { name: 'ETH', color: '#3c3c3d' },
  { name: 'EOS', color: '#2a2a2a' },
  { name: 'EUR', color: '#004994' },
  { name: 'USD', color: 'gold' },
  { name: 'USDT', color: 'gold' },
  { name: 'TUSD', color: 'gold' },
  { name: 'USDC', color: 'gold' },
  { name: 'DAI', color: 'gold' },
  { name: 'DOT', color: '#d64cA8' },
  { name: 'DOT.S', color: '#d64cA8' },
  { name: 'IOTA', color: '#22b1ab' },
  { name: 'OMG', color: '#1A53F0' },
  { name: 'XRP', color: '#006097' },
  { name: 'BCH', color: '#ee8c28' },
  { name: 'ADA', color: '#2a71d0' },
  { name: 'LTC', color: '#d3d3d3' },
  { name: 'NEO', color: '#74BC2A' },
  { name: 'XLM', color: '#04b5e5' },
  { name: 'XTZ', color: '#3391ff' },
  { name: 'XTZ.S', color: '#3391ff' },
];

export const getColor = (name) => {
  const data = dataCrypto.find((x) => x.name === name);
  return data ? data.color : '';
};

class PieChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          height: props.height || '100%',
          // plotBackgroundColor: null,
          // plotBorderWidth: null,
          // plotShadow: false,
          backgroundColor: null,
          type: 'pie',
        },
        credits: { enabled: false },
        title: {
          text: '',
        },
        tooltip: {
          pointFormat:
            '{series.name}: <b>{point.percentage:.1f}%</b><br/>' +
            'Amount: <b>{point.amount}</b><br/>' +
            'Value: <b>{point.value}</b>',
        },
        plotOptions: {
          pie: {
            // allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: 'Brands',
            // colorByPoint: true,
            data: [],
          },
        ],
      },
    };
  }

  render() {
    const { options } = this.state;
    const { data } = this.props;

    let myOptions = { ...options };
    myOptions.series = [{ name: 'Wallet', data: data }];

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={myOptions}
        // constructorType={'pie'}
      />
    );
  }
}

export default PieChart;
