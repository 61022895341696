import React from 'react';
import { connect } from 'react-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import { bindActionCreators } from 'redux';
import { getOrdersLimit, IsAdmin } from '../../../redux/admin/actions';
import transSmartTrade from '../../../locales/smartTrade.json';
import transOrders from '../../../locales/orders.json';
import transConfirm from '../../../locales/confirmation.json';
import { withLocalize } from 'react-localize-redux';
import Orders from '../../Orders/Orders';

class OrdersLimitPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transOrders);
    this.props.addTranslation(transSmartTrade);
    this.props.addTranslation(transConfirm);
  }
  componentDidMount() {
    this.props.getOrdersLimitActions();
    this.props.IsAdminActions();
  }

  render() {
    const { orders } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          Nombre ordre: {orders.length}
          <Orders
            isHistoric={true}
            forceTable={true}
            isAdmin={true}
            orders={orders}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    orders: admin.orders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrdersLimitActions: bindActionCreators(getOrdersLimit, dispatch),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(OrdersLimitPage),
);
