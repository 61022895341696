import React from 'react';
import { isRequired } from '../../helpers/form';
import { Translate } from 'react-localize-redux';

export default (values) => {
  const errors = {};
  const requiredFields = ['name', 'reference', 'shortDescription'];
  const { reference, name, shortDescription } = values;

  for (const field of requiredFields) {
    if (isRequired(values[field])) {
      errors[field] = <Translate id="central.required" />;
    }
  }

  const minAll = 5;

  const lengthName = name && name.length;
  const minName = minAll;
  const maxName = 25;
  if (lengthName < minName || lengthName > maxName)
    errors.name = (
      <Translate id="error.between" data={{ min: minName, max: maxName }} />
    );

  const maxRef = 32;
  const lengthReference = reference && reference.length;
  if (lengthReference < minAll || lengthReference > maxRef)
    errors.reference = (
      <Translate id="error.between" data={{ min: minAll, max: maxRef }} />
    );

  const lengthShortDescription = shortDescription && shortDescription.length;
  if (lengthShortDescription < minAll)
    errors.shortDescription = (
      <Translate id="error.greater" data={{ val: minAll }} />
    );

  return errors;
};
