import React from 'react';

import GridContainer from '../../components/Grid/GridContainer.jsx';
import TableExchange from '../Accounts/Exchange/TableExchange';
import PieExchange from '../Accounts/ExchangeContainer';
import { bindActionCreators } from 'redux';
import { getExchanges } from '../../redux/user/actions';
import connect from 'react-redux/es/connect/connect';
import GridItem from '../../components/Grid/GridItem.jsx';
import LineChartWallet from '../../components/Charts/LineChartWallet';
import { isLogged } from '../../helpers/general';
import transExchanges from '../../locales/exchanges';
import Responsive from '../../layouts/Responsive.jsx';
import { withLocalize } from 'react-localize-redux';

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transExchanges);
  }
  componentDidMount() {
    const { auth } = this.props;
    if (isLogged(auth)) {
      this.props.getExchangesActions(false);
    }
  }
  render() {
    const exchangesBase = this.props.exchanges;
    if (!(exchangesBase && exchangesBase.length > 0)) return <div />;
    const exchanges = exchangesBase.filter(
      (x) => x.ExchangeName !== 'Binance_Futures_Test',
    );

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={4} xl={3}>
          <PieExchange exchange={exchanges} />
        </GridItem>
        <Responsive
          desktop={
            <GridItem xs={12} sm={12} md={12} lg={8} xl={9}>
              <LineChartWallet />
            </GridItem>
          }
        />
        <TableExchange exchange={exchanges} />
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { user, authentication } = state;
  return {
    auth: authentication,
    exchanges: user.exchanges,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getExchangesActions: bindActionCreators(getExchanges, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(DashboardPage),
);
