import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createRootReducer from '../redux/reducers';

export const history = createBrowserHistory();

export default function configureStore(initialState) {
  //  Dev remote devtools
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
          name: 'My Redux',
        })
      : compose;

  const enhancer = composeEnhancers(
    applyMiddleware(routerMiddleware(history), thunkMiddleware),
  );

  const store = createStore(
    createRootReducer(history),
    initialState,
    compose(enhancer),
    // initialState
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../redux/reducers', () => {
      const nextReducer = createRootReducer(history);
      store.replaceReducer(nextReducer);
    });
  }
  return store;
}
