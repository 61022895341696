import React from 'react';
// import { TextField } from 'redux-form-material-ui';
import TextField from '@material-ui/core/TextField';
// import createComponent from './createComponent';
// import mapError from './mapError';

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    // placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);
export default renderTextField;
// export default TextField;
// export default createComponent(TextField, ({ defaultValue, ...props }) =>
//   mapError(props)
// );
