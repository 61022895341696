/* eslint-disable react/prop-types */
import React from 'react';

// eslint-disable-next-line react/display-name
export default (selectType) => (props) => {
  return React.createElement(selectType, {
    ...props,
    value: props.input.value,
    onChange: (value) => props.input.onChange(value),
    onBlur: () => props.input.onBlur(props.input.value),
  });
};
