import { strategyConfigurationConstants } from './constants';

const initialState = { strategyConfigurations: [] };

export function strategyConfiguration(state = initialState, action) {
  switch (action.type) {
    case strategyConfigurationConstants.GET_STRATEGY_CONFIGURATION:
      return {
        ...state,
        strategyConfigurations: action.payload,
      };
    case strategyConfigurationConstants.POST_STRATEGY_CONFIGURATION:
      return {
        ...state,
        strategyConfigurations: state.strategyConfigurations.concat(
          action.payload,
        ),
      };
    case strategyConfigurationConstants.DELETE_STRATEGY_CONFIGURATION:
      return {
        ...state,
        strategyConfigurations: state.strategyConfigurations.filter(
          (x) => x.StrategyConfigurationId !== action.payload,
        ),
      };
    case strategyConfigurationConstants.PUT_STRATEGY_CONFIGURATION: {
      const index = state.strategyConfigurations.findIndex(
        (x) =>
          x.StrategyConfigurationId === action.payload.StrategyConfigurationId,
      );
      if (index === -1) return state;
      state.strategyConfigurations[index] = action.payload;
      return {
        ...state,
        strategyConfigurations: [...state.strategyConfigurations],
      };
    }
    default:
      return state;
  }
}
