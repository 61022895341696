import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Divider,
  FormControl,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { Translate } from 'react-localize-redux';

import {
  successColor,
  dangerColor,
} from '../../assets/jss/material-dashboard-react.jsx';
import { sum } from '../../helpers/general';
import { getActiveCode, getIndexLang } from '../../helpers/localize.js';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
}));

const monthsStr = [
  [
    'JAN',
    'FÉV',
    'MARS',
    'AVR',
    'MAI',
    'JUIN',
    'JUIL',
    'AOÛT',
    'SEP',
    'OCT',
    'NOV',
    'DÉC',
  ],
  [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ],
  [
    'ЯНВ',
    'ФЕВ',
    'МАР',
    'АПР',
    'МАЙ',
    'ИЮН',
    'ИЮЛ',
    'АВГ',
    'СЕН',
    'ОКТ',
    'НОЯ',
    'ДЕК',
  ],
];

const ColorizedStat = ({ value }) => (
  <span style={{ color: value >= 0 ? successColor : dangerColor }}>
    {value}
  </span>
);

/**
 * MonthlyStats mobile screen size
 */
function MonthlyStatsMobile({ stats }) {
  const codeLang = useSelector(getActiveCode);
  const months = monthsStr[getIndexLang(codeLang)];
  const classes = useStyles();
  const dates = useMemo(() => (stats ? Object.keys(stats) : []), [stats]);
  const [selectedDates, setSelectedDates] = useState(
    dates.length ? [dates[dates.length - 1]] : [],
  );
  useEffect(() => {
    setSelectedDates(dates.length ? [dates[dates.length - 1]] : []);
  }, [dates]);
  const [open, setOpen] = useState(false);
  const handleChange = (event) => {
    setSelectedDates(event.target.value);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const sortedSelectedDates = selectedDates.slice().sort();
  return (
    <FormControl className={classes.formControl}>
      <Select
        multiple
        value={sortedSelectedDates}
        onChange={handleChange}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {dates.map((date) => (
          <MenuItem key={date} value={date}>
            {date}
          </MenuItem>
        ))}
      </Select>
      <div>
        {selectedDates.length === 0 ? (
          <p>↑ Séléctionnez une ou plusieurs dates ↑</p>
        ) : (
          <TableContainer component={Paper}>
            <Table className={classes.table} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell />
                  {sortedSelectedDates.map((selectedDate) => (
                    <TableCell key={selectedDate} align="right">
                      {selectedDate}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Total</TableCell>
                  {sortedSelectedDates.map((selectedDate) => (
                    <TableCell key={selectedDate} align="right">
                      <ColorizedStat
                        value={sum(Object.values(stats[selectedDate])).toFixed(
                          2,
                        )}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                {months.map((month, monthIndex) => (
                  <TableRow key={month}>
                    <TableCell component="th" scope="row">
                      {month}
                    </TableCell>
                    {sortedSelectedDates.map((selectedDate) => (
                      <TableCell key={selectedDate} align="right">
                        {stats[selectedDate][monthIndex + 1] && (
                          <ColorizedStat
                            value={stats[selectedDate][monthIndex + 1].toFixed(
                              2,
                            )}
                          />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Divider />
        <div style={{ textAlign: 'center', margin: '0.5rem' }}>
          <div className="icons-text">
            <ErrorOutline />
          </div>
          <Translate
            id="traders.pastDontTellFuture"
            options={{ renderInnerHtml: true }}
          />
        </div>
      </div>
    </FormControl>
  );
}

export default MonthlyStatsMobile;
