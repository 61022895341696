import {
  Autorenew,
  Close,
  Create,
  DeleteForever,
  Done,
  Eject,
  Info,
  Power,
  PowerOff,
} from '@material-ui/icons';
import React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import {
  dangerColor,
  infoColor,
  roseColor,
  successColor,
  warningColor,
} from '../../assets/jss/material-dashboard-react';
import GetMenuItem from '../../helpers/menuItems';
import { alertPopup } from '../../redux/alert/alert.actions';
import {
  acceptOrder,
  checkOrder,
  deleteOrder,
  refuseOrder,
  toggleDisabledUser,
} from '../../redux/order/actions';
import { adminUrl } from '../../routes/dashboard';
import DialogPanicSell from './DialogPanicSell';
import { getAllStart } from '../../helpers/order';
import { isOrderV2 } from '../../helpers/Common';

class OrderMenuButtons extends React.Component {
  render() {
    const { order, isAdmin, noPopupTrading } = this.props;
    const isTemplate = order.OrderType === 1 || order.State === 9; // order.State === 9 est pour la v1, à retirer quand plus de v1
    const isWait = !isTemplate && [0].includes(order.State);
    const canUpdate = isTemplate || [0, 1, 4].includes(order.State);
    const canCheck = !isTemplate && [1, 4].includes(order.State);
    let canTmp = canCheck && order && getAllStart(order.Triggers);
    const canPanicSell = canTmp && !!canTmp.find((x) => x.State === 3);
    const canDelete = isTemplate || [1, 4].includes(order.State);
    const isBuy = order.IsBuy;
    const isV2 = isOrderV2(order);

    const removeOrderAction = (id) => {
      if (noPopupTrading)
        this.props.deleteOrderActions(id, isV2, noPopupTrading);
      else {
        this.props.alertActions({
          type: 'confirm',
          then: (result) => {
            if (result.value)
              this.props.deleteOrderActions(id, isV2, noPopupTrading);
          },
        });
      }
    };
    const acceptOrderAction = (id) => {
      if (noPopupTrading) this.props.acceptOrderActions(id, noPopupTrading, isV2);
      else {
        this.props.alertActions({
          type: 'confirm',
          then: (result) => {
            if (result.value) this.props.acceptOrderActions(id, noPopupTrading, isV2);
          },
        });
      }
    };
    const refuseOrderAction = (id) => {
      if (noPopupTrading) this.props.refuseOrderActions(id, noPopupTrading, isV2);
      else {
        this.props.alertActions({
          type: 'confirm',
          then: (result) => {
            if (result.value) this.props.refuseOrderActions(id, noPopupTrading, isV2);
          },
        });
      }
    };
    const refreshOrderAction = (id) => {
      if (noPopupTrading) this.props.checkOrderActions(id, noPopupTrading, isV2);
      else {
        this.props.alertActions({
          type: 'confirm',
          then: (result) => {
            if (result.value) this.props.checkOrderActions(id, noPopupTrading, isV2);
          },
        });
      }
    };
    return (
      <div>
        {isWait && (
          <>
            <GetMenuItem
              icon={<Done htmlColor={successColor} />}
              text={<Translate id="orders.accept" />}
              onClick={() => acceptOrderAction(order.OrderId)}
            />
            <GetMenuItem
              icon={<Close htmlColor={dangerColor} />}
              text={<Translate id="orders.refuse" />}
              onClick={() => refuseOrderAction(order.OrderId)}
            />
          </>
        )}
        {canDelete && (
          <GetMenuItem
            icon={<DeleteForever htmlColor={dangerColor} />}
            text={
              isTemplate ? (
                <Translate id="orders.cancelTemplate" />
              ) : order.State === 1 ? (
                <Translate id="orders.cancelOrderEntry" />
              ) : (
                <Translate id="orders.cancelOrder" />
              )
            }
            onClick={() => removeOrderAction(order.OrderId)}
          />
        )}
        {canUpdate && (
          <GetMenuItem
            icon={<Create htmlColor={warningColor} />}
            text={<Translate id="orders.updateOrder" />}
            to={'/' + ('OrderV2Id' in order ? '' : 'V1-') + 'smartTrade/' + order.OrderId}
          />
        )}
        {canCheck && (
          <GetMenuItem
            icon={<Autorenew />}
            text={<Translate id="orders.refreshOrder" />}
            onClick={() => refreshOrderAction(order.OrderId)}
          />
        )}
        {canPanicSell && (
          <GetMenuItem
            icon={<Eject htmlColor={roseColor} />}
            text={
              <Translate
                id={isBuy ? 'orders.panicSellOrder' : 'orders.panicBuyOrder'}
              />
            }
            dialog={<DialogPanicSell order={order} />}
          />
        )}
        {isAdmin && (
          <GetMenuItem
            icon={<Info htmlColor={infoColor} />}
            text="infos"
            to={adminUrl + '/user/order/' + order.OrderId}
          />
        )}
        {isTemplate && (
          <GetMenuItem
            icon={
              !order.IsDisabled ? (
                <PowerOff htmlColor={dangerColor} />
              ) : (
                <Power htmlColor={successColor} />
              )
            }
            text={
              !order.IsDisabled ? (
                <Translate id="orders.disableTemplate" />
              ) : (
                <Translate id="orders.enableTemplate" />
              )
            }
            onClick={() => {
              this.props.disableOrderActions(
                order.OrderId,
                !order.IsDisabled,
                noPopupTrading,
              );
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { localize, config } = state;
  return {
    translate: getTranslate(localize),
    noPopupTrading: config.noPopupTrading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertActions: bindActionCreators(alertPopup, dispatch),
    acceptOrderActions: bindActionCreators(acceptOrder, dispatch),
    refuseOrderActions: bindActionCreators(refuseOrder, dispatch),
    checkOrderActions: bindActionCreators(checkOrder, dispatch),
    deleteOrderActions: bindActionCreators(deleteOrder, dispatch),
    disableOrderActions: bindActionCreators(toggleDisabledUser, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderMenuButtons);
