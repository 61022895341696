import React from 'react';
import { bindActionCreators } from 'redux';
import { change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import Button from '../../components/CustomButtons/Button.jsx';
import Danger from '../../components/Typography/Danger';
import withWidth from '@material-ui/core/withWidth/withWidth';
import { nbColumn } from '../../helpers/general';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem';

const styles = {
  buttonLeft: {
    width: '25%',
    marginLeft: '0px',
    marginRight: '0px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  },
  buttonCenter: {
    width: '25%',
    marginLeft: '0px',
    marginRight: '0px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  },
  buttonRight: {
    width: '25%',
    marginLeft: '0px',
    marginRight: '0px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
  sizeXs: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  sizeSm: {
    paddingTop: '11px',
    paddingBottom: '11px',
  },
  sizeMd: {
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  sizeLg: {
    paddingTop: '13px',
    paddingBottom: '13px',
  },
};

class ButtonsMonthYear extends React.PureComponent {
  toggleMonthYear = (value) => {
    this.props.change(this.props.formName, 'nbMonth', value);
  };

  handleChange = (event) => {
    this.toggleMonthYear(event.target.value);
  };

  render() {
    const { nbMonth } = this.props;
    const isMobile = nbColumn(this.props.width) < 2;

    return (
      <GridContainer>
        {!isMobile && (
          <GridItem>
            <Button
              style={styles.buttonLeft}
              color={nbMonth === 1 ? 'primary' : 'default'}
              onClick={() => this.toggleMonthYear(1)}
            >
              <b>
                <Translate id="traders.month" />
                <Danger>
                  <b>&nbsp;</b>
                </Danger>
              </b>
            </Button>
            <Button
              style={styles.buttonCenter}
              color={nbMonth === 3 ? 'primary' : 'default'}
              onClick={() => this.toggleMonthYear(3)}
            >
              <b>
                <Translate id="traders.quarterly" />
                <Danger>
                  <b> -13%</b>
                </Danger>
              </b>
            </Button>
            <Button
              style={styles.buttonCenter}
              color={nbMonth === 6 ? 'primary' : 'default'}
              onClick={() => this.toggleMonthYear(6)}
            >
              <b>
                <Translate id="traders.half-yearly" />
                <Danger>
                  <b> -20%</b>
                </Danger>
              </b>
            </Button>
            <Button
              style={styles.buttonRight}
              color={nbMonth === 12 ? 'primary' : 'default'}
              onClick={() => this.toggleMonthYear(12)}
            >
              <b>
                <Translate id="traders.year" />
                <Danger>
                  <b> -33%</b>
                </Danger>
              </b>
            </Button>
          </GridItem>
        )}
        {isMobile && (
          <GridItem>
            <Select value={nbMonth} onChange={this.handleChange}>
              <MenuItem value={1}>
                <Translate id="traders.month" />
              </MenuItem>
              <MenuItem value={3}>
                <Translate id="traders.quarterly" />
                &nbsp;
                <Danger>
                  <b> -10%</b>
                </Danger>
              </MenuItem>
              <MenuItem value={6}>
                <Translate id="traders.half-yearly" />
                &nbsp;
                <Danger>
                  <b> -15%</b>
                </Danger>
              </MenuItem>
              <MenuItem value={12}>
                <Translate id="traders.year" />
                &nbsp;
                <Danger>
                  <b> -20%</b>
                </Danger>
              </MenuItem>
            </Select>
          </GridItem>
        )}
      </GridContainer>
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  return {
    nbMonth: formSelector(state, 'nbMonth'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default withWidth()(
  connect(mapStateToProps, mapDispatchToProps)(ButtonsMonthYear),
);
