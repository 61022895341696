import { Divider } from '@material-ui/core';
import { Notifications } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import { querySelector } from '../../helpers/selector.js';
import { getUserNotifications } from '../../redux/config/actions.js';
import {
  notificationErrorSelector,
  notificationSelector,
} from '../../redux/config/selector.js';
import NotificationsForm from './NotificationsForm.jsx';

const NotificationsContainer = (props) => {
  const notificationError = useSelector(notificationErrorSelector);
  const notificationSettings = useSelector(notificationSelector);
  const dispatch = useDispatch();
  const query = useSelector(querySelector);
  useEffect(() => {
    dispatch(getUserNotifications(query?.email, query?.token));
  }, []);

  if (notificationSettings === null)
    return (
      <div>
        {!notificationError && <Translate id="central.loading" />}
        {notificationError && <Translate id="central.errorLoading" />}
      </div>
    );
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
          <Notifications style={{ marginTop: '-3px', marginRight: '5px' }} />
          <span>
            &nbsp;
            <Translate id="settings.notificationSettings" />
          </span>
        </GridItem>
        <GridItem xs={4} sm={6} md={6} />
        <GridItem xs={4} sm={3} md={3}>
          <span>&nbsp;Email</span>
        </GridItem>
        <GridItem xs={4} sm={3} md={3}>
          <span>Telegram</span>
        </GridItem>
      </GridContainer>
      <Divider style={{ marginTop: '4px', marginBottom: '4px' }} />
      <NotificationsForm notificationSettings={notificationSettings} />
    </>
  );
};

export default NotificationsContainer;
