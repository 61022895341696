import React from 'react';
import { bindActionCreators } from 'redux';
import { change, formValueSelector } from 'redux-form';
import { smartTradeSelector, isBuySelector } from '../../../helpers/Common';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import Button from '../../../components/CustomButtons/Button.jsx';

const styles = {
  buttonLeft: {
    width: '49%',
    marginLeft: '0px',
    marginRight: '0px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  },
  buttonRight: {
    width: '49%',
    marginLeft: '0px',
    marginRight: '0px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
};

class ButtonsOneEach extends React.PureComponent {
  toggleOneEach = (value) => {
    const { formName, name, timeOutStopLossCB } = this.props;
    if (value) this.props.change(formName, 'typeStart', 'Market');
    else this.props.change(formName, 'TVType', 0);

    this.props.change(formName, name, value);
    if (timeOutStopLossCB === true) {
      this.props.change(formName, 'timeOutStopLossCB', false);
    }
  };

  render() {
    const { isTemplate, disabled } = this.props;
    return (
      <div>
        <Button
          style={styles.buttonLeft}
          color={!isTemplate ? 'primary' : 'default'}
          size={'sm'}
          disabled={disabled}
          onClick={() => this.toggleOneEach(false)}
        >
          <b>
            <Translate id="smartTrade.template.one" />
          </b>
        </Button>
        <Button
          style={styles.buttonRight}
          color={isTemplate ? 'primary' : 'default'}
          size={'sm'}
          disabled={disabled}
          onClick={() => this.toggleOneEach(true)}
        >
          <b>
            <Translate id="smartTrade.template.each" />
          </b>
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  return {
    isTemplate: formSelector(state, 'isTemplate'),
    timeOutStopLossCB: formSelector(state, 'timeOutStopLossCB'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsOneEach);
