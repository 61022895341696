import React from 'react';
import { getDateToFormat } from '../../helpers/localize';
import moment from 'moment-timezone/builds/moment-timezone-with-data';

export default class CurrentServerDateTime extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: moment().tz('Europe/Paris'),
    };
  }
  componentDidMount() {
    setInterval(() => {
      this.setState({ currentDate: moment().tz('Europe/Paris') });
    }, 60 * 30 * 10); //30sec
  }
  render() {
    const { currentDate } = this.state;
    const { codeLang } = this.props;

    const format = getDateToFormat(currentDate, codeLang);
    return format;
  }
}
