import React from 'react';

export default class PolitiqueConfidentialitePage extends React.Component {
  render() {
    return (
      <div style={{ textAlign: 'justify' }}>
        <h1>
          <b>Mentions légales et politique de confidentialité</b>
        </h1>
        La société Wall Of Traders, soucieuse des droits des individus,
        notamment au regard des traitements automatisés et dans une volonté de
        transparence avec ses clients, a mis en place une politique reprenant
        l’ensemble de ces traitements, des finalités poursuivies par ces
        derniers ainsi que des moyens d’actions à la disposition des individus
        afin qu’ils puissent au mieux exercer leurs droits. Pour toute
        information complémentaire sur la protection des données personnelles,
        nous vous invitons à consulter le site : https://www.cnil.fr/
        <br />
        La poursuite de la navigation sur ce site vaut acceptation sans réserve
        des dispositions et conditions d&apos;utilisation qui suivent.
        <br />
        <h3>
          <b>Article 1 - Mentions légales</b>
        </h3>
        <h4>
          <u>1.1 Site (ci-après « le site ») :</u>
        </h4>
        Wall Of Traders
        <h4>
          <u>1.2 Éditeur (ci-après « l&apos;éditeur ») :</u>
        </h4>
        Wall Of Traders SAS au capital de 1000 € dont le siège social est situé
        au 15 rue des Halles 75001 Paris et immatriculée au registre du commerce
        et des sociétés de PARIS sous le numéro SIREN 907601579. adresse mail :
        contact@walloftraders.com
        <h4>
          <u>1.3 Hébergeur (ci-après « l&apos;hébergeur ») :</u>
        </h4>
        Wall Of Traders est hébergé par So you Start OVH, dont le siège social
        est situé 2 rue Kellermann 59100 Roubaix.
        <br />
        <h3>
          <b>Article 2 - Accès au site</b>
        </h3>
        L&apos;accès au site et son utilisation sont réservés à un usage
        strictement personnel. Vous vous engagez à ne pas utiliser ce site et
        les informations ou données qui y figurent à des fins commerciales,
        politiques, publicitaires et pour toute forme de sollicitation
        commerciale et notamment l&apos;envoi de courriers électroniques non
        sollicités.
        <br />
        <h3>
          <b>Article 3 - Contenu du site</b>
        </h3>
        Toutes les marques, photographies, textes, commentaires, illustrations,
        images animées ou non, séquences vidéo, sons, ainsi que toutes les
        applications informatiques qui pourraient être utilisées pour faire
        fonctionner ce site et plus généralement tous les éléments reproduits ou
        utilisés sur le site sont protégés par les lois en vigueur au titre de
        la propriété intellectuelle. Ils sont la propriété pleine et entière de
        l&apos;éditeur ou de ses partenaires. Toute reproduction,
        représentation, utilisation ou adaptation, sous quelque forme que ce
        soit, de tout ou partie de ces éléments, y compris les applications
        informatiques, sans l&apos;accord préalable et écrit de l&apos;éditeur,
        sont strictement interdites. Le fait pour l&apos;éditeur de ne pas
        engager de procédure dès la prise de connaissance de ces utilisations
        non autorisées ne vaut pas acceptation desdites utilisations et
        renonciation aux poursuites.
        <br />
        <h3>
          <b>Article 4 - Gestion du site</b>
        </h3>
        Pour la bonne gestion du site, l&apos;éditeur pourra à tout moment :
        <ul>
          <li>
            suspendre, interrompre ou limiter l&apos;accès à tout ou partie du
            site, réserver l&apos;accès au site, ou à certaines parties du site,
            à une catégorie déterminée d&apos;internautes ;
          </li>
          <li>
            supprimer toute information pouvant en perturber le fonctionnement
            ou entrant en contravention avec les lois nationales ou
            internationales ;
          </li>
          <li>suspendre le site afin de procéder à des mises à jour.</li>
        </ul>
        <h3>
          <b>Article 5 - Responsabilités</b>
        </h3>
        La responsabilité de l&apos;éditeur ne peut être engagée en cas de
        défaillance, panne, difficulté ou interruption de fonctionnement,
        empêchant l&apos;accès au site ou à une de ses fonctionnalités. Le
        matériel de connexion au site que vous utilisez est sous votre entière
        responsabilité. Vous devez prendre toutes les mesures appropriées pour
        protéger votre matériel et vos propres données notamment d&apos;attaques
        virales par Internet. Vous êtes par ailleurs seul responsable des sites
        et données que vous consultez.
        <br />
        L&apos;éditeur ne pourra être tenu responsable en cas de poursuites
        judiciaires à votre encontre :
        <ul>
          <li>
            du fait de l&apos;usage du site ou de tout service accessible via
            Internet ;
          </li>
          <li>
            du fait du non-respect par vous des présentes conditions générales.
          </li>
        </ul>
        L&apos;éditeur n&apos;est pas responsable des dommages causés à
        vous-même, à des tiers et/ou à votre équipement du fait de votre
        connexion ou de votre utilisation du site et vous renoncez à toute
        action contre lui de ce fait. Si l&apos;éditeur venait à faire
        l&apos;objet d&apos;une procédure amiable ou judiciaire en raison de
        votre utilisation du site, il pourra se retourner contre vous pour
        obtenir l&apos;indemnisation de tous les préjudices, sommes,
        condamnations et frais qui pourraient découler de cette procédure.
        <br />
        <h3>
          <b>Article 6 - Liens hypertextes</b>
        </h3>
        La mise en place par les utilisateurs de tous liens hypertextes vers
        tout ou partie du site est autorisée par l&apos;éditeur. Tout lien devra
        être retiré sur simple demande de l&apos;éditeur. Toute information
        accessible via un lien vers d&apos;autres sites n&apos;est pas publiée
        par l&apos;éditeur. L&apos;éditeur ne dispose d&apos;aucun droit sur le
        contenu présent dans ledit lien.
        <br />
        <h3>
          <b>Article 7 - Collecte et protection des données</b>
        </h3>
        Vos données sont collectées par La société Wall Of Traders. Une donnée à
        caractère personnel désigne toute information concernant une personne
        physique identifiée ou identifiable (personne concernée) ; est réputée
        identifiable une personne qui peut être identifiée, directement ou
        indirectement, notamment par référence à un nom, un numéro
        d&apos;identification ou à un ou plusieurs éléments spécifiques, propres
        à son identité physique, physiologique, génétique, psychique,
        économique, culturelle ou sociale. Les informations personnelles pouvant
        être recueillies sur le site sont principalement utilisées par
        l&apos;éditeur pour la gestion des relations avec vous, et le cas
        échéant pour le traitement de vos commandes.
        <br />
        Les données personnelles collectées sont les suivantes :
        <ul>
          <li>Nom et prénom</li>
          <li>Adresse email</li>
        </ul>
        <h3>
          <b>
            Article 8 - Droit d’accès, de rectification et de déréférencement de
            vos données
          </b>
        </h3>
        En application de la réglementation applicable aux données à caractère
        personnel, les utilisateurs disposent des droits suivants :
        <ul>
          <li>
            le droit d’accès : ils peuvent exercer leur droit d&apos;accès, pour
            connaître les données personnelles les concernant, en écrivant à
            l&apos;adresse électronique ci-dessous mentionnée. Dans ce cas,
            avant la mise en œuvre de ce droit, la Plateforme peut demander une
            preuve de l&apos;identité de l&apos;utilisateur afin d&apos;en
            vérifier l&apos;exactitude ;
          </li>
          <li>
            le droit de rectification : si les données à caractère personnel
            détenues par la Plateforme sont inexactes, ils peuvent demander la
            mise à jour des informations ;
          </li>
          <li>
            le droit de suppression des données : les utilisateurs peuvent
            demander la suppression de leurs données à caractère personnel,
            conformément aux lois applicables en matière de protection des
            données ;
          </li>
          <li>
            le droit à la limitation du traitement : les utilisateurs peuvent de
            demander à la Plateforme de limiter le traitement des données
            personnelles conformément aux hypothèses prévues par le RGPD ;
          </li>
          <li>
            le droit de s’opposer au traitement des données : les utilisateurs
            peuvent s’opposer à ce que leurs données soient traitées
            conformément aux hypothèses prévues par le RGPD ;
          </li>
          <li>
            le droit à la portabilité : ils peuvent réclamer que la Plateforme
            leur remette les données personnelles qu&apos;ils ont fournies pour
            les transmettre à une nouvelle Plateforme.
          </li>
        </ul>
        Vous pouvez exercer ce droit en nous contactant, en envoyant un email à
        : contact@walloftraders.com.
        <br />
        Toute demande doit être accompagnée de la photocopie d’un titre
        d’identité en cours de validité signé et faire mention de l’adresse à
        laquelle l&apos;éditeur pourra contacter le demandeur. La réponse sera
        adressée dans le mois suivant la réception de la demande. Ce délai
        d&apos;un mois peut être prolongé de deux mois si la complexité de la
        demande et/ou le nombre de demandes l&apos;exigent.
        <br />
        De plus, et depuis la loi n°2016-1321 du 7 octobre 2016, les personnes
        qui le souhaitent, ont la possibilité d’organiser le sort de leurs
        données après leur décès. Pour plus d’information sur le sujet, vous
        pouvez consulter le site Internet de la CNIL : https://www.cnil.fr/.
        <br />
        Les utilisateurs peuvent aussi introduire une réclamation auprès de la
        CNIL sur le site de la CNIL : https://www.cnil.fr.
        <br />
        Nous vous recommandons de nous contacter dans un premier temps avant de
        déposer une réclamation auprès de la CNIL, car nous sommes à votre
        entière disposition pour régler votre problème.
        <br />
        <h3>
          <b>Article 9 - Utilisation des données</b>
        </h3>
        Les données personnelles collectées auprès des utilisateurs ont pour
        objectif la mise à disposition des services de la Plateforme, leur
        amélioration et le maintien d&apos;un environnement sécurisé. La base
        légale des traitements est l’exécution du contrat entre l’utilisateur et
        la Plateforme. Plus précisément, les utilisations sont les suivantes :
        <br />
        <ul>
          <li>
            accès et utilisation de la Plateforme par l&apos;utilisateur ;
          </li>
          <li>gestion du fonctionnement et optimisation de la Plateforme ;</li>
          <li>mise en œuvre d&apos;une assistance utilisateurs ;</li>
          <li>
            vérification, identification et authentification des données
            transmises par l&apos;utilisateur ;
          </li>
          <li>
            personnalisation des services en affichant des publicités en
            fonction de l&apos;historique de navigation de l&apos;utilisateur,
            selon ses préférences ;
          </li>
          <li>
            prévention et détection des fraudes, malwares (malicious softwares
            ou logiciels malveillants) et gestion des incidents de sécurité ;
          </li>
          <li>gestion des éventuels litiges avec les utilisateurs ;</li>
          <li>
            envoi d&apos;informations commerciales et publicitaires, en fonction
            des préférences de l&apos;utilisateur ;
          </li>
          <li>
            organisation des conditions d&apos;utilisation des Services de
            paiement.
          </li>
        </ul>
        <h3>
          <b>Article 10 - Politique de conservation des données</b>
        </h3>
        La Plateforme conserve vos données pour la durée nécessaire pour vous
        fournir ses services ou son assistance. Dans la mesure raisonnablement
        nécessaire ou requise pour satisfaire aux obligations légales ou
        réglementaires, régler des litiges, empêcher les fraudes et abus ou
        appliquer nos modalités et conditions, nous pouvons également conserver
        certaines de vos informations si nécessaire, même après que vous ayez
        fermé votre compte ou que nous n&apos;ayons plus besoin pour vous
        fournir nos services.
        <br />
        <h3>
          <b>Article 11- Partage des données personnelles avec des tiers</b>
        </h3>
        Les données personnelles peuvent être partagées avec des sociétés
        tierces exclusivement dans l’Union européenne, dans les cas suivants :
        <ul>
          <li>
            quand l&apos;utilisateur utilise les services de paiement, pour la
            mise en œuvre de ces services, la Plateforme est en relation avec
            des sociétés bancaires et financières tierces avec lesquelles elle a
            passé des contrats ;
          </li>
          <li>
            lorsque l&apos;utilisateur publie, dans les zones de commentaires
            libres de la Plateforme, des informations accessibles au public ;
          </li>
          <li>
            quand l&apos;utilisateur autorise le site web d&apos;un tiers à
            accéder à ses données ;
          </li>
          <li>
            quand la Plateforme recourt aux services de prestataires pour
            fournir l&apos;assistance utilisateurs, la publicité et les services
            de paiement. Ces prestataires disposent d&apos;un accès limité aux
            données de l&apos;utilisateur, dans le cadre de l&apos;exécution de
            ces prestations, et ont l&apos;obligation contractuelle de les
            utiliser en conformité avec les dispositions de la réglementation
            applicable en matière protection des données à caractère personnel ;
          </li>
          <li>
            si la loi l&apos;exige, la Plateforme peut effectuer la transmission
            de données pour donner suite aux réclamations présentées contre la
            Plateforme et se conformer aux procédures administratives et
            judiciaires.
          </li>
          <li>
            La politique de confidentialité de Trading View n&apos;est pas lié à
            cette politique de confidentialité.
          </li>
        </ul>
        <h3>
          <b>Article 12 - Offres commerciales</b>
        </h3>
        Vous êtes susceptible de recevoir des offres commerciales de
        l&apos;éditeur. Si vous ne le souhaitez pas, veuillez envoyer un mail à
        : contact@walloftraders.com. Vos données sont susceptibles d’être
        utilisées par les partenaires de l&apos;éditeur à des fins de
        prospection commerciale, si vous ne le souhaitez pas, veuillez envoyer
        un mail à : contact@walloftraders.com. Si, lors de la consultation du
        site, vous accédez à des données à caractère personnel, vous devez vous
        abstenir de toute collecte, de toute utilisation non autorisée et de
        tout acte pouvant constituer une atteinte à la vie privée ou à la
        réputation des personnes. L&apos;éditeur décline toute responsabilité à
        cet égard. Les données sont conservées et utilisées pour une durée
        conforme à la législation en vigueur.
        <br />
        <h3>
          <b>Article 13 - Cookies</b>
        </h3>
        Qu’est-ce qu’un « cookie » ? Un « Cookie » ou traceur est un fichier
        électronique déposé sur un terminal (ordinateur, tablette, smartphone,…)
        et lu par exemple lors de la consultation d&apos;un site internet, de la
        lecture d&apos;un courrier électronique, de l&apos;installation ou de
        l&apos;utilisation d&apos;un logiciel ou d&apos;une application mobile
        et ce, quel que soit le type de terminal utilisé (source :
        https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi). En naviguant
        sur ce site, des « cookies » émanant de la société responsable du site
        concerné et/ou des sociétés tierces pourront être déposés sur votre
        terminal. Lors de la première navigation sur ce site, une bannière
        explicative sur l’utilisation des « cookies » apparaîtra. Dès lors, en
        poursuivant la navigation, le client et/ou prospect sera réputé informé
        et avoir accepté l’utilisation desdits « cookies ». Le consentement
        donné sera valable pour une période de treize (13) mois.
        L&apos;utilisateur a la possibilité de désactiver les cookies à partir
        des paramètres de son navigateur.
        <br />
        Toutes les informations collectées ne seront utilisées que pour suivre
        le volume, le type et la configuration du trafic utilisant ce site, pour
        en développer la conception et l&apos;agencement et à d&apos;autres fins
        administratives et de planification et plus généralement pour améliorer
        le service que nous vous offrons.
        <br />
        Les cookies suivants sont présents sur ce site :<br />
        <br />
        <b>Cookies Google :</b>
        <ul>
          <li>
            Google analytics : permet de mesurer l&apos;audience du site ;
          </li>
          <li>
            Google tag manager : facilite l’implémentation des tags sur les
            pages et permet de gérer les balises Google ;
          </li>
          <li>
            Google Adsense : régie publicitaire de Google utilisant les sites
            web ou les vidéos YouTube comme support pour ses annonces ;
          </li>
          <li>
            Google Dynamic Remarketing : permet de vous proposer de la publicité
            dynamique en fonction des précédentes recherches ;
          </li>
          <li>
            Google Adwords Conversion : outil de suivi des campagnes
            publicitaires adwords ;
          </li>
          <li>
            DoubleClick : cookies publicitaires de Google pour diffuser des
            bannières.
          </li>
        </ul>
        <b>Cookies Facebook :</b>
        <ul>
          <li>
            Facebook connect : permet de s&apos;identifier à l&apos;aide de son
            compte Facebook ;
          </li>
          <li>
            Facebook social plugins : permet de liker, partager, commenter du
            contenu avec un compte Facebook ;
          </li>
          <li>
            Facebook Custom Audience : permet d&apos;intérargir avec
            l&apos;audience sur Facebook.
          </li>
        </ul>
        <b>Cookies Twitter :</b>
        <ul>
          <li>
            Twitter button : permet de partager facilement et afficher le
            contenu de Twitter ;
          </li>
          <li>
            Twitter advertising : permet d&apos;afficher et de cibler des
            publicités par la régie publicitaire de Twitter.
          </li>
        </ul>
        La durée de vie de ces cookies est de treize mois.
        <br />
        Pour plus d’informations sur l’utilisation, la gestion et la suppression
        des « cookies », pour tout type de navigateur, nous vous invitons à
        consulter le lien suivant :
        https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser.
        <br />
        <h3>
          <b>Article 14 - Photographies et représentation des produits</b>
        </h3>
        Les photographies de produits, accompagnant leur description, ne sont
        pas contractuelles et n&apos;engagent pas l&apos;éditeur.
        <br />
        <h3>
          <b>Article 15 - Loi applicable</b>
        </h3>
        Les présentes conditions d&apos;utilisation du site sont régies par la
        loi française et soumises à la compétence des tribunaux du siège social
        de l&apos;éditeur, sous réserve d&apos;une attribution de compétence
        spécifique découlant d&apos;un texte de loi ou réglementaire
        particulier.
        <br />
        <h3>
          <b>Article 16 - Langue</b>
        </h3>
        En cas de traduction de cette présente politique de confidentialité en
        d’autres langues, la langue d’interprétation sera la langue française.
        <br />
        <h3>
          <b>Article 17 - Contactez-nous</b>
        </h3>
        Pour toute question, information sur les produits présentés sur le site,
        ou concernant le site lui-même, vous pouvez envoyer un mail à :
        contact@walloftraders.com.
        <br />
      </div>
    );
  }
}
