import React from 'react';
import { connect } from 'react-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Input from '@material-ui/core/Input';
import Button from '../../../components/CustomButtons/Button';
import { isEmpty } from '../../../helpers/Common.js';
import { bindActionCreators } from 'redux';
import {
  cancelAllIds,
  IsAdmin,
  panicSellAllIds,
} from '../../../redux/admin/actions.js';

class PanicSellAllPage extends React.Component {
  state = {
    // panic sell value
    psValue: '',
    // cancel value
    cValue: '',
  };
  componentDidMount() {
    this.props.IsAdminActions();
  }
  sendPanicSellAll = (list) => {
    if (isEmpty(list)) return;
    this.props.panicSellAllActions(list);
  };
  sendCancelAll = (list) => {
    if (isEmpty(list)) return;
    this.props.cancelAllActions(list);
  };
  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Input
            value={this.state.psValue}
            multiline={true}
            fullWidth={true}
            minRows={10}
            onChange={(e) => this.setState({ psValue: e.target.value })}
          />
          <Button onClick={() => this.sendPanicSellAll(this.state.psValue)}>
            Send Panic Sell All
          </Button>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Input
            value={this.state.cValue}
            multiline={true}
            fullWidth={true}
            minRows={10}
            onChange={(e) => this.setState({ cValue: e.target.value })}
          />
          <Button onClick={() => this.sendCancelAll(this.state.cValue)}>
            Send Cancel All
          </Button>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    panicSellAllActions: bindActionCreators(panicSellAllIds, dispatch),
    cancelAllActions: bindActionCreators(cancelAllIds, dispatch),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PanicSellAllPage);
