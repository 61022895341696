import React from 'react';
import { changeStep } from '../../redux/onboarding/actions';
import { postExchangeOnboarding } from '../../redux/user/actions';
import { exchangesFormSelector } from '../../redux/user/selector';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../components/Card/Card';
import FormExchange from '../Accounts/Exchanges/FormExchange';
import { profilConstant } from './profil.constant';
import { formatDataForPost } from '../../helpers/exchange';

const AddExchangeContainer = () => {
  const dispatch = useDispatch();
  const fromValues = useSelector(exchangesFormSelector);

  return (
    <Card>
      <FormExchange
        handleSubmit={() => {
          const exchangeUser = formatDataForPost(fromValues);
          dispatch(postExchangeOnboarding(exchangeUser));
          dispatch(changeStep(profilConstant.STEP_POPUP_EXCHANGE));
        }}
      />
    </Card>
  );
};

export default AddExchangeContainer;
