import React from 'react';

import GridItem from '../../components/Grid/GridItem.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import Button from '../../components/CustomButtons/Button';
import { bindActionCreators } from 'redux';
import { forgotPassword } from '../../redux/authentication/actions';
import connect from 'react-redux/es/connect/connect';
import CustomInput from '../../components/CustomInput/CustomInput';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import { withLocalize } from 'react-localize-redux';
import { Translate } from 'react-localize-redux';
import transEmail from '../../locales/email.json';
import transConfirmation from '../../locales/confirmation';

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transEmail);
    this.props.addTranslation(transConfirmation);
    this.state = {
      email: '',
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.sendEmail();
    }
  };

  sendEmail = () => {
    this.props.forgotPassword({ Email: this.state.email });
  };
  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="primary">
              <Translate id="email.enterEmail" />
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText={<Translate id="email.emailAddress" />}
                id="email"
                inputProps={{
                  value: this.state.email,
                  type: 'email',
                  onChange: this.handleChange,
                  onKeyPress: this.handleKeyPress,
                }}
              />
              <GridContainer justifyContent="center">
                <Button onClick={this.sendEmail} color="primary" round>
                  <Translate id="email.sendEmail" />
                </Button>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: bindActionCreators(forgotPassword, dispatch),
  };
};

export default withLocalize(
  connect(null, mapDispatchToProps)(ForgotPasswordPage),
);
