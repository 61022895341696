import React from 'react';

import GridItem from '../../components/Grid/GridItem.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import CustomTabs from '../../components/CustomTabs/CustomTabs.jsx';

import LoginContainer from './LoginContainer.jsx';
import RegisterContainer from './RegisterContainer.jsx';

import { withLocalize } from 'react-localize-redux';
import { Translate } from 'react-localize-redux';
import transEmail from '../../locales/email.json';
import transConfirmation from '../../locales/confirmation.json';

class IdentificationPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transEmail);
    this.props.addTranslation(transConfirmation);
  }
  render() {
    const { location } = this.props;
    const value =
      location.search && location.search.indexOf('login') !== -1 ? 1 : 0;
    return (
      <GridContainer>
        <GridItem md={3} />
        <GridItem xs={12} sm={12} md={12} lg={6}>
          <CustomTabs
            value={value}
            title=""
            headerColor="primary"
            tabs={[
              {
                tabName: <Translate id="email.register" />,
                // tabIcon: Code,
                tabContent: <RegisterContainer />,
              },
              {
                tabName: <Translate id="email.login" />,
                // tabIcon: BugReport,
                tabContent: <LoginContainer />,
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default withLocalize(IdentificationPage);
