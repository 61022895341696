import React from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import { userIdSelector } from '../../redux/authentication/selector';
import { tradersSelector } from '../../redux/trader/selector';
import { isNull } from '../../helpers/Common';
import transTrader from '../../locales/traders.json';
import transExchanges from '../../locales/exchanges.json';
import transError from '../../locales/error.json';
import transConfirmation from '../../locales/confirmation.json';

import FormAddFollower from './FormAddFollower';

const styles = {
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

class AddFollowerPage extends React.Component {
  constructor(props) {
    super(props);

    this.props.addTranslation(transTrader);
    this.props.addTranslation(transExchanges);
    this.props.addTranslation(transError);
    this.props.addTranslation(transConfirmation);
  }

  render() {
    const { traders, match, userId } = this.props;

    const trader = !isNull(traders)
      ? traders.find((x) => x.GroupId === parseInt(match.params.id))
      : null;

    if (!trader || userId !== trader.ApplicationUserId) return <div />;

    return <FormAddFollower trader={trader} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    traders: tradersSelector(state),
    userId: userIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const connected = connect(mapStateToProps, mapDispatchToProps)(AddFollowerPage);
const withStyle = withStyles(styles)(connected);

export default withLocalize(withStyle);
