import { createSelector } from 'reselect';

export const availableAmountsSelector = (state) => {
  // const state = isFunction(state) ? getState() : getState;
  if (!state || !state.order) return [];
  let { availableAmounts } = state.order;
  if (!availableAmounts || availableAmounts.length === 0)
    availableAmounts = JSON.parse(localStorage.getItem('availableAmounts'));

  if (!availableAmounts || availableAmounts.length === 0) return [];
  return availableAmounts;
};

const orderStateSelector = (state) => state.order;

const ordersSelector = createSelector(
  orderStateSelector,
  (order) => order.orders,
);

/**
 * Retrieve an order with the given orderId
 * @param {String|Int} orderId - The order id to retrieve in the store
 * @returns {Order} The order with the given id, undefnied if not found
 * TODO: test it
 */
export const orderByIdSelector = (orderId) =>
  createSelector(ordersSelector, (orders) => {
    const id = parseInt(orderId);
    return orders.find((order) => order.OrderId === id);
  });
