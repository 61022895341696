import React from 'react';
import { bindActionCreators } from 'redux';
import { change, formValueSelector, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import Button from '../../components/CustomButtons/Button.jsx';
import CardBody from '../../components/Card/CardBody';
import Card from '../../components/Card/Card';
import Info from '../../components/Typography/Info';
import Warning from '../../components/Typography/Warning';
import WarningIcon from '@material-ui/icons/Warning';
import { getPriceAbo, getPriceStr } from '../../helpers/general';
import { postPaiement } from '../../redux/trader/actions';
import Coinbase from '../../components/Payment/Coinbase';
import {
  groupsSelector,
  paiementSelector,
  tradersSelector,
} from '../../redux/trader/selector';
import { isNull } from '../../helpers/Common.js';

class OrderOverview extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      prevValues: null,
    };
  }
  createCharge = () => {
    const { values, groups } = this.props;
    const { prevValues } = this.state;

    if (
      prevValues === null ||
      prevValues.groupIds !== values.groupIds ||
      prevValues.nbMonth !== values.nbMonth
    ) {
      this.setState({ prevValues: values });
      const nbMonth = values.nbMonth;
      let Groups = [];
      for (const groupId of values.groupIds) {
        let tmpObj = isNull(groups[groupId])
          ? { groupId, nbMonth }
          : { groupId, nbMonth, percentage: groups[groupId] };
        Groups.push(tmpObj);
      }
      let post = { ...values };
      post.Groups = Groups;
      post.BaseUrl = location.protocol + '//' + location.host;
      this.props.postPaiementActions(post);
    }
    this.props.change(this.props.formName, 'showModal', true);
  };

  render() {
    const { nbMonth, groupIds, traders, formName } = this.props;

    let total = 0;
    if (groupIds && groupIds.length > 0) {
      for (const subscription of groupIds) {
        const trader = traders.find((x) => x.GroupId === subscription);
        if (trader && trader.Prices) {
          total += getPriceAbo(trader.Prices, nbMonth) * nbMonth;
        }
      }
      total = parseFloat(total.toFixed(2));
    }

    return (
      <Card>
        <CardBody>
          <h3>
            <Translate id="traders.orderOverview" />
          </h3>
          <ul>
            {groupIds &&
              groupIds.map((subscription) => {
                const trader = traders.find((x) => x.GroupId === subscription);
                if (!trader) return;
                return (
                  <li key={trader.GroupId}>
                    {trader.Name}{' '}
                    <Info>{getPriceStr(trader.Prices, nbMonth)}</Info>
                  </li>
                );
              })}
          </ul>
          {/*<Coinbase formName={formName} />*/}
          {/*<div>*/}
          {/*  <Warning severity="warning" color="warning">*/}
          {/*    <div className="icons-text">*/}
          {/*      <WarningIcon />*/}
          {/*    </div>*/}
          {/*    <Translate*/}
          {/*      id="traders.onlyEthereumChain"*/}
          {/*      options={{ renderInnerHtml: true }}*/}
          {/*    />*/}
          {/*  </Warning>*/}
          {/*</div>*/}
          <div>
            <Button color="primary" onClick={() => this.createCharge()}>
              <Translate id="traders.pay" /> {total}€
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  return {
    paiement: paiementSelector(state),
    traders: tradersSelector(state),
    values: getFormValues(props.formName)(state),
    groupIds: formSelector(state, 'groupIds'),
    nbMonth: formSelector(state, 'nbMonth'),
    isUpdate: formSelector(state, 'isUpdate'),
    groups: groupsSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    postPaiementActions: bindActionCreators(postPaiement, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderOverview);
