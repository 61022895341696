import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from './Button';
import { Link } from 'react-router-dom';
import { isEmpty, isNull } from '../../helpers/Common';

const styles = {
  littleButton: {
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
  },
  xs: {
    padding: '0.2em',
  },
};

class LittleButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      disabledClick: false,
    };
  }
  button = () => {
    const { classes, onClick, icon, color, to, size, disabled, style } =
      this.props;
    let { timeout } = this.props;
    if (isNull(timeout)) timeout = 1000;
    const { disabledClick } = this.state;
    return (
      <Button
        component={to ? Link : 'button'}
        to={to}
        className={size === 'xs' ? classes.xs : classes.littleButton}
        size={'sm'}
        disabled={disabled || disabledClick}
        onClick={() => {
          this.setState({ disabledClick: true });
          if (!isNull(onClick)) onClick();
          setTimeout(() => {
            this.setState({ disabledClick: false });
          }, timeout);
        }}
        color={color}
        style={style}
        timeout={timeout}
      >
        {icon}
      </Button>
    );
  };
  render() {
    const { tooltip } = this.props;
    if (isEmpty(tooltip)) return this.button();
    return (
      <Tooltip title={tooltip} enterDelay={200} leaveDelay={200}>
        {this.button()}
      </Tooltip>
    );
  }
}

LittleButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LittleButton);
