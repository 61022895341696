import React from 'react';
import { GetSymbolForCSS, ShowExchange, ShowPair } from './LogoContainer';
import { isNull } from '../../helpers/Common';

class OptionsContainer extends React.PureComponent {
  render() {
    if (!this.props) return '';
    if (!this.props.data) return this.props.children;
    if (this.props.data.Symbol) {
      const data = this.props.data;
      const label = isNull(data.Name)
        ? this.props.children
        : data.Name + ' (' + data.Symbol + ')';
      let symbol = GetSymbolForCSS(data.Symbol);
      return (
        <div>
          <div className={symbol} /> {label}
        </div>
      );
    } else if (this.props.data.quote) {
      const quote = this.props.data.quote;
      return (
        <div>
          <div className={quote} /> {this.props.children}
        </div>
      );
    } else if (this.props.data.pair) {
      return ShowPair(this.props.data.pair);
    } else if (this.props.data.exchange) {
      return ShowExchange(this.props.data);
    } else if (this.props.children) {
      return this.props.children;
    } else {
      return <div>{this.props.data.Name}</div>;
    }
  }
}

export default OptionsContainer;
