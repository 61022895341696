import React from 'react';
import { Translate } from 'react-localize-redux';
import { isEmpty, isNull } from '../../helpers/Common';
import { getExchangeExStr, getExchangeStr } from '../../helpers/order';

export const GetSymbolForCSS = (symbol) => {
  if (isNull(symbol)) return symbol;
  //remove $ en premier lettre
  if (symbol[0] === '$') symbol = symbol.substring(1);
  if ('0123456789'.indexOf(symbol[0]) !== -1)
    symbol = 'n' + symbol.toUpperCase();
  symbol = symbol.indexOf('BULL') !== 0 ? symbol.replace('BULL', '') : symbol;
  symbol = symbol.indexOf('BEAR') !== 0 ? symbol.replace('BEAR', '') : symbol;
  symbol = symbol.indexOf('HALF') !== 0 ? symbol.replace('HALF', '') : symbol;
  symbol = symbol.indexOf('HEDGE') !== 0 ? symbol.replace('HEDGE', '') : symbol;
  return symbol;
};

export const ShowPair = (pair) => {
  if (!pair) return '';
  const split = pair.split('-');
  if (split.length >= 1 && split[0] === 'XBT') split[0] = 'BTC';
  if (split.length >= 2 && split[1] === 'XBT') split[1] = 'BTC';
  let symbol = GetSymbolForCSS(split[0]);
  let quote = GetSymbolForCSS(split[1]);
  return (
    <div>
      {split.length >= 1 && <div className={symbol} />} {pair}{' '}
      {split.length >= 2 && <div className={quote} />}
      {/*<div style={{ position: 'absolute', top: 3, right: 72, zIndex:999 }} onClick={() => {alert('toto')}}>*/}
      {/*<StarBorder />*/}
      {/*</div>*/}
    </div>
  );
};

export const ShowExchangeEx = (exchangeName) => {
  let name = getExchangeExStr(exchangeName);
  name = name.replaceAll('_', ' ');
  let className = name.toLowerCase();
  if (className.indexOf('binance') !== -1) className = 'binance';
  if (className.indexOf('bybit') !== -1) className = 'bybit';
  if (className.indexOf('ftx') !== -1) className = 'ftx';
  return (
    <div>
      <div className={className} /> {name}
    </div>
  );
};

export const ShowExchange = (exchange) => {
  if (isNull(exchange)) return '';
  const exchangeName = !isNull(exchange.ExchangeName)
    ? Number.isInteger(exchange.ExchangeName)
      ? getExchangeStr(exchange)
      : exchange.ExchangeName
    : exchange.exchange;
  let name = isEmpty(exchange.Name)
    ? isEmpty(exchange.label)
      ? exchangeName
      : exchange.label
    : exchange.Name;
  name = name.replaceAll('_', ' ');
  let className = exchangeName.toLowerCase();
  if (className.indexOf('binance') !== -1) className = 'binance';
  if (className.indexOf('bybit') !== -1) className = 'bybit';
  if (className.indexOf('ftx') !== -1) className = 'ftx';
  const isBeta =
    exchangeName === 'LBank' ||
    exchangeName === 'Binance_US' ||
    exchangeName === 'Bitmex' ||
    exchangeName === 'FTX' ||
    exchangeName === 'FTX_US';
  return (
    <div>
      <div className={className} /> {name} {isBeta ? '(Beta)' : ''}
    </div>
  );
};

export const ShowWallet = (account) => {
  if (!account) return '';
  return (
    <div>
      <div className={'s-s-' + account.Currency} /> {account.Name + ' / '}
      {account.AccountType}
    </div>
  );
};

export const ShowAccount = (account) => {
  if (!account) return '';
  const isArray = Array.isArray(account);
  if (isArray) return <Translate id="central.allAccounts" />;
  if (account.AccountType === 'Exchange') {
    return ShowExchange(account);
  } else if (account.AccountType === 'Wallet') {
    return ShowWallet(account);
  }
  return account.Name + ' / ' + account.AccountType;
};
