import React from 'react';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import ButtonsMonthYear from './ButtonsMonthYear';
import ProductContainer from './ProductContainer';
import GridItem from '../../components/Grid/GridItem';
import { formValueSelector, initialize, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import OrderOverview from './OrderOverview';
import { tradersSelector } from '../../redux/trader/selector';
import { goldColor } from '../../assets/jss/material-dashboard-react.jsx';

const formName = 'formPaiement';

class PaiementContainer extends React.PureComponent {
  render() {
    const { traders, groups } = this.props;
    const wotPaiement = traders.find((x) => x.GroupId === 1);

    return (
      <form>
        <GridItem container>
          <GridItem container xs={12} sm={8} md={8}>
            <GridItem container justifyContent="center">
              <span style={{ color: goldColor }}>
                <Translate id="traders.promo" />
              </span>
            </GridItem>
            <GridItem container justifyContent="center">
              <ButtonsMonthYear formName={formName} />
            </GridItem>
            {wotPaiement && (
              <GridItem container>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <ProductContainer trader={wotPaiement} formName={formName} />
                </GridItem>
              </GridItem>
            )}
            <GridItem container>
              {traders &&
                traders.map((trader) => {
                  if (
                    [1, 1025, 1131].indexOf(trader.GroupId) === -1 && //exception
                    trader.Price !== null &&
                    (!groups || groups.includes(trader.GroupId))
                  ) {
                    return (
                      <GridItem
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        key={trader.GroupId}
                      >
                        <ProductContainer trader={trader} formName={formName} />
                      </GridItem>
                    );
                  }
                })}
            </GridItem>
          </GridItem>
          <GridItem container xs={12} sm={4} md={4}>
            <OrderOverview formName={formName} />
          </GridItem>
        </GridItem>
      </form>
    );
  }
}

let formPaiement = reduxForm({
  form: formName,
})(PaiementContainer);

const groupsSelector = (state, props) => {
  return props.groups;
};

const initialValuesSelector = createSelector(
  tradersSelector,
  groupsSelector,
  (traders, groups) => {
    if (groups) {
      return {
        isUpdate: true,
        nbMonth: 12,
        groupIds: groups,
        showModal: false,
      };
    }
    return {
      isUpdate: false,
      nbMonth: 12,
      groupIds: [1],
      showModal: false,
    };
  },
);

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(formName);
  return {
    initialValues: initialValuesSelector(state, props),
    traders: tradersSelector(state, props),
    groupIds: formSelector(state, 'groupIds'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initialize: bindActionCreators(initialize, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(formPaiement),
);
