import React from 'react';
import PaiementContainer from './PaiementContainer.jsx';

import { bindActionCreators } from 'redux';
import { getTraders } from '../../redux/trader/actions';
import connect from 'react-redux/es/connect/connect';

import { withLocalize } from 'react-localize-redux';
import transTrader from '../../locales/traders.json';
import GridContainer from '../../components/Grid/GridContainer';

class PaiementPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transTrader);
    // const { location } = this.props;
    // const search = location.search ? location.search.toLowerCase() : '';
    // const params = new URLSearchParams(search);
    // const pValue = params.get('p');
    // const npIdValue = params.get('np_id');
    // //TODO: check status on backend and show message
    // console.log(pValue, npIdValue);
  }

  componentDidMount() {
    this.props.getTradersActions();
  }

  render() {
    return (
      <GridContainer>
        <PaiementContainer />
      </GridContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTradersActions: bindActionCreators(getTraders, dispatch),
  };
};

export default withLocalize(connect(null, mapDispatchToProps)(PaiementPage));
