import React from 'react';
import { bindActionCreators } from 'redux';
import { logout } from '../../redux/authentication/actions';
import connect from 'react-redux/es/connect/connect';
import { alertPopup, clearAlert } from '../../redux/alert/alert.actions';
import { push } from 'connected-react-router';
import transConfirmation from '../../locales/confirmation';
import { withLocalize } from 'react-localize-redux';

class LogoutPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transConfirmation);
    this.props.alertActions({
      type: 'confirm',
      then: (result) => {
        if (result.value) {
          this.props.logoutActions();
        } else {
          this.props.clearAlertActions();
          this.props.redirect();
        }
      },
    });
  }

  render() {
    return <div>Logout</div>;
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = (dispatch) => {
  return {
    redirect: () => dispatch(push('/')),
    clearAlertActions: bindActionCreators(clearAlert, dispatch),
    alertActions: bindActionCreators(alertPopup, dispatch),
    logoutActions: bindActionCreators(logout, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(LogoutPage),
);
