import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import footerStyle from '../../assets/jss/material-dashboard-react/components/footerStyle';
import { getActiveCode } from '../../helpers/localize';

class Footer extends React.Component {
  render() {
    const { classes, codeLang } = this.props;
    const termsLinks = {
      fr: '/cgvu',
      gb: '/terms-and-conditions',
      ru: '/politika',
    };
    const privacyLinks = {
      fr: '/politique-de-confidentialite',
      gb: '/privacy-policy',
      ru: '/condifent',
    };
    const termsLink = termsLinks[codeLang];
    const privacyLink = privacyLinks[codeLang];
    return (
      <footer className={classes.footer}>
        <div className={classes.container}>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem
                className={classes.inlineBlock}
                style={{ marginLeft: '10px' }}
              >
                <small>
                  &copy; {1900 + new Date().getYear()} Wall Of Traders. All
                  rights reserved.
                </small>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to={termsLink} className={classes.block}>
                  <Translate id="menu.terms" />
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to={privacyLink} className={classes.block}>
                  <Translate id="menu.privacy" />
                </Link>
              </ListItem>
            </List>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    codeLang: getActiveCode(state),
  };
};

export default withLocalize(
  connect(mapStateToProps, null)(withStyles(footerStyle)(Footer)),
);
