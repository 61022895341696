// Create series
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

export const createAxisAndSeriesForStackedArea = (
  chart,
  field,
  name,
  color,
) => {
  const series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.valueY = field;
  series.dataFields.dateX = 'date';
  series.strokeWidth = 2;
  series.name = name;
  // series.tooltipText = '{name}: [bold]{valueY}[/]';
  series.tooltipText = '[#000]{name}: [bold][#000]{valueY.value}[/][/][/]';
  series.tooltip.getStrokeFromObject = true;
  series.tooltip.background.fill = am4core.color(color);
  series.tooltip.background.strokeWidth = 3;
  series.tooltip.getFillFromObject = false;
  series.fillOpacity = 0.6;
  series.stacked = true;
  return series;
};

export const createAxisAndSeries = (chart, field, name, opposite, color) => {
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

  const series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.valueY = field;
  series.dataFields.dateX = 'date';
  series.strokeWidth = 2;
  series.yAxis = valueAxis;
  series.name = name;
  series.tooltipText = '{name}: [bold]{valueY}[/]';
  series.tooltip.getFillFromObject = false;
  series.tooltip.background.fill = color;
  series.tensionX = 0.8;
  series.stroke = color;

  valueAxis.renderer.line.strokeOpacity = 1;
  valueAxis.renderer.line.strokeWidth = 2;
  valueAxis.renderer.line.stroke = series.stroke;
  valueAxis.renderer.labels.template.fill = series.stroke;
  valueAxis.renderer.opposite = opposite;
  valueAxis.renderer.grid.template.disabled = true;
  return series;
};
