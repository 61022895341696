import React from 'react';
import { connect } from 'react-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';

import { bindActionCreators } from 'redux';
import {
  getAccountsGroup,
  getOrdersGroup,
  getStatGroup,
  IsAdmin,
} from '../../../redux/admin/actions';
import TableStatsGroup from './TableStatsGroup';
import MonthlyStats from '../../Traders/MonthlyStats';
import Card from '../../../components/Card/Card';
import Orders from '../../Orders/Orders';
import transOrders from '../../../locales/orders';
import transSmartTrade from '../../../locales/smartTrade';
import transConfirm from '../../../locales/confirmation';
import { withLocalize } from 'react-localize-redux';
import { isNull } from '../../../helpers/Common';
import TextField from '@material-ui/core/TextField';
import Button from '../../../components/CustomButtons/Button';
import SwitchUI from '@material-ui/core/Switch/Switch';
import transExchanges from '../../../locales/exchanges';
import transExternal from '../../../locales/externals';
import ShowAccounts from './ShowAccounts';
import ShowStats from './ShowStats.jsx';

class StatGroupPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transOrders);
    this.props.addTranslation(transSmartTrade);
    this.props.addTranslation(transExchanges);
    this.props.addTranslation(transExternal);
    this.props.addTranslation(transConfirm);
    this.state = {
      dateFrom: '',
      dateTo: '',
      showAccounts: false,
      withOrderOnStats: true,
      orderFollowers: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const id = match.params.id;
    this.props.getOrdersGroupActions(id);
    this.props.getStatGroupActions(id);
    this.props.IsAdminActions();
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { statsGroup, orders, match } = this.props;

    const groupId = match.params.id;
    const nameGroup =
      !isNull(statsGroup) && statsGroup.length > 0
        ? statsGroup[0].Group.Name
        : '';
    const monthlyStats =
      !isNull(statsGroup) && statsGroup.length > 0
        ? statsGroup[0].ProfitPerMonth
        : null;
    const userId =
      !isNull(statsGroup) &&
      !isNull(statsGroup[0]) &&
      !isNull(statsGroup[0].Group)
        ? statsGroup[0].Group.ApplicationUserId
        : null;
    if (isNull(userId)) return <div />;
    return (
      <GridContainer>
        <ShowAccounts {...this.props} showButton={true} isAdmin={true} />
        <ShowAccounts
          {...this.props}
          showButton={true}
          isAdmin={true}
          showPositions={true}
        />

        <GridItem xs={12} sm={12} md={12}>
          <h1>{nameGroup}</h1>
          <TextField
            id="dateFrom"
            name="dateFrom"
            label="From"
            type="date"
            onChange={(e) => this.handleChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="dateTo"
            name="dateTo"
            label="to"
            type="date"
            onChange={(e) => this.handleChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            onClick={() => {
              this.props.getOrdersGroupActions(
                groupId,
                this.state.dateFrom,
                this.state.dateTo,
                this.state.withOrderOnStats,
                this.state.orderFollowers,
              );
              this.props.getStatGroupActions(
                groupId,
                this.state.dateFrom,
                this.state.dateTo,
              );
            }}
          >
            OK
          </Button>
          {/*Afficher les ordres qui sont dans les stats*/}
          {/*<SwitchUI*/}
          {/*  checked={this.state.withOrderOnStats}*/}
          {/*  onChange={() => {*/}
          {/*    this.setState({ withOrderOnStats: !this.state.withOrderOnStats });*/}
          {/*  }}*/}
          {/*  color="primary"*/}
          {/*/>*/}
          Afficher les ordres des suiveurs
          <SwitchUI
            checked={this.state.orderFollowers}
            onChange={() => {
              this.setState({ orderFollowers: !this.state.orderFollowers });
            }}
            color="primary"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <ShowStats groupId={groupId} whichStat={'old'} isAdmin={true} />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <ShowStats groupId={groupId} whichStat={'new'} isAdmin={true} />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Orders
            isHistoric={true}
            forceTable={true}
            isAdmin={true}
            orders={orders}
            userId={userId}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    orders: admin.orders,
    accounts: admin.accountsGroup,
    ordersGroup: admin.ordersGroup,
    statsGroup: admin.statsGroup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountsGroupActions: bindActionCreators(getAccountsGroup, dispatch),
    getOrdersGroupActions: bindActionCreators(getOrdersGroup, dispatch),
    getStatGroupActions: bindActionCreators(getStatGroup, dispatch),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(StatGroupPage),
);
