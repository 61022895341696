import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';

const styles = () => ({
  root: {
    fontSize: '14px',
    cursor: 'pointer',
    // color: theme.color
  },
});

const LabelCheckbox = (props) => {
  return <FormLabel {...props} />;
};

export default withStyles(styles)(LabelCheckbox);
