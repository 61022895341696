import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class Circular extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
    };
  }
  handleChange = (event, newValue) => {
    this.setState({ ...this.state, value: newValue });
  };
  refreshData = () => {
    const { progress } = this.state;

    if (progress >= 100) {
      this.props.actionToDo();
    }
    this.setState({
      ...this.state,
      progress: progress >= 100 ? 0 : progress + 1,
    });
  };

  componentDidMount() {
    const { refreshSec } = this.props;

    setInterval(this.refreshData, refreshSec * 10);
  }
  render() {
    const { refreshSec } = this.props;
    const { progress } = this.state;
    return (
      <div>
        <div
          style={{
            position: 'absolute',
            paddingLeft: '0.45em',
            paddingTop: '0.25em',
          }}
        >
          {((progress * refreshSec) / 100).toFixed(0)}
        </div>
        <CircularProgress
          variant="static"
          value={progress}
          style={{ width: '2em', height: '2em' }}
        />
      </div>
    );
  }
}
