import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridItem from '../../components/Grid/GridItem.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import Button from '../../components/CustomButtons/Button.jsx';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { bindActionCreators } from 'redux';
import { resetPassword } from '../../redux/authentication/actions';
import connect from 'react-redux/es/connect/connect';
import { Field, reduxForm } from 'redux-form';
import ReactTextField from '../../components/Form/ReactTextFieldUI';
import validate from './RegisterValidate';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';

import { withLocalize } from 'react-localize-redux';
import { Translate } from 'react-localize-redux';
import transEmail from '../../locales/email.json';
import transConfirmation from '../../locales/confirmation';

const styles = {};

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transEmail);
    this.props.addTranslation(transConfirmation);
    this.state = {
      email: '',
      code: '',
      password: '',
      showPassword: false,
      passwordConfirm: '',
      showPasswordConfirm: false,
      sendEmail: false,
    };
    const search = props.location.search;
    let datas = [];
    if (search && search !== '') {
      datas = JSON.parse(
        '{"' +
          decodeURI(search.substring(1))
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}',
      );
      this.state.code = datas['token'] ? datas['token'] : '';
      this.state.email = datas['email'] ? datas['email'] : '';
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };

  handleClickShowPasswordConfirm = () => {
    this.setState((state) => ({
      showPasswordConfirm: !state.showPasswordConfirm,
    }));
  };

  handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      await this.resetPassword();
    }
  };

  resetPassword = () => {
    const register = this.props.formResetPassword.values;
    register.email = this.state.email;
    register.code = this.state.code;
    this.props.resetPasswordActions(register);
    this.setState({ sendEmail: true });
  };

  render() {
    const { classes, invalid } = this.props;
    return (
      <GridContainer>
        {!this.state.sendEmail && <GridItem md={3} />}
        <GridItem xs={12} sm={12} md={6}>
          {!this.state.sendEmail && (
            <form onSubmit={this.register}>
              <Card>
                <CardHeader color="primary">Reset password</CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Field
                        name="password"
                        type="password"
                        label={<Translate id="email.newPassword" />}
                        fullWidth={true}
                        component={ReactTextField}
                        required
                        InputProps={{
                          type: this.state.showPassword ? 'text' : 'password',
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                              >
                                {this.state.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Field
                        name="confirmPassword"
                        type="password"
                        label={<Translate id="email.confirmNewPassword" />}
                        fullWidth={true}
                        component={ReactTextField}
                        required
                        InputProps={{
                          onKeyPress: this.handleKeyPress,
                          type: this.state.showPassword ? 'text' : 'password',
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                              >
                                {this.state.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer justifyContent="center">
                    <Button
                      onClick={(e) => this.resetPassword(e)}
                      className={classes.buttonCenter}
                      color="primary"
                      round
                      disabled={invalid}
                    >
                      <Translate id="email.resetPassword" />
                    </Button>
                  </GridContainer>
                </CardBody>
              </Card>
            </form>
          )}
          {this.state.sendEmail && (
            <Button
              color="primary"
              onClick={() => (window.location.href = '/identification')}
            >
              <Translate id="email.goToLogin" />
            </Button>
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

ResetPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { form } = state;
  return {
    formResetPassword: form.resetPassword,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPasswordActions: bindActionCreators(resetPassword, dispatch),
  };
};

let resetPasswordContainer = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage),
);
export default withLocalize(
  reduxForm({
    form: 'resetPassword', // a unique identifier for this form
    validate: validate,
    // asyncValidate
  })(resetPasswordContainer),
);
