import React from 'react';
import { Translate } from 'react-localize-redux';

export default (values) => {
  const errors = {};
  const requiredFields = ['name', 'currency', 'publicAddress'];
  for (const field of requiredFields) {
    if (
      (values[field] &&
        Array.isArray(values[field]) &&
        values[field].length === 0) ||
      !values[field]
    ) {
      errors[field] = <Translate id="central.required" />;
    }
  }

  if (values && values.currency) {
    if (
      values.currency.label === 'Bitcoin' &&
      values.publicAddress &&
      (values.publicAddress.length < 25 || values.publicAddress.length > 35)
    ) {
      errors.publicAddress = <Translate id="externals.errorSizeBTC" />;
    } else if (values.currency.label === 'Ethereum') {
      if (values.publicAddress && values.publicAddress.length !== 42)
        errors.publicAddress = <Translate id="externals.errorSizeETH" />;
      else if (values.publicAddress && !values.publicAddress.startsWith('0x'))
        errors.publicAddress = <Translate id="externals.errorStartETH" />;
    } else if (
      values.currency.label === 'NEO' &&
      values.publicAddress &&
      values.publicAddress.length !== 34
    ) {
      errors.publicAddress = <Translate id="externals.errorSizeNEO" />;
    }
  }

  return errors;
};
