import React from 'react';
import PropTypes from 'prop-types';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import GridItem from '../../components/Grid/GridItem.jsx';
import Grid from '@material-ui/core/Grid';
import Card from '../../components/Card/Card.jsx';
import Accessibility from '@material-ui/icons/Accessibility';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import Assessment from '@material-ui/icons/Assessment';
import DateRange from '@material-ui/icons/DateRange';
import Star from '@material-ui/icons/Star';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import FollowContainer from './FollowContainer';
import Divider from '@material-ui/core/Divider/Divider';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { getPriceStr } from '../../helpers/general';
import Collapse from '@material-ui/core/Collapse';
import Button from '../../components/CustomButtons/Button';
import { Link } from 'react-router-dom';
import { descriptionSelector } from '../../redux/trader/selector';
import { ShowExchangeEx } from '../../components/Fields/LogoContainer';

const styles = {
  card: {
    display: 'flex',
    // flex: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // alignContent: 'space-between',
    width: '100%',
    marginBottom: 10,
    padding: '0.9375rem 20px',
  },
  details: {
    width: '20%',
  },
  block: {
    width: '25%',
    height: '100%',
  },
  cover: {
    // width: '25%',
    height: '100%',
  },
  blocIcon: {
    display: 'inline-flex',
  },
  blocIconIcon: {
    margin: 5,
  },
  blocIconText: {
    marginTop: 6,
  },
  img: {
    maxWidth: '150px',
    maxHeight: '150px',
  },
};

class Trader extends React.Component {
  state = {
    checked: false,
  };
  handleChange = () => {
    this.setState((state) => ({ checked: !state.checked }));
  };
  render() {
    const { checked } = this.state;

    const { classes, trader, translate, theme, description } = this.props;

    // let endPerf = translate(trader.Name + '.EndPerf');
    // if (endPerf.startsWith('Missing')) endPerf = 'profit / trade';
    const xs = 6;
    const sm = 2;
    const md = 2;
    const priceStr = getPriceStr(trader.Prices);
    const binanceLink = `https://www.binance.com/en/futures-activity/leaderboard/user/um?encryptedUid=${trader.Reference}`;
    return (
      <Card className={classes.card}>
        <Grid container>
          <GridItem xs={12} sm={12} md={2}>
            <CardMedia>
              <Grid container justifyContent="center">
                <img
                  className={classes.img}
                  src={trader.Image}
                  alt={trader.Name}
                />
              </Grid>
              <Grid container justifyContent="center">
                <Button
                  color="info"
                  position="center"
                  to={'/trader/' + trader.GroupId}
                  style={{ width: '150px' }}
                  component={Link}
                  round
                >
                  <Translate id="traders.seeStats" />
                </Button>
              </Grid>
            </CardMedia>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <Typography align="center" variant="h4">
              {trader.Name}
            </Typography>
            <Collapse collapsedSize="9rem" in={checked}>
              <div>
                {trader.IsActive && (
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                )}
                {!trader.IsActive && description}
                {trader.IsLeaderBoard === true && (
                  <>
                    <br />
                    {/*<Button*/}
                    {/*  // color="info"*/}
                    {/*  size="sm"*/}
                    {/*  onClick={() => window.open(binanceLink, '_blank')}*/}
                    {/*>*/}
                    {/*  <Translate id="traders.leaderboardPage"/>*/}
                    {/*</Button>*/}
                    <a
                      href={binanceLink}
                      target="_blank"
                      rel="nofollow noreferrer"
                      className={
                        'link-text ' + (theme === 'dark' ? 'dark' : 'light')
                      }
                    >
                      <Translate id="traders.leaderboardPage" />
                    </a>
                  </>
                )}
              </div>
            </Collapse>
            <div style={{ float: 'right' }}>
              <span
                className={'link-text ' + (theme === 'dark' ? 'dark' : 'light')}
                onClick={this.handleChange}
              >
                {!checked ? (
                  <Translate id="traders.showMore" />
                ) : (
                  <Translate id="traders.showLess" />
                )}
              </span>
            </div>
          </GridItem>
          <Hidden mdUp>
            <GridItem xs={12} sm={12}>
              <Divider style={{ margin: '10px 0px' }} />
            </GridItem>
          </Hidden>
          <GridItem xs={12} sm={12} md={2}>
            <FollowContainer trader={trader} />
          </GridItem>
        </Grid>
        <Grid container>
          <GridItem xs={12} sm={12} md={12}>
            <Divider style={{ margin: '10px 0px' }} />
          </GridItem>
        </Grid>
        <Grid container>
          <GridItem xs={xs} sm={sm} md={md}>
            <div className={classes.blocIcon}>
              <Star className={classes.blocIconIcon} />
              <div className={classes.blocIconText}>
                {trader.Quotes}
                {/*{trader.Profit >= 0 && (*/}
                {/*<Success>*/}
                {/*<b>+{trader.Profit}%</b>*/}
                {/*</Success>*/}
                {/*)}*/}
                {/*{trader.Profit < 0 && (*/}
                {/*<Danger>*/}
                {/*<b>{trader.Profit}%</b>*/}
                {/*</Danger>*/}
                {/*)}{' '}*/}
                {/*{endPerf}*/}
              </div>
            </div>
          </GridItem>
          <GridItem xs={xs} sm={sm} md={md}>
            <div className={classes.blocIcon}>
              <AccountBalanceWallet className={classes.blocIconIcon} />
              <div className={classes.blocIconText}>
                {ShowExchangeEx(trader.ExchangeName)}
              </div>
            </div>
          </GridItem>
          <GridItem xs={xs} sm={sm} md={md}>
            <div className={classes.blocIcon}>
              <Accessibility className={classes.blocIconIcon} />
              <div className={classes.blocIconText}>
                {trader.NbSubscribers}+ <Translate id="traders.subscribers" />
              </div>
            </div>
          </GridItem>
          <GridItem xs={xs} sm={sm} md={md}>
            <div className={classes.blocIcon}>
              <Assessment className={classes.blocIconIcon} />
              <div className={classes.blocIconText}>
                ~ {trader.NbSignalsPerWeek}{' '}
                <Translate id="traders.signalsPerWeek" />
              </div>
            </div>
          </GridItem>
          <GridItem xs={xs} sm={sm} md={md}>
            <div className={classes.blocIcon}>
              <DateRange className={classes.blocIconIcon} />
              <div className={classes.blocIconText}>
                ~ {trader.AvgTrade} <Translate id="traders.daysPerTrade" />
              </div>
            </div>
          </GridItem>
          <GridItem xs={xs} sm={sm} md={md}>
            <div className={classes.blocIcon}>
              <FontAwesomeIcon
                icon={faEuroSign}
                style={{ height: '24px', width: '24px' }}
                className={classes.blocIconIcon}
              />
              <div className={classes.blocIconText}>
                {priceStr !== '' &&
                  translate('traders.from') +
                    ' ' +
                    priceStr +
                    ' ' +
                    translate('traders.perMonth')}
                {priceStr === '' && <Translate id="traders.free" />}
              </div>
            </div>
          </GridItem>
        </Grid>
      </Card>
    );
  }
}

Trader.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { localize, config } = state;
  return {
    theme: config.theme,
    translate: getTranslate(localize),
    description: descriptionSelector(state, props),
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Trader));
