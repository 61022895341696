import moment from 'moment';
import {
  getAllStart,
  getAverageTriggerPrice,
  getTriggerPrice,
  triggerIsDone,
} from '../../order';
import {
  defaultExchange,
  emptyTriggerModel,
  getFirstFromSec,
  getFromValue,
  getPartFromPourcentage,
  getPartFromSecQty,
  getSecFromFirst,
  getSecQtyFromPart,
  isEmpty,
} from '../../Common';
import { formatDateEN } from '../../localize';

const startsInitFromOrder = (
  order,
  currentPrice,
  capital,
  stepPrice,
  secPrice,
  lotStep,
) => {
  const ret = {
    TVType: order.TVType,
    isBuyDone: false,
    dontDoStart: order.DontDoStart,
    starts: [],
    pourcentage: order.Pourcentage,
    leverage: 1,
    leverageCB: false,
    isTemplate: order.OrderType === 1,
  };
  if (order.Leverage != null) {
    ret.leverageCB = true;
    ret.leverage = order.Leverage;
  }
  const triggers = getAllStart(order.Triggers);
  if (isEmpty(triggers)) return ret;

  const timeOut = moment().add(3, 'days').format(formatDateEN);
  ret.isBuyDone = isEmpty(triggers.find((start) => !triggerIsDone(start)));
  for (const trigger of triggers) {
    const newTrigger = {
      pourcentage: trigger.Pourcentage,
      price: getTriggerPrice(trigger),
      tradingViewGuid: trigger.TradingViewGuid,
      tradingViewCB: !isEmpty(trigger.TradingViewGuid),
      trailing: trigger.Trailing,
      trailingCB: !isEmpty(trigger.Trailing),
      timeOut: !isEmpty(trigger.TimeOut) ? trigger.TimeOut : timeOut,
      timeOutCB: !isEmpty(trigger.TimeOut),
      type: trigger.OrderType === 0 ? 'Limit' : 'Market',
      triggerPrice: trigger.TriggerPrice,
      triggerCB: !isEmpty(trigger.TriggerPrice),
      optionsCB: false,
      lastUpdate: 'price',
      isDone: triggerIsDone(trigger),
      triggerId: trigger.TriggerId,
    };
    if (newTrigger.isDone === false) {
      if (newTrigger.type === 'Market') newTrigger.price = currentPrice;
      newTrigger.optionsCB =
        newTrigger.tradingViewCB ||
        newTrigger.trailingCB ||
        newTrigger.timeOutCB ||
        newTrigger.triggerCB;
    }
    ret.starts.push(newTrigger);
  }

  const averagePrice = getAverageTriggerPrice(triggers);
  if (ret.isBuyDone) {
    ret.firstQty = order.Quantity;
    ret.secQty = getSecFromFirst(ret.firstQty, averagePrice, stepPrice);
    ret.partUse = getPartFromSecQty(ret.secQty, secPrice);
  } else {
    ret.partUse = getPartFromPourcentage(
      capital,
      ret.pourcentage,
      ret.leverage,
    );
    ret.secQty = getSecQtyFromPart(ret.partUse, secPrice, stepPrice);
    ret.firstQty = getFirstFromSec(ret.secQty, averagePrice, lotStep);
  }
  if (averagePrice > 0 && !ret.isBuyDone) {
    ret.startTriggerPriceCB = true;
    ret.startTriggerPrice = averagePrice;
  }
  ret.startAveragePrice = averagePrice;
  return ret;
};

const startsInit = ({
  order,
  ex,
  correctCurrentPrice: currentPrice,
  form,
  secPrice,
  stepPrice,
  lotStep,
  capital,
}) => {
  if (order && order.AAccount)
    return startsInitFromOrder(
      order,
      currentPrice,
      capital,
      stepPrice,
      secPrice,
      lotStep,
    );

  const leverage = getFromValue(form, 'leverage', 1);
  const isBuy = getFromValue(form, 'isBuy', true);
  const exchangeName = !ex || !ex.exchange ? defaultExchange : ex.exchange;
  const ret = {
    TVType: getFromValue(form, 'TVType', 0),
    isBuyDone: false,
    dontDoStart: getFromValue(form, 'dontDoStart', false),
    pourcentage: getFromValue(form, 'pourcentage', 5),
    leverage: leverage,
    leverageCB: getFromValue(form, 'leverageCB', false),
    isTemplate: getFromValue(form, 'isTemplate', false),
    templateGuid: getFromValue(form, 'templateGuid', null),
    startAveragePrice: getFromValue(form, 'startAveragePrice', currentPrice),
  };

  ret.partUse = getFromValue(
    form,
    'partUse',
    getPartFromPourcentage(capital, ret.pourcentage, ret.leverage),
  );
  ret.secQty = getFromValue(
    form,
    'secQty',
    getSecQtyFromPart(ret.partUse, secPrice, stepPrice),
  );
  ret.firstQty = getFromValue(
    form,
    'firstQty',
    getFirstFromSec(
      ret.secQty,
      ret.startAveragePrice,
      lotStep,
      exchangeName,
      leverage,
      isBuy,
    ),
  );

  ret.starts = getFromValue(form, 'starts', [
    {
      pourcentage: 100,
      price: currentPrice,
      tradingViewGuid: null,
      tradingViewCB: false,
      trailingPrice: null,
      trailing: 2,
      trailingCB: false,
      timeOut: moment().add(3, 'days').format(formatDateEN),
      timeOutCB: false,
      type: 'Limit',
      triggerPrice: 0,
      triggerCB: false,
      optionsCB: false,
      lastUpdate: 'price',
      isDone: false,
    },
  ]);

  return ret;
};

export default startsInit;
