import React from 'react';
import Card from '../../../components/Card/Card.jsx';
import {
  TreeDataState,
  SortingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  SummaryState,
  IntegratedSummary,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  PagingPanel,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';

import connect from 'react-redux/es/connect/connect';
import { getActiveCode } from '../../../helpers/localize';
import { parseToFloat } from '../../../helpers/Common';

class TableVolumes extends React.PureComponent {
  constructor(props) {
    super(props);

    let columns = [
      {
        name: 'User',
        title: 'User',
        getCellValue: (row) => {
          if (!Object.hasOwn(row['AAccount'], 'ApplicationUser')) return '';
          return row['AAccount']['ApplicationUser'].Email;
        },
      },
      {
        name: 'Date',
        title: 'Date',
      },
      {
        name: 'Exchange',
        title: 'Exchange',
        getCellValue: (row) => {
          return row['AAccount'].ExchangeName;
        },
      },
      {
        name: 'Income',
        title: 'Income',
      },
      {
        name: 'Value',
        title: 'Value',
        getCellValue: (row) => {
          return parseFloat(row.Value);
        },
      },
      {
        name: 'Asset',
        title: 'Asset',
      },
      {
        name: 'Pair',
        title: 'Pair',
      },
      {
        name: 'Volume',
        title: 'Volume',
      },
      {
        name: 'PourcentageCopyTrading',
        title: 'PourcentageCopyTrading',
      },
      {
        name: 'GroupName',
        title: 'GroupName',
      },
      {
        name: 'VolumeCalculed',
        title: 'VolumeCalculed',
      },
      {
        name: 'NbTrigger',
        title: 'NbTrigger',
      },
      {
        name: 'DiffVolume',
        title: '% DiffVolume',
        getCellValue: (row) => {
          return 100 - (row.VolumeCalculed / row.Volume) * 100;
        },
      },
      {
        name: '% fees',
        title: '% fees ( Income / volume *100 )',
        getCellValue: (row) => {
          return parseToFloat(
            (parseFloat(row.Value) / parseFloat(row.Volume)) * 100,
            0.00001,
          );
        },
      },
    ];

    let defaultColumnWidths = [
      { columnName: 'User', width: 150 },
      { columnName: 'Date', width: 95 },
      { columnName: 'Exchange', width: 50 },
      { columnName: 'Income', width: 95 },
      { columnName: 'Value', width: 95 },
      { columnName: 'Asset', width: 60 },
      { columnName: 'Pair', width: 95 },
      { columnName: 'GroupName', width: 95 },
      { columnName: 'Volume', width: 95 },
      { columnName: 'PourcentageCopyTrading', width: 95 },
      { columnName: 'VolumeCalculed', width: 95 },
      { columnName: 'NbTrigger', width: 95 },
      { columnName: 'DiffVolume', width: 95 },
      { columnName: '% fees', width: 60 },
    ];

    this.state = {
      //table
      columns: columns,
      id: 1,
      rows: props.tradingSummaries,
      pageSizes: [5, 10, 20, 50, 0],
      defaultColumnWidths: defaultColumnWidths,
      filters: [],
    };
    this.changeFilters = (filters) => this.setState({ filters });
  }

  componentDidUpdate() {
    this.setState({ rows: this.props.tradingSummaries });
  }

  render() {
    const { rows, columns, defaultColumnWidths, filters } = this.state;

    return (
      <Card>
        <Grid rows={rows} columns={columns}>
          <FilteringState
            filters={filters}
            onFiltersChange={this.changeFilters}
          />
          <SortingState
            defaultSorting={[{ columnName: 'Date', direction: 'desc' }]}
          />
          <PagingState defaultCurrentPage={0} defaultPageSize={20} />
          <SummaryState
            totalItems={[
              { columnName: 'Value', type: 'sum' },
              { columnName: 'Value', type: 'avg' },
              { columnName: 'Volume', type: 'sum' },
              { columnName: 'Volume', type: 'avg' },
            ]}
          />

          <TreeDataState />

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          <IntegratedSummary />

          <Table />
          <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />

          <TableHeaderRow showSortingControls={true} />
          <TableFilterRow />
          <TableSummaryRow />
          <PagingPanel pageSizes={[5, 10, 20, 50, 100, 200, 500, 1000, 0]} />
        </Grid>
        {/*{rows && <pre>{JSON.stringify(rows, null, 2)}</pre>}*/}
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    codeLang: getActiveCode(state),
    // traders: tradersSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TableVolumes);
