import React from 'react';
import { FileCopyTwoTone } from '@material-ui/icons';
import { Field } from 'redux-form';
import ReactTextFieldUI from '../Form/ReactTextFieldUI';
import { InputAdornment } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import '../../assets/css/copy-function.css';

const CopyField = (props) => {
  const { name, copycontent, value } = props;
  const [copied, setCopied] = React.useState(false);
  const copy = async (toCopy) => {
    await navigator.clipboard.writeText(toCopy);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  const copyButton = () => {
    return (
      <div onClick={() => copy(copycontent)} style={{ cursor: 'pointer' }}>
        {!copied ? <FileCopyTwoTone /> : <CheckIcon className="wobble" />}
      </div>
    );
  };

  return (
    <Field
      className="form-control"
      name={name}
      id={name}
      component={ReactTextFieldUI}
      fullWidth={true}
      disabled={true}
      InputProps={{
        value: value,
        endAdornment: (
          <InputAdornment position="end">{copyButton()}</InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default CopyField;
