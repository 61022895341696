import React, { PureComponent } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';
import connect from 'react-redux/es/connect/connect';
import { createAxisAndSeriesForStackedArea } from './chartHelper';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_dark);

class StackedArea extends PureComponent {
  componentDidMount() {
    const { data, name } = this.props;
    if (!data) return;
    let chart = am4core.create(name, am4charts.XYChart);

    //Date: "2019-08-01T00:00:00"
    // ExchangeName: "Binance"
    // Total: 0.7253133009999999
    //TO
    // {
    //   "date": "2019-09-01T00:00:00",
    //   "binance": 1587,
    //   "bittrex": 650,
    //   ...
    // }
    let dataFormated = [];
    let exchanges = [];
    for (const d of data) {
      let t = dataFormated.find((x) => x.date === d.Date);
      if (t === undefined) {
        t = { date: d.Date };
        dataFormated.push(t);
      }
      if (Object.hasOwn(t, d.ExchangeName)) {
        t[d.ExchangeName] += d.Total;
      } else {
        if (exchanges.indexOf(d.ExchangeName) === -1) {
          exchanges.push(d.ExchangeName);
        }
        t[d.ExchangeName] = d.Total;
      }
    }

    chart.data = dataFormated;

    // chart.dateFormatter.inputDateFormat = 'yyyy';
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd';
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.tooltipDateFormat = 'yyyy-MM';
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.baseInterval = {
      timeUnit: 'month',
      count: 1,
    };

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    for (const exchange of exchanges) {
      createAxisAndSeriesForStackedArea(chart, exchange, exchange, '#FFF');
    }

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.scrollbarX = new am4core.Scrollbar();

    // Add a legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';
    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { name } = this.props;

    return (
      <div
        id={name}
        style={{ width: '100%', height: '300px', margin: '10px 0px 0 0px' }}
      />
    );
  }
}

function mapStateToProps(state) {
  const { config } = state;
  return {
    theme: config.theme,
  };
}

export default connect(mapStateToProps, null)(StackedArea);
