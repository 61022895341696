import React from 'react';
import { bindActionCreators } from 'redux';
import { change, Field, formValueSelector } from 'redux-form';
import {
  capitalSelector,
  currentPriceSelector,
  getFirstFromSec,
  getPartFromPourcentage,
  getPriceFromProfit,
  getProfitFromPrice,
  getSecQtyFromPart,
  isEmpty,
  leverageBuySelector,
  leverageSellSelector,
  pairsSelectSelector,
  parseToFloat,
  secPriceSelector,
  smartTradeSelector,
  stepPriceSelector,
  stepQtySelector,
} from '../../../helpers/Common';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import ReactSelect from '../../../components/Form/ReactSelectUI';
import { getAveragePrice, haveLeverage } from '../../../helpers/order';

// const PairOption = ({ pair }) => {
//   if (!pair) {
//     return '';
//   }
//   const split = pair.split('-');
//   if (split.length >= 1 && split[0] === 'XBT') split[0] = 'BTC';
//   if (split.length >= 2 && split[1] === 'XBT') split[1] = 'BTC';
//   return (
//     <div>
//       {split.length >= 1 && <div className={'s-s-' + split[0]} />} {pair}{' '}
//       {split.length >= 2 && <div className={'s-s-' + split[1]} />}
//     </div>
//   );
// };

class SelectPairsContainer extends React.PureComponent {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      pair,
      change,
      formName,
      currentPrice,
      isUpdate,
      starts,
      targets,
      stops,
      isBuy,
      stepPrice,
      capital,
      secPrice,
      lotStep,
      leverageCB,
      leverage,
      exchange,
      leverageBuy,
      leverageSell,
      pourcentage,
    } = this.props;
    // const isUpdate = order !== null;
    if (isUpdate) return;
    // console.log('SelectPairsContainer componentDidUpdate', pair, currentPrice);
    if (!isEmpty(currentPrice) || !isEmpty(pair)) {
      if (pair === prevProps.pair || isEmpty(starts)) return;

      starts.forEach((start, index) => {
        start.price = currentPrice;
        const nPriceTrailing = parseFloat(
          getPriceFromProfit(-1 * start.trailing, isBuy, start.price),
        );
        const newPriceTrailing = !isEmpty(nPriceTrailing)
          ? parseToFloat(nPriceTrailing, stepPrice)
          : null;

        change(formName, `starts.${index}.price`, currentPrice);
        // if (start.triggerPrice !== prevProps.currentPrice)
        change(formName, `starts.${index}.triggerPrice`, currentPrice);
        change(formName, `starts.${index}.trailingPrice`, newPriceTrailing);
      });

      const startAveragePrice = getAveragePrice(starts, stepPrice);
      targets.forEach((target, index) => {
        const nPrice = parseFloat(
          getPriceFromProfit(target.profit, isBuy, startAveragePrice),
        );
        const newPrice = !isEmpty(nPrice)
          ? parseToFloat(nPrice, stepPrice)
          : null;
        change(formName, `targets.${index}.price`, newPrice);
      });
      stops.forEach((stop, index) => {
        const nPrice = parseFloat(
          getPriceFromProfit(stop.profit, isBuy, startAveragePrice),
        );
        const newPrice = !isEmpty(nPrice)
          ? parseToFloat(nPrice, stepPrice)
          : null;
        change(formName, `stops.${index}.price`, newPrice);
      });
      change(formName, 'startAveragePrice', startAveragePrice);

      const isLeverage =
        exchange &&
        haveLeverage(exchange.exchange, isBuy, leverageBuy, leverageSell);
      const leverageUse = leverageCB && isLeverage ? leverage : 1;

      const newPartUse = getPartFromPourcentage(
        capital,
        pourcentage,
        leverageUse,
      );
      const qtySec = getSecQtyFromPart(newPartUse, secPrice, stepPrice);
      const fstFromSec = getFirstFromSec(qtySec, startAveragePrice, lotStep);

      change(formName, 'partUse', newPartUse);
      change(formName, 'secQty', qtySec);
      change(formName, 'firstQty', fstFromSec);
    }
  }

  render() {
    const { pairs, isUpdate } = this.props;
    const isDisabled = !pairs || !pairs.length || isUpdate;
    return (
      <Field
        name="pair"
        label={<Translate id="smartTrade.pair" />}
        options={pairs}
        fullWidth={true}
        component={ReactSelect}
        required
        disabled={isDisabled}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  return {
    // values: getFormValues(props.formName)(state),
    values: smartTradeSelector(state),
    // initialValues: getFormInitialValues(props.formName)(state),
    pair: formSelector(state, 'pair'),
    isUpdate: formSelector(state, 'isUpdate'),
    order: formSelector(state, 'order'),
    currentPrice: currentPriceSelector(state, props),
    pairs: pairsSelectSelector(state, props),
    starts: formSelector(state, 'starts'),
    targets: formSelector(state, 'targets'),
    stops: formSelector(state, 'stops'),
    isBuy: formSelector(state, 'isBuy'),
    stepPrice: stepPriceSelector(state, props),
    capital: capitalSelector(state, props),
    secPrice: secPriceSelector(state, props),
    startAveragePrice: formSelector(state, 'startAveragePrice'),
    lotStep: stepQtySelector(state, props),
    leverageCB: formSelector(state, 'leverageCB'),
    leverage: formSelector(state, 'leverage'),
    exchange: formSelector(state, 'accountId'),
    leverageBuy: leverageBuySelector(state, props),
    leverageSell: leverageSellSelector(state, props),
    pourcentage: formSelector(state, 'pourcentage'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectPairsContainer);
