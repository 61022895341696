import { alertConstants } from './alert.constants';
import { authenticationConstants } from '../authentication/constants';

export function alert(state = { popupOptionsPaiement: false }, action) {
  switch (action.type) {
    case alertConstants.ALERT:
      return action;
    case alertConstants.SUCCESS:
      return {
        type: 'success',
        message: action.message,
        duration: action.duration,
      };
    case alertConstants.POPUP_OPTIONS_PAIEMENT:
      return {
        ...state,
        popupOptionsPaiement: action.payload,
      };
    case alertConstants.ERROR:
      return {
        type: 'danger',
        message: action.message,
        duration: action.duration,
      };
    case alertConstants.CLEAR:
    case authenticationConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}
