import React from 'react';

// @material-ui/core components
import Button from '../../components/CustomButtons/Button.jsx';
import CustomInput from '../../components/CustomInput/CustomInput.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';

import { connect } from 'react-redux';

import { Translate } from 'react-localize-redux';
import { withLocalize } from 'react-localize-redux';
import GridItem from '../../components/Grid/GridItem.jsx';
import { bindActionCreators } from 'redux';
import { getTFA, setTFA } from '../../redux/authentication/actions';
import { isNull } from '../../helpers/Common';
import { makeRandomStr } from '../../helpers/general';

class TFAContainer extends React.Component {
  constructor(props) {
    super(props);
    const secret = makeRandomStr(12, true);
    this.state = {
      code: '',
      secret: secret,
    };
  }
  componentDidMount() {
    this.props.getTFAActions(this.state.secret);
  }
  onChange = (e) => {
    this.setState({ code: e.target.value });
  };
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.setTFA();
    }
  };
  setTFA = () => {
    const { tfaActive } = this.props;
    const { secret, code } = this.state;
    this.props.setTFAActions(secret, code, !tfaActive);
    this.setState({ code: '' });
  };
  render() {
    const { tfa, tfaActive } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={3} />
        {!isNull(tfa) && (
          <GridItem xs={12} sm={12} md={6}>
            {!tfaActive && (
              <div>
                <p>
                  <Translate id="settings.tfa1" />
                  <br />
                  <Translate id="settings.tfa2" />
                  <br />
                  <Translate id="settings.tfa3" />
                  <br />
                  <Translate id="settings.tfa4" />
                  <br />
                </p>
                <img src={tfa.QrCodeSetupImageUrl} alt="QRCode 2FA" />
                <br />
                <Translate id="settings.tfaSecretCode" />:{' '}
                <b>{tfa.ManualEntryKey}</b>
              </div>
            )}
            <h3>
              <Translate id="settings.tfaLabelInput" />
            </h3>
            <CustomInput
              labelText={<Translate id="settings.tfaPlaceHolderInput" />}
              name={'code'}
              fullWidth={true}
              inputProps={{
                value: this.state.code,
                type: 'number',
                onChange: this.onChange,
                onKeyPress: this.handleKeyPress,
              }}
            />
            <Button
              position="center"
              color={!tfaActive ? 'success' : 'danger'}
              onClick={() => {
                this.setTFA();
              }}
              style={{ width: '100%' }}
            >
              {!tfaActive ? (
                <Translate id="settings.activate" />
              ) : (
                <Translate id="settings.deactivate" />
              )}
            </Button>
          </GridItem>
        )}
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { authentication } = state;

  return {
    tfa: authentication.tfa,
    tfaActive: authentication.tfaActive,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTFAActions: bindActionCreators(getTFA, dispatch),
    setTFAActions: bindActionCreators(setTFA, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(TFAContainer),
);
