import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import {
  getEmailByAccountId,
  getEmailByGroupId,
  getEmailByOrderId,
  getEmailByTradingViewGuid,
  getEmailByTriggerId,
  getEmailByUserId,
} from '../../../redux/admin/actions';
import { isEmpty } from '../../../helpers/Common';
import AdminButtons from '../../../components/CustomButtons/AdminButtons';
import LittleButton from '../../../components/CustomButtons/LittleButton';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { Input } from '@material-ui/core';
import { Search } from '@material-ui/icons';

class SearchPage extends React.Component {
  render() {
    const {
      emailByAccount,
      emailByUser,
      emailByTrigger,
      emailByOrder,
      emailByGroup,
      emailByTradingView,
    } = this.props;

    var inputByAccountId = '';
    var inputByUserId = '';
    var inputByTriggerId = '';
    var inputByOrderId = '';
    var inputByGroupId = '';
    var inputByTradingViewGuid = '';

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} container justifyContent="center">
          <div style={{ width: '100%' }}>
            <Input
              fullWidth={true}
              placeholder="By Account Id"
              onChange={(e) => (inputByAccountId = e.target.value)}
              endAdornment={
                <LittleButton
                  size="xs"
                  color="info"
                  icon={<Search />}
                  onClick={() => {
                    if (!isEmpty(inputByAccountId)) {
                      this.props.getEmailByAccountIdActions(inputByAccountId);
                    }
                  }}
                />
              }
            />
            {!isEmpty(emailByAccount) && (
              <GridContainer>
                <GridItem
                  xs={6}
                  sm={6}
                  md={6}
                  container
                  justifyContent="flex-end"
                >
                  <span style={{ alignItems: 'center', display: 'flex' }}>
                    {emailByAccount.Email} :
                  </span>
                </GridItem>
                <GridItem
                  xs={6}
                  sm={6}
                  md={6}
                  container
                  justifyContent="flex-start"
                >
                  <AdminButtons user={emailByAccount} />
                </GridItem>
              </GridContainer>
            )}
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} container justifyContent="center">
          <div style={{ width: '100%' }}>
            <Input
              fullWidth={true}
              placeholder="By User Id"
              onChange={(e) => (inputByUserId = e.target.value)}
              endAdornment={
                <LittleButton
                  size="xs"
                  color="info"
                  icon={<Search />}
                  onClick={() => {
                    if (!isEmpty(inputByUserId)) {
                      this.props.getEmailByUserIdActions(inputByUserId);
                    }
                  }}
                />
              }
            />
            {!isEmpty(emailByUser) && (
              <GridContainer>
                <GridItem
                  xs={6}
                  sm={6}
                  md={6}
                  container
                  justifyContent="flex-end"
                >
                  <span style={{ alignItems: 'center', display: 'flex' }}>
                    {emailByUser.Email} :
                  </span>
                </GridItem>
                <GridItem
                  xs={6}
                  sm={6}
                  md={6}
                  container
                  justifyContent="flex-start"
                >
                  <AdminButtons user={emailByUser} />
                </GridItem>
              </GridContainer>
            )}
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} container justifyContent="center">
          <div style={{ width: '100%' }}>
            <Input
              fullWidth={true}
              placeholder="By Trigger Id"
              onChange={(e) => (inputByTriggerId = e.target.value)}
              endAdornment={
                <LittleButton
                  size="xs"
                  color="info"
                  icon={<Search />}
                  onClick={() => {
                    if (!isEmpty(inputByTriggerId)) {
                      this.props.getEmailByTriggerIdActions(inputByTriggerId);
                    }
                  }}
                />
              }
            />
            {!isEmpty(emailByTrigger) && (
              <GridContainer>
                <GridItem
                  xs={6}
                  sm={6}
                  md={6}
                  container
                  justifyContent="flex-end"
                >
                  <span style={{ alignItems: 'center', display: 'flex' }}>
                    {emailByTrigger.Email} :
                  </span>
                </GridItem>
                <GridItem
                  xs={6}
                  sm={6}
                  md={6}
                  container
                  justifyContent="flex-start"
                >
                  <AdminButtons user={emailByTrigger} />
                </GridItem>
              </GridContainer>
            )}
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} container justifyContent="center">
          <div style={{ width: '100%' }}>
            <Input
              fullWidth={true}
              placeholder="By Order Id"
              onChange={(e) => (inputByOrderId = e.target.value)}
              endAdornment={
                <LittleButton
                  size="xs"
                  color="info"
                  icon={<Search />}
                  onClick={() => {
                    if (!isEmpty(inputByOrderId)) {
                      this.props.getEmailByOrderIdActions(inputByOrderId);
                    }
                  }}
                />
              }
            />
            {!isEmpty(emailByOrder) && (
              <GridContainer>
                <GridItem
                  xs={6}
                  sm={6}
                  md={6}
                  container
                  justifyContent="flex-end"
                >
                  <span style={{ alignItems: 'center', display: 'flex' }}>
                    {emailByOrder.Email} :
                  </span>
                </GridItem>
                <GridItem
                  xs={6}
                  sm={6}
                  md={6}
                  container
                  justifyContent="flex-start"
                >
                  <AdminButtons user={emailByOrder} />
                </GridItem>
              </GridContainer>
            )}
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} container justifyContent="center">
          <div style={{ width: '100%' }}>
            <Input
              fullWidth={true}
              placeholder="By Group Id"
              onChange={(e) => (inputByGroupId = e.target.value)}
              endAdornment={
                <LittleButton
                  size="xs"
                  color="info"
                  icon={<Search />}
                  onClick={() => {
                    if (!isEmpty(inputByGroupId)) {
                      this.props.getEmailByGroupIdActions(inputByGroupId);
                    }
                  }}
                />
              }
            />
            {!isEmpty(emailByGroup) && (
              <GridContainer>
                <GridItem
                  xs={6}
                  sm={6}
                  md={6}
                  container
                  justifyContent="flex-end"
                >
                  <span style={{ alignItems: 'center', display: 'flex' }}>
                    {emailByGroup.Email} :
                  </span>
                </GridItem>
                <GridItem
                  xs={6}
                  sm={6}
                  md={6}
                  container
                  justifyContent="flex-start"
                >
                  <AdminButtons user={emailByGroup} />
                </GridItem>
              </GridContainer>
            )}
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} container justifyContent="center">
          <div style={{ width: '100%' }}>
            <Input
              fullWidth={true}
              placeholder="By TradingView Guid"
              onChange={(e) => (inputByTradingViewGuid = e.target.value)}
              endAdornment={
                <LittleButton
                  size="xs"
                  color="info"
                  icon={<Search />}
                  onClick={() => {
                    if (!isEmpty(inputByTradingViewGuid)) {
                      this.props.getEmailByTradingViewGuidActions(
                        inputByTradingViewGuid,
                      );
                    }
                  }}
                />
              }
            />
            {!isEmpty(emailByTradingView) && (
              <GridContainer>
                <GridItem
                  xs={6}
                  sm={6}
                  md={6}
                  container
                  justifyContent="flex-end"
                >
                  <span style={{ alignItems: 'center', display: 'flex' }}>
                    {emailByTradingView.Email} :
                  </span>
                </GridItem>
                <GridItem
                  xs={6}
                  sm={6}
                  md={6}
                  container
                  justifyContent="flex-start"
                >
                  <AdminButtons user={emailByTradingView} />
                </GridItem>
              </GridContainer>
            )}
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    emailByAccount: admin.emailByAccount,
    emailByUser: admin.emailByUser,
    emailByTrigger: admin.emailByTrigger,
    emailByOrder: admin.emailByOrder,
    emailByGroup: admin.emailByGroup,
    emailByTradingView: admin.emailByTradingView,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmailByAccountIdActions: bindActionCreators(
      getEmailByAccountId,
      dispatch,
    ),
    getEmailByUserIdActions: bindActionCreators(getEmailByUserId, dispatch),
    getEmailByTriggerIdActions: bindActionCreators(
      getEmailByTriggerId,
      dispatch,
    ),
    getEmailByOrderIdActions: bindActionCreators(getEmailByOrderId, dispatch),
    getEmailByGroupIdActions: bindActionCreators(getEmailByGroupId, dispatch),
    getEmailByTradingViewGuidActions: bindActionCreators(
      getEmailByTradingViewGuid,
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
