import React from 'react';

import useIsMobileDevice from '../hooks/useIsMobileDevice';

/**
 * Component that manage Responsiveness
 * @param mobile {React.ElementType} element that will be used on mobile devices
 * @param desktop {React.ElementType} element that will be used on desktop devices
 * @returns mobile if isMobileDevice is true, desktop otherwise
 */
function Responsive({ mobile, desktop }) {
  const isMobileDevice = useIsMobileDevice();
  return isMobileDevice ? mobile : desktop;
}

Responsive.defaultProps = {
  mobile: <></>,
  desktop: <></>,
};

export default Responsive;
