import React from 'react';
import { Translate } from 'react-localize-redux';
import { batch, connect, useDispatch } from 'react-redux';
import { reduxForm, change, FormSection } from 'redux-form';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { putUserNotifications } from '../../redux/config/actions';
import Button from '../../components/CustomButtons/Button';
import { Collapse, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { querySelector } from '../../helpers/selector.js';
import { isNull } from '../../helpers/Common';
import WarningHover from '../../components/Hover/WarningHover';
import { ArrowDropDown, ArrowRight } from '@material-ui/icons';

const formName = 'notificationForm';
const NotificationsForm = (props) => {
  const [startExpanded, setStartExpanded] = React.useState(false);
  const [targetExpanded, setTargetExpanded] = React.useState(false);
  const [stopExpanded, setStopExpanded] = React.useState(false);
  const dispatch = useDispatch();
  const { valuesForm } = props;
  const dispos = valuesForm.Dispos;
  const sectionName = 'Options';
  const query = useSelector(querySelector);

  const generalSettings = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const startSettings = [11, 12, 13, 14, 15];
  const targetSettings = [16, 17, 18, 19, 20];
  const stopSettings = [21, 22, 23, 24, 25];

  const values = {
    "General": [],
    "Start": [],
    "Target": [],
    "Stop": [],
    "Others": [],
    "All": []
  };
  let rank = 0;
  for (const option of valuesForm.Options) {
    const newOptions = { ...option, Rank: rank++ };
    if (generalSettings.includes(option.Notification))
      values["General"].push(newOptions);
    else if (startSettings.includes(option.Notification))
      values["Start"].push(newOptions);
    else if (targetSettings.includes(option.Notification))
      values["Target"].push(newOptions);
    else if (stopSettings.includes(option.Notification))
      values["Stop"].push(newOptions);
    else
      values["Others"].push(newOptions);
    values["All"].push(newOptions);
  }

  const isAllCheckedEmail = (values) => values.every((option) => option.Email);
  const isAllCheckedTelegram = (values) => values.every((option) => option.Telegram);
  const changeAllValues = (values, bolean, type) => {
    batch(() => {
      for (const option of values) {
        dispatch(change(formName, `${sectionName}.${option.Rank}.${type}`, bolean));
      }
    });
  };
  const GetNotificationsFromList = (options, isTrigger = false) => {
    return (
      <>
        {options.map((option) => {
          let notif = option.Notification;
          if (isTrigger) {
            if (notif === 11 || notif === 16 || notif === 21) notif = 1;
            else if (notif === 12 || notif === 17 || notif === 22) notif = 2;
            else if (notif === 13 || notif === 18 || notif === 23) notif = 3;
            else if (notif === 14 || notif === 19 || notif === 24) notif = 4;
            else if (notif === 15 || notif === 20 || notif === 25) notif = 5;
            notif = "trigger." + notif;
          }
          const transName = "settings.notifications." + notif;
          return (
            <GridContainer key={option.Notification}>
              <GridItem xs={4} sm={6} md={6} style={{ marginLeft: "15px", marginRight: "-15px" }}>
                <div style={{ marginTop: '8px' }}>
                  <Translate id={transName} />
                </div>
              </GridItem>
              <GridItem xs={4} sm={3} md={3}>
                <Checkbox
                  checked={option.Email}
                  onChange={() => {
                    dispatch(
                      change(
                        formName,
                        `${sectionName}.${option.Rank}.Email`,
                        !option.Email,
                      ),
                    );
                  }}
                  value="theme"
                  color="primary"
                />
                {isNull(dispos[option.Notification].EmailType) && (
                  <WarningHover
                    text={<Translate id="settings.unavailable" />}
                  />
                )}
              </GridItem>
              <GridItem xs={4} sm={3} md={3}>
                <Checkbox
                  checked={option.Telegram}
                  onChange={() => {
                    dispatch(
                      change(
                        formName,
                        `${sectionName}.${option.Rank}.Telegram`,
                        !option.Telegram,
                      ),
                    );
                  }}
                  value="theme"
                  color="primary"
                />
                {isNull(dispos[option.Notification].TelegramType) && (
                  <WarningHover
                    text={<Translate id="settings.unavailable" />}
                  />
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Divider style={{ marginTop: '4px', marginBottom: '4px' }} />
              </GridItem>
            </GridContainer>
          );
        })}
      </>
    );
  };
  const GetSmartTradeNotifications = (triggerType, options) => {
    const onClick = () => {
      if (triggerType === 'Start') setStartExpanded(!startExpanded);
      else if (triggerType === 'Target') setTargetExpanded(!targetExpanded);
      else if (triggerType === 'Stop') setStopExpanded(!stopExpanded);
    };
    const expanded = triggerType === 'Start' ? startExpanded : triggerType === 'Target' ? targetExpanded : stopExpanded;
    const anyMissingEmail = options.some((option) => isNull(dispos[option.Notification].EmailType));
    const anyMissingTelegram = options.some((option) => isNull(dispos[option.Notification].TelegramType));
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={4} sm={6} md={6} onClick={onClick} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'left', marginLeft: '-10px', marginRight: '10px' }}>
              <span style={{ scale: '0.8' }}>
                {expanded ? <ArrowDropDown /> : <ArrowRight />}
              </span>
              <Translate id={"settings.notifications." + triggerType} />
            </GridItem>
            <GridItem xs={4} sm={3} md={3}>
              <Checkbox
                checked={isAllCheckedEmail(options)}
                indeterminate={!isAllCheckedEmail(options) && options.some((option) => option.Email)}
                onChange={() => {
                  changeAllValues(options, !isAllCheckedEmail(options), 'Email');
                }}
                value="theme"
                color="primary"
              />
              {anyMissingEmail && (
                <WarningHover
                  text={<Translate id="settings.manyUnavailable" />}
                />
              )}
            </GridItem>
            <GridItem xs={4} sm={3} md={3}>
              <Checkbox
                checked={isAllCheckedTelegram(options)}
                indeterminate={!isAllCheckedTelegram(options) && options.some((option) => option.Telegram)}
                onChange={() => {
                  changeAllValues(options, !isAllCheckedTelegram(options), 'Telegram');
                }}
                value="theme"
                color="primary"
              />
              {anyMissingTelegram && (
                <WarningHover
                  text={<Translate id="settings.manyUnavailable" />}
                />
              )}
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Collapse in={expanded}>
            <Divider style={{ marginTop: '4px', marginBottom: '4px' }} />
            {GetNotificationsFromList(options, true)}
          </Collapse>
          {!expanded && <Divider style={{ marginTop: '4px', marginBottom: '4px' }} />}
        </GridItem>
      </GridContainer>
    );
  };
  return (
    <form>
      <FormSection name={sectionName}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {GetNotificationsFromList(values["General"])}
          </GridItem>
        </GridContainer>
        {GetSmartTradeNotifications("Start", values["Start"])}
        {GetSmartTradeNotifications("Target", values["Target"])}
        {GetSmartTradeNotifications("Stop", values["Stop"])}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {GetNotificationsFromList(values["Others"])}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={4} sm={6} md={6} style={{ marginLeft: "15px", marginRight: "-15px" }}>
            <div style={{ marginTop: '8px' }}>
              <Translate id="settings.selectDeselectAll" />
            </div>
          </GridItem>
          <GridItem xs={4} sm={3} md={3}>
            <Checkbox
              checked={isAllCheckedEmail(values["All"])}
              indeterminate={!isAllCheckedEmail(values["All"]) && values["All"].some((option) => option.Email)}
              onChange={() => {
                changeAllValues(values["All"], !isAllCheckedEmail(values["All"]), 'Email');
              }}
              value="theme"
              color="primary"
            />
          </GridItem>
          <GridItem xs={4} sm={3} md={3}>
            <Checkbox
              checked={isAllCheckedTelegram(values["All"])}
              indeterminate={!isAllCheckedTelegram(values["All"]) && values["All"].some((option) => option.Telegram)}
              onChange={() => {
                changeAllValues(values["All"], !isAllCheckedTelegram(values["All"]), 'Telegram');
              }}
              value="theme"
              color="primary"
            />
          </GridItem>
        </GridContainer>
        <Divider style={{ marginTop: '4px', marginBottom: '4px' }} />
        <GridContainer justifyContent="center">
          <Button
            color="primary"
            round
            target={'_blank'}
            onClick={() => {
              dispatch(
                putUserNotifications(valuesForm, query?.email, query?.token),
              );
            }}
            style={{ marginTop: '10px', marginBottom: '-5px' }}
          >
            <Translate id="central.save" />
          </Button>
        </GridContainer>
      </FormSection>
    </form>
  );
};

let notificationsForm = reduxForm({
  form: formName, // a unique identifier for this form
})(NotificationsForm);

const mapStateToProps = (state, props) => {
  const { form } = state;
  return {
    valuesForm:
      form && form[formName] && form[formName].values
        ? form[formName].values
        : props.notificationSettings,
    initialValues: props.notificationSettings,
  };
};

export default connect(mapStateToProps, null)(notificationsForm);
