import moment from 'moment';
import {
  getAllStopLoss,
  getAverageTotalPrice,
  getMoveSLOption,
  getTriggerPrice,
  triggerIsDone,
} from '../../order';
import {
  getFromValue,
  getPriceFromProfit,
  getProfitFromPrice,
  isEmpty,
  isNull,
  parseToFloat,
} from '../../Common';
import { formatDateEN } from '../../localize';

const getBaseStopModel = (averagePrice, stepPrice) => {
  return {
    pourcentage: 100,
    price: parseToFloat(averagePrice * 0.98, stepPrice),
    profit: -2,
    tradingViewGuid: null,
    tradingViewCB: false,
    trailingPrice: null,
    trailing: -2,
    trailingCB: false,
    timeOut: moment().add(3, 'days').format(formatDateEN),
    timeOutCB: false,
    type: 'Limit',
    triggerPrice: 0,
    triggerCB: false,
    optionsCB: false,
    lastUpdate: 'profit',
    isDone: false,
  };
};

const stopsInitFromOrder = (order, currentPrice, stepPrice, starts) => {
  const ret = {
    stopsLock: true,
    moveSL: null,
    moveSLCB: false,
    stops: [],
  };
  const averagePrice = getAverageTotalPrice(starts, stepPrice);
  const triggers = getAllStopLoss(order.Triggers);

  if (isEmpty(triggers)) {
    return {
      ...ret,
      stops: [getBaseStopModel(averagePrice, stepPrice)],
    };
  }
  ret.stopsLock = false;
  if (order.MoveSL !== null) {
    const moveSLOption = getMoveSLOption(order.MoveSL);
    if (!isNull(moveSLOption)) {
      ret.moveSL = moveSLOption;
      ret.moveSLCB = true;
    }
  }

  const baseStartPrice = averagePrice === 0 ? currentPrice : averagePrice;
  const isTemplate = order.State === 9;
  const isBuy = order.IsBuy;
  const timeOut = moment().add(3, 'days').format(formatDateEN);

  for (const trigger of triggers) {
    const triggerPrice = getTriggerPrice(trigger);
    const tmpPrice = isTemplate
      ? null
      : !isNull(triggerPrice)
      ? triggerPrice
      : getPriceFromProfit(trigger.Profit, isBuy, baseStartPrice);
    const price = parseToFloat(tmpPrice, stepPrice);
    // ret.stopPrice = parseToFloat(price, stepPrice);
    const tmpProfit = parseFloat(
      getProfitFromPrice(price, isBuy, baseStartPrice),
    );
    const profit = !isNull(trigger.Profit)
      ? trigger.Profit
      : !isEmpty(tmpProfit)
      ? parseToFloat(tmpProfit, 0.01)
      : tmpProfit;
    const trailingPrice =
      isNull(trigger.Trailing) || isEmpty(averagePrice)
        ? null
        : parseToFloat(
            getPriceFromProfit(-1 * trigger.Trailing, isBuy, averagePrice),
            stepPrice,
          );
    const newTrigger = {
      pourcentage: trigger.Pourcentage,
      price: price,
      profit: profit,
      tradingViewGuid: trigger.TradingViewGuid,
      tradingViewCB: !isEmpty(trigger.TradingViewGuid),
      trailingPrice: trailingPrice,
      trailing: trigger.Trailing * -1,
      trailingCB: !isEmpty(trigger.Trailing),
      timeOut: !isEmpty(trigger.TimeOut) ? trigger.TimeOut : timeOut,
      timeOutCB: !isEmpty(trigger.TimeOut),
      type: trigger.OrderType === 0 ? 'Limit' : 'Market',
      triggerPrice: trigger.TriggerPrice,
      triggerCB: !isEmpty(trigger.TriggerPrice),
      optionsCB: false,
      lastUpdate: !isNull(trigger.Profit) ? 'profit' : 'price',
      isDone: triggerIsDone(trigger),
      triggerId: trigger.TriggerId,
    };
    if (newTrigger.isDone === false) {
      newTrigger.optionsCB =
        newTrigger.tradingViewCB ||
        newTrigger.trailingCB ||
        newTrigger.timeOutCB ||
        newTrigger.triggerCB;
    }
    ret.stops.push(newTrigger);
  }

  return ret;
};

const stopsInit = ({
  order,
  correctCurrentPrice: currentPrice,
  form,
  stepPrice,
  starts,
}) => {
  if (order) return stopsInitFromOrder(order, currentPrice, stepPrice, starts);

  const ret = {
    stopsLock: getFromValue(form, 'stopsLock', true),
    moveSL: getFromValue(form, 'moveSL', null),
    moveSLCB: getFromValue(form, 'moveSLCB', false),
    stops: [],
  };
  ret.stops = getFromValue(form, 'stops', [
    getBaseStopModel(currentPrice, stepPrice),
  ]);

  return ret;
};

export default stopsInit;
