import React from 'react';
import { connect } from 'react-redux';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import { withLocalize } from 'react-localize-redux';
import ShowAccounts from './ShowAccounts';
import transOrders from '../../../locales/orders';
import transSmartTrade from '../../../locales/smartTrade';
import transExchanges from '../../../locales/exchanges';
import transExternal from '../../../locales/externals';
import transConfirm from '../../../locales/confirmation';

class AccountsGroupPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transOrders);
    this.props.addTranslation(transSmartTrade);
    this.props.addTranslation(transExchanges);
    this.props.addTranslation(transExternal);
    this.props.addTranslation(transConfirm);
  }

  render() {
    return (
      <GridContainer>
        <ShowAccounts {...this.props} showButton={false} isAdmin={true} />
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(AccountsGroupPage),
);
