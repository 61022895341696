import React from 'react';
import { bindActionCreators } from 'redux';
import { getTrader } from '../../redux/trader/actions';
import connect from 'react-redux/es/connect/connect';

import { Translate, withLocalize } from 'react-localize-redux';
import { tradersSelector } from '../../redux/trader/selector';
import FormAddTrader from './FormAddTrader';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import { isNull } from '../../helpers/Common';
import transTrader from '../../locales/traders.json';
import transError from '../../locales/error.json';
import transExchanges from '../../locales/exchanges.json';
import transConfirmation from '../../locales/confirmation.json';

class UpdateTraderPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transTrader);
    this.props.addTranslation(transError);
    this.props.addTranslation(transExchanges);
    this.props.addTranslation(transConfirmation);
  }

  componentDidMount() {
    const { match, traders } = this.props;

    const trader = !isNull(traders)
      ? traders.find((x) => x.GroupId === parseInt(match.params.id))
      : null;

    const id = match.params.id;
    if (trader) this.props.getTraderActions(id, trader.Reference);
  }

  render() {
    const { traders, match } = this.props;
    const trader = !isNull(traders)
      ? traders.find((x) => x.GroupId === parseInt(match.params.id))
      : null;
    if (!trader) return <div />;
    return (
      <Card>
        <CardHeader color="primary">
          <h4>
            <Translate id="traders.updateMyGroup" />: {trader.Name}
          </h4>
        </CardHeader>
        <CardBody>
          <FormAddTrader
            noPopupTrading={true}
            redirect={true}
            trader={trader}
          />
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    traders: tradersSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTraderActions: bindActionCreators(getTrader, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(UpdateTraderPage),
);
