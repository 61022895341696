import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import GridItem from '../../components/Grid/GridItem';
import { Translate, withLocalize } from 'react-localize-redux';

import { withWidth } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ManageReferrals from './ManageReferrals';
import { tradersSelector } from '../../redux/trader/selector';
import { getFollowers } from '../../redux/trader/actions';
import { IsAdmin } from '../../redux/admin/actions';
import {
  createFollowersGridAction,
  resetFollowersColumns,
} from '../../redux/config/actions';
import Success from '../../components/Typography/Success';
import { grayColor } from '../../assets/jss/material-dashboard-react';
import { getCampaigns } from '../../redux/referrals/actions';
import { referralSelector } from '../../redux/referrals/selector';
import AddReferralButton from './AddReferralButton';
import WithdrawalButton from './WithdrawalButton';
import { getUsdFromReferrals } from '../../helpers/referrals';

class Referrals extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.getCampaignsAction();
  }

  render() {
    const { match, referrals } = this.props;
    const groupId = match?.params?.id;
    const totalGains = getUsdFromReferrals(referrals);

    return (
      <GridItem container>
        <GridItem container>
          <GridItem container xs={4} sm={8} md={8} justifyContent="flex-start">
            <AddReferralButton />
            <WithdrawalButton totalGains={totalGains} />
          </GridItem>
          <GridItem container xs={12} sm={4} md={4} justifyContent="flex-end">
            <div>
              <Success>
                <Typography variant="h5">
                  <Translate id="referrals.totalAmount" />
                  {' ' + totalGains}
                </Typography>
              </Success>
              <span style={{ color: grayColor }}>
                <Translate id="referrals.withdrawalPossibleFrom50" />
              </span>
            </div>
          </GridItem>
          <ManageReferrals groupId={groupId} referrals={referrals} />
        </GridItem>
      </GridItem>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    traders: tradersSelector(state),
    referrals: referralSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFollowersActions: bindActionCreators(getFollowers, dispatch),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
    resetColumnsActions: () => dispatch(resetFollowersColumns()),
    clearFilterActions: () =>
      dispatch(createFollowersGridAction('filters', [])),
    getCampaignsAction: bindActionCreators(getCampaigns, dispatch),
  };
};

export default withWidth()(
  withLocalize(connect(mapStateToProps, mapDispatchToProps)(Referrals)),
);
