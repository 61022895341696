import React from 'react';

// @material-ui/core components
// core components
import GridItem from '../../components/Grid/GridItem.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import Button from '../../components/CustomButtons/Button.jsx';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { bindActionCreators } from 'redux';
import { login } from '../../redux/authentication/actions';
import connect from 'react-redux/es/connect/connect';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import { getActiveCode } from '../../helpers/localize';
import withStyles from '@material-ui/core/styles/withStyles';
import { linkStyle } from '../../assets/jss/material-dashboard-react/components/linkStyle';
import { Field, getFormSyncErrors, reduxForm } from 'redux-form';
import validate from './LoginValidate';
import ReactTextField from '../../components/Form/ReactTextFieldUI';
import Checkbox from '../../components/Form/Checkbox';

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      showPassword: false,
      redirect: false,
    };
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.login();
    }
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  login = () => {
    const { errors } = this.props;
    const hasErrors = Object.keys(errors).length !== 0;
    // const emailTRS = [
    //   'trader.square.wot@protonmail.com',
    //   'testtrs@testwot.com',
    //   'gaetan_test_trs@testwot.com',
    //   'adrien@testtrs.com',
    //   'gaetan@testtrs.com',
    // ];
    if (!hasErrors) {
      const login = this.props.formLogin.values;

      // if (emailTRS.includes(login.email))
      //   localStorage.setItem('siteName', 'trs');
      // else localStorage.removeItem('siteName');

      // const body = `userName=${encodeURIComponent(login.email)}&
      //           password=${encodeURIComponent(login.password)}&
      //           grant_type=password&
      //           tfa=${encodeURIComponent(login.tfa)}&
      //           stayConnected=${encodeURIComponent(login.stayConnected)}&
      //           lang=${encodeURIComponent(codeLang)}`;
      //this.props.loginActions(body, codeLang);
      this.props.loginActions({
        username: login.email,
        password: login.password,
        stayConnected: login.stayConnected,
        tfa: login.tfa,
      });
    }
  };

  render() {
    const { classes, errors } = this.props;
    const hasErrors = Object.keys(errors).length !== 0;
    return (
      <div>
        <form>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Field
                name="email"
                type="email"
                label={<Translate id="email.emailAddress" />}
                fullWidth={true}
                component={ReactTextField}
                required
                inputProps={{
                  onKeyPress: this.handleKeyPress,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Field
                name="password"
                type="password"
                label={<Translate id="email.password" />}
                fullWidth={true}
                component={ReactTextField}
                required
                inputProps={{
                  onKeyPress: this.handleKeyPress,
                }}
                InputProps={{
                  type: this.state.showPassword ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Field
                name="tfa"
                type="tfa"
                label={<Translate id="email.tfa" />}
                fullWidth={true}
                component={ReactTextField}
                required
                inputProps={{
                  onKeyPress: this.handleKeyPress,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Field
                name="stayConnected"
                id="stayConnected"
                component={Checkbox}
                style={{ padding: '6px' }}
                type="checkbox"
              />
              <label htmlFor="stayConnected">
                <Translate id="email.stayConnected" />
              </label>
            </GridItem>
          </GridContainer>
          <GridContainer justifyContent="center">
            <Button
              onClick={this.login}
              color="primary"
              disabled={hasErrors}
              round
            >
              <Translate id="email.login" />
            </Button>
          </GridContainer>
          <GridContainer justifyContent="flex-end">
            <Link to="/forgotPassword" className={classes.link}>
              <Translate id="email.forgetPassword" />
            </Link>
          </GridContainer>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { form } = state;
  return {
    codeLang: getActiveCode(state),
    errors: getFormSyncErrors('login')(state),
    formLogin: form.login,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginActions: bindActionCreators(login, dispatch),
  };
};

let loginContainer = withStyles(linkStyle)(
  connect(mapStateToProps, mapDispatchToProps)(LoginContainer),
);

export default reduxForm({
  form: 'login', // a unique identifier for this form
  validate: validate,
  // asyncValidate
})(loginContainer);
