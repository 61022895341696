import { alertConstants } from './alert.constants';

export const alertPopup = (payload) => async (dispatch) => {
  dispatch({ type: alertConstants.ALERT, payload });
};

export const showPopupOptionsPaiementDispatch = () => async (dispatch) => {
  dispatch(showPopupOptionsPaiement());
};

export const hidePopupOptionsPaiementDispatch = () => async (dispatch) => {
  dispatch(hidePopupOptionsPaiement());
};

export const showPopupOptionsPaiement = () => {
  return { type: alertConstants.POPUP_OPTIONS_PAIEMENT, payload: true };
};

export const hidePopupOptionsPaiement = () => {
  return { type: alertConstants.POPUP_OPTIONS_PAIEMENT, payload: false };
};

const notifSuccess = (message, duration = 6000) => {
  return { type: alertConstants.SUCCESS, message, duration };
};

const notifError = (message, duration = 6000) => {
  return { type: alertConstants.ERROR, message, duration };
};

const alertSuccess = (message, html = null) => {
  const payload = { title: message, type: 'success' };
  if (html) {
    payload.html = html;
  }
  return { type: alertConstants.ALERT, payload };
};

const alertError = (message) => {
  const payload = { title: message, type: 'error' };
  return { type: alertConstants.ALERT, payload };
};

const clear = () => {
  return { type: alertConstants.CLEAR };
};

// const confirm = payload => async (dispatch, getState) => {
//   dispatch({
//
//   });
// };

export const clearAlert = () => async (dispatch) => {
  dispatch(clear());
};

export const alertActions = {
  notifSuccess,
  notifError,
  alertSuccess,
  alertError,
  clear,
};
