import { push } from 'connected-react-router';
import { exchangeConstants } from './constants';
import { fetcher } from '../../helpers/fetcher';
import { Translate } from '../../helpers/general';
import { alertActions } from '../alert/alert.actions';
import { traderConstants } from '../trader/constants';
import { isEmpty } from '../../helpers/Common';

export const refreshExchange = (id) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Exchange/refreshExchange/${id}`,
    {},
  );
  if (eu) {
    dispatch({
      type: exchangeConstants.ACTION_GET_EXCHANGE,
      payload: eu,
    });
    // await fetchExchanges(dispatch);
    // dispatch({
    //   type: exchangeConstants.ACTION_GET_EXCHANGE,
    //   payload: eu
    // });
    // localStorage.setItem('exchanges', JSON.stringify(eu));
  }
};

const fetchExchanges = async (dispatch, withHistoric = false) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Exchange/Exchanges?withHistoric=${withHistoric}`,
    {},
  );
  if (eu) {
    dispatch({
      type: exchangeConstants.ACTION_GET_EXCHANGES,
      payload: eu,
    });
    // Filtre last week and 1 point par jour
    // let lastWeek = new Date();
    // lastWeek.setDate(lastWeek.getDate() - 7);
    // let filtered = [...eu];
    // for (const e of filtered) {
    //   let historicFiltered = [];
    //   if (!isNull(e.HistoricBalances)) {
    //     for (const h of e.HistoricBalances) {
    //       const date = new Date(h.Date);
    //       if (h.Date.endsWith('00:00:00') || date > lastWeek) {
    //         historicFiltered.push(h);
    //       }
    //     }
    //   }
    //   e.HistoricBalances = historicFiltered;
    // }
    const euLocal = JSON.parse(localStorage.getItem('exchanges'));
    if (withHistoric || !euLocal)
      localStorage.setItem('exchanges', JSON.stringify(eu));
    //Todo Merge les deux
    // else {
    //   localStorage.setItem('exchanges', JSON.stringify(eu));
    // }
  }
};

export const getExchange =
  (id, withHistoric = false) =>
  async (dispatch) => {
    const euLocal = JSON.parse(localStorage.getItem('exchanges'));
    if (euLocal) {
      dispatch({
        type: exchangeConstants.ACTION_LOCAL_EXCHANGES,
        payload: euLocal,
      });
    }
    const eu = await fetcher(
      dispatch,
      `api/v1/Exchange?id=${id}&withHistoric=${withHistoric}`,
      {},
    );
    if (eu) {
      dispatch({
        type: exchangeConstants.ACTION_GET_EXCHANGE,
        payload: eu,
      });
    }
  };

export const getExchanges =
  (withHistoric = false) =>
  async (dispatch) => {
    const euLocal = JSON.parse(localStorage.getItem('exchanges'));
    if (euLocal) {
      dispatch({
        type: exchangeConstants.ACTION_LOCAL_EXCHANGES,
        payload: euLocal,
      });
    }
    await fetchExchanges(dispatch, withHistoric);
  };

export const getHistoricBalances = (id) => async (dispatch) => {
  var requestUrl = 'api/v1/Exchange/HistoricBalances?id';
  if (!isEmpty(id)) requestUrl += `=${id}`;
  const eu = await fetcher(dispatch, requestUrl, {});
  if (eu) {
    dispatch({
      type: exchangeConstants.ACTION_GET_HISTORIC_BALANCES,
      payload: eu,
    });
  }
};

export const postExchanges = (exchangeUser) => async (dispatch, getState) => {
  exchangeUser.accountType = 'Exchange';
  const eu = await fetcher(dispatch, 'api/v1/Exchange', {
    method: 'POST',
    body: exchangeUser,
  });
  if (eu) {
    const text = Translate(getState, 'exchanges.accountCreated');
    dispatch(alertActions.alertSuccess(text));
    dispatch({
      type: exchangeConstants.ACTION_POST_EXCHANGE,
      payload: eu,
    });
    dispatch(push('/accounts'));
    await fetchExchanges(dispatch);
    // window.location.reload();
  }
};

export const postExchangeOnboarding = (exchangeUser) => async (dispatch) => {
  exchangeUser.accountType = 'Exchange';
  const eu = await fetcher(dispatch, 'api/v1/Exchange', {
    method: 'POST',
    body: exchangeUser,
  });
  if (eu) {
    dispatch({
      type: exchangeConstants.ACTION_POST_EXCHANGE,
      payload: eu,
    });
  }
};

export const deleteExchanges = (id) => async (dispatch, getState) => {
  const eu = await fetcher(dispatch, `api/v1/Exchange/${id}`, {
    method: 'DELETE',
  });
  if (eu) {
    const text = Translate(getState, 'exchanges.accountDeleted');
    dispatch(alertActions.alertSuccess(text));
    dispatch({
      type: exchangeConstants.ACTION_DELETE_EXCHANGE,
      payload: eu,
    });
  }
};

export const updateExchanges = (exchange) => async (dispatch, getState) => {
  const eu = await fetcher(dispatch, `api/v1/Exchange/${exchange.id}`, {
    body: exchange,
    method: 'PUT',
  });
  if (eu) {
    const text = Translate(getState, 'exchanges.accountUpdated');
    dispatch(alertActions.alertSuccess(text));
    dispatch({
      type: exchangeConstants.ACTION_PUT_EXCHANGE,
      payload: eu,
    });
    // localStorage.setItem(
    //   'exchanges',
    //   JSON.stringify(getState().user.exchanges)
    // );
    await fetchExchanges(dispatch);
    dispatch(push('/accounts'));
  }
};

export const sellAllTo = (sellAllTo) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Order/SellAllTo', {
    method: 'POST',
    body: sellAllTo,
  });
  if (eu) {
    dispatch({
      type: exchangeConstants.ACTION_GET_EXCHANGES,
      payload: eu,
    });
    // localStorage.setItem('exchanges', JSON.stringify(eu));
  }
};

export const postExternal = (addExternal) => async (dispatch) => {
  addExternal.accountType = 'Wallet';
  const eu = await fetcher(dispatch, 'api/v1/Exchange', {
    method: 'POST',
    body: addExternal,
  });
  if (eu) {
    dispatch({
      type: exchangeConstants.ACTION_PUT_EXCHANGE,
      payload: eu,
    });
    dispatch(push('/accounts'));
  }
};

export const putExtra = (addExtra) => async (dispatch) => {
  addExtra.accountType = 'OffExchange';
  const eu = await fetcher(dispatch, `api/v1/Exchange/${addExtra.id}`, {
    method: 'PUT',
    body: addExtra,
  });
  if (eu) {
    dispatch({
      type: exchangeConstants.ACTION_PUT_EXCHANGE,
      payload: eu,
    });
    dispatch(push('/accounts'));
  }
};

export const postExtra = (addExtra) => async (dispatch) => {
  addExtra.accountType = 'OffExchange';
  const eu = await fetcher(dispatch, 'api/v1/Exchange', {
    method: 'POST',
    body: addExtra,
  });
  if (eu) {
    dispatch({
      type: exchangeConstants.ACTION_POST_EXCHANGE,
      payload: eu,
    });
    dispatch(push('/accounts'));
  }
};

export const deleteUser = (userId) => async (dispatch, state) => {
  const eu = await fetcher(dispatch, `api/v1/Account/Delete?userId=${userId}`, {
    method: 'DELETE',
  });
  if (eu) {
    const text = Translate(() => state, 'exchanges.accountDeleted');
    dispatch({
      type: traderConstants.ACTION_DELETE_USER,
      payload: userId,
    });
    dispatch(alertActions.alertSuccess(text));
  }
};

export const pushGroups = (groups) => async (dispatch) => {
  dispatch({
    type: traderConstants.ACTION_GET_GROUPS,
    payload: groups,
  });
};
