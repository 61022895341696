import React from 'react';
import { connect } from 'react-redux';
import { withWidth } from '@material-ui/core';
import TableUIFollowers from './TableUIFollowers';
import FollowersContainer from './FollowersContainer';
import { nbColumn } from '../../helpers/general';
import GridItem from '../../components/Grid/GridItem';

class ManageFollowers extends React.Component {
  render() {
    const { group, followers } = this.props;

    const isMobile = nbColumn(this.props.width) < 2;
    let isShowTable = !isMobile;

    return (
      <GridItem container>
        {isShowTable && (
          <TableUIFollowers group={group} followers={followers} />
        )}
        {!isShowTable && (
          <FollowersContainer group={group} followers={followers} />
        )}
      </GridItem>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { trader } = state;
  return {
    followers: trader?.followers,
  };
};

export default withWidth()(connect(mapStateToProps, null)(ManageFollowers));
