import React from 'react';
import { isRequired } from '../../helpers/form';
import { Translate } from 'react-localize-redux';
import { getApiLength } from '../../helpers/exchange';
import { isBybit, isBybitFuture } from '../../helpers/Common';

export default (values) => {
  const errors = {};
  let requiredFields = ['exchangeName', 'userName'];
  const { isPaperTrading, hasUpdateRole, isUpdate } = values;

  if (values.capitalRadio === 'Fixe') {
    requiredFields.push('capital');
  }
  if (!isPaperTrading) {
    requiredFields.push('apiKey');
    requiredFields.push('apiSecret');
  }
  if (hasUpdateRole) requiredFields = [];
  for (const field of requiredFields) {
    if (isRequired(values[field])) {
      errors[field] = <Translate id="central.required" />;
    }
  }

  if (
    !isUpdate &&
    hasUpdateRole &&
    values.userName &&
    !/^[a-zA-Z0-9._]*$/i.test(values.userName)
  ) {
    // errors.userName = <Translate id="error.alphaNumeric" />;
  }

  if (
    !isPaperTrading &&
    hasUpdateRole &&
    values.exchangeName &&
    values.exchangeName.value &&
    values.exchangeName.value !== 'Huobi'
    // && !isBybit(values.exchangeName.value) && !isBybitFuture(values.exchangeName.value)
  ) {
    const apiLength = getApiLength(values.exchangeName.exchange);
    if (!values.apiKey || values.apiKey.length !== apiLength.apiKey) {
      errors.apiKey = (
        <Translate id="exchanges.errorChar" data={{ val: apiLength.apiKey }} />
      );
    }
    if (!values.apiSecret || values.apiSecret.length !== apiLength.apiSecret) {
      errors.apiSecret = (
        <Translate
          id="exchanges.errorChar"
          data={{ val: apiLength.apiSecret }}
        />
      );
    }
  }
  return errors;
};
