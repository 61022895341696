import React from 'react';
import { connect } from 'react-redux';

import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';

import { bindActionCreators } from 'redux';
import { IsAdmin, getHistoricEmail } from '../../../redux/admin/actions';
import TableHistoricEmail from './TableHistoricEmail';

class HistoricEmailPage extends React.Component {
  componentDidMount() {
    this.props.IsAdminActions();
    this.props.getHistoricEmailActions();
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <TableHistoricEmail historicEmail={this.props.historicEmail} />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    historicEmail: admin.historicEmail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHistoricEmailActions: bindActionCreators(getHistoricEmail, dispatch),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
  };
};

const connectedUsers = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HistoricEmailPage);
export default connectedUsers;
