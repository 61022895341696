import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMobileDevice from '../../hooks/useIsMobileDevice';
import useTranslation from '../../hooks/useTranslation';
import onboardingjson from '../../locales/onboarding.json';
import transExchanges from '../../locales/exchanges.json';
import errorjson from '../../locales/error.json';
import traders from '../../locales/traders.json';
import confirmation from '../../locales/confirmation.json';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGroupId,
  getProfilUserSelector,
  getShowDialog,
  getStepSelector,
} from '../../redux/onboarding/selector';
import { changeShowDialog, changeStep } from '../../redux/onboarding/actions';
import ProfilContainer from './ProfilContainer';
import NewPopupContentExchange from './NewPopupContentExchange';
import AddExchangeContainer from './AddExchangeContainer';
import AddTrader from './AddTrader';
import OnboardingForGroup from './Group/OnboardingForGroup';
import WhiteLabelText from './Group/WhiteLabelText';
import ShareCommunity from './Group/ShareCommunity';
import { profilConstant } from './profil.constant';
import { exchangesSelector } from '../../redux/user/selector';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dialogContent: {
    padding: '8px -1px',
  },
  arrowBack: {
    position: 'absolute',
    top: 0,
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: 0,
  },
  arrowForward: {
    position: 'absolute',
    right: theme.spacing(6),
    top: 0,
  },
  paper: {
    minWidth: '800px',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OnboardingDialog = () => {
  const dispatch = useDispatch();
  useTranslation(onboardingjson);
  useTranslation(transExchanges);
  useTranslation(confirmation);
  useTranslation(traders);
  useTranslation(errorjson);
  const isMobile = useIsMobileDevice();
  const classes = useStyles();
  const open = useSelector(getShowDialog);
  const step = useSelector(getStepSelector);
  const exchanges = useSelector(exchangesSelector);
  const alreadyHasExchange =
    exchanges === null ? false : exchanges?.length !== 0;
  const profil = useSelector(getProfilUserSelector);
  const alreadyHasTrader = useSelector(getGroupId) !== 0;
  const profilFilled = profil !== '';
  const showArrowForward =
    (step === profilConstant.STEP_PROFIL && profilFilled) ||
    (step === profilConstant.STEP_ADD_EXCHANGE && alreadyHasExchange) ||
    (step === profilConstant.STEP_ADD_TRADER && alreadyHasTrader);

  const handleClose = (event, reason) => {
    if ('backdropClick' === reason) return;
    dispatch(changeShowDialog(false));
  };

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      classes={!isMobile ? { paper: classes.paper } : null}
    >
      <DialogTitle>
        {step !== profilConstant.STEP_PROFIL && (
          <IconButton
            edge="start"
            className={classes.arrowBack}
            color="inherit"
            onClick={() => {
              if (step === profilConstant.STEP_ADD_EXCHANGE)
                dispatch(changeStep(profilConstant.STEP_PROFIL));
              else if (step === profilConstant.STEP_POPUP_EXCHANGE)
                dispatch(changeStep(profilConstant.STEP_ADD_EXCHANGE));
              else if (step === profilConstant.STEP_ADD_TRADER)
                dispatch(changeStep(profilConstant.STEP_POPUP_EXCHANGE));
              else if (step === profilConstant.STEP_GROUP_CHOICES)
                dispatch(changeStep(profilConstant.STEP_ADD_TRADER));
              else if (step === profilConstant.STEP_SHARE_COMMUNITY)
                dispatch(changeStep(profilConstant.STEP_GROUP_CHOICES));
              else if (step === profilConstant.STEP_WHITE_LABEL)
                dispatch(changeStep(profilConstant.STEP_GROUP_CHOICES));
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}

        {showArrowForward && (
          <IconButton
            edge="start"
            className={classes.arrowForward}
            color="inherit"
            onClick={() => {
              if (
                step === profilConstant.STEP_ADD_EXCHANGE &&
                alreadyHasExchange
              )
                dispatch(changeStep(profilConstant.STEP_POPUP_EXCHANGE));
              else if (step === profilConstant.USER_SOLO_TRADER) {
                dispatch(changeStep(profilConstant.STEP_ADD_EXCHANGE));
              } else if (step === profilConstant.USER_GROUP_TRADER)
                dispatch(changeStep(profilConstant.STEP_ADD_TRADER));
              else if (step === profilConstant.STEP_ADD_TRADER)
                dispatch(changeStep(profilConstant.STEP_GROUP_CHOICES));
              else if (step === profilConstant.USER_COPY_TRADER) {
                dispatch(changeStep(profilConstant.STEP_ADD_EXCHANGE));
              } else if (step === profilConstant.USER_WALLET_TRADER) {
                dispatch(changeStep(profilConstant.STEP_ADD_EXCHANGE));
              }
            }}
          >
            <ArrowForwardIcon />
          </IconButton>
        )}

        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        {step === profilConstant.STEP_POPUP_EXCHANGE && (
          <NewPopupContentExchange />
        )}
        {step === profilConstant.STEP_PROFIL && <ProfilContainer />}
        {step === profilConstant.STEP_ADD_EXCHANGE && <AddExchangeContainer />}
        {step === profilConstant.STEP_ADD_TRADER && <AddTrader />}
        {step === profilConstant.STEP_GROUP_CHOICES && <OnboardingForGroup />}
        {step === profilConstant.STEP_WHITE_LABEL && <WhiteLabelText />}
        {step === profilConstant.STEP_SHARE_COMMUNITY && <ShareCommunity />}
      </DialogContent>
    </Dialog>
  );
};

export default OnboardingDialog;
