import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Grid,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import {
  Add,
  AttachMoney,
  People,
  Save,
  SwapVert,
  TrendingDown,
  TrendingUp,
} from '@material-ui/icons';

import Button from '../../components/CustomButtons/Button';

import { getMyGroupsSelect } from '../../helpers/Common';

import PairExchangeContainer from './Container/PairExchangeContainer';
import TakeProfitsContainer from './Container/TakeProfitsContainer';
import StopLossContainer from './Container/StopLossContainer';
import StartContainer from './Container/StartContainer';
import TraderContainer from './Container/TraderContainer';
import TradingViewContainer from './Container/TradingViewContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  appBarSubmitOrderButton: {
    top: 'auto',
    bottom: '75px', // height of the other app bar
    display: 'flex',
    alignItems: 'center',
  },
  tabSubmitOrderButton: {
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    width: '100%',
  },
  tinyButton: {
    minWidth: 0, // remove mui min-width:52px (this is too large to fit 5 tabs)
  },
}));

const TabPanel = ({ children, value, index, title, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`tabpanel-${title}`}
    {...other}
  >
    {value === index && (
      <Box
        p={0}
        margin="auto"
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="start"
        flexDirection="column"
      >
        {title && <h3>{title}</h3>}
        <Grid>{children}</Grid>
      </Box>
    )}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const SubmitOrderButton = ({ onClick, isUpdate, disabled }) => (
  <Button onClick={onClick} color="primary" round disabled={disabled}>
    {isUpdate ? (
      <>
        <Save /> <Translate id="smartTrade.updateTrade" />
      </>
    ) : (
      <>
        <Add /> <Translate id="smartTrade.createTrade" />
      </>
    )}
  </Button>
);

// TODO; true icons
const getTabs = ({ groups, formName, currentOrder }) => {
  const tabs = [
    {
      content: (
        <>
          <PairExchangeContainer formName={formName} />
          <TradingViewContainer />
        </>
      ),
      icon: <SwapVert fontSize="small" />,
      label: 'Pairs', // TODO
    },
    {
      content: <StartContainer formName={formName} order={currentOrder} />,
      icon: <AttachMoney fontSize="small" />,
      label: 'Start', // TODO
    },
    {
      content: <TakeProfitsContainer formName={formName} />,
      icon: <TrendingUp fontSize="small" />,
      label: <Translate id="smartTrade.setTargetProfit" />,
    },
    {
      content: <StopLossContainer formName={formName} />,
      icon: <TrendingDown fontSize="small" />,
      label: <Translate id="smartTrade.setStopLoss" />,
    },
  ];
  if (Array.isArray(groups) && groups.length > 0) {
    tabs.push({
      content: <TraderContainer formName={formName} />,
      icon: <People fontSize="small" />,
      label: <Translate id="smartTrade.setFollowers" />,
    });
  }
  return tabs;
};
function FormSmartTradeMobile({ formName, disabled, currentOrder, onSubmit }) {
  const classes = useStyles();
  const theme = useTheme();
  const groups = useSelector(getMyGroupsSelect);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const handleChangeIndex = (event, index) => {
    setSelectedTabIndex(index);
  };
  const tabs = useMemo(
    () => getTabs({ groups, formName, currentOrder }),
    [groups, formName, currentOrder],
  );

  return (
    <>
      <div className={classes.root}>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={selectedTabIndex}
          onChangeIndex={setSelectedTabIndex}
        >
          {tabs.map(({ content, label }, i) => (
            <TabPanel
              key={i}
              value={selectedTabIndex}
              index={i}
              dir={theme.direction}
              title={label}
            >
              {content}
            </TabPanel>
          ))}
        </SwipeableViews>
      </div>
      {selectedTabIndex > 0 ? (
        <AppBar
          position="fixed"
          color="transparent"
          className={classes.appBarSubmitOrderButton}
        >
          <SubmitOrderButton
            className={classes.tabSubmitOrderButton}
            onClick={onSubmit}
            isUpdate={!!currentOrder}
            disabled={disabled}
          />
        </AppBar>
      ) : null}
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <BottomNavigation value={selectedTabIndex} onChange={handleChangeIndex}>
          {tabs.map(({ icon }, i) => (
            <BottomNavigationAction
              className={classes.tinyButton}
              key={i}
              value={i}
              icon={icon}
            />
          ))}
        </BottomNavigation>
      </AppBar>
    </>
  );
}

export default FormSmartTradeMobile;
