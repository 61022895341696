import React from 'react';
import {
  Field,
  formValueSelector,
  reduxForm,
  getFormValues,
  change,
} from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import WarningIcon from '@material-ui/icons/Warning';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from '../../components/Grid/GridItem.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import ReactSelect from '../../components/Form/ReactSelectUI';
import ReactTextField from '../../components/Form/ReactTextFieldUI';
import RadioGroup from '../../components/Form/ReactRadioGroupUI';
import LabelCheckbox from '../../components/Form/LabelCheckbox';
import { maxLength, required } from '../../components/Form/validator';
import { SmallCheckBox } from '../../components/Fields/SmallCheckBox';
import MyDateTimePicker from '../../components/Fields/MyDateTimePicker';
import Price from '../../components/Fields/Price';
import Card from '../../components/Card/Card.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import Warning from '../../components/Typography/Warning';
import {
  isBinanceFuture,
  isBybit,
  isBybitFuture,
  isEmpty,
  isNull,
} from '../../helpers/Common';
import validate from './validate';
import { addFollower } from '../../redux/trader/actions';
import { updateFollower } from '../../redux/trader/actions';
import {
  formatDataForPost,
  getApiLength,
  isHedgeMode,
  optionsExchanges,
} from '../../helpers/exchange';
import { getActiveCode } from '../../helpers/localize';
import {
  getExchangeExEnum,
  getExchangeStr,
  dateToBackFormat,
  getExchangeExStr,
} from '../../helpers/order';
import SwitchUI from '@material-ui/core/Switch/Switch';

const styles = {
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const isZeroOrNegativeCapital = (value) =>
  value && value < 1 ? (
    <Translate id="error.smallAmount" data={{ val: 1 }} />
  ) : undefined;
const isZeroOrNegativePercentage = (value) =>
  value && value < 1 ? (
    <Translate id="error.between" data={{ min: 1, max: 100 }} />
  ) : undefined;
const isOverOneHundred = (value) =>
  value && value > 100 ? (
    <Translate id="error.between" data={{ min: 1, max: 100 }} />
  ) : undefined;

const formName = 'formAddFollower';
const defaultExchange = 'Binance_Spot';
const defaultExchangeLabel = 'Binance_Spot';
const defaultCapitalRadio = 'Auto';
const defaultPercentageExchange = '100';
const lastPercentageBeforeMax = 99.99;

class FormAddFollower extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledClick: false,
    };
  }

  addFollower = () => {
    const {
      exchangeName,
      userName,
      apiKey,
      apiSecret,
      hedgeMode,
      passPhrase,
      subAccount,
      endDateCB,
      note,
    } = this.props.data;
    const { trader, isUpdate, userId, noPopupTrading, redirect } = this.props;
    const groupId = trader.GroupId;

    const endDate =
      this.props.data?.endDate && endDateCB
        ? dateToBackFormat(this.props.data.endDate)
        : null;
    const capital =
      this.props.data?.capitalRadio === 'Auto' ? null : this.props.data.capital;
    const percentageExchange = this.props.data?.startPercentageExchangeCB
      ? this.props.data.percentageExchange
      : '100';

    const request = formatDataForPost({
      groupId,
      userName,
      endDate,
      pourcentage: percentageExchange,
      publicApi: apiKey,
      privateApi: apiSecret,
      hedgeMode,
      capital,
      exchangeName,
      note: isNull(note) ? '' : note,
      passPhrase,
      subAccount,
    });

    this.setState({ disabledClick: true });
    setTimeout(() => {
      this.setState({ disabledClick: false });
    }, 3000);

    if (isUpdate) {
      request.userId = userId;
      this.props.updateFollowerActions(request);
    } else {
      this.props.addFollowerActions(request, noPopupTrading, redirect);
    }
  };

  render() {
    const {
      isUpdate,
      trader,
      follower,
      classes,
      exchangeName,
      capitalRadio,
      startPercentageExchangeCB,
      endDate,
      endDateCB,
      hedgeMode,
      codeLang,
      invalid,
      change,
      hasUpdateRole,
    } = this.props;
    const { disabledClick } = this.state;
    const isFix = capitalRadio === 'Fixe';
    const isUpdatableExchange = isUpdate && !follower.Exchange;
    //user cacher
    if (follower && follower.ApplicationUserId === follower.UserName)
      return <div />;

    const isBinance =
      exchangeName &&
      Object.hasOwn(exchangeName, 'exchange') &&
      exchangeName.exchange === 'Binance_Spot';
    const isBinanceFutures =
      exchangeName &&
      Object.hasOwn(exchangeName, 'exchange') &&
      isBinanceFuture(exchangeName.exchange);
    const isFTX =
      exchangeName &&
      Object.hasOwn(exchangeName, 'exchange') &&
      exchangeName.exchange.indexOf('FTX') !== -1;
    const isKucoin =
      exchangeName &&
      Object.hasOwn(exchangeName, 'exchange') &&
      exchangeName.exchange === 'Kucoin';
    const isOkx =
      exchangeName &&
      Object.hasOwn(exchangeName, 'exchange') &&
      exchangeName.exchange === 'Okx';
    const isBybits =
      exchangeName &&
      Object.hasOwn(exchangeName, 'exchange') &&
      isBybit(exchangeName.exchange);
    const isBybitFutures =
      exchangeName &&
      Object.hasOwn(exchangeName, 'exchange') &&
      isBybitFuture(exchangeName.exchange);
    const needPassphrase = isKucoin || isOkx;
    const allowIP =
      exchangeName &&
      Object.hasOwn(exchangeName, 'exchange') &&
      (exchangeName.exchange === 'Huobi' ||
        exchangeName.exchange === 'LBank' ||
        exchangeName.exchange.indexOf('Binance') !== -1);
    const IPs = ['54.38.230.54', '145.239.120.101', '147.135.182.132'];
    return (
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={10} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                <Translate
                  id={
                    isUpdate
                      ? 'traders.editFollowerFrom'
                      : 'traders.addFollowerTo'
                  }
                />
                : {trader.Name}
              </h4>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Field
                      name="exchangeName"
                      label={<Translate id="exchanges.exchangeName" />}
                      options={optionsExchanges}
                      fullWidth={true}
                      component={ReactSelect}
                      required
                      disabled={!isUpdatableExchange || !hasUpdateRole}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Field
                      name="userName"
                      label={<Translate id="central.username" />}
                      fullWidth={true}
                      component={ReactTextField}
                      required
                      disabled={isUpdate}
                    />
                  </GridItem>
                  {hasUpdateRole && (
                    <>
                      {allowIP && (
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          style={{ marginBottom: '10px' }}
                        >
                          <Warning severity="warning" color="warning">
                            <div className="icons-text">
                              <WarningIcon />
                            </div>
                            <Translate
                              id="central.showIP"
                              data={{ ips: IPs.join(isBinance ? ' ' : ',') }}
                              options={{ renderInnerHtml: true }}
                            />
                          </Warning>
                        </GridItem>
                      )}
                      <GridItem xs={12} sm={12} md={12}>
                        <Field
                          name="capitalRadio"
                          id="capitalRadio"
                          component={RadioGroup}
                        >
                          <FormControlLabel
                            value="Auto"
                            control={<Radio color="primary" />}
                            label={<Translate id="exchanges.takeAllCapital" />}
                          />
                          <FormControlLabel
                            value="Fixe"
                            control={<Radio color="primary" />}
                            label={<Translate id="exchanges.defineCapital" />}
                          />
                        </Field>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        hidden={!isFix}
                        style={{ marginTop: '12px', marginBottom: '-12px' }}
                      >
                        <Price
                          name="capital"
                          label={<Translate id="exchanges.capital" />}
                          endAdornment="$"
                          validate={[isZeroOrNegativeCapital]}
                          step={10}
                        />
                      </GridItem>
                      <GridItem
                        xs={9}
                        sm={8}
                        md={8}
                        lg={6}
                        style={{ marginTop: '30px' }}
                      >
                        <Field
                          name="startPercentageExchangeCB"
                          id="startPercentageExchangeCB"
                          component={SmallCheckBox}
                        />
                        <LabelCheckbox htmlFor="startPercentageExchangeCB">
                          <Translate id="traders.percentageExchange" />
                        </LabelCheckbox>
                      </GridItem>
                      <GridItem
                        xs={3}
                        sm={4}
                        md={4}
                        lg={6}
                        style={{ marginBottom: '-30px', marginTop: '12px' }}
                      >
                        {startPercentageExchangeCB && (
                          <Field
                            className="form-control"
                            name="percentageExchange"
                            label={<Translate id="central.percentage" />}
                            component={ReactTextField}
                            required
                            validate={[
                              isZeroOrNegativePercentage,
                              isOverOneHundred,
                              required,
                            ]}
                            type="number"
                            inputProps={{ step: 1 }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ marginTop: '24px' }}
                      >
                        <Field
                          name="apiKey"
                          label={<Translate id="exchanges.APIkey" />}
                          fullWidth={true}
                          component={ReactTextField}
                          inputProps={{ autocomplete: 'off' }}
                          required
                        />
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ marginTop: '-4px' }}
                      >
                        <Field
                          name="apiSecret"
                          label={<Translate id="exchanges.APIsecret" />}
                          fullWidth={true}
                          component={ReactTextField}
                          inputProps={{ autocomplete: 'off' }}
                          required
                        />
                      </GridItem>
                      {needPassphrase && (
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          style={{ marginTop: '-4px' }}
                        >
                          <Field
                            name="passPhrase"
                            label={<Translate id="exchanges.PassPhrase" />}
                            fullWidth={true}
                            component={ReactTextField}
                            inputProps={{ autocomplete: 'off' }}
                            required
                          />
                        </GridItem>
                      )}
                      {isFTX && (
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          style={{ marginTop: '-4px' }}
                        >
                          <Field
                            name="subAccount"
                            label={<Translate id="exchanges.SubAccount" />}
                            fullWidth={true}
                            component={ReactTextField}
                            inputProps={{ autocomplete: 'off' }}
                          />
                        </GridItem>
                      )}
                    </>
                  )}
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    hidden={!isBinanceFutures && !isBybitFutures}
                  >
                    <br />
                    <Translate id="exchanges.oneWayMode" />
                    &nbsp;
                    <SwitchUI
                      checked={hedgeMode === true}
                      onChange={() => {
                        this.props.change('hedgeMode', !hedgeMode);
                      }}
                      color="primary"
                    />
                    <Translate id="exchanges.hedgeMode" />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ marginTop: '4px' }}
                  >
                    <Field
                      name="note"
                      label={<Translate id="central.note" />}
                      fullWidth={true}
                      component={ReactTextField}
                      validate={maxLength(128)}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={5} md={6} style={{ marginTop: '12px' }}>
                    <Field
                      name="endDateCB"
                      id="endDateCB"
                      component={SmallCheckBox}
                      onClick={() => {
                        if (isEmpty(endDate)) change('endDate', new Date());
                      }}
                    />
                    <LabelCheckbox htmlFor="endDateCB">
                      <Translate id="traders.endDate" />
                    </LabelCheckbox>
                  </GridItem>
                  <GridItem
                    xs={8}
                    sm={7}
                    md={6}
                    style={{ marginBottom: '12px' }}
                  >
                    {endDateCB && (
                      <MyDateTimePicker
                        disablePast={false}
                        lang={codeLang}
                        name="endDate"
                        formName={formName}
                      />
                    )}
                  </GridItem>
                  <GridItem
                    container
                    justifyContent="center"
                    style={{ marginTop: '10px' }}
                  >
                    <Button
                      color="info"
                      position="center"
                      disabled={invalid || disabledClick}
                      onClick={() => this.addFollower()}
                    >
                      <Translate
                        id={
                          isUpdate
                            ? 'traders.editFollower'
                            : 'traders.addFollower'
                        }
                      />
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

FormAddFollower.propTypes = {
  classes: PropTypes.object.isRequired,
};

let formAddFollower = reduxForm({
  form: formName, // a unique identifier for this form
  touchOnChange: true,
  validate,
  initialValues: {
    isUpdate: false,
    hasUpdateRole: true,
    capitalRadio: defaultCapitalRadio,
    percentageExchange: defaultPercentageExchange,
    exchangeName: {
      value: defaultExchange,
      label: defaultExchangeLabel,
      exchange: defaultExchange,
    },
  },
})(FormAddFollower);

const followerSelector = (state, props) => {
  return isNull(props.follower) ? null : props.follower;
};

const traderSelector = (state, props) => {
  return isNull(props.trader) ? null : props.trader;
};

const initialValuesSelector = createSelector(
  followerSelector,
  traderSelector,
  (follower, trader) => {
    if (follower) {
      const roleSG = follower?.RoleSG;
      const hasUpdateRole = roleSG === 1 || roleSG === 3;
      const apiLength = getApiLength(getExchangeStr(follower?.Exchange));
      const hedgeMode = isHedgeMode(follower?.Exchange?.Options);
      const pourcentage = follower?.Pourcentage;
      const returnedData = {
        isUpdate: true,
        hasUpdateRole: hasUpdateRole,
        userName: follower.UserName,
        hedgeMode: hedgeMode,
        capitalRadio: follower?.Exchange?.Capital ? 'Fixe' : 'Auto',
        startPercentageExchangeCB: pourcentage <= lastPercentageBeforeMax,
        percentageExchange:
          pourcentage > lastPercentageBeforeMax ? '100' : pourcentage,
        apiKey: '*'.repeat(apiLength.apiKey),
        apiSecret: '*'.repeat(apiLength.apiSecret),
        note: follower.Note,
        //passPhrase: ,
        //subAccount: ,
      };
      if (follower?.Exchange) {
        returnedData.exchangeName = {
          value: follower.Exchange.ExchangeName,
          label: follower.Exchange.Name,
          exchange: getExchangeExStr(follower.Exchange.ExchangeName),
        };
      } else {
        returnedData.exchangeName = {
          value: getExchangeExEnum(defaultExchange),
          label: defaultExchangeLabel,
          exchange: defaultExchange,
        };
      }
      if (follower?.Exchange?.Capital)
        returnedData.capital = follower.Exchange.Capital;
      if (follower.EndDate) {
        returnedData.endDateCB = true;
        returnedData.endDate = follower.EndDate;
      }
      return returnedData;
    } else {
      const exchangeStr = getExchangeStr(trader);
      return {
        isUpdate: false,
        hasUpdateRole: true,
        capitalRadio: defaultCapitalRadio,
        percentageExchange: defaultPercentageExchange,
        exchangeName: {
          value: getExchangeExEnum(exchangeStr),
          label: exchangeStr,
          exchange: exchangeStr,
        },
      };
    }
  },
);

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(formName);
  return {
    isUpdate: formSelector(state, 'isUpdate'),
    hasUpdateRole: formSelector(state, 'hasUpdateRole'),
    exchangeName: formSelector(state, 'exchangeName'),
    capitalRadio: formSelector(state, 'capitalRadio'),
    startPercentageExchangeCB: formSelector(state, 'startPercentageExchangeCB'),
    endDateCB: formSelector(state, 'endDateCB'),
    hedgeMode: formSelector(state, 'hedgeMode'),
    endDate: formSelector(state, 'endDate'),
    codeLang: getActiveCode(state),
    data: getFormValues(formName)(state),
    initialValues: initialValuesSelector(state, props),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    addFollowerActions: bindActionCreators(addFollower, dispatch),
    updateFollowerActions: bindActionCreators(updateFollower, dispatch),
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(formAddFollower);
const withStyle = withStyles(styles)(connected);

export default withLocalize(withStyle);
